.basket {
    .price {
        color: $primary;
        font-size: 30px;
        font-weight: 600;
    }
    .item {
        &:nth-child(odd) {
            background: #fafafa;
        }
        padding: 15px;
    }
    &-count {
        left: 70%;
        > span {
            font-size: 10px;
            position: absolute;
            top: -1px;
            left: -1px;
            display: inline-block;
            height: 20px;
            width: 20px;
        }
    }
}