/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

    .sliderch {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
    }
    
    .sliderch:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .switch + span {
    position: absolute;
  }
  
  /* The slider */

  
  input:checked + .sliderch {
    background-color: $primary;
  }
  
  input:focus + .sliderch {
    box-shadow: 0 0 1px $primary;
  }
  
  input:checked + .sliderch:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .sliderch.round {
    border-radius: 34px;
  }
  
  .sliderch.round:before {
    border-radius: 50%;
  }

  .disabled {
    background: #ccc !important;
  }