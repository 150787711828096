.matchcard {
    .card {
        margin-bottom: 10px;
    }
    .slim-pageheader {
        margin:20px 0;
        position: relative;
        .slim-pageactions {
            float:right;
            position: relative;
            top: -15px;
        }
        +div {
            clear:both;
        }
    }
    h6 {
        margin-top: 0;
    }
    .btn-icon {
        padding: 0;
        &.btn-circle {
            border-radius: 50%;
            border: none;
    
            >div {
                height: 50px;
                width: 50px;
            }
            &.text-secondary {
                &:hover {
                    i {
                        color: $white;
                    }
                }
            }
        }
        > div {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            width: 35px;
            height: 35px;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
        }
    }
}