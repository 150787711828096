.booking-slots {
    .slot {
        a {
            padding: 20px;
            background-color: #ddd;
            border-radius: 30px;
            &.btn-danger {
                background-color: $danger;
            }
        }
    }
}