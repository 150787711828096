
// Forms
$body-bg:                       #f0f2f7;
$body-color:                    #868ba1;

// Border
$border-radius:                 3px;

// Color
$orange:                        #f27510;
$teal:                          #1CAF9A;
$cyan:                          #5B93D3;
$blue:                          #1b84e7; //#0866C6;
$green:                         #23BF08;
$yellow:                        #F49917;
$darkblue:                      #0D0D6B;

// Card
$card-border-color:             #ced4da;

// Custom Forms
$ckbox-size:                    16px;
$rdiobox-size:                  16px;

// Forms elements
$input-btn-padding-y:           0.594rem;
$input-focus-box-shadow:        none;

// Fonts
$font-family-opensans:          'Open Sans', 'Helvetica Neue', Arial, sans-serif;
$font-family-roboto:            'Roboto', 'Helvetica Neue', Arial, sans-serif;
$font-family-montserrat:        'Montserrat', 'Helvetica Neue', Arial, sans-serif;
$font-family-lato:              'Lato', 'Helvetica Neue', Arial, sans-serif;

$font-family-base:              $font-family-roboto;
$font-size-base:                .875rem;

// Links
$link-hover-decoration:         none;

// Table
$table-border-color:            #dee2e6;

