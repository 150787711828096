.btn {
    .fa-spin {
        display: none;
    }

    &.loading {
        .fa-spin {
            display: block;
        }
    }

    &[class*="bg-"] {
        color: $white;
        &:hover {
            color: $white;
        }
    }

    &.bg-white {
        color: $secondary;
        &:hover {
            color: $secondary;
        }
    }

    .bg-primary {
        &:hover {
            background-color: rgba($primary, 0.8);
        }
    }
    &.btn-circle {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        padding: 15px;
        display: inline-block;
    }
}
