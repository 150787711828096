.widget {
    &--league-table {
        .sub-title {
            font-size: 12px;
            display: block;
            letter-spacing: 0.5px;
            font-weight: normal;
            line-height: 14px;
        }
    }
}