@media print {
    body {
        background: #fff;
    }

    table {
        td {
            padding: 0;
            margin: 0;
            border: none !important;
        }
    }

    .fixture-list {
        .date {
            width: 90px;
        }

        .day {
            width: 130px;
        }

        .time {
            width: 80px;
        }

        .teama,
        .teamb {
            width: 200px;
        }

        .venue {
            width: 200px;
            span {
                text-overflow: ellipsis;
                /* Required for text-overflow to do anything */
                white-space: nowrap;
                overflow: hidden;
                display: block;
                width: 200px;
            }
        }

        .score {
            width: 60px;
        }

        .tbd {
            font-size: 16px;
        }
    }
}