.card {
    padding: 10px;
    border:none;
    border-radius: 5px;
    -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.07);
    -ms-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.07);
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.07);
    overflow: hidden;
    &--border {
        border: solid 1px #ddd;
    }
}