// User List Group
.list-group-default {
    .list-group-item {
        padding: 15px 20px;

        @media (min-width: 480px) {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        img {
            width: 48px;
            @include border-radius(100%);
        }
    }

    .list-title {
        margin-top: 10px;
        margin-right: auto;

        @media (min-width: 480px) {
            margin-left: 15px;
            margin-top: 0;
        }

        p {
            margin-bottom: 0;
            color: $gray-800;
            font-weight: 500;
        }

        span {
            display: block;
            font-size: 13px;
        }
    }

    .list-btn-wrapper {
        display: flex;
        align-items: center;
        margin-top: 10px;

        @media (min-width: 480px) {
            margin-top: 0;
        }

        .btn+.btn {
            margin-left: 5px;
        }
    }

    .list-status-wrapper {
        display: flex;
        align-items: center;
        margin-top: 10px;

        @media (min-width: 480px) {
            margin-top: 0;
        }

        .btn+.btn {
            margin-left: 5px;
        }
    }
}

.list-group-sortable {
    .list-group-item {
        &:hover {
            cursor: pointer;
        }
    }
}

.fixture-list {
    .card-body {
        @media (max-width: 992px) {
            padding:10px;
        }
    }
    .date {
        background: $gray-100;
        padding: 10px;
        margin-bottom: 10px;
        position: relative;

        .dropdown {
            margin-top: -8px;
            position: absolute;
            right: 0;
        }
    }

    .team-a {
        text-align: right;
    }

    .team-b {
        text-align: left;
    }

    .match {
        border-bottom: solid 1px #eee;
        padding: 10px 0;

        .dropdown {
            .btn-icon {
                >div {
                    height: auto;
                }
            }
        }
    }
}

body { 
    >.list-group {
        z-index: 9999;
    }
}

.list-group {
    .list-group-item {
        .avatar {
            background: #f4f4f4;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $gray-800;
            font-weight: 500;
            min-width: 50px;
        }
        .user-btn-wrapper {
          margin-left: 15px;
        }

        h5 {
            color: #555;
            margin:0;
        }
    }
}