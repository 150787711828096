/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/instagram-feed/css/sb-instagram.min.css?ver=1.12 ; media=all */
@media all {
  #sb_instagram {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  #sb_instagram {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
  }

  #sb_instagram:after {
    content: "";
    display: table;
    clear: both;
  }

  #sb_instagram #sbi_images {
    width: 100%;
    float: left;
    line-height: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  #sb_instagram .sb_instagram_header {
    float: left;
    clear: both;
    margin: 0 0 15px;
    padding: 0;
    line-height: 1.2;
    width: 100%;
  }

  #sb_instagram #sbi_load,
  #sb_instagram .sb_instagram_error {
    width: 100%;
    text-align: center;
    line-height: 1.5;
  }

  #sb_instagram #sbi_load {
    float: left;
    clear: both;
    opacity: 1;
    transition: all 0.5s ease-in;
  }

  #sb_instagram .sbi_hidden {
    opacity: 0 !important;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/sportspress/assets/css/sportspress.css?ver=2.6.14 ; media=all */
@media all {
  .sp-header {
    position: relative;
    overflow: visible;
    z-index: 10000;
  }

  .sp-template {
    margin-bottom: 1.5em;
  }

  .sp-scrollable-table-wrapper {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
  }

  .sp-data-table {
    width: 100%;
    table-layout: auto;
    border-spacing: 0;
  }

  .sp-paginated-table {
    margin-bottom: 0;
  }

  .sp-data-table thead th {
    white-space: nowrap;
  }

  .sp-data-table thead .sorting {
    cursor: pointer;
  }

  .sp-data-table tbody a {
    text-decoration: none;
  }

  .sp-data-table .sorting:after {
    font-family: dashicons;
  }

  .sp-data-table .sorting:after {
    content: "\f156";
    color: transparent;
    position: absolute;
  }

  .sp-data-table .data-name {
    text-align: left;
  }

  .sp-data-table .data-rank {
    width: 1px;
  }

  .sp-player-list td {
    line-height: 2em;
  }

  .sp-event-blocks thead {
    display: none;
  }

  .sp-event-blocks .sp-event-date,
  .sp-event-blocks .sp-event-results,
  .sp-event-blocks .sp-event-title {
    text-align: center;
    display: block;
    margin: 10px 0;
    clear: none;
  }

  .sp-event-blocks .team-logo {
    margin: 10px;
    width: 20%;
    height: 4em;
    max-width: 6em;
    text-align: center;
  }

  .sp-event-blocks .logo-odd {
    float: left;
  }

  .sp-event-blocks .logo-even {
    float: right;
  }

  .sp-event-blocks .team-logo img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }

  .sp-template-countdown .event-name {
    clear: both;
    overflow: hidden;
  }

  .sp-template-countdown .sp-countdown {
    clear: both;
  }

  .sp-view-all-link {
    display: block;
    margin-top: 0.5em;
  }

  @media screen and (min-width: 801px) {
    .sp-widget-align-none {
      clear: both;
    }
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/sportspress/assets/css/sportspress-style.css?ver=2.3 ; media=all */
@media all {
  .sp-template,
  .sp-data-table,
  .sp-table-caption {
    font-family: "Roboto", "Helvetica", sans-serif;
  }

  .sp-template {
    clear: both;
    overflow: hidden;
  }

  .sp-template h3,
  .sp-template h4,
  .sp-template h5,
  .sp-data-table h4,
  .sp-data-table h5 {
    font-family: "Roboto", "Helvetica", sans-serif !important;
  }

  .sp-template {
    margin-bottom: 20px !important;
  }

  .sp-template *,
  .sp-data-table *,
  .sp-table-caption {
    box-sizing: border-box;
    text-transform: none;
    letter-spacing: normal;
    color: #333;
  }

  .sp-table-caption {
    margin: 0 !important;
    padding: 15px !important;
    background: #fff !important;
    border: 1px solid #e5e5e5 !important;
    border-bottom: none !important;
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    display: table !important;
    width: 100% !important;
  }

  .sp-template a:hover,
  .sp-data-table a:hover {
    text-decoration: underline !important;
  }

  .sp-view-all-link {
    padding: 10px 15px !important;
    margin: 0 !important;
    background: #fff !important;
    border: 1px solid #e5e5e5 !important;
    border-top: none !important;
    overflow: hidden !important;
    display: table !important;
    width: 100% !important;
    text-align: right !important;
  }

  .sp-view-all-link a {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    text-decoration: none !important;
    color: rgba(51, 51, 51, 0.5) !important;
  }

  .sp-view-all-link a:hover {
    color: #333 !important;
    text-decoration: none !important;
  }

  .sp-template img {
    display: inline-block !important;
    box-shadow: none !important;
  }

  .sp-data-table {
    margin: 0 !important;
    background: #fff !important;
    border-collapse: collapse !important;
    border: 1px solid #e5e5e5 !important;
    width: 100% !important;
  }

  .sp-data-table tr {
    border: none !important;
  }

  .sp-template-countdown .sp-event-name,
  .sp-template-countdown .sp-event-venue,
  .sp-template-countdown .sp-event-date {
    margin: 0 !important;
    padding: 5px 10px !important;
    text-align: center !important;
    line-height: 1.5 !important;
    display: table !important;
    width: 100% !important;
    background: #fff !important;
    border: 1px solid #e5e5e5 !important;
    border-bottom: none !important;
  }

  .sp-template-countdown .sp-event-name {
    font-size: 13px !important;
    font-weight: 400 !important;
    color: #333 !important;
  }

  .sp-template-countdown .sp-event-venue,
  .sp-template-countdown .sp-event-date {
    font-size: 12px !important;
    font-weight: 500 !important;
    background: #fff !important;
    color: #999 !important;
  }

  .sp-countdown time {
    display: table !important;
    background: #fff !important;
    border: 1px solid #e5e5e5 !important;
    border-collapse: collapse !important;
    width: 100% !important;
  }

  .sp-countdown span {
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    display: table-cell !important;
    border: 1px solid #e5e5e5 !important;
    padding: 10px 5px !important;
    text-align: center !important;
  }

  .sp-countdown span small {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    font-weight: 500 !important;
    color: rgba(51, 51, 51, 0.5) !important;
    display: block !important;
  }

  .sp-template-event-blocks td {
    padding: 0 !important;
  }

  .sp-template-event-blocks .sp-event-date {
    color: rgba(51, 51, 51, 0.5) !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    margin: 10px !important;
  }

  .sp-template-event-blocks .sp-event-date a,
  .sp-template-event-blocks .sp-event-date a:hover {
    color: inherit !important;
  }

  .sp-template-event-blocks .sp-event-results {
    font-size: 20px !important;
    font-weight: 400 !important;
    padding: 0 !important;
    margin: 0 !important;
    color: #333 !important;
  }

  .sp-template-event-blocks .sp-event-results a {
    color: inherit !important;
  }

  .sp-template-event-blocks .sp-event-results a:hover {
    color: inherit !important;
    text-decoration: none !important;
  }

  .sp-template-event-blocks .sp-event-title {
    display: block !important;
    padding: 0 !important;
    margin: 10px !important;
    font-size: 13px !important;
    font-weight: 400 !important;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/sportspress/assets/css/sportspress-style-ltr.css?ver=2.3 ; media=all */
@media all {
  .sp-data-table .data-name {
    text-align: left !important;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/trx_addons/css/font-icons/css/trx_addons_icons.css ; media=all */
@media all {
  [class*=" trx_addons_icon-"]:before {
    font-family: "trx_addons_icons";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: 0.2em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .trx_addons_icon-up:before {
    content: "\e85a";
  }

  .trx_addons_icon-search:before {
    content: "\e898";
  }

  .trx_addons_icon-menu:before {
    content: "\e8bc";
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/trx_addons/js/swiper/swiper.min.css ; media=all */
@media all {
  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property:
      transform,
      -webkit-transform;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }

  .swiper-wrapper {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .swiper-slide {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property:
      transform,
      -webkit-transform;
  }

  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 100%;
    background: #000;
    opacity: 0.2;
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
    background: #007aff;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/trx_addons/js/magnific/magnific-popup.min.css ; media=all */
@media all {
  button::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/trx_addons/css/__styles.css ; media=all */
@media all {
  .trx_addons_scroll_to_top {
    display: block;
    background: #fff;
    cursor: pointer;
    opacity: 0;
    position: fixed;
    z-index: 100001;
    bottom: -4em;
    right: 2em;
    width: 3em;
    height: 3em;
    line-height: 3em;
    text-align: center;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .trx_addons_scroll_to_top:before {
    margin: 0 !important;
  }

  [class*=" trx_addons_icon-"]:before {
    margin: 0;
    width: auto;
    font-size: inherit;
    line-height: inherit !important;
    font-weight: inherit;
  }

  .post_meta_item {
    margin-left: 0.5em;
    white-space: nowrap;
  }

  .post_meta_item:before {
    vertical-align: top;
    line-height: inherit;
    margin: 0 0.3em 0 0;
  }

  .trx_addons_columns_wrap {
    margin-left: 0px;
    margin-right: -30px;
  }

  .trx_addons_columns_wrap > [class*="trx_addons_column-"] {
    display: inline-block;
    vertical-align: top;
    position: relative;
    min-height: 1px;
    padding-left: 0px;
    padding-right: 30px;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .trx_addons_columns_wrap.columns_padding_bottom > [class*="trx_addons_column-"] {
    padding-bottom: 30px;
  }

  .trx_addons_column-1_2 {
    width: 50%;
  }

  .trx_addons_columns_wrap:after {
    content: " ";
    clear: both;
    width: 100%;
    height: 0;
    display: block;
  }

  .sc_align_center {
    text-align: center;
  }

  .sc_item_title,
  .sc_item_button {
    margin: 0;
  }

  .sc_item_title {
    line-height: 1.2em;
  }

  .sc_item_subtitle {
    font-size: 14px;
    text-transform: uppercase;
    line-height: 1.2em;
    letter-spacing: 0;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .sc_item_button {
    line-height: 0;
  }

  .sc_item_title.sc_item_title_style_accent {
    position: relative;
  }

  .sc_item_title + .sc_item_button {
    margin-top: 2em;
  }

  .sc_item_featured {
    position: relative;
  }

  .elementor-container:not(.elementor-column-gap-no)
    > .elementor-row
    > .elementor-column
    > .elementor-element-populated {
    padding-top: 0;
    padding-bottom: 0;
  }

  .elementor-section {
    position: relative;
  }

  .elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(
      .elementor-absolute
    ):not(.sc_layouts_item) {
    margin-bottom: 0;
  }

  .elementor-widget-text-editor:last-child,
  .elementor-widget-text-editor p:last-child {
    margin-bottom: 0;
  }

  .sc_layouts_row {
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .sc_layouts_row .sc_layouts_item {
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
  }

  .sc_layouts_item {
    max-width: 100%;
  }

  .sc_layouts_column .sc_layouts_item {
    display: inline-block;
    vertical-align: middle;
    margin-right: 1.5em;
    width: auto !important;
  }

  .sc_layouts_column .sc_layouts_item:last-child {
    margin-right: 0 !important;
  }

  .sc_layouts_column_align_right .sc_layouts_item {
    margin-left: 1.5em;
    margin-right: 0;
  }

  .sc_layouts_column_align_right .sc_layouts_item:first-child {
    margin-left: 0 !important;
  }

  .sc_layouts_column_align_left {
    text-align: left;
  }

  .sc_layouts_column_align_right,
  .sc_layouts_column.sc_layouts_column_align_right .widget,
  .sc_layouts_column.sc_layouts_column_align_right .widget_title {
    text-align: right;
  }

  .sc_layouts_column_align_left > .elementor-column-wrap > .elementor-widget-wrap {
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .sc_layouts_column_align_right > .elementor-column-wrap > .elementor-widget-wrap {
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .elementor-section-content-middle .elementor-widget-wrap {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .elementor-section-content-bottom .elementor-widget-wrap {
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .sc_layouts_iconed_text {
    white-space: nowrap;
  }

  .sc_layouts_item_link {
    display: block;
  }

  .sc_layouts_item_icon {
    font-size: 2.25em;
    line-height: 1.1em;
    display: inline-block;
    vertical-align: middle;
    -webkit-transition: color 0.3s ease;
    -ms-transition: color 0.3s ease;
    transition: color 0.3s ease;
  }

  .sc_layouts_row_type_normal .sc_layouts_item {
    letter-spacing: 0;
  }

  .sc_layouts_row_type_normal {
    padding: 2em 0;
  }

  .sc_layouts_row_fixed_placeholder {
    display: none;
  }

  .sc_blogger {
    position: relative;
    padding-top: 0.0001px;
  }

  .sc_blogger_columns_wrap {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .sc_blogger_slider .sc_blogger_item,
  .sc_blogger_columns_wrap .sc_blogger_item {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 100%;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .sc_blogger_slider .sc_blogger_item {
    margin-bottom: 0 !important;
  }

  .sc_blogger .sc_blogger_item_title {
    margin: 0;
  }

  .sc_blogger .sc_blogger_item_featured {
    margin-bottom: 0;
  }

  .sc_blogger .sc_blogger_item_featured[class*="hover_"] {
    display: block;
  }

  .sc_blogger .sc_blogger_item_featured img {
    width: 100%;
    height: auto;
  }

  .sc_blogger .sc_blogger_item_title + .sc_blogger_item_excerpt {
    margin-top: 1em;
  }

  .sc_blogger_item_default.sc_blogger_item_image_position_top .sc_blogger_item_featured + .sc_blogger_item_content {
    margin-top: 1em;
  }

  .sc_button_wrap:not([class*="sc_align_"]) {
    display: inline-block;
  }

  .sc_button {
    display: inline-block;
    text-transform: uppercase;
    white-space: nowrap;
    padding: 1.5em 3em;
    background-position: center center;
    background-repeat: repeat;
    overflow: hidden;
    background-color: #efa758;
    color: #fff;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .sc_button:hover {
    background-color: #007fc5;
  }

  .sc_button_text {
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    text-decoration: inherit;
  }

  .sc_inner_width_none > .elementor-column-wrap {
    width: 100%;
  }

  .sc_title {
    position: relative;
  }

  .widget_banner .image_wrap {
    overflow: hidden;
    display: block;
    margin-bottom: 1em;
  }

  .widget_banner .image_wrap img {
    width: 100%;
    height: auto;
  }

  .sc_recent_news .post_item {
    background-color: transparent;
    text-align: left;
    overflow: hidden;
    position: relative;
  }

  .sc_recent_news .post_item .post_featured {
    margin-bottom: 0;
  }

  .sc_recent_news .post_item .post_featured img {
    width: 100%;
  }

  .sc_recent_news .post_item .post_header {
    text-align: left;
    margin-bottom: 0;
  }

  .sc_recent_news .post_item .post_title {
    margin: 0;
    letter-spacing: 0;
    line-height: 1.1em;
    font-weight: 400;
  }

  .sc_recent_news .post_item .post_meta {
    margin: 0.2em 0 1em !important;
  }

  .sc_recent_news .post_item .post_date {
    display: inline-block;
    font-size: 13px;
    line-height: 1.5em;
  }

  .sc_recent_news .post_item .post_date:before {
    content: "|";
    display: inline-block;
    margin: 0 0.6em;
    font-size: 0.8462em;
    position: relative;
    top: -1px;
  }

  .sc_recent_news .post_item .post_content {
    margin-top: 0.8em;
  }

  .sc_recent_news_style_news-excerpt {
    overflow: hidden;
  }

  .sc_recent_news_style_news-excerpt .post_item + .post_item {
    padding-top: 2em;
    margin-top: 2em;
    border-top: 1px solid #eee;
  }

  .sc_recent_news_style_news-excerpt .post_featured {
    float: left;
    width: 40%;
    margin-bottom: 0;
  }

  .sc_recent_news_style_news-excerpt .post_body {
    overflow: hidden;
    float: left;
    width: 60%;
    padding-left: 2em;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .slider_container {
    display: none;
    position: relative;
    overflow: hidden;
  }

  .slider_outer {
    position: relative;
  }

  .slider_container .slider-slide {
    position: relative;
    max-height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .slider_container:not(.slider_height_fixed) .slider-slide {
    height: auto;
  }

  .slider_container:not(.slider_multi):not(.slider_type_images),
  .slider_container:not(.slider_multi):not(.slider_type_images) .slider-slide {
    min-height: 180px;
  }

  .slider_container.slider_type_images .slider-slide {
    text-align: center;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .slider_container.slider_type_images .slider-slide > img {
    max-width: 100%;
    height: auto;
  }

  .sc_slider_controls .slider_controls_wrap {
    position: relative;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .sc_slider_controls.sc_align_center .slider_controls_wrap {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .sc_slider_controls .slider_controls_wrap .slider_pagination_wrap {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    text-align: center;
    height: 2.8em;
  }

  .sc_slider_controls.slider_pagination_style_bullets .slider_pagination_bullet {
    display: inline-block;
    vertical-align: top;
    border: 2px solid #f0f0f0;
    background-color: transparent;
    width: 10px;
    height: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;
    cursor: pointer;
  }

  .sc_slider_controls.slider_pagination_style_bullets .slider_pagination_bullet + .slider_pagination_bullet {
    margin-left: 4px;
  }

  .sc_layouts_logo img {
    max-height: 6em;
    width: auto;
  }

  .sc_layouts_menu_mobile_button {
    display: none !important;
  }

  .sc_layouts_menu_mobile_button .sc_layouts_item_icon,
  .sc_layouts_menu_mobile_button_burger .sc_layouts_item_icon {
    font-size: 2em;
  }

  .sc_layouts_menu_mobile_button .sc_layouts_item_icon:before,
  .sc_layouts_menu_mobile_button_burger .sc_layouts_item_icon:before {
    line-height: 1.2em;
  }

  .sc_layouts_menu {
    position: relative;
    z-index: 1000;
    overflow: hidden;
    visibility: hidden;
    max-height: 3em;
  }

  .sc_layouts_menu.inited {
    overflow: visible;
    visibility: visible;
    max-height: none;
  }

  .sc_layouts_menu_nav,
  .sc_layouts_menu_nav ul {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style-type: none !important;
  }

  .sc_layouts_menu_nav:after,
  .sc_layouts_menu_nav ul:after {
    content: " ";
    clear: both;
    width: 100%;
    height: 0;
    display: block;
  }

  .sc_layouts_menu_nav li {
    margin: 0;
    position: relative;
  }

  .sc_layouts_menu_nav li > a {
    display: block;
    position: relative;
  }

  .sc_layouts_menu_nav {
    text-align: left;
    vertical-align: middle;
    padding-left: 0 !important;
  }

  .sc_layouts_menu_nav > li {
    position: relative;
    float: left;
    z-index: 20;
  }

  .sc_layouts_menu_nav > li + li {
    z-index: 19;
  }

  .sc_layouts_menu_nav > li + li + li {
    z-index: 18;
  }

  .sc_layouts_menu_nav > li + li + li + li {
    z-index: 17;
  }

  .sc_layouts_menu_nav > li > a {
    padding: 1em;
    position: relative;
    color: #333;
    -webkit-transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
    -ms-transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
    transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
  }

  .sc_layouts_menu_nav > li > a:hover,
  .sc_layouts_menu_nav > li.current-menu-parent > a,
  .sc_layouts_menu_nav > li.current-menu-ancestor > a {
    color: #efa758;
  }

  .sc_layouts_menu_nav > li.current-menu-parent > a,
  .sc_layouts_menu_nav > li.current-menu-ancestor > a {
    font-weight: 600 !important;
  }

  .sc_layouts_menu_nav > li.menu-item-has-children > a {
    padding-right: 2em;
  }

  .sc_layouts_menu_nav > li.menu-item-has-children > a:after {
    content: "\e882";
    font-family: "trx_addons_icons";
    font-weight: normal;
    display: inline-block;
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 1em;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: color 0.3s ease;
    -ms-transition: color 0.3s ease;
    transition: color 0.3s ease;
  }

  .sc_layouts_menu_nav li li.menu-item-has-children > a:after {
    content: "\e884";
    font-family: "trx_addons_icons";
    font-weight: normal;
    position: absolute;
    z-index: 1;
    top: 0.65em;
    right: 0.75em;
    -webkit-transition: color 0.3s ease;
    -ms-transition: color 0.3s ease;
    transition: color 0.3s ease;
  }

  .sc_layouts_menu_nav > li ul {
    position: absolute;
    display: none;
    z-index: 10000;
    width: 13em;
    padding: 1.4em 0;
    text-align: left;
    background-color: #f0f0f0;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.2);
  }

  .sc_layouts_menu_nav > li li > a {
    padding: 0.6em 1.5em;
    color: #333;
  }

  .sc_layouts_menu_nav > li li > a:hover,
  .sc_layouts_menu_nav > li li.current-menu-item > a {
    color: #efa758;
  }

  .sc_layouts_menu_nav > li li.current-menu-item:before {
    color: #efa758;
  }

  .sc_layouts_menu_nav > li > ul {
    top: 4.2em;
    left: 0;
  }

  .sc_layouts_menu_nav > li > ul ul {
    left: 13em;
    top: -1.4em;
    margin: 0 0 0 2px;
    background-color: #f0f0f0;
  }

  .sc_layouts_menu_mobile_button_burger {
    position: relative;
  }

  .search_wrap,
  .search_wrap .search_form {
    position: relative;
  }

  .search_wrap .search_field {
    width: 6em;
    padding: 0 0 0 1.25em !important;
    vertical-align: middle;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .search_wrap .search_submit {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    background-color: transparent;
    padding: 0;
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
  }

  .search_wrap .search_submit:before {
    margin: 0;
  }

  .sc_layouts_row_type_normal .search_wrap .search_field {
    font-size: 11px;
    text-transform: uppercase;
    padding: 0 1.5em 0 3em !important;
    width: 15em;
    height: 4.7727em;
    line-height: 4.7727em;
    -webkit-border-radius: 3em;
    -ms-border-radius: 3em;
    border-radius: 3em;
  }

  .sc_layouts_row_type_normal .search_wrap .search_submit {
    display: block;
    font-size: 11px;
    text-align: center;
    width: 1em;
    height: 4.7727em;
    line-height: 4.7727em;
    position: absolute;
    top: 0;
    right: auto;
    bottom: auto;
    left: 1em;
  }

  .sc_layouts_row_type_normal .search_wrap .search_submit:before {
    font-size: 13px;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/trx_addons/css/trx_addons.animation.css ; media=all */
@media all {
  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: visible;
  }

  .animated.fast {
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
  }

  .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }

  .fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
  }

  .fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
  }

  .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/elementor/assets/lib/animations/animations.min.css?ver=2.5.15 ; media=all */
@media all {
  .fadeIn {
    animation-name: fadeIn;
  }

  .fadeInLeft {
    animation-name: fadeInLeft;
  }

  .fadeInRight {
    animation-name: fadeInRight;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/elementor/assets/css/frontend.min.css?ver=2.5.15 ; media=all */
@media all {
  .elementor-screen-only {
    position: absolute;
    top: -10000em;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .elementor-clearfix:after {
    content: "";
    display: block;
    clear: both;
    width: 0;
    height: 0;
  }

  .elementor {
    -webkit-hyphens: manual;
    -ms-hyphens: manual;
    hyphens: manual;
  }

  .elementor *,
  .elementor :after,
  .elementor :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .elementor a {
    -webkit-box-shadow: none;
    box-shadow: none;
    text-decoration: none;
  }

  .elementor img {
    height: auto;
    max-width: 100%;
    border: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .elementor-invisible {
    visibility: hidden;
  }

  .elementor-section {
    position: relative;
  }

  .elementor-section .elementor-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative;
  }

  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 1140px;
  }

  .elementor-section.elementor-section-stretched {
    position: relative;
    width: 100%;
  }

  .elementor-section-content-middle
    > .elementor-container
    > .elementor-row
    > .elementor-column
    > .elementor-column-wrap {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .elementor-section-content-bottom
    > .elementor-container
    > .elementor-row
    > .elementor-column
    > .elementor-column-wrap {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .elementor-row {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  @media (max-width: 1024px) {
    .elementor-row {
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
  }

  .elementor-column-wrap {
    width: 100%;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .elementor-widget-wrap {
    position: relative;
    width: 100%;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }

  .elementor:not(.elementor-bc-flex-widget) .elementor-widget-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .elementor-widget-wrap > .elementor-element {
    width: 100%;
  }

  .elementor-widget {
    position: relative;
  }

  .elementor-widget:not(:last-child) {
    margin-bottom: 20px;
  }

  .elementor-column {
    position: relative;
    min-height: 1px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
    padding: 15px;
  }

  .elementor-inner-section .elementor-column-gap-no .elementor-element-populated {
    padding: 0;
  }

  @media (min-width: 768px) {
    .elementor-column.elementor-col-33 {
      width: 33.333%;
    }

    .elementor-column.elementor-col-50 {
      width: 50%;
    }

    .elementor-column.elementor-col-66 {
      width: 66.666%;
    }

    .elementor-column.elementor-col-100 {
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    .elementor-column {
      width: 100%;
    }
  }

  @media (min-width: 1025px) {
    #elementor-device-mode:after {
      content: "desktop";
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    #elementor-device-mode:after {
      content: "tablet";
    }
  }

  @media (max-width: 767px) {
    #elementor-device-mode:after {
      content: "mobile";
    }
  }

  .elementor-element .elementor-widget-container {
    -webkit-transition:
      background 0.3s,
      border 0.3s,
      -webkit-border-radius 0.3s,
      -webkit-box-shadow 0.3s;
    transition:
      background 0.3s,
      border 0.3s,
      -webkit-border-radius 0.3s,
      -webkit-box-shadow 0.3s;
    -o-transition:
      background 0.3s,
      border 0.3s,
      border-radius 0.3s,
      box-shadow 0.3s;
    transition:
      background 0.3s,
      border 0.3s,
      border-radius 0.3s,
      box-shadow 0.3s;
    transition:
      background 0.3s,
      border 0.3s,
      border-radius 0.3s,
      box-shadow 0.3s,
      -webkit-border-radius 0.3s,
      -webkit-box-shadow 0.3s;
  }

  .elementor-widget-divider .elementor-divider {
    line-height: 0;
    font-size: 0;
  }

  .elementor-widget-divider .elementor-divider-separator {
    display: inline-block;
  }

  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property:
      transform,
      -webkit-transform;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }

  .swiper-wrapper {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  .swiper-slide {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
  }

  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    display: inline-block;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #000;
    opacity: 0.2;
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
  }

  .animated {
    -webkit-animation-duration: 1.25s;
    animation-duration: 1.25s;
  }
}

/*! CSS Used from: Embedded */
.elementor-section.elementor-section-boxed > .elementor-container {
  max-width: 1308px;
}

.elementor-widget:not(:last-child) {
  margin-bottom: 0px;
}

.elementor-598 .elementor-element.elementor-element-99e1259:not(.elementor-motion-effects-element-type-background) {
  background-image: url(../../assets/images/header1.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

// .scheme-light {
//   .elementor-598 .elementor-element.elementor-element-99e1259:not(.elementor-motion-effects-element-type-background) {
//     background-position: top center;
//   }
// }

.elementor-598 .elementor-element.elementor-element-99e1259 {
  transition:
    background 0.3s,
    border 0.3s,
    border-radius 0.3s,
    box-shadow 0.3s;
}

.elementor-598 .elementor-element.elementor-element-390f28f {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-598 .elementor-element.elementor-element-49158eb .elementor-spacer-inner {
  height: 450px;
}

.elementor-598 .elementor-element.elementor-element-41a5bd6 .elementor-spacer-inner {
  height: 300px;
}

.elementor-598 .elementor-element.elementor-element-223582c {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-598 .elementor-element.elementor-element-d2d2405 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-598 .elementor-element.elementor-element-62e51d1 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-598 .elementor-element.elementor-element-d52953c {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-598 .elementor-element.elementor-element-6b56955 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-598 .elementor-element.elementor-element-92a0b0a .elementor-spacer-inner {
  height: 30px;
}

.elementor-598 .elementor-element.elementor-element-dbdd0e4 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-598 .elementor-element.elementor-element-c793c8d > .elementor-widget-container {
  padding: 30px 30px 35px 30px;
  background-color: #ffffff;
  border-radius: 0px 0px 3px 3px;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.06);
}

.elementor-598 .elementor-element.elementor-element-2b5b5cf .elementor-spacer-inner {
  height: 30px;
}

.elementor-598 .elementor-element.elementor-element-b19b114:not(.elementor-motion-effects-element-type-background) {
  background-color: #ffffff;
}

.elementor-598 .elementor-element.elementor-element-b19b114 {
  border-radius: 3px 3px 3px 3px;
}

.elementor-598 .elementor-element.elementor-element-b19b114 {
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.06);
  transition:
    background 0.3s,
    border 0.3s,
    border-radius 0.3s,
    box-shadow 0.3s;
}

.elementor-598 .elementor-element.elementor-element-080ea4d {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-598 .elementor-element.elementor-element-ee6c397 > .elementor-widget-container {
  margin: -2px 0px 0px 0px;
  padding: 0px 0px 25px 0px;
  background-color: #ffffff;
}

.elementor-598 .elementor-element.elementor-element-5004f3a .elementor-spacer-inner {
  height: 30px;
}

.elementor-598 .elementor-element.elementor-element-b71eb6c > .elementor-widget-container {
  padding: 30px 30px 16px 30px;
  background-color: #ffffff;
  border-radius: 00px 00px 3px 3px;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.06);
}

.elementor-598 .elementor-element.elementor-element-6fbc401 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-598 .elementor-element.elementor-element-4000484 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-598 .elementor-element.elementor-element-bce779f .elementor-spacer-inner {
  height: 10px;
}

@media (min-width: 768px) {
  .elementor-598 .elementor-element.elementor-element-390f28f {
    width: 75%;
  }

  .elementor-598 .elementor-element.elementor-element-223582c {
    width: 48.089%;
  }

  .elementor-598 .elementor-element.elementor-element-dbdd0e4 {
    width: 65%;
  }

  .elementor-598 .elementor-element.elementor-element-6fbc401 {
    width: 34.997%;
  }
}

@media (max-width: 1024px) {
  .elementor-598 .elementor-element.elementor-element-99e1259 {
    padding: 0px 0px 40px 0px;
  }

  .elementor-598 .elementor-element.elementor-element-41a5bd6 .elementor-spacer-inner {
    height: 100px;
  }

  .elementor-598 .elementor-element.elementor-element-c793c8d > .elementor-widget-container {
    padding: 20px 20px 25px 20px;
  }

  .elementor-598 .elementor-element.elementor-element-b71eb6c > .elementor-widget-container {
    padding: 20px 20px 10px 20px;
  }
}

@media (max-width: 767px) {
  .elementor-598 .elementor-element.elementor-element-99e1259 {
    padding: 0px 0px 20px 0px;
  }

  .elementor-598 .elementor-element.elementor-element-41a5bd6 .elementor-spacer-inner {
    height: 80px;
  }

  .elementor-598 .elementor-element.elementor-element-fc40494 > .elementor-widget-container {
    margin: 0px 0px 20px 0px;
  }

  .elementor-598 .elementor-element.elementor-element-92a0b0a .elementor-spacer-inner {
    height: 20px;
  }

  .elementor-598 .elementor-element.elementor-element-2b5b5cf .elementor-spacer-inner {
    height: 20px;
  }

  .elementor-598 .elementor-element.elementor-element-ee6c397 > .elementor-widget-container {
    padding: 0px 0px 12px 0px;
  }

  .elementor-598 .elementor-element.elementor-element-5004f3a .elementor-spacer-inner {
    height: 20px;
  }

  .elementor-598 .elementor-element.elementor-element-6fbc401 > .elementor-element-populated {
    margin: 20px 0px 0px 0px;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/font-icons/css/fontello.css ; media=all */
@media all {
  [class*=" icon-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: 0.2em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-cancel:before {
    content: "\e8ac";
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/style.css ; media=all */
@media all {
  body,
  div,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  pre,
  a,
  small,
  ul,
  li,
  form,
  label,
  input,
  textarea,
  button,
  table,
  tbody,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  section {
    font-family: inherit;
    font-size: 100%;
    line-height: inherit;
    font-weight: inherit;
    font-style: inherit;
    border: 0;
    outline: 0;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
  }

  body {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  article,
  aside,
  footer,
  header,
  nav,
  section {
    display: block;
  }

  li > ul {
    margin-bottom: 0;
  }

  ul {
    padding-left: 1.5em;
  }

  a {
    text-decoration: none;
    background: transparent;
  }

  a,
  a:hover,
  a:focus,
  a:active {
    outline: 0;
  }

  a img {
    border: none;
  }

  a,
  button,
  input[type="submit"] {
    -webkit-transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
    -ms-transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
    transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
  }

  h3 > a,
  h4 > a,
  h5 > a {
    display: block;
  }

  :active,
  :focus {
    outline: 0;
  }

  table {
    border-collapse: collapse;
  }

  table th + th,
  table td + td {
    border-left: 2px solid #ddd;
  }

  table td,
  table th {
    padding: 1.95em 1.1em;
  }

  table th {
    text-transform: none;
    text-align: center;
  }

  pre {
    font-family: "Courier New", Courier, monospace;
    font-size: 1em;
    letter-spacing: 0;
  }

  pre {
    overflow: auto;
    max-width: 100%;
  }

  small {
    font-size: 80%;
  }

  img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
  }

  [class*=" icon-"]:before {
    font-size: inherit;
    line-height: inherit !important;
    font-weight: inherit;
    font-style: inherit;
    display: inline-block;
    vertical-align: top;
    width: auto;
    margin: 0;
  }

  form {
    margin-bottom: 0;
  }

  button,
  input,
  textarea {
    font-family: inherit;
    font-size: 1em;
    margin: 0;
    vertical-align: baseline;
  }

  button {
    overflow: visible;
  }

  input[placeholder]::-webkit-input-placeholder,
  textarea[placeholder]::-webkit-input-placeholder {
    text-overflow: ellipsis;
  }

  input[placeholder]::-moz-placeholder,
  textarea[placeholder]::-moz-placeholder {
    text-overflow: ellipsis;
  }

  input[placeholder]:-ms-input-placeholder,
  textarea[placeholder]:-ms-input-placeholder {
    text-overflow: ellipsis;
  }

  input[placeholder]::placeholder,
  textarea[placeholder]::placeholder {
    text-overflow: ellipsis;
  }

  input[placeholder],
  textarea[placeholder] {
    text-overflow: ellipsis;
  }

  textarea,
  input[type="text"],
  input[type="email"],
  input[type="checkbox"] {
    -webkit-transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
    -ms-transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
    transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
  }

  input[type="checkbox"] {
    padding: 0;
  }

  button::-moz-focus-inner,
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  html input[disabled] {
    cursor: default !important;
  }

  button:focus,
  input[type="text"]:focus,
  input[type="email"]:focus,
  textarea:focus {
    outline: none;
  }

  input[type="text"],
  input[type="email"],
  textarea {
    -webkit-appearance: none;
    outline: none;
    resize: none;
  }

  form button,
  input[type="submit"],
  .mc4wp-form .mc4wp-form-fields input[type="submit"],
  .sc_button,
  .sp-view-all-link > a {
    -webkit-appearance: none;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    text-transform: none;
    white-space: nowrap;
    padding: 1.38em 3.5em;
    letter-spacing: 0;
    height: auto;
    border-width: 0 !important;
    -webkit-border-radius: 30px;
    -ms-border-radius: 30px;
    border-radius: 30px;
  }

  input[type="text"],
  input[type="email"],
  textarea,
  form.mc4wp-form .mc4wp-form-fields input[type="email"] {
    padding: 0.94em 1.5em;
    border: 2px solid #ddd;
    -webkit-border-radius: 28px;
    -ms-border-radius: 28px;
    border-radius: 28px;
  }

  textarea {
    overflow: auto;
    vertical-align: top;
    min-height: 10em;
  }

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] + label {
    position: relative;
    padding-left: 1.4em;
    line-height: 1.5em;
    display: inline-block;
    vertical-align: top;
  }

  input[type="checkbox"] + label:before {
    content: " ";
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
    font-size: 1em;
    display: block;
    text-align: center;
    border: 2px solid #e4e4e4;
    width: 1em;
    height: 1em;
    line-height: 0.53em;
    position: absolute;
    z-index: 1;
    top: 0.2em;
    left: 0;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  input[type="checkbox"]:checked + label:before {
    content: "\F00C";
  }

  .page_wrap {
    min-height: 100vh;
    overflow: hidden;
  }

  .page_wrap,
  .content_wrap {
    margin: 0 auto;
  }

  .page_content_wrap {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .remove_margins .page_content_wrap {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .content_wrap {
    width: 1278px;
    margin: 0 auto;
  }

  .content_wrap:after {
    content: " ";
    clear: both;
    width: 100%;
    height: 0;
    display: block;
  }

  .content {
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .page_content_wrap .content_wrap {
    position: relative;
  }

  .sidebar_hide [class*="content_wrap"] > .content {
    margin-left: auto;
    margin-right: auto;
  }

  .top_panel {
    position: relative;
    z-index: 999;
    padding: 0.01px 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .sc_layouts_menu_mobile_button .sc_layouts_item_icon:before {
    content: "\e8ba";
    font-family: "fontello";
  }

  .menu_mobile_close {
    display: block;
    cursor: pointer;
    width: 5em;
    height: 5em;
    line-height: 5em;
    text-align: center;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
  }

  .menu_mobile_close:before,
  .menu_mobile_close:after {
    content: " ";
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 38%;
    width: 25%;
    height: 0;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    margin-top: -1px;
    border-top: 2px solid #fff;
  }

  .menu_mobile_close:before {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .menu_mobile_close:after {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .menu_mobile_overlay {
    display: none !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 100000;
  }

  .menu_mobile {
    position: fixed;
    z-index: 100002;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    -webkit-transition: height 0.3s ease;
    -ms-transition: height 0.3s ease;
    transition: height 0.3s ease;
  }

  .menu_mobile_inner {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    overflow: hidden;
  }

  .menu_mobile li > a {
    display: block;
  }

  .menu_mobile .sc_layouts_logo {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    margin: 4.4rem 0 0;
    font-size: 1em !important;
  }

  .menu_mobile .menu_mobile_nav_area {
    position: absolute;
    z-index: 1;
    top: 48%;
    left: 0;
    width: 100%;
    height: auto;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    max-height: 49%;
    padding-right: 20px;
    overflow-y: scroll;
  }

  .menu_mobile .menu_mobile_nav_area ul {
    margin: 0 auto;
    padding: 0;
    list-style: none;
    width: auto;
  }

  .menu_mobile .menu_mobile_nav_area ul ul {
    margin: 5px 0;
    display: none;
  }

  .menu_mobile .menu_mobile_nav_area li {
    margin-bottom: 0;
    width: auto;
  }

  .menu_mobile .menu_mobile_nav_area li:before {
    display: none;
  }

  .menu_mobile .menu_mobile_nav_area li > a {
    font-size: 1.25em;
    line-height: 1.2em;
    padding: 1px 2.5em 1px 0;
    text-transform: none;
    letter-spacing: 0.5px;
    position: relative;
    display: inline-block;
    width: 28rem;
    text-align: left;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    font-weight: 500;
  }

  .menu_mobile .menu_mobile_nav_area > ul > li > a {
    font-size: 20px;
    line-height: 1.2em;
    padding: 5px 0;
    padding-right: 30px;
  }

  .menu_mobile .menu_mobile_nav_area > ul > li li > a {
    padding-left: 1em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
  }

  .menu_mobile .menu_mobile_nav_area > ul > li li li > a {
    padding-left: 2em;
  }

  .menu_mobile .menu_mobile_nav_area > ul > li li li li > a {
    padding-left: 3em;
  }

  .menu_mobile .menu_mobile_nav_area .open_child_menu {
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    width: 1.3rem;
    height: 1.3rem;
    line-height: 1.3rem;
    text-align: center;
    padding: 0.35rem;
    background-color: rgba(255, 255, 255, 0.1);
    -webkit-transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
    -ms-transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
    transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    top: 5px;
  }

  .menu_mobile .menu_mobile_nav_area li li .open_child_menu {
    background-color: transparent;
    padding: 0.35rem;
    top: 2px;
  }

  .menu_mobile .menu_mobile_nav_area a:hover > .open_child_menu {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .menu_mobile .menu_mobile_nav_area .open_child_menu:before {
    font-family: "fontello";
    content: "\e828";
  }

  .menu_mobile .sc_layouts_logo img {
    max-height: 5em;
  }

  .menu_mobile .search_mobile {
    position: absolute;
    z-index: 1;
    bottom: 8rem;
    left: 50%;
    width: 28rem;
    margin-left: -14.5rem;
  }

  .menu_mobile .search_mobile form {
    position: relative;
  }

  .menu_mobile .search_mobile .search_field {
    width: 100%;
    padding: 1em 3em 1em 1.5em !important;
    border: none;
  }

  .menu_mobile .search_mobile .search_submit {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
    font-size: 14px;
    padding: 0.9em 1.5em;
    background-color: transparent;
  }

  .post_item p {
    margin-bottom: 0;
  }

  .page_content_wrap {
    position: relative;
  }

  .post_featured {
    overflow: hidden;
    position: relative;
    margin-bottom: 1.8em;
  }

  .post_meta .post_meta_item {
    font-size: inherit;
    margin-left: 0;
    white-space: normal;
    -webkit-transition: color 0.3s ease;
    -ms-transition: color 0.3s ease;
    transition: color 0.3s ease;
  }

  .post_meta .post_meta_item:after {
    content: "";
    display: inline;
    font-family: inherit;
    font-size: inherit;
    font-weight: 400;
    font-style: normal;
    vertical-align: baseline;
    margin: 0 0.4em;
  }

  .post_meta .post_meta_item:last-child:after {
    display: none;
  }

  .post_meta_item.post_categories a {
    padding: 5px 16px;
    -webkit-border-radius: 15px;
    -ms-border-radius: 15px;
    border-radius: 15px;
    margin-right: 3px;
  }

  .post_meta_item.post_categories a:last-child {
    margin-right: 0;
  }

  .post_meta_item.post_categories {
    margin-left: -3px;
  }

  .post_featured .mask {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .format-video .post_featured.with_thumb {
    position: relative;
  }

  .format-video .post_featured.with_thumb .post_video {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    text-align: center;
    opacity: 0;
    overflow: hidden;
  }

  .format-video .post_featured.with_thumb .post_video:before {
    content: " ";
    display: inline-block;
    padding-top: 55%;
    width: 0;
    height: 0;
  }

  .format-video .post_featured.with_thumb img {
    position: relative;
    z-index: 2;
  }

  .format-video .post_featured.with_thumb .mask {
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 0;
  }

  .format-video .post_featured.with_thumb:hover .mask {
    opacity: 1;
  }

  .format-video .post_featured.with_thumb .post_video_hover {
    width: 56px;
    height: 56px;
    line-height: 56px;
    text-align: center;
    -webkit-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    position: absolute;
    z-index: 20;
    top: 50%;
    left: 50%;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    margin: -28px 0 0 -28px;
    font-size: 16px;
    opacity: 1;
    cursor: pointer;
    overflow: hidden;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.3);
    border: none;
    text-indent: 4px;
  }

  .format-video .post_featured.with_thumb:hover .post_video_hover {
    opacity: 1;
    margin-top: -28px;
  }

  .format-video .post_featured.with_thumb .post_video_hover:before {
    content: "\e966";
    font-family: "fontello";
  }

  .post_item_single .post_title {
    margin-top: -0.2em;
  }

  .sc_layouts_widgets .widget {
    padding: 30px;
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.07);
    -ms-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.07);
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.07);
    overflow: hidden;
  }

  .sc_layouts_widgets .widget_area_inner > div + div,
  .sc_layouts_widgets .widget_area_inner > div + aside,
  .sc_layouts_widgets .widget_area_inner > aside + aside {
    margin-top: 30px;
  }

  .widget p {
    margin: 0;
  }

  .sc_layouts_widgets .widget .widget_title,
  .widget .widget_title {
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
    letter-spacing: 2.2px;
    padding: 16px 23px;
    border-left: 7px solid red;
  }

  .sc_layouts_widgets .widget .widget_title {
    margin-left: -30px;
    margin-right: -30px;
    margin-top: -30px;
  }

  .widget_banner .image_wrap {
    margin: 0;
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }

  #sb_instagram .sb_instagram_header {
    padding: 0 !important;
    margin-bottom: 0.5em !important;
  }

  #sb_instagram #sbi_load {
    margin-top: 0.5em;
  }

  .footer_wrap p {
    margin: 0;
  }

  .footer_wrap .sc_layouts_menu > ul > li + li:before {
    display: none;
  }

  .footer_wrap .sc_layouts_menu > ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  .footer_wrap .sc_layouts_menu > ul > li {
    display: inline-block;
    vertical-align: baseline;
    font-size: 16px;
    z-index: auto !important;
    font-weight: 500;
  }

  .footer_wrap .sc_layouts_menu > ul > li + li:before {
    content: " ";
    display: inline-block;
    width: 0;
    height: 0.8em;
    margin: 0;
    vertical-align: baseline;
    border-left: 1px dotted #ddd;
  }

  .footer_wrap .sc_layouts_menu > ul > li > a {
    padding: 0.5em 1em;
    display: inline-block;
  }

  .footer_wrap .sc_layouts_column_align_left .sc_layouts_menu > ul > li:first-child > a {
    padding-left: 0;
  }

  .sc_layouts_row_type_normal {
    padding: 1em 0;
  }

  .sc_layouts_column_align_right .sc_layouts_item {
    margin-left: 1.3em;
    margin-right: 0;
  }

  .sc_layouts_menu_mobile_button .sc_layouts_item_icon {
    font-size: 30px !important;
  }

  .sc_layouts_row_type_normal .sc_layouts_item_icon {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    -webkit-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    font-size: 14px;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .search_wrap .search_submit {
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .search_wrap .search_submit:before {
    content: "\e968";
    font-family: "fontello";
  }

  .sc_layouts_row_type_normal .search_wrap .search_field {
    font-size: 14px;
    font-weight: 400;
    text-transform: lowercase;
    padding: 0 60px 0 21px !important;
    width: 18em;
    height: 50px;
    line-height: 50px;
    border: none;
  }

  .sc_layouts_row_type_normal .search_wrap .search_submit {
    display: block;
    font-size: 11px;
    text-align: center;
    width: 60px;
    height: 50px;
    line-height: 50px;
    position: absolute;
    top: 0;
    bottom: auto;
    left: auto;
    right: 0;
  }

  .sc_layouts_row_type_normal .search_wrap .search_submit:before {
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .top_panel:not(.scheme_dark) .sc_layouts_row_type_normal:not(.scheme_dark) .search_wrap .search_submit:before {
    color: #797e87 !important;
  }

  .top_panel:not(.scheme_dark) .sc_layouts_row_type_normal:not(.scheme_dark) .search_wrap .search_submit:hover:before {
    color: #262f3e !important;
  }

  .sc_layouts_menu_nav li {
    text-align: center;
  }

  .sc_layouts_menu_nav > li > ul:after {
    content: " ";
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 50%;
    width: 12px;
    height: 12px;
    margin: -6px 0 0 -6px;
    background-color: #fff;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .sc_layouts_menu_nav li li.menu-item-has-children > a:after,
  .sc_layouts_menu_nav > li.menu-item-has-children > a:after {
    display: none;
  }

  .sc_layouts_menu_nav > li > a,
  .sc_layouts_menu_nav > li.menu-item-has-children > a {
    padding: 0.85em 1.3em;
  }

  .sc_layouts_menu_nav > li > ul {
    top: 4em;
    left: 50%;
    margin-left: -3em;
  }

  .sc_layouts_menu_nav > li ul {
    width: 10.8em;
    padding: 1.8em 0;
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }

  .sc_layouts_menu_nav > li > ul ul {
    left: 10.8em;
    top: -1.8em;
    margin: 0 0 0 2px;
  }

  .sc_layouts_menu_nav > li li > a {
    padding: 0.27em 1em;
  }

  .sc_layouts_menu_nav > li ul {
    -webkit-box-shadow: none;
    -ms-box-shadow: none;
    box-shadow: none;
  }

  .sc_layouts_menu_nav > li.current-menu-parent > a,
  .sc_layouts_menu_nav > li.current-menu-ancestor > a {
    font-weight: 500 !important;
  }

  .footer_wrap .sc_layouts_menu > ul > li + li:before {
    display: none;
  }

  #sb_instagram {
    padding-bottom: 0 !important;
  }

  .widget #sb_instagram #sbi_images {
    width: calc(100% + 8px);
    margin: 0 -4px;
    padding: 2px !important;
  }

  .widget #sbi_load {
    display: none;
  }

  .sc_blogger .sc_blogger_item_featured img,
  .sc_recent_news .post_item .post_featured img {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .sc_blogger .sc_blogger_item_featured:hover img,
  .sc_recent_news .post_item .post_featured:hover img {
    -webkit-transform: scale(1.02);
    -ms-transform: scale(1.02);
    transform: scale(1.02);
  }

  .box-style {
    overflow: hidden;
    -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.06);
    -ms-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.06);
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.06);
  }

  .copyright-text {
    font-size: 16px;
  }

  .med-title .sc_item_title {
    font-size: 72px;
  }

  .post_meta_item.post_categories {
    margin-left: 0;
    margin-top: -5px !important;
    display: inline-block;
    margin-bottom: -5px !important;
  }

  .post_meta_item.post_categories a {
    display: inline-block;
    line-height: 20px;
    margin-bottom: 3px;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__plugins.css ; media=all */
@media all {
  .post_featured[class*="hover_"] {
    position: relative;
    -webkit-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
    display: inline-block;
    vertical-align: top;
  }

  .post_featured[class*="hover_"],
  .post_featured[class*="hover_"] * {
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .post_featured .mask {
    content: " ";
    position: absolute;
    z-index: 4;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .post_featured.hover_simple:hover .mask {
    opacity: 1;
  }

  .post_featured.hover_simple .icons {
    position: absolute;
    z-index: 6;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .sc_slider_controls.slider_pagination_style_bullets .slider_pagination_bullet + .slider_pagination_bullet {
    margin-left: 12px;
  }

  .sc_slider_controls.slider_pagination_style_bullets .slider_pagination_bullet {
    width: 33px;
    height: 5px;
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    border: none;
    opacity: 1;
    vertical-align: middle;
  }

  .sc_widget_slider .slider_engine_swiper {
    overflow: hidden;
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }

  .footer_wrap .sc_layouts_item {
    vertical-align: top;
    margin-top: 0.25em !important;
    margin-bottom: 0.25em !important;
  }

  .footer_wrap .sc_layouts_item .widget {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .sc_blogger .post_featured {
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    position: relative;
    z-index: 1;
  }

  .sc_blogger_item_meta .post_meta_item.post_categories {
    margin-bottom: 0 !important;
    display: inline-block;
  }

  .sc_blogger_default .sc_blogger_item_title {
    font-size: 36px;
    line-height: 0.95em;
    letter-spacing: 1.4px;
  }

  .sc_blogger_default.sc_blogger_default_classic_meta .post_meta + .sc_blogger_item_title {
    margin-top: 7px;
  }

  .sc_blogger_item_default.sc_blogger_item_image_position_top .sc_blogger_item_featured + .sc_blogger_item_content {
    margin-top: 2.2em;
  }

  .sc_blogger .sc_blogger_item_title + .sc_blogger_item_excerpt {
    margin-top: 1.4em;
  }

  .sc_blogger_default.sc_blogger_default_classic_meta .sc_blogger_item_excerpt {
    padding-right: 30px;
  }

  .sc_blogger_default.sc_blogger_default_classic_meta_simple .sc_blogger_item_content {
    margin-top: -1px !important;
    text-align: center;
    padding: 40px 30px 15px;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .sc_blogger_default.sc_blogger_default_classic_meta_simple .post_featured {
    -webkit-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
  }

  .sc_blogger_default.sc_blogger_default_classic_meta_simple .post_meta {
    margin: 0 0 6px;
  }

  .scheme_dark.elementor-widget-trx_sc_slider_controls .sc_slider_controls span.swiper-pagination-bullet {
    background-color: #e6e6e6;
  }

  .sc_item_title {
    line-height: 0.94em;
    word-break: break-word;
  }

  .sc_title.sc_title_accent {
    margin-top: 0;
    margin-bottom: 0;
    padding: 16px 23px;
    border-left: 7px solid #ddd;
    -webkit-border-radius: 3px 3px 0 0;
    -ms-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
    overflow: hidden;
  }

  .sc_title.sc_title_accent .sc_item_title {
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
    letter-spacing: 2.2px;
  }

  .trx_addons_scroll_to_top {
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }

  .sc_recent_news .post_item .post_date:before {
    content: "";
  }

  .sc_recent_news .post_item .post_title {
    font-weight: 700;
  }

  .sc_recent_news_style_news-excerpt .post_featured {
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    overflow: hidden;
    z-index: 1;
  }

  .sc_recent_news_style_news-excerpt .post_featured {
    float: left;
    width: 100%;
    margin-bottom: 0;
    max-width: 260px;
  }

  .sc_recent_news_style_news-excerpt .post_item .post_meta {
    margin: 5px 0 0.9em !important;
  }

  .sc_recent_news_style_news-excerpt .post_item .post_title {
    font-weight: 700;
    font-size: 30px;
    letter-spacing: 1.2px;
    line-height: 1.1em;
  }

  .sc_recent_news_style_news-excerpt .post_item .post_header .post_categories {
    font-size: 13px;
    line-height: inherit;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 0;
    position: relative;
    padding-right: 0;
    text-transform: none;
  }

  .sc_recent_news_style_news-excerpt .post_item .post_header + .post_content {
    margin-top: 1.2em;
  }

  .sc_recent_news_style_news-excerpt .post_body {
    width: calc(100% - 260px);
    padding: 22px 60px 10px 35px;
  }

  .sc_recent_news_style_news-excerpt .post_item + .post_item {
    padding-top: 0;
    margin-top: 30px;
    border-top: none;
  }

  .sc_recent_news_style_news-excerpt .post_item .post_header .post_date:before {
    display: none;
  }

  .post_content > .elementor {
    margin-left: -15px;
    margin-right: -15px;
  }

  .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
    padding: 15px;
  }

  form.mc4wp-form {
    position: relative;
    padding-right: 0;
  }

  form.mc4wp-form .mc4wp-form-fields input[type="email"] {
    width: 100%;
    vertical-align: top;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    height: 50px;
    line-height: 50px;
    border: none;
    -webkit-border-radius: 30px;
    -ms-border-radius: 30px;
    border-radius: 30px;
    padding: 0 10em 0 2.2em;
  }

  form.mc4wp-form .mc4wp-form-fields input[type="submit"] {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    height: 50px;
    line-height: 50px;
    min-width: 9.5em;
    vertical-align: top;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0;
    padding-bottom: 0;
    -webkit-border-radius: 30px;
    -ms-border-radius: 30px;
    border-radius: 30px;
    text-align: center;
  }

  form.mc4wp-form label {
    margin-top: 10px;
  }

  .sp-template a:hover,
  .sp-data-table a:hover {
    text-decoration: none !important;
  }

  .sp-template img {
    vertical-align: middle;
  }

  .sp-template {
    margin-bottom: 30px !important;
  }

  .sp-table-caption {
    margin: 0 0 30px !important;
    padding: 0 !important;
    background: transparent !important;
    border: none !important;
    font-size: 48px !important;
    font-weight: 700 !important;
    line-height: 1em !important;
    display: block !important;
    width: 100% !important;
  }

  .sp-template h3,
  .sp-template h4,
  .sp-template h5,
  .sp-data-table h4,
  .sp-data-table h5 {
    letter-spacing: 1px !important;
  }

  .sp-data-table {
    border: none !important;
    background: transparent !important;
  }

  .sp-data-table th {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 1.2px;
    padding: 10px 15px;
  }

  .sp-data-table td {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.4em;
    border: none;
    padding: 17px 25px;
    text-align: left;
    background: transparent;
    letter-spacing: 0.6px;
  }

  .sp-data-table tr + tr {
    border-top: 1px solid #ddd !important;
  }

  .sp-data-table tr:last-child {
    border-bottom: 1px solid #ddd !important;
  }

  .sp-data-table .data-name {
    text-align: left !important;
  }

  .sc_layouts_widgets .sp-data-table tr:first-child th {
    padding-top: 0 !important;
  }

  .sc_layouts_widgets .sp-data-table th,
  .sc_layouts_widgets .sp-data-table td {
    padding: 14px 8px !important;
    background: none !important;
  }

  body .sc_layouts_widgets .sp-data-table th,
  body .sc_layouts_widgets .sp-data-table td {
    background: none !important;
  }

  .sc_layouts_widgets .sp-data-table td.data-name,
  .sc_layouts_widgets .sp-data-table td.data-rank {
    text-align: left !important;
  }

  .sc_layouts_widgets .sp-data-table thead tr:last-child {
    border-bottom-width: 2px !important;
  }

  .sc_layouts_widgets .sp-view-all-link > a {
    width: 100% !important;
    text-align: center;
    padding: 0.8em 3.5em !important;
    margin-bottom: 1px;
  }

  .sc_layouts_widgets .sp-view-all-link {
    padding: 20px 0 0 0 !important;
  }

  .sc_layouts_widgets .sp-template {
    margin-bottom: 0 !important;
  }

  .sp-view-all-link {
    padding: 25px 0 0 0 !important;
    margin: 0 !important;
    background: transparent !important;
    border: none !important;
    overflow: hidden !important;
    display: block !important;
    width: 100% !important;
    text-align: center !important;
  }

  .sp-view-all-link > a {
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    text-transform: capitalize !important;
  }

  .sp-template-event-blocks .sp-event-results {
    font-size: 60px !important;
    font-weight: 700 !important;
    padding: 5px 0 !important;
  }

  .sp-template-event-blocks .sp-event-results * {
    font-weight: 700 !important;
  }

  .sp-template-event-blocks .sp-event-date,
  .sp-template-event-blocks .sp-event-title a,
  .sp-template-event-blocks .sp-event-title {
    font-size: 13px !important;
    font-weight: 500 !important;
  }

  .sp-template.sp-template-event-blocks {
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    overflow: hidden;
    -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.07);
    -ms-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.07);
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.07);
  }

  .sp-template-event-blocks .sp-data-table tr:last-child {
    border-bottom: none !important;
  }

  .sp-template-event-blocks:not(.sp-template-event-logos-block) .sp-event-blocks .team-logo {
    margin: 10px;
    width: 22%;
    height: 6em;
    max-width: 6em;
    margin-top: 33px;
  }

  .sp-template-event-blocks .sp-event-title {
    display: none !important;
  }

  .sp-template-event-blocks:not(.sp-template-event-logos-block) .sp-data-table td {
    padding: 17px 25px 19px !important;
  }

  .sp-template-countdown .sp-event-name,
  .sp-template-countdown .sp-event-venue,
  .sp-template-countdown .sp-event-date {
    border: none !important;
    background: transparent !important;
    padding: 5px 0 !important;
  }

  .sp-countdown time {
    display: table !important;
    background: transparent !important;
    border: none !important;
  }

  .sp-countdown time > span {
    background: transparent !important;
    border: none !important;
  }

  .sp-countdown-wrapper .countdown.sp-countdown time span {
    border: none !important;
    font-size: 49px !important;
    font-weight: 700 !important;
    line-height: 1em !important;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .sp-countdown-wrapper .countdown.sp-countdown time span small {
    font-size: 13px !important;
    line-height: 1.2em !important;
    font-weight: 500 !important;
    display: block !important;
    text-transform: capitalize !important;
  }

  .sp-template-countdown .sp-event-name > a:not(.team-logo) {
    font-size: 13px !important;
    line-height: 18px !important;
    font-weight: 500 !important;
  }

  .sp-template-countdown .sp-event-venue,
  .sp-template-countdown .sp-event-date {
    font-size: 13px !important;
    font-weight: 700 !important;
    letter-spacing: 0 !important;
  }

  .sc_layouts_widgets .sp-countdown time {
    border-top: 1px solid #ddd !important;
    margin-top: 23px;
  }

  .sc_layouts_widgets .sp-countdown-wrapper .countdown.sp-countdown time span {
    padding: 20px 0 5px 0 !important;
  }

  .sc_layouts_widgets .sp-template-countdown .sp-event-name > a:not(.team-logo) {
    margin-top: 50px;
  }

  .extra-event-block .elementor-container {
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    overflow: hidden;
    -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.065);
    -ms-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.065);
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.065);
    margin-left: 15px;
    margin-right: 15px;
  }

  .extra-event-block .sp-template.sp-template-event-blocks {
    -webkit-box-shadow: none;
    -ms-box-shadow: none;
    box-shadow: none;
    margin: 0 !important;
  }

  .extra-event-block .elementor-row > .elementor-element + .elementor-element:before {
    position: absolute;
    content: "";
    display: block;
    left: 0;
    top: 13%;
    width: 1px;
    height: 74%;
    z-index: 1;
    background: #ddd;
  }

  .extra-event-block .sp-template-event-blocks .sp-event-results {
    font-size: 48px !important;
    padding: 0 !important;
    line-height: 0.9em;
  }

  .extra-event-block .sp-template-event-blocks .sp-event-blocks .team-logo {
    height: 5.1em;
    margin-top: 25px;
  }

  .extra-event-block .sp-template-event-blocks .sp-data-table td {
    padding: 22px 25px 22px !important;
  }

  .extra-countdown .sp-countdown-wrapper > * {
    display: none !important;
    background: transparent !important;
    border-left: none !important;
    border-right: none !important;
  }

  .extra-countdown .sp-countdown-wrapper .countdown.sp-countdown {
    display: block !important;
  }

  .extra-countdown .sp-countdown-wrapper .countdown.sp-countdown time {
    background: transparent !important;
    border: none !important;
    display: block !important;
  }

  .extra-countdown .sp-countdown-wrapper .countdown.sp-countdown time span {
    border: none !important;
    font-size: 49px !important;
    font-weight: 700 !important;
    line-height: 1em !important;
    display: block !important;
    padding: 10px 5px !important;
    text-align: left !important;
    width: 25% !important;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    float: left !important;
  }

  .extra-countdown .sp-countdown-wrapper .countdown.sp-countdown time span small {
    font-size: 13px !important;
    line-height: 1.2em !important;
    font-weight: 500 !important;
    display: block !important;
    text-transform: capitalize !important;
  }

  .extra-countdown .sp-template-countdown {
    margin-bottom: 0 !important;
  }

  .extra-countdown.top-style-2 {
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    overflow: hidden;
    max-width: 538px;
    padding: 12px 0 7px 35px;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    float: right;
    display: block;
    clear: both;
    width: 100%;
    text-align: left;
  }

  .extra-countdown.top-style-2 .wp-widget-sportspress-countdown {
    text-align: left;
  }

  .extra-countdown.top-style-2 {
    max-width: 568px;
  }

  .extra-countdown.top-style-2 .widget_title {
    margin: 0 !important;
    background: transparent !important;
    border: none !important;
    padding: 0 !important;
    font-size: 20px;
    text-align: left;
  }

  .extra-countdown.top-style-2 .sp-table-caption {
    font-size: 20px !important;
    letter-spacing: 2.3px !important;
    margin: 0 !important;
  }

  .extra-countdown.top-style-2 .widget_title,
  .extra-countdown.top-style-2 .sp-table-caption {
    margin-top: 20px !important;
    width: 100% !important;
    float: left;
    display: block !important;
    clear: both;
    max-width: 164px !important;
  }

  .extra-countdown.top-style-2 .widget_title,
  .extra-countdown.top-style-2 .sp-table-caption {
    max-width: 194px !important;
  }

  .extra-countdown.top-style-2 .widget_title + .sp-table-caption {
    margin-top: 4px !important;
  }

  .extra-countdown.top-style-2 .sp-template-countdown {
    width: 338px;
    display: inline-block !important;
    position: relative;
    overflow: visible;
    padding-left: 30px;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .extra-countdown.top-style-2 .sp-template-countdown:before {
    position: absolute;
    display: block;
    font-family: "fontello";
    content: "\e814\e814\e814\e814\e814\e814\e814\e814\e814\e814\e814\e814\e814\e814\e814";
    width: 8px;
    height: 160%;
    left: 0;
    top: -27%;
    color: #091625;
    font-size: 5px;
    letter-spacing: 0;
    text-indent: 0;
    line-height: 9px;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__custom.css ; media=all */
@media all {
  .content_wrap {
    width: 1278px;
  }

  body {
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    font-style: normal;
    line-height: 1.76em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0.26px;
  }

  p,
  ul {
    margin-top: 0em;
    margin-bottom: 1.6em;
  }

  h1 {
    font-family: "Roboto", sans-serif;
    font-size: 4.286em;
    font-weight: 700;
    font-style: normal;
    line-height: 0.93em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 2.1px;
    margin-bottom: 0.63em;
  }

  h2 {
    font-family: "Roboto", sans-serif;
    font-size: 3.429em;
    font-weight: 700;
    font-style: normal;
    line-height: 0.96em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 1.75px;
    margin-bottom: 0.52em;
  }

  h3 {
    font-family: "Roboto", sans-serif;
    font-size: 2.571em;
    font-weight: 700;
    font-style: normal;
    line-height: 0.91em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 1.3px;
    margin-bottom: 0.92em;
  }

  h4 {
    font-family: "Roboto", sans-serif;
    font-size: 2.143em;
    font-weight: 700;
    font-style: normal;
    line-height: 1.06em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 1.1px;
    margin-bottom: 0.6em;
  }

  h5 {
    font-family: "Roboto", sans-serif;
    font-size: 1.714em;
    font-weight: 700;
    font-style: normal;
    line-height: 1.06em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0.85px;
    margin-bottom: 0.7em;
  }

  input[type="text"],
  input[type="password"],
  input[type="email"],
  textarea {
    font-size: 1em;
    font-weight: 400;
    font-style: normal;
    line-height: 1.5em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0px;
  }

  .sp-view-all-link > a,
  form button,
  input[type="submit"] {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 18px;
    text-decoration: none;
  }

  .post_meta {
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.4em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0px;
    margin-top: 0.4em;
  }

  .sc_layouts_row:not(.sc_layouts_row_type_narrow) .sc_layouts_menu_nav {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    line-height: 1.5em;
  }

  .sc_layouts_row:not(.sc_layouts_row_type_narrow) .sc_layouts_menu_nav > li > a {
    font-weight: 500;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0.18px;
  }

  .sc_layouts_row:not(.sc_layouts_row_type_narrow) .sc_layouts_menu_nav > li > ul {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    line-height: 1.35em;
  }

  .sc_layouts_row:not(.sc_layouts_row_type_narrow) .sc_layouts_menu_nav > li ul > li > a {
    font-weight: 500;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0.18px;
  }

  .menu_mobile .menu_mobile_nav_area > ul {
    font-family: "Poppins", sans-serif;
  }

  .menu_mobile .menu_mobile_nav_area > ul > li ul {
    font-family: "Poppins", sans-serif;
  }

  .slider_pagination_wrap {
    font-family: "Roboto", sans-serif;
  }

  .sc_button {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 18px;
    text-decoration: none;
  }

  form.mc4wp-form .mc4wp-form-fields input[type="email"] {
    font-size: 1em;
    font-weight: 400;
    font-style: normal;
    line-height: 1.5em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0px;
  }

  form.mc4wp-form .mc4wp-form-fields input[type="submit"] {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 18px;
    text-decoration: none;
  }

  .sp-countdown-wrapper .countdown.sp-countdown time span,
  .sp-template-event-blocks .sp-event-results *,
  .sp-template-event-blocks .sp-event-results,
  .sp-data-table th,
  .sp-table-caption,
  .sp-template h3,
  .sp-template h4,
  .sp-template h5,
  .sp-data-table h4,
  .sp-data-table h5 {
    font-family: "Roboto", sans-serif !important;
  }

  .sp-template-countdown .sp-event-venue,
  .sp-template-countdown .sp-event-date {
    font-family: "Poppins", sans-serif !important;
  }

  .sp-template-countdown .sp-event-name > a:not(.team-logo),
  .sp-countdown-wrapper .countdown.sp-countdown time span small,
  .sp-template-event-blocks .sp-event-title a,
  .sp-template,
  .sp-data-table,
  .sp-table-caption {
    font-family: "Poppins", sans-serif;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__colors-alter.css ; media=all */
@media all {
  .scheme_alter {
    color: #797e87;
    background: #f0f2f7;
  }

  .scheme_alter h1,
  .scheme_alter h2,
  .scheme_alter h3,
  .scheme_alter h4,
  .scheme_alter h5,
  .scheme_alter h3 a,
  .scheme_alter h4 a,
  .scheme_alter h5 a,
  .scheme_alter li a {
    color: #262f3e;
  }

  .scheme_alter h3 a:hover,
  .scheme_alter h4 a:hover,
  .scheme_alter h5 a:hover,
  .scheme_alter li a:hover {
    color: #ff0000;
  }

  .scheme_alter a {
    color: #ff0000;
  }

  .scheme_alter a:hover {
    color: #081324;
  }

  .scheme_alter table th,
  .scheme_alter table th + th {
    border-color: #262f3e;
  }

  .scheme_alter table td,
  .scheme_alter table td + td {
    color: #797e87;
    border-color: #e3e3e3;
  }

  .scheme_alter table th {
    color: #8c9097;
    background-color: #224452;
  }

  .scheme_alter table > tbody > tr:nth-child(2n + 1) > td {
    background-color: #ebebeb;
  }

  .scheme_alter table > tbody > tr:nth-child(2n) > td {
    background-color: #f4f4f4;
  }

  .scheme_alter ul > li:before {
    color: #ff0000;
  }

  .scheme_alter input[type="text"],
  .scheme_alter input[type="password"],
  .scheme_alter input[type="email"],
  .scheme_alter textarea,
  .scheme_alter form.mc4wp-form input[type="email"] {
    color: #797e87;
    border-color: #e4e4e4;
    background-color: #f4f4f4;
  }

  .scheme_alter input[type="text"]:focus,
  .scheme_alter input[type="password"]:focus,
  .scheme_alter input[type="email"]:focus,
  .scheme_alter textarea:focus,
  .scheme_alter form.mc4wp-form input[type="email"]:focus {
    color: #262f3e;
    border-color: #262f3e;
    background-color: #f4f4f4;
  }

  .scheme_alter input[placeholder]::-webkit-input-placeholder {
    color: #797e87;
    opacity: 1;
  }

  .scheme_alter textarea[placeholder]::-webkit-input-placeholder {
    color: #797e87;
    opacity: 1;
  }

  .scheme_alter input[placeholder]::-moz-placeholder {
    color: #797e87;
    opacity: 1;
  }

  .scheme_alter textarea[placeholder]::-moz-placeholder {
    color: #797e87;
    opacity: 1;
  }

  .scheme_alter input[placeholder]:-ms-input-placeholder {
    color: #797e87;
    opacity: 1;
  }

  .scheme_alter textarea[placeholder]:-ms-input-placeholder {
    color: #797e87;
    opacity: 1;
  }

  .scheme_alter input[placeholder]::placeholder {
    color: #797e87;
    opacity: 1;
  }

  .scheme_alter textarea[placeholder]::placeholder {
    color: #797e87;
    opacity: 1;
  }

  .scheme_alter input[type="checkbox"] + label:before {
    border-color: #e4e4e4 !important;
  }

  div .sp-view-all-link > a {
    color: #ffffff !important;
  }

  .scheme_alter .search_wrap .search_submit:before {
    color: #797e87;
  }

  .scheme_alter input[type="submit"][disabled],
  .scheme_alter input[type="submit"][disabled]:hover {
    background-color: rgba(38, 47, 62, 0.4) !important;
    color: #ffffff !important;
  }

  .scheme_alter .sp-view-all-link > a:hover,
  .scheme_alter button:hover,
  .scheme_alter button:focus,
  .scheme_alter input[type="submit"]:hover,
  .scheme_alter input[type="submit"]:focus,
  .scheme_alter .sc_button_default:hover,
  .scheme_alter .sc_button:not(.sc_button_simple):not(.sc_button_bordered):not(.sc_button_bg_image):hover,
  .scheme_alter .mc4wp-form input[type="submit"]:hover,
  .scheme_alter .mc4wp-form input[type="submit"]:focus {
    color: #ffffff;
    background-color: #262f3e;
  }

  .scheme_alter div .sp-view-all-link > a:hover {
    color: #ffffff !important;
  }

  .scheme_alter .search_wrap .search_submit:hover:before {
    color: #262f3e;
  }

  .scheme_alter .sc_layouts_item_icon {
    color: #797e87;
  }

  .scheme_alter .sc_layouts_row_type_normal .sc_layouts_item {
    color: #797e87;
  }

  .scheme_alter .sc_layouts_row_type_normal .sc_layouts_item a:not(.sc_button):not(.button) {
    color: #797e87;
  }

  .scheme_alter .sc_layouts_row_type_normal .sc_layouts_item a:not(.sc_button):not(.button):hover,
  .scheme_alter
    .sc_layouts_row_type_normal
    .sc_layouts_item
    a:not(.sc_button):not(.button):hover
    .sc_layouts_item_icon {
    color: #262f3e;
  }

  .scheme_alter .sc_layouts_row_type_normal .search_wrap .search_submit:before,
  .scheme_alter .sc_layouts_row_type_normal .search_wrap .search_submit {
    background-color: transparent;
    color: #262f3e;
  }

  .scheme_alter .sc_layouts_row_type_normal .search_wrap .search_submit:hover:before,
  .scheme_alter .sc_layouts_row_type_normal .search_wrap .search_submit:hover {
    background-color: transparent;
    color: #797e87;
  }

  .scheme_alter .sc_layouts_menu_nav > li > a {
    color: #262f3e;
  }

  .scheme_alter .sc_layouts_menu_nav > li > a:hover {
    color: #ff0000 !important;
  }

  .scheme_alter .sc_layouts_menu_nav > li.current-menu-parent > a,
  .scheme_alter .sc_layouts_menu_nav > li.current-menu-ancestor > a {
    color: #ff0000 !important;
  }

  .scheme_alter .sc_layouts_menu_nav > li li > a {
    color: #8c9097 !important;
  }

  .scheme_alter .sc_layouts_menu_nav > li li > a:hover {
    color: #ff0000 !important;
  }

  .scheme_alter .sc_layouts_menu_nav > li li > a:hover:after {
    color: #ff0000 !important;
  }

  .scheme_alter .sc_layouts_menu_nav > li li.current-menu-item > a {
    color: #ff0000 !important;
  }

  .scheme_alter .sc_layouts_menu_nav > li li.current-menu-item:before {
    color: #ff0000 !important;
  }

  .scheme_alter .menu_mobile_inner {
    color: #797e87;
    background-color: #ffffff;
  }

  .scheme_alter .menu_mobile_close:before,
  .scheme_alter .menu_mobile_close:after {
    border-color: #1d1d1d;
  }

  .scheme_alter .menu_mobile_close:hover:before,
  .scheme_alter .menu_mobile_close:hover:after {
    border-color: #ff0000;
  }

  .scheme_alter .menu_mobile_inner a,
  .scheme_alter .menu_mobile_inner .menu_mobile_nav_area li:before {
    color: #1d1d1d;
  }

  .scheme_alter .menu_mobile_inner a:hover,
  .scheme_alter .menu_mobile_inner .menu_mobile_nav_area li:hover:before {
    color: #ff0000;
  }

  .scheme_alter .menu_mobile_inner .search_mobile .search_submit {
    color: #797e87;
  }

  .scheme_alter .menu_mobile_inner .search_mobile .search_submit:focus,
  .scheme_alter .menu_mobile_inner .search_mobile .search_submit:hover {
    color: #262f3e;
  }

  .scheme_alter .sc_layouts_row_type_normal .sc_layouts_item_icon {
    color: #ffffff;
    background-color: #8c9097;
  }

  .scheme_alter .sc_layouts_row_type_normal .sc_layouts_item_icon:hover {
    color: #ffffff;
    background-color: #262f3e;
  }

  .scheme_alter .top_panel {
    background-color: #ffffff;
  }

  .scheme_alter .post_item {
    color: #797e87;
  }

  .scheme_alter .post_meta,
  .scheme_alter .post_meta_item,
  .scheme_alter .post_meta_item:after,
  .scheme_alter .post_meta_item:hover:after,
  .scheme_alter .post_meta_item a {
    color: #797e87;
  }

  .scheme_alter .post_meta_item.post_categories a {
    color: #ffffff;
    background-color: #ff0000;
  }

  .scheme_alter .post_meta_item.post_categories a:hover {
    color: #ffffff;
    background-color: #081324;
  }

  .scheme_alter .post_meta_item.post_date {
    color: #ff0000;
  }

  .scheme_alter .post_meta_item a:hover {
    color: #262f3e;
  }

  .scheme_alter .post_item .post_title a:hover {
    color: #ff0000;
  }

  .scheme_alter .format-video .post_featured.with_thumb .post_video_hover {
    color: #ffffff;
    background-color: #ff0000;
  }

  .scheme_alter .format-video .post_featured.with_thumb .post_video_hover:hover {
    color: #ff0000;
    background-color: #ffffff;
  }

  .scheme_alter .sc_layouts_widgets .widget {
    background-color: #ffffff;
  }

  .scheme_alter .sc_title.sc_title_accent,
  .scheme_alter .widget .widget_title {
    background-color: #224452;
    color: #ffffff;
    border-color: #ff0000;
  }

  .scheme_alter .sc_item_title.sc_item_title_style_accent {
    color: #ffffff;
  }

  .scheme_alter .sc_layouts_menu_mobile_button .sc_layouts_item_icon {
    background-color: #ff0000 !important;
    color: #ffffff !important;
  }

  .scheme_alter .sc_layouts_menu_mobile_button .sc_layouts_item_icon:hover {
    background-color: #262f3e !important;
    color: #ffffff !important;
  }

  .scheme_alter .sc_layouts_menu_mobile_button .sc_layouts_item_icon {
    color: #ffffff !important;
    background-color: #8c9097 !important;
  }

  .scheme_alter .sc_layouts_menu_mobile_button .sc_layouts_item_icon:hover {
    color: #ffffff !important;
    background-color: #262f3e !important;
  }

  .scheme_alter .sc_slider_controls.slider_pagination_style_bullets .slider_pagination_bullet {
    border-color: #262f3e;
    background-color: #262f3e;
  }

  .scheme_alter
    .sc_slider_controls.slider_pagination_style_bullets
    .slider_pagination_bullet.swiper-pagination-bullet-active,
  .scheme_alter .sc_slider_controls.slider_pagination_style_bullets .slider_pagination_bullet:hover {
    border-color: #ff0000;
    background-color: #ff0000;
  }

  .scheme_alter .sc_recent_news_style_news-excerpt .post_item {
    border-color: #e3e3e3;
  }

  .scheme_alter .sc_blogger_default.sc_blogger_default_classic_meta_simple .sc_blogger_item_content {
    background-color: #ffffff;
  }

  .scheme_alter .trx_addons_scroll_to_top {
    color: #ffffff;
    border-color: #ff0000;
    background-color: #ff0000;
  }

  .scheme_alter .trx_addons_scroll_to_top:hover {
    color: #ffffff;
    border-color: #ff150d;
    background-color: #262f3e;
  }

  .scheme_alter .elementor-divider-separator {
    border-color: #e3e3e3;
  }

  .scheme_alter .sp-template *,
  .scheme_alter .sp-data-table *,
  .scheme_alter .sp-table-caption {
    color: #797e87;
  }

  .scheme_alter .sp-table-caption {
    color: #262f3e;
  }

  .scheme_alter .sp-data-table td {
    color: #262f3e;
  }

  .scheme_alter .sp-data-table th {
    color: #262f3e;
    background: #f4f4f4;
  }

  .scheme_alter .sp-data-table tr + tr {
    border-color: #e3e3e3;
  }

  .scheme_alter .sp-template-event-blocks .sp-event-results span,
  .scheme_alter .sp-template-event-blocks .sp-event-results,
  .scheme_alter .sp-template-event-blocks .sp-event-results a:hover,
  .scheme_alter .sp-template-event-blocks .sp-event-results a,
  .scheme_alter .sp-template-event-blocks .sp-event-date,
  .scheme_alter .sp-template-event-blocks .sp-event-title a,
  .scheme_alter .sp-template-event-blocks .sp-event-title {
    color: #262f3e !important;
  }

  .scheme_alter .sp-template a,
  .scheme_alter .sp-data-table a {
    color: #262f3e !important;
  }

  .scheme_alter .sp-template a:hover,
  .scheme_alter .sp-data-table a:hover {
    color: #ff0000 !important;
  }

  .scheme_alter .extra-event-block .elementor-container,
  .scheme_alter .sp-template.sp-template-event-blocks {
    background: #ffffff !important;
  }

  .scheme_alter .sp-template-countdown .sp-event-venue,
  .scheme_alter .sp-template-countdown .sp-event-date {
    color: #262f3e !important;
  }

  .scheme_alter .extra-event-block .elementor-row > .elementor-element + .elementor-element:before {
    background: #e3e3e3;
  }

  .scheme_alter .sp-countdown-wrapper .countdown.sp-countdown time span {
    color: #262f3e !important;
  }

  .scheme_alter .sp-countdown-wrapper .countdown.sp-countdown time span small {
    color: #797e87 !important;
  }

  .scheme_alter .extra-countdown.top-style-2 {
    background: #e3e3e3;
  }

  .scheme_alter .extra-countdown.top-style-2 .widget_title {
    color: #ff0000 !important;
  }

  .scheme_alter .sp-countdown time {
    border-color: #e3e3e3 !important;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__colors-dark.css ; media=all */
@media all {
  .scheme_dark {
    color: #848992;
  }

  .scheme_dark h1,
  .scheme_dark h2,
  .scheme_dark h3,
  .scheme_dark h4,
  .scheme_dark h5,
  .scheme_dark h3 a,
  .scheme_dark li a {
    color: #ffffff;
  }

  .scheme_dark h3 a:hover,
  .scheme_dark li a:hover {
    color: #ff0000;
  }

  .scheme_dark a {
    color: #ff0000;
  }

  .scheme_dark a:hover {
    color: #ffffff;
  }

  .scheme_dark ul > li:before {
    color: #ff0000;
  }

  .scheme_dark input[type="text"],
  .scheme_dark input[type="password"],
  .scheme_dark input[type="email"],
  .scheme_dark form.mc4wp-form input[type="email"] {
    color: #b7b7b7;
    border-color: #262f3e;
    background-color: #262f3e;
  }

  .scheme_dark input[type="text"]:focus,
  .scheme_dark input[type="password"]:focus,
  .scheme_dark input[type="email"]:focus,
  .scheme_dark form.mc4wp-form input[type="email"]:focus {
    color: #ffffff;
    border-color: #353535;
    background-color: #262f3e;
  }

  .scheme_dark input[placeholder]::-webkit-input-placeholder {
    color: #6f6f6f;
    opacity: 1;
  }

  .scheme_dark input[placeholder]::-moz-placeholder {
    color: #6f6f6f;
    opacity: 1;
  }

  .scheme_dark input[placeholder]:-ms-input-placeholder {
    color: #6f6f6f;
    opacity: 1;
  }

  .scheme_dark input[placeholder]::placeholder {
    color: #6f6f6f;
    opacity: 1;
  }

  .scheme_dark input[type="checkbox"] + label:before {
    border-color: #262f3e !important;
  }

  .scheme_dark button:not(.components-button),
  .scheme_dark input[type="submit"],
  .scheme_dark .sc_button_default,
  .scheme_dark .sc_button:not(.sc_button_simple):not(.sc_button_bordered):not(.sc_button_bg_image),
  .scheme_dark .mc4wp-form input[type="submit"] {
    color: #ffffff;
    background-color: #ff0000;
  }

  .scheme_dark .search_wrap .search_submit:before {
    color: #b7b7b7;
  }

  .scheme_dark input[type="submit"][disabled],
  .scheme_dark input[type="submit"][disabled]:hover {
    background-color: rgba(255, 255, 255, 0.4) !important;
    color: #081224 !important;
  }

  .scheme_dark button:hover,
  .scheme_dark button:focus,
  .scheme_dark input[type="submit"]:hover,
  .scheme_dark input[type="submit"]:focus,
  .scheme_dark .sc_button_default:hover,
  .scheme_dark .sc_button:not(.sc_button_simple):not(.sc_button_bordered):not(.sc_button_bg_image):hover,
  .scheme_dark .mc4wp-form input[type="submit"]:hover,
  .scheme_dark .mc4wp-form input[type="submit"]:focus {
    color: #262f3e;
    background-color: #ffffff;
  }

  .scheme_dark .search_wrap .search_submit:hover:before {
    color: #ffffff;
  }

  .scheme_dark.footer_wrap {
    color: #848992;
    background-color: #081224;
  }

  .scheme_dark .sc_layouts_menu_nav > li > a {
    color: #ffffff;
  }

  .scheme_dark .sc_layouts_menu_nav > li > a:hover {
    color: #ff0000 !important;
  }

  .scheme_dark .menu_mobile_inner {
    color: #a6a6a6;
    background-color: #081224;
  }

  .scheme_dark .menu_mobile_close:before,
  .scheme_dark .menu_mobile_close:after {
    border-color: #ffffff;
  }

  .scheme_dark .menu_mobile_close:hover:before,
  .scheme_dark .menu_mobile_close:hover:after {
    border-color: #ff0000;
  }

  .scheme_dark .menu_mobile_inner a,
  .scheme_dark .menu_mobile_inner .menu_mobile_nav_area li:before {
    color: #ffffff;
  }

  .scheme_dark .menu_mobile_inner a:hover,
  .scheme_dark .menu_mobile_inner .menu_mobile_nav_area li:hover:before {
    color: #ff0000;
  }

  .scheme_dark .menu_mobile_inner .search_mobile .search_submit {
    color: #6f6f6f;
  }

  .scheme_dark .menu_mobile_inner .search_mobile .search_submit:focus,
  .scheme_dark .menu_mobile_inner .search_mobile .search_submit:hover {
    color: #ffffff;
  }

  .scheme_dark.footer_wrap {
    background-color: #081224;
    color: #a6a6a6;
  }

  .scheme_dark.footer_wrap .widget {
    border-color: #848992;
  }

  .scheme_dark.footer_wrap h2 {
    color: #ffffff;
  }

  .scheme_dark.footer_wrap a {
    color: #ffffff;
  }

  .scheme_dark.footer_wrap a:hover {
    color: #ff0000;
  }

  .scheme_dark .widget .widget_title {
    background-color: #ffffff;
    color: #081224;
    border-color: #ff0000;
  }

  .scheme_dark .sc_slider_controls.slider_pagination_style_bullets .slider_pagination_bullet {
    border-color: #ffffff;
    background-color: #ffffff;
  }

  .scheme_dark
    .sc_slider_controls.slider_pagination_style_bullets
    .slider_pagination_bullet.swiper-pagination-bullet-active,
  .scheme_dark .sc_slider_controls.slider_pagination_style_bullets .slider_pagination_bullet:hover {
    border-color: #ff0000;
    background-color: #ff0000;
  }

  .scheme_dark .elementor-divider-separator {
    border-color: #262f3e;
  }

  .scheme_dark .sp-template *,
  .scheme_dark .sp-table-caption {
    color: #848992 !important;
  }

  .scheme_dark .sp-table-caption {
    color: #ffffff !important;
  }

  .scheme_dark .sp-template a {
    color: #ffffff !important;
  }

  .scheme_dark .sp-template a:hover {
    color: #ff0000 !important;
  }

  .scheme_dark .sp-countdown-wrapper .countdown.sp-countdown time span {
    color: #ffffff !important;
  }

  .scheme_dark .sp-countdown-wrapper .countdown.sp-countdown time span small {
    color: #848992 !important;
  }

  .scheme_dark .extra-countdown.top-style-2 {
    background: #262f3e;
  }

  .scheme_dark .extra-countdown.top-style-2 .widget_title {
    color: #ff0000 !important;
  }

  .scheme_dark .sp-countdown time {
    border-color: #262f3e !important;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__colors-default.css ; media=all */
@media all {
  .scheme_default {
    color: #797e87;
  }

  .scheme_default h5,
  .scheme_default h5 a {
    color: #262f3e;
  }

  .scheme_default h5 a:hover {
    color: #ff0000;
  }

  .scheme_default a {
    color: #ff0000;
  }

  .scheme_default a:hover {
    color: #081324;
  }

  .scheme_default .post_meta,
  .scheme_default .post_meta_item,
  .scheme_default .post_meta_item:after,
  .scheme_default .post_meta_item:hover:after,
  .scheme_default .post_meta_item a {
    color: #797e87;
  }

  .scheme_default .post_meta_item.post_categories a {
    color: #ffffff;
    background-color: #ff0000;
  }

  .scheme_default .post_meta_item.post_categories a:hover {
    color: #ffffff;
    background-color: #081324;
  }

  .scheme_default .post_meta_item.post_date {
    color: #ff0000;
  }

  .scheme_default .post_meta_item a:hover {
    color: #262f3e;
  }

  .scheme_default .format-video .post_featured.with_thumb .post_video_hover {
    color: #ffffff;
    background-color: #ff0000;
  }

  .scheme_default .format-video .post_featured.with_thumb .post_video_hover:hover {
    color: #ff0000;
    background-color: #ffffff;
  }

  .scheme_default .sc_blogger_default.sc_blogger_default_classic_meta_simple .sc_blogger_item_content {
    background-color: #ffffff;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/trx_addons/css/__responsive.css ; media=all */
@media all {
  @media (max-width: 1439px) {
    .sc_item_title + .sc_item_button {
      margin-top: 2em;
    }

    .sc_layouts_row:not(.sc_layouts_row_type_narrow),
    .sc_layouts_row:not(.sc_layouts_row_type_narrow) input[type="text"] {
      font-size: 14px;
    }

    .sc_layouts_row .sc_layouts_logo img {
      max-height: 4.5em;
    }
  }

  @media (max-width: 1279px) {
    .sc_layouts_row:not(.sc_layouts_row_type_narrow),
    .sc_layouts_row:not(.sc_layouts_row_type_narrow) input[type="text"] {
      font-size: 13px;
      line-height: 21px;
    }
  }

  @media (max-width: 1023px) {
    .trx_addons_scroll_to_top {
      right: 1em;
    }

    .sc_item_title {
      letter-spacing: 1px;
    }

    .sc_layouts_column .sc_layouts_item {
      margin-right: 1.15em;
    }

    .sc_layouts_column_align_right .sc_layouts_item {
      margin-left: 1.15em;
      margin-right: 0;
    }

    .sc_layouts_menu_nav > li > a {
      padding: 0.7em;
    }

    .sc_layouts_menu_nav > li.menu-item-has-children > a {
      padding-right: 1.3em;
    }

    .sc_layouts_menu_nav > li.menu-item-has-children > a:after {
      right: 0.5em;
    }
  }

  @media (max-width: 767px) {
    .trx_addons_columns_wrap {
      margin-right: -20px;
    }

    .trx_addons_columns_wrap > [class*="trx_addons_column-"] {
      padding-right: 20px;
    }

    .trx_addons_columns_wrap.columns_padding_bottom > [class*="trx_addons_column-"] {
      padding-bottom: 20px;
    }

    .trx_addons_columns_wrap:not(.columns_fluid) > [class*="trx_addons_column-"] {
      width: 50%;
    }

    .sc_item_title {
      letter-spacing: 0;
    }

    .sc_item_title + .sc_item_button {
      margin-top: 1.5em;
    }

    .hide_on_mobile,
    .sc_layouts_hide_on_mobile {
      display: none !important;
    }

    .sc_layouts_menu_mobile_button {
      display: inline-block !important;
    }
  }

  @media (max-width: 479px) {
    .trx_addons_columns_wrap {
      margin-right: -20px;
    }

    .trx_addons_columns_wrap > [class*="trx_addons_column-"] {
      padding-right: 20px;
    }

    .trx_addons_columns_wrap.columns_padding_bottom > [class*="trx_addons_column-"] {
      padding-bottom: 20px;
    }

    .trx_addons_columns_wrap:not(.columns_fluid) > [class*="trx_addons_column-"] {
      width: 100%;
    }

    .sc_layouts_row:not(.sc_layouts_row_type_narrow),
    .sc_layouts_row:not(.sc_layouts_row_type_narrow) input[type="text"] {
      font-size: 13px;
      line-height: 19px;
    }

    .sc_recent_news_style_news-excerpt .post_item .post_featured {
      float: none;
      padding-right: 0;
      margin-bottom: 1.5em;
      width: 100%;
    }

    .sc_recent_news_style_news-excerpt .post_item .post_body {
      float: none;
      width: 100%;
      padding: 0;
    }
  }

  @media (min-width: 2160px) {
    .sc_layouts_hide_on_wide {
      display: none !important;
    }
  }

  @media (min-width: 1680px) and (max-width: 2159px) {
    .sc_layouts_hide_on_desktop {
      display: none !important;
    }
  }

  @media (min-width: 1280px) and (max-width: 1679px) {
    .sc_layouts_hide_on_notebook {
      display: none !important;
    }
  }

  @media (min-width: 768px) and (max-width: 1279px) {
    .sc_layouts_hide_on_tablet {
      display: none !important;
    }
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__responsive.css ; media=all */
@media all {
  @media (max-width: 1679px) {
    .sp-template-event-blocks:not(.sp-template-event-logos-block) .sp-data-table td {
      padding: 17px 15px 15px !important;
    }

    .sp-template-event-blocks .sp-event-results {
      font-size: 58px !important;
    }

    .sp-template-event-blocks:not(.sp-template-event-logos-block) .sp-event-blocks .team-logo {
      width: 24%;
    }
  }

  @media (max-width: 1439px) {
    .content_wrap {
      width: 1100px;
    }

    .sc_layouts_row:not(.sc_layouts_row_type_narrow),
    .sc_layouts_row:not(.sc_layouts_row_type_narrow) input[type="text"] {
      font-size: 14px;
      line-height: inherit;
    }

    .sc_recent_news_style_news-excerpt .post_featured {
      max-width: 250px;
    }

    .sc_recent_news_style_news-excerpt .post_body {
      width: calc(100% - 250px);
      padding: 10px 10px 10px 30px;
    }

    .sc_recent_news_style_news-excerpt .post_item .post_title {
      font-size: 26px;
    }

    .sc_recent_news_style_news-excerpt .post_item .post_header + .post_content {
      margin-top: 10px;
    }

    .sc_blogger_default .sc_blogger_item_title {
      font-size: 30px;
    }

    .sc_blogger_default.sc_blogger_default_classic_meta .sc_blogger_item_excerpt {
      padding-right: 0;
    }

    .sc_blogger .sc_blogger_item_title + .sc_blogger_item_excerpt {
      margin-top: 10px;
    }

    .sc_layouts_row_type_normal .search_wrap .search_field {
      width: 16em;
    }

    .sp-template-event-blocks .sp-event-results {
      font-size: 50px !important;
    }

    .sp-template-event-blocks:not(.sp-template-event-logos-block) .sp-event-blocks .team-logo {
      margin-left: 5px;
      margin-right: 5px;
    }

    .sp-template-event-blocks .sp-event-results {
      padding: 0 !important;
    }

    .extra-countdown.top-style-2 .widget_title,
    .extra-countdown.top-style-2 .sp-table-caption {
      max-width: 134px !important;
    }

    .extra-countdown.top-style-2 .widget_title {
      margin-top: 10px !important;
    }

    .sc_layouts_widgets .sp-template-countdown .sp-event-name > a:not(.team-logo) {
      margin-top: 40px;
    }

    .elementor-section.elementor-section-boxed > .elementor-container {
      max-width: 1130px;
    }

    .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  @media (max-width: 1279px) {
    h1 {
      font-size: 3.7em;
      margin-bottom: 22px;
      letter-spacing: 1.5px;
    }

    h2 {
      font-size: 3em;
      margin-bottom: 20px;
      letter-spacing: 1.5px;
    }

    h3 {
      font-size: 2.4em;
      margin-bottom: 20px;
      letter-spacing: 1px;
    }

    h4 {
      font-size: 2em;
      margin-bottom: 5px;
      letter-spacing: 1px;
    }

    h5 {
      font-size: 1.6em;
      margin-bottom: 18px;
    }

    p,
    ul {
      margin-bottom: 1.4em;
    }

    // .page_content_wrap {
    //   padding-top: 50px;
    //   padding-bottom: 50px;
    // }

    .sp-view-all-link {
      padding: 30px 0 0 0 !important;
    }

    .content_wrap {
      width: 964px !important;
    }

    .trx_addons_columns_wrap {
      margin-right: -30px;
    }

    .trx_addons_columns_wrap > [class*="trx_addons_column-"] {
      padding-right: 30px;
    }

    .trx_addons_columns_wrap.columns_padding_bottom > [class*="trx_addons_column-"] {
      padding-bottom: 30px;
    }

    form button,
    input[type="submit"],
    .sc_button,
    .sp-view-all-link > a {
      padding: 1em 3em;
    }

    table th,
    table td {
      padding: 1rem;
    }

    .footer_wrap .sc_layouts_menu > ul > li {
      font-size: 15px;
    }

    .sc_layouts_row_type_normal .search_wrap .search_field {
      width: 10em;
    }

    .sc_layouts_menu_nav > li > a,
    .sc_layouts_menu_nav > li.menu-item-has-children > a {
      padding: 0.85em 1em;
    }

    .sc_recent_news_style_news-excerpt .post_featured {
      max-width: 210px;
    }

    .sc_recent_news_style_news-excerpt .post_body {
      width: calc(100% - 210px);
      padding: 0 0 0 25px;
    }

    .sc_recent_news_style_news-excerpt .post_item .post_header + .post_content {
      margin-top: 7px;
    }

    .sc_blogger_default .sc_blogger_item_title {
      font-size: 28px;
    }

    .sc_item_title + .sc_item_button {
      margin-top: 1.6em;
    }

    .trx_addons_scroll_to_top {
      right: 1px;
      width: 2.2em;
      height: 2.2em;
      line-height: 2.2em;
    }

    .sc_layouts_widgets .widget {
      padding: 20px;
    }

    .sc_layouts_widgets .widget .widget_title,
    .widget .widget_title {
      margin-bottom: 20px;
      padding: 16px 13px;
    }

    .sc_layouts_widgets .widget .widget_title {
      margin-left: -20px;
      margin-right: -20px;
      margin-top: -20px;
    }

    .sc_title.sc_title_accent {
      padding: 16px 13px;
    }

    .sc_layouts_row_type_normal {
      padding: 0.8em 0;
    }

    .sp-data-table td {
      padding: 15px 20px !important;
    }

    .med-title .sc_item_title {
      font-size: 60px;
    }

    .extra-event-block .sp-template-event-blocks:not(.sp-template-event-logos-block) .sp-data-table td {
      padding: 17px 0 15px !important;
    }

    .sp-template-event-blocks:not(.sp-template-event-logos-block) .sp-event-blocks .team-logo {
      margin: 10px 5px;
      width: 25%;
      height: 6.5em;
      max-width: 6.5em;
      margin-top: 21px;
    }

    .extra-countdown.top-style-2 .sp-template-countdown {
      width: 253px;
    }

    .extra-countdown .sp-countdown-wrapper .countdown.sp-countdown time span {
      font-size: 36px !important;
    }

    .extra-countdown.top-style-2 .sp-table-caption {
      margin-top: 14px !important;
    }

    .extra-countdown.top-style-2 .widget_title {
      margin-top: 4px !important;
    }

    .copyright-text {
      font-size: 15px;
    }

    .elementor-section.elementor-section-boxed > .elementor-container {
      max-width: 994px;
    }

    .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  @media (max-width: 1023px) {
    h1 {
      font-size: 3em;
      margin-bottom: 15px;
      letter-spacing: 1.3px;
    }

    h2 {
      font-size: 2.6em;
      margin-bottom: 15px;
      letter-spacing: 1.3px;
    }

    h3 {
      font-size: 2.1em;
      margin-bottom: 14px;
      letter-spacing: 1px;
    }

    h4 {
      font-size: 1.8em;
      margin-bottom: 13px;
      letter-spacing: 1px;
    }

    h5 {
      font-size: 1.45em;
      margin-top: 30px;
      margin-bottom: 13px;
    }

    .content_wrap {
      width: 708px !important;
    }

    // .page_content_wrap {
    //   padding-top: 50px;
    //   padding-bottom: 50px;
    // }

    .sc_layouts_row_type_normal .search_wrap .search_field {
      width: 9em;
      padding: 0 40px 0 20px !important;
      height: 40px;
      line-height: 40px;
    }

    .sc_layouts_row_type_normal .search_wrap .search_submit {
      width: 40px;
      height: 40px;
      line-height: 40px;
    }

    .sc_layouts_menu_nav > li > a,
    .sc_layouts_menu_nav > li.menu-item-has-children > a {
      padding: 0.8em;
    }

    .sc_layouts_row_type_normal {
      padding: 0.6em 0;
    }

    form.mc4wp-form .mc4wp-form-fields input[type="submit"] {
      min-width: 7.5em;
    }

    form.mc4wp-form .mc4wp-form-fields input[type="email"] {
      padding: 0 8em 0 2em;
    }

    .copyright-text {
      font-size: 14px;
      line-height: 1.4em;
    }

    .footer_wrap .sc_layouts_menu > ul > li > a {
      padding: 0.5em 0.8em;
    }

    .footer_wrap .sc_layouts_menu > ul > li {
      font-size: 14px;
    }

    .sc_layouts_row_type_normal .sc_layouts_item_icon {
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-size: 13px;
    }

    .sc_layouts_row_type_normal .search_wrap .search_submit {
      width: 35px;
    }

    .sc_layouts_row_type_normal .search_wrap .search_field {
      width: 7em;
      padding: 0 30px 0 12px !important;
    }

    .extra-countdown.top-style-2 {
      max-width: 100%;
      padding: 20px 20px 15px 20px;
    }

    .extra-countdown.top-style-2 .sp-table-caption {
      margin-top: 0 !important;
    }

    .extra-countdown.top-style-2 .widget_title,
    .extra-countdown.top-style-2 .sp-table-caption {
      max-width: 100% !important;
      text-align: center;
    }

    .extra-countdown.top-style-2 .sp-template-countdown {
      padding: 0;
      width: 100%;
    }

    .extra-countdown.top-style-2 .sp-template-countdown:before {
      display: none;
    }

    .extra-countdown .sp-countdown-wrapper .countdown.sp-countdown time span {
      padding-bottom: 0 !important;
      text-align: center !important;
    }

    .extra-countdown.top-style-2 .widget_title {
      margin-top: 0 !important;
    }

    .extra-event-block .sp-template-event-blocks .sp-event-results {
      font-size: 44px !important;
    }

    .sp-template-event-blocks:not(.sp-template-event-logos-block) .sp-event-blocks .team-logo {
      height: auto;
    }

    .sc_recent_news_style_news-excerpt .post_featured {
      max-width: 174px;
      float: left;
      margin: 0 20px 10px 0;
    }

    .sc_recent_news_style_news-excerpt .post_body {
      width: auto;
      padding: 0;
      display: inline;
      float: none;
    }

    .sc_blogger_default.sc_blogger_default_classic_meta_simple .sc_blogger_item_content {
      padding: 40px 20px 10px;
    }

    .sc_slider_controls .slider_controls_wrap .slider_pagination_wrap {
      height: 2.5em;
    }

    .sc_blogger_default.sc_blogger_default_classic_meta_simple .sc_blogger_slider .sc_blogger_item_title {
      font-size: 24px;
    }

    .sc_blogger_default .sc_blogger_item_title {
      font-size: 26px;
    }

    .sc_blogger_item_default.sc_blogger_item_image_position_top .sc_blogger_item_featured + .sc_blogger_item_content {
      margin-top: 1.7em;
    }

    .post_meta_item.post_categories a {
      padding: 3px 10px;
    }

    .post_meta .post_meta_item:after {
      margin: 0 0.3em;
    }

    .sc_layouts_widgets .sp-data-table th,
    .sc_layouts_widgets .sp-data-table td {
      padding: 8px 5px !important;
    }

    .sc_layouts_widgets .sp-data-table th {
      font-size: 18px !important;
      letter-spacing: 1px !important;
    }

    .sc_layouts_widgets .sp-template-countdown .sp-event-name > a:not(.team-logo) {
      margin-top: 0;
      text-align: center;
      overflow: hidden;
      margin-bottom: -5px;
      padding-top: 11px;
      display: block;
      width: 100%;
    }

    .sc_layouts_widgets .sp-countdown time {
      margin-top: 15px;
    }

    .sc_layouts_widgets .sp-countdown-wrapper .countdown.sp-countdown time span {
      padding: 15px 0 5px 0 !important;
      font-size: 30px !important;
    }

    .sp-table-caption {
      margin: 0 0 25px !important;
      font-size: 38px !important;
    }

    .slider_container:not(.slider_multi):not(.slider_type_images),
    .slider_container:not(.slider_multi):not(.slider_type_images) .slider-slide {
      min-height: 135px;
    }

    .med-title .sc_item_title {
      font-size: 44px;
    }

    .med-title .sc_item_title + .sc_item_button {
      margin-top: 1.3em;
    }

    .elementor-section.elementor-section-boxed > .elementor-container {
      max-width: 738px;
    }

    .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  @media (max-width: 767px) {
    h1 {
      font-size: 2.6em;
      margin-top: 40px;
      margin-bottom: 15px;
      letter-spacing: 1.3px;
    }

    h2 {
      font-size: 2.3em;
      margin-bottom: 15px;
      letter-spacing: 1.3px;
    }

    h3 {
      font-size: 2em;
      margin-bottom: 14px;
      letter-spacing: 1px;
    }

    h4 {
      font-size: 1.6em;
      margin-bottom: 13px;
      letter-spacing: 1px;
    }

    h5 {
      font-size: 1.3em;
      margin-bottom: 13px;
    }

    .menu_mobile .sc_layouts_logo {
      margin: 2.4rem 0 0;
    }

    .content_wrap {
      width: 440px !important;
    }

    .trx_addons_columns_wrap {
      margin-right: -20px;
    }

    .trx_addons_columns_wrap > [class*="trx_addons_column-"] {
      padding-right: 20px;
    }

    .trx_addons_columns_wrap.columns_padding_bottom > [class*="trx_addons_column-"] {
      padding-bottom: 20px;
    }

    .trx_addons_columns_wrap:not(.columns_fluid) > [class*="trx_addons_column-"] {
      width: 100%;
    }

    // .page_content_wrap {
    //   padding-top: 40px;
    //   padding-bottom: 40px;
    // }

    form button,
    input[type="submit"],
    .sc_button,
    .sp-view-all-link > a {
      padding: 0.84em 2.5em;
    }

    .sc_button {
      font-size: 13px;
    }

    .sp-view-all-link > a {
      font-size: 13px !important;
    }

    input[type="text"],
    input[type="email"],
    textarea,
    form.mc4wp-form .mc4wp-form-fields input[type="email"] {
      padding: 0.8em 1.5em;
    }

    .menu_mobile .menu_mobile_inner {
      overflow-y: auto;
      top: 0;
      bottom: 0;
    }

    .sc_item_title + .sc_item_button {
      margin-top: 1.2em;
    }

    .sp-data-table th {
      font-size: 18px !important;
      letter-spacing: 1px !important;
      padding: 8px 10px !important;
    }

    .sp-data-table td {
      padding: 8px 10px !important;
    }

    .sp-view-all-link {
      padding: 20px 0 0 0 !important;
    }

    .sc_blogger_default.sc_blogger_default_classic_meta_simple .sc_blogger_item_content {
      padding: 20px 20px 10px;
    }

    .sc_slider_controls .slider_controls_wrap .slider_pagination_wrap {
      height: 2em;
    }

    .sc_blogger_default.sc_blogger_default_classic_meta_simple .sc_blogger_slider .sc_blogger_item_title {
      font-size: 22px;
    }

    .sc_recent_news_style_news-excerpt .post_item + .post_item {
      margin-top: 20px;
    }

    .sc_recent_news_style_news-excerpt .post_item .post_title {
      font-size: 22px;
    }

    .sc_blogger_default .sc_blogger_item_title {
      font-size: 24px;
    }

    .sc_blogger .sc_blogger_item_title + .sc_blogger_item_excerpt {
      margin-top: 8px;
      line-height: 1.5em;
    }

    .sc_recent_news_style_news-excerpt .post_item .post_meta {
      margin: 5px 0 0.8em !important;
    }

    .sc_layouts_menu_nav > li > a,
    .sc_layouts_menu_nav > li.menu-item-has-children > a {
      padding: 0.55em;
    }

    .sc_layouts_widgets .widget_area_inner > div + div,
    .sc_layouts_widgets .widget_area_inner > div + aside,
    .sc_layouts_widgets .widget_area_inner > aside + aside {
      margin-top: 20px;
    }

    .sc_item_title .sc_item_title_text br {
      display: none;
    }

    .sp-table-caption {
      margin: 0 0 20px !important;
      font-size: 36px !important;
    }

    .sp-template {
      margin-bottom: 20px !important;
    }

    .med-title .sc_item_title + .sc_item_button {
      margin-top: 1em;
    }

    .extra-event-block .elementor-row > .elementor-element + .elementor-element:before {
      left: 0;
      top: 0;
      width: 100%;
      height: 1px;
    }

    .extra-event-block .sp-template-event-blocks:not(.sp-template-event-logos-block) .sp-data-table td {
      padding: 15px 0 15px !important;
    }

    .sp-template-event-blocks:not(.sp-template-event-logos-block) .sp-event-blocks .team-logo {
      max-width: 6.8em;
      margin-top: 14px;
    }

    .sc_layouts_widgets .sp-countdown-wrapper .countdown.sp-countdown time span {
      padding: 18px 0 3px 0 !important;
      font-size: 38px !important;
    }

    .sc_layouts_widgets .sp-template-countdown .sp-event-name > a:not(.team-logo) {
      margin-top: 60px;
    }

    .extra-countdown .sp-countdown-wrapper .countdown.sp-countdown time span {
      font-size: 44px !important;
    }

    .extra-countdown.top-style-2 .sp-template-countdown {
      padding: 5px 0 0 0;
    }

    .extra-countdown.top-style-2 {
      padding: 22px 20px 17px 20px;
    }

    .extra-countdown.top-style-2 .sp-table-caption {
      font-size: 22px !important;
    }

    body div .sc_layouts_column .copyright-text {
      text-align: center;
      display: block;
      width: 100% !important;
    }

    .sc_item_title {
      letter-spacing: 1px;
    }

    .sp-data-table thead th {
      position: relative;
    }

    .sc_layouts_widgets .sp-template-countdown .sp-event-name > a:not(.team-logo) {
      width: auto;
      margin-top: 50px;
    }

    .elementor-section.elementor-section-boxed > .elementor-container {
      max-width: 460px;
    }

    .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  @media (max-width: 479px) {
    p,
    ul {
      margin-bottom: 1.1em;
    }

    table th,
    table td {
      padding: 0.5rem;
    }

    body {
      letter-spacing: 0;
      line-height: 1.6em;
    }

    h1 {
      font-size: 2.2em;
      margin-bottom: 12px;
      letter-spacing: 1px;
      line-height: 0.93em;
    }

    h2 {
      font-size: 2em;
      margin-bottom: 11px;
      letter-spacing: 1px;
      line-height: 0.96em;
    }

    h3 {
      font-size: 1.8em;
      margin-bottom: 10px;
      letter-spacing: 1px;
      line-height: 0.91em;
    }

    h4 {
      font-size: 1.6em;
      margin-bottom: 9px;
      letter-spacing: 1px;
      line-height: 1.06em;
    }

    h5 {
      font-size: 1.3em;
      margin-bottom: 8px;
      line-height: 1.06em;
    }

    // .page_content_wrap {
    //   padding-top: 50px;
    //   padding-bottom: 50px;
    // }

    .menu_mobile .sc_layouts_logo {
      margin: 2rem 0 0;
    }

    .menu_mobile_close {
      width: 3em;
      height: 3em;
      line-height: 3em;
    }

    .content_wrap {
      width: 280px !important;
    }

    .trx_addons_columns_wrap {
      margin-right: -20px;
    }

    .trx_addons_columns_wrap > [class*="trx_addons_column-"] {
      padding-right: 20px;
    }

    .trx_addons_columns_wrap.columns_padding_bottom > [class*="trx_addons_column-"] {
      padding-bottom: 20px;
    }

    .trx_addons_columns_wrap:not(.columns_fluid) > [class*="trx_addons_column-"] {
      width: 100%;
    }

    .menu_mobile .menu_mobile_nav_area > ul > li > a {
      font-size: 18px;
      line-height: 1.5em;
    }

    .menu_mobile .menu_mobile_nav_area li > a {
      font-size: 14px;
      line-height: 1.5em;
      width: 280px;
    }

    .menu_mobile .menu_mobile_nav_area li li .open_child_menu {
      top: -1px;
    }

    .menu_mobile_close:before,
    .menu_mobile_close:after {
      width: 35%;
    }

    form.mc4wp-form .mc4wp-form-fields input[type="email"] {
      height: 40px;
      line-height: 40px;
    }

    form.mc4wp-form .mc4wp-form-fields input[type="submit"] {
      height: 40px;
      line-height: 40px;
      font-size: 13px;
    }

    .sp-view-all-link > a,
    form button,
    input[type="submit"] {
      font-size: 13px;
    }

    .sc_layouts_row_type_normal .sc_layouts_item_icon {
      width: 35px;
      height: 35px;
      line-height: 35px;
    }

    .sc_layouts_menu_mobile_button .sc_layouts_item_icon {
      font-size: 25px !important;
    }

    .extra-countdown.top-style-2 {
      padding: 20px 15px 15px 15px;
    }

    .sp-template-event-blocks:not(.sp-template-event-logos-block) .sp-data-table td {
      padding: 15px 10px 13px !important;
    }

    .sp-template-event-blocks .sp-event-results {
      font-size: 40px !important;
    }

    .sp-template-event-blocks .sp-event-date {
      margin: 8px !important;
    }

    .sp-template-event-blocks:not(.sp-template-event-logos-block) .sp-event-blocks .team-logo {
      margin-top: 20px;
    }

    .sp-data-table th {
      font-size: 16px !important;
      letter-spacing: 1px !important;
      padding: 6px 8px !important;
    }

    .sp-data-table td {
      padding: 6px 8px !important;
    }

    .med-title .sc_item_title {
      font-size: 40px;
    }

    .sc_recent_news_style_news-excerpt .post_featured {
      max-width: 100%;
      margin: 0;
    }

    .sc_recent_news_style_news-excerpt .post_item .post_featured {
      margin-bottom: 1.2em;
      vertical-align: bottom;
    }

    .sc_blogger_default.sc_blogger_default_classic_meta_simple .sc_blogger_item_content {
      padding: 20px 15px 10px;
    }

    .sc_slider_controls.slider_pagination_style_bullets .slider_pagination_bullet {
      width: 20px;
    }

    .sc_layouts_widgets .sp-template-countdown .sp-event-name > a:not(.team-logo) {
      margin-top: 23px;
      font-size: 12px !important;
    }

    .sc_item_title {
      letter-spacing: 1px;
    }

    .sc_layouts_widgets .sp-template-countdown .sp-event-name > a:not(.team-logo) {
      margin-top: 23px;
      font-size: 12px !important;
    }

    .sp-table-caption {
      margin: 0 0 16px !important;
      font-size: 32px !important;
    }

    .elementor-section.elementor-section-boxed > .elementor-container {
      max-width: 300px;
    }

    .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

/*! CSS Used from: Embedded */
.elementor-1578 .elementor-element.elementor-element-3e313d6d {
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.08);
}

.elementor-1578 .elementor-element.elementor-element-3eea3720 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-1578 .elementor-element.elementor-element-58da9b79 .logo_image {
  max-height: 80px;
}

.elementor-1578 .elementor-element.elementor-element-39a51b3f {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

@media (max-width: 767px) {
  .elementor-1578 .elementor-element.elementor-element-3eea3720 {
    width: 70%;
  }

  .elementor-1578 .elementor-element.elementor-element-39a51b3f {
    width: 30%;
  }
}

@media (min-width: 768px) {
  .elementor-1578 .elementor-element.elementor-element-3eea3720 {
    width: 32%;
  }

  .elementor-1578 .elementor-element.elementor-element-39a51b3f {
    width: 68%;
  }
}

/*! CSS Used from: Embedded */
.elementor-872 .elementor-element.elementor-element-1459491d {
  transition:
    background 0.3s,
    border 0.3s,
    border-radius 0.3s,
    box-shadow 0.3s;
}

.elementor-872 .elementor-element.elementor-element-49b79c89 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-872 .elementor-element.elementor-element-4d806f3c .elementor-spacer-inner {
  height: 28px;
}

.elementor-872 .elementor-element.elementor-element-61c0e418 .elementor-spacer-inner {
  height: 25px;
}

.elementor-872 .elementor-element.elementor-element-5f99db4a .elementor-divider-separator {
  border-top-style: solid;
  border-top-width: 2px;
  width: 100%;
}

.elementor-872 .elementor-element.elementor-element-5f99db4a .elementor-divider {
  padding-top: 15px;
  padding-bottom: 15px;
}

.elementor-872 .elementor-element.elementor-element-6743357d .elementor-spacer-inner {
  height: 20px;
}

.elementor-872 .elementor-element.elementor-element-a3d305d {
  transition:
    background 0.3s,
    border 0.3s,
    border-radius 0.3s,
    box-shadow 0.3s;
}

.elementor-872 .elementor-element.elementor-element-7e68f05 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-872 .elementor-element.elementor-element-bd100bb {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-872 .elementor-element.elementor-element-e6cb6af {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-872 .elementor-element.elementor-element-77ff75d0 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-872 .elementor-element.elementor-element-39c2827b .elementor-spacer-inner {
  height: 30px;
}

.elementor-872 .elementor-element.elementor-element-73075dae .elementor-divider-separator {
  border-top-style: solid;
  border-top-width: 2px;
  width: 100%;
}

.elementor-872 .elementor-element.elementor-element-73075dae .elementor-divider {
  padding-top: 0px;
  padding-bottom: 0px;
}

.elementor-872 .elementor-element.elementor-element-6a425bd6 .elementor-spacer-inner {
  height: 13px;
}

.elementor-872 .elementor-element.elementor-element-7cc27e17 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-872 .elementor-element.elementor-element-497bd034 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-872 .elementor-element.elementor-element-2cd3ae93 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-872 .elementor-element.elementor-element-6357fdf2 .elementor-spacer-inner {
  height: 13px;
}

@media (min-width: 768px) {
  .elementor-872 .elementor-element.elementor-element-7e68f05 {
    width: 22.018%;
  }

  .elementor-872 .elementor-element.elementor-element-bd100bb {
    width: 40.979%;
  }

  .elementor-872 .elementor-element.elementor-element-e6cb6af {
    width: 36.999%;
  }

  .elementor-872 .elementor-element.elementor-element-7cc27e17 {
    width: 60.092%;
  }

  .elementor-872 .elementor-element.elementor-element-497bd034 {
    width: 39.908%;
  }
}

@media (max-width: 1024px) {
  .elementor-872 .elementor-element.elementor-element-61c0e418 .elementor-spacer-inner {
    height: 20px;
  }
}

@media (max-width: 767px) {
  .elementor-872 .elementor-element.elementor-element-4d806f3c .elementor-spacer-inner {
    height: 18px;
  }

  .elementor-872 .elementor-element.elementor-element-61c0e418 .elementor-spacer-inner {
    height: 10px;
  }

  .elementor-872 .elementor-element.elementor-element-6743357d .elementor-spacer-inner {
    height: 18px;
  }

  .elementor-872 .elementor-element.elementor-element-39c2827b .elementor-spacer-inner {
    height: 25px;
  }

  .elementor-872 .elementor-element.elementor-element-6a425bd6 .elementor-spacer-inner {
    height: 12px;
  }

  .elementor-872 .elementor-element.elementor-element-6357fdf2 .elementor-spacer-inner {
    height: 12px;
  }
}

/*! CSS Used from: Embedded */
.fcunited_inline_430234963 {
  background-color: #081224;
}

.trx_addons_inline_388837223 img {
  max-height: 80px;
}

/*! CSS Used from: Embedded */
#MagiCSS-bookmarklet,
html > body #MagiCSS-bookmarklet {
  display: block;
}

/*! CSS Used from: Embedded */
#MagiCSS-bookmarklet .cancelDragHandle {
  cursor: default;
}

/*! CSS Used keyframes */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/*! CSS Used from: http://fc-united.axiomthemes.com/wp-content/plugins/trx_addons/css/__styles.css ; media=all */
@media all {
  .breadcrumbs {
    font-size: 13px;
    line-height: 19px;
  }

  .breadcrumbs .breadcrumbs_item {
    display: inline-block;
    vertical-align: top;
  }

  .breadcrumbs .breadcrumbs_delimiter {
    display: inline-block;
    position: relative;
    margin: 0 0.45em;
    vertical-align: top;
    text-align: center;
  }

  .breadcrumbs .breadcrumbs_delimiter:before {
    content: "/";
  }

  .elementor-container:not(.elementor-column-gap-no)
    > .elementor-row
    > .elementor-column
    > .elementor-element-populated {
    padding-top: 0;
    padding-bottom: 0;
  }

  .elementor-section {
    position: relative;
  }

  .sc_layouts_item {
    max-width: 100%;
  }

  .sc_inner_width_none > .elementor-column-wrap {
    width: 100%;
  }

  .sc_layouts_title_caption {
    margin: 0 auto;
  }

  .sc_layouts_title_title > .sc_layouts_title_caption {
    float: none;
  }

  .sc_layouts_title_breadcrumbs {
    margin-top: 0.5em;
  }

  .sc_layouts_title.with_content {
    position: relative;
    min-height: 0;
  }

  .sc_layouts_title .sc_layouts_title_content {
    position: static;
  }
}

/*! CSS Used from: http://fc-united.axiomthemes.com/wp-content/plugins/trx_addons/css/trx_addons.animation.css ; media=all */
@media all {
  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: visible;
  }

  .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }
}

/*! CSS Used from: http://fc-united.axiomthemes.com/wp-content/plugins/elementor/assets/lib/animations/animations.min.css?ver=2.5.15 ; media=all */
@media all {
  .fadeIn {
    animation-name: fadeIn;
  }
}

/*! CSS Used from: http://fc-united.axiomthemes.com/wp-content/plugins/elementor/assets/css/frontend.min.css?ver=2.5.15 ; media=all */
@media all {
  .elementor *,
  .elementor :after,
  .elementor :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .elementor a {
    -webkit-box-shadow: none;
    box-shadow: none;
    text-decoration: none;
  }

  .elementor-section {
    position: relative;
  }

  .elementor-section .elementor-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative;
  }

  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 1140px;
  }

  .elementor-row {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  @media (max-width: 1024px) {
    .elementor-row {
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
  }

  .elementor-column-wrap {
    width: 100%;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .elementor-widget-wrap {
    position: relative;
    width: 100%;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }

  .elementor-widget-wrap > .elementor-element {
    width: 100%;
  }

  .elementor-widget {
    position: relative;
  }

  .elementor-widget:not(:last-child) {
    margin-bottom: 20px;
  }

  .elementor-column {
    position: relative;
    min-height: 1px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
    padding: 15px;
  }

  @media (min-width: 768px) {
    .elementor-column.elementor-col-100 {
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    .elementor-column {
      width: 100%;
    }
  }

  .elementor-element .elementor-widget-container {
    -webkit-transition:
      background 0.3s,
      border 0.3s,
      -webkit-border-radius 0.3s,
      -webkit-box-shadow 0.3s;
    transition:
      background 0.3s,
      border 0.3s,
      -webkit-border-radius 0.3s,
      -webkit-box-shadow 0.3s;
    -o-transition:
      background 0.3s,
      border 0.3s,
      border-radius 0.3s,
      box-shadow 0.3s;
    transition:
      background 0.3s,
      border 0.3s,
      border-radius 0.3s,
      box-shadow 0.3s;
    transition:
      background 0.3s,
      border 0.3s,
      border-radius 0.3s,
      box-shadow 0.3s,
      -webkit-border-radius 0.3s,
      -webkit-box-shadow 0.3s;
  }

  .animated {
    -webkit-animation-duration: 1.25s;
    animation-duration: 1.25s;
  }
}

/*! CSS Used from: Embedded */
.elementor-section.elementor-section-boxed > .elementor-container {
  max-width: 1308px;
}

.elementor-widget:not(:last-child) {
  margin-bottom: 0px;
}

@media all {
  div,
  span,
  h1,
  a,
  section {
    font-family: inherit;
    font-size: 100%;
    line-height: inherit;
    font-weight: inherit;
    font-style: inherit;
    border: 0;
    outline: 0;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
  }

  section {
    display: block;
  }

  a {
    text-decoration: none;
    background: 0 0;
  }

  a,
  a:hover,
  a:focus,
  a:active {
    outline: 0;
  }

  a {
    -webkit-transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
    -ms-transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
    transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
  }

  :active,
  :focus {
    outline: 0;
  }

  .sc_layouts_title_caption {
    font-size: 72px;
  }

  .breadcrumbs {
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
  }

  .breadcrumbs .breadcrumbs_delimiter {
    margin: 0 0.15em;
  }
}

/*! CSS Used from: http://fc-united.axiomthemes.com/wp-content/themes/fcunited/css/__plugins.css ; media=all */
@media all {
  .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
    padding: 15px;
  }
}

/*! CSS Used from: http://fc-united.axiomthemes.com/wp-content/themes/fcunited/css/__custom.css ; media=all */
@media all {
  h1 {
    font-family: Roboto, sans-serif;
    font-size: 4.286em;
    font-weight: 700;
    font-style: normal;
    line-height: 0.93em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 2.1px;
    margin-top: 1.69em;
    margin-bottom: 0.63em;
  }
}

/*! CSS Used from: http://fc-united.axiomthemes.com/wp-content/themes/fcunited/css/__colors-default.css ; media=all */
@media all {
  .scheme_default h1 {
    color: #262f3e;
  }

  .scheme_default a {
    color: red;
  }

  .scheme_default a:hover {
    color: #081324;
  }

  .scheme_default .sc_layouts_title .sc_layouts_title_breadcrumbs,
  .scheme_default .sc_layouts_title .sc_layouts_title_breadcrumbs a {
    color: #262f3e;
  }

  .scheme_default .sc_layouts_title .sc_layouts_title_breadcrumbs a:hover {
    color: #081324;
  }
}

/*! CSS Used from: http://fc-united.axiomthemes.com/wp-content/themes/fcunited/css/__colors-dark.css ; media=all */
@media all {
  .scheme_dark {
    color: #848992;
  }

  .scheme_dark h1 {
    color: #fff;
  }

  .scheme_dark a {
    color: red;
  }

  .scheme_dark a:hover {
    color: #fff;
  }

  .scheme_dark .sc_layouts_title .sc_layouts_title_breadcrumbs,
  .scheme_dark .sc_layouts_title .sc_layouts_title_breadcrumbs a {
    color: #fff;
  }

  .scheme_dark .sc_layouts_title .sc_layouts_title_breadcrumbs a:hover {
    color: #fff;
  }
}

/*! CSS Used from: http://fc-united.axiomthemes.com/wp-content/plugins/trx_addons/css/__responsive.css ; media=all */
@media all {
  @media (max-width: 1023px) {
    .sc_layouts_title_title {
      font-size: 0.8em;
    }
  }

  @media (max-width: 767px) {
    .sc_layouts_hide_on_mobile {
      display: none !important;
    }
  }

  @media (max-width: 479px) {
    .sc_layouts_title_title {
      font-size: 0.7em;
    }
  }

  @media (min-width: 2160px) {
    .sc_layouts_hide_on_wide {
      display: none !important;
    }
  }

  @media (min-width: 1680px) and (max-width: 2159px) {
    .sc_layouts_hide_on_desktop {
      display: none !important;
    }
  }

  @media (min-width: 1280px) and (max-width: 1679px) {
    .sc_layouts_hide_on_notebook {
      display: none !important;
    }
  }

  @media (min-width: 768px) and (max-width: 1279px) {
    .sc_layouts_hide_on_tablet {
      display: none !important;
    }
  }
}

/*! CSS Used from: http://fc-united.axiomthemes.com/wp-content/themes/fcunited/css/__responsive.css ; media=all */
@media all {
  @media (max-width: 1439px) {
    .elementor-section.elementor-section-boxed > .elementor-container {
      max-width: 1130px;
    }

    .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  @media (max-width: 1279px) {
    .sc_layouts_title_caption {
      font-size: 4rem;
    }

    h1 {
      font-size: 3.7em;
      margin-top: 55px;
      margin-bottom: 22px;
      letter-spacing: 1.5px;
    }

    .elementor-section.elementor-section-boxed > .elementor-container {
      max-width: 994px;
    }

    .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  @media (max-width: 1023px) {
    .sc_layouts_title_caption {
      font-size: 3rem;
    }

    h1 {
      font-size: 3em;
      margin-top: 45px;
      margin-bottom: 15px;
      letter-spacing: 1.3px;
    }

    .elementor-section.elementor-section-boxed > .elementor-container {
      max-width: 738px;
    }

    .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  @media (max-width: 767px) {
    .sc_layouts_title_caption {
      font-size: 2.6rem;
    }

    h1 {
      font-size: 2.6em;
      margin-top: 40px;
      margin-bottom: 15px;
      letter-spacing: 1.3px;
    }

    .elementor-section.elementor-section-boxed > .elementor-container {
      max-width: 460px;
    }

    .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  @media (max-width: 479px) {
    h1 {
      hyphens: manual;
    }

    .sc_layouts_title_caption {
      font-size: 2.2rem;
    }

    h1 {
      font-size: 2.2em;
      margin-top: 28px;
      margin-bottom: 12px;
      letter-spacing: 1px;
      line-height: 0.93em;
    }

    .elementor-section.elementor-section-boxed > .elementor-container {
      max-width: 300px;
    }

    .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

/*! CSS Used from: Embedded */
.elementor-6 .elementor-element.elementor-element-b70eb50:not(.elementor-motion-effects-element-type-background) {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #091223;
}

.elementor-6 .elementor-element.elementor-element-b70eb50 {
  transition:
    background 0.3s,
    border 0.3s,
    border-radius 0.3s,
    box-shadow 0.3s;
}

.elementor-6 .elementor-element.elementor-element-ed1b682 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-6 .elementor-element.elementor-element-6850b4b .elementor-spacer-inner {
  height: 285px;
}

.elementor-6 .elementor-element.elementor-element-b76221f .elementor-spacer-inner {
  height: 180px;
}

.elementor-6 .elementor-element.elementor-element-612f306 .sc_layouts_title {
  min-height: 0px;
}

.elementor-6 .elementor-element.elementor-element-01551c2 .elementor-spacer-inner {
  height: 61px;
}

@media (max-width: 1024px) {
  .elementor-6 .elementor-element.elementor-element-b76221f .elementor-spacer-inner {
    height: 120px;
  }

  .elementor-6 .elementor-element.elementor-element-01551c2 .elementor-spacer-inner {
    height: 50px;
  }
}

@media (max-width: 767px) {
  .elementor-6 .elementor-element.elementor-element-b76221f .elementor-spacer-inner {
    height: 65px;
  }

  .elementor-6 .elementor-element.elementor-element-01551c2 .elementor-spacer-inner {
    height: 30px;
  }
}

/*! CSS Used keyframes */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/sportspress/assets/css/sportspress.css?ver=2.6.14 ; media=all */
@media all {
  .sp-header {
    position: relative;
    overflow: visible;
    z-index: 10000;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/trx_addons/css/font-icons/css/trx_addons_icons.css ; media=all */
@media all {
  [class^="trx_addons_icon-"]:before,
  [class*=" trx_addons_icon-"]:before {
    font-family: "trx_addons_icons";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: 0.2em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .trx_addons_icon-facebook:before {
    content: "\e815";
  }

  .trx_addons_icon-gplus:before {
    content: "\e81c";
  }

  .trx_addons_icon-twitter:before {
    content: "\e840";
  }

  .trx_addons_icon-up:before {
    content: "\e85a";
  }

  .trx_addons_icon-search:before {
    content: "\e898";
  }

  .trx_addons_icon-menu:before {
    content: "\e8bc";
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/trx_addons/js/magnific/magnific-popup.min.css ; media=all */
@media all {
  button::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/trx_addons/css/__styles.css ; media=all */
@media all {
  .trx_addons_scroll_to_top {
    display: block;
    background: #fff;
    cursor: pointer;
    opacity: 0;
    position: fixed;
    z-index: 100001;
    bottom: -4em;
    right: 2em;
    width: 3em;
    height: 3em;
    line-height: 3em;
    text-align: center;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .trx_addons_scroll_to_top:before {
    margin: 0 !important;
  }

  .breadcrumbs {
    font-size: 13px;
    line-height: 19px;
  }

  .breadcrumbs .breadcrumbs_item {
    display: inline-block;
    vertical-align: top;
  }

  .breadcrumbs .breadcrumbs_delimiter {
    display: inline-block;
    position: relative;
    margin: 0 0.45em;
    vertical-align: top;
    text-align: center;
  }

  .breadcrumbs .breadcrumbs_delimiter:before {
    content: "/";
  }

  [class^="trx_addons_icon-"]:before,
  [class*=" trx_addons_icon-"]:before {
    margin: 0;
    width: auto;
    font-size: inherit;
    line-height: inherit !important;
    font-weight: inherit;
  }

  .post_meta_item {
    margin-left: 0.5em;
    white-space: nowrap;
  }

  .post_meta_item:before {
    vertical-align: top;
    line-height: inherit;
    margin: 0 0.3em 0 0;
  }

  .socials_share {
    position: relative;
    vertical-align: top;
  }

  .socials_share.socials_wrap {
    margin: 0;
  }

  .widget_area .post_item,
  aside .post_item {
    overflow: hidden;
    text-align: left;
    margin-bottom: 0;
    background-color: transparent !important;
    position: relative;
  }

  aside .post_item + .post_item {
    margin-top: 1.6em;
  }

  .widget_area .post_item .post_content,
  aside .post_item .post_content {
    overflow: hidden;
  }

  .widget_area .post_item .post_thumb,
  aside .post_item .post_thumb {
    float: left;
    width: 5em;
    height: 5em;
    margin: 0 1em 4px 0;
    overflow: hidden;
  }

  .widget_area .post_item .post_thumb img,
  aside .post_item .post_thumb img {
    width: 100%;
  }

  .widget_area .post_item .post_title,
  aside .post_item .post_title {
    margin: -2px 0 4px 0;
    text-align: left;
    line-height: 1.25em;
    font-weight: 400;
  }

  .widget_area .post_item .post_info,
  aside .post_item .post_info {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 0;
  }

  .widget_area .post_item .post_info .post_info_item + .post_info_item:before,
  aside .post_item .post_info .post_info_item + .post_info_item:before {
    content: "";
    margin: 0 0 0 5px;
  }

  .clearfix:after {
    content: " ";
    clear: both;
    width: 100%;
    height: 0;
    display: block;
  }

  .sc_item_title {
    margin: 0;
  }

  .sc_item_title {
    line-height: 1.2em;
  }

  .elementor-container:not(.elementor-column-gap-no)
    > .elementor-row
    > .elementor-column
    > .elementor-element-populated {
    padding-top: 0;
    padding-bottom: 0;
  }

  .elementor-section {
    position: relative;
  }

  .elementor-widget-text-editor:last-child,
  .elementor-widget-text-editor p:last-child {
    margin-bottom: 0;
  }

  .sc_layouts_row {
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .sc_layouts_row .sc_layouts_item {
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
  }

  .sc_layouts_item {
    max-width: 100%;
  }

  .sc_layouts_column .sc_layouts_item {
    display: inline-block;
    vertical-align: middle;
    margin-right: 1.5em;
    width: auto !important;
  }

  .sc_layouts_column .sc_layouts_item:last-child {
    margin-right: 0 !important;
  }

  .sc_layouts_column_align_right .sc_layouts_item {
    margin-left: 1.5em;
    margin-right: 0;
  }

  .sc_layouts_column_align_right .sc_layouts_item:first-child {
    margin-left: 0 !important;
  }

  .sc_layouts_column_align_left {
    text-align: left;
  }

  .sc_layouts_column_align_right {
    text-align: right;
  }

  .sc_layouts_column_align_left > .elementor-column-wrap > .elementor-widget-wrap {
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .sc_layouts_column_align_right > .elementor-column-wrap > .elementor-widget-wrap {
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .elementor-section-content-middle .elementor-widget-wrap {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .sc_layouts_iconed_text {
    white-space: nowrap;
  }

  .sc_layouts_item_link {
    display: block;
  }

  .sc_layouts_item_icon {
    font-size: 2.25em;
    line-height: 1.1em;
    display: inline-block;
    vertical-align: middle;
    -webkit-transition: color 0.3s ease;
    -ms-transition: color 0.3s ease;
    transition: color 0.3s ease;
  }

  .sc_layouts_row_type_normal .sc_layouts_item {
    letter-spacing: 0;
  }

  .sc_layouts_row_type_normal {
    padding: 2em 0;
  }

  .sc_layouts_row_fixed_placeholder {
    display: none;
  }

  .sc_inner_width_none > .elementor-column-wrap {
    width: 100%;
  }

  .socials_wrap {
    display: block;
    vertical-align: middle;
    white-space: nowrap;
    margin: -8px 0 0 0;
  }

  .socials_wrap .social_item {
    display: inline-block;
    line-height: inherit;
    vertical-align: top;
    text-align: center;
    margin: 8px 8px 0 0;
  }

  .socials_wrap .social_item:last-child {
    margin-right: 0 !important;
  }

  .socials_wrap .social_item .social_icon {
    position: relative;
    display: block;
    background-size: 100%;
    background-position: top center;
    overflow: hidden;
    width: 3em;
    height: 3em;
    line-height: 3em;
    text-align: center;
    -webkit-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
  }

  .socials_wrap .social_item .social_icon {
    -webkit-transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
    -ms-transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
    transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
  }

  .sc_title {
    position: relative;
  }

  .widget_banner .image_wrap {
    overflow: hidden;
    display: block;
    margin-bottom: 1em;
  }

  .widget_banner .image_wrap img {
    width: 100%;
    height: auto;
  }

  .widget_twitter .widget_content ul {
    padding-left: 2em;
    list-style-type: none;
  }

  .widget_twitter .widget_content ul > li {
    position: relative;
    text-align: left;
    color: #bbb;
  }

  .widget_twitter .widget_content ul > li + li {
    margin-top: 1.5em;
  }

  .widget_twitter .widget_content ul > li:before {
    content: "\e840";
    font-family: "trx_addons_icons";
    font-size: 1.2em;
    font-style: normal;
    display: inline-block;
    background-color: transparent !important;
    position: absolute;
    z-index: 1;
    top: 0;
    left: -1.5em;
    width: 1em;
    height: 1em;
    line-height: inherit;
  }

  .widget_twitter .widget_content ul > li a {
    font-weight: normal;
  }

  .sc_layouts_logo img {
    max-height: 6em;
    width: auto;
  }

  .sc_layouts_menu_mobile_button {
    display: none !important;
  }

  .sc_layouts_menu_mobile_button .sc_layouts_item_icon,
  .sc_layouts_menu_mobile_button_burger .sc_layouts_item_icon {
    font-size: 2em;
  }

  .sc_layouts_menu_mobile_button .sc_layouts_item_icon:before,
  .sc_layouts_menu_mobile_button_burger .sc_layouts_item_icon:before {
    line-height: 1.2em;
  }

  .sc_layouts_menu {
    position: relative;
    z-index: 1000;
    overflow: hidden;
    visibility: hidden;
    max-height: 3em;
  }

  .sc_layouts_menu.inited {
    overflow: visible;
    visibility: visible;
    max-height: none;
  }

  .sc_layouts_menu_nav,
  .sc_layouts_menu_nav ul {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style-type: none !important;
  }

  .sc_layouts_menu_nav:after,
  .sc_layouts_menu_nav ul:after {
    content: " ";
    clear: both;
    width: 100%;
    height: 0;
    display: block;
  }

  .sc_layouts_menu_nav li {
    margin: 0;
    position: relative;
  }

  .sc_layouts_menu_nav li > a {
    display: block;
    position: relative;
  }

  .sc_layouts_menu_nav {
    text-align: left;
    vertical-align: middle;
    padding-left: 0 !important;
  }

  .sc_layouts_menu_nav > li {
    position: relative;
    float: left;
    z-index: 20;
  }

  .sc_layouts_menu_nav > li + li {
    z-index: 19;
  }

  .sc_layouts_menu_nav > li + li + li {
    z-index: 18;
  }

  .sc_layouts_menu_nav > li + li + li + li {
    z-index: 17;
  }

  .sc_layouts_menu_nav > li + li + li + li + li {
    z-index: 16;
  }

  .sc_layouts_menu_nav > li + li + li + li + li + li {
    z-index: 15;
  }

  .sc_layouts_menu_nav > li + li + li + li + li + li + li {
    z-index: 14;
  }

  .sc_layouts_menu_nav > li > a {
    padding: 1em;
    position: relative;
    color: #333;
    -webkit-transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
    -ms-transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
    transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
  }

  .sc_layouts_menu_nav > li > a:hover {
    color: #efa758;
  }

  .sc_layouts_menu_nav > li.menu-item-has-children > a {
    padding-right: 2em;
  }

  .sc_layouts_menu_nav > li.menu-item-has-children > a:after {
    content: "\e882";
    font-family: "trx_addons_icons";
    font-weight: normal;
    display: inline-block;
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 1em;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: color 0.3s ease;
    -ms-transition: color 0.3s ease;
    transition: color 0.3s ease;
  }

  .sc_layouts_menu_nav li li.menu-item-has-children > a:after {
    content: "\e884";
    font-family: "trx_addons_icons";
    font-weight: normal;
    position: absolute;
    z-index: 1;
    top: 0.65em;
    right: 0.75em;
    -webkit-transition: color 0.3s ease;
    -ms-transition: color 0.3s ease;
    transition: color 0.3s ease;
  }

  .sc_layouts_menu_nav > li ul {
    position: absolute;
    display: none;
    z-index: 10000;
    width: 13em;
    padding: 1.4em 0;
    text-align: left;
    background-color: #f0f0f0;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.2);
  }

  .sc_layouts_menu_nav > li li > a {
    padding: 0.6em 1.5em;
    color: #333;
  }

  .sc_layouts_menu_nav > li li > a:hover {
    color: #efa758;
  }

  .sc_layouts_menu_nav > li > ul {
    top: 4.2em;
    left: 0;
  }

  .sc_layouts_menu_nav > li > ul ul {
    left: 13em;
    top: -1.4em;
    margin: 0 0 0 2px;
    background-color: #f0f0f0;
  }

  .sc_layouts_menu_mobile_button_burger {
    position: relative;
  }

  .search_wrap,
  .search_wrap .search_form {
    position: relative;
  }

  .search_wrap .search_field {
    width: 6em;
    padding: 0 0 0 1.25em !important;
    vertical-align: middle;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .search_wrap .search_submit {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    background-color: transparent;
    padding: 0;
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
  }

  .search_wrap .search_submit:before {
    margin: 0;
  }

  .sc_layouts_row_type_normal .search_wrap .search_field {
    font-size: 11px;
    text-transform: uppercase;
    padding: 0 1.5em 0 3em !important;
    width: 15em;
    height: 4.7727em;
    line-height: 4.7727em;
    -webkit-border-radius: 3em;
    -ms-border-radius: 3em;
    border-radius: 3em;
  }

  .sc_layouts_row_type_normal .search_wrap .search_submit {
    display: block;
    font-size: 11px;
    text-align: center;
    width: 1em;
    height: 4.7727em;
    line-height: 4.7727em;
    position: absolute;
    top: 0;
    right: auto;
    bottom: auto;
    left: 1em;
  }

  .sc_layouts_row_type_normal .search_wrap .search_submit:before {
    font-size: 13px;
  }

  .sc_layouts_title_caption {
    margin: 0 auto;
  }

  .sc_layouts_title_title > .sc_layouts_title_caption {
    float: none;
  }

  .sc_layouts_title_breadcrumbs {
    margin-top: 0.5em;
  }

  .sc_layouts_title.with_content {
    position: relative;
    min-height: 0em;
  }

  .sc_layouts_title .sc_layouts_title_content {
    position: static;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/trx_addons/css/trx_addons.animation.css ; media=all */
@media all {
  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: visible;
  }

  .animated.fast {
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
  }

  .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }

  .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/woocommerce/assets/css/woocommerce.css?ver=3.6.2 ; media=all */
@media all {
  .screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/elementor/assets/lib/animations/animations.min.css?ver=2.5.15 ; media=all */
@media all {
  .fadeIn {
    animation-name: fadeIn;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/elementor/assets/css/frontend.min.css?ver=2.5.15 ; media=all */
@media all {
  .elementor-screen-only,
  .screen-reader-text {
    position: absolute;
    top: -10000em;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .elementor-clearfix:after {
    content: "";
    display: block;
    clear: both;
    width: 0;
    height: 0;
  }

  .elementor {
    -webkit-hyphens: manual;
    -ms-hyphens: manual;
    hyphens: manual;
  }

  .elementor *,
  .elementor :after,
  .elementor :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .elementor a {
    -webkit-box-shadow: none;
    box-shadow: none;
    text-decoration: none;
  }

  .elementor img {
    height: auto;
    max-width: 100%;
    border: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .elementor-invisible {
    visibility: hidden;
  }

  .elementor-section {
    position: relative;
  }

  .elementor-section .elementor-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative;
  }

  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 1140px;
  }

  .elementor-section-content-middle
    > .elementor-container
    > .elementor-row
    > .elementor-column
    > .elementor-column-wrap {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .elementor-row {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  @media (max-width: 1024px) {
    .elementor-row {
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
  }

  .elementor-column-wrap {
    width: 100%;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .elementor-widget-wrap {
    position: relative;
    width: 100%;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }

  .elementor-widget-wrap > .elementor-element {
    width: 100%;
  }

  .elementor-widget {
    position: relative;
  }

  .elementor-widget:not(:last-child) {
    margin-bottom: 20px;
  }

  .elementor-column {
    position: relative;
    min-height: 1px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
    padding: 15px;
  }

  @media (min-width: 768px) {
    .elementor-column.elementor-col-33 {
      width: 33.333%;
    }

    .elementor-column.elementor-col-50 {
      width: 50%;
    }

    .elementor-column.elementor-col-100 {
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    .elementor-column {
      width: 100%;
    }
  }

  @media (min-width: 1025px) {
    #elementor-device-mode:after {
      content: "desktop";
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    #elementor-device-mode:after {
      content: "tablet";
    }
  }

  @media (max-width: 767px) {
    #elementor-device-mode:after {
      content: "mobile";
    }
  }

  .elementor-element .elementor-widget-container {
    -webkit-transition:
      background 0.3s,
      border 0.3s,
      -webkit-border-radius 0.3s,
      -webkit-box-shadow 0.3s;
    transition:
      background 0.3s,
      border 0.3s,
      -webkit-border-radius 0.3s,
      -webkit-box-shadow 0.3s;
    -o-transition:
      background 0.3s,
      border 0.3s,
      border-radius 0.3s,
      box-shadow 0.3s;
    transition:
      background 0.3s,
      border 0.3s,
      border-radius 0.3s,
      box-shadow 0.3s;
    transition:
      background 0.3s,
      border 0.3s,
      border-radius 0.3s,
      box-shadow 0.3s,
      -webkit-border-radius 0.3s,
      -webkit-box-shadow 0.3s;
  }

  .elementor-widget-divider .elementor-divider {
    line-height: 0;
    font-size: 0;
  }

  .elementor-widget-divider .elementor-divider-separator {
    display: inline-block;
  }

  .animated {
    -webkit-animation-duration: 1.25s;
    animation-duration: 1.25s;
  }
}

/*! CSS Used from: Embedded */
.elementor-section.elementor-section-boxed > .elementor-container {
  max-width: 1308px;
}

.elementor-widget:not(:last-child) {
  margin-bottom: 0px;
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/font-icons/css/fontello.css ; media=all */
@media all {
  [class*=" icon-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: 0.2em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-cancel:before {
    content: "\e8ac";
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/style.css ; media=all */
@media all {
  body,
  div,
  span,
  h1,
  h2,
  h3,
  h5,
  h6,
  p,
  a,
  small,
  ul,
  li,
  form,
  label,
  input,
  textarea,
  button,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  section {
    font-family: inherit;
    font-size: 100%;
    line-height: inherit;
    font-weight: inherit;
    font-style: inherit;
    border: 0;
    outline: 0;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
  }

  body {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  article,
  aside,
  footer,
  header,
  nav,
  section {
    display: block;
  }

  li > ul {
    margin-bottom: 0;
  }

  ul {
    padding-left: 1.5em;
  }

  a {
    text-decoration: none;
    background: transparent;
  }

  a,
  a:hover,
  a:focus,
  a:active {
    outline: 0;
  }

  a img {
    border: none;
  }

  a,
  button,
  input[type="submit"] {
    -webkit-transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
    -ms-transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
    transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
  }

  h6 > a {
    display: block;
  }

  :active,
  :focus {
    outline: 0;
  }

  table {
    border-collapse: collapse;
  }

  table th + th,
  table td + td {
    border-left: 2px solid #ddd;
  }

  table td,
  table th {
    padding: 1.95em 1.1em;
  }

  table th {
    text-transform: none;
    text-align: center;
  }

  caption {
    font-weight: 700;
    text-align: center;
  }

  small {
    font-size: 80%;
  }

  img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
  }

  [class*=" icon-"]:before {
    font-size: inherit;
    line-height: inherit !important;
    font-weight: inherit;
    font-style: inherit;
    display: inline-block;
    vertical-align: top;
    width: auto;
    margin: 0;
  }

  form {
    margin-bottom: 0;
  }

  button,
  input,
  textarea {
    font-family: inherit;
    font-size: 1em;
    margin: 0;
    vertical-align: baseline;
  }

  button {
    overflow: visible;
  }

  input[placeholder]::-webkit-input-placeholder,
  textarea[placeholder]::-webkit-input-placeholder {
    text-overflow: ellipsis;
  }

  input[placeholder]::-moz-placeholder,
  textarea[placeholder]::-moz-placeholder {
    text-overflow: ellipsis;
  }

  input[placeholder]:-ms-input-placeholder,
  textarea[placeholder]:-ms-input-placeholder {
    text-overflow: ellipsis;
  }

  input[placeholder]::placeholder,
  textarea[placeholder]::placeholder {
    text-overflow: ellipsis;
  }

  input[placeholder],
  textarea[placeholder] {
    text-overflow: ellipsis;
  }

  textarea,
  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="search"],
  input[type="checkbox"] {
    -webkit-transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
    -ms-transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
    transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
  }

  input[type="checkbox"] {
    padding: 0;
  }

  input[type="search"] {
    -webkit-appearance: none;
    -webkit-appearance: textfield;
  }

  button::-moz-focus-inner,
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  html input[disabled] {
    cursor: default !important;
  }

  button:focus,
  input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="search"]:focus,
  textarea:focus {
    outline: none;
  }

  input[type="text"],
  input[type="email"],
  input[type="search"],
  textarea {
    -webkit-appearance: none;
    outline: none;
    resize: none;
  }

  label.required:after {
    content: "*";
    display: none;
    vertical-align: text-top;
    font-size: 80%;
    color: #da6f5b;
  }

  form button,
  input[type="submit"],
  .mc4wp-form .mc4wp-form-fields input[type="submit"] {
    -webkit-appearance: none;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    text-transform: none;
    white-space: nowrap;
    padding: 1.38em 3.5em;
    letter-spacing: 0;
    height: auto;
    border-width: 0 !important;
    -webkit-border-radius: 30px;
    -ms-border-radius: 30px;
    border-radius: 30px;
  }

  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="search"],
  textarea,
  form.mc4wp-form .mc4wp-form-fields input[type="email"] {
    padding: 0.94em 1.5em;
    border: 2px solid #ddd;
    -webkit-border-radius: 28px;
    -ms-border-radius: 28px;
    border-radius: 28px;
  }

  textarea {
    overflow: auto;
    vertical-align: top;
    min-height: 10em;
  }

  .widget input[type="search"] {
    padding: 0.94em 1.5em;
  }

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] + label {
    position: relative;
    padding-left: 1.4em;
    line-height: 1.5em;
    display: inline-block;
    vertical-align: top;
  }

  input[type="checkbox"] + label:before {
    content: " ";
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
    font-size: 1em;
    display: block;
    text-align: center;
    border: 2px solid #e4e4e4;
    width: 1em;
    height: 1em;
    line-height: 0.53em;
    position: absolute;
    z-index: 1;
    top: 0.2em;
    left: 0;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  input[type="checkbox"]:checked + label:before {
    content: "\F00C";
  }

  .screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .screen-reader-text:hover,
  .screen-reader-text:active,
  .screen-reader-text:focus {
    display: block;
    top: 5px;
    left: 5px;
    width: auto;
    height: auto;
    font-size: 0.8em;
    line-height: normal;
    padding: 1em 1.5em;
    color: #21759b;
    background-color: #f1f1f1;
    border-radius: 3px;
    clip: auto !important;
    text-decoration: none;
    z-index: 100000;
    -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    -ms-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  }

  .columns_wrap {
    margin-left: 0px;
    margin-right: -30px;
  }

  .columns_wrap > [class*="column-"] {
    display: inline-block;
    vertical-align: top;
    position: relative;
    min-height: 1px;
    padding-left: 0;
    padding-right: 30px;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .columns_wrap.columns_padding_bottom > [class*="column-"] {
    padding-bottom: 30px;
  }

  .column-1_2 {
    width: 50%;
  }

  .clearfix:after,
  .columns_wrap:after {
    content: " ";
    clear: both;
    width: 100%;
    height: 0;
    display: block;
  }

  .page_wrap {
    min-height: 100vh;
    overflow: hidden;
  }

  .page_wrap,
  .content_wrap {
    margin: 0 auto;
  }

  .page_content_wrap {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .content_wrap {
    width: 1278px;
    margin: 0 auto;
  }

  .content_wrap:after {
    content: " ";
    clear: both;
    width: 100%;
    height: 0;
    display: block;
  }

  .content,
  .sidebar,
  .sidebar_inner {
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .page_content_wrap .content_wrap {
    position: relative;
  }

  .body_style_wide:not(.expand_content) [class*="content_wrap"] > .content {
    width: 818px;
  }

  [class*="content_wrap"] > .sidebar {
    width: 435px;
  }

  .sidebar_right [class*="content_wrap"] > .content {
    float: left;
  }

  .sidebar_right [class*="content_wrap"] > .sidebar {
    float: right;
  }

  .sc_layouts_menu_mobile_button .sc_layouts_item_icon:before {
    content: "\e8ba";
    font-family: "fontello";
  }

  .menu_mobile_close {
    display: block;
    cursor: pointer;
    width: 5em;
    height: 5em;
    line-height: 5em;
    text-align: center;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
  }

  .menu_mobile_close:before,
  .menu_mobile_close:after {
    content: " ";
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 38%;
    width: 25%;
    height: 0;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    margin-top: -1px;
    border-top: 2px solid #fff;
  }

  .menu_mobile_close:before {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .menu_mobile_close:after {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .menu_mobile_overlay {
    display: none !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 100000;
  }

  .menu_mobile {
    position: fixed;
    z-index: 100002;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    -webkit-transition: height 0.3s ease;
    -ms-transition: height 0.3s ease;
    transition: height 0.3s ease;
  }

  .menu_mobile_inner {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    overflow: hidden;
  }

  .menu_mobile li > a {
    display: block;
  }

  .menu_mobile .sc_layouts_logo {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    margin: 4.4rem 0 0;
    font-size: 1em !important;
  }

  .menu_mobile .menu_mobile_nav_area {
    position: absolute;
    z-index: 1;
    top: 48%;
    left: 0;
    width: 100%;
    height: auto;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    max-height: 49%;
    padding-right: 20px;
    overflow-y: scroll;
  }

  .menu_mobile .menu_mobile_nav_area ul {
    margin: 0 auto;
    padding: 0;
    list-style: none;
    width: auto;
  }

  .menu_mobile .menu_mobile_nav_area ul ul {
    margin: 5px 0;
    display: none;
  }

  .menu_mobile .menu_mobile_nav_area li {
    margin-bottom: 0;
    width: auto;
  }

  .menu_mobile .menu_mobile_nav_area li:before {
    display: none;
  }

  .menu_mobile .menu_mobile_nav_area li > a {
    font-size: 1.25em;
    line-height: 1.2em;
    padding: 1px 2.5em 1px 0;
    text-transform: none;
    letter-spacing: 0.5px;
    position: relative;
    display: inline-block;
    width: 28rem;
    text-align: left;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    font-weight: 500;
  }

  .menu_mobile .menu_mobile_nav_area > ul > li > a {
    font-size: 20px;
    line-height: 1.2em;
    padding: 5px 0;
    padding-right: 30px;
  }

  .menu_mobile .menu_mobile_nav_area > ul > li li > a {
    padding-left: 1em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
  }

  .menu_mobile .menu_mobile_nav_area > ul > li li li > a {
    padding-left: 2em;
  }

  .menu_mobile .menu_mobile_nav_area > ul > li li li li > a {
    padding-left: 3em;
  }

  .menu_mobile .menu_mobile_nav_area .open_child_menu {
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    width: 1.3rem;
    height: 1.3rem;
    line-height: 1.3rem;
    text-align: center;
    padding: 0.35rem;
    background-color: rgba(255, 255, 255, 0.1);
    -webkit-transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
    -ms-transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
    transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    top: 5px;
  }

  .menu_mobile .menu_mobile_nav_area li li .open_child_menu {
    background-color: transparent;
    padding: 0.35rem;
    top: 2px;
  }

  .menu_mobile .menu_mobile_nav_area a:hover > .open_child_menu {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .menu_mobile .menu_mobile_nav_area .open_child_menu:before {
    font-family: "fontello";
    content: "\e828";
  }

  .menu_mobile .sc_layouts_logo img {
    max-height: 5em;
  }

  .menu_mobile .search_mobile {
    position: absolute;
    z-index: 1;
    bottom: 8rem;
    left: 50%;
    width: 28rem;
    margin-left: -14.5rem;
  }

  .menu_mobile .search_mobile form {
    position: relative;
  }

  .menu_mobile .search_mobile .search_field {
    width: 100%;
    padding: 1em 3em 1em 1.5em !important;
    border: none;
  }

  .menu_mobile .search_mobile .search_submit {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
    font-size: 14px;
    padding: 0.9em 1.5em;
    background-color: transparent;
  }

  .header_content_wrap {
    margin-bottom: 0;
  }

  .header_content_wrap .post_header {
    margin-bottom: 0;
  }

  .header_content_wrap .post_featured {
    margin: 0;
    overflow: visible;
  }

  .post_header_position_under .header_content_wrap .post_header {
    margin-top: 0;
  }

  .header_content_wrap .post_header .post_meta_other {
    margin-top: 0;
  }

  .page_content_wrap {
    position: relative;
  }

  .post_featured {
    overflow: hidden;
    position: relative;
    margin-bottom: 1.8em;
  }

  .post_info .post_info_item.post_info_posted_by {
    font-weight: 400;
  }

  .post_info .post_info_item a.post_info_author {
    font-weight: 700;
  }

  .widget_area .post_item .post_info .post_info_item + .post_info_item:before,
  aside .post_item .post_info .post_info_item + .post_info_item:before {
    margin: 0 0 0 16px;
  }

  .post_meta .post_meta_item {
    font-size: inherit;
    margin-left: 0;
    white-space: normal;
    -webkit-transition: color 0.3s ease;
    -ms-transition: color 0.3s ease;
    transition: color 0.3s ease;
  }

  .post_meta .post_meta_item:after {
    content: "";
    display: inline;
    font-family: inherit;
    font-size: inherit;
    font-weight: 400;
    font-style: normal;
    vertical-align: baseline;
    margin: 0 0.4em;
  }

  .post_meta .post_meta_item:last-child:after {
    display: none;
  }

  .post_meta_item.post_categories a {
    padding: 5px 16px;
    -webkit-border-radius: 15px;
    -ms-border-radius: 15px;
    border-radius: 15px;
    margin-right: 3px;
  }

  .post_meta_item.post_categories a:last-child {
    margin-right: 0;
  }

  .post_meta_item.post_categories {
    margin-left: -3px;
  }

  .post_share .social_items {
    font-size: 1em;
  }

  .post_meta .socials_share {
    display: inline;
    vertical-align: baseline;
  }

  .post_meta_item .socials_share .social_items {
    bottom: auto;
    top: 2.5em;
    background: transparent;
  }

  .post_meta_item .socials_share .social_items:before {
    bottom: auto;
    top: -5px;
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  .post_featured .mask {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .nav-links {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 2em;
    text-align: left;
    border: none;
    list-style-type: none;
    clear: both;
  }

  .nav-links-single .nav-links {
    font-size: 1em;
    text-align: left;
    margin-top: 4.3em;
    overflow: hidden;
    text-transform: none;
  }

  .nav-links-single .nav-links a {
    display: block;
    position: relative;
    min-height: 3em;
    z-index: 1;
  }

  .nav-links-single .nav-links a .nav-arrow {
    width: 5em;
    height: 4.6em;
    line-height: 4.6em;
    overflow: hidden;
    text-align: center;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: none;
  }

  .nav-links-single .nav-links a .nav-arrow:before {
    content: " ";
    display: block;
    background-color: #fff;
    opacity: 0;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .nav-links-single .nav-links a:hover .nav-arrow:before {
    opacity: 0.5;
  }

  .nav-links-single .nav-links a .nav-arrow:after {
    content: "\e939";
    font-family: "fontello";
    display: inline-block;
    position: relative;
    z-index: 2;
    top: 3em;
    opacity: 0;
    color: #292929;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .nav-links-single .nav-links a:hover .nav-arrow:after {
    top: 0;
    opacity: 1;
  }

  .nav-links-single .nav-links .nav-previous {
    width: 49%;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    padding: 25px 20px 23px;
    position: relative;
    z-index: 1;
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }

  .nav-links-single .nav-links .nav-previous {
    float: left;
    text-align: left;
  }

  .nav-links-single .nav-links .nav-previous a {
    padding-left: 0;
  }

  .nav-links-single .nav-links .nav-previous .post-title {
    text-align: left;
  }

  .nav-links-single .nav-links .post-title {
    -webkit-transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
    -ms-transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
    transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
  }

  .nav-links-single .nav-links .post-title {
    margin: 11px 0 0;
    font-size: 30px;
    line-height: 1.08em;
    font-weight: 700;
    letter-spacing: 1px;
  }

  .nav-links-single .nav-links .screen-reader-text:hover,
  .nav-links-single .nav-links .screen-reader-text {
    display: block !important;
    clip: auto !important;
    top: 0 !important;
    left: 0 !important;
    width: auto !important;
    height: auto !important;
    position: relative !important;
    padding: 0 !important;
    border: none !important;
    -webkit-box-shadow: none !important;
    -ms-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 13px !important;
    line-height: 16px !important;
    font-weight: 500 !important;
    -webkit-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    border-radius: 0 !important;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    letter-spacing: 0.3px !important;
  }

  .nav-links-single .nav-previous .screen-reader-text:before {
    content: "\e96a";
    font-family: "fontello";
    display: inline-block;
  }

  .nav-links-single .nav-previous .screen-reader-text:before {
    -moz-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
    filter: FlipH;
    -ms-filter: "FlipH";
    margin-right: 7px;
  }

  .single-post .post_item_single {
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    overflow: hidden;
  }

  .single-post .post_item_single .post_content_single {
    padding: 50px;
  }

  .single-post .post_item_single .post_header_single {
    padding: 18px 50px 18px;
  }

  .single-post .header_content_wrap + .post_content_single {
    padding-top: 0;
  }

  .section_title {
    margin: 0 0 0.72em;
    text-align: left;
    font-size: 3.429em;
    letter-spacing: 1.8px;
  }

  .post_item_single .post_header_single {
    margin-bottom: 0;
  }

  .post_item_single .post_content > .post_meta_single {
    font-size: 1em;
    line-height: 1.35em;
    margin-top: 1.6em;
    letter-spacing: 0;
    overflow: hidden;
    clear: both;
  }

  .post_item_single .post_content .post_tags .post_meta_label {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 2.3px;
    vertical-align: middle;
  }

  .post_item_single .post_content > .post_meta_single .post_tags a {
    padding: 6px 14px;
    margin: 0 7px 7px 0;
    font-size: 13px !important;
    line-height: 17px;
    font-weight: 500;
    letter-spacing: 0;
    text-transform: lowercase;
    -webkit-border-radius: 20px;
    -ms-border-radius: 20px;
    border-radius: 20px;
    display: inline-block;
  }

  .post_item_single .post_content > .post_meta_single .post_tags {
    float: left;
    display: block;
    max-width: 50%;
    margin-top: 3px;
  }

  .post_item_single .post_content > .post_meta_single .post_tags:after {
    display: none;
  }

  .post_item_single .post_content > .post_meta_single .post_share {
    float: right;
    display: block;
    max-width: 47%;
  }

  .post_item_single .post_content > .post_meta_single .post_share:before {
    display: none;
  }

  .post_item_single .post_content > .post_meta_single .post_share .social_item {
    margin: 3px 0 3px 0.5em;
  }

  .post_item_single .post_content > .post_meta_single .post_share .socials_share {
    vertical-align: middle;
    display: inline-block;
  }

  .post_item_single .post_content > .post_meta_single .post_share .social_item .social_icon {
    display: block;
    width: 29px;
    height: 29px;
    line-height: 29px;
    text-align: center;
    font-size: 16px;
  }

  .post_item_single .post_content > .post_meta_single .share_meta_label {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 2.3px;
    vertical-align: middle;
  }

  .related_wrap {
    margin-top: 6.9em;
    padding-top: 7em;
    border-top: 1px solid #eee;
  }

  .related_wrap .related_item {
    text-align: left;
  }

  .related_wrap .post_title {
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    line-height: 0.94em;
    letter-spacing: 1px;
  }

  .related_wrap .post_title a {
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .nav-links-single .nav-links .nav-previous .post-title {
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .related_wrap.related_style_classic .post_featured {
    margin-bottom: 2em;
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }

  .related_wrap.related_style_classic .post_featured[class*="hover_"] {
    display: inline-block;
  }

  .related_wrap.related_style_classic .post_featured img {
    width: 100%;
  }

  .related_wrap.related_style_classic .post_meta {
    margin-top: 0.4em;
    margin-bottom: 0.6em;
  }

  .related_wrap.related_style_classic .post_title + .post_content_inner {
    margin-top: 1.4em;
  }

  .comments_form_wrap {
    margin-top: 7em;
    padding-top: 7em;
    border-top: 1px solid #eee;
    overflow: hidden;
  }

  .related_wrap + .comments_wrap .comments_form_wrap {
    margin-top: 5em;
  }

  .comments_form_wrap form {
    position: relative;
  }

  .comments_wrap #cancel-comment-reply-link {
    font-size: 0;
  }

  .comments_wrap #cancel-comment-reply-link:before {
    content: "\e916";
    font-family: "fontello";
    font-size: 18px;
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    width: 2em;
    height: 2em;
    line-height: 2em;
    text-align: center;
  }

  .comments_wrap .comments_field {
    margin-bottom: 20px;
  }

  .comments_wrap .comments_author,
  .comments_wrap .comments_email {
    width: 49%;
    float: left;
  }

  .comments_wrap .comments_email {
    float: right;
  }

  .comments_wrap .comments_comment:before {
    content: " ";
    clear: both;
    width: 100%;
    height: 0;
    display: block;
  }

  .comments_wrap .comments_field label {
    display: none;
  }

  .comments_wrap .comments_field input[type="checkbox"] + label {
    display: block;
  }

  .comments_wrap .comments_field input,
  .comments_wrap .comments_field textarea {
    width: 100%;
    padding: 0.9em 1.5em;
  }

  .comments_wrap .comments_field textarea {
    min-height: 17.5em;
  }

  .comments_wrap .form-submit {
    margin: 1.3em 0 0;
  }

  .comments_wrap .form-submit:before {
    content: " ";
    clear: both;
    width: 100%;
    height: 0;
    display: block;
  }

  .comments_wrap .form-submit input[type="submit"] {
    display: inline-block;
  }

  .sidebar .widget {
    padding: 30px;
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.07);
    -ms-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.07);
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.07);
    overflow: hidden;
  }

  .sidebar .widget + .widget {
    margin-top: 30px;
  }

  .widget .widget_title {
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
    letter-spacing: 2.2px;
    padding: 16px 23px;
    border-left: 7px solid red;
  }

  .sidebar_inner .widget .widget_title {
    margin-left: -30px;
    margin-right: -30px;
    margin-top: -30px;
  }

  .widget_area .post_item .post_title,
  aside .post_item .post_title {
    margin: 0 0 5px 0;
    text-align: left;
    line-height: 1.25em;
    font-weight: 500;
  }

  .widget_area .post_item .post_thumb,
  aside .post_item .post_thumb {
    float: left;
    width: 66px;
    height: 66px;
    margin: 0 25px 3px 0;
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }

  .widget_area .post_item .post_info,
  aside .post_item .post_info {
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 0;
    font-weight: 500;
  }

  aside .post_item + .post_item {
    margin-top: 9px;
    border-top: 1px solid red;
    padding-top: 12px;
  }

  .widget ul {
    margin: 0;
    padding-left: 1.2em;
    list-style-type: none;
  }

  .widget li {
    position: relative;
  }

  .widget ul > li:before {
    content: " ";
    display: block;
    width: 3px;
    height: 3px;
    line-height: 3px;
    text-align: center;
    position: absolute;
    z-index: 1;
    top: 0.8em;
    left: -1.2em;
  }

  .widget_categories > ul {
    padding: 0;
  }

  .widget_categories ul > li:before {
    display: none;
  }

  .widget_categories ul {
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 2.4px;
    font-weight: 700;
  }

  .widget_categories ul li {
    padding: 15px 0;
  }

  .widget_categories ul li + li {
    border-top: 1px solid red;
  }

  .widget_categories > ul > li:first-child {
    padding-top: 0 !important;
  }

  .widget_categories ul li:last-child {
    padding-bottom: 0 !important;
  }

  .widget_calendar table {
    width: 100%;
    position: relative;
  }

  .widget_calendar caption {
    margin-bottom: 13px;
    font-weight: 500;
    padding: 17px;
    -webkit-border-radius: 30px;
    -ms-border-radius: 30px;
    border-radius: 30px;
    font-size: 14px;
    line-height: 16px;
  }

  .widget_calendar table > thead > tr {
    background-color: transparent !important;
  }

  .widget_calendar th,
  .widget_calendar td {
    font-size: 15px !important;
    line-height: 2.2em;
    font-weight: 400 !important;
    font-style: normal;
    text-align: center;
    text-transform: uppercase;
    padding: 2px;
    border: none !important;
    background-color: transparent !important;
    width: 14.2857%;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .widget_calendar th {
    font-weight: 700 !important;
    font-size: 20px !important;
  }

  .widget_calendar td {
    line-height: 3.1em;
    padding: 0;
  }

  .widget_calendar tfoot td {
    line-height: 2em;
  }

  .widget_calendar td#today {
    position: relative;
    z-index: 2;
  }

  .widget_calendar td#today:before {
    content: " ";
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 2.9em;
    height: 2.9em;
    line-height: 2.9em;
    text-align: center;
    -webkit-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
  }

  .widget_calendar tfoot {
    position: relative;
    top: 3px;
  }

  .widget_calendar tfoot td {
    text-transform: none !important;
  }

  .widget_calendar td#prev {
    text-align: left;
    overflow: hidden;
  }

  .widget_calendar td#next {
    text-align: right;
  }

  .widget_calendar td#prev a {
    position: relative;
    letter-spacing: 1px;
    font-weight: 400;
  }

  .widget_calendar td#prev a {
    padding-left: 0.6em;
  }

  .widget_calendar td#prev a:before {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 1.2em;
    height: 1em;
    line-height: 1.4em;
    display: inline-block;
  }

  .widget_calendar td#prev a:before {
    content: "\e939";
    font-family: "fontello";
    display: none;
  }

  .widget_search form {
    width: 100%;
    overflow: hidden;
    position: relative;
  }

  .widget_search form:after {
    content: "\e968";
    font-family: "fontello";
    display: block;
    position: absolute;
    z-index: 0;
    top: 50%;
    right: 0;
    margin-top: -0.5em;
    width: 1em;
    height: 1em;
    line-height: 1em;
    text-align: center;
    pointer-events: none;
    cursor: pointer;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: 2;
    width: 5.5em;
    letter-spacing: -1px;
  }

  .widget_search .search-field {
    width: 100%;
    padding: 1.1em 1.5em !important;
    padding-right: 3em !important;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    text-transform: lowercase;
    border: none !important;
  }

  .widget_search input.search-submit {
    display: block;
    text-indent: -1000px;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    width: 5.5em;
    height: 100%;
    padding: 0;
    border: none !important;
    cursor: pointer;
  }

  .widget_tag_cloud {
    overflow: hidden;
  }

  .widget_tag_cloud .tagcloud {
    margin-bottom: -10px;
    overflow: hidden;
  }

  .widget_tag_cloud a {
    display: block;
    float: left;
    padding: 6px 14px;
    margin: 0 7px 10px 0;
    font-size: 13px !important;
    line-height: 17px;
    font-weight: 500;
    letter-spacing: 0;
    text-transform: lowercase;
    -webkit-border-radius: 20px;
    -ms-border-radius: 20px;
    border-radius: 20px;
  }

  #recentcomments {
    padding-left: 0;
  }

  .recentcomments {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
  }

  .recentcomments > a {
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    float: left;
    display: block !important;
    width: 100%;
    letter-spacing: 0;
  }

  li.recentcomments:before {
    display: none !important;
  }

  .recentcomments + .recentcomments {
    margin-top: 11px;
    padding-top: 11px;
    border-top: 1px solid #ddd;
  }

  .recentcomments .comment-author-link {
    font-size: 13px;
    font-weight: 700;
  }

  .widget_banner .image_wrap {
    margin: 0;
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }

  .widget_twitter .widget_content ul li + li {
    margin-top: 11px;
    padding-top: 11px;
    border-top: 1px solid #ddd;
  }

  .widget_twitter .widget_content ul li {
    padding-left: 59px;
    min-height: 44px;
  }

  .widget_twitter .widget_content ul {
    padding-left: 0;
  }

  .widget_twitter .widget_content ul > li:before {
    content: "\e840";
    font-style: normal;
    display: inline-block;
    top: 0;
    left: 0;
    width: 44px;
    height: 44px;
    -webkit-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    line-height: 44px;
    font-weight: 400;
    font-size: 2em;
  }

  .widget_twitter .widget_content ul li + li:before {
    margin-top: 11px;
  }

  .widget_twitter .widget_content ul a {
    border-bottom: 1px solid #ddd;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    line-height: 1em;
    display: inline-block;
  }

  .footer_wrap p {
    margin: 0;
  }

  .footer_wrap .sc_layouts_menu > ul > li + li:before {
    display: none;
  }

  .footer_wrap .sc_layouts_menu > ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  .footer_wrap .sc_layouts_menu > ul > li {
    display: inline-block;
    vertical-align: baseline;
    font-size: 16px;
    z-index: auto !important;
    font-weight: 500;
  }

  .footer_wrap .sc_layouts_menu > ul > li + li:before {
    content: " ";
    display: inline-block;
    width: 0;
    height: 0.8em;
    margin: 0;
    vertical-align: baseline;
    border-left: 1px dotted #ddd;
  }

  .footer_wrap .sc_layouts_menu > ul > li > a {
    padding: 0.5em 1em;
    display: inline-block;
  }

  .footer_wrap .sc_layouts_column_align_left .sc_layouts_menu > ul > li:first-child > a {
    padding-left: 0;
  }

  .sc_layouts_row_type_normal {
    padding: 1em 0;
  }

  .sc_layouts_column_align_right .sc_layouts_item {
    margin-left: 1.3em;
    margin-right: 0;
  }

  .sc_layouts_menu_mobile_button .sc_layouts_item_icon {
    font-size: 30px !important;
  }

  .sc_layouts_row_type_normal .sc_layouts_item_icon {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    -webkit-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    font-size: 14px;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .search_wrap .search_submit {
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .search_wrap .search_submit:before {
    content: "\e968";
    font-family: "fontello";
  }

  .sc_layouts_row_type_normal .search_wrap .search_field {
    font-size: 14px;
    font-weight: 400;
    text-transform: lowercase;
    padding: 0 60px 0 21px !important;
    width: 18em;
    height: 50px;
    line-height: 50px;
    border: none;
  }

  .sc_layouts_row_type_normal .search_wrap .search_submit {
    display: block;
    font-size: 11px;
    text-align: center;
    width: 60px;
    height: 50px;
    line-height: 50px;
    position: absolute;
    top: 0;
    bottom: auto;
    left: auto;
    right: 0;
  }

  .sc_layouts_row_type_normal .search_wrap .search_submit:before {
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .top_panel:not(.scheme_dark) .sc_layouts_row_type_normal:not(.scheme_dark) .search_wrap .search_submit:before {
    color: #797e87 !important;
  }

  .top_panel:not(.scheme_dark) .sc_layouts_row_type_normal:not(.scheme_dark) .search_wrap .search_submit:hover:before {
    color: #262f3e !important;
  }

  .sc_layouts_menu_nav li {
    text-align: center;
  }

  .sc_layouts_menu_nav > li > ul:after {
    content: " ";
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 50%;
    width: 12px;
    height: 12px;
    margin: -6px 0 0 -6px;
    background-color: #fff;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .sc_layouts_menu_nav li li.menu-item-has-children > a:after,
  .sc_layouts_menu_nav > li.menu-item-has-children > a:after {
    display: none;
  }

  .sc_layouts_menu_nav > li > a,
  .sc_layouts_menu_nav > li.menu-item-has-children > a {
    padding: 0.85em 1.3em;
  }

  .sc_layouts_menu_nav > li > ul {
    top: 4em;
    left: 50%;
    margin-left: -3em;
  }

  .sc_layouts_menu_nav > li ul {
    width: 10.8em;
    padding: 1.8em 0;
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }

  .sc_layouts_menu_nav > li > ul ul {
    left: 10.8em;
    top: -1.8em;
    margin: 0 0 0 2px;
  }

  .sc_layouts_menu_nav > li li > a {
    padding: 0.27em 1em;
  }

  .sc_layouts_menu_nav > li ul {
    -webkit-box-shadow: none;
    -ms-box-shadow: none;
    box-shadow: none;
  }

  .footer_wrap .sc_layouts_menu > ul > li + li:before {
    display: none;
  }

  .sc_layouts_title_caption {
    font-size: 72px;
  }

  .breadcrumbs {
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
  }

  .breadcrumbs .breadcrumbs_delimiter {
    margin: 0 0.15em;
  }

  .copyright-text {
    font-size: 16px;
  }

  .post_meta_item.post_categories {
    margin-left: 0;
    margin-top: -5px !important;
    display: inline-block;
    margin-bottom: -5px !important;
  }

  .single-post .post_meta_item.post_categories {
    margin-bottom: 0 !important;
  }

  .post_meta_item.post_categories a {
    display: inline-block;
    line-height: 20px;
    margin-bottom: 3px;
  }
}

/*! CSS Used from: Embedded */
.post-navigation .nav-previous a .nav-arrow {
  background-image: url(https://fcunited.azurewebsites.net/wp-content/uploads/2018/11/post-6-copyright-406x228.jpg);
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__plugins.css ; media=all */
@media all {
  .posts_container.columns_wrap {
    display: flex;
    flex-wrap: wrap;
  }

  .post_featured[class*="hover_"] {
    position: relative;
    -webkit-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
    display: inline-block;
    vertical-align: top;
  }

  .post_featured[class*="hover_"],
  .post_featured[class*="hover_"] * {
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .post_featured .mask {
    content: " ";
    position: absolute;
    z-index: 4;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .post_featured.hover_simple:hover .mask {
    opacity: 1;
  }

  .post_featured.hover_simple .icons {
    position: absolute;
    z-index: 6;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .footer_wrap .sc_layouts_item {
    vertical-align: top;
    margin-top: 0.25em !important;
    margin-bottom: 0.25em !important;
  }

  .sc_item_title {
    line-height: 0.94em;
  }

  .socials_wrap {
    margin: -12px 0 0 0;
  }

  .socials_wrap .social_item {
    margin: 12px 12px 0 0;
  }

  .socials_wrap .social_item .social_icon {
    width: 46px;
    height: 46px;
    line-height: 47px;
    font-size: 26px;
  }

  .trx_addons_scroll_to_top {
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }

  .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
    padding: 15px;
  }

  form.mc4wp-form {
    position: relative;
    padding-right: 0;
  }

  form.mc4wp-form .mc4wp-form-fields input[type="email"] {
    width: 100%;
    vertical-align: top;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    height: 50px;
    line-height: 50px;
    border: none;
    -webkit-border-radius: 30px;
    -ms-border-radius: 30px;
    border-radius: 30px;
    padding: 0 10em 0 2.2em;
  }

  form.mc4wp-form .mc4wp-form-fields input[type="submit"] {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    height: 50px;
    line-height: 50px;
    min-width: 9.5em;
    vertical-align: top;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0;
    padding-bottom: 0;
    -webkit-border-radius: 30px;
    -ms-border-radius: 30px;
    border-radius: 30px;
    text-align: center;
  }

  form.mc4wp-form label {
    margin-top: 10px;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__custom.css ; media=all */
@media all {
  .content_wrap {
    width: 1278px;
  }

  body.body_style_wide:not(.expand_content) [class*="content_wrap"] > .content {
    width: 818px;
  }

  [class*="content_wrap"] > .sidebar {
    width: 435px;
  }

  body {
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    font-style: normal;
    line-height: 1.76em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0.26px;
  }

  p,
  ul {
    margin-top: 0em;
    margin-bottom: 1.6em;
  }

  h1 {
    font-family: "BebasNeue", sans-serif;
    font-size: 4.286em;
    font-weight: 700;
    font-style: normal;
    line-height: 0.93em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 2.1px;
    margin-bottom: 0.63em;
  }

  h2 {
    font-family: "BebasNeue", sans-serif;
    font-size: 3.429em;
    font-weight: 700;
    font-style: normal;
    line-height: 0.96em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 1.75px;
    margin-bottom: 0.52em;
  }

  h3 {
    font-family: "BebasNeue", sans-serif;
    font-size: 2.571em;
    font-weight: 700;
    font-style: normal;
    line-height: 0.91em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 1.3px;
    margin-bottom: 0.92em;
  }

  h5 {
    font-family: "BebasNeue", sans-serif;
    font-size: 1.714em;
    font-weight: 700;
    font-style: normal;
    line-height: 1.06em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0.85px;
    margin-bottom: 0.7em;
  }

  h6 {
    font-family: "Poppins", sans-serif;
    font-size: 1.286em;
    font-weight: 500;
    font-style: normal;
    line-height: 1.23em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0px;
    margin-bottom: 0.69em;
  }

  input[type="text"],
  input[type="email"],
  input[type="search"],
  textarea {
    font-size: 1em;
    font-weight: 400;
    font-style: normal;
    line-height: 1.5em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0px;
  }

  form button,
  input[type="submit"] {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 18px;
    text-decoration: none;
  }

  .related_wrap .post_title,
  .nav-links-single .nav-links .post-title,
  .post_item_single .post_content > .post_meta_single .share_meta_label,
  .post_item_single .post_content .post_tags .post_meta_label,
  .nav-links,
  .widget_calendar table,
  .widget_categories ul {
    font-family: "BebasNeue", sans-serif;
  }

  .widget_calendar caption {
    font-family: "Poppins", sans-serif;
  }

  .post_meta {
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.4em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0px;
    margin-top: 0.4em;
  }

  .nav-links-single .screen-reader-text {
    font-family: "Poppins", sans-serif;
  }

  .sc_layouts_row:not(.sc_layouts_row_type_narrow) .sc_layouts_menu_nav {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    line-height: 1.5em;
  }

  .sc_layouts_row:not(.sc_layouts_row_type_narrow) .sc_layouts_menu_nav > li > a {
    font-weight: 500;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0.18px;
  }

  .sc_layouts_row:not(.sc_layouts_row_type_narrow) .sc_layouts_menu_nav > li > ul {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    line-height: 1.35em;
  }

  .sc_layouts_row:not(.sc_layouts_row_type_narrow) .sc_layouts_menu_nav > li ul > li > a {
    font-weight: 500;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0.18px;
  }

  .menu_mobile .menu_mobile_nav_area > ul {
    font-family: "Poppins", sans-serif;
  }

  .menu_mobile .menu_mobile_nav_area > ul > li ul {
    font-family: "Poppins", sans-serif;
  }

  form.mc4wp-form .mc4wp-form-fields input[type="email"] {
    font-size: 1em;
    font-weight: 400;
    font-style: normal;
    line-height: 1.5em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0px;
  }

  form.mc4wp-form .mc4wp-form-fields input[type="submit"] {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 18px;
    text-decoration: none;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__colors-default.css ; media=all */
@media all {
  .scheme_default {
    color: #797e87;
  }

  .scheme_default h1,
  .scheme_default h2,
  .scheme_default h3,
  .scheme_default h5,
  .scheme_default h6,
  .scheme_default h3 a,
  .scheme_default h6 a,
  .scheme_default li a {
    color: #262f3e;
  }

  .scheme_default h3 a:hover,
  .scheme_default h6 a:hover,
  .scheme_default li a:hover {
    color: #ff0000;
  }

  .scheme_default a {
    color: #ff0000;
  }

  .scheme_default a:hover {
    color: #081324;
  }

  .scheme_default table th,
  .scheme_default table th + th {
    border-color: #262f3e;
  }

  .scheme_default table td,
  .scheme_default table td + td {
    color: #797e87;
    border-color: #e3e3e3;
  }

  .scheme_default table th {
    color: #8c9097;
    background-color: #081224;
  }

  .scheme_default table > tbody > tr:nth-child(2n + 1) > td {
    background-color: #ebebeb;
  }

  .scheme_default table > tbody > tr:nth-child(2n) > td {
    background-color: #f4f4f4;
  }

  .scheme_default ul > li:before {
    color: #ff0000;
  }

  .scheme_default .widget_search form:after {
    color: #ffffff;
  }

  .scheme_default .widget_search form:hover:after {
    color: #ffffff;
  }

  .scheme_default input[type="text"],
  .scheme_default input[type="password"],
  .scheme_default input[type="email"],
  .scheme_default input[type="search"],
  .scheme_default textarea,
  .scheme_default form.mc4wp-form input[type="email"] {
    color: #797e87;
    border-color: #e4e4e4;
    background-color: #f4f4f4;
  }

  .scheme_default input[type="text"]:focus,
  .scheme_default input[type="password"]:focus,
  .scheme_default input[type="email"]:focus,
  .scheme_default input[type="search"]:focus,
  .scheme_default textarea:focus,
  .scheme_default form.mc4wp-form input[type="email"]:focus {
    color: #262f3e;
    border-color: #262f3e;
    background-color: #f4f4f4;
  }

  .scheme_default input[placeholder]::-webkit-input-placeholder {
    color: #797e87;
    opacity: 1;
  }

  .scheme_default textarea[placeholder]::-webkit-input-placeholder {
    color: #797e87;
    opacity: 1;
  }

  .scheme_default input[placeholder]::-moz-placeholder {
    color: #797e87;
    opacity: 1;
  }

  .scheme_default textarea[placeholder]::-moz-placeholder {
    color: #797e87;
    opacity: 1;
  }

  .scheme_default input[placeholder]:-ms-input-placeholder {
    color: #797e87;
    opacity: 1;
  }

  .scheme_default textarea[placeholder]:-ms-input-placeholder {
    color: #797e87;
    opacity: 1;
  }

  .scheme_default input[placeholder]::placeholder {
    color: #797e87;
    opacity: 1;
  }

  .scheme_default textarea[placeholder]::placeholder {
    color: #797e87;
    opacity: 1;
  }

  .scheme_default input[type="checkbox"] + label:before {
    border-color: #e4e4e4 !important;
  }

  .scheme_default .search_wrap .search_submit:before {
    color: #797e87;
  }

  .scheme_default input[type="submit"][disabled],
  .scheme_default input[type="submit"][disabled]:hover {
    background-color: rgba(38, 47, 62, 0.4) !important;
    color: #ffffff !important;
  }

  .scheme_default button:hover,
  .scheme_default button:focus,
  .scheme_default input[type="submit"]:hover,
  .scheme_default input[type="submit"]:focus,
  .scheme_default .comments_wrap .form-submit input[type="submit"]:hover,
  .scheme_default .comments_wrap .form-submit input[type="submit"]:focus,
  .scheme_default .socials_share:not(.socials_type_drop) .social_icon:hover,
  .scheme_default .mc4wp-form input[type="submit"]:hover,
  .scheme_default .mc4wp-form input[type="submit"]:focus {
    color: #ffffff;
    background-color: #262f3e;
  }

  .scheme_default .search_wrap .search_submit:hover:before {
    color: #262f3e;
  }

  .scheme_default .sc_layouts_item_icon {
    color: #797e87;
  }

  .scheme_default .sc_layouts_row_type_normal .sc_layouts_item {
    color: #797e87;
  }

  .scheme_default .sc_layouts_row_type_normal .sc_layouts_item a:not(.sc_button):not(.button) {
    color: #797e87;
  }

  .scheme_default .sc_layouts_row_type_normal .sc_layouts_item a:not(.sc_button):not(.button):hover,
  .scheme_default
    .sc_layouts_row_type_normal
    .sc_layouts_item
    a:not(.sc_button):not(.button):hover
    .sc_layouts_item_icon {
    color: #262f3e;
  }

  .scheme_default .sc_layouts_row_type_normal .search_wrap .search_submit:before,
  .scheme_default .sc_layouts_row_type_normal .search_wrap .search_submit {
    background-color: transparent;
    color: #262f3e;
  }

  .scheme_default .sc_layouts_row_type_normal .search_wrap .search_submit:hover:before,
  .scheme_default .sc_layouts_row_type_normal .search_wrap .search_submit:hover {
    background-color: transparent;
    color: #797e87;
  }

  .scheme_default .sc_layouts_title .sc_layouts_title_breadcrumbs,
  .scheme_default .sc_layouts_title .sc_layouts_title_breadcrumbs a {
    color: #262f3e;
  }

  .scheme_default .sc_layouts_title .sc_layouts_title_breadcrumbs a:hover {
    color: #081324;
  }

  .scheme_default .sc_layouts_menu_nav > li > a {
    color: #262f3e;
  }

  .scheme_default .sc_layouts_menu_nav > li > a:hover {
    color: #ff0000 !important;
  }

  .scheme_default .sc_layouts_menu_nav > li ul {
    background-color: #081224;
  }

  .scheme_default .sc_layouts_menu_nav > li > ul:after {
    background-color: #081224;
  }

  .scheme_default .sc_layouts_menu_nav > li li > a {
    color: #8c9097 !important;
  }

  .scheme_default .sc_layouts_menu_nav > li li > a:hover {
    color: #ff0000 !important;
  }

  .scheme_default .sc_layouts_menu_nav > li li > a:hover:after {
    color: #ff0000 !important;
  }

  .scheme_default .menu_mobile_inner {
    color: #797e87;
    background-color: #ffffff;
  }

  .scheme_default .menu_mobile_close:before,
  .scheme_default .menu_mobile_close:after {
    border-color: #1d1d1d;
  }

  .scheme_default .menu_mobile_close:hover:before,
  .scheme_default .menu_mobile_close:hover:after {
    border-color: #ff0000;
  }

  .scheme_default .menu_mobile_inner a,
  .scheme_default .menu_mobile_inner .menu_mobile_nav_area li:before {
    color: #1d1d1d;
  }

  .scheme_default .menu_mobile_inner a:hover,
  .scheme_default .menu_mobile_inner .menu_mobile_nav_area li:hover:before {
    color: #ff0000;
  }

  .scheme_default .menu_mobile_inner .search_mobile .search_submit {
    color: #797e87;
  }

  .scheme_default .menu_mobile_inner .search_mobile .search_submit:focus,
  .scheme_default .menu_mobile_inner .search_mobile .search_submit:hover {
    color: #262f3e;
  }

  .scheme_default .sc_layouts_row_type_normal .sc_layouts_item_icon {
    color: #ffffff;
    background-color: #8c9097;
  }

  .scheme_default .sc_layouts_row_type_normal .sc_layouts_item_icon:hover {
    color: #ffffff;
    background-color: #262f3e;
  }

  .scheme_default .top_panel {
    background-color: #ffffff;
  }

  .scheme_default .post_item {
    color: #797e87;
  }

  .scheme_default .post_meta,
  .scheme_default .post_meta_item,
  .scheme_default .post_meta_item:after,
  .scheme_default .post_meta_item:hover:after,
  .scheme_default .post_meta_item a,
  .scheme_default .post_info .post_info_item,
  .scheme_default .post_info .post_info_item a {
    color: #797e87;
  }

  .scheme_default .post_info .post_info_item a.post_info_date {
    color: #ff0000;
  }

  .scheme_default .post_info .post_info_item a.post_info_author {
    color: #262f3e;
  }

  .scheme_default .post_info .post_info_item a.post_info_author:hover {
    color: #ff0000;
  }

  .scheme_default .post_meta_item.post_categories a {
    color: #ffffff;
    background-color: #ff0000;
  }

  .scheme_default .post_meta_item.post_categories a:hover {
    color: #ffffff;
    background-color: #081324;
  }

  .scheme_default .post_meta_item.post_date {
    color: #ff0000;
  }

  .scheme_default .post_meta_item a:hover,
  .scheme_default .post_info .post_info_item a:hover {
    color: #262f3e;
  }

  .scheme_default .post_item .post_title a:hover {
    color: #ff0000;
  }

  .scheme_default .post_meta_item .social_items,
  .scheme_default .post_meta_item .social_items:before {
    border-color: #e3e3e3;
    color: #797e87;
  }

  .scheme_default .post_item_single .post_content > .post_meta_single .share_meta_label,
  .scheme_default .post_item_single .post_content .post_tags .post_meta_label {
    color: #262f3e;
  }

  .scheme_default .post_item_single .post_content .post_meta_single .post_tags a {
    color: #797e87;
    background-color: #f4f4f4;
  }

  .scheme_default .post_item_single .post_content .post_meta_single .post_tags a:hover {
    color: #ffffff;
    background-color: #ff0000;
  }

  .scheme_default .post_item_single .post_content .post_meta .post_share .socials_type_block .social_item .social_icon {
    color: #ffffff !important;
    background-color: #262f3e;
  }

  .scheme_default
    .post_item_single
    .post_content
    .post_meta
    .post_share
    .socials_type_block
    .social_item:hover
    .social_icon {
    color: #ffffff !important;
    background-color: #ff0000;
  }

  .scheme_default .nav-links-single .screen-reader-text {
    color: #ff0000;
  }

  .scheme_default .nav-links-single .screen-reader-text:hover {
    color: #081324 !important;
  }

  .scheme_default .nav-links-single .nav-links {
    border-color: #e3e3e3;
  }

  .scheme_default .nav-links-single .nav-links a:hover .post-title {
    color: #ff0000;
  }

  .scheme_default .related_wrap {
    border-color: #e3e3e3;
  }

  .scheme_default .related_wrap .post_title a:hover {
    color: #ff0000;
  }

  .scheme_default .comments_form_wrap {
    border-color: #e3e3e3;
  }

  .scheme_default .sidebar_inner .widget + .widget {
    border-color: #e3e3e3;
  }

  .scheme_default .sidebar .widget {
    background-color: #ffffff;
  }

  .scheme_default .widget ul > li:before {
    background-color: #ff0000;
  }

  .scheme_default .widget_calendar caption {
    color: #ffffff;
    background-color: #ff0000;
  }

  .scheme_default .widget_calendar th {
    color: #262f3e;
  }

  .scheme_default .widget_calendar tbody td {
    color: #797e87 !important;
  }

  .scheme_default .widget_calendar td#today {
    color: #ffffff !important;
  }

  .scheme_default .widget_calendar td#today {
    color: #ffffff;
  }

  .scheme_default .widget_calendar td#today:before {
    background-color: #ff0000;
  }

  .scheme_default .widget_calendar #prev a {
    color: #ff0000;
  }

  .scheme_default .widget_calendar #prev a:hover {
    color: #081324;
  }

  .scheme_default .widget_calendar td#prev a:before {
    background-color: #ffffff;
  }

  .scheme_default .widget_categories li {
    color: #262f3e;
  }

  .scheme_default .widget_tag_cloud a {
    color: #797e87;
    background-color: #f4f4f4;
  }

  .scheme_default .widget_tag_cloud a:hover {
    color: #ffffff !important;
    background-color: #ff0000;
  }

  .scheme_default .widget_search input.search-submit {
    background-color: #ff0000;
  }

  .scheme_default .widget .widget_title {
    background-color: #081224;
    color: #ffffff;
    border-color: #ff0000;
  }

  .scheme_default .recentcomments + .recentcomments,
  .scheme_default aside .post_item + .post_item,
  .scheme_default .widget_categories ul li + li {
    border-color: #e3e3e3;
  }

  .scheme_default .recentcomments .comment-author-link {
    color: #262f3e;
  }

  .scheme_default .single-post .post_item_single {
    background-color: #ffffff;
  }

  .scheme_default .sc_layouts_menu_mobile_button .sc_layouts_item_icon {
    background-color: #ff0000 !important;
    color: #ffffff !important;
  }

  .scheme_default .sc_layouts_menu_mobile_button .sc_layouts_item_icon:hover {
    background-color: #262f3e !important;
    color: #ffffff !important;
  }

  .scheme_default .sc_layouts_menu_mobile_button .sc_layouts_item_icon {
    color: #ffffff !important;
    background-color: #8c9097 !important;
  }

  .scheme_default .sc_layouts_menu_mobile_button .sc_layouts_item_icon:hover {
    color: #ffffff !important;
    background-color: #262f3e !important;
  }

  .scheme_default .nav-links-single .nav-links .nav-previous {
    background-color: #ffffff;
  }

  .scheme_default .comments_wrap .comments_field input,
  .scheme_default .comments_wrap .comments_field textarea {
    background-color: #ffffff;
  }

  .scheme_default .widget_twitter .widget_content li {
    color: #797e87;
  }

  .scheme_default .widget_twitter .widget_content .sc_twitter_list li {
    color: #797e87;
  }

  .scheme_default .widget_twitter .widget_content .sc_twitter_list li:before {
    color: #ffffff !important;
    background-color: #ff0000 !important;
  }

  .scheme_default .widget_twitter .widget_content ul li + li {
    border-color: #e3e3e3;
  }

  .scheme_default .widget_twitter .widget_content ul a {
    border-color: #797e87;
    color: #797e87;
  }

  .scheme_default .widget_twitter .widget_content ul a:hover {
    border-color: #ff0000;
    color: #ff0000;
  }

  .scheme_default .socials_wrap .social_item .social_icon {
    background-color: #081224;
  }

  .scheme_default .socials_wrap .social_item .social_icon {
    color: #ffffff;
  }

  .scheme_default .socials_wrap .social_item:hover .social_icon {
    background-color: #ff0000;
  }

  .scheme_default .socials_wrap .social_item:hover .social_icon {
    color: #ffffff;
  }

  .scheme_default .trx_addons_scroll_to_top {
    color: #ffffff;
    border-color: #ff0000;
    background-color: #ff0000;
  }

  .scheme_default .trx_addons_scroll_to_top:hover {
    color: #ffffff;
    border-color: #ff150d;
    background-color: #262f3e;
  }

  .scheme_default .elementor-divider-separator {
    border-color: #e3e3e3;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__colors-dark.css ; media=all */
@media all {
  .scheme_dark {
    color: #848992;
  }

  .scheme_dark h1,
  .scheme_dark h2,
  .scheme_dark li a {
    color: #ffffff;
  }

  .scheme_dark li a:hover {
    color: #ff0000;
  }

  .scheme_dark a {
    color: #ff0000;
  }

  .scheme_dark a:hover {
    color: #ffffff;
  }

  .scheme_dark ul > li:before {
    color: #ff0000;
  }

  .scheme_dark input[type="text"],
  .scheme_dark input[type="email"],
  .scheme_dark form.mc4wp-form input[type="email"] {
    color: #b7b7b7;
    border-color: #262f3e;
    background-color: #262f3e;
  }

  .scheme_dark input[type="text"]:focus,
  .scheme_dark input[type="email"]:focus,
  .scheme_dark form.mc4wp-form input[type="email"]:focus {
    color: #ffffff;
    border-color: #353535;
    background-color: #262f3e;
  }

  .scheme_dark input[placeholder]::-webkit-input-placeholder {
    color: #6f6f6f;
    opacity: 1;
  }

  .scheme_dark input[placeholder]::-moz-placeholder {
    color: #6f6f6f;
    opacity: 1;
  }

  .scheme_dark input[placeholder]:-ms-input-placeholder {
    color: #6f6f6f;
    opacity: 1;
  }

  .scheme_dark input[placeholder]::placeholder {
    color: #6f6f6f;
    opacity: 1;
  }

  .scheme_dark input[type="checkbox"] + label:before {
    border-color: #262f3e !important;
  }

  .scheme_dark button:not(.components-button),
  .scheme_dark input[type="submit"],
  .scheme_dark .mc4wp-form input[type="submit"] {
    color: #ffffff;
    background-color: #ff0000;
  }

  .scheme_dark .search_wrap .search_submit:before {
    color: #b7b7b7;
  }

  .scheme_dark input[type="submit"][disabled],
  .scheme_dark input[type="submit"][disabled]:hover {
    background-color: rgba(255, 255, 255, 0.4) !important;
    color: #081224 !important;
  }

  .scheme_dark button:hover,
  .scheme_dark button:focus,
  .scheme_dark input[type="submit"]:hover,
  .scheme_dark input[type="submit"]:focus,
  .scheme_dark .mc4wp-form input[type="submit"]:hover,
  .scheme_dark .mc4wp-form input[type="submit"]:focus {
    color: #262f3e;
    background-color: #ffffff;
  }

  .scheme_dark .search_wrap .search_submit:hover:before {
    color: #ffffff;
  }

  .scheme_dark.footer_wrap {
    color: #848992;
    background-color: #081224;
  }

  .scheme_dark .sc_layouts_title .sc_layouts_title_breadcrumbs,
  .scheme_dark .sc_layouts_title .sc_layouts_title_breadcrumbs a {
    color: #ffffff;
  }

  .scheme_dark .sc_layouts_title .sc_layouts_title_breadcrumbs a:hover {
    color: #ffffff;
  }

  .scheme_dark .sc_layouts_menu_nav > li > a {
    color: #ffffff;
  }

  .scheme_dark .sc_layouts_menu_nav > li > a:hover {
    color: #ff0000 !important;
  }

  .scheme_dark .menu_mobile_inner {
    color: #a6a6a6;
    background-color: #081224;
  }

  .scheme_dark .menu_mobile_close:before,
  .scheme_dark .menu_mobile_close:after {
    border-color: #ffffff;
  }

  .scheme_dark .menu_mobile_close:hover:before,
  .scheme_dark .menu_mobile_close:hover:after {
    border-color: #ff0000;
  }

  .scheme_dark .menu_mobile_inner a,
  .scheme_dark .menu_mobile_inner .menu_mobile_nav_area li:before {
    color: #ffffff;
  }

  .scheme_dark .menu_mobile_inner a:hover,
  .scheme_dark .menu_mobile_inner .menu_mobile_nav_area li:hover:before {
    color: #ff0000;
  }

  .scheme_dark .menu_mobile_inner .search_mobile .search_submit {
    color: #6f6f6f;
  }

  .scheme_dark .menu_mobile_inner .search_mobile .search_submit:focus,
  .scheme_dark .menu_mobile_inner .search_mobile .search_submit:hover {
    color: #ffffff;
  }

  .scheme_dark.footer_wrap {
    background-color: #081224;
    color: #a6a6a6;
  }

  .scheme_dark.footer_wrap h2 {
    color: #ffffff;
  }

  .scheme_dark.footer_wrap a {
    color: #ffffff;
  }

  .scheme_dark.footer_wrap a:hover {
    color: #ff0000;
  }

  .scheme_dark .elementor-divider-separator {
    border-color: #262f3e;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/trx_addons/css/__responsive.css ; media=all */
@media all {
  @media (max-width: 1439px) {
    .sc_layouts_row:not(.sc_layouts_row_type_narrow),
    .sc_layouts_row:not(.sc_layouts_row_type_narrow) input[type="text"] {
      font-size: 14px;
    }

    .sc_layouts_row .sc_layouts_logo img {
      max-height: 4.5em;
    }
  }

  @media (max-width: 1279px) {
    .sc_layouts_row:not(.sc_layouts_row_type_narrow),
    .sc_layouts_row:not(.sc_layouts_row_type_narrow) input[type="text"] {
      font-size: 13px;
      line-height: 21px;
    }
  }

  @media (max-width: 1023px) {
    .trx_addons_scroll_to_top {
      right: 1em;
    }

    .sc_item_title {
      letter-spacing: 1px;
    }

    .sc_layouts_column .sc_layouts_item {
      margin-right: 1.15em;
    }

    .sc_layouts_column_align_right .sc_layouts_item {
      margin-left: 1.15em;
      margin-right: 0;
    }

    .sc_layouts_title_title {
      font-size: 0.8em;
    }

    .sc_layouts_menu_nav > li > a {
      padding: 0.7em;
    }

    .sc_layouts_menu_nav > li.menu-item-has-children > a {
      padding-right: 1.3em;
    }

    .sc_layouts_menu_nav > li.menu-item-has-children > a:after {
      right: 0.5em;
    }
  }

  @media (max-width: 767px) {
    .sc_item_title {
      letter-spacing: 0;
    }

    .hide_on_mobile,
    .sc_layouts_hide_on_mobile {
      display: none !important;
    }

    .socials_wrap .social_item .social_icon {
      width: 2em;
      height: 2em;
      line-height: 2em;
      text-align: center;
    }

    .socials_wrap .social_item {
      margin: 6px 6px 0 0;
    }

    .sc_layouts_menu_mobile_button {
      display: inline-block !important;
    }
  }

  @media (max-width: 479px) {
    .sc_layouts_row:not(.sc_layouts_row_type_narrow),
    .sc_layouts_row:not(.sc_layouts_row_type_narrow) input[type="text"] {
      font-size: 13px;
      line-height: 19px;
    }

    .sc_layouts_title_title {
      font-size: 0.7em;
    }
  }

  @media (min-width: 2160px) {
    .sc_layouts_hide_on_wide {
      display: none !important;
    }
  }

  @media (min-width: 1680px) and (max-width: 2159px) {
    .sc_layouts_hide_on_desktop {
      display: none !important;
    }
  }

  @media (min-width: 1280px) and (max-width: 1679px) {
    .sc_layouts_hide_on_notebook {
      display: none !important;
    }
  }

  @media (min-width: 768px) and (max-width: 1279px) {
    .sc_layouts_hide_on_tablet {
      display: none !important;
    }
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__responsive.css ; media=all */
@media all {
  @media (max-width: 1439px) {
    .content_wrap {
      width: 1100px;
    }

    body.body_style_wide:not(.expand_content) [class*="content_wrap"] > .content {
      width: 704.06885759px;
    }

    [class*="content_wrap"] > .sidebar {
      width: 374.4131455399px;
    }

    .widget_area .post_item .post_thumb,
    aside .post_item .post_thumb {
      margin: 0 20px 3px 0;
    }

    .sc_layouts_row:not(.sc_layouts_row_type_narrow),
    .sc_layouts_row:not(.sc_layouts_row_type_narrow) input[type="text"] {
      font-size: 14px;
      line-height: inherit;
    }

    .sc_layouts_row_type_normal .search_wrap .search_field {
      width: 16em;
    }

    .related_wrap.related_style_classic .post_title + .post_content_inner {
      margin-top: 10px;
    }

    .single-post .post_item_single .post_header_single {
      padding: 20px 40px 20px;
    }

    .single-post .post_item_single .post_content_single {
      padding: 40px;
    }

    .single-post .header_content_wrap + .post_content_single {
      padding-top: 0;
    }

    .widget_recent_posts .post_info > .post_info_item.post_info_posted:first-child {
      display: block;
    }

    .widget_recent_posts .post_info > .post_info_item.post_info_posted_by:nth-child(2):before {
      display: none;
    }

    .elementor-section.elementor-section-boxed > .elementor-container {
      max-width: 1130px;
    }

    .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  @media (max-width: 1279px) {
    .sc_layouts_title_caption {
      font-size: 4rem;
    }

    h1 {
      font-size: 3.7em;
      margin-top: 55px;
      margin-bottom: 22px;
      letter-spacing: 1.5px;
    }

    h2 {
      font-size: 3em;
      margin-top: 25px;
      margin-bottom: 20px;
      letter-spacing: 1.5px;
    }

    h3 {
      font-size: 2.4em;
      margin-top: 40px;
      margin-bottom: 20px;
      letter-spacing: 1px;
    }

    h5 {
      font-size: 1.6em;
      margin-top: 35px;
      margin-bottom: 18px;
    }

    h6 {
      font-size: 1.2em;
      margin-top: 30px;
      margin-bottom: 16px;
    }

    p,
    ul {
      margin-bottom: 1.4em;
    }

    // .page_content_wrap {
    //   padding-top: 50px;
    //   padding-bottom: 50px;
    // }

    .widget_categories ul li {
      padding: 10px 0;
    }

    .widget_categories ul {
      font-size: 17px;
      letter-spacing: 1.5px;
    }

    .widget_area .post_item .post_info .post_info_item + .post_info_item:before,
    aside .post_item .post_info .post_info_item + .post_info_item:before {
      margin: 0 0 0 7px;
    }

    .widget_area .post_item .post_thumb,
    aside .post_item .post_thumb {
      margin: 0 18px 3px 0;
    }

    .content_wrap {
      width: 964px !important;
    }

    body:not(.expand_content):not(.body_style_fullwide):not(.body_style_fullscreen) [class*="content_wrap"] > .content {
      width: 617.0203442879px;
    }

    [class*="content_wrap"] > .sidebar {
      width: 328.1220657277px;
    }

    .columns_wrap {
      margin-right: -30px;
    }

    .columns_wrap > [class*="column-"] {
      padding-right: 30px;
    }

    .columns_wrap.columns_padding_bottom > [class*="column-"] {
      padding-bottom: 30px;
    }

    form button,
    input[type="submit"] {
      padding: 1em 3em;
    }

    .widget_calendar td {
      line-height: 2.5em;
    }

    .widget_calendar td#today:before {
      width: 2.5em;
      height: 2.5em;
      line-height: 2.5em;
    }

    .widget_calendar caption {
      padding: 12px;
    }

    .widget_tag_cloud a {
      padding: 5px 13px;
      margin: 0 5px 6px 0;
    }

    .widget_twitter .widget_content ul > li:before {
      width: 34px;
      height: 34px;
      line-height: 34px;
      font-size: 1.3em;
    }

    .widget_twitter .widget_content ul li {
      min-height: 34px;
    }

    .widget_twitter .widget_content ul li {
      padding-left: 50px;
    }

    table th,
    table td {
      padding: 1rem;
    }

    .footer_wrap .sc_layouts_menu > ul > li {
      font-size: 15px;
    }

    .sc_layouts_row_type_normal .search_wrap .search_field {
      width: 10em;
    }

    .sc_layouts_menu_nav > li > a,
    .sc_layouts_menu_nav > li.menu-item-has-children > a {
      padding: 0.85em 1em;
    }

    .trx_addons_scroll_to_top {
      right: 1px;
      width: 2.2em;
      height: 2.2em;
      line-height: 2.2em;
    }

    .sidebar .widget {
      padding: 20px;
    }

    .widget .widget_title {
      margin-bottom: 20px;
      padding: 16px 13px;
    }

    .sidebar_inner .widget .widget_title {
      margin-left: -20px;
      margin-right: -20px;
      margin-top: -20px;
    }

    .sc_layouts_row_type_normal {
      padding: 0.8em 0;
    }

    .single-post .post_item_single .post_content_single {
      padding: 30px;
    }

    .single-post .header_content_wrap + .post_content_single {
      padding-top: 0;
    }

    .single-post .post_item_single .post_header_single {
      padding: 20px 30px 20px;
    }

    .nav-links-single .nav-links .post-title {
      margin: 10px 0 0;
      font-size: 25px;
    }

    .related_wrap {
      margin-top: 60px;
      padding-top: 60px;
    }

    .section_title {
      margin: 0 0 0.5em;
      font-size: 3em;
    }

    .related_wrap .post_title {
      font-size: 26px;
    }

    .related_wrap.related_style_classic .post_featured {
      margin-bottom: 1.8em;
    }

    .comments_form_wrap {
      margin-top: 60px;
      padding-top: 60px;
    }

    .related_wrap + .comments_wrap .comments_form_wrap {
      margin-top: 30px;
    }

    .nav-links {
      margin-top: 1.6em;
    }

    .copyright-text {
      font-size: 15px;
    }

    .elementor-section.elementor-section-boxed > .elementor-container {
      max-width: 994px;
    }

    .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  @media (max-width: 1023px) {
    .sc_layouts_title_caption {
      font-size: 3rem;
    }

    h1 {
      font-size: 3em;
      margin-top: 45px;
      margin-bottom: 15px;
      letter-spacing: 1.3px;
    }

    h2 {
      font-size: 2.6em;
      margin-top: 42px;
      margin-bottom: 15px;
      letter-spacing: 1.3px;
    }

    h3 {
      font-size: 2.1em;
      margin-top: 35px;
      margin-bottom: 14px;
      letter-spacing: 1px;
    }

    h5 {
      font-size: 1.45em;
      margin-top: 30px;
      margin-bottom: 13px;
    }

    h6 {
      font-size: 1.1em;
      margin-top: 25px;
      margin-bottom: 12px;
    }

    .content_wrap {
      width: 708px !important;
    }

    body:not(.expand_content):not(.body_style_fullwide):not(.body_style_fullscreen) [class*="content_wrap"] > .content {
      width: 100% !important;
      float: none !important;
    }

    [class*="content_wrap"] > .sidebar {
      width: 100% !important;
      float: none !important;
      margin-top: 75px;
    }

    // .page_content_wrap {
    //   padding-top: 80px;
    //   padding-bottom: 80px;
    // }

    .sc_layouts_row_type_normal .search_wrap .search_field {
      width: 9em;
      padding: 0 40px 0 20px !important;
      height: 40px;
      line-height: 40px;
    }

    .sc_layouts_row_type_normal .search_wrap .search_submit {
      width: 40px;
      height: 40px;
      line-height: 40px;
    }

    .sc_layouts_menu_nav > li > a,
    .sc_layouts_menu_nav > li.menu-item-has-children > a {
      padding: 0.8em;
    }

    .sc_layouts_row_type_normal {
      padding: 0.6em 0;
    }

    form.mc4wp-form .mc4wp-form-fields input[type="submit"] {
      min-width: 7.5em;
    }

    form.mc4wp-form .mc4wp-form-fields input[type="email"] {
      padding: 0 8em 0 2em;
    }

    .copyright-text {
      font-size: 14px;
      line-height: 1.4em;
    }

    .footer_wrap .sc_layouts_menu > ul > li > a {
      padding: 0.5em 0.8em;
    }

    .footer_wrap .sc_layouts_menu > ul > li {
      font-size: 14px;
    }

    .sc_layouts_row_type_normal .sc_layouts_item_icon {
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-size: 13px;
    }

    .sc_layouts_row_type_normal .search_wrap .search_submit {
      width: 35px;
    }

    .sc_layouts_row_type_normal .search_wrap .search_field {
      width: 7em;
      padding: 0 30px 0 12px !important;
    }

    .post_meta_item.post_categories a {
      padding: 3px 10px;
    }

    .post_meta .post_meta_item:after {
      margin: 0 0.3em;
    }

    .socials_wrap {
      margin: -8px 0 0 0;
    }

    .socials_wrap .social_item {
      margin: 8px 8px 0 0;
    }

    .socials_wrap .social_item .social_icon {
      width: 36px;
      height: 36px;
      line-height: 36px;
      font-size: 18px;
    }

    .elementor-section.elementor-section-boxed > .elementor-container {
      max-width: 738px;
    }

    .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  @media (max-width: 767px) {
    .sc_layouts_title_caption {
      font-size: 2.6rem;
    }

    h1 {
      font-size: 2.6em;
      margin-top: 40px;
      margin-bottom: 15px;
      letter-spacing: 1.3px;
    }

    h2 {
      font-size: 2.3em;
      margin-top: 38px;
      margin-bottom: 15px;
      letter-spacing: 1.3px;
    }

    h3 {
      font-size: 2em;
      margin-top: 30px;
      margin-bottom: 14px;
      letter-spacing: 1px;
    }

    h5 {
      font-size: 1.3em;
      margin-top: 28px;
      margin-bottom: 13px;
    }

    h6 {
      font-size: 1em;
      margin-top: 23px;
      margin-bottom: 12px;
    }

    .menu_mobile .sc_layouts_logo {
      margin: 2.4rem 0 0;
    }

    .content_wrap {
      width: 440px !important;
    }

    body:not(.expand_content):not(.body_style_fullwide):not(.body_style_fullscreen) [class*="content_wrap"] > .content {
      margin-bottom: 0;
    }

    [class*="content_wrap"] > .sidebar {
      margin-top: 60px;
    }

    .columns_wrap {
      margin-right: -20px;
    }

    .columns_wrap > [class*="column-"] {
      padding-right: 20px;
    }

    .columns_wrap.columns_padding_bottom > [class*="column-"] {
      padding-bottom: 20px;
    }

    .columns_wrap:not(.columns_fluid) > [class*="column-"] {
      width: 100%;
    }

    // .page_content_wrap {
    //   padding-top: 60px;
    //   padding-bottom: 60px;
    // }

    .post_item_single .post_content > .post_meta_single .post_tags,
    .post_item_single .post_content > .post_meta_single .post_share {
      max-width: none;
      float: none;
      margin-top: 0;
    }

    .post_item_single .post_content > .post_meta_single .post_share {
      margin-top: 2em;
    }

    .post_item_single .post_content > .post_meta_single .post_share .social_item:first-child {
      margin-left: 0;
    }

    form button,
    input[type="submit"] {
      padding: 0.84em 2.5em;
    }

    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="search"],
    textarea,
    form.mc4wp-form .mc4wp-form-fields input[type="email"] {
      padding: 0.8em 1.5em;
    }

    .menu_mobile .menu_mobile_inner {
      overflow-y: auto;
      top: 0;
      bottom: 0;
    }

    .sc_layouts_menu_nav > li > a,
    .sc_layouts_menu_nav > li.menu-item-has-children > a {
      padding: 0.55em;
    }

    .sidebar .widget + .widget {
      margin-top: 20px;
    }

    .widget_area .post_item .post_title,
    aside .post_item .post_title {
      font-size: 16px;
    }

    .widget_calendar td {
      line-height: 2.7em;
    }

    .single-post .post_item_single .post_header_single {
      padding: 15px 20px 15px;
    }

    .single-post .post_item_single .post_content_single {
      padding: 20px;
    }

    .single-post .header_content_wrap + .post_content_single {
      padding-top: 0;
    }

    .post_item_single .post_content > .post_meta_single .post_share {
      margin-top: 0.5em;
    }

    .post_item_single .post_content > .post_meta_single .share_meta_label {
      margin-right: 5px;
    }

    .nav-links-single .nav-links {
      margin-top: 40px;
    }

    .nav-links-single .nav-links .post-title {
      margin: 10px 0 0;
      font-size: 20px;
    }

    .related_wrap {
      margin-top: 40px;
      padding-top: 40px;
    }

    .section_title {
      font-size: 28px;
      letter-spacing: 1px;
    }

    .comments_form_wrap {
      margin-top: 40px;
      padding-top: 40px;
    }

    .comments_wrap .comments_field textarea {
      min-height: 10.5em;
    }

    .comments_wrap .comments_field input,
    .comments_wrap .comments_field textarea {
      padding: 0.8em 1.5em;
    }

    body div .sc_layouts_column .copyright-text {
      text-align: center;
      display: block;
      width: 100% !important;
    }

    .sc_item_title {
      letter-spacing: 1px;
    }

    .widget_recent_posts .post_info > .post_info_item.post_info_posted:first-child {
      display: inline-block;
    }

    .widget_recent_posts .post_info > .post_info_item.post_info_posted_by:nth-child(2):before {
      display: inline;
    }

    .elementor-section.elementor-section-boxed > .elementor-container {
      max-width: 460px;
    }

    .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  @media (max-width: 479px) {
    p,
    ul {
      margin-bottom: 1.1em;
    }

    table th,
    table td {
      padding: 0.5rem;
    }

    body {
      letter-spacing: 0;
      line-height: 1.6em;
    }

    .sc_layouts_title_caption {
      font-size: 2.2rem;
    }

    h1 {
      font-size: 2.2em;
      margin-top: 28px;
      margin-bottom: 12px;
      letter-spacing: 1px;
      line-height: 0.93em;
    }

    h2 {
      font-size: 2em;
      margin-top: 28px;
      margin-bottom: 11px;
      letter-spacing: 1px;
      line-height: 0.96em;
    }

    h3 {
      font-size: 1.8em;
      margin-top: 23px;
      margin-bottom: 10px;
      letter-spacing: 1px;
      line-height: 0.91em;
    }

    h5 {
      font-size: 1.3em;
      margin-top: 20px;
      margin-bottom: 8px;
      line-height: 1.06em;
    }

    h6 {
      font-size: 1em;
      margin-top: 20px;
      margin-bottom: 8px;
      line-height: 1.23em;
    }

    // .page_content_wrap {
    //   padding-top: 50px;
    //   padding-bottom: 50px;
    // }

    .menu_mobile .sc_layouts_logo {
      margin: 2rem 0 0;
    }

    .menu_mobile_close {
      width: 3em;
      height: 3em;
      line-height: 3em;
    }

    .content_wrap {
      width: 280px !important;
    }

    [class*="content_wrap"] > .sidebar {
      margin-top: 50px;
    }

    .columns_wrap {
      margin-right: -20px;
    }

    .columns_wrap > [class*="column-"] {
      padding-right: 20px;
    }

    .columns_wrap.columns_padding_bottom > [class*="column-"] {
      padding-bottom: 20px;
    }

    .columns_wrap:not(.columns_fluid) > [class*="column-"] {
      width: 100%;
    }

    .menu_mobile .menu_mobile_nav_area > ul > li > a {
      font-size: 18px;
      line-height: 1.5em;
    }

    .menu_mobile .menu_mobile_nav_area li > a {
      font-size: 14px;
      line-height: 1.5em;
      width: 280px;
    }

    .menu_mobile .menu_mobile_nav_area li li .open_child_menu {
      top: -1px;
    }

    .menu_mobile_close:before,
    .menu_mobile_close:after {
      width: 35%;
    }

    .sidebar .widget {
      width: 100% !important;
    }

    .sidebar .widget + .widget {
      margin-top: 20px !important;
    }

    .nav-links-single .nav-links .nav-previous {
      padding-right: 8px;
    }

    .nav-links-single .nav-links .post-title {
      letter-spacing: 0;
    }

    .widget_area .post_item .post_title,
    aside .post_item .post_title {
      font-size: 15px;
    }

    .widget_calendar td {
      line-height: 2.2em;
    }

    .widget_calendar th {
      font-size: 18px !important;
    }

    .widget_calendar caption {
      padding: 8px;
    }

    .widget_tag_cloud a {
      padding: 4px 12px;
      margin: 0 4px 5px 0;
    }

    .widget_twitter .widget_content ul li {
      padding-left: 45px;
    }

    .widget_twitter .widget_content ul li + li:before {
      margin-top: 14px;
    }

    .recentcomments > a {
      font-size: 16px;
      line-height: 20px;
    }

    form.mc4wp-form .mc4wp-form-fields input[type="email"] {
      height: 40px;
      line-height: 40px;
    }

    form.mc4wp-form .mc4wp-form-fields input[type="submit"] {
      height: 40px;
      line-height: 40px;
      font-size: 13px;
    }

    .widget_search .search-field {
      padding: 0.8em 1.5em !important;
      padding-right: 3em !important;
    }

    .widget_search input.search-submit {
      width: 4.5em;
    }

    .widget_search form:after {
      width: 4em;
    }

    .widget_calendar td#today:before {
      width: 2.3em;
      height: 2.3em;
      line-height: 2.3em;
    }

    form button,
    input[type="submit"] {
      font-size: 13px;
    }

    .sc_layouts_row_type_normal .sc_layouts_item_icon {
      width: 35px;
      height: 35px;
      line-height: 35px;
    }

    .sc_layouts_menu_mobile_button .sc_layouts_item_icon {
      font-size: 25px !important;
    }

    .sc_item_title {
      letter-spacing: 1px;
    }

    .nav-links {
      margin-top: 1.3em;
    }

    .single-post .post_item_single .post_header_single {
      padding: 15px 20px 15px;
    }

    .related_wrap .post_title {
      font-size: 24px;
    }

    .related_wrap.related_style_classic .post_title + .post_content_inner {
      margin-top: 8px;
    }

    .comments_wrap .comments_author,
    .comments_wrap .comments_email {
      width: 100%;
    }

    .comments_wrap .comments_field {
      margin-bottom: 15px;
    }

    .related_wrap + .comments_wrap .comments_form_wrap {
      margin-top: 20px;
    }

    .post_item_single .post_content > .post_meta_single .post_tags a {
      padding: 5px 12px;
    }

    .post_item_single .post_content > .post_meta_single .post_tags a {
      padding: 5px 12px;
    }

    .related_wrap.related_style_classic .post_featured {
      margin-bottom: 1.45em;
    }

    .widget_tag_cloud .tagcloud {
      margin-bottom: -8px;
    }

    .widget ul > li:before {
      top: 0.7em;
    }

    .widget_recent_posts .post_info > .post_info_item.post_info_posted:first-child {
      display: block;
    }

    .widget_recent_posts .post_info > .post_info_item.post_info_posted_by:nth-child(2):before {
      display: none;
    }

    .widget_twitter .widget_content ul a {
      display: inline;
      hyphens: manual;
    }

    .nav-links-single .nav-links .nav-previous {
      padding: 20px 15px 18px;
    }

    .nav-links-single .nav-links .post-title {
      margin: 8px 0 0;
    }

    .nav-links-single .nav-links .screen-reader-text:hover,
    .nav-links-single .nav-links .screen-reader-text {
      font-size: 12px !important;
    }

    .elementor-section.elementor-section-boxed > .elementor-container {
      max-width: 300px;
    }

    .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

/*! CSS Used from: Embedded */
.recentcomments a {
  display: inline !important;
  padding: 0 !important;
  margin: 0 !important;
}

/*! CSS Used from: Embedded */
.elementor-6 .elementor-element.elementor-element-bef9ba7 {
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.08);
}

.elementor-6 .elementor-element.elementor-element-d06c25b {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-6 .elementor-element.elementor-element-214e821 .logo_image {
  max-height: 80px;
}

.elementor-6 .elementor-element.elementor-element-f2e75ce {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-6 .elementor-element.elementor-element-b70eb50:not(.elementor-motion-effects-element-type-background) {
  background-image: url(../../assets/images/header1.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.elementor-6 .elementor-element.elementor-element-b70eb50 {
  transition:
    background 0.3s,
    border 0.3s,
    border-radius 0.3s,
    box-shadow 0.3s;
}

.elementor-6 .elementor-element.elementor-element-ed1b682 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-6 .elementor-element.elementor-element-6850b4b .elementor-spacer-inner {
  height: 285px;
}

.elementor-6 .elementor-element.elementor-element-b76221f .elementor-spacer-inner {
  height: 180px;
}

.elementor-6 .elementor-element.elementor-element-612f306 .sc_layouts_title {
  min-height: 0px;
}

.elementor-6 .elementor-element.elementor-element-01551c2 .elementor-spacer-inner {
  height: 61px;
}

@media (max-width: 1024px) {
  .elementor-6 .elementor-element.elementor-element-b76221f .elementor-spacer-inner {
    height: 120px;
  }

  .elementor-6 .elementor-element.elementor-element-01551c2 .elementor-spacer-inner {
    height: 50px;
  }
}

@media (min-width: 768px) {
  .elementor-6 .elementor-element.elementor-element-d06c25b {
    width: 27.829%;
  }

  .elementor-6 .elementor-element.elementor-element-f2e75ce {
    width: 72.171%;
  }
}

@media (max-width: 1024px) and (min-width: 768px) {
  .elementor-6 .elementor-element.elementor-element-d06c25b {
    width: 32%;
  }

  .elementor-6 .elementor-element.elementor-element-f2e75ce {
    width: 68%;
  }
}

@media (max-width: 767px) {
  .elementor-6 .elementor-element.elementor-element-d06c25b {
    width: 70%;
  }

  .elementor-6 .elementor-element.elementor-element-f2e75ce {
    width: 30%;
  }

  .elementor-6 .elementor-element.elementor-element-b76221f .elementor-spacer-inner {
    height: 65px;
  }

  .elementor-6 .elementor-element.elementor-element-01551c2 .elementor-spacer-inner {
    height: 30px;
  }
}

/*! CSS Used from: Embedded */
.elementor-6 .elementor-element.elementor-element-bef9ba7 {
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.08);
}

.elementor-6 .elementor-element.elementor-element-d06c25b {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-6 .elementor-element.elementor-element-214e821 .logo_image {
  max-height: 80px;
}

.elementor-6 .elementor-element.elementor-element-f2e75ce {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-6 .elementor-element.elementor-element-b70eb50:not(.elementor-motion-effects-element-type-background) {
  background-image: url(../../assets/images/header1.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.elementor-6 .elementor-element.elementor-element-b70eb50 {
  transition:
    background 0.3s,
    border 0.3s,
    border-radius 0.3s,
    box-shadow 0.3s;
}

.elementor-6 .elementor-element.elementor-element-ed1b682 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-6 .elementor-element.elementor-element-6850b4b .elementor-spacer-inner {
  height: 285px;
}

.elementor-6 .elementor-element.elementor-element-b76221f .elementor-spacer-inner {
  height: 180px;
}

.elementor-6 .elementor-element.elementor-element-612f306 .sc_layouts_title {
  min-height: 0px;
}

.elementor-6 .elementor-element.elementor-element-01551c2 .elementor-spacer-inner {
  height: 61px;
}

@media (max-width: 767px) {
  .elementor-6 .elementor-element.elementor-element-d06c25b {
    width: 70%;
  }

  .elementor-6 .elementor-element.elementor-element-f2e75ce {
    width: 30%;
  }

  .elementor-6 .elementor-element.elementor-element-b76221f .elementor-spacer-inner {
    height: 65px;
  }

  .elementor-6 .elementor-element.elementor-element-01551c2 .elementor-spacer-inner {
    height: 30px;
  }
}

@media (min-width: 768px) {
  .elementor-6 .elementor-element.elementor-element-d06c25b {
    width: 27.829%;
  }

  .elementor-6 .elementor-element.elementor-element-f2e75ce {
    width: 72.171%;
  }
}

@media (max-width: 1024px) and (min-width: 768px) {
  .elementor-6 .elementor-element.elementor-element-d06c25b {
    width: 32%;
  }

  .elementor-6 .elementor-element.elementor-element-f2e75ce {
    width: 68%;
  }
}

@media (max-width: 1024px) {
  .elementor-6 .elementor-element.elementor-element-b76221f .elementor-spacer-inner {
    height: 120px;
  }

  .elementor-6 .elementor-element.elementor-element-01551c2 .elementor-spacer-inner {
    height: 50px;
  }
}

/*! CSS Used from: Embedded */
.elementor-622 .elementor-element.elementor-element-09bdb0e {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-622 .elementor-element.elementor-element-f08c64c .elementor-spacer-inner {
  height: 84px;
}

.elementor-622 .elementor-element.elementor-element-eabacc7 {
  transition:
    background 0.3s,
    border 0.3s,
    border-radius 0.3s,
    box-shadow 0.3s;
}

.elementor-622 .elementor-element.elementor-element-8e2b6db {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-622 .elementor-element.elementor-element-19f2f15 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-622 .elementor-element.elementor-element-37347fb {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-622 .elementor-element.elementor-element-54b219a {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-622 .elementor-element.elementor-element-baba68a .elementor-spacer-inner {
  height: 72px;
}

.elementor-622 .elementor-element.elementor-element-38e0052 .elementor-divider-separator {
  border-top-style: solid;
  border-top-width: 2px;
  width: 100%;
}

.elementor-622 .elementor-element.elementor-element-38e0052 .elementor-divider {
  padding-top: 0px;
  padding-bottom: 0px;
}

.elementor-622 .elementor-element.elementor-element-d85a5d0 .elementor-spacer-inner {
  height: 15px;
}

.elementor-622 .elementor-element.elementor-element-1c99334 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-622 .elementor-element.elementor-element-c6d692b {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-622 .elementor-element.elementor-element-87f9d0c {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-622 .elementor-element.elementor-element-fdaa68a .elementor-spacer-inner {
  height: 18px;
}

@media (min-width: 768px) {
  .elementor-622 .elementor-element.elementor-element-8e2b6db {
    width: 22.018%;
  }

  .elementor-622 .elementor-element.elementor-element-19f2f15 {
    width: 40.979%;
  }

  .elementor-622 .elementor-element.elementor-element-37347fb {
    width: 36.999%;
  }

  .elementor-622 .elementor-element.elementor-element-1c99334 {
    width: 60.092%;
  }

  .elementor-622 .elementor-element.elementor-element-c6d692b {
    width: 39.908%;
  }
}

@media (max-width: 1024px) {
  .elementor-622 .elementor-element.elementor-element-f08c64c .elementor-spacer-inner {
    height: 44px;
  }

  .elementor-622 .elementor-element.elementor-element-baba68a .elementor-spacer-inner {
    height: 37px;
  }
}

@media (max-width: 767px) {
  .elementor-622 .elementor-element.elementor-element-f08c64c .elementor-spacer-inner {
    height: 25px;
  }

  .elementor-622 .elementor-element.elementor-element-37347fb > .elementor-element-populated {
    margin: 3px 0px 0px 0px;
  }

  .elementor-622 .elementor-element.elementor-element-baba68a .elementor-spacer-inner {
    height: 17px;
  }

  .elementor-622 .elementor-element.elementor-element-d85a5d0 .elementor-spacer-inner {
    height: 9px;
  }

  .elementor-622 .elementor-element.elementor-element-fdaa68a .elementor-spacer-inner {
    height: 12px;
  }
}

/*! CSS Used from: Embedded */
.elementor-622 .elementor-element.elementor-element-09bdb0e {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-622 .elementor-element.elementor-element-f08c64c .elementor-spacer-inner {
  height: 84px;
}

.elementor-622 .elementor-element.elementor-element-eabacc7 {
  transition:
    background 0.3s,
    border 0.3s,
    border-radius 0.3s,
    box-shadow 0.3s;
}

.elementor-622 .elementor-element.elementor-element-8e2b6db {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-622 .elementor-element.elementor-element-19f2f15 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-622 .elementor-element.elementor-element-37347fb {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-622 .elementor-element.elementor-element-54b219a {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-622 .elementor-element.elementor-element-baba68a .elementor-spacer-inner {
  height: 72px;
}

.elementor-622 .elementor-element.elementor-element-38e0052 .elementor-divider-separator {
  border-top-style: solid;
  border-top-width: 2px;
  width: 100%;
}

.elementor-622 .elementor-element.elementor-element-38e0052 .elementor-divider {
  padding-top: 0px;
  padding-bottom: 0px;
}

.elementor-622 .elementor-element.elementor-element-d85a5d0 .elementor-spacer-inner {
  height: 15px;
}

.elementor-622 .elementor-element.elementor-element-1c99334 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-622 .elementor-element.elementor-element-c6d692b {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-622 .elementor-element.elementor-element-87f9d0c {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-622 .elementor-element.elementor-element-fdaa68a .elementor-spacer-inner {
  height: 18px;
}

@media (max-width: 1024px) {
  .elementor-622 .elementor-element.elementor-element-f08c64c .elementor-spacer-inner {
    height: 44px;
  }

  .elementor-622 .elementor-element.elementor-element-baba68a .elementor-spacer-inner {
    height: 37px;
  }
}

@media (max-width: 767px) {
  .elementor-622 .elementor-element.elementor-element-f08c64c .elementor-spacer-inner {
    height: 25px;
  }

  .elementor-622 .elementor-element.elementor-element-37347fb > .elementor-element-populated {
    margin: 3px 0px 0px 0px;
  }

  .elementor-622 .elementor-element.elementor-element-baba68a .elementor-spacer-inner {
    height: 17px;
  }

  .elementor-622 .elementor-element.elementor-element-d85a5d0 .elementor-spacer-inner {
    height: 9px;
  }

  .elementor-622 .elementor-element.elementor-element-fdaa68a .elementor-spacer-inner {
    height: 12px;
  }
}

@media (min-width: 768px) {
  .elementor-622 .elementor-element.elementor-element-8e2b6db {
    width: 22.018%;
  }

  .elementor-622 .elementor-element.elementor-element-19f2f15 {
    width: 40.979%;
  }

  .elementor-622 .elementor-element.elementor-element-37347fb {
    width: 36.999%;
  }

  .elementor-622 .elementor-element.elementor-element-1c99334 {
    width: 60.092%;
  }

  .elementor-622 .elementor-element.elementor-element-c6d692b {
    width: 39.908%;
  }
}

/*! CSS Used from: Embedded */
.fcunited_inline_1671874526 {
  background-color: #f4f4f4;
}

.trx_addons_inline_1743425229 img {
  max-height: 80px;
}

/*! CSS Used keyframes */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/**** News Items ****/

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/trx_addons/css/__styles.css ; media=all */
@media all {
  .post_meta_item {
    margin-left: 0.5em;
    white-space: nowrap;
  }

  .post_meta_item:before {
    vertical-align: top;
    line-height: inherit;
    margin: 0 0.3em 0 0;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/style.css ; media=all */
@media all {
  div,
  span,
  h4,
  a,
  article {
    font-family: inherit;
    font-size: 100%;
    line-height: inherit;
    font-weight: inherit;
    font-style: inherit;
    border: 0;
    outline: 0;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
  }

  article {
    display: block;
  }

  a {
    text-decoration: none;
    background: transparent;
  }

  a,
  a:hover,
  a:focus,
  a:active {
    outline: 0;
  }

  a {
    -webkit-transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
    -ms-transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
    transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
  }

  h4 > a {
    display: block;
  }

  :active,
  :focus {
    outline: 0;
  }

  img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
  }

  .post_featured {
    overflow: hidden;
    position: relative;
    margin-bottom: 1.8em;
  }

  .post_meta .post_meta_item {
    font-size: inherit;
    margin-left: 0;
    white-space: normal;
    -webkit-transition: color 0.3s ease;
    -ms-transition: color 0.3s ease;
    transition: color 0.3s ease;
  }

  .post_meta .post_meta_item:after {
    content: "";
    display: inline;
    font-family: inherit;
    font-size: inherit;
    font-weight: 400;
    font-style: normal;
    vertical-align: baseline;
    margin: 0 0.4em;
  }

  .post_meta .post_meta_item:last-child:after {
    display: none;
  }

  .post_meta_item.post_categories a {
    padding: 5px 16px;
    -webkit-border-radius: 15px;
    -ms-border-radius: 15px;
    border-radius: 15px;
    margin-right: 3px;
  }

  .post_meta_item.post_categories a:last-child {
    margin-right: 0;
  }

  .post_meta_item.post_categories {
    margin-left: -3px;
  }

  .post_featured .mask {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .post_meta_item.post_categories {
    margin-left: 0;
    margin-top: -5px !important;
    display: inline-block;
    margin-bottom: -5px !important;
  }

  .post_meta_item.post_categories a {
    display: inline-block;
    line-height: 20px;
    margin-bottom: 3px;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__plugins.css ; media=all */
@media all {
  .posts_container.columns_wrap .post_layout_classic {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 100%;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .post_layout_classic .post_layout_classic_wrap {
    padding: 30px;
  }

  .post_layout_classic {
    position: relative;
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    z-index: 1;
  }

  .post_layout_classic .post_featured[class*="hover_"] {
    display: block;
  }

  .post_layout_classic .post_featured {
    margin-bottom: 8px;
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }

  .post_layout_classic .post_featured img {
    width: 100%;
  }

  .post_layout_classic .post_header {
    padding-top: 0.01px;
  }

  .post_layout_classic .post_title {
    font-size: 36px;
    line-height: 0.95em;
    margin: 0;
  }

  .post_layout_classic .post_meta {
    margin: 0;
  }

  .post_layout_classic .post_meta + .post_title {
    margin-top: 12px;
  }

  .post_layout_classic .post_header + .post_content {
    padding-top: 20px;
  }

  .post_featured[class*="hover_"] {
    position: relative;
    -webkit-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
    display: inline-block;
    vertical-align: top;
  }

  .post_featured[class*="hover_"],
  .post_featured[class*="hover_"] * {
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .post_featured .mask {
    content: " ";
    position: absolute;
    z-index: 4;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .post_featured.hover_simple:hover .mask {
    opacity: 1;
  }

  .post_featured.hover_simple .icons {
    position: absolute;
    z-index: 6;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__custom.css ; media=all */
@media all {
  h4 {
    font-family: "BebasNeue", sans-serif;
    font-size: 2.143em;
    font-weight: 700;
    font-style: normal;
    line-height: 1.06em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 1.1px;
    margin-bottom: 0.6em;
  }

  .post_meta {
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.4em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0px;
    margin-top: 0.4em;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__colors-default.css ; media=all */
@media all {
  .post_item {
    h4 {
      a {
        color: #262f3e;
      }
    }
  }

  .scheme_default h4 a:hover {
    color: #ff0000;
  }

  .scheme_default a {
    color: #ff0000;
  }

  .scheme_default a:hover {
    color: #081324;
  }

  .scheme_default .post_item {
    color: #797e87;
  }

  .scheme_default .post_meta,
  .scheme_default .post_meta_item,
  .scheme_default .post_meta_item:after,
  .scheme_default .post_meta_item:hover:after,
  .scheme_default .post_meta_item a {
    color: #797e87;
  }

  .scheme_default .post_meta_item.post_categories a {
    color: #ffffff;
    background-color: #ff0000;
  }

  .scheme_default .post_meta_item.post_categories a:hover {
    color: #ffffff;
    background-color: #081324;
  }

  .scheme_default .post_meta_item.post_date,
  .scheme_default .post_meta_item.post_date a {
    color: #ff0000;
  }

  .scheme_default .post_meta_item.post_date a:hover {
    color: #081324;
  }

  .scheme_default .post_date a:hover,
  .scheme_default .post_meta_item a:hover {
    color: #262f3e;
  }

  .scheme_default .post_item .post_title a:hover {
    color: #ff0000;
  }

  .scheme_default .post_layout_classic {
    border-color: #e3e3e3;
  }

  .scheme_default .post_layout_classic {
    background-color: #ffffff;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__responsive.css ; media=all */
@media all {
  @media (max-width: 1439px) {
    .post_layout_classic .post_header + .post_content {
      padding-top: 10px;
    }

    .post_layout_classic .post_content {
      font-size: 1em;
    }

    .post_layout_classic .post_title {
      font-size: 32px;
    }
  }

  @media (max-width: 1279px) {
    h4 {
      font-size: 2em;
      margin-bottom: 5px;
      letter-spacing: 1px;
    }

    .post_layout_classic .post_title {
      font-size: 28px;
    }

    .post_layout_classic .post_featured {
      margin-bottom: 5px;
    }

    .post_layout_classic .post_layout_classic_wrap {
      padding: 25px;
    }
  }

  @media (max-width: 1023px) {
    h4 {
      font-size: 1.8em;
      margin-bottom: 13px;
      letter-spacing: 1px;
    }

    .post_meta_item.post_categories a {
      padding: 3px 10px;
    }

    .post_meta .post_meta_item:after {
      margin: 0 0.3em;
    }

    .post_layout_classic .post_featured {
      margin-bottom: 5px;
    }

    .blog_style_classic_3 .post_layout_classic_wrap {
      padding: 20px;
    }

    .post_layout_classic .post_meta_item.post_categories {
      margin-bottom: 0 !important;
    }

    .post_layout_classic .post_title {
      font-size: 24px;
    }
  }

  @media (max-width: 767px) {
    h4 {
      font-size: 1.6em;
      margin-top: 28px;
      margin-bottom: 13px;
      letter-spacing: 1px;
    }

    .post_layout_classic_wrap {
      padding: 25px;
    }
  }

  @media (max-width: 479px) {
    h4 {
      font-size: 1.6em;
      margin-top: 22px;
      margin-bottom: 9px;
      letter-spacing: 1px;
      line-height: 1.06em;
    }

    .post_layout_classic .post_header + .post_content {
      padding-top: 8px;
    }

    .post_layout_classic .post_featured {
      margin-bottom: 5px;
    }
  }
}

// *** NAVIGATION *** //

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/woocommerce/assets/css/woocommerce.css?ver=3.6.2 ; media=all */
@media all {
  .screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/elementor/assets/css/frontend.min.css?ver=2.5.15 ; media=all */
@media all {
  .screen-reader-text {
    position: absolute;
    top: -10000em;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/style.css ; media=all */
@media all {
  div,
  span,
  h2,
  a {
    font-family: inherit;
    font-size: 100%;
    line-height: inherit;
    font-weight: inherit;
    font-style: inherit;
    border: 0;
    outline: 0;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
  }

  nav {
    display: block;
  }

  a {
    text-decoration: none;
    background: transparent;
  }

  a,
  a:hover,
  a:focus,
  a:active {
    outline: 0;
  }

  a {
    -webkit-transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
    -ms-transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
    transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
  }

  :active,
  :focus {
    outline: 0;
  }

  .screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .screen-reader-text:hover,
  .screen-reader-text:active,
  .screen-reader-text:focus {
    display: block;
    top: 5px;
    left: 5px;
    width: auto;
    height: auto;
    font-size: 0.8em;
    line-height: normal;
    padding: 1em 1.5em;
    color: #21759b;
    background-color: #f1f1f1;
    border-radius: 3px;
    clip: auto !important;
    text-decoration: none;
    z-index: 100000;
    -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    -ms-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  }

  .nav-links {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 2em;
    text-align: left;
    border: none;
    list-style-type: none;
    clear: both;
  }

  .nav-links .page-numbers {
    display: inline-block;
    vertical-align: top;
    font-size: inherit;
    font-weight: inherit;
    margin: 3px 1px 0 0;
    padding: 0;
    border: none;
    width: 39px;
    height: 39px;
    line-height: 39px;
    text-align: center;
    -webkit-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease,
      background-position 0.3s ease;
    -ms-transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease,
      background-position 0.3s ease;
    transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease,
      background-position 0.3s ease;
  }

  .nav-links .page-numbers.prev {
    text-indent: 200px;
    overflow: hidden;
    position: relative;
    display: none;
  }

  .nav-links .page-numbers.prev:before {
    font-family: "fontello";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    text-indent: 0;
  }

  .nav-links .page-numbers.prev:before {
    content: "\e939";
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__custom.css ; media=all */
@media all {
  h2 {
    font-family: "BebasNeue", sans-serif;
    font-size: 3.429em;
    font-weight: 700;
    font-style: normal;
    line-height: 0.96em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 1.75px;
    margin-bottom: 0.52em;
  }

  .nav-links {
    font-family: "BebasNeue", sans-serif;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__colors-default.css ; media=all */
@media all {
  .scheme_default a {
    color: #ff0000;
  }

  .scheme_default a:hover {
    color: #081324;
  }

  .scheme_default .nav-links .page-numbers {
    color: #262f3e;
  }

  .scheme_default .nav-links a.page-numbers:hover,
  .scheme_default .nav-links .page-numbers.current {
    background-color: #081224;
    color: #ffffff;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__responsive.css ; media=all */
@media all {
  @media (max-width: 1279px) {
    h2 {
      font-size: 3em;
      margin-top: 25px;
      margin-bottom: 20px;
      letter-spacing: 1.5px;
    }

    .nav-links {
      margin-top: 1.6em;
    }
  }

  @media (max-width: 1023px) {
    h2 {
      font-size: 2.6em;
      margin-top: 42px;
      margin-bottom: 15px;
      letter-spacing: 1.3px;
    }

    .blog_style_classic_3 .pagination .nav-links {
      margin-top: 1.2em;
    }
  }

  @media (max-width: 767px) {
    h2 {
      font-size: 2.3em;
      margin-top: 38px;
      margin-bottom: 15px;
      letter-spacing: 1.3px;
    }

    .blog_style_classic_3 .pagination .nav-links {
      margin-top: 0.8em;
    }
  }

  @media (max-width: 479px) {
    h2 {
      font-size: 2em;
      margin-top: 28px;
      margin-bottom: 11px;
      letter-spacing: 1px;
      line-height: 0.96em;
    }

    .nav-links {
      margin-top: 1.3em;
    }

    .blog_style_classic_3 .pagination .nav-links {
      margin-top: 0.6em;
    }
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/sportspress/assets/css/sportspress-style.css?ver=2.3 ; media=all */
@media all {
  .sp-template * {
    box-sizing: border-box;
    font-weight: 400;
    text-transform: none;
    letter-spacing: normal;
    color: #333;
  }

  .sp-template a:hover {
    text-decoration: underline !important;
  }

  .sp-view-all-link a {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    text-decoration: none !important;
    color: rgba(51, 51, 51, 0.5) !important;
  }

  .sp-view-all-link a:hover {
    color: #333 !important;
    text-decoration: none !important;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/elementor/assets/css/frontend.min.css?ver=2.5.15 ; media=all */
@media all {
  .elementor *,
  .elementor :after,
  .elementor :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .elementor a {
    -webkit-box-shadow: none;
    box-shadow: none;
    text-decoration: none;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/style.css ; media=all */
@media all {
  a {
    font-family: inherit;
    font-size: 100%;
    line-height: inherit;
    font-weight: inherit;
    font-style: inherit;
    border: 0;
    outline: 0;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
  }

  a {
    text-decoration: none;
    background: transparent;
  }

  a,
  a:hover,
  a:focus,
  a:active {
    outline: 0;
  }

  a {
    -webkit-transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
    -ms-transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
    transition:
      color 0.3s ease,
      background-color 0.3s ease,
      border-color 0.3s ease;
  }

  :active,
  :focus {
    outline: 0;
  }

  .sp-view-all-link > a {
    -webkit-appearance: none;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    text-transform: none;
    white-space: nowrap;
    padding: 1.38em 3.5em;
    letter-spacing: 0;
    height: auto;
    border-width: 0 !important;
    -webkit-border-radius: 30px;
    -ms-border-radius: 30px;
    border-radius: 30px;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__plugins.css ; media=all */
@media all {
  .sp-template a:hover {
    text-decoration: none !important;
  }

  .sp-view-all-link > a {
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    text-transform: capitalize !important;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__custom.css ; media=all */
@media all {
  .sp-view-all-link > a {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 18px;
    text-decoration: none;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__colors-default.css ; media=all */
@media all {
  .scheme_default a {
    color: #ff0000;
  }

  .scheme_default a:hover {
    color: #081324;
  }

  .scheme_default .sp-view-all-link > a {
    color: #ffffff;
    background-color: #ff0000;
  }

  .scheme_default div .sp-view-all-link > a {
    color: #ffffff !important;
  }

  .scheme_default .sp-view-all-link > a:hover {
    color: #ffffff;
    background-color: #262f3e;
  }

  .scheme_default div .sp-view-all-link > a:hover {
    color: #ffffff;
  }

  .scheme_default .sp-template * {
    color: #797e87;
  }

  .scheme_default .sp-template a {
    color: #262f3e;
    font-weight: 400;
    font-size: 15px;
  }

  .scheme_default .sp-template a:hover {
    color: #ff0000;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__responsive.css ; media=all */
@media all {
  @media (max-width: 1279px) {
    .sp-view-all-link > a {
      padding: 1em 3em;
    }
  }

  @media (max-width: 767px) {
    .sp-view-all-link > a {
      padding: 0.84em 2.5em;
    }

    .sp-view-all-link > a {
      font-size: 13px !important;
    }
  }

  @media (max-width: 479px) {
    .sp-view-all-link > a {
      font-size: 13px;
    }
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/sportspress/assets/css/sportspress.css?ver=2.6.14 ; media=all */
@media all {
  .sp-template {
    margin-bottom: 1.5em;
  }

  .sp-scrollable-table-wrapper {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
  }

  .sp-data-table {
    width: 100%;
    table-layout: auto;
    border-spacing: 0;
  }

  .sp-data-table thead th {
    white-space: nowrap;
  }

  .sp-data-table thead .sorting {
    cursor: pointer;
  }

  .sp-data-table tbody a {
    text-decoration: none;
  }

  .sp-data-table .sorting:after {
    font-family: dashicons;
  }

  .sp-data-table .sorting:after {
    content: "\f156";
    color: transparent;
    position: absolute;
  }

  .sp-data-table .data-name {
    text-align: left;
  }

  .sp-data-table .data-number {
    width: 1px;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/sportspress/assets/css/sportspress-style.css?ver=2.3 ; media=all */
@media all {
  .sp-template,
  .sp-data-table,
  .sp-table-caption {
    font-family: "Roboto", "Helvetica", sans-serif;
  }

  .sp-template {
    clear: both;
    overflow: hidden;
  }

  .sp-template h4 {
    font-family: "Roboto", "Helvetica", sans-serif !important;
  }

  .sp-template {
    margin-bottom: 20px;
  }

  .sp-template *,
  .sp-data-table *,
  .sp-table-caption {
    box-sizing: border-box;
    text-transform: none;
    letter-spacing: normal;
    color: #333;
  }

  .sp-table-caption {
    margin: 0 !important;
    padding: 15px !important;
    background: #fff !important;
    border: 1px solid #e5e5e5 !important;
    border-bottom: none !important;
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    display: table !important;
    width: 100% !important;
  }

  .sp-template a:hover,
  .sp-data-table a:hover {
    text-decoration: underline;
  }

  .sp-data-table {
    margin: 0;
    background: #fff;
    border-collapse: collapse;
    border: 1px solid #e5e5e5;
    width: 100%;
  }

  .sp-data-table tr {
    border: none;
  }

  .sp-data-table th,
  .sp-data-table td {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    border: 1px solid #e5e5e5;
    padding: 5px 10px;
    text-align: left;
  }

  .sp-data-table th {
    font-size: 12px;
    font-weight: 500;
    background: #fff;
    color: #999;
    border: none;
  }
}

@media all {
  .sp-data-table .data-name {
    text-align: left !important;
  }

  .sp-data-table .data-player {
    text-align: left !important;
  }
}

@media all {
  .sp-template a:hover,
  .sp-data-table a:hover {
    text-decoration: none !important;
  }

  .standings {
    margin-bottom: 30px;
  }

  .sp-table-caption {
    margin: 0 0 30px !important;
    padding: 0 !important;
    background: transparent !important;
    border: none !important;
    font-size: 48px !important;
    font-weight: 700 !important;
    line-height: 1em !important;
    display: block !important;
    width: 100% !important;
  }

  .sp-template h4 {
    letter-spacing: 1px !important;
  }

  .sp-data-table {
    border: none !important;
    background: transparent !important;
  }

  .sp-data-table th {
    font-size: 20px !important;
    font-weight: 700 !important;
    letter-spacing: 1.2px !important;
    padding: 10px 15px !important;
  }

  .sp-data-table td {
    font-weight: 400 !important;
    line-height: 1.4em !important;
    border: none !important;
    padding: 15px !important;
    text-align: left;
    background: transparent !important;
    letter-spacing: 0.6px !important;
  }

  .sp-data-table tr:last-child {
    border-bottom: 1px solid #ddd !important;
  }

  .sp-data-table .data-name {
    text-align: left !important;
  }

  .sp_event .sp-template {
    margin-bottom: 90px !important;
  }

  .sp_event .sp-template.sp-template-event-performance {
    margin-bottom: 30px !important;
  }

  .sp-data-table.sp-event-performance .data-number {
    font-size: 36px !important;
    font-weight: 700 !important;
  }

  .sp-data-table.sp-event-performance .data-name > a {
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  }

  .sp-data-table.sp-event-performance .data-name > p {
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  }

  .sp-data-table.sp-event-performance .data-player > a {
    font-size: 15px;
    line-height: 22px;
    text-align: left;
  }

  .sp-data-table.sp-event-performance .data-player > p {
    font-size: 15px;
    line-height: 22px;
    text-align: left;
    margin: 0;
  }

  .sp-data-table.sp-event-performance .data-games > p {
    font-size: 18px;
    line-height: 22px;
    text-align: left;
  }

  .sp-data-table.sp-event-performance .data-games > p span:nth-child(odd) {
    color: #262f3e !important;
  }

  .sp-data-table {
    &.data-table-results {
      .data-header {
        padding: 0;
        background: $gray-300;
        td {
          color: $primary-dark !important;
        }
      }
      tbody {
        tr:last-child {
          td {
            padding-bottom: 20px !important;
          }
          // border: none !important;
        }
      }
      td {
        padding: 15px !important;
        text-align: left;
        font-size: 16px;
      }
      .data-games {
        p {
          font-size: 18px;
          line-height: 22px;
          text-align: left;
          margin: 0;
          span:nth-child(odd) {
            color: #262f3e !important;
          }
        }
      }
    }
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__custom.css ; media=all */
@media all {
  .sp-data-table.sp-event-performance .data-number,
  .sp-data-table th,
  .sp-table-caption,
  .sp-template h4 {
    font-family: "BebasNeue", sans-serif !important;
  }

  .sp-template,
  .sp-data-table,
  .sp-table-caption {
    font-family: "Poppins", sans-serif;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__colors-default.css ; media=all */
@media all {
  .scheme_default table th,
  .scheme_default table th + th {
    border-color: #262f3e;
  }

  .scheme_default table td,
  .scheme_default table td + td {
    color: #797e87;
    border-color: #e3e3e3;
  }

  .scheme_default table th {
    color: #8c9097;
    background-color: #081224;
  }

  .scheme_default table > tbody > tr:nth-child(2n + 1) > td {
    background-color: #ebebeb;
  }

  .scheme_default .sp-template *,
  .scheme_default .sp-data-table *,
  .scheme_default .sp-table-caption {
    color: #797e87;
  }

  .scheme_default .sp-table-caption {
    color: #262f3e;
  }

  .scheme_default .sp-data-table td {
    color: #262f3e;
  }

  .scheme_default .sp-data-table th {
    color: #262f3e;
    background: #f4f4f4;
  }

  .scheme_default .sp-template a,
  .scheme_default .sp-data-table a {
    color: #262f3e;
  }

  .scheme_default .sp-template a:hover,
  .scheme_default .sp-data-table a:hover {
    color: #ff0000;
  }
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__responsive.css ; media=all */
@media all {
  @media (max-width: 1279px) {
    table th,
    table td {
      padding: 1rem;
    }

    .sp_event .sp-template {
      margin-bottom: 80px !important;
    }

    .sp-data-table.sp-event-performance .data-number {
      font-size: 32px !important;
    }

    .sp-data-table td {
      padding: 15px 20px !important;
    }
  }

  @media (max-width: 1023px) {
    .sp-table-caption {
      margin: 0 0 25px !important;
      font-size: 38px !important;
    }

    .sp_event .sp-template {
      margin-bottom: 75px !important;
    }

    .sp-data-table.sp-event-performance .data-name > a {
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media (max-width: 767px) {
    .sp-data-table th {
      font-size: 18px !important;
      letter-spacing: 1px !important;
      padding: 8px 10px !important;
    }

    .sp-data-table td {
      padding: 8px 10px !important;
    }

    .sp-table-caption {
      margin: 0 0 20px !important;
      font-size: 36px !important;
    }

    .sp-template {
      margin-bottom: 20px !important;
    }

    .sp_event .sp-template {
      margin-bottom: 60px !important;
    }

    .sp-data-table.sp-event-performance .data-number {
      font-size: 26px !important;
    }

    .sp-data-table thead th {
      position: relative;
    }
  }

  @media (max-width: 479px) {
    table th,
    table td {
      padding: 0.5rem;
    }

    .sp-data-table th {
      font-size: 16px !important;
      letter-spacing: 1px !important;
      padding: 6px 8px !important;
    }

    .sp-data-table td {
      padding: 6px 8px !important;
    }

    .sp-table-caption {
      margin: 0 0 16px !important;
      font-size: 32px !important;
    }

    .sp_event .sp-template {
      margin-bottom: 45px !important;
    }
  }
}

.fixture-list {
  .time {
    font-size: 25px;
    margin-bottom: 0;
  }
}

.page-light {
  background-color: #f4f4f4;
}

@media (max-width: 768px) {
  .fixture {
    h3 {
      font-size: 30px;
    }
  }
}

.no-results {
  font-size: 16px;
}

.match-details {
  p {
    line-height: 20px;
    margin: 0;
  }
}

.match-details {
  .sub-title {
    margin-bottom: 5px;
    font-size: 14px;
    text-transform: uppercase;
    color: #27303f;
    font-weight: bold;
  }
}
