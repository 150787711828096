@charset "UTF-8";
@import "../../node_modules/@fortawesome/fontawesome-pro/css/all.css";
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #1b84e7;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #f27510;
  --yellow: #F49917;
  --green: #23BF08;
  --teal: #1CAF9A;
  --cyan: #5B93D3;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #262f3e;
  --secondary: #ff0000;
  --success: #23BF08;
  --info: #1b84e7;
  --warning: #F49917;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #868ba1;
  text-align: left;
  background-color: #f0f2f7;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #262f3e;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #090b0f;
  text-decoration: none;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.1875rem;
}

h2, .h2 {
  font-size: 1.75rem;
}

h3, .h3 {
  font-size: 1.53125rem;
}

h4, .h4 {
  font-size: 1.3125rem;
}

h5, .h5 {
  font-size: 1.09375rem;
}

h6, .h6 {
  font-size: 0.875rem;
}

.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f0f2f7;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 940px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1240px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #868ba1;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #868ba1;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c2c5c9;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #8e939b;
}

.table-hover .table-primary:hover {
  background-color: #b4b8bd;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b4b8bd;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #ffb8b8;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #ff7a7a;
}

.table-hover .table-secondary:hover {
  background-color: #ff9f9f;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #ff9f9f;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c1edba;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8dde7f;
}

.table-hover .table-success:hover {
  background-color: #afe8a6;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #afe8a6;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bfddf8;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #88bff3;
}

.table-hover .table-info:hover {
  background-color: #a8d1f5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #a8d1f5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fce2be;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f9ca86;
}

.table-hover .table-warning:hover {
  background-color: #fbd7a6;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fbd7a6;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.188rem + 2px);
  padding: 0.594rem 0.594rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #566b8d;
  outline: 0;
  box-shadow: none;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.594rem + 1px);
  padding-bottom: calc(0.594rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.09375rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.765625rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.594rem;
  padding-bottom: 0.594rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #868ba1;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #23BF08;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(35, 191, 8, 0.9);
  border-radius: 3px;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #23BF08;
  padding-right: calc(1.5em + 1.188rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2323BF08' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.297rem);
  background-size: calc(0.75em + 0.594rem) calc(0.75em + 0.594rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #23BF08;
  box-shadow: 0 0 0 0.2rem rgba(35, 191, 8, 0.25);
}
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.188rem);
  background-position: top calc(0.375em + 0.297rem) right calc(0.375em + 0.297rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #23BF08;
  padding-right: calc((1em + 1.188rem) * 3 / 4 + 1.594rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.594rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2323BF08' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.594rem/calc(0.75em + 0.594rem) calc(0.75em + 0.594rem);
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #23BF08;
  box-shadow: 0 0 0 0.2rem rgba(35, 191, 8, 0.25);
}
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #23BF08;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #23BF08;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #23BF08;
}
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #2cf00a;
  background-color: #2cf00a;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(35, 191, 8, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #23BF08;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #23BF08;
}
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #23BF08;
  box-shadow: 0 0 0 0.2rem rgba(35, 191, 8, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 3px;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 1.188rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.297rem);
  background-size: calc(0.75em + 0.594rem) calc(0.75em + 0.594rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.188rem);
  background-position: top calc(0.375em + 0.297rem) right calc(0.375em + 0.297rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 1.188rem) * 3 / 4 + 1.594rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.594rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.594rem/calc(0.75em + 0.594rem) calc(0.75em + 0.594rem);
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #868ba1;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.594rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #868ba1;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 47, 62, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #262f3e;
  border-color: #262f3e;
}
.btn-primary:hover {
  color: #fff;
  background-color: #171d26;
  border-color: #13171e;
}
.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 78, 91, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #262f3e;
  border-color: #262f3e;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #13171e;
  border-color: #0e1116;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 78, 91, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #ff0000;
  border-color: #ff0000;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #d90000;
  border-color: #cc0000;
}
.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 38, 38, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #ff0000;
  border-color: #ff0000;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #cc0000;
  border-color: #bf0000;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 38, 38, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #23BF08;
  border-color: #23BF08;
}
.btn-success:hover {
  color: #fff;
  background-color: #1c9a06;
  border-color: #1a8e06;
}
.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(68, 201, 45, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #23BF08;
  border-color: #23BF08;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1a8e06;
  border-color: #188205;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(68, 201, 45, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #1b84e7;
  border-color: #1b84e7;
}
.btn-info:hover {
  color: #fff;
  background-color: #1570c7;
  border-color: #146abb;
}
.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(61, 150, 235, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #1b84e7;
  border-color: #1b84e7;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #146abb;
  border-color: #1363b0;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(61, 150, 235, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #F49917;
  border-color: #F49917;
}
.btn-warning:hover {
  color: #fff;
  background-color: #da850a;
  border-color: #ce7d0a;
}
.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(212, 136, 26, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #F49917;
  border-color: #F49917;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #ce7d0a;
  border-color: #c27609;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(212, 136, 26, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #262f3e;
  border-color: #262f3e;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #262f3e;
  border-color: #262f3e;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 47, 62, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #262f3e;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #262f3e;
  border-color: #262f3e;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 47, 62, 0.5);
}

.btn-outline-secondary {
  color: #ff0000;
  border-color: #ff0000;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #ff0000;
  border-color: #ff0000;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #ff0000;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #ff0000;
  border-color: #ff0000;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.5);
}

.btn-outline-success {
  color: #23BF08;
  border-color: #23BF08;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #23BF08;
  border-color: #23BF08;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(35, 191, 8, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #23BF08;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #23BF08;
  border-color: #23BF08;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(35, 191, 8, 0.5);
}

.btn-outline-info {
  color: #1b84e7;
  border-color: #1b84e7;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #1b84e7;
  border-color: #1b84e7;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(27, 132, 231, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #1b84e7;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #1b84e7;
  border-color: #1b84e7;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(27, 132, 231, 0.5);
}

.btn-outline-warning {
  color: #F49917;
  border-color: #F49917;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #F49917;
  border-color: #F49917;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 153, 23, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #F49917;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #F49917;
  border-color: #F49917;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 153, 23, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #262f3e;
  text-decoration: none;
}
.btn-link:hover {
  color: #090b0f;
  text-decoration: none;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: none;
  box-shadow: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #868ba1;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #262f3e;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.765625rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.594rem 0.594rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 3px;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.594rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #262f3e;
  background-color: #262f3e;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #566b8d;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #6f84a7;
  border-color: #6f84a7;
}
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.15625rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.15625rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 3px;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #262f3e;
  background-color: #262f3e;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(38, 47, 62, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(38, 47, 62, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(38, 47, 62, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.15625rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(38, 47, 62, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.188rem + 2px);
  padding: 0.594rem 1.594rem 0.594rem 0.594rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.594rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 3px;
  appearance: none;
}
.custom-select:focus {
  border-color: #566b8d;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 47, 62, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.594rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.765625rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.09375rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.188rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 1.188rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #566b8d;
  box-shadow: none;
}
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 1.188rem + 2px);
  padding: 0.594rem 0.594rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 3px;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 1.188rem);
  padding: 0.594rem 0.594rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 3px 3px 0;
}

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f0f2f7, none;
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f0f2f7, none;
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #f0f2f7, none;
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #262f3e;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #6f84a7;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #262f3e;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #6f84a7;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #262f3e;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #6f84a7;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #f0f2f7;
  border-color: #dee2e6 #dee2e6 #f0f2f7;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 3px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #262f3e;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3359375rem;
  padding-bottom: 0.3359375rem;
  margin-right: 1rem;
  font-size: 1.09375rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.09375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 3px;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #ced4da;
  border-radius: 3px;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid #ced4da;
}
.card-header:first-child {
  border-radius: calc(3px - 1px) calc(3px - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid #ced4da;
}
.card-footer:last-child {
  border-radius: 0 0 calc(3px - 1px) calc(3px - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(3px - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(3px - 1px);
  border-top-right-radius: calc(3px - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(3px - 1px);
  border-bottom-left-radius: calc(3px - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}
.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}
.accordion > .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card .card-header {
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 3px;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 3px;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #262f3e;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #090b0f;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 47, 62, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.page-item:last-child .page-link {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #262f3e;
  border-color: #262f3e;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.09375rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #262f3e;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #13171e;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 47, 62, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #ff0000;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #cc0000;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #23BF08;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1a8e06;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(35, 191, 8, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #1b84e7;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #146abb;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(27, 132, 231, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #F49917;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #ce7d0a;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(244, 153, 23, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 3px;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3.8125rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #141820;
  background-color: #d4d5d8;
  border-color: #c2c5c9;
}
.alert-primary hr {
  border-top-color: #b4b8bd;
}
.alert-primary .alert-link {
  color: #000001;
}

.alert-secondary {
  color: #850000;
  background-color: #ffcccc;
  border-color: #ffb8b8;
}
.alert-secondary hr {
  border-top-color: #ff9f9f;
}
.alert-secondary .alert-link {
  color: #520000;
}

.alert-success {
  color: #126304;
  background-color: #d3f2ce;
  border-color: #c1edba;
}
.alert-success hr {
  border-top-color: #afe8a6;
}
.alert-success .alert-link {
  color: #093202;
}

.alert-info {
  color: #0e4578;
  background-color: #d1e6fa;
  border-color: #bfddf8;
}
.alert-info hr {
  border-top-color: #a8d1f5;
}
.alert-info .alert-link {
  color: #092b4a;
}

.alert-warning {
  color: #7f500c;
  background-color: #fdebd1;
  border-color: #fce2be;
}
.alert-warning hr {
  border-top-color: #fbd7a6;
}
.alert-warning .alert-link {
  color: #503308;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.65625rem;
  background-color: #e9ecef;
  border-radius: 3px;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #262f3e;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #868ba1;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #262f3e;
  border-color: #262f3e;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal .list-group-item {
  margin-right: -1px;
  margin-bottom: 0;
}
.list-group-horizontal .list-group-item:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 0;
}
.list-group-horizontal .list-group-item:last-child {
  margin-right: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 0;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 0;
  }
}
.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px;
}
.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.list-group-item-primary {
  color: #141820;
  background-color: #c2c5c9;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #141820;
  background-color: #b4b8bd;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #141820;
  border-color: #141820;
}

.list-group-item-secondary {
  color: #850000;
  background-color: #ffb8b8;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #850000;
  background-color: #ff9f9f;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #850000;
  border-color: #850000;
}

.list-group-item-success {
  color: #126304;
  background-color: #c1edba;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #126304;
  background-color: #afe8a6;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #126304;
  border-color: #126304;
}

.list-group-item-info {
  color: #0e4578;
  background-color: #bfddf8;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0e4578;
  background-color: #a8d1f5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0e4578;
  border-color: #0e4578;
}

.list-group-item-warning {
  color: #7f500c;
  background-color: #fce2be;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #7f500c;
  background-color: #fbd7a6;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #7f500c;
  border-color: #7f500c;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.765625rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 3px;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.765625rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc((0.5rem + 1px) * -1);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #868ba1;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #262f3e !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #13171e !important;
}

.bg-secondary {
  background-color: #ff0000 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #cc0000 !important;
}

.bg-success {
  background-color: #23BF08 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1a8e06 !important;
}

.bg-info {
  background-color: #1b84e7 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #146abb !important;
}

.bg-warning {
  background-color: #F49917 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ce7d0a !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #262f3e !important;
}

.border-secondary {
  border-color: #ff0000 !important;
}

.border-success {
  border-color: #23BF08 !important;
}

.border-info {
  border-color: #1b84e7 !important;
}

.border-warning {
  border-color: #F49917 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 3px !important;
}

.rounded-top {
  border-top-left-radius: 3px !important;
  border-top-right-radius: 3px !important;
}

.rounded-right {
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}

.rounded-bottom {
  border-bottom-right-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.rounded-left {
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #262f3e !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #090b0f !important;
}

.text-secondary {
  color: #ff0000 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #b30000 !important;
}

.text-success {
  color: #23BF08 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #167605 !important;
}

.text-info {
  color: #1b84e7 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #115da4 !important;
}

.text-warning {
  color: #F49917 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #b66f09 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #868ba1 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/instagram-feed/css/sb-instagram.min.css?ver=1.12 ; media=all */
@media all {
  #sb_instagram {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  #sb_instagram {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
  }

  #sb_instagram:after {
    content: "";
    display: table;
    clear: both;
  }

  #sb_instagram #sbi_images {
    width: 100%;
    float: left;
    line-height: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  #sb_instagram .sb_instagram_header {
    float: left;
    clear: both;
    margin: 0 0 15px;
    padding: 0;
    line-height: 1.2;
    width: 100%;
  }

  #sb_instagram #sbi_load,
#sb_instagram .sb_instagram_error {
    width: 100%;
    text-align: center;
    line-height: 1.5;
  }

  #sb_instagram #sbi_load {
    float: left;
    clear: both;
    opacity: 1;
    transition: all 0.5s ease-in;
  }

  #sb_instagram .sbi_hidden {
    opacity: 0 !important;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/sportspress/assets/css/sportspress.css?ver=2.6.14 ; media=all */
@media all {
  .sp-header {
    position: relative;
    overflow: visible;
    z-index: 10000;
  }

  .sp-template {
    margin-bottom: 1.5em;
  }

  .sp-scrollable-table-wrapper {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
  }

  .sp-data-table {
    width: 100%;
    table-layout: auto;
    border-spacing: 0;
  }

  .sp-paginated-table {
    margin-bottom: 0;
  }

  .sp-data-table thead th {
    white-space: nowrap;
  }

  .sp-data-table thead .sorting {
    cursor: pointer;
  }

  .sp-data-table tbody a {
    text-decoration: none;
  }

  .sp-data-table .sorting:after {
    font-family: dashicons;
  }

  .sp-data-table .sorting:after {
    content: "\f156";
    color: transparent;
    position: absolute;
  }

  .sp-data-table .data-name {
    text-align: left;
  }

  .sp-data-table .data-rank {
    width: 1px;
  }

  .sp-player-list td {
    line-height: 2em;
  }

  .sp-event-blocks thead {
    display: none;
  }

  .sp-event-blocks .sp-event-date,
.sp-event-blocks .sp-event-results,
.sp-event-blocks .sp-event-title {
    text-align: center;
    display: block;
    margin: 10px 0;
    clear: none;
  }

  .sp-event-blocks .team-logo {
    margin: 10px;
    width: 20%;
    height: 4em;
    max-width: 6em;
    text-align: center;
  }

  .sp-event-blocks .logo-odd {
    float: left;
  }

  .sp-event-blocks .logo-even {
    float: right;
  }

  .sp-event-blocks .team-logo img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }

  .sp-template-countdown .event-name {
    clear: both;
    overflow: hidden;
  }

  .sp-template-countdown .sp-countdown {
    clear: both;
  }

  .sp-view-all-link {
    display: block;
    margin-top: 0.5em;
  }
}
@media screen and (min-width: 801px) {
  .sp-widget-align-none {
    clear: both;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/sportspress/assets/css/sportspress-style.css?ver=2.3 ; media=all */
@media all {
  .sp-template,
.sp-data-table,
.sp-table-caption {
    font-family: "Roboto", "Helvetica", sans-serif;
  }

  .sp-template {
    clear: both;
    overflow: hidden;
  }

  .sp-template h3,
.sp-template h4,
.sp-template h5,
.sp-data-table h4,
.sp-data-table h5 {
    font-family: "Roboto", "Helvetica", sans-serif !important;
  }

  .sp-template {
    margin-bottom: 20px !important;
  }

  .sp-template *,
.sp-data-table *,
.sp-table-caption {
    box-sizing: border-box;
    text-transform: none;
    letter-spacing: normal;
    color: #333;
  }

  .sp-table-caption {
    margin: 0 !important;
    padding: 15px !important;
    background: #fff !important;
    border: 1px solid #e5e5e5 !important;
    border-bottom: none !important;
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    display: table !important;
    width: 100% !important;
  }

  .sp-template a:hover,
.sp-data-table a:hover {
    text-decoration: underline !important;
  }

  .sp-view-all-link {
    padding: 10px 15px !important;
    margin: 0 !important;
    background: #fff !important;
    border: 1px solid #e5e5e5 !important;
    border-top: none !important;
    overflow: hidden !important;
    display: table !important;
    width: 100% !important;
    text-align: right !important;
  }

  .sp-view-all-link a {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    text-decoration: none !important;
    color: rgba(51, 51, 51, 0.5) !important;
  }

  .sp-view-all-link a:hover {
    color: #333 !important;
    text-decoration: none !important;
  }

  .sp-template img {
    display: inline-block !important;
    box-shadow: none !important;
  }

  .sp-data-table {
    margin: 0 !important;
    background: #fff !important;
    border-collapse: collapse !important;
    border: 1px solid #e5e5e5 !important;
    width: 100% !important;
  }

  .sp-data-table tr {
    border: none !important;
  }

  .sp-template-countdown .sp-event-name,
.sp-template-countdown .sp-event-venue,
.sp-template-countdown .sp-event-date {
    margin: 0 !important;
    padding: 5px 10px !important;
    text-align: center !important;
    line-height: 1.5 !important;
    display: table !important;
    width: 100% !important;
    background: #fff !important;
    border: 1px solid #e5e5e5 !important;
    border-bottom: none !important;
  }

  .sp-template-countdown .sp-event-name {
    font-size: 13px !important;
    font-weight: 400 !important;
    color: #333 !important;
  }

  .sp-template-countdown .sp-event-venue,
.sp-template-countdown .sp-event-date {
    font-size: 12px !important;
    font-weight: 500 !important;
    background: #fff !important;
    color: #999 !important;
  }

  .sp-countdown time {
    display: table !important;
    background: #fff !important;
    border: 1px solid #e5e5e5 !important;
    border-collapse: collapse !important;
    width: 100% !important;
  }

  .sp-countdown span {
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    display: table-cell !important;
    border: 1px solid #e5e5e5 !important;
    padding: 10px 5px !important;
    text-align: center !important;
  }

  .sp-countdown span small {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    font-weight: 500 !important;
    color: rgba(51, 51, 51, 0.5) !important;
    display: block !important;
  }

  .sp-template-event-blocks td {
    padding: 0 !important;
  }

  .sp-template-event-blocks .sp-event-date {
    color: rgba(51, 51, 51, 0.5) !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    margin: 10px !important;
  }

  .sp-template-event-blocks .sp-event-date a,
.sp-template-event-blocks .sp-event-date a:hover {
    color: inherit !important;
  }

  .sp-template-event-blocks .sp-event-results {
    font-size: 20px !important;
    font-weight: 400 !important;
    padding: 0 !important;
    margin: 0 !important;
    color: #333 !important;
  }

  .sp-template-event-blocks .sp-event-results a {
    color: inherit !important;
  }

  .sp-template-event-blocks .sp-event-results a:hover {
    color: inherit !important;
    text-decoration: none !important;
  }

  .sp-template-event-blocks .sp-event-title {
    display: block !important;
    padding: 0 !important;
    margin: 10px !important;
    font-size: 13px !important;
    font-weight: 400 !important;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/sportspress/assets/css/sportspress-style-ltr.css?ver=2.3 ; media=all */
@media all {
  .sp-data-table .data-name {
    text-align: left !important;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/trx_addons/css/font-icons/css/trx_addons_icons.css ; media=all */
@media all {
  [class*=" trx_addons_icon-"]:before {
    font-family: "trx_addons_icons";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: 0.2em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .trx_addons_icon-up:before {
    content: "\e85a";
  }

  .trx_addons_icon-search:before {
    content: "\e898";
  }

  .trx_addons_icon-menu:before {
    content: "\e8bc";
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/trx_addons/js/swiper/swiper.min.css ; media=all */
@media all {
  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }

  .swiper-wrapper {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .swiper-slide {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
  }

  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 100%;
    background: #000;
    opacity: 0.2;
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
    background: #007aff;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/trx_addons/js/magnific/magnific-popup.min.css ; media=all */
@media all {
  button::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/trx_addons/css/__styles.css ; media=all */
@media all {
  .trx_addons_scroll_to_top {
    display: block;
    background: #fff;
    cursor: pointer;
    opacity: 0;
    position: fixed;
    z-index: 100001;
    bottom: -4em;
    right: 2em;
    width: 3em;
    height: 3em;
    line-height: 3em;
    text-align: center;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .trx_addons_scroll_to_top:before {
    margin: 0 !important;
  }

  [class*=" trx_addons_icon-"]:before {
    margin: 0;
    width: auto;
    font-size: inherit;
    line-height: inherit !important;
    font-weight: inherit;
  }

  .post_meta_item {
    margin-left: 0.5em;
    white-space: nowrap;
  }

  .post_meta_item:before {
    vertical-align: top;
    line-height: inherit;
    margin: 0 0.3em 0 0;
  }

  .trx_addons_columns_wrap {
    margin-left: 0px;
    margin-right: -30px;
  }

  .trx_addons_columns_wrap > [class*=trx_addons_column-] {
    display: inline-block;
    vertical-align: top;
    position: relative;
    min-height: 1px;
    padding-left: 0px;
    padding-right: 30px;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .trx_addons_columns_wrap.columns_padding_bottom > [class*=trx_addons_column-] {
    padding-bottom: 30px;
  }

  .trx_addons_column-1_2 {
    width: 50%;
  }

  .trx_addons_columns_wrap:after {
    content: " ";
    clear: both;
    width: 100%;
    height: 0;
    display: block;
  }

  .sc_align_center {
    text-align: center;
  }

  .sc_item_title,
.sc_item_button {
    margin: 0;
  }

  .sc_item_title {
    line-height: 1.2em;
  }

  .sc_item_subtitle {
    font-size: 14px;
    text-transform: uppercase;
    line-height: 1.2em;
    letter-spacing: 0;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .sc_item_button {
    line-height: 0;
  }

  .sc_item_title.sc_item_title_style_accent {
    position: relative;
  }

  .sc_item_title + .sc_item_button {
    margin-top: 2em;
  }

  .sc_item_featured {
    position: relative;
  }

  .elementor-container:not(.elementor-column-gap-no) > .elementor-row > .elementor-column > .elementor-element-populated {
    padding-top: 0;
    padding-bottom: 0;
  }

  .elementor-section {
    position: relative;
  }

  .elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute):not(.sc_layouts_item) {
    margin-bottom: 0;
  }

  .elementor-widget-text-editor:last-child,
.elementor-widget-text-editor p:last-child {
    margin-bottom: 0;
  }

  .sc_layouts_row {
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .sc_layouts_row .sc_layouts_item {
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
  }

  .sc_layouts_item {
    max-width: 100%;
  }

  .sc_layouts_column .sc_layouts_item {
    display: inline-block;
    vertical-align: middle;
    margin-right: 1.5em;
    width: auto !important;
  }

  .sc_layouts_column .sc_layouts_item:last-child {
    margin-right: 0 !important;
  }

  .sc_layouts_column_align_right .sc_layouts_item {
    margin-left: 1.5em;
    margin-right: 0;
  }

  .sc_layouts_column_align_right .sc_layouts_item:first-child {
    margin-left: 0 !important;
  }

  .sc_layouts_column_align_left {
    text-align: left;
  }

  .sc_layouts_column_align_right,
.sc_layouts_column.sc_layouts_column_align_right .widget,
.sc_layouts_column.sc_layouts_column_align_right .widget_title {
    text-align: right;
  }

  .sc_layouts_column_align_left > .elementor-column-wrap > .elementor-widget-wrap {
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .sc_layouts_column_align_right > .elementor-column-wrap > .elementor-widget-wrap {
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .elementor-section-content-middle .elementor-widget-wrap {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .elementor-section-content-bottom .elementor-widget-wrap {
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .sc_layouts_iconed_text {
    white-space: nowrap;
  }

  .sc_layouts_item_link {
    display: block;
  }

  .sc_layouts_item_icon {
    font-size: 2.25em;
    line-height: 1.1em;
    display: inline-block;
    vertical-align: middle;
    -webkit-transition: color 0.3s ease;
    -ms-transition: color 0.3s ease;
    transition: color 0.3s ease;
  }

  .sc_layouts_row_type_normal .sc_layouts_item {
    letter-spacing: 0;
  }

  .sc_layouts_row_type_normal {
    padding: 2em 0;
  }

  .sc_layouts_row_fixed_placeholder {
    display: none;
  }

  .sc_blogger {
    position: relative;
    padding-top: 0.0001px;
  }

  .sc_blogger_columns_wrap {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .sc_blogger_slider .sc_blogger_item,
.sc_blogger_columns_wrap .sc_blogger_item {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 100%;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .sc_blogger_slider .sc_blogger_item {
    margin-bottom: 0 !important;
  }

  .sc_blogger .sc_blogger_item_title {
    margin: 0;
  }

  .sc_blogger .sc_blogger_item_featured {
    margin-bottom: 0;
  }

  .sc_blogger .sc_blogger_item_featured[class*=hover_] {
    display: block;
  }

  .sc_blogger .sc_blogger_item_featured img {
    width: 100%;
    height: auto;
  }

  .sc_blogger .sc_blogger_item_title + .sc_blogger_item_excerpt {
    margin-top: 1em;
  }

  .sc_blogger_item_default.sc_blogger_item_image_position_top .sc_blogger_item_featured + .sc_blogger_item_content {
    margin-top: 1em;
  }

  .sc_button_wrap:not([class*=sc_align_]) {
    display: inline-block;
  }

  .sc_button {
    display: inline-block;
    text-transform: uppercase;
    white-space: nowrap;
    padding: 1.5em 3em;
    background-position: center center;
    background-repeat: repeat;
    overflow: hidden;
    background-color: #efa758;
    color: #fff;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .sc_button:hover {
    background-color: #007fc5;
  }

  .sc_button_text {
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    text-decoration: inherit;
  }

  .sc_inner_width_none > .elementor-column-wrap {
    width: 100%;
  }

  .sc_title {
    position: relative;
  }

  .widget_banner .image_wrap {
    overflow: hidden;
    display: block;
    margin-bottom: 1em;
  }

  .widget_banner .image_wrap img {
    width: 100%;
    height: auto;
  }

  .sc_recent_news .post_item {
    background-color: transparent;
    text-align: left;
    overflow: hidden;
    position: relative;
  }

  .sc_recent_news .post_item .post_featured {
    margin-bottom: 0;
  }

  .sc_recent_news .post_item .post_featured img {
    width: 100%;
  }

  .sc_recent_news .post_item .post_header {
    text-align: left;
    margin-bottom: 0;
  }

  .sc_recent_news .post_item .post_title {
    margin: 0;
    letter-spacing: 0;
    line-height: 1.1em;
    font-weight: 400;
  }

  .sc_recent_news .post_item .post_meta {
    margin: 0.2em 0 1em !important;
  }

  .sc_recent_news .post_item .post_date {
    display: inline-block;
    font-size: 13px;
    line-height: 1.5em;
  }

  .sc_recent_news .post_item .post_date:before {
    content: "|";
    display: inline-block;
    margin: 0 0.6em;
    font-size: 0.8462em;
    position: relative;
    top: -1px;
  }

  .sc_recent_news .post_item .post_content {
    margin-top: 0.8em;
  }

  .sc_recent_news_style_news-excerpt {
    overflow: hidden;
  }

  .sc_recent_news_style_news-excerpt .post_item + .post_item {
    padding-top: 2em;
    margin-top: 2em;
    border-top: 1px solid #eee;
  }

  .sc_recent_news_style_news-excerpt .post_featured {
    float: left;
    width: 40%;
    margin-bottom: 0;
  }

  .sc_recent_news_style_news-excerpt .post_body {
    overflow: hidden;
    float: left;
    width: 60%;
    padding-left: 2em;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .slider_container {
    display: none;
    position: relative;
    overflow: hidden;
  }

  .slider_outer {
    position: relative;
  }

  .slider_container .slider-slide {
    position: relative;
    max-height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .slider_container:not(.slider_height_fixed) .slider-slide {
    height: auto;
  }

  .slider_container:not(.slider_multi):not(.slider_type_images),
.slider_container:not(.slider_multi):not(.slider_type_images) .slider-slide {
    min-height: 180px;
  }

  .slider_container.slider_type_images .slider-slide {
    text-align: center;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .slider_container.slider_type_images .slider-slide > img {
    max-width: 100%;
    height: auto;
  }

  .sc_slider_controls .slider_controls_wrap {
    position: relative;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .sc_slider_controls.sc_align_center .slider_controls_wrap {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .sc_slider_controls .slider_controls_wrap .slider_pagination_wrap {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    text-align: center;
    height: 2.8em;
  }

  .sc_slider_controls.slider_pagination_style_bullets .slider_pagination_bullet {
    display: inline-block;
    vertical-align: top;
    border: 2px solid #f0f0f0;
    background-color: transparent;
    width: 10px;
    height: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;
    cursor: pointer;
  }

  .sc_slider_controls.slider_pagination_style_bullets .slider_pagination_bullet + .slider_pagination_bullet {
    margin-left: 4px;
  }

  .sc_layouts_logo img {
    max-height: 6em;
    width: auto;
  }

  .sc_layouts_menu_mobile_button {
    display: none !important;
  }

  .sc_layouts_menu_mobile_button .sc_layouts_item_icon,
.sc_layouts_menu_mobile_button_burger .sc_layouts_item_icon {
    font-size: 2em;
  }

  .sc_layouts_menu_mobile_button .sc_layouts_item_icon:before,
.sc_layouts_menu_mobile_button_burger .sc_layouts_item_icon:before {
    line-height: 1.2em;
  }

  .sc_layouts_menu {
    position: relative;
    z-index: 1000;
    overflow: hidden;
    visibility: hidden;
    max-height: 3em;
  }

  .sc_layouts_menu.inited {
    overflow: visible;
    visibility: visible;
    max-height: none;
  }

  .sc_layouts_menu_nav,
.sc_layouts_menu_nav ul {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style-type: none !important;
  }

  .sc_layouts_menu_nav:after,
.sc_layouts_menu_nav ul:after {
    content: " ";
    clear: both;
    width: 100%;
    height: 0;
    display: block;
  }

  .sc_layouts_menu_nav li {
    margin: 0;
    position: relative;
  }

  .sc_layouts_menu_nav li > a {
    display: block;
    position: relative;
  }

  .sc_layouts_menu_nav {
    text-align: left;
    vertical-align: middle;
    padding-left: 0 !important;
  }

  .sc_layouts_menu_nav > li {
    position: relative;
    float: left;
    z-index: 20;
  }

  .sc_layouts_menu_nav > li + li {
    z-index: 19;
  }

  .sc_layouts_menu_nav > li + li + li {
    z-index: 18;
  }

  .sc_layouts_menu_nav > li + li + li + li {
    z-index: 17;
  }

  .sc_layouts_menu_nav > li > a {
    padding: 1em;
    position: relative;
    color: #333;
    -webkit-transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    -ms-transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  }

  .sc_layouts_menu_nav > li > a:hover,
.sc_layouts_menu_nav > li.current-menu-parent > a,
.sc_layouts_menu_nav > li.current-menu-ancestor > a {
    color: #efa758;
  }

  .sc_layouts_menu_nav > li.current-menu-parent > a,
.sc_layouts_menu_nav > li.current-menu-ancestor > a {
    font-weight: 600 !important;
  }

  .sc_layouts_menu_nav > li.menu-item-has-children > a {
    padding-right: 2em;
  }

  .sc_layouts_menu_nav > li.menu-item-has-children > a:after {
    content: "\e882";
    font-family: "trx_addons_icons";
    font-weight: normal;
    display: inline-block;
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 1em;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: color 0.3s ease;
    -ms-transition: color 0.3s ease;
    transition: color 0.3s ease;
  }

  .sc_layouts_menu_nav li li.menu-item-has-children > a:after {
    content: "\e884";
    font-family: "trx_addons_icons";
    font-weight: normal;
    position: absolute;
    z-index: 1;
    top: 0.65em;
    right: 0.75em;
    -webkit-transition: color 0.3s ease;
    -ms-transition: color 0.3s ease;
    transition: color 0.3s ease;
  }

  .sc_layouts_menu_nav > li ul {
    position: absolute;
    display: none;
    z-index: 10000;
    width: 13em;
    padding: 1.4em 0;
    text-align: left;
    background-color: #f0f0f0;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.2);
  }

  .sc_layouts_menu_nav > li li > a {
    padding: 0.6em 1.5em;
    color: #333;
  }

  .sc_layouts_menu_nav > li li > a:hover,
.sc_layouts_menu_nav > li li.current-menu-item > a {
    color: #efa758;
  }

  .sc_layouts_menu_nav > li li.current-menu-item:before {
    color: #efa758;
  }

  .sc_layouts_menu_nav > li > ul {
    top: 4.2em;
    left: 0;
  }

  .sc_layouts_menu_nav > li > ul ul {
    left: 13em;
    top: -1.4em;
    margin: 0 0 0 2px;
    background-color: #f0f0f0;
  }

  .sc_layouts_menu_mobile_button_burger {
    position: relative;
  }

  .search_wrap,
.search_wrap .search_form {
    position: relative;
  }

  .search_wrap .search_field {
    width: 6em;
    padding: 0 0 0 1.25em !important;
    vertical-align: middle;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .search_wrap .search_submit {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    background-color: transparent;
    padding: 0;
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
  }

  .search_wrap .search_submit:before {
    margin: 0;
  }

  .sc_layouts_row_type_normal .search_wrap .search_field {
    font-size: 11px;
    text-transform: uppercase;
    padding: 0 1.5em 0 3em !important;
    width: 15em;
    height: 4.7727em;
    line-height: 4.7727em;
    -webkit-border-radius: 3em;
    -ms-border-radius: 3em;
    border-radius: 3em;
  }

  .sc_layouts_row_type_normal .search_wrap .search_submit {
    display: block;
    font-size: 11px;
    text-align: center;
    width: 1em;
    height: 4.7727em;
    line-height: 4.7727em;
    position: absolute;
    top: 0;
    right: auto;
    bottom: auto;
    left: 1em;
  }

  .sc_layouts_row_type_normal .search_wrap .search_submit:before {
    font-size: 13px;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/trx_addons/css/trx_addons.animation.css ; media=all */
@media all {
  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: visible;
  }

  .animated.fast {
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
  }

  .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }

  .fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
  }

  .fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
  }

  .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/elementor/assets/lib/animations/animations.min.css?ver=2.5.15 ; media=all */
@media all {
  .fadeIn {
    animation-name: fadeIn;
  }

  .fadeInLeft {
    animation-name: fadeInLeft;
  }

  .fadeInRight {
    animation-name: fadeInRight;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/elementor/assets/css/frontend.min.css?ver=2.5.15 ; media=all */
@media all {
  .elementor-screen-only {
    position: absolute;
    top: -10000em;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .elementor-clearfix:after {
    content: "";
    display: block;
    clear: both;
    width: 0;
    height: 0;
  }

  .elementor {
    -webkit-hyphens: manual;
    -ms-hyphens: manual;
    hyphens: manual;
  }

  .elementor *,
.elementor :after,
.elementor :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .elementor a {
    -webkit-box-shadow: none;
    box-shadow: none;
    text-decoration: none;
  }

  .elementor img {
    height: auto;
    max-width: 100%;
    border: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .elementor-invisible {
    visibility: hidden;
  }

  .elementor-section {
    position: relative;
  }

  .elementor-section .elementor-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative;
  }

  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 1140px;
  }

  .elementor-section.elementor-section-stretched {
    position: relative;
    width: 100%;
  }

  .elementor-section-content-middle > .elementor-container > .elementor-row > .elementor-column > .elementor-column-wrap {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .elementor-section-content-bottom > .elementor-container > .elementor-row > .elementor-column > .elementor-column-wrap {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .elementor-row {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}
@media (max-width: 1024px) {
  .elementor-row {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
@media all {
  .elementor-column-wrap {
    width: 100%;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}
@media all {
  .elementor-widget-wrap {
    position: relative;
    width: 100%;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
}
@media all {
  .elementor:not(.elementor-bc-flex-widget) .elementor-widget-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}
@media all {
  .elementor-widget-wrap > .elementor-element {
    width: 100%;
  }
}
@media all {
  .elementor-widget {
    position: relative;
  }
}
@media all {
  .elementor-widget:not(:last-child) {
    margin-bottom: 20px;
  }
}
@media all {
  .elementor-column {
    position: relative;
    min-height: 1px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}
@media all {
  .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
    padding: 15px;
  }
}
@media all {
  .elementor-inner-section .elementor-column-gap-no .elementor-element-populated {
    padding: 0;
  }
}
@media (min-width: 768px) {
  .elementor-column.elementor-col-33 {
    width: 33.333%;
  }

  .elementor-column.elementor-col-50 {
    width: 50%;
  }

  .elementor-column.elementor-col-66 {
    width: 66.666%;
  }

  .elementor-column.elementor-col-100 {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .elementor-column {
    width: 100%;
  }
}
@media (min-width: 1025px) {
  #elementor-device-mode:after {
    content: "desktop";
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  #elementor-device-mode:after {
    content: "tablet";
  }
}
@media (max-width: 767px) {
  #elementor-device-mode:after {
    content: "mobile";
  }
}
@media all {
  .elementor-element .elementor-widget-container {
    -webkit-transition: background 0.3s, border 0.3s, -webkit-border-radius 0.3s, -webkit-box-shadow 0.3s;
    transition: background 0.3s, border 0.3s, -webkit-border-radius 0.3s, -webkit-box-shadow 0.3s;
    -o-transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s, -webkit-border-radius 0.3s, -webkit-box-shadow 0.3s;
  }
}
@media all {
  .elementor-widget-divider .elementor-divider {
    line-height: 0;
    font-size: 0;
  }
}
@media all {
  .elementor-widget-divider .elementor-divider-separator {
    display: inline-block;
  }
}
@media all {
  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }
}
@media all {
  .swiper-wrapper {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
@media all {
  .swiper-slide {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
  }
}
@media all {
  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    display: inline-block;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #000;
    opacity: 0.2;
  }
}
@media all {
  .swiper-pagination-bullet-active {
    opacity: 1;
  }
}
@media all {
  .animated {
    -webkit-animation-duration: 1.25s;
    animation-duration: 1.25s;
  }
}
/*! CSS Used from: Embedded */
.elementor-section.elementor-section-boxed > .elementor-container {
  max-width: 1308px;
}

.elementor-widget:not(:last-child) {
  margin-bottom: 0px;
}

.elementor-598 .elementor-element.elementor-element-99e1259:not(.elementor-motion-effects-element-type-background) {
  background-image: url(../../assets/images/header1.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.elementor-598 .elementor-element.elementor-element-99e1259 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-598 .elementor-element.elementor-element-390f28f {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-598 .elementor-element.elementor-element-49158eb .elementor-spacer-inner {
  height: 450px;
}

.elementor-598 .elementor-element.elementor-element-41a5bd6 .elementor-spacer-inner {
  height: 300px;
}

.elementor-598 .elementor-element.elementor-element-223582c {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-598 .elementor-element.elementor-element-d2d2405 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-598 .elementor-element.elementor-element-62e51d1 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-598 .elementor-element.elementor-element-d52953c {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-598 .elementor-element.elementor-element-6b56955 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-598 .elementor-element.elementor-element-92a0b0a .elementor-spacer-inner {
  height: 30px;
}

.elementor-598 .elementor-element.elementor-element-dbdd0e4 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-598 .elementor-element.elementor-element-c793c8d > .elementor-widget-container {
  padding: 30px 30px 35px 30px;
  background-color: #ffffff;
  border-radius: 0px 0px 3px 3px;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.06);
}

.elementor-598 .elementor-element.elementor-element-2b5b5cf .elementor-spacer-inner {
  height: 30px;
}

.elementor-598 .elementor-element.elementor-element-b19b114:not(.elementor-motion-effects-element-type-background) {
  background-color: #ffffff;
}

.elementor-598 .elementor-element.elementor-element-b19b114 {
  border-radius: 3px 3px 3px 3px;
}

.elementor-598 .elementor-element.elementor-element-b19b114 {
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.06);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-598 .elementor-element.elementor-element-080ea4d {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-598 .elementor-element.elementor-element-ee6c397 > .elementor-widget-container {
  margin: -2px 0px 0px 0px;
  padding: 0px 0px 25px 0px;
  background-color: #ffffff;
}

.elementor-598 .elementor-element.elementor-element-5004f3a .elementor-spacer-inner {
  height: 30px;
}

.elementor-598 .elementor-element.elementor-element-b71eb6c > .elementor-widget-container {
  padding: 30px 30px 16px 30px;
  background-color: #ffffff;
  border-radius: 0px 0px 3px 3px;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.06);
}

.elementor-598 .elementor-element.elementor-element-6fbc401 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-598 .elementor-element.elementor-element-4000484 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-598 .elementor-element.elementor-element-bce779f .elementor-spacer-inner {
  height: 10px;
}

@media (min-width: 768px) {
  .elementor-598 .elementor-element.elementor-element-390f28f {
    width: 75%;
  }

  .elementor-598 .elementor-element.elementor-element-223582c {
    width: 48.089%;
  }

  .elementor-598 .elementor-element.elementor-element-dbdd0e4 {
    width: 65%;
  }

  .elementor-598 .elementor-element.elementor-element-6fbc401 {
    width: 34.997%;
  }
}
@media (max-width: 1024px) {
  .elementor-598 .elementor-element.elementor-element-99e1259 {
    padding: 0px 0px 40px 0px;
  }

  .elementor-598 .elementor-element.elementor-element-41a5bd6 .elementor-spacer-inner {
    height: 100px;
  }

  .elementor-598 .elementor-element.elementor-element-c793c8d > .elementor-widget-container {
    padding: 20px 20px 25px 20px;
  }

  .elementor-598 .elementor-element.elementor-element-b71eb6c > .elementor-widget-container {
    padding: 20px 20px 10px 20px;
  }
}
@media (max-width: 767px) {
  .elementor-598 .elementor-element.elementor-element-99e1259 {
    padding: 0px 0px 20px 0px;
  }

  .elementor-598 .elementor-element.elementor-element-41a5bd6 .elementor-spacer-inner {
    height: 80px;
  }

  .elementor-598 .elementor-element.elementor-element-fc40494 > .elementor-widget-container {
    margin: 0px 0px 20px 0px;
  }

  .elementor-598 .elementor-element.elementor-element-92a0b0a .elementor-spacer-inner {
    height: 20px;
  }

  .elementor-598 .elementor-element.elementor-element-2b5b5cf .elementor-spacer-inner {
    height: 20px;
  }

  .elementor-598 .elementor-element.elementor-element-ee6c397 > .elementor-widget-container {
    padding: 0px 0px 12px 0px;
  }

  .elementor-598 .elementor-element.elementor-element-5004f3a .elementor-spacer-inner {
    height: 20px;
  }

  .elementor-598 .elementor-element.elementor-element-6fbc401 > .elementor-element-populated {
    margin: 20px 0px 0px 0px;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/font-icons/css/fontello.css ; media=all */
@media all {
  [class*=" icon-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: 0.2em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-cancel:before {
    content: "\e8ac";
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/style.css ; media=all */
@media all {
  body,
div,
span,
h1,
h2,
h3,
h4,
h5,
p,
pre,
a,
small,
ul,
li,
form,
label,
input,
textarea,
button,
table,
tbody,
thead,
tr,
th,
td,
article,
aside,
section {
    font-family: inherit;
    font-size: 100%;
    line-height: inherit;
    font-weight: inherit;
    font-style: inherit;
    border: 0;
    outline: 0;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
  }

  body {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  article,
aside,
footer,
header,
nav,
section {
    display: block;
  }

  li > ul {
    margin-bottom: 0;
  }

  ul {
    padding-left: 1.5em;
  }

  a {
    text-decoration: none;
    background: transparent;
  }

  a,
a:hover,
a:focus,
a:active {
    outline: 0;
  }

  a img {
    border: none;
  }

  a,
button,
input[type=submit] {
    -webkit-transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    -ms-transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  }

  h3 > a,
h4 > a,
h5 > a {
    display: block;
  }

  :active,
:focus {
    outline: 0;
  }

  table {
    border-collapse: collapse;
  }

  table th + th,
table td + td {
    border-left: 2px solid #ddd;
  }

  table td,
table th {
    padding: 1.95em 1.1em;
  }

  table th {
    text-transform: none;
    text-align: center;
  }

  pre {
    font-family: "Courier New", Courier, monospace;
    font-size: 1em;
    letter-spacing: 0;
  }

  pre {
    overflow: auto;
    max-width: 100%;
  }

  small {
    font-size: 80%;
  }

  img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
  }

  [class*=" icon-"]:before {
    font-size: inherit;
    line-height: inherit !important;
    font-weight: inherit;
    font-style: inherit;
    display: inline-block;
    vertical-align: top;
    width: auto;
    margin: 0;
  }

  form {
    margin-bottom: 0;
  }

  button,
input,
textarea {
    font-family: inherit;
    font-size: 1em;
    margin: 0;
    vertical-align: baseline;
  }

  button {
    overflow: visible;
  }

  input[placeholder]::-webkit-input-placeholder,
textarea[placeholder]::-webkit-input-placeholder {
    text-overflow: ellipsis;
  }

  input[placeholder]::-moz-placeholder,
textarea[placeholder]::-moz-placeholder {
    text-overflow: ellipsis;
  }

  input[placeholder]:-ms-input-placeholder,
textarea[placeholder]:-ms-input-placeholder {
    text-overflow: ellipsis;
  }

  input[placeholder]::placeholder,
textarea[placeholder]::placeholder {
    text-overflow: ellipsis;
  }

  input[placeholder],
textarea[placeholder] {
    text-overflow: ellipsis;
  }

  textarea,
input[type=text],
input[type=email],
input[type=checkbox] {
    -webkit-transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    -ms-transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
  }

  input[type=checkbox] {
    padding: 0;
  }

  button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  html input[disabled] {
    cursor: default !important;
  }

  button:focus,
input[type=text]:focus,
input[type=email]:focus,
textarea:focus {
    outline: none;
  }

  input[type=text],
input[type=email],
textarea {
    -webkit-appearance: none;
    outline: none;
    resize: none;
  }

  form button,
input[type=submit],
.mc4wp-form .mc4wp-form-fields input[type=submit],
.sc_button,
.sp-view-all-link > a {
    -webkit-appearance: none;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    text-transform: none;
    white-space: nowrap;
    padding: 1.38em 3.5em;
    letter-spacing: 0;
    height: auto;
    border-width: 0 !important;
    -webkit-border-radius: 30px;
    -ms-border-radius: 30px;
    border-radius: 30px;
  }

  input[type=text],
input[type=email],
textarea,
form.mc4wp-form .mc4wp-form-fields input[type=email] {
    padding: 0.94em 1.5em;
    border: 2px solid #ddd;
    -webkit-border-radius: 28px;
    -ms-border-radius: 28px;
    border-radius: 28px;
  }

  textarea {
    overflow: auto;
    vertical-align: top;
    min-height: 10em;
  }

  input[type=checkbox] {
    display: none;
  }

  input[type=checkbox] + label {
    position: relative;
    padding-left: 1.4em;
    line-height: 1.5em;
    display: inline-block;
    vertical-align: top;
  }

  input[type=checkbox] + label:before {
    content: " ";
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
    font-size: 1em;
    display: block;
    text-align: center;
    border: 2px solid #e4e4e4;
    width: 1em;
    height: 1em;
    line-height: 0.53em;
    position: absolute;
    z-index: 1;
    top: 0.2em;
    left: 0;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  input[type=checkbox]:checked + label:before {
    content: "\f00c";
  }

  .page_wrap {
    min-height: 100vh;
    overflow: hidden;
  }

  .page_wrap,
.content_wrap {
    margin: 0 auto;
  }

  .page_content_wrap {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .remove_margins .page_content_wrap {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .content_wrap {
    width: 1278px;
    margin: 0 auto;
  }

  .content_wrap:after {
    content: " ";
    clear: both;
    width: 100%;
    height: 0;
    display: block;
  }

  .content {
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .page_content_wrap .content_wrap {
    position: relative;
  }

  .sidebar_hide [class*=content_wrap] > .content {
    margin-left: auto;
    margin-right: auto;
  }

  .top_panel {
    position: relative;
    z-index: 999;
    padding: 0.01px 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .sc_layouts_menu_mobile_button .sc_layouts_item_icon:before {
    content: "\e8ba";
    font-family: "fontello";
  }

  .menu_mobile_close {
    display: block;
    cursor: pointer;
    width: 5em;
    height: 5em;
    line-height: 5em;
    text-align: center;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
  }

  .menu_mobile_close:before,
.menu_mobile_close:after {
    content: " ";
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 38%;
    width: 25%;
    height: 0;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    margin-top: -1px;
    border-top: 2px solid #fff;
  }

  .menu_mobile_close:before {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .menu_mobile_close:after {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .menu_mobile_overlay {
    display: none !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 100000;
  }

  .menu_mobile {
    position: fixed;
    z-index: 100002;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    -webkit-transition: height 0.3s ease;
    -ms-transition: height 0.3s ease;
    transition: height 0.3s ease;
  }

  .menu_mobile_inner {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    overflow: hidden;
  }

  .menu_mobile li > a {
    display: block;
  }

  .menu_mobile .sc_layouts_logo {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    margin: 4.4rem 0 0;
    font-size: 1em !important;
  }

  .menu_mobile .menu_mobile_nav_area {
    position: absolute;
    z-index: 1;
    top: 48%;
    left: 0;
    width: 100%;
    height: auto;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    max-height: 49%;
    padding-right: 20px;
    overflow-y: scroll;
  }

  .menu_mobile .menu_mobile_nav_area ul {
    margin: 0 auto;
    padding: 0;
    list-style: none;
    width: auto;
  }

  .menu_mobile .menu_mobile_nav_area ul ul {
    margin: 5px 0;
    display: none;
  }

  .menu_mobile .menu_mobile_nav_area li {
    margin-bottom: 0;
    width: auto;
  }

  .menu_mobile .menu_mobile_nav_area li:before {
    display: none;
  }

  .menu_mobile .menu_mobile_nav_area li > a {
    font-size: 1.25em;
    line-height: 1.2em;
    padding: 1px 2.5em 1px 0;
    text-transform: none;
    letter-spacing: 0.5px;
    position: relative;
    display: inline-block;
    width: 28rem;
    text-align: left;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    font-weight: 500;
  }

  .menu_mobile .menu_mobile_nav_area > ul > li > a {
    font-size: 20px;
    line-height: 1.2em;
    padding: 5px 0;
    padding-right: 30px;
  }

  .menu_mobile .menu_mobile_nav_area > ul > li li > a {
    padding-left: 1em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
  }

  .menu_mobile .menu_mobile_nav_area > ul > li li li > a {
    padding-left: 2em;
  }

  .menu_mobile .menu_mobile_nav_area > ul > li li li li > a {
    padding-left: 3em;
  }

  .menu_mobile .menu_mobile_nav_area .open_child_menu {
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    width: 1.3rem;
    height: 1.3rem;
    line-height: 1.3rem;
    text-align: center;
    padding: 0.35rem;
    background-color: rgba(255, 255, 255, 0.1);
    -webkit-transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    -ms-transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    top: 5px;
  }

  .menu_mobile .menu_mobile_nav_area li li .open_child_menu {
    background-color: transparent;
    padding: 0.35rem;
    top: 2px;
  }

  .menu_mobile .menu_mobile_nav_area a:hover > .open_child_menu {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .menu_mobile .menu_mobile_nav_area .open_child_menu:before {
    font-family: "fontello";
    content: "\e828";
  }

  .menu_mobile .sc_layouts_logo img {
    max-height: 5em;
  }

  .menu_mobile .search_mobile {
    position: absolute;
    z-index: 1;
    bottom: 8rem;
    left: 50%;
    width: 28rem;
    margin-left: -14.5rem;
  }

  .menu_mobile .search_mobile form {
    position: relative;
  }

  .menu_mobile .search_mobile .search_field {
    width: 100%;
    padding: 1em 3em 1em 1.5em !important;
    border: none;
  }

  .menu_mobile .search_mobile .search_submit {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
    font-size: 14px;
    padding: 0.9em 1.5em;
    background-color: transparent;
  }

  .post_item p {
    margin-bottom: 0;
  }

  .page_content_wrap {
    position: relative;
  }

  .post_featured {
    overflow: hidden;
    position: relative;
    margin-bottom: 1.8em;
  }

  .post_meta .post_meta_item {
    font-size: inherit;
    margin-left: 0;
    white-space: normal;
    -webkit-transition: color 0.3s ease;
    -ms-transition: color 0.3s ease;
    transition: color 0.3s ease;
  }

  .post_meta .post_meta_item:after {
    content: "";
    display: inline;
    font-family: inherit;
    font-size: inherit;
    font-weight: 400;
    font-style: normal;
    vertical-align: baseline;
    margin: 0 0.4em;
  }

  .post_meta .post_meta_item:last-child:after {
    display: none;
  }

  .post_meta_item.post_categories a {
    padding: 5px 16px;
    -webkit-border-radius: 15px;
    -ms-border-radius: 15px;
    border-radius: 15px;
    margin-right: 3px;
  }

  .post_meta_item.post_categories a:last-child {
    margin-right: 0;
  }

  .post_meta_item.post_categories {
    margin-left: -3px;
  }

  .post_featured .mask {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .format-video .post_featured.with_thumb {
    position: relative;
  }

  .format-video .post_featured.with_thumb .post_video {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    text-align: center;
    opacity: 0;
    overflow: hidden;
  }

  .format-video .post_featured.with_thumb .post_video:before {
    content: " ";
    display: inline-block;
    padding-top: 55%;
    width: 0;
    height: 0;
  }

  .format-video .post_featured.with_thumb img {
    position: relative;
    z-index: 2;
  }

  .format-video .post_featured.with_thumb .mask {
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 0;
  }

  .format-video .post_featured.with_thumb:hover .mask {
    opacity: 1;
  }

  .format-video .post_featured.with_thumb .post_video_hover {
    width: 56px;
    height: 56px;
    line-height: 56px;
    text-align: center;
    -webkit-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    position: absolute;
    z-index: 20;
    top: 50%;
    left: 50%;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    margin: -28px 0 0 -28px;
    font-size: 16px;
    opacity: 1;
    cursor: pointer;
    overflow: hidden;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.3);
    border: none;
    text-indent: 4px;
  }

  .format-video .post_featured.with_thumb:hover .post_video_hover {
    opacity: 1;
    margin-top: -28px;
  }

  .format-video .post_featured.with_thumb .post_video_hover:before {
    content: "\e966";
    font-family: "fontello";
  }

  .post_item_single .post_title {
    margin-top: -0.2em;
  }

  .sc_layouts_widgets .widget {
    padding: 30px;
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.07);
    -ms-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.07);
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.07);
    overflow: hidden;
  }

  .sc_layouts_widgets .widget_area_inner > div + div,
.sc_layouts_widgets .widget_area_inner > div + aside,
.sc_layouts_widgets .widget_area_inner > aside + aside {
    margin-top: 30px;
  }

  .widget p {
    margin: 0;
  }

  .sc_layouts_widgets .widget .widget_title,
.widget .widget_title {
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
    letter-spacing: 2.2px;
    padding: 16px 23px;
    border-left: 7px solid red;
  }

  .sc_layouts_widgets .widget .widget_title {
    margin-left: -30px;
    margin-right: -30px;
    margin-top: -30px;
  }

  .widget_banner .image_wrap {
    margin: 0;
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }

  #sb_instagram .sb_instagram_header {
    padding: 0 !important;
    margin-bottom: 0.5em !important;
  }

  #sb_instagram #sbi_load {
    margin-top: 0.5em;
  }

  .footer_wrap p {
    margin: 0;
  }

  .footer_wrap .sc_layouts_menu > ul > li + li:before {
    display: none;
  }

  .footer_wrap .sc_layouts_menu > ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  .footer_wrap .sc_layouts_menu > ul > li {
    display: inline-block;
    vertical-align: baseline;
    font-size: 16px;
    z-index: auto !important;
    font-weight: 500;
  }

  .footer_wrap .sc_layouts_menu > ul > li + li:before {
    content: " ";
    display: inline-block;
    width: 0;
    height: 0.8em;
    margin: 0;
    vertical-align: baseline;
    border-left: 1px dotted #ddd;
  }

  .footer_wrap .sc_layouts_menu > ul > li > a {
    padding: 0.5em 1em;
    display: inline-block;
  }

  .footer_wrap .sc_layouts_column_align_left .sc_layouts_menu > ul > li:first-child > a {
    padding-left: 0;
  }

  .sc_layouts_row_type_normal {
    padding: 1em 0;
  }

  .sc_layouts_column_align_right .sc_layouts_item {
    margin-left: 1.3em;
    margin-right: 0;
  }

  .sc_layouts_menu_mobile_button .sc_layouts_item_icon {
    font-size: 30px !important;
  }

  .sc_layouts_row_type_normal .sc_layouts_item_icon {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    -webkit-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    font-size: 14px;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .search_wrap .search_submit {
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .search_wrap .search_submit:before {
    content: "\e968";
    font-family: "fontello";
  }

  .sc_layouts_row_type_normal .search_wrap .search_field {
    font-size: 14px;
    font-weight: 400;
    text-transform: lowercase;
    padding: 0 60px 0 21px !important;
    width: 18em;
    height: 50px;
    line-height: 50px;
    border: none;
  }

  .sc_layouts_row_type_normal .search_wrap .search_submit {
    display: block;
    font-size: 11px;
    text-align: center;
    width: 60px;
    height: 50px;
    line-height: 50px;
    position: absolute;
    top: 0;
    bottom: auto;
    left: auto;
    right: 0;
  }

  .sc_layouts_row_type_normal .search_wrap .search_submit:before {
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .top_panel:not(.scheme_dark) .sc_layouts_row_type_normal:not(.scheme_dark) .search_wrap .search_submit:before {
    color: #797e87 !important;
  }

  .top_panel:not(.scheme_dark) .sc_layouts_row_type_normal:not(.scheme_dark) .search_wrap .search_submit:hover:before {
    color: #262f3e !important;
  }

  .sc_layouts_menu_nav li {
    text-align: center;
  }

  .sc_layouts_menu_nav > li > ul:after {
    content: " ";
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 50%;
    width: 12px;
    height: 12px;
    margin: -6px 0 0 -6px;
    background-color: #fff;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .sc_layouts_menu_nav li li.menu-item-has-children > a:after,
.sc_layouts_menu_nav > li.menu-item-has-children > a:after {
    display: none;
  }

  .sc_layouts_menu_nav > li > a,
.sc_layouts_menu_nav > li.menu-item-has-children > a {
    padding: 0.85em 1.3em;
  }

  .sc_layouts_menu_nav > li > ul {
    top: 4em;
    left: 50%;
    margin-left: -3em;
  }

  .sc_layouts_menu_nav > li ul {
    width: 10.8em;
    padding: 1.8em 0;
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }

  .sc_layouts_menu_nav > li > ul ul {
    left: 10.8em;
    top: -1.8em;
    margin: 0 0 0 2px;
  }

  .sc_layouts_menu_nav > li li > a {
    padding: 0.27em 1em;
  }

  .sc_layouts_menu_nav > li ul {
    -webkit-box-shadow: none;
    -ms-box-shadow: none;
    box-shadow: none;
  }

  .sc_layouts_menu_nav > li.current-menu-parent > a,
.sc_layouts_menu_nav > li.current-menu-ancestor > a {
    font-weight: 500 !important;
  }

  .footer_wrap .sc_layouts_menu > ul > li + li:before {
    display: none;
  }

  #sb_instagram {
    padding-bottom: 0 !important;
  }

  .widget #sb_instagram #sbi_images {
    width: calc(100% + 8px);
    margin: 0 -4px;
    padding: 2px !important;
  }

  .widget #sbi_load {
    display: none;
  }

  .sc_blogger .sc_blogger_item_featured img,
.sc_recent_news .post_item .post_featured img {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .sc_blogger .sc_blogger_item_featured:hover img,
.sc_recent_news .post_item .post_featured:hover img {
    -webkit-transform: scale(1.02);
    -ms-transform: scale(1.02);
    transform: scale(1.02);
  }

  .box-style {
    overflow: hidden;
    -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.06);
    -ms-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.06);
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.06);
  }

  .copyright-text {
    font-size: 16px;
  }

  .med-title .sc_item_title {
    font-size: 72px;
  }

  .post_meta_item.post_categories {
    margin-left: 0;
    margin-top: -5px !important;
    display: inline-block;
    margin-bottom: -5px !important;
  }

  .post_meta_item.post_categories a {
    display: inline-block;
    line-height: 20px;
    margin-bottom: 3px;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__plugins.css ; media=all */
@media all {
  .post_featured[class*=hover_] {
    position: relative;
    -webkit-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
    display: inline-block;
    vertical-align: top;
  }

  .post_featured[class*=hover_],
.post_featured[class*=hover_] * {
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .post_featured .mask {
    content: " ";
    position: absolute;
    z-index: 4;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .post_featured.hover_simple:hover .mask {
    opacity: 1;
  }

  .post_featured.hover_simple .icons {
    position: absolute;
    z-index: 6;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .sc_slider_controls.slider_pagination_style_bullets .slider_pagination_bullet + .slider_pagination_bullet {
    margin-left: 12px;
  }

  .sc_slider_controls.slider_pagination_style_bullets .slider_pagination_bullet {
    width: 33px;
    height: 5px;
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    border: none;
    opacity: 1;
    vertical-align: middle;
  }

  .sc_widget_slider .slider_engine_swiper {
    overflow: hidden;
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }

  .footer_wrap .sc_layouts_item {
    vertical-align: top;
    margin-top: 0.25em !important;
    margin-bottom: 0.25em !important;
  }

  .footer_wrap .sc_layouts_item .widget {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .sc_blogger .post_featured {
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    position: relative;
    z-index: 1;
  }

  .sc_blogger_item_meta .post_meta_item.post_categories {
    margin-bottom: 0 !important;
    display: inline-block;
  }

  .sc_blogger_default .sc_blogger_item_title {
    font-size: 36px;
    line-height: 0.95em;
    letter-spacing: 1.4px;
  }

  .sc_blogger_default.sc_blogger_default_classic_meta .post_meta + .sc_blogger_item_title {
    margin-top: 7px;
  }

  .sc_blogger_item_default.sc_blogger_item_image_position_top .sc_blogger_item_featured + .sc_blogger_item_content {
    margin-top: 2.2em;
  }

  .sc_blogger .sc_blogger_item_title + .sc_blogger_item_excerpt {
    margin-top: 1.4em;
  }

  .sc_blogger_default.sc_blogger_default_classic_meta .sc_blogger_item_excerpt {
    padding-right: 30px;
  }

  .sc_blogger_default.sc_blogger_default_classic_meta_simple .sc_blogger_item_content {
    margin-top: -1px !important;
    text-align: center;
    padding: 40px 30px 15px;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .sc_blogger_default.sc_blogger_default_classic_meta_simple .post_featured {
    -webkit-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
  }

  .sc_blogger_default.sc_blogger_default_classic_meta_simple .post_meta {
    margin: 0 0 6px;
  }

  .scheme_dark.elementor-widget-trx_sc_slider_controls .sc_slider_controls span.swiper-pagination-bullet {
    background-color: #e6e6e6;
  }

  .sc_item_title {
    line-height: 0.94em;
    word-break: break-word;
  }

  .sc_title.sc_title_accent {
    margin-top: 0;
    margin-bottom: 0;
    padding: 16px 23px;
    border-left: 7px solid #ddd;
    -webkit-border-radius: 3px 3px 0 0;
    -ms-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
    overflow: hidden;
  }

  .sc_title.sc_title_accent .sc_item_title {
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
    letter-spacing: 2.2px;
  }

  .trx_addons_scroll_to_top {
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }

  .sc_recent_news .post_item .post_date:before {
    content: "";
  }

  .sc_recent_news .post_item .post_title {
    font-weight: 700;
  }

  .sc_recent_news_style_news-excerpt .post_featured {
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    overflow: hidden;
    z-index: 1;
  }

  .sc_recent_news_style_news-excerpt .post_featured {
    float: left;
    width: 100%;
    margin-bottom: 0;
    max-width: 260px;
  }

  .sc_recent_news_style_news-excerpt .post_item .post_meta {
    margin: 5px 0 0.9em !important;
  }

  .sc_recent_news_style_news-excerpt .post_item .post_title {
    font-weight: 700;
    font-size: 30px;
    letter-spacing: 1.2px;
    line-height: 1.1em;
  }

  .sc_recent_news_style_news-excerpt .post_item .post_header .post_categories {
    font-size: 13px;
    line-height: inherit;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 0;
    position: relative;
    padding-right: 0;
    text-transform: none;
  }

  .sc_recent_news_style_news-excerpt .post_item .post_header + .post_content {
    margin-top: 1.2em;
  }

  .sc_recent_news_style_news-excerpt .post_body {
    width: calc(100% - 260px);
    padding: 22px 60px 10px 35px;
  }

  .sc_recent_news_style_news-excerpt .post_item + .post_item {
    padding-top: 0;
    margin-top: 30px;
    border-top: none;
  }

  .sc_recent_news_style_news-excerpt .post_item .post_header .post_date:before {
    display: none;
  }

  .post_content > .elementor {
    margin-left: -15px;
    margin-right: -15px;
  }

  .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
    padding: 15px;
  }

  form.mc4wp-form {
    position: relative;
    padding-right: 0;
  }

  form.mc4wp-form .mc4wp-form-fields input[type=email] {
    width: 100%;
    vertical-align: top;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    height: 50px;
    line-height: 50px;
    border: none;
    -webkit-border-radius: 30px;
    -ms-border-radius: 30px;
    border-radius: 30px;
    padding: 0 10em 0 2.2em;
  }

  form.mc4wp-form .mc4wp-form-fields input[type=submit] {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    height: 50px;
    line-height: 50px;
    min-width: 9.5em;
    vertical-align: top;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0;
    padding-bottom: 0;
    -webkit-border-radius: 30px;
    -ms-border-radius: 30px;
    border-radius: 30px;
    text-align: center;
  }

  form.mc4wp-form label {
    margin-top: 10px;
  }

  .sp-template a:hover,
.sp-data-table a:hover {
    text-decoration: none !important;
  }

  .sp-template img {
    vertical-align: middle;
  }

  .sp-template {
    margin-bottom: 30px !important;
  }

  .sp-table-caption {
    margin: 0 0 30px !important;
    padding: 0 !important;
    background: transparent !important;
    border: none !important;
    font-size: 48px !important;
    font-weight: 700 !important;
    line-height: 1em !important;
    display: block !important;
    width: 100% !important;
  }

  .sp-template h3,
.sp-template h4,
.sp-template h5,
.sp-data-table h4,
.sp-data-table h5 {
    letter-spacing: 1px !important;
  }

  .sp-data-table {
    border: none !important;
    background: transparent !important;
  }

  .sp-data-table th {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 1.2px;
    padding: 10px 15px;
  }

  .sp-data-table td {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.4em;
    border: none;
    padding: 17px 25px;
    text-align: left;
    background: transparent;
    letter-spacing: 0.6px;
  }

  .sp-data-table tr + tr {
    border-top: 1px solid #ddd !important;
  }

  .sp-data-table tr:last-child {
    border-bottom: 1px solid #ddd !important;
  }

  .sp-data-table .data-name {
    text-align: left !important;
  }

  .sc_layouts_widgets .sp-data-table tr:first-child th {
    padding-top: 0 !important;
  }

  .sc_layouts_widgets .sp-data-table th,
.sc_layouts_widgets .sp-data-table td {
    padding: 14px 8px !important;
    background: none !important;
  }

  body .sc_layouts_widgets .sp-data-table th,
body .sc_layouts_widgets .sp-data-table td {
    background: none !important;
  }

  .sc_layouts_widgets .sp-data-table td.data-name,
.sc_layouts_widgets .sp-data-table td.data-rank {
    text-align: left !important;
  }

  .sc_layouts_widgets .sp-data-table thead tr:last-child {
    border-bottom-width: 2px !important;
  }

  .sc_layouts_widgets .sp-view-all-link > a {
    width: 100% !important;
    text-align: center;
    padding: 0.8em 3.5em !important;
    margin-bottom: 1px;
  }

  .sc_layouts_widgets .sp-view-all-link {
    padding: 20px 0 0 0 !important;
  }

  .sc_layouts_widgets .sp-template {
    margin-bottom: 0 !important;
  }

  .sp-view-all-link {
    padding: 25px 0 0 0 !important;
    margin: 0 !important;
    background: transparent !important;
    border: none !important;
    overflow: hidden !important;
    display: block !important;
    width: 100% !important;
    text-align: center !important;
  }

  .sp-view-all-link > a {
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    text-transform: capitalize !important;
  }

  .sp-template-event-blocks .sp-event-results {
    font-size: 60px !important;
    font-weight: 700 !important;
    padding: 5px 0 !important;
  }

  .sp-template-event-blocks .sp-event-results * {
    font-weight: 700 !important;
  }

  .sp-template-event-blocks .sp-event-date,
.sp-template-event-blocks .sp-event-title a,
.sp-template-event-blocks .sp-event-title {
    font-size: 13px !important;
    font-weight: 500 !important;
  }

  .sp-template.sp-template-event-blocks {
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    overflow: hidden;
    -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.07);
    -ms-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.07);
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.07);
  }

  .sp-template-event-blocks .sp-data-table tr:last-child {
    border-bottom: none !important;
  }

  .sp-template-event-blocks:not(.sp-template-event-logos-block) .sp-event-blocks .team-logo {
    margin: 10px;
    width: 22%;
    height: 6em;
    max-width: 6em;
    margin-top: 33px;
  }

  .sp-template-event-blocks .sp-event-title {
    display: none !important;
  }

  .sp-template-event-blocks:not(.sp-template-event-logos-block) .sp-data-table td {
    padding: 17px 25px 19px !important;
  }

  .sp-template-countdown .sp-event-name,
.sp-template-countdown .sp-event-venue,
.sp-template-countdown .sp-event-date {
    border: none !important;
    background: transparent !important;
    padding: 5px 0 !important;
  }

  .sp-countdown time {
    display: table !important;
    background: transparent !important;
    border: none !important;
  }

  .sp-countdown time > span {
    background: transparent !important;
    border: none !important;
  }

  .sp-countdown-wrapper .countdown.sp-countdown time span {
    border: none !important;
    font-size: 49px !important;
    font-weight: 700 !important;
    line-height: 1em !important;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .sp-countdown-wrapper .countdown.sp-countdown time span small {
    font-size: 13px !important;
    line-height: 1.2em !important;
    font-weight: 500 !important;
    display: block !important;
    text-transform: capitalize !important;
  }

  .sp-template-countdown .sp-event-name > a:not(.team-logo) {
    font-size: 13px !important;
    line-height: 18px !important;
    font-weight: 500 !important;
  }

  .sp-template-countdown .sp-event-venue,
.sp-template-countdown .sp-event-date {
    font-size: 13px !important;
    font-weight: 700 !important;
    letter-spacing: 0 !important;
  }

  .sc_layouts_widgets .sp-countdown time {
    border-top: 1px solid #ddd !important;
    margin-top: 23px;
  }

  .sc_layouts_widgets .sp-countdown-wrapper .countdown.sp-countdown time span {
    padding: 20px 0 5px 0 !important;
  }

  .sc_layouts_widgets .sp-template-countdown .sp-event-name > a:not(.team-logo) {
    margin-top: 50px;
  }

  .extra-event-block .elementor-container {
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    overflow: hidden;
    -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.065);
    -ms-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.065);
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.065);
    margin-left: 15px;
    margin-right: 15px;
  }

  .extra-event-block .sp-template.sp-template-event-blocks {
    -webkit-box-shadow: none;
    -ms-box-shadow: none;
    box-shadow: none;
    margin: 0 !important;
  }

  .extra-event-block .elementor-row > .elementor-element + .elementor-element:before {
    position: absolute;
    content: "";
    display: block;
    left: 0;
    top: 13%;
    width: 1px;
    height: 74%;
    z-index: 1;
    background: #ddd;
  }

  .extra-event-block .sp-template-event-blocks .sp-event-results {
    font-size: 48px !important;
    padding: 0 !important;
    line-height: 0.9em;
  }

  .extra-event-block .sp-template-event-blocks .sp-event-blocks .team-logo {
    height: 5.1em;
    margin-top: 25px;
  }

  .extra-event-block .sp-template-event-blocks .sp-data-table td {
    padding: 22px 25px 22px !important;
  }

  .extra-countdown .sp-countdown-wrapper > * {
    display: none !important;
    background: transparent !important;
    border-left: none !important;
    border-right: none !important;
  }

  .extra-countdown .sp-countdown-wrapper .countdown.sp-countdown {
    display: block !important;
  }

  .extra-countdown .sp-countdown-wrapper .countdown.sp-countdown time {
    background: transparent !important;
    border: none !important;
    display: block !important;
  }

  .extra-countdown .sp-countdown-wrapper .countdown.sp-countdown time span {
    border: none !important;
    font-size: 49px !important;
    font-weight: 700 !important;
    line-height: 1em !important;
    display: block !important;
    padding: 10px 5px !important;
    text-align: left !important;
    width: 25% !important;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    float: left !important;
  }

  .extra-countdown .sp-countdown-wrapper .countdown.sp-countdown time span small {
    font-size: 13px !important;
    line-height: 1.2em !important;
    font-weight: 500 !important;
    display: block !important;
    text-transform: capitalize !important;
  }

  .extra-countdown .sp-template-countdown {
    margin-bottom: 0 !important;
  }

  .extra-countdown.top-style-2 {
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    overflow: hidden;
    max-width: 538px;
    padding: 12px 0 7px 35px;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    float: right;
    display: block;
    clear: both;
    width: 100%;
    text-align: left;
  }

  .extra-countdown.top-style-2 .wp-widget-sportspress-countdown {
    text-align: left;
  }

  .extra-countdown.top-style-2 {
    max-width: 568px;
  }

  .extra-countdown.top-style-2 .widget_title {
    margin: 0 !important;
    background: transparent !important;
    border: none !important;
    padding: 0 !important;
    font-size: 20px;
    text-align: left;
  }

  .extra-countdown.top-style-2 .sp-table-caption {
    font-size: 20px !important;
    letter-spacing: 2.3px !important;
    margin: 0 !important;
  }

  .extra-countdown.top-style-2 .widget_title,
.extra-countdown.top-style-2 .sp-table-caption {
    margin-top: 20px !important;
    width: 100% !important;
    float: left;
    display: block !important;
    clear: both;
    max-width: 164px !important;
  }

  .extra-countdown.top-style-2 .widget_title,
.extra-countdown.top-style-2 .sp-table-caption {
    max-width: 194px !important;
  }

  .extra-countdown.top-style-2 .widget_title + .sp-table-caption {
    margin-top: 4px !important;
  }

  .extra-countdown.top-style-2 .sp-template-countdown {
    width: 338px;
    display: inline-block !important;
    position: relative;
    overflow: visible;
    padding-left: 30px;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .extra-countdown.top-style-2 .sp-template-countdown:before {
    position: absolute;
    display: block;
    font-family: "fontello";
    content: "\e814\e814\e814\e814\e814\e814\e814\e814\e814\e814\e814\e814\e814\e814\e814";
    width: 8px;
    height: 160%;
    left: 0;
    top: -27%;
    color: #091625;
    font-size: 5px;
    letter-spacing: 0;
    text-indent: 0;
    line-height: 9px;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__custom.css ; media=all */
@media all {
  .content_wrap {
    width: 1278px;
  }

  body {
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    font-style: normal;
    line-height: 1.76em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0.26px;
  }

  p,
ul {
    margin-top: 0em;
    margin-bottom: 1.6em;
  }

  h1 {
    font-family: "Roboto", sans-serif;
    font-size: 4.286em;
    font-weight: 700;
    font-style: normal;
    line-height: 0.93em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 2.1px;
    margin-bottom: 0.63em;
  }

  h2 {
    font-family: "Roboto", sans-serif;
    font-size: 3.429em;
    font-weight: 700;
    font-style: normal;
    line-height: 0.96em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 1.75px;
    margin-bottom: 0.52em;
  }

  h3 {
    font-family: "Roboto", sans-serif;
    font-size: 2.571em;
    font-weight: 700;
    font-style: normal;
    line-height: 0.91em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 1.3px;
    margin-bottom: 0.92em;
  }

  h4 {
    font-family: "Roboto", sans-serif;
    font-size: 2.143em;
    font-weight: 700;
    font-style: normal;
    line-height: 1.06em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 1.1px;
    margin-bottom: 0.6em;
  }

  h5 {
    font-family: "Roboto", sans-serif;
    font-size: 1.714em;
    font-weight: 700;
    font-style: normal;
    line-height: 1.06em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0.85px;
    margin-bottom: 0.7em;
  }

  input[type=text],
input[type=password],
input[type=email],
textarea {
    font-size: 1em;
    font-weight: 400;
    font-style: normal;
    line-height: 1.5em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0px;
  }

  .sp-view-all-link > a,
form button,
input[type=submit] {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 18px;
    text-decoration: none;
  }

  .post_meta {
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.4em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0px;
    margin-top: 0.4em;
  }

  .sc_layouts_row:not(.sc_layouts_row_type_narrow) .sc_layouts_menu_nav {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    line-height: 1.5em;
  }

  .sc_layouts_row:not(.sc_layouts_row_type_narrow) .sc_layouts_menu_nav > li > a {
    font-weight: 500;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0.18px;
  }

  .sc_layouts_row:not(.sc_layouts_row_type_narrow) .sc_layouts_menu_nav > li > ul {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    line-height: 1.35em;
  }

  .sc_layouts_row:not(.sc_layouts_row_type_narrow) .sc_layouts_menu_nav > li ul > li > a {
    font-weight: 500;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0.18px;
  }

  .menu_mobile .menu_mobile_nav_area > ul {
    font-family: "Poppins", sans-serif;
  }

  .menu_mobile .menu_mobile_nav_area > ul > li ul {
    font-family: "Poppins", sans-serif;
  }

  .slider_pagination_wrap {
    font-family: "Roboto", sans-serif;
  }

  .sc_button {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 18px;
    text-decoration: none;
  }

  form.mc4wp-form .mc4wp-form-fields input[type=email] {
    font-size: 1em;
    font-weight: 400;
    font-style: normal;
    line-height: 1.5em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0px;
  }

  form.mc4wp-form .mc4wp-form-fields input[type=submit] {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 18px;
    text-decoration: none;
  }

  .sp-countdown-wrapper .countdown.sp-countdown time span,
.sp-template-event-blocks .sp-event-results *,
.sp-template-event-blocks .sp-event-results,
.sp-data-table th,
.sp-table-caption,
.sp-template h3,
.sp-template h4,
.sp-template h5,
.sp-data-table h4,
.sp-data-table h5 {
    font-family: "Roboto", sans-serif !important;
  }

  .sp-template-countdown .sp-event-venue,
.sp-template-countdown .sp-event-date {
    font-family: "Poppins", sans-serif !important;
  }

  .sp-template-countdown .sp-event-name > a:not(.team-logo),
.sp-countdown-wrapper .countdown.sp-countdown time span small,
.sp-template-event-blocks .sp-event-title a,
.sp-template,
.sp-data-table,
.sp-table-caption {
    font-family: "Poppins", sans-serif;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__colors-alter.css ; media=all */
@media all {
  .scheme_alter {
    color: #797e87;
    background: #f0f2f7;
  }

  .scheme_alter h1,
.scheme_alter h2,
.scheme_alter h3,
.scheme_alter h4,
.scheme_alter h5,
.scheme_alter h3 a,
.scheme_alter h4 a,
.scheme_alter h5 a,
.scheme_alter li a {
    color: #262f3e;
  }

  .scheme_alter h3 a:hover,
.scheme_alter h4 a:hover,
.scheme_alter h5 a:hover,
.scheme_alter li a:hover {
    color: #ff0000;
  }

  .scheme_alter a {
    color: #ff0000;
  }

  .scheme_alter a:hover {
    color: #081324;
  }

  .scheme_alter table th,
.scheme_alter table th + th {
    border-color: #262f3e;
  }

  .scheme_alter table td,
.scheme_alter table td + td {
    color: #797e87;
    border-color: #e3e3e3;
  }

  .scheme_alter table th {
    color: #8c9097;
    background-color: #224452;
  }

  .scheme_alter table > tbody > tr:nth-child(2n+1) > td {
    background-color: #ebebeb;
  }

  .scheme_alter table > tbody > tr:nth-child(2n) > td {
    background-color: #f4f4f4;
  }

  .scheme_alter ul > li:before {
    color: #ff0000;
  }

  .scheme_alter input[type=text],
.scheme_alter input[type=password],
.scheme_alter input[type=email],
.scheme_alter textarea,
.scheme_alter form.mc4wp-form input[type=email] {
    color: #797e87;
    border-color: #e4e4e4;
    background-color: #f4f4f4;
  }

  .scheme_alter input[type=text]:focus,
.scheme_alter input[type=password]:focus,
.scheme_alter input[type=email]:focus,
.scheme_alter textarea:focus,
.scheme_alter form.mc4wp-form input[type=email]:focus {
    color: #262f3e;
    border-color: #262f3e;
    background-color: #f4f4f4;
  }

  .scheme_alter input[placeholder]::-webkit-input-placeholder {
    color: #797e87;
    opacity: 1;
  }

  .scheme_alter textarea[placeholder]::-webkit-input-placeholder {
    color: #797e87;
    opacity: 1;
  }

  .scheme_alter input[placeholder]::-moz-placeholder {
    color: #797e87;
    opacity: 1;
  }

  .scheme_alter textarea[placeholder]::-moz-placeholder {
    color: #797e87;
    opacity: 1;
  }

  .scheme_alter input[placeholder]:-ms-input-placeholder {
    color: #797e87;
    opacity: 1;
  }

  .scheme_alter textarea[placeholder]:-ms-input-placeholder {
    color: #797e87;
    opacity: 1;
  }

  .scheme_alter input[placeholder]::placeholder {
    color: #797e87;
    opacity: 1;
  }

  .scheme_alter textarea[placeholder]::placeholder {
    color: #797e87;
    opacity: 1;
  }

  .scheme_alter input[type=checkbox] + label:before {
    border-color: #e4e4e4 !important;
  }

  div .sp-view-all-link > a {
    color: #ffffff !important;
  }

  .scheme_alter .search_wrap .search_submit:before {
    color: #797e87;
  }

  .scheme_alter input[type=submit][disabled],
.scheme_alter input[type=submit][disabled]:hover {
    background-color: rgba(38, 47, 62, 0.4) !important;
    color: #ffffff !important;
  }

  .scheme_alter .sp-view-all-link > a:hover,
.scheme_alter button:hover,
.scheme_alter button:focus,
.scheme_alter input[type=submit]:hover,
.scheme_alter input[type=submit]:focus,
.scheme_alter .sc_button_default:hover,
.scheme_alter .sc_button:not(.sc_button_simple):not(.sc_button_bordered):not(.sc_button_bg_image):hover,
.scheme_alter .mc4wp-form input[type=submit]:hover,
.scheme_alter .mc4wp-form input[type=submit]:focus {
    color: #ffffff;
    background-color: #262f3e;
  }

  .scheme_alter div .sp-view-all-link > a:hover {
    color: #ffffff !important;
  }

  .scheme_alter .search_wrap .search_submit:hover:before {
    color: #262f3e;
  }

  .scheme_alter .sc_layouts_item_icon {
    color: #797e87;
  }

  .scheme_alter .sc_layouts_row_type_normal .sc_layouts_item {
    color: #797e87;
  }

  .scheme_alter .sc_layouts_row_type_normal .sc_layouts_item a:not(.sc_button):not(.button) {
    color: #797e87;
  }

  .scheme_alter .sc_layouts_row_type_normal .sc_layouts_item a:not(.sc_button):not(.button):hover,
.scheme_alter .sc_layouts_row_type_normal .sc_layouts_item a:not(.sc_button):not(.button):hover .sc_layouts_item_icon {
    color: #262f3e;
  }

  .scheme_alter .sc_layouts_row_type_normal .search_wrap .search_submit:before,
.scheme_alter .sc_layouts_row_type_normal .search_wrap .search_submit {
    background-color: transparent;
    color: #262f3e;
  }

  .scheme_alter .sc_layouts_row_type_normal .search_wrap .search_submit:hover:before,
.scheme_alter .sc_layouts_row_type_normal .search_wrap .search_submit:hover {
    background-color: transparent;
    color: #797e87;
  }

  .scheme_alter .sc_layouts_menu_nav > li > a {
    color: #262f3e;
  }

  .scheme_alter .sc_layouts_menu_nav > li > a:hover {
    color: #ff0000 !important;
  }

  .scheme_alter .sc_layouts_menu_nav > li.current-menu-parent > a,
.scheme_alter .sc_layouts_menu_nav > li.current-menu-ancestor > a {
    color: #ff0000 !important;
  }

  .scheme_alter .sc_layouts_menu_nav > li li > a {
    color: #8c9097 !important;
  }

  .scheme_alter .sc_layouts_menu_nav > li li > a:hover {
    color: #ff0000 !important;
  }

  .scheme_alter .sc_layouts_menu_nav > li li > a:hover:after {
    color: #ff0000 !important;
  }

  .scheme_alter .sc_layouts_menu_nav > li li.current-menu-item > a {
    color: #ff0000 !important;
  }

  .scheme_alter .sc_layouts_menu_nav > li li.current-menu-item:before {
    color: #ff0000 !important;
  }

  .scheme_alter .menu_mobile_inner {
    color: #797e87;
    background-color: #ffffff;
  }

  .scheme_alter .menu_mobile_close:before,
.scheme_alter .menu_mobile_close:after {
    border-color: #1d1d1d;
  }

  .scheme_alter .menu_mobile_close:hover:before,
.scheme_alter .menu_mobile_close:hover:after {
    border-color: #ff0000;
  }

  .scheme_alter .menu_mobile_inner a,
.scheme_alter .menu_mobile_inner .menu_mobile_nav_area li:before {
    color: #1d1d1d;
  }

  .scheme_alter .menu_mobile_inner a:hover,
.scheme_alter .menu_mobile_inner .menu_mobile_nav_area li:hover:before {
    color: #ff0000;
  }

  .scheme_alter .menu_mobile_inner .search_mobile .search_submit {
    color: #797e87;
  }

  .scheme_alter .menu_mobile_inner .search_mobile .search_submit:focus,
.scheme_alter .menu_mobile_inner .search_mobile .search_submit:hover {
    color: #262f3e;
  }

  .scheme_alter .sc_layouts_row_type_normal .sc_layouts_item_icon {
    color: #ffffff;
    background-color: #8c9097;
  }

  .scheme_alter .sc_layouts_row_type_normal .sc_layouts_item_icon:hover {
    color: #ffffff;
    background-color: #262f3e;
  }

  .scheme_alter .top_panel {
    background-color: #ffffff;
  }

  .scheme_alter .post_item {
    color: #797e87;
  }

  .scheme_alter .post_meta,
.scheme_alter .post_meta_item,
.scheme_alter .post_meta_item:after,
.scheme_alter .post_meta_item:hover:after,
.scheme_alter .post_meta_item a {
    color: #797e87;
  }

  .scheme_alter .post_meta_item.post_categories a {
    color: #ffffff;
    background-color: #ff0000;
  }

  .scheme_alter .post_meta_item.post_categories a:hover {
    color: #ffffff;
    background-color: #081324;
  }

  .scheme_alter .post_meta_item.post_date {
    color: #ff0000;
  }

  .scheme_alter .post_meta_item a:hover {
    color: #262f3e;
  }

  .scheme_alter .post_item .post_title a:hover {
    color: #ff0000;
  }

  .scheme_alter .format-video .post_featured.with_thumb .post_video_hover {
    color: #ffffff;
    background-color: #ff0000;
  }

  .scheme_alter .format-video .post_featured.with_thumb .post_video_hover:hover {
    color: #ff0000;
    background-color: #ffffff;
  }

  .scheme_alter .sc_layouts_widgets .widget {
    background-color: #ffffff;
  }

  .scheme_alter .sc_title.sc_title_accent,
.scheme_alter .widget .widget_title {
    background-color: #224452;
    color: #ffffff;
    border-color: #ff0000;
  }

  .scheme_alter .sc_item_title.sc_item_title_style_accent {
    color: #ffffff;
  }

  .scheme_alter .sc_layouts_menu_mobile_button .sc_layouts_item_icon {
    background-color: #ff0000 !important;
    color: #ffffff !important;
  }

  .scheme_alter .sc_layouts_menu_mobile_button .sc_layouts_item_icon:hover {
    background-color: #262f3e !important;
    color: #ffffff !important;
  }

  .scheme_alter .sc_layouts_menu_mobile_button .sc_layouts_item_icon {
    color: #ffffff !important;
    background-color: #8c9097 !important;
  }

  .scheme_alter .sc_layouts_menu_mobile_button .sc_layouts_item_icon:hover {
    color: #ffffff !important;
    background-color: #262f3e !important;
  }

  .scheme_alter .sc_slider_controls.slider_pagination_style_bullets .slider_pagination_bullet {
    border-color: #262f3e;
    background-color: #262f3e;
  }

  .scheme_alter .sc_slider_controls.slider_pagination_style_bullets .slider_pagination_bullet.swiper-pagination-bullet-active,
.scheme_alter .sc_slider_controls.slider_pagination_style_bullets .slider_pagination_bullet:hover {
    border-color: #ff0000;
    background-color: #ff0000;
  }

  .scheme_alter .sc_recent_news_style_news-excerpt .post_item {
    border-color: #e3e3e3;
  }

  .scheme_alter .sc_blogger_default.sc_blogger_default_classic_meta_simple .sc_blogger_item_content {
    background-color: #ffffff;
  }

  .scheme_alter .trx_addons_scroll_to_top {
    color: #ffffff;
    border-color: #ff0000;
    background-color: #ff0000;
  }

  .scheme_alter .trx_addons_scroll_to_top:hover {
    color: #ffffff;
    border-color: #ff150d;
    background-color: #262f3e;
  }

  .scheme_alter .elementor-divider-separator {
    border-color: #e3e3e3;
  }

  .scheme_alter .sp-template *,
.scheme_alter .sp-data-table *,
.scheme_alter .sp-table-caption {
    color: #797e87;
  }

  .scheme_alter .sp-table-caption {
    color: #262f3e;
  }

  .scheme_alter .sp-data-table td {
    color: #262f3e;
  }

  .scheme_alter .sp-data-table th {
    color: #262f3e;
    background: #f4f4f4;
  }

  .scheme_alter .sp-data-table tr + tr {
    border-color: #e3e3e3;
  }

  .scheme_alter .sp-template-event-blocks .sp-event-results span,
.scheme_alter .sp-template-event-blocks .sp-event-results,
.scheme_alter .sp-template-event-blocks .sp-event-results a:hover,
.scheme_alter .sp-template-event-blocks .sp-event-results a,
.scheme_alter .sp-template-event-blocks .sp-event-date,
.scheme_alter .sp-template-event-blocks .sp-event-title a,
.scheme_alter .sp-template-event-blocks .sp-event-title {
    color: #262f3e !important;
  }

  .scheme_alter .sp-template a,
.scheme_alter .sp-data-table a {
    color: #262f3e !important;
  }

  .scheme_alter .sp-template a:hover,
.scheme_alter .sp-data-table a:hover {
    color: #ff0000 !important;
  }

  .scheme_alter .extra-event-block .elementor-container,
.scheme_alter .sp-template.sp-template-event-blocks {
    background: #ffffff !important;
  }

  .scheme_alter .sp-template-countdown .sp-event-venue,
.scheme_alter .sp-template-countdown .sp-event-date {
    color: #262f3e !important;
  }

  .scheme_alter .extra-event-block .elementor-row > .elementor-element + .elementor-element:before {
    background: #e3e3e3;
  }

  .scheme_alter .sp-countdown-wrapper .countdown.sp-countdown time span {
    color: #262f3e !important;
  }

  .scheme_alter .sp-countdown-wrapper .countdown.sp-countdown time span small {
    color: #797e87 !important;
  }

  .scheme_alter .extra-countdown.top-style-2 {
    background: #e3e3e3;
  }

  .scheme_alter .extra-countdown.top-style-2 .widget_title {
    color: #ff0000 !important;
  }

  .scheme_alter .sp-countdown time {
    border-color: #e3e3e3 !important;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__colors-dark.css ; media=all */
@media all {
  .scheme_dark {
    color: #848992;
  }

  .scheme_dark h1,
.scheme_dark h2,
.scheme_dark h3,
.scheme_dark h4,
.scheme_dark h5,
.scheme_dark h3 a,
.scheme_dark li a {
    color: #ffffff;
  }

  .scheme_dark h3 a:hover,
.scheme_dark li a:hover {
    color: #ff0000;
  }

  .scheme_dark a {
    color: #ff0000;
  }

  .scheme_dark a:hover {
    color: #ffffff;
  }

  .scheme_dark ul > li:before {
    color: #ff0000;
  }

  .scheme_dark input[type=text],
.scheme_dark input[type=password],
.scheme_dark input[type=email],
.scheme_dark form.mc4wp-form input[type=email] {
    color: #b7b7b7;
    border-color: #262f3e;
    background-color: #262f3e;
  }

  .scheme_dark input[type=text]:focus,
.scheme_dark input[type=password]:focus,
.scheme_dark input[type=email]:focus,
.scheme_dark form.mc4wp-form input[type=email]:focus {
    color: #ffffff;
    border-color: #353535;
    background-color: #262f3e;
  }

  .scheme_dark input[placeholder]::-webkit-input-placeholder {
    color: #6f6f6f;
    opacity: 1;
  }

  .scheme_dark input[placeholder]::-moz-placeholder {
    color: #6f6f6f;
    opacity: 1;
  }

  .scheme_dark input[placeholder]:-ms-input-placeholder {
    color: #6f6f6f;
    opacity: 1;
  }

  .scheme_dark input[placeholder]::placeholder {
    color: #6f6f6f;
    opacity: 1;
  }

  .scheme_dark input[type=checkbox] + label:before {
    border-color: #262f3e !important;
  }

  .scheme_dark button:not(.components-button),
.scheme_dark input[type=submit],
.scheme_dark .sc_button_default,
.scheme_dark .sc_button:not(.sc_button_simple):not(.sc_button_bordered):not(.sc_button_bg_image),
.scheme_dark .mc4wp-form input[type=submit] {
    color: #ffffff;
    background-color: #ff0000;
  }

  .scheme_dark .search_wrap .search_submit:before {
    color: #b7b7b7;
  }

  .scheme_dark input[type=submit][disabled],
.scheme_dark input[type=submit][disabled]:hover {
    background-color: rgba(255, 255, 255, 0.4) !important;
    color: #081224 !important;
  }

  .scheme_dark button:hover,
.scheme_dark button:focus,
.scheme_dark input[type=submit]:hover,
.scheme_dark input[type=submit]:focus,
.scheme_dark .sc_button_default:hover,
.scheme_dark .sc_button:not(.sc_button_simple):not(.sc_button_bordered):not(.sc_button_bg_image):hover,
.scheme_dark .mc4wp-form input[type=submit]:hover,
.scheme_dark .mc4wp-form input[type=submit]:focus {
    color: #262f3e;
    background-color: #ffffff;
  }

  .scheme_dark .search_wrap .search_submit:hover:before {
    color: #ffffff;
  }

  .scheme_dark.footer_wrap {
    color: #848992;
    background-color: #081224;
  }

  .scheme_dark .sc_layouts_menu_nav > li > a {
    color: #ffffff;
  }

  .scheme_dark .sc_layouts_menu_nav > li > a:hover {
    color: #ff0000 !important;
  }

  .scheme_dark .menu_mobile_inner {
    color: #a6a6a6;
    background-color: #081224;
  }

  .scheme_dark .menu_mobile_close:before,
.scheme_dark .menu_mobile_close:after {
    border-color: #ffffff;
  }

  .scheme_dark .menu_mobile_close:hover:before,
.scheme_dark .menu_mobile_close:hover:after {
    border-color: #ff0000;
  }

  .scheme_dark .menu_mobile_inner a,
.scheme_dark .menu_mobile_inner .menu_mobile_nav_area li:before {
    color: #ffffff;
  }

  .scheme_dark .menu_mobile_inner a:hover,
.scheme_dark .menu_mobile_inner .menu_mobile_nav_area li:hover:before {
    color: #ff0000;
  }

  .scheme_dark .menu_mobile_inner .search_mobile .search_submit {
    color: #6f6f6f;
  }

  .scheme_dark .menu_mobile_inner .search_mobile .search_submit:focus,
.scheme_dark .menu_mobile_inner .search_mobile .search_submit:hover {
    color: #ffffff;
  }

  .scheme_dark.footer_wrap {
    background-color: #081224;
    color: #a6a6a6;
  }

  .scheme_dark.footer_wrap .widget {
    border-color: #848992;
  }

  .scheme_dark.footer_wrap h2 {
    color: #ffffff;
  }

  .scheme_dark.footer_wrap a {
    color: #ffffff;
  }

  .scheme_dark.footer_wrap a:hover {
    color: #ff0000;
  }

  .scheme_dark .widget .widget_title {
    background-color: #ffffff;
    color: #081224;
    border-color: #ff0000;
  }

  .scheme_dark .sc_slider_controls.slider_pagination_style_bullets .slider_pagination_bullet {
    border-color: #ffffff;
    background-color: #ffffff;
  }

  .scheme_dark .sc_slider_controls.slider_pagination_style_bullets .slider_pagination_bullet.swiper-pagination-bullet-active,
.scheme_dark .sc_slider_controls.slider_pagination_style_bullets .slider_pagination_bullet:hover {
    border-color: #ff0000;
    background-color: #ff0000;
  }

  .scheme_dark .elementor-divider-separator {
    border-color: #262f3e;
  }

  .scheme_dark .sp-template *,
.scheme_dark .sp-table-caption {
    color: #848992 !important;
  }

  .scheme_dark .sp-table-caption {
    color: #ffffff !important;
  }

  .scheme_dark .sp-template a {
    color: #ffffff !important;
  }

  .scheme_dark .sp-template a:hover {
    color: #ff0000 !important;
  }

  .scheme_dark .sp-countdown-wrapper .countdown.sp-countdown time span {
    color: #ffffff !important;
  }

  .scheme_dark .sp-countdown-wrapper .countdown.sp-countdown time span small {
    color: #848992 !important;
  }

  .scheme_dark .extra-countdown.top-style-2 {
    background: #262f3e;
  }

  .scheme_dark .extra-countdown.top-style-2 .widget_title {
    color: #ff0000 !important;
  }

  .scheme_dark .sp-countdown time {
    border-color: #262f3e !important;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__colors-default.css ; media=all */
@media all {
  .scheme_default {
    color: #797e87;
  }

  .scheme_default h5,
.scheme_default h5 a {
    color: #262f3e;
  }

  .scheme_default h5 a:hover {
    color: #ff0000;
  }

  .scheme_default a {
    color: #ff0000;
  }

  .scheme_default a:hover {
    color: #081324;
  }

  .scheme_default .post_meta,
.scheme_default .post_meta_item,
.scheme_default .post_meta_item:after,
.scheme_default .post_meta_item:hover:after,
.scheme_default .post_meta_item a {
    color: #797e87;
  }

  .scheme_default .post_meta_item.post_categories a {
    color: #ffffff;
    background-color: #ff0000;
  }

  .scheme_default .post_meta_item.post_categories a:hover {
    color: #ffffff;
    background-color: #081324;
  }

  .scheme_default .post_meta_item.post_date {
    color: #ff0000;
  }

  .scheme_default .post_meta_item a:hover {
    color: #262f3e;
  }

  .scheme_default .format-video .post_featured.with_thumb .post_video_hover {
    color: #ffffff;
    background-color: #ff0000;
  }

  .scheme_default .format-video .post_featured.with_thumb .post_video_hover:hover {
    color: #ff0000;
    background-color: #ffffff;
  }

  .scheme_default .sc_blogger_default.sc_blogger_default_classic_meta_simple .sc_blogger_item_content {
    background-color: #ffffff;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/trx_addons/css/__responsive.css ; media=all */
@media (max-width: 1439px) {
  .sc_item_title + .sc_item_button {
    margin-top: 2em;
  }

  .sc_layouts_row:not(.sc_layouts_row_type_narrow),
.sc_layouts_row:not(.sc_layouts_row_type_narrow) input[type=text] {
    font-size: 14px;
  }

  .sc_layouts_row .sc_layouts_logo img {
    max-height: 4.5em;
  }
}
@media (max-width: 1279px) {
  .sc_layouts_row:not(.sc_layouts_row_type_narrow),
.sc_layouts_row:not(.sc_layouts_row_type_narrow) input[type=text] {
    font-size: 13px;
    line-height: 21px;
  }
}
@media (max-width: 1023px) {
  .trx_addons_scroll_to_top {
    right: 1em;
  }

  .sc_item_title {
    letter-spacing: 1px;
  }

  .sc_layouts_column .sc_layouts_item {
    margin-right: 1.15em;
  }

  .sc_layouts_column_align_right .sc_layouts_item {
    margin-left: 1.15em;
    margin-right: 0;
  }

  .sc_layouts_menu_nav > li > a {
    padding: 0.7em;
  }

  .sc_layouts_menu_nav > li.menu-item-has-children > a {
    padding-right: 1.3em;
  }

  .sc_layouts_menu_nav > li.menu-item-has-children > a:after {
    right: 0.5em;
  }
}
@media (max-width: 767px) {
  .trx_addons_columns_wrap {
    margin-right: -20px;
  }

  .trx_addons_columns_wrap > [class*=trx_addons_column-] {
    padding-right: 20px;
  }

  .trx_addons_columns_wrap.columns_padding_bottom > [class*=trx_addons_column-] {
    padding-bottom: 20px;
  }

  .trx_addons_columns_wrap:not(.columns_fluid) > [class*=trx_addons_column-] {
    width: 50%;
  }

  .sc_item_title {
    letter-spacing: 0;
  }

  .sc_item_title + .sc_item_button {
    margin-top: 1.5em;
  }

  .hide_on_mobile,
.sc_layouts_hide_on_mobile {
    display: none !important;
  }

  .sc_layouts_menu_mobile_button {
    display: inline-block !important;
  }
}
@media (max-width: 479px) {
  .trx_addons_columns_wrap {
    margin-right: -20px;
  }

  .trx_addons_columns_wrap > [class*=trx_addons_column-] {
    padding-right: 20px;
  }

  .trx_addons_columns_wrap.columns_padding_bottom > [class*=trx_addons_column-] {
    padding-bottom: 20px;
  }

  .trx_addons_columns_wrap:not(.columns_fluid) > [class*=trx_addons_column-] {
    width: 100%;
  }

  .sc_layouts_row:not(.sc_layouts_row_type_narrow),
.sc_layouts_row:not(.sc_layouts_row_type_narrow) input[type=text] {
    font-size: 13px;
    line-height: 19px;
  }

  .sc_recent_news_style_news-excerpt .post_item .post_featured {
    float: none;
    padding-right: 0;
    margin-bottom: 1.5em;
    width: 100%;
  }

  .sc_recent_news_style_news-excerpt .post_item .post_body {
    float: none;
    width: 100%;
    padding: 0;
  }
}
@media (min-width: 2160px) {
  .sc_layouts_hide_on_wide {
    display: none !important;
  }
}
@media (min-width: 1680px) and (max-width: 2159px) {
  .sc_layouts_hide_on_desktop {
    display: none !important;
  }
}
@media (min-width: 1280px) and (max-width: 1679px) {
  .sc_layouts_hide_on_notebook {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .sc_layouts_hide_on_tablet {
    display: none !important;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__responsive.css ; media=all */
@media (max-width: 1679px) {
  .sp-template-event-blocks:not(.sp-template-event-logos-block) .sp-data-table td {
    padding: 17px 15px 15px !important;
  }

  .sp-template-event-blocks .sp-event-results {
    font-size: 58px !important;
  }

  .sp-template-event-blocks:not(.sp-template-event-logos-block) .sp-event-blocks .team-logo {
    width: 24%;
  }
}
@media (max-width: 1439px) {
  .content_wrap {
    width: 1100px;
  }

  .sc_layouts_row:not(.sc_layouts_row_type_narrow),
.sc_layouts_row:not(.sc_layouts_row_type_narrow) input[type=text] {
    font-size: 14px;
    line-height: inherit;
  }

  .sc_recent_news_style_news-excerpt .post_featured {
    max-width: 250px;
  }

  .sc_recent_news_style_news-excerpt .post_body {
    width: calc(100% - 250px);
    padding: 10px 10px 10px 30px;
  }

  .sc_recent_news_style_news-excerpt .post_item .post_title {
    font-size: 26px;
  }

  .sc_recent_news_style_news-excerpt .post_item .post_header + .post_content {
    margin-top: 10px;
  }

  .sc_blogger_default .sc_blogger_item_title {
    font-size: 30px;
  }

  .sc_blogger_default.sc_blogger_default_classic_meta .sc_blogger_item_excerpt {
    padding-right: 0;
  }

  .sc_blogger .sc_blogger_item_title + .sc_blogger_item_excerpt {
    margin-top: 10px;
  }

  .sc_layouts_row_type_normal .search_wrap .search_field {
    width: 16em;
  }

  .sp-template-event-blocks .sp-event-results {
    font-size: 50px !important;
  }

  .sp-template-event-blocks:not(.sp-template-event-logos-block) .sp-event-blocks .team-logo {
    margin-left: 5px;
    margin-right: 5px;
  }

  .sp-template-event-blocks .sp-event-results {
    padding: 0 !important;
  }

  .extra-countdown.top-style-2 .widget_title,
.extra-countdown.top-style-2 .sp-table-caption {
    max-width: 134px !important;
  }

  .extra-countdown.top-style-2 .widget_title {
    margin-top: 10px !important;
  }

  .sc_layouts_widgets .sp-template-countdown .sp-event-name > a:not(.team-logo) {
    margin-top: 40px;
  }

  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 1130px;
  }

  .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 1279px) {
  h1 {
    font-size: 3.7em;
    margin-bottom: 22px;
    letter-spacing: 1.5px;
  }

  h2 {
    font-size: 3em;
    margin-bottom: 20px;
    letter-spacing: 1.5px;
  }

  h3 {
    font-size: 2.4em;
    margin-bottom: 20px;
    letter-spacing: 1px;
  }

  h4 {
    font-size: 2em;
    margin-bottom: 5px;
    letter-spacing: 1px;
  }

  h5 {
    font-size: 1.6em;
    margin-bottom: 18px;
  }

  p,
ul {
    margin-bottom: 1.4em;
  }

  .sp-view-all-link {
    padding: 30px 0 0 0 !important;
  }

  .content_wrap {
    width: 964px !important;
  }

  .trx_addons_columns_wrap {
    margin-right: -30px;
  }

  .trx_addons_columns_wrap > [class*=trx_addons_column-] {
    padding-right: 30px;
  }

  .trx_addons_columns_wrap.columns_padding_bottom > [class*=trx_addons_column-] {
    padding-bottom: 30px;
  }

  form button,
input[type=submit],
.sc_button,
.sp-view-all-link > a {
    padding: 1em 3em;
  }

  table th,
table td {
    padding: 1rem;
  }

  .footer_wrap .sc_layouts_menu > ul > li {
    font-size: 15px;
  }

  .sc_layouts_row_type_normal .search_wrap .search_field {
    width: 10em;
  }

  .sc_layouts_menu_nav > li > a,
.sc_layouts_menu_nav > li.menu-item-has-children > a {
    padding: 0.85em 1em;
  }

  .sc_recent_news_style_news-excerpt .post_featured {
    max-width: 210px;
  }

  .sc_recent_news_style_news-excerpt .post_body {
    width: calc(100% - 210px);
    padding: 0 0 0 25px;
  }

  .sc_recent_news_style_news-excerpt .post_item .post_header + .post_content {
    margin-top: 7px;
  }

  .sc_blogger_default .sc_blogger_item_title {
    font-size: 28px;
  }

  .sc_item_title + .sc_item_button {
    margin-top: 1.6em;
  }

  .trx_addons_scroll_to_top {
    right: 1px;
    width: 2.2em;
    height: 2.2em;
    line-height: 2.2em;
  }

  .sc_layouts_widgets .widget {
    padding: 20px;
  }

  .sc_layouts_widgets .widget .widget_title,
.widget .widget_title {
    margin-bottom: 20px;
    padding: 16px 13px;
  }

  .sc_layouts_widgets .widget .widget_title {
    margin-left: -20px;
    margin-right: -20px;
    margin-top: -20px;
  }

  .sc_title.sc_title_accent {
    padding: 16px 13px;
  }

  .sc_layouts_row_type_normal {
    padding: 0.8em 0;
  }

  .sp-data-table td {
    padding: 15px 20px !important;
  }

  .med-title .sc_item_title {
    font-size: 60px;
  }

  .extra-event-block .sp-template-event-blocks:not(.sp-template-event-logos-block) .sp-data-table td {
    padding: 17px 0 15px !important;
  }

  .sp-template-event-blocks:not(.sp-template-event-logos-block) .sp-event-blocks .team-logo {
    margin: 10px 5px;
    width: 25%;
    height: 6.5em;
    max-width: 6.5em;
    margin-top: 21px;
  }

  .extra-countdown.top-style-2 .sp-template-countdown {
    width: 253px;
  }

  .extra-countdown .sp-countdown-wrapper .countdown.sp-countdown time span {
    font-size: 36px !important;
  }

  .extra-countdown.top-style-2 .sp-table-caption {
    margin-top: 14px !important;
  }

  .extra-countdown.top-style-2 .widget_title {
    margin-top: 4px !important;
  }

  .copyright-text {
    font-size: 15px;
  }

  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 994px;
  }

  .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 1023px) {
  h1 {
    font-size: 3em;
    margin-bottom: 15px;
    letter-spacing: 1.3px;
  }

  h2 {
    font-size: 2.6em;
    margin-bottom: 15px;
    letter-spacing: 1.3px;
  }

  h3 {
    font-size: 2.1em;
    margin-bottom: 14px;
    letter-spacing: 1px;
  }

  h4 {
    font-size: 1.8em;
    margin-bottom: 13px;
    letter-spacing: 1px;
  }

  h5 {
    font-size: 1.45em;
    margin-top: 30px;
    margin-bottom: 13px;
  }

  .content_wrap {
    width: 708px !important;
  }

  .sc_layouts_row_type_normal .search_wrap .search_field {
    width: 9em;
    padding: 0 40px 0 20px !important;
    height: 40px;
    line-height: 40px;
  }

  .sc_layouts_row_type_normal .search_wrap .search_submit {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }

  .sc_layouts_menu_nav > li > a,
.sc_layouts_menu_nav > li.menu-item-has-children > a {
    padding: 0.8em;
  }

  .sc_layouts_row_type_normal {
    padding: 0.6em 0;
  }

  form.mc4wp-form .mc4wp-form-fields input[type=submit] {
    min-width: 7.5em;
  }

  form.mc4wp-form .mc4wp-form-fields input[type=email] {
    padding: 0 8em 0 2em;
  }

  .copyright-text {
    font-size: 14px;
    line-height: 1.4em;
  }

  .footer_wrap .sc_layouts_menu > ul > li > a {
    padding: 0.5em 0.8em;
  }

  .footer_wrap .sc_layouts_menu > ul > li {
    font-size: 14px;
  }

  .sc_layouts_row_type_normal .sc_layouts_item_icon {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 13px;
  }

  .sc_layouts_row_type_normal .search_wrap .search_submit {
    width: 35px;
  }

  .sc_layouts_row_type_normal .search_wrap .search_field {
    width: 7em;
    padding: 0 30px 0 12px !important;
  }

  .extra-countdown.top-style-2 {
    max-width: 100%;
    padding: 20px 20px 15px 20px;
  }

  .extra-countdown.top-style-2 .sp-table-caption {
    margin-top: 0 !important;
  }

  .extra-countdown.top-style-2 .widget_title,
.extra-countdown.top-style-2 .sp-table-caption {
    max-width: 100% !important;
    text-align: center;
  }

  .extra-countdown.top-style-2 .sp-template-countdown {
    padding: 0;
    width: 100%;
  }

  .extra-countdown.top-style-2 .sp-template-countdown:before {
    display: none;
  }

  .extra-countdown .sp-countdown-wrapper .countdown.sp-countdown time span {
    padding-bottom: 0 !important;
    text-align: center !important;
  }

  .extra-countdown.top-style-2 .widget_title {
    margin-top: 0 !important;
  }

  .extra-event-block .sp-template-event-blocks .sp-event-results {
    font-size: 44px !important;
  }

  .sp-template-event-blocks:not(.sp-template-event-logos-block) .sp-event-blocks .team-logo {
    height: auto;
  }

  .sc_recent_news_style_news-excerpt .post_featured {
    max-width: 174px;
    float: left;
    margin: 0 20px 10px 0;
  }

  .sc_recent_news_style_news-excerpt .post_body {
    width: auto;
    padding: 0;
    display: inline;
    float: none;
  }

  .sc_blogger_default.sc_blogger_default_classic_meta_simple .sc_blogger_item_content {
    padding: 40px 20px 10px;
  }

  .sc_slider_controls .slider_controls_wrap .slider_pagination_wrap {
    height: 2.5em;
  }

  .sc_blogger_default.sc_blogger_default_classic_meta_simple .sc_blogger_slider .sc_blogger_item_title {
    font-size: 24px;
  }

  .sc_blogger_default .sc_blogger_item_title {
    font-size: 26px;
  }

  .sc_blogger_item_default.sc_blogger_item_image_position_top .sc_blogger_item_featured + .sc_blogger_item_content {
    margin-top: 1.7em;
  }

  .post_meta_item.post_categories a {
    padding: 3px 10px;
  }

  .post_meta .post_meta_item:after {
    margin: 0 0.3em;
  }

  .sc_layouts_widgets .sp-data-table th,
.sc_layouts_widgets .sp-data-table td {
    padding: 8px 5px !important;
  }

  .sc_layouts_widgets .sp-data-table th {
    font-size: 18px !important;
    letter-spacing: 1px !important;
  }

  .sc_layouts_widgets .sp-template-countdown .sp-event-name > a:not(.team-logo) {
    margin-top: 0;
    text-align: center;
    overflow: hidden;
    margin-bottom: -5px;
    padding-top: 11px;
    display: block;
    width: 100%;
  }

  .sc_layouts_widgets .sp-countdown time {
    margin-top: 15px;
  }

  .sc_layouts_widgets .sp-countdown-wrapper .countdown.sp-countdown time span {
    padding: 15px 0 5px 0 !important;
    font-size: 30px !important;
  }

  .sp-table-caption {
    margin: 0 0 25px !important;
    font-size: 38px !important;
  }

  .slider_container:not(.slider_multi):not(.slider_type_images),
.slider_container:not(.slider_multi):not(.slider_type_images) .slider-slide {
    min-height: 135px;
  }

  .med-title .sc_item_title {
    font-size: 44px;
  }

  .med-title .sc_item_title + .sc_item_button {
    margin-top: 1.3em;
  }

  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 738px;
  }

  .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 767px) {
  h1 {
    font-size: 2.6em;
    margin-top: 40px;
    margin-bottom: 15px;
    letter-spacing: 1.3px;
  }

  h2 {
    font-size: 2.3em;
    margin-bottom: 15px;
    letter-spacing: 1.3px;
  }

  h3 {
    font-size: 2em;
    margin-bottom: 14px;
    letter-spacing: 1px;
  }

  h4 {
    font-size: 1.6em;
    margin-bottom: 13px;
    letter-spacing: 1px;
  }

  h5 {
    font-size: 1.3em;
    margin-bottom: 13px;
  }

  .menu_mobile .sc_layouts_logo {
    margin: 2.4rem 0 0;
  }

  .content_wrap {
    width: 440px !important;
  }

  .trx_addons_columns_wrap {
    margin-right: -20px;
  }

  .trx_addons_columns_wrap > [class*=trx_addons_column-] {
    padding-right: 20px;
  }

  .trx_addons_columns_wrap.columns_padding_bottom > [class*=trx_addons_column-] {
    padding-bottom: 20px;
  }

  .trx_addons_columns_wrap:not(.columns_fluid) > [class*=trx_addons_column-] {
    width: 100%;
  }

  form button,
input[type=submit],
.sc_button,
.sp-view-all-link > a {
    padding: 0.84em 2.5em;
  }

  .sc_button {
    font-size: 13px;
  }

  .sp-view-all-link > a {
    font-size: 13px !important;
  }

  input[type=text],
input[type=email],
textarea,
form.mc4wp-form .mc4wp-form-fields input[type=email] {
    padding: 0.8em 1.5em;
  }

  .menu_mobile .menu_mobile_inner {
    overflow-y: auto;
    top: 0;
    bottom: 0;
  }

  .sc_item_title + .sc_item_button {
    margin-top: 1.2em;
  }

  .sp-data-table th {
    font-size: 18px !important;
    letter-spacing: 1px !important;
    padding: 8px 10px !important;
  }

  .sp-data-table td {
    padding: 8px 10px !important;
  }

  .sp-view-all-link {
    padding: 20px 0 0 0 !important;
  }

  .sc_blogger_default.sc_blogger_default_classic_meta_simple .sc_blogger_item_content {
    padding: 20px 20px 10px;
  }

  .sc_slider_controls .slider_controls_wrap .slider_pagination_wrap {
    height: 2em;
  }

  .sc_blogger_default.sc_blogger_default_classic_meta_simple .sc_blogger_slider .sc_blogger_item_title {
    font-size: 22px;
  }

  .sc_recent_news_style_news-excerpt .post_item + .post_item {
    margin-top: 20px;
  }

  .sc_recent_news_style_news-excerpt .post_item .post_title {
    font-size: 22px;
  }

  .sc_blogger_default .sc_blogger_item_title {
    font-size: 24px;
  }

  .sc_blogger .sc_blogger_item_title + .sc_blogger_item_excerpt {
    margin-top: 8px;
    line-height: 1.5em;
  }

  .sc_recent_news_style_news-excerpt .post_item .post_meta {
    margin: 5px 0 0.8em !important;
  }

  .sc_layouts_menu_nav > li > a,
.sc_layouts_menu_nav > li.menu-item-has-children > a {
    padding: 0.55em;
  }

  .sc_layouts_widgets .widget_area_inner > div + div,
.sc_layouts_widgets .widget_area_inner > div + aside,
.sc_layouts_widgets .widget_area_inner > aside + aside {
    margin-top: 20px;
  }

  .sc_item_title .sc_item_title_text br {
    display: none;
  }

  .sp-table-caption {
    margin: 0 0 20px !important;
    font-size: 36px !important;
  }

  .sp-template {
    margin-bottom: 20px !important;
  }

  .med-title .sc_item_title + .sc_item_button {
    margin-top: 1em;
  }

  .extra-event-block .elementor-row > .elementor-element + .elementor-element:before {
    left: 0;
    top: 0;
    width: 100%;
    height: 1px;
  }

  .extra-event-block .sp-template-event-blocks:not(.sp-template-event-logos-block) .sp-data-table td {
    padding: 15px 0 15px !important;
  }

  .sp-template-event-blocks:not(.sp-template-event-logos-block) .sp-event-blocks .team-logo {
    max-width: 6.8em;
    margin-top: 14px;
  }

  .sc_layouts_widgets .sp-countdown-wrapper .countdown.sp-countdown time span {
    padding: 18px 0 3px 0 !important;
    font-size: 38px !important;
  }

  .sc_layouts_widgets .sp-template-countdown .sp-event-name > a:not(.team-logo) {
    margin-top: 60px;
  }

  .extra-countdown .sp-countdown-wrapper .countdown.sp-countdown time span {
    font-size: 44px !important;
  }

  .extra-countdown.top-style-2 .sp-template-countdown {
    padding: 5px 0 0 0;
  }

  .extra-countdown.top-style-2 {
    padding: 22px 20px 17px 20px;
  }

  .extra-countdown.top-style-2 .sp-table-caption {
    font-size: 22px !important;
  }

  body div .sc_layouts_column .copyright-text {
    text-align: center;
    display: block;
    width: 100% !important;
  }

  .sc_item_title {
    letter-spacing: 1px;
  }

  .sp-data-table thead th {
    position: relative;
  }

  .sc_layouts_widgets .sp-template-countdown .sp-event-name > a:not(.team-logo) {
    width: auto;
    margin-top: 50px;
  }

  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 460px;
  }

  .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 479px) {
  p,
ul {
    margin-bottom: 1.1em;
  }

  table th,
table td {
    padding: 0.5rem;
  }

  body {
    letter-spacing: 0;
    line-height: 1.6em;
  }

  h1 {
    font-size: 2.2em;
    margin-bottom: 12px;
    letter-spacing: 1px;
    line-height: 0.93em;
  }

  h2 {
    font-size: 2em;
    margin-bottom: 11px;
    letter-spacing: 1px;
    line-height: 0.96em;
  }

  h3 {
    font-size: 1.8em;
    margin-bottom: 10px;
    letter-spacing: 1px;
    line-height: 0.91em;
  }

  h4 {
    font-size: 1.6em;
    margin-bottom: 9px;
    letter-spacing: 1px;
    line-height: 1.06em;
  }

  h5 {
    font-size: 1.3em;
    margin-bottom: 8px;
    line-height: 1.06em;
  }

  .menu_mobile .sc_layouts_logo {
    margin: 2rem 0 0;
  }

  .menu_mobile_close {
    width: 3em;
    height: 3em;
    line-height: 3em;
  }

  .content_wrap {
    width: 280px !important;
  }

  .trx_addons_columns_wrap {
    margin-right: -20px;
  }

  .trx_addons_columns_wrap > [class*=trx_addons_column-] {
    padding-right: 20px;
  }

  .trx_addons_columns_wrap.columns_padding_bottom > [class*=trx_addons_column-] {
    padding-bottom: 20px;
  }

  .trx_addons_columns_wrap:not(.columns_fluid) > [class*=trx_addons_column-] {
    width: 100%;
  }

  .menu_mobile .menu_mobile_nav_area > ul > li > a {
    font-size: 18px;
    line-height: 1.5em;
  }

  .menu_mobile .menu_mobile_nav_area li > a {
    font-size: 14px;
    line-height: 1.5em;
    width: 280px;
  }

  .menu_mobile .menu_mobile_nav_area li li .open_child_menu {
    top: -1px;
  }

  .menu_mobile_close:before,
.menu_mobile_close:after {
    width: 35%;
  }

  form.mc4wp-form .mc4wp-form-fields input[type=email] {
    height: 40px;
    line-height: 40px;
  }

  form.mc4wp-form .mc4wp-form-fields input[type=submit] {
    height: 40px;
    line-height: 40px;
    font-size: 13px;
  }

  .sp-view-all-link > a,
form button,
input[type=submit] {
    font-size: 13px;
  }

  .sc_layouts_row_type_normal .sc_layouts_item_icon {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }

  .sc_layouts_menu_mobile_button .sc_layouts_item_icon {
    font-size: 25px !important;
  }

  .extra-countdown.top-style-2 {
    padding: 20px 15px 15px 15px;
  }

  .sp-template-event-blocks:not(.sp-template-event-logos-block) .sp-data-table td {
    padding: 15px 10px 13px !important;
  }

  .sp-template-event-blocks .sp-event-results {
    font-size: 40px !important;
  }

  .sp-template-event-blocks .sp-event-date {
    margin: 8px !important;
  }

  .sp-template-event-blocks:not(.sp-template-event-logos-block) .sp-event-blocks .team-logo {
    margin-top: 20px;
  }

  .sp-data-table th {
    font-size: 16px !important;
    letter-spacing: 1px !important;
    padding: 6px 8px !important;
  }

  .sp-data-table td {
    padding: 6px 8px !important;
  }

  .med-title .sc_item_title {
    font-size: 40px;
  }

  .sc_recent_news_style_news-excerpt .post_featured {
    max-width: 100%;
    margin: 0;
  }

  .sc_recent_news_style_news-excerpt .post_item .post_featured {
    margin-bottom: 1.2em;
    vertical-align: bottom;
  }

  .sc_blogger_default.sc_blogger_default_classic_meta_simple .sc_blogger_item_content {
    padding: 20px 15px 10px;
  }

  .sc_slider_controls.slider_pagination_style_bullets .slider_pagination_bullet {
    width: 20px;
  }

  .sc_layouts_widgets .sp-template-countdown .sp-event-name > a:not(.team-logo) {
    margin-top: 23px;
    font-size: 12px !important;
  }

  .sc_item_title {
    letter-spacing: 1px;
  }

  .sc_layouts_widgets .sp-template-countdown .sp-event-name > a:not(.team-logo) {
    margin-top: 23px;
    font-size: 12px !important;
  }

  .sp-table-caption {
    margin: 0 0 16px !important;
    font-size: 32px !important;
  }

  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 300px;
  }

  .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
    padding-left: 10px;
    padding-right: 10px;
  }
}
/*! CSS Used from: Embedded */
.elementor-1578 .elementor-element.elementor-element-3e313d6d {
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.08);
}

.elementor-1578 .elementor-element.elementor-element-3eea3720 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-1578 .elementor-element.elementor-element-58da9b79 .logo_image {
  max-height: 80px;
}

.elementor-1578 .elementor-element.elementor-element-39a51b3f {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

@media (max-width: 767px) {
  .elementor-1578 .elementor-element.elementor-element-3eea3720 {
    width: 70%;
  }

  .elementor-1578 .elementor-element.elementor-element-39a51b3f {
    width: 30%;
  }
}
@media (min-width: 768px) {
  .elementor-1578 .elementor-element.elementor-element-3eea3720 {
    width: 32%;
  }

  .elementor-1578 .elementor-element.elementor-element-39a51b3f {
    width: 68%;
  }
}
/*! CSS Used from: Embedded */
.elementor-872 .elementor-element.elementor-element-1459491d {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-872 .elementor-element.elementor-element-49b79c89 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-872 .elementor-element.elementor-element-4d806f3c .elementor-spacer-inner {
  height: 28px;
}

.elementor-872 .elementor-element.elementor-element-61c0e418 .elementor-spacer-inner {
  height: 25px;
}

.elementor-872 .elementor-element.elementor-element-5f99db4a .elementor-divider-separator {
  border-top-style: solid;
  border-top-width: 2px;
  width: 100%;
}

.elementor-872 .elementor-element.elementor-element-5f99db4a .elementor-divider {
  padding-top: 15px;
  padding-bottom: 15px;
}

.elementor-872 .elementor-element.elementor-element-6743357d .elementor-spacer-inner {
  height: 20px;
}

.elementor-872 .elementor-element.elementor-element-a3d305d {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-872 .elementor-element.elementor-element-7e68f05 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-872 .elementor-element.elementor-element-bd100bb {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-872 .elementor-element.elementor-element-e6cb6af {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-872 .elementor-element.elementor-element-77ff75d0 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-872 .elementor-element.elementor-element-39c2827b .elementor-spacer-inner {
  height: 30px;
}

.elementor-872 .elementor-element.elementor-element-73075dae .elementor-divider-separator {
  border-top-style: solid;
  border-top-width: 2px;
  width: 100%;
}

.elementor-872 .elementor-element.elementor-element-73075dae .elementor-divider {
  padding-top: 0px;
  padding-bottom: 0px;
}

.elementor-872 .elementor-element.elementor-element-6a425bd6 .elementor-spacer-inner {
  height: 13px;
}

.elementor-872 .elementor-element.elementor-element-7cc27e17 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-872 .elementor-element.elementor-element-497bd034 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-872 .elementor-element.elementor-element-2cd3ae93 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-872 .elementor-element.elementor-element-6357fdf2 .elementor-spacer-inner {
  height: 13px;
}

@media (min-width: 768px) {
  .elementor-872 .elementor-element.elementor-element-7e68f05 {
    width: 22.018%;
  }

  .elementor-872 .elementor-element.elementor-element-bd100bb {
    width: 40.979%;
  }

  .elementor-872 .elementor-element.elementor-element-e6cb6af {
    width: 36.999%;
  }

  .elementor-872 .elementor-element.elementor-element-7cc27e17 {
    width: 60.092%;
  }

  .elementor-872 .elementor-element.elementor-element-497bd034 {
    width: 39.908%;
  }
}
@media (max-width: 1024px) {
  .elementor-872 .elementor-element.elementor-element-61c0e418 .elementor-spacer-inner {
    height: 20px;
  }
}
@media (max-width: 767px) {
  .elementor-872 .elementor-element.elementor-element-4d806f3c .elementor-spacer-inner {
    height: 18px;
  }

  .elementor-872 .elementor-element.elementor-element-61c0e418 .elementor-spacer-inner {
    height: 10px;
  }

  .elementor-872 .elementor-element.elementor-element-6743357d .elementor-spacer-inner {
    height: 18px;
  }

  .elementor-872 .elementor-element.elementor-element-39c2827b .elementor-spacer-inner {
    height: 25px;
  }

  .elementor-872 .elementor-element.elementor-element-6a425bd6 .elementor-spacer-inner {
    height: 12px;
  }

  .elementor-872 .elementor-element.elementor-element-6357fdf2 .elementor-spacer-inner {
    height: 12px;
  }
}
/*! CSS Used from: Embedded */
.fcunited_inline_430234963 {
  background-color: #081224;
}

.trx_addons_inline_388837223 img {
  max-height: 80px;
}

/*! CSS Used from: Embedded */
#MagiCSS-bookmarklet,
html > body #MagiCSS-bookmarklet {
  display: block;
}

/*! CSS Used from: Embedded */
#MagiCSS-bookmarklet .cancelDragHandle {
  cursor: default;
}

/*! CSS Used keyframes */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*! CSS Used from: http://fc-united.axiomthemes.com/wp-content/plugins/trx_addons/css/__styles.css ; media=all */
@media all {
  .breadcrumbs {
    font-size: 13px;
    line-height: 19px;
  }

  .breadcrumbs .breadcrumbs_item {
    display: inline-block;
    vertical-align: top;
  }

  .breadcrumbs .breadcrumbs_delimiter {
    display: inline-block;
    position: relative;
    margin: 0 0.45em;
    vertical-align: top;
    text-align: center;
  }

  .breadcrumbs .breadcrumbs_delimiter:before {
    content: "/";
  }

  .elementor-container:not(.elementor-column-gap-no) > .elementor-row > .elementor-column > .elementor-element-populated {
    padding-top: 0;
    padding-bottom: 0;
  }

  .elementor-section {
    position: relative;
  }

  .sc_layouts_item {
    max-width: 100%;
  }

  .sc_inner_width_none > .elementor-column-wrap {
    width: 100%;
  }

  .sc_layouts_title_caption {
    margin: 0 auto;
  }

  .sc_layouts_title_title > .sc_layouts_title_caption {
    float: none;
  }

  .sc_layouts_title_breadcrumbs {
    margin-top: 0.5em;
  }

  .sc_layouts_title.with_content {
    position: relative;
    min-height: 0;
  }

  .sc_layouts_title .sc_layouts_title_content {
    position: static;
  }
}
/*! CSS Used from: http://fc-united.axiomthemes.com/wp-content/plugins/trx_addons/css/trx_addons.animation.css ; media=all */
@media all {
  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: visible;
  }

  .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }
}
/*! CSS Used from: http://fc-united.axiomthemes.com/wp-content/plugins/elementor/assets/lib/animations/animations.min.css?ver=2.5.15 ; media=all */
@media all {
  .fadeIn {
    animation-name: fadeIn;
  }
}
/*! CSS Used from: http://fc-united.axiomthemes.com/wp-content/plugins/elementor/assets/css/frontend.min.css?ver=2.5.15 ; media=all */
@media all {
  .elementor *,
.elementor :after,
.elementor :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .elementor a {
    -webkit-box-shadow: none;
    box-shadow: none;
    text-decoration: none;
  }

  .elementor-section {
    position: relative;
  }

  .elementor-section .elementor-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative;
  }

  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 1140px;
  }

  .elementor-row {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}
@media (max-width: 1024px) {
  .elementor-row {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
@media all {
  .elementor-column-wrap {
    width: 100%;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}
@media all {
  .elementor-widget-wrap {
    position: relative;
    width: 100%;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
}
@media all {
  .elementor-widget-wrap > .elementor-element {
    width: 100%;
  }
}
@media all {
  .elementor-widget {
    position: relative;
  }
}
@media all {
  .elementor-widget:not(:last-child) {
    margin-bottom: 20px;
  }
}
@media all {
  .elementor-column {
    position: relative;
    min-height: 1px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}
@media all {
  .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
    padding: 15px;
  }
}
@media (min-width: 768px) {
  .elementor-column.elementor-col-100 {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .elementor-column {
    width: 100%;
  }
}
@media all {
  .elementor-element .elementor-widget-container {
    -webkit-transition: background 0.3s, border 0.3s, -webkit-border-radius 0.3s, -webkit-box-shadow 0.3s;
    transition: background 0.3s, border 0.3s, -webkit-border-radius 0.3s, -webkit-box-shadow 0.3s;
    -o-transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s, -webkit-border-radius 0.3s, -webkit-box-shadow 0.3s;
  }
}
@media all {
  .animated {
    -webkit-animation-duration: 1.25s;
    animation-duration: 1.25s;
  }
}
/*! CSS Used from: Embedded */
.elementor-section.elementor-section-boxed > .elementor-container {
  max-width: 1308px;
}

.elementor-widget:not(:last-child) {
  margin-bottom: 0px;
}

@media all {
  div,
span,
h1,
a,
section {
    font-family: inherit;
    font-size: 100%;
    line-height: inherit;
    font-weight: inherit;
    font-style: inherit;
    border: 0;
    outline: 0;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
  }

  section {
    display: block;
  }

  a {
    text-decoration: none;
    background: 0 0;
  }

  a,
a:hover,
a:focus,
a:active {
    outline: 0;
  }

  a {
    -webkit-transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    -ms-transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  }

  :active,
:focus {
    outline: 0;
  }

  .sc_layouts_title_caption {
    font-size: 72px;
  }

  .breadcrumbs {
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
  }

  .breadcrumbs .breadcrumbs_delimiter {
    margin: 0 0.15em;
  }
}
/*! CSS Used from: http://fc-united.axiomthemes.com/wp-content/themes/fcunited/css/__plugins.css ; media=all */
@media all {
  .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
    padding: 15px;
  }
}
/*! CSS Used from: http://fc-united.axiomthemes.com/wp-content/themes/fcunited/css/__custom.css ; media=all */
@media all {
  h1 {
    font-family: Roboto, sans-serif;
    font-size: 4.286em;
    font-weight: 700;
    font-style: normal;
    line-height: 0.93em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 2.1px;
    margin-top: 1.69em;
    margin-bottom: 0.63em;
  }
}
/*! CSS Used from: http://fc-united.axiomthemes.com/wp-content/themes/fcunited/css/__colors-default.css ; media=all */
@media all {
  .scheme_default h1 {
    color: #262f3e;
  }

  .scheme_default a {
    color: red;
  }

  .scheme_default a:hover {
    color: #081324;
  }

  .scheme_default .sc_layouts_title .sc_layouts_title_breadcrumbs,
.scheme_default .sc_layouts_title .sc_layouts_title_breadcrumbs a {
    color: #262f3e;
  }

  .scheme_default .sc_layouts_title .sc_layouts_title_breadcrumbs a:hover {
    color: #081324;
  }
}
/*! CSS Used from: http://fc-united.axiomthemes.com/wp-content/themes/fcunited/css/__colors-dark.css ; media=all */
@media all {
  .scheme_dark {
    color: #848992;
  }

  .scheme_dark h1 {
    color: #fff;
  }

  .scheme_dark a {
    color: red;
  }

  .scheme_dark a:hover {
    color: #fff;
  }

  .scheme_dark .sc_layouts_title .sc_layouts_title_breadcrumbs,
.scheme_dark .sc_layouts_title .sc_layouts_title_breadcrumbs a {
    color: #fff;
  }

  .scheme_dark .sc_layouts_title .sc_layouts_title_breadcrumbs a:hover {
    color: #fff;
  }
}
/*! CSS Used from: http://fc-united.axiomthemes.com/wp-content/plugins/trx_addons/css/__responsive.css ; media=all */
@media (max-width: 1023px) {
  .sc_layouts_title_title {
    font-size: 0.8em;
  }
}
@media (max-width: 767px) {
  .sc_layouts_hide_on_mobile {
    display: none !important;
  }
}
@media (max-width: 479px) {
  .sc_layouts_title_title {
    font-size: 0.7em;
  }
}
@media (min-width: 2160px) {
  .sc_layouts_hide_on_wide {
    display: none !important;
  }
}
@media (min-width: 1680px) and (max-width: 2159px) {
  .sc_layouts_hide_on_desktop {
    display: none !important;
  }
}
@media (min-width: 1280px) and (max-width: 1679px) {
  .sc_layouts_hide_on_notebook {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .sc_layouts_hide_on_tablet {
    display: none !important;
  }
}
/*! CSS Used from: http://fc-united.axiomthemes.com/wp-content/themes/fcunited/css/__responsive.css ; media=all */
@media (max-width: 1439px) {
  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 1130px;
  }

  .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 1279px) {
  .sc_layouts_title_caption {
    font-size: 4rem;
  }

  h1 {
    font-size: 3.7em;
    margin-top: 55px;
    margin-bottom: 22px;
    letter-spacing: 1.5px;
  }

  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 994px;
  }

  .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 1023px) {
  .sc_layouts_title_caption {
    font-size: 3rem;
  }

  h1 {
    font-size: 3em;
    margin-top: 45px;
    margin-bottom: 15px;
    letter-spacing: 1.3px;
  }

  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 738px;
  }

  .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 767px) {
  .sc_layouts_title_caption {
    font-size: 2.6rem;
  }

  h1 {
    font-size: 2.6em;
    margin-top: 40px;
    margin-bottom: 15px;
    letter-spacing: 1.3px;
  }

  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 460px;
  }

  .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 479px) {
  h1 {
    hyphens: manual;
  }

  .sc_layouts_title_caption {
    font-size: 2.2rem;
  }

  h1 {
    font-size: 2.2em;
    margin-top: 28px;
    margin-bottom: 12px;
    letter-spacing: 1px;
    line-height: 0.93em;
  }

  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 300px;
  }

  .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
    padding-left: 10px;
    padding-right: 10px;
  }
}
/*! CSS Used from: Embedded */
.elementor-6 .elementor-element.elementor-element-b70eb50:not(.elementor-motion-effects-element-type-background) {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #091223;
}

.elementor-6 .elementor-element.elementor-element-b70eb50 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-6 .elementor-element.elementor-element-ed1b682 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-6 .elementor-element.elementor-element-6850b4b .elementor-spacer-inner {
  height: 285px;
}

.elementor-6 .elementor-element.elementor-element-b76221f .elementor-spacer-inner {
  height: 180px;
}

.elementor-6 .elementor-element.elementor-element-612f306 .sc_layouts_title {
  min-height: 0px;
}

.elementor-6 .elementor-element.elementor-element-01551c2 .elementor-spacer-inner {
  height: 61px;
}

@media (max-width: 1024px) {
  .elementor-6 .elementor-element.elementor-element-b76221f .elementor-spacer-inner {
    height: 120px;
  }

  .elementor-6 .elementor-element.elementor-element-01551c2 .elementor-spacer-inner {
    height: 50px;
  }
}
@media (max-width: 767px) {
  .elementor-6 .elementor-element.elementor-element-b76221f .elementor-spacer-inner {
    height: 65px;
  }

  .elementor-6 .elementor-element.elementor-element-01551c2 .elementor-spacer-inner {
    height: 30px;
  }
}
/*! CSS Used keyframes */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/sportspress/assets/css/sportspress.css?ver=2.6.14 ; media=all */
@media all {
  .sp-header {
    position: relative;
    overflow: visible;
    z-index: 10000;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/trx_addons/css/font-icons/css/trx_addons_icons.css ; media=all */
@media all {
  [class^=trx_addons_icon-]:before,
[class*=" trx_addons_icon-"]:before {
    font-family: "trx_addons_icons";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: 0.2em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .trx_addons_icon-facebook:before {
    content: "\e815";
  }

  .trx_addons_icon-gplus:before {
    content: "\e81c";
  }

  .trx_addons_icon-twitter:before {
    content: "\e840";
  }

  .trx_addons_icon-up:before {
    content: "\e85a";
  }

  .trx_addons_icon-search:before {
    content: "\e898";
  }

  .trx_addons_icon-menu:before {
    content: "\e8bc";
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/trx_addons/js/magnific/magnific-popup.min.css ; media=all */
@media all {
  button::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/trx_addons/css/__styles.css ; media=all */
@media all {
  .trx_addons_scroll_to_top {
    display: block;
    background: #fff;
    cursor: pointer;
    opacity: 0;
    position: fixed;
    z-index: 100001;
    bottom: -4em;
    right: 2em;
    width: 3em;
    height: 3em;
    line-height: 3em;
    text-align: center;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .trx_addons_scroll_to_top:before {
    margin: 0 !important;
  }

  .breadcrumbs {
    font-size: 13px;
    line-height: 19px;
  }

  .breadcrumbs .breadcrumbs_item {
    display: inline-block;
    vertical-align: top;
  }

  .breadcrumbs .breadcrumbs_delimiter {
    display: inline-block;
    position: relative;
    margin: 0 0.45em;
    vertical-align: top;
    text-align: center;
  }

  .breadcrumbs .breadcrumbs_delimiter:before {
    content: "/";
  }

  [class^=trx_addons_icon-]:before,
[class*=" trx_addons_icon-"]:before {
    margin: 0;
    width: auto;
    font-size: inherit;
    line-height: inherit !important;
    font-weight: inherit;
  }

  .post_meta_item {
    margin-left: 0.5em;
    white-space: nowrap;
  }

  .post_meta_item:before {
    vertical-align: top;
    line-height: inherit;
    margin: 0 0.3em 0 0;
  }

  .socials_share {
    position: relative;
    vertical-align: top;
  }

  .socials_share.socials_wrap {
    margin: 0;
  }

  .widget_area .post_item,
aside .post_item {
    overflow: hidden;
    text-align: left;
    margin-bottom: 0;
    background-color: transparent !important;
    position: relative;
  }

  aside .post_item + .post_item {
    margin-top: 1.6em;
  }

  .widget_area .post_item .post_content,
aside .post_item .post_content {
    overflow: hidden;
  }

  .widget_area .post_item .post_thumb,
aside .post_item .post_thumb {
    float: left;
    width: 5em;
    height: 5em;
    margin: 0 1em 4px 0;
    overflow: hidden;
  }

  .widget_area .post_item .post_thumb img,
aside .post_item .post_thumb img {
    width: 100%;
  }

  .widget_area .post_item .post_title,
aside .post_item .post_title {
    margin: -2px 0 4px 0;
    text-align: left;
    line-height: 1.25em;
    font-weight: 400;
  }

  .widget_area .post_item .post_info,
aside .post_item .post_info {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 0;
  }

  .widget_area .post_item .post_info .post_info_item + .post_info_item:before,
aside .post_item .post_info .post_info_item + .post_info_item:before {
    content: "";
    margin: 0 0 0 5px;
  }

  .clearfix:after {
    content: " ";
    clear: both;
    width: 100%;
    height: 0;
    display: block;
  }

  .sc_item_title {
    margin: 0;
  }

  .sc_item_title {
    line-height: 1.2em;
  }

  .elementor-container:not(.elementor-column-gap-no) > .elementor-row > .elementor-column > .elementor-element-populated {
    padding-top: 0;
    padding-bottom: 0;
  }

  .elementor-section {
    position: relative;
  }

  .elementor-widget-text-editor:last-child,
.elementor-widget-text-editor p:last-child {
    margin-bottom: 0;
  }

  .sc_layouts_row {
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .sc_layouts_row .sc_layouts_item {
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
  }

  .sc_layouts_item {
    max-width: 100%;
  }

  .sc_layouts_column .sc_layouts_item {
    display: inline-block;
    vertical-align: middle;
    margin-right: 1.5em;
    width: auto !important;
  }

  .sc_layouts_column .sc_layouts_item:last-child {
    margin-right: 0 !important;
  }

  .sc_layouts_column_align_right .sc_layouts_item {
    margin-left: 1.5em;
    margin-right: 0;
  }

  .sc_layouts_column_align_right .sc_layouts_item:first-child {
    margin-left: 0 !important;
  }

  .sc_layouts_column_align_left {
    text-align: left;
  }

  .sc_layouts_column_align_right {
    text-align: right;
  }

  .sc_layouts_column_align_left > .elementor-column-wrap > .elementor-widget-wrap {
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .sc_layouts_column_align_right > .elementor-column-wrap > .elementor-widget-wrap {
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .elementor-section-content-middle .elementor-widget-wrap {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .sc_layouts_iconed_text {
    white-space: nowrap;
  }

  .sc_layouts_item_link {
    display: block;
  }

  .sc_layouts_item_icon {
    font-size: 2.25em;
    line-height: 1.1em;
    display: inline-block;
    vertical-align: middle;
    -webkit-transition: color 0.3s ease;
    -ms-transition: color 0.3s ease;
    transition: color 0.3s ease;
  }

  .sc_layouts_row_type_normal .sc_layouts_item {
    letter-spacing: 0;
  }

  .sc_layouts_row_type_normal {
    padding: 2em 0;
  }

  .sc_layouts_row_fixed_placeholder {
    display: none;
  }

  .sc_inner_width_none > .elementor-column-wrap {
    width: 100%;
  }

  .socials_wrap {
    display: block;
    vertical-align: middle;
    white-space: nowrap;
    margin: -8px 0 0 0;
  }

  .socials_wrap .social_item {
    display: inline-block;
    line-height: inherit;
    vertical-align: top;
    text-align: center;
    margin: 8px 8px 0 0;
  }

  .socials_wrap .social_item:last-child {
    margin-right: 0 !important;
  }

  .socials_wrap .social_item .social_icon {
    position: relative;
    display: block;
    background-size: 100%;
    background-position: top center;
    overflow: hidden;
    width: 3em;
    height: 3em;
    line-height: 3em;
    text-align: center;
    -webkit-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
  }

  .socials_wrap .social_item .social_icon {
    -webkit-transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    -ms-transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  }

  .sc_title {
    position: relative;
  }

  .widget_banner .image_wrap {
    overflow: hidden;
    display: block;
    margin-bottom: 1em;
  }

  .widget_banner .image_wrap img {
    width: 100%;
    height: auto;
  }

  .widget_twitter .widget_content ul {
    padding-left: 2em;
    list-style-type: none;
  }

  .widget_twitter .widget_content ul > li {
    position: relative;
    text-align: left;
    color: #bbb;
  }

  .widget_twitter .widget_content ul > li + li {
    margin-top: 1.5em;
  }

  .widget_twitter .widget_content ul > li:before {
    content: "\e840";
    font-family: "trx_addons_icons";
    font-size: 1.2em;
    font-style: normal;
    display: inline-block;
    background-color: transparent !important;
    position: absolute;
    z-index: 1;
    top: 0;
    left: -1.5em;
    width: 1em;
    height: 1em;
    line-height: inherit;
  }

  .widget_twitter .widget_content ul > li a {
    font-weight: normal;
  }

  .sc_layouts_logo img {
    max-height: 6em;
    width: auto;
  }

  .sc_layouts_menu_mobile_button {
    display: none !important;
  }

  .sc_layouts_menu_mobile_button .sc_layouts_item_icon,
.sc_layouts_menu_mobile_button_burger .sc_layouts_item_icon {
    font-size: 2em;
  }

  .sc_layouts_menu_mobile_button .sc_layouts_item_icon:before,
.sc_layouts_menu_mobile_button_burger .sc_layouts_item_icon:before {
    line-height: 1.2em;
  }

  .sc_layouts_menu {
    position: relative;
    z-index: 1000;
    overflow: hidden;
    visibility: hidden;
    max-height: 3em;
  }

  .sc_layouts_menu.inited {
    overflow: visible;
    visibility: visible;
    max-height: none;
  }

  .sc_layouts_menu_nav,
.sc_layouts_menu_nav ul {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style-type: none !important;
  }

  .sc_layouts_menu_nav:after,
.sc_layouts_menu_nav ul:after {
    content: " ";
    clear: both;
    width: 100%;
    height: 0;
    display: block;
  }

  .sc_layouts_menu_nav li {
    margin: 0;
    position: relative;
  }

  .sc_layouts_menu_nav li > a {
    display: block;
    position: relative;
  }

  .sc_layouts_menu_nav {
    text-align: left;
    vertical-align: middle;
    padding-left: 0 !important;
  }

  .sc_layouts_menu_nav > li {
    position: relative;
    float: left;
    z-index: 20;
  }

  .sc_layouts_menu_nav > li + li {
    z-index: 19;
  }

  .sc_layouts_menu_nav > li + li + li {
    z-index: 18;
  }

  .sc_layouts_menu_nav > li + li + li + li {
    z-index: 17;
  }

  .sc_layouts_menu_nav > li + li + li + li + li {
    z-index: 16;
  }

  .sc_layouts_menu_nav > li + li + li + li + li + li {
    z-index: 15;
  }

  .sc_layouts_menu_nav > li + li + li + li + li + li + li {
    z-index: 14;
  }

  .sc_layouts_menu_nav > li > a {
    padding: 1em;
    position: relative;
    color: #333;
    -webkit-transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    -ms-transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  }

  .sc_layouts_menu_nav > li > a:hover {
    color: #efa758;
  }

  .sc_layouts_menu_nav > li.menu-item-has-children > a {
    padding-right: 2em;
  }

  .sc_layouts_menu_nav > li.menu-item-has-children > a:after {
    content: "\e882";
    font-family: "trx_addons_icons";
    font-weight: normal;
    display: inline-block;
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 1em;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: color 0.3s ease;
    -ms-transition: color 0.3s ease;
    transition: color 0.3s ease;
  }

  .sc_layouts_menu_nav li li.menu-item-has-children > a:after {
    content: "\e884";
    font-family: "trx_addons_icons";
    font-weight: normal;
    position: absolute;
    z-index: 1;
    top: 0.65em;
    right: 0.75em;
    -webkit-transition: color 0.3s ease;
    -ms-transition: color 0.3s ease;
    transition: color 0.3s ease;
  }

  .sc_layouts_menu_nav > li ul {
    position: absolute;
    display: none;
    z-index: 10000;
    width: 13em;
    padding: 1.4em 0;
    text-align: left;
    background-color: #f0f0f0;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.2);
  }

  .sc_layouts_menu_nav > li li > a {
    padding: 0.6em 1.5em;
    color: #333;
  }

  .sc_layouts_menu_nav > li li > a:hover {
    color: #efa758;
  }

  .sc_layouts_menu_nav > li > ul {
    top: 4.2em;
    left: 0;
  }

  .sc_layouts_menu_nav > li > ul ul {
    left: 13em;
    top: -1.4em;
    margin: 0 0 0 2px;
    background-color: #f0f0f0;
  }

  .sc_layouts_menu_mobile_button_burger {
    position: relative;
  }

  .search_wrap,
.search_wrap .search_form {
    position: relative;
  }

  .search_wrap .search_field {
    width: 6em;
    padding: 0 0 0 1.25em !important;
    vertical-align: middle;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .search_wrap .search_submit {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    background-color: transparent;
    padding: 0;
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
  }

  .search_wrap .search_submit:before {
    margin: 0;
  }

  .sc_layouts_row_type_normal .search_wrap .search_field {
    font-size: 11px;
    text-transform: uppercase;
    padding: 0 1.5em 0 3em !important;
    width: 15em;
    height: 4.7727em;
    line-height: 4.7727em;
    -webkit-border-radius: 3em;
    -ms-border-radius: 3em;
    border-radius: 3em;
  }

  .sc_layouts_row_type_normal .search_wrap .search_submit {
    display: block;
    font-size: 11px;
    text-align: center;
    width: 1em;
    height: 4.7727em;
    line-height: 4.7727em;
    position: absolute;
    top: 0;
    right: auto;
    bottom: auto;
    left: 1em;
  }

  .sc_layouts_row_type_normal .search_wrap .search_submit:before {
    font-size: 13px;
  }

  .sc_layouts_title_caption {
    margin: 0 auto;
  }

  .sc_layouts_title_title > .sc_layouts_title_caption {
    float: none;
  }

  .sc_layouts_title_breadcrumbs {
    margin-top: 0.5em;
  }

  .sc_layouts_title.with_content {
    position: relative;
    min-height: 0em;
  }

  .sc_layouts_title .sc_layouts_title_content {
    position: static;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/trx_addons/css/trx_addons.animation.css ; media=all */
@media all {
  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: visible;
  }

  .animated.fast {
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
  }

  .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }

  .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/woocommerce/assets/css/woocommerce.css?ver=3.6.2 ; media=all */
@media all {
  .screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/elementor/assets/lib/animations/animations.min.css?ver=2.5.15 ; media=all */
@media all {
  .fadeIn {
    animation-name: fadeIn;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/elementor/assets/css/frontend.min.css?ver=2.5.15 ; media=all */
@media all {
  .elementor-screen-only,
.screen-reader-text {
    position: absolute;
    top: -10000em;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .elementor-clearfix:after {
    content: "";
    display: block;
    clear: both;
    width: 0;
    height: 0;
  }

  .elementor {
    -webkit-hyphens: manual;
    -ms-hyphens: manual;
    hyphens: manual;
  }

  .elementor *,
.elementor :after,
.elementor :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .elementor a {
    -webkit-box-shadow: none;
    box-shadow: none;
    text-decoration: none;
  }

  .elementor img {
    height: auto;
    max-width: 100%;
    border: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .elementor-invisible {
    visibility: hidden;
  }

  .elementor-section {
    position: relative;
  }

  .elementor-section .elementor-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative;
  }

  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 1140px;
  }

  .elementor-section-content-middle > .elementor-container > .elementor-row > .elementor-column > .elementor-column-wrap {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .elementor-row {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}
@media (max-width: 1024px) {
  .elementor-row {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
@media all {
  .elementor-column-wrap {
    width: 100%;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}
@media all {
  .elementor-widget-wrap {
    position: relative;
    width: 100%;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
}
@media all {
  .elementor-widget-wrap > .elementor-element {
    width: 100%;
  }
}
@media all {
  .elementor-widget {
    position: relative;
  }
}
@media all {
  .elementor-widget:not(:last-child) {
    margin-bottom: 20px;
  }
}
@media all {
  .elementor-column {
    position: relative;
    min-height: 1px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}
@media all {
  .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
    padding: 15px;
  }
}
@media (min-width: 768px) {
  .elementor-column.elementor-col-33 {
    width: 33.333%;
  }

  .elementor-column.elementor-col-50 {
    width: 50%;
  }

  .elementor-column.elementor-col-100 {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .elementor-column {
    width: 100%;
  }
}
@media (min-width: 1025px) {
  #elementor-device-mode:after {
    content: "desktop";
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  #elementor-device-mode:after {
    content: "tablet";
  }
}
@media (max-width: 767px) {
  #elementor-device-mode:after {
    content: "mobile";
  }
}
@media all {
  .elementor-element .elementor-widget-container {
    -webkit-transition: background 0.3s, border 0.3s, -webkit-border-radius 0.3s, -webkit-box-shadow 0.3s;
    transition: background 0.3s, border 0.3s, -webkit-border-radius 0.3s, -webkit-box-shadow 0.3s;
    -o-transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s, -webkit-border-radius 0.3s, -webkit-box-shadow 0.3s;
  }
}
@media all {
  .elementor-widget-divider .elementor-divider {
    line-height: 0;
    font-size: 0;
  }
}
@media all {
  .elementor-widget-divider .elementor-divider-separator {
    display: inline-block;
  }
}
@media all {
  .animated {
    -webkit-animation-duration: 1.25s;
    animation-duration: 1.25s;
  }
}
/*! CSS Used from: Embedded */
.elementor-section.elementor-section-boxed > .elementor-container {
  max-width: 1308px;
}

.elementor-widget:not(:last-child) {
  margin-bottom: 0px;
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/font-icons/css/fontello.css ; media=all */
@media all {
  [class*=" icon-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: 0.2em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-cancel:before {
    content: "\e8ac";
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/style.css ; media=all */
@media all {
  body,
div,
span,
h1,
h2,
h3,
h5,
h6,
p,
a,
small,
ul,
li,
form,
label,
input,
textarea,
button,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
section {
    font-family: inherit;
    font-size: 100%;
    line-height: inherit;
    font-weight: inherit;
    font-style: inherit;
    border: 0;
    outline: 0;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
  }

  body {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  article,
aside,
footer,
header,
nav,
section {
    display: block;
  }

  li > ul {
    margin-bottom: 0;
  }

  ul {
    padding-left: 1.5em;
  }

  a {
    text-decoration: none;
    background: transparent;
  }

  a,
a:hover,
a:focus,
a:active {
    outline: 0;
  }

  a img {
    border: none;
  }

  a,
button,
input[type=submit] {
    -webkit-transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    -ms-transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  }

  h6 > a {
    display: block;
  }

  :active,
:focus {
    outline: 0;
  }

  table {
    border-collapse: collapse;
  }

  table th + th,
table td + td {
    border-left: 2px solid #ddd;
  }

  table td,
table th {
    padding: 1.95em 1.1em;
  }

  table th {
    text-transform: none;
    text-align: center;
  }

  caption {
    font-weight: 700;
    text-align: center;
  }

  small {
    font-size: 80%;
  }

  img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
  }

  [class*=" icon-"]:before {
    font-size: inherit;
    line-height: inherit !important;
    font-weight: inherit;
    font-style: inherit;
    display: inline-block;
    vertical-align: top;
    width: auto;
    margin: 0;
  }

  form {
    margin-bottom: 0;
  }

  button,
input,
textarea {
    font-family: inherit;
    font-size: 1em;
    margin: 0;
    vertical-align: baseline;
  }

  button {
    overflow: visible;
  }

  input[placeholder]::-webkit-input-placeholder,
textarea[placeholder]::-webkit-input-placeholder {
    text-overflow: ellipsis;
  }

  input[placeholder]::-moz-placeholder,
textarea[placeholder]::-moz-placeholder {
    text-overflow: ellipsis;
  }

  input[placeholder]:-ms-input-placeholder,
textarea[placeholder]:-ms-input-placeholder {
    text-overflow: ellipsis;
  }

  input[placeholder]::placeholder,
textarea[placeholder]::placeholder {
    text-overflow: ellipsis;
  }

  input[placeholder],
textarea[placeholder] {
    text-overflow: ellipsis;
  }

  textarea,
input[type=text],
input[type=password],
input[type=email],
input[type=search],
input[type=checkbox] {
    -webkit-transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    -ms-transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
  }

  input[type=checkbox] {
    padding: 0;
  }

  input[type=search] {
    -webkit-appearance: none;
    -webkit-appearance: textfield;
  }

  button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  html input[disabled] {
    cursor: default !important;
  }

  button:focus,
input[type=text]:focus,
input[type=email]:focus,
input[type=search]:focus,
textarea:focus {
    outline: none;
  }

  input[type=text],
input[type=email],
input[type=search],
textarea {
    -webkit-appearance: none;
    outline: none;
    resize: none;
  }

  label.required:after {
    content: "*";
    display: none;
    vertical-align: text-top;
    font-size: 80%;
    color: #da6f5b;
  }

  form button,
input[type=submit],
.mc4wp-form .mc4wp-form-fields input[type=submit] {
    -webkit-appearance: none;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    text-transform: none;
    white-space: nowrap;
    padding: 1.38em 3.5em;
    letter-spacing: 0;
    height: auto;
    border-width: 0 !important;
    -webkit-border-radius: 30px;
    -ms-border-radius: 30px;
    border-radius: 30px;
  }

  input[type=text],
input[type=password],
input[type=email],
input[type=search],
textarea,
form.mc4wp-form .mc4wp-form-fields input[type=email] {
    padding: 0.94em 1.5em;
    border: 2px solid #ddd;
    -webkit-border-radius: 28px;
    -ms-border-radius: 28px;
    border-radius: 28px;
  }

  textarea {
    overflow: auto;
    vertical-align: top;
    min-height: 10em;
  }

  .widget input[type=search] {
    padding: 0.94em 1.5em;
  }

  input[type=checkbox] {
    display: none;
  }

  input[type=checkbox] + label {
    position: relative;
    padding-left: 1.4em;
    line-height: 1.5em;
    display: inline-block;
    vertical-align: top;
  }

  input[type=checkbox] + label:before {
    content: " ";
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
    font-size: 1em;
    display: block;
    text-align: center;
    border: 2px solid #e4e4e4;
    width: 1em;
    height: 1em;
    line-height: 0.53em;
    position: absolute;
    z-index: 1;
    top: 0.2em;
    left: 0;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  input[type=checkbox]:checked + label:before {
    content: "\f00c";
  }

  .screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .screen-reader-text:hover,
.screen-reader-text:active,
.screen-reader-text:focus {
    display: block;
    top: 5px;
    left: 5px;
    width: auto;
    height: auto;
    font-size: 0.8em;
    line-height: normal;
    padding: 1em 1.5em;
    color: #21759b;
    background-color: #f1f1f1;
    border-radius: 3px;
    clip: auto !important;
    text-decoration: none;
    z-index: 100000;
    -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    -ms-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  }

  .columns_wrap {
    margin-left: 0px;
    margin-right: -30px;
  }

  .columns_wrap > [class*=column-] {
    display: inline-block;
    vertical-align: top;
    position: relative;
    min-height: 1px;
    padding-left: 0;
    padding-right: 30px;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .columns_wrap.columns_padding_bottom > [class*=column-] {
    padding-bottom: 30px;
  }

  .column-1_2 {
    width: 50%;
  }

  .clearfix:after,
.columns_wrap:after {
    content: " ";
    clear: both;
    width: 100%;
    height: 0;
    display: block;
  }

  .page_wrap {
    min-height: 100vh;
    overflow: hidden;
  }

  .page_wrap,
.content_wrap {
    margin: 0 auto;
  }

  .page_content_wrap {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .content_wrap {
    width: 1278px;
    margin: 0 auto;
  }

  .content_wrap:after {
    content: " ";
    clear: both;
    width: 100%;
    height: 0;
    display: block;
  }

  .content,
.sidebar,
.sidebar_inner {
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .page_content_wrap .content_wrap {
    position: relative;
  }

  .body_style_wide:not(.expand_content) [class*=content_wrap] > .content {
    width: 818px;
  }

  [class*=content_wrap] > .sidebar {
    width: 435px;
  }

  .sidebar_right [class*=content_wrap] > .content {
    float: left;
  }

  .sidebar_right [class*=content_wrap] > .sidebar {
    float: right;
  }

  .sc_layouts_menu_mobile_button .sc_layouts_item_icon:before {
    content: "\e8ba";
    font-family: "fontello";
  }

  .menu_mobile_close {
    display: block;
    cursor: pointer;
    width: 5em;
    height: 5em;
    line-height: 5em;
    text-align: center;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
  }

  .menu_mobile_close:before,
.menu_mobile_close:after {
    content: " ";
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 38%;
    width: 25%;
    height: 0;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    margin-top: -1px;
    border-top: 2px solid #fff;
  }

  .menu_mobile_close:before {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .menu_mobile_close:after {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .menu_mobile_overlay {
    display: none !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 100000;
  }

  .menu_mobile {
    position: fixed;
    z-index: 100002;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    -webkit-transition: height 0.3s ease;
    -ms-transition: height 0.3s ease;
    transition: height 0.3s ease;
  }

  .menu_mobile_inner {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    overflow: hidden;
  }

  .menu_mobile li > a {
    display: block;
  }

  .menu_mobile .sc_layouts_logo {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    margin: 4.4rem 0 0;
    font-size: 1em !important;
  }

  .menu_mobile .menu_mobile_nav_area {
    position: absolute;
    z-index: 1;
    top: 48%;
    left: 0;
    width: 100%;
    height: auto;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    max-height: 49%;
    padding-right: 20px;
    overflow-y: scroll;
  }

  .menu_mobile .menu_mobile_nav_area ul {
    margin: 0 auto;
    padding: 0;
    list-style: none;
    width: auto;
  }

  .menu_mobile .menu_mobile_nav_area ul ul {
    margin: 5px 0;
    display: none;
  }

  .menu_mobile .menu_mobile_nav_area li {
    margin-bottom: 0;
    width: auto;
  }

  .menu_mobile .menu_mobile_nav_area li:before {
    display: none;
  }

  .menu_mobile .menu_mobile_nav_area li > a {
    font-size: 1.25em;
    line-height: 1.2em;
    padding: 1px 2.5em 1px 0;
    text-transform: none;
    letter-spacing: 0.5px;
    position: relative;
    display: inline-block;
    width: 28rem;
    text-align: left;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    font-weight: 500;
  }

  .menu_mobile .menu_mobile_nav_area > ul > li > a {
    font-size: 20px;
    line-height: 1.2em;
    padding: 5px 0;
    padding-right: 30px;
  }

  .menu_mobile .menu_mobile_nav_area > ul > li li > a {
    padding-left: 1em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
  }

  .menu_mobile .menu_mobile_nav_area > ul > li li li > a {
    padding-left: 2em;
  }

  .menu_mobile .menu_mobile_nav_area > ul > li li li li > a {
    padding-left: 3em;
  }

  .menu_mobile .menu_mobile_nav_area .open_child_menu {
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    width: 1.3rem;
    height: 1.3rem;
    line-height: 1.3rem;
    text-align: center;
    padding: 0.35rem;
    background-color: rgba(255, 255, 255, 0.1);
    -webkit-transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    -ms-transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    top: 5px;
  }

  .menu_mobile .menu_mobile_nav_area li li .open_child_menu {
    background-color: transparent;
    padding: 0.35rem;
    top: 2px;
  }

  .menu_mobile .menu_mobile_nav_area a:hover > .open_child_menu {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .menu_mobile .menu_mobile_nav_area .open_child_menu:before {
    font-family: "fontello";
    content: "\e828";
  }

  .menu_mobile .sc_layouts_logo img {
    max-height: 5em;
  }

  .menu_mobile .search_mobile {
    position: absolute;
    z-index: 1;
    bottom: 8rem;
    left: 50%;
    width: 28rem;
    margin-left: -14.5rem;
  }

  .menu_mobile .search_mobile form {
    position: relative;
  }

  .menu_mobile .search_mobile .search_field {
    width: 100%;
    padding: 1em 3em 1em 1.5em !important;
    border: none;
  }

  .menu_mobile .search_mobile .search_submit {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
    font-size: 14px;
    padding: 0.9em 1.5em;
    background-color: transparent;
  }

  .header_content_wrap {
    margin-bottom: 0;
  }

  .header_content_wrap .post_header {
    margin-bottom: 0;
  }

  .header_content_wrap .post_featured {
    margin: 0;
    overflow: visible;
  }

  .post_header_position_under .header_content_wrap .post_header {
    margin-top: 0;
  }

  .header_content_wrap .post_header .post_meta_other {
    margin-top: 0;
  }

  .page_content_wrap {
    position: relative;
  }

  .post_featured {
    overflow: hidden;
    position: relative;
    margin-bottom: 1.8em;
  }

  .post_info .post_info_item.post_info_posted_by {
    font-weight: 400;
  }

  .post_info .post_info_item a.post_info_author {
    font-weight: 700;
  }

  .widget_area .post_item .post_info .post_info_item + .post_info_item:before,
aside .post_item .post_info .post_info_item + .post_info_item:before {
    margin: 0 0 0 16px;
  }

  .post_meta .post_meta_item {
    font-size: inherit;
    margin-left: 0;
    white-space: normal;
    -webkit-transition: color 0.3s ease;
    -ms-transition: color 0.3s ease;
    transition: color 0.3s ease;
  }

  .post_meta .post_meta_item:after {
    content: "";
    display: inline;
    font-family: inherit;
    font-size: inherit;
    font-weight: 400;
    font-style: normal;
    vertical-align: baseline;
    margin: 0 0.4em;
  }

  .post_meta .post_meta_item:last-child:after {
    display: none;
  }

  .post_meta_item.post_categories a {
    padding: 5px 16px;
    -webkit-border-radius: 15px;
    -ms-border-radius: 15px;
    border-radius: 15px;
    margin-right: 3px;
  }

  .post_meta_item.post_categories a:last-child {
    margin-right: 0;
  }

  .post_meta_item.post_categories {
    margin-left: -3px;
  }

  .post_share .social_items {
    font-size: 1em;
  }

  .post_meta .socials_share {
    display: inline;
    vertical-align: baseline;
  }

  .post_meta_item .socials_share .social_items {
    bottom: auto;
    top: 2.5em;
    background: transparent;
  }

  .post_meta_item .socials_share .social_items:before {
    bottom: auto;
    top: -5px;
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  .post_featured .mask {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .nav-links {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 2em;
    text-align: left;
    border: none;
    list-style-type: none;
    clear: both;
  }

  .nav-links-single .nav-links {
    font-size: 1em;
    text-align: left;
    margin-top: 4.3em;
    overflow: hidden;
    text-transform: none;
  }

  .nav-links-single .nav-links a {
    display: block;
    position: relative;
    min-height: 3em;
    z-index: 1;
  }

  .nav-links-single .nav-links a .nav-arrow {
    width: 5em;
    height: 4.6em;
    line-height: 4.6em;
    overflow: hidden;
    text-align: center;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: none;
  }

  .nav-links-single .nav-links a .nav-arrow:before {
    content: " ";
    display: block;
    background-color: #fff;
    opacity: 0;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .nav-links-single .nav-links a:hover .nav-arrow:before {
    opacity: 0.5;
  }

  .nav-links-single .nav-links a .nav-arrow:after {
    content: "\e939";
    font-family: "fontello";
    display: inline-block;
    position: relative;
    z-index: 2;
    top: 3em;
    opacity: 0;
    color: #292929;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .nav-links-single .nav-links a:hover .nav-arrow:after {
    top: 0;
    opacity: 1;
  }

  .nav-links-single .nav-links .nav-previous {
    width: 49%;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    padding: 25px 20px 23px;
    position: relative;
    z-index: 1;
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }

  .nav-links-single .nav-links .nav-previous {
    float: left;
    text-align: left;
  }

  .nav-links-single .nav-links .nav-previous a {
    padding-left: 0;
  }

  .nav-links-single .nav-links .nav-previous .post-title {
    text-align: left;
  }

  .nav-links-single .nav-links .post-title {
    -webkit-transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    -ms-transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  }

  .nav-links-single .nav-links .post-title {
    margin: 11px 0 0;
    font-size: 30px;
    line-height: 1.08em;
    font-weight: 700;
    letter-spacing: 1px;
  }

  .nav-links-single .nav-links .screen-reader-text:hover,
.nav-links-single .nav-links .screen-reader-text {
    display: block !important;
    clip: auto !important;
    top: 0 !important;
    left: 0 !important;
    width: auto !important;
    height: auto !important;
    position: relative !important;
    padding: 0 !important;
    border: none !important;
    -webkit-box-shadow: none !important;
    -ms-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 13px !important;
    line-height: 16px !important;
    font-weight: 500 !important;
    -webkit-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    border-radius: 0 !important;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    letter-spacing: 0.3px !important;
  }

  .nav-links-single .nav-previous .screen-reader-text:before {
    content: "\e96a";
    font-family: "fontello";
    display: inline-block;
  }

  .nav-links-single .nav-previous .screen-reader-text:before {
    -moz-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
    filter: FlipH;
    -ms-filter: "FlipH";
    margin-right: 7px;
  }

  .single-post .post_item_single {
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    overflow: hidden;
  }

  .single-post .post_item_single .post_content_single {
    padding: 50px;
  }

  .single-post .post_item_single .post_header_single {
    padding: 18px 50px 18px;
  }

  .single-post .header_content_wrap + .post_content_single {
    padding-top: 0;
  }

  .section_title {
    margin: 0 0 0.72em;
    text-align: left;
    font-size: 3.429em;
    letter-spacing: 1.8px;
  }

  .post_item_single .post_header_single {
    margin-bottom: 0;
  }

  .post_item_single .post_content > .post_meta_single {
    font-size: 1em;
    line-height: 1.35em;
    margin-top: 1.6em;
    letter-spacing: 0;
    overflow: hidden;
    clear: both;
  }

  .post_item_single .post_content .post_tags .post_meta_label {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 2.3px;
    vertical-align: middle;
  }

  .post_item_single .post_content > .post_meta_single .post_tags a {
    padding: 6px 14px;
    margin: 0 7px 7px 0;
    font-size: 13px !important;
    line-height: 17px;
    font-weight: 500;
    letter-spacing: 0;
    text-transform: lowercase;
    -webkit-border-radius: 20px;
    -ms-border-radius: 20px;
    border-radius: 20px;
    display: inline-block;
  }

  .post_item_single .post_content > .post_meta_single .post_tags {
    float: left;
    display: block;
    max-width: 50%;
    margin-top: 3px;
  }

  .post_item_single .post_content > .post_meta_single .post_tags:after {
    display: none;
  }

  .post_item_single .post_content > .post_meta_single .post_share {
    float: right;
    display: block;
    max-width: 47%;
  }

  .post_item_single .post_content > .post_meta_single .post_share:before {
    display: none;
  }

  .post_item_single .post_content > .post_meta_single .post_share .social_item {
    margin: 3px 0 3px 0.5em;
  }

  .post_item_single .post_content > .post_meta_single .post_share .socials_share {
    vertical-align: middle;
    display: inline-block;
  }

  .post_item_single .post_content > .post_meta_single .post_share .social_item .social_icon {
    display: block;
    width: 29px;
    height: 29px;
    line-height: 29px;
    text-align: center;
    font-size: 16px;
  }

  .post_item_single .post_content > .post_meta_single .share_meta_label {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 2.3px;
    vertical-align: middle;
  }

  .related_wrap {
    margin-top: 6.9em;
    padding-top: 7em;
    border-top: 1px solid #eee;
  }

  .related_wrap .related_item {
    text-align: left;
  }

  .related_wrap .post_title {
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    line-height: 0.94em;
    letter-spacing: 1px;
  }

  .related_wrap .post_title a {
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .nav-links-single .nav-links .nav-previous .post-title {
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .related_wrap.related_style_classic .post_featured {
    margin-bottom: 2em;
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }

  .related_wrap.related_style_classic .post_featured[class*=hover_] {
    display: inline-block;
  }

  .related_wrap.related_style_classic .post_featured img {
    width: 100%;
  }

  .related_wrap.related_style_classic .post_meta {
    margin-top: 0.4em;
    margin-bottom: 0.6em;
  }

  .related_wrap.related_style_classic .post_title + .post_content_inner {
    margin-top: 1.4em;
  }

  .comments_form_wrap {
    margin-top: 7em;
    padding-top: 7em;
    border-top: 1px solid #eee;
    overflow: hidden;
  }

  .related_wrap + .comments_wrap .comments_form_wrap {
    margin-top: 5em;
  }

  .comments_form_wrap form {
    position: relative;
  }

  .comments_wrap #cancel-comment-reply-link {
    font-size: 0;
  }

  .comments_wrap #cancel-comment-reply-link:before {
    content: "\e916";
    font-family: "fontello";
    font-size: 18px;
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    width: 2em;
    height: 2em;
    line-height: 2em;
    text-align: center;
  }

  .comments_wrap .comments_field {
    margin-bottom: 20px;
  }

  .comments_wrap .comments_author,
.comments_wrap .comments_email {
    width: 49%;
    float: left;
  }

  .comments_wrap .comments_email {
    float: right;
  }

  .comments_wrap .comments_comment:before {
    content: " ";
    clear: both;
    width: 100%;
    height: 0;
    display: block;
  }

  .comments_wrap .comments_field label {
    display: none;
  }

  .comments_wrap .comments_field input[type=checkbox] + label {
    display: block;
  }

  .comments_wrap .comments_field input,
.comments_wrap .comments_field textarea {
    width: 100%;
    padding: 0.9em 1.5em;
  }

  .comments_wrap .comments_field textarea {
    min-height: 17.5em;
  }

  .comments_wrap .form-submit {
    margin: 1.3em 0 0;
  }

  .comments_wrap .form-submit:before {
    content: " ";
    clear: both;
    width: 100%;
    height: 0;
    display: block;
  }

  .comments_wrap .form-submit input[type=submit] {
    display: inline-block;
  }

  .sidebar .widget {
    padding: 30px;
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.07);
    -ms-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.07);
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.07);
    overflow: hidden;
  }

  .sidebar .widget + .widget {
    margin-top: 30px;
  }

  .widget .widget_title {
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
    letter-spacing: 2.2px;
    padding: 16px 23px;
    border-left: 7px solid red;
  }

  .sidebar_inner .widget .widget_title {
    margin-left: -30px;
    margin-right: -30px;
    margin-top: -30px;
  }

  .widget_area .post_item .post_title,
aside .post_item .post_title {
    margin: 0 0 5px 0;
    text-align: left;
    line-height: 1.25em;
    font-weight: 500;
  }

  .widget_area .post_item .post_thumb,
aside .post_item .post_thumb {
    float: left;
    width: 66px;
    height: 66px;
    margin: 0 25px 3px 0;
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }

  .widget_area .post_item .post_info,
aside .post_item .post_info {
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 0;
    font-weight: 500;
  }

  aside .post_item + .post_item {
    margin-top: 9px;
    border-top: 1px solid red;
    padding-top: 12px;
  }

  .widget ul {
    margin: 0;
    padding-left: 1.2em;
    list-style-type: none;
  }

  .widget li {
    position: relative;
  }

  .widget ul > li:before {
    content: " ";
    display: block;
    width: 3px;
    height: 3px;
    line-height: 3px;
    text-align: center;
    position: absolute;
    z-index: 1;
    top: 0.8em;
    left: -1.2em;
  }

  .widget_categories > ul {
    padding: 0;
  }

  .widget_categories ul > li:before {
    display: none;
  }

  .widget_categories ul {
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 2.4px;
    font-weight: 700;
  }

  .widget_categories ul li {
    padding: 15px 0;
  }

  .widget_categories ul li + li {
    border-top: 1px solid red;
  }

  .widget_categories > ul > li:first-child {
    padding-top: 0 !important;
  }

  .widget_categories ul li:last-child {
    padding-bottom: 0 !important;
  }

  .widget_calendar table {
    width: 100%;
    position: relative;
  }

  .widget_calendar caption {
    margin-bottom: 13px;
    font-weight: 500;
    padding: 17px;
    -webkit-border-radius: 30px;
    -ms-border-radius: 30px;
    border-radius: 30px;
    font-size: 14px;
    line-height: 16px;
  }

  .widget_calendar table > thead > tr {
    background-color: transparent !important;
  }

  .widget_calendar th,
.widget_calendar td {
    font-size: 15px !important;
    line-height: 2.2em;
    font-weight: 400 !important;
    font-style: normal;
    text-align: center;
    text-transform: uppercase;
    padding: 2px;
    border: none !important;
    background-color: transparent !important;
    width: 14.2857%;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .widget_calendar th {
    font-weight: 700 !important;
    font-size: 20px !important;
  }

  .widget_calendar td {
    line-height: 3.1em;
    padding: 0;
  }

  .widget_calendar tfoot td {
    line-height: 2em;
  }

  .widget_calendar td#today {
    position: relative;
    z-index: 2;
  }

  .widget_calendar td#today:before {
    content: " ";
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 2.9em;
    height: 2.9em;
    line-height: 2.9em;
    text-align: center;
    -webkit-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
  }

  .widget_calendar tfoot {
    position: relative;
    top: 3px;
  }

  .widget_calendar tfoot td {
    text-transform: none !important;
  }

  .widget_calendar td#prev {
    text-align: left;
    overflow: hidden;
  }

  .widget_calendar td#next {
    text-align: right;
  }

  .widget_calendar td#prev a {
    position: relative;
    letter-spacing: 1px;
    font-weight: 400;
  }

  .widget_calendar td#prev a {
    padding-left: 0.6em;
  }

  .widget_calendar td#prev a:before {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 1.2em;
    height: 1em;
    line-height: 1.4em;
    display: inline-block;
  }

  .widget_calendar td#prev a:before {
    content: "\e939";
    font-family: "fontello";
    display: none;
  }

  .widget_search form {
    width: 100%;
    overflow: hidden;
    position: relative;
  }

  .widget_search form:after {
    content: "\e968";
    font-family: "fontello";
    display: block;
    position: absolute;
    z-index: 0;
    top: 50%;
    right: 0;
    margin-top: -0.5em;
    width: 1em;
    height: 1em;
    line-height: 1em;
    text-align: center;
    pointer-events: none;
    cursor: pointer;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: 2;
    width: 5.5em;
    letter-spacing: -1px;
  }

  .widget_search .search-field {
    width: 100%;
    padding: 1.1em 1.5em !important;
    padding-right: 3em !important;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    text-transform: lowercase;
    border: none !important;
  }

  .widget_search input.search-submit {
    display: block;
    text-indent: -1000px;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    width: 5.5em;
    height: 100%;
    padding: 0;
    border: none !important;
    cursor: pointer;
  }

  .widget_tag_cloud {
    overflow: hidden;
  }

  .widget_tag_cloud .tagcloud {
    margin-bottom: -10px;
    overflow: hidden;
  }

  .widget_tag_cloud a {
    display: block;
    float: left;
    padding: 6px 14px;
    margin: 0 7px 10px 0;
    font-size: 13px !important;
    line-height: 17px;
    font-weight: 500;
    letter-spacing: 0;
    text-transform: lowercase;
    -webkit-border-radius: 20px;
    -ms-border-radius: 20px;
    border-radius: 20px;
  }

  #recentcomments {
    padding-left: 0;
  }

  .recentcomments {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
  }

  .recentcomments > a {
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    float: left;
    display: block !important;
    width: 100%;
    letter-spacing: 0;
  }

  li.recentcomments:before {
    display: none !important;
  }

  .recentcomments + .recentcomments {
    margin-top: 11px;
    padding-top: 11px;
    border-top: 1px solid #ddd;
  }

  .recentcomments .comment-author-link {
    font-size: 13px;
    font-weight: 700;
  }

  .widget_banner .image_wrap {
    margin: 0;
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }

  .widget_twitter .widget_content ul li + li {
    margin-top: 11px;
    padding-top: 11px;
    border-top: 1px solid #ddd;
  }

  .widget_twitter .widget_content ul li {
    padding-left: 59px;
    min-height: 44px;
  }

  .widget_twitter .widget_content ul {
    padding-left: 0;
  }

  .widget_twitter .widget_content ul > li:before {
    content: "\e840";
    font-style: normal;
    display: inline-block;
    top: 0;
    left: 0;
    width: 44px;
    height: 44px;
    -webkit-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    line-height: 44px;
    font-weight: 400;
    font-size: 2em;
  }

  .widget_twitter .widget_content ul li + li:before {
    margin-top: 11px;
  }

  .widget_twitter .widget_content ul a {
    border-bottom: 1px solid #ddd;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    line-height: 1em;
    display: inline-block;
  }

  .footer_wrap p {
    margin: 0;
  }

  .footer_wrap .sc_layouts_menu > ul > li + li:before {
    display: none;
  }

  .footer_wrap .sc_layouts_menu > ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  .footer_wrap .sc_layouts_menu > ul > li {
    display: inline-block;
    vertical-align: baseline;
    font-size: 16px;
    z-index: auto !important;
    font-weight: 500;
  }

  .footer_wrap .sc_layouts_menu > ul > li + li:before {
    content: " ";
    display: inline-block;
    width: 0;
    height: 0.8em;
    margin: 0;
    vertical-align: baseline;
    border-left: 1px dotted #ddd;
  }

  .footer_wrap .sc_layouts_menu > ul > li > a {
    padding: 0.5em 1em;
    display: inline-block;
  }

  .footer_wrap .sc_layouts_column_align_left .sc_layouts_menu > ul > li:first-child > a {
    padding-left: 0;
  }

  .sc_layouts_row_type_normal {
    padding: 1em 0;
  }

  .sc_layouts_column_align_right .sc_layouts_item {
    margin-left: 1.3em;
    margin-right: 0;
  }

  .sc_layouts_menu_mobile_button .sc_layouts_item_icon {
    font-size: 30px !important;
  }

  .sc_layouts_row_type_normal .sc_layouts_item_icon {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    -webkit-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    font-size: 14px;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .search_wrap .search_submit {
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .search_wrap .search_submit:before {
    content: "\e968";
    font-family: "fontello";
  }

  .sc_layouts_row_type_normal .search_wrap .search_field {
    font-size: 14px;
    font-weight: 400;
    text-transform: lowercase;
    padding: 0 60px 0 21px !important;
    width: 18em;
    height: 50px;
    line-height: 50px;
    border: none;
  }

  .sc_layouts_row_type_normal .search_wrap .search_submit {
    display: block;
    font-size: 11px;
    text-align: center;
    width: 60px;
    height: 50px;
    line-height: 50px;
    position: absolute;
    top: 0;
    bottom: auto;
    left: auto;
    right: 0;
  }

  .sc_layouts_row_type_normal .search_wrap .search_submit:before {
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .top_panel:not(.scheme_dark) .sc_layouts_row_type_normal:not(.scheme_dark) .search_wrap .search_submit:before {
    color: #797e87 !important;
  }

  .top_panel:not(.scheme_dark) .sc_layouts_row_type_normal:not(.scheme_dark) .search_wrap .search_submit:hover:before {
    color: #262f3e !important;
  }

  .sc_layouts_menu_nav li {
    text-align: center;
  }

  .sc_layouts_menu_nav > li > ul:after {
    content: " ";
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 50%;
    width: 12px;
    height: 12px;
    margin: -6px 0 0 -6px;
    background-color: #fff;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .sc_layouts_menu_nav li li.menu-item-has-children > a:after,
.sc_layouts_menu_nav > li.menu-item-has-children > a:after {
    display: none;
  }

  .sc_layouts_menu_nav > li > a,
.sc_layouts_menu_nav > li.menu-item-has-children > a {
    padding: 0.85em 1.3em;
  }

  .sc_layouts_menu_nav > li > ul {
    top: 4em;
    left: 50%;
    margin-left: -3em;
  }

  .sc_layouts_menu_nav > li ul {
    width: 10.8em;
    padding: 1.8em 0;
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }

  .sc_layouts_menu_nav > li > ul ul {
    left: 10.8em;
    top: -1.8em;
    margin: 0 0 0 2px;
  }

  .sc_layouts_menu_nav > li li > a {
    padding: 0.27em 1em;
  }

  .sc_layouts_menu_nav > li ul {
    -webkit-box-shadow: none;
    -ms-box-shadow: none;
    box-shadow: none;
  }

  .footer_wrap .sc_layouts_menu > ul > li + li:before {
    display: none;
  }

  .sc_layouts_title_caption {
    font-size: 72px;
  }

  .breadcrumbs {
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
  }

  .breadcrumbs .breadcrumbs_delimiter {
    margin: 0 0.15em;
  }

  .copyright-text {
    font-size: 16px;
  }

  .post_meta_item.post_categories {
    margin-left: 0;
    margin-top: -5px !important;
    display: inline-block;
    margin-bottom: -5px !important;
  }

  .single-post .post_meta_item.post_categories {
    margin-bottom: 0 !important;
  }

  .post_meta_item.post_categories a {
    display: inline-block;
    line-height: 20px;
    margin-bottom: 3px;
  }
}
/*! CSS Used from: Embedded */
.post-navigation .nav-previous a .nav-arrow {
  background-image: url(https://fcunited.azurewebsites.net/wp-content/uploads/2018/11/post-6-copyright-406x228.jpg);
}

/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__plugins.css ; media=all */
@media all {
  .posts_container.columns_wrap {
    display: flex;
    flex-wrap: wrap;
  }

  .post_featured[class*=hover_] {
    position: relative;
    -webkit-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
    display: inline-block;
    vertical-align: top;
  }

  .post_featured[class*=hover_],
.post_featured[class*=hover_] * {
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .post_featured .mask {
    content: " ";
    position: absolute;
    z-index: 4;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .post_featured.hover_simple:hover .mask {
    opacity: 1;
  }

  .post_featured.hover_simple .icons {
    position: absolute;
    z-index: 6;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .footer_wrap .sc_layouts_item {
    vertical-align: top;
    margin-top: 0.25em !important;
    margin-bottom: 0.25em !important;
  }

  .sc_item_title {
    line-height: 0.94em;
  }

  .socials_wrap {
    margin: -12px 0 0 0;
  }

  .socials_wrap .social_item {
    margin: 12px 12px 0 0;
  }

  .socials_wrap .social_item .social_icon {
    width: 46px;
    height: 46px;
    line-height: 47px;
    font-size: 26px;
  }

  .trx_addons_scroll_to_top {
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }

  .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
    padding: 15px;
  }

  form.mc4wp-form {
    position: relative;
    padding-right: 0;
  }

  form.mc4wp-form .mc4wp-form-fields input[type=email] {
    width: 100%;
    vertical-align: top;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    height: 50px;
    line-height: 50px;
    border: none;
    -webkit-border-radius: 30px;
    -ms-border-radius: 30px;
    border-radius: 30px;
    padding: 0 10em 0 2.2em;
  }

  form.mc4wp-form .mc4wp-form-fields input[type=submit] {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    height: 50px;
    line-height: 50px;
    min-width: 9.5em;
    vertical-align: top;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0;
    padding-bottom: 0;
    -webkit-border-radius: 30px;
    -ms-border-radius: 30px;
    border-radius: 30px;
    text-align: center;
  }

  form.mc4wp-form label {
    margin-top: 10px;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__custom.css ; media=all */
@media all {
  .content_wrap {
    width: 1278px;
  }

  body.body_style_wide:not(.expand_content) [class*=content_wrap] > .content {
    width: 818px;
  }

  [class*=content_wrap] > .sidebar {
    width: 435px;
  }

  body {
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    font-style: normal;
    line-height: 1.76em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0.26px;
  }

  p,
ul {
    margin-top: 0em;
    margin-bottom: 1.6em;
  }

  h1 {
    font-family: "BebasNeue", sans-serif;
    font-size: 4.286em;
    font-weight: 700;
    font-style: normal;
    line-height: 0.93em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 2.1px;
    margin-bottom: 0.63em;
  }

  h2 {
    font-family: "BebasNeue", sans-serif;
    font-size: 3.429em;
    font-weight: 700;
    font-style: normal;
    line-height: 0.96em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 1.75px;
    margin-bottom: 0.52em;
  }

  h3 {
    font-family: "BebasNeue", sans-serif;
    font-size: 2.571em;
    font-weight: 700;
    font-style: normal;
    line-height: 0.91em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 1.3px;
    margin-bottom: 0.92em;
  }

  h5 {
    font-family: "BebasNeue", sans-serif;
    font-size: 1.714em;
    font-weight: 700;
    font-style: normal;
    line-height: 1.06em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0.85px;
    margin-bottom: 0.7em;
  }

  h6 {
    font-family: "Poppins", sans-serif;
    font-size: 1.286em;
    font-weight: 500;
    font-style: normal;
    line-height: 1.23em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0px;
    margin-bottom: 0.69em;
  }

  input[type=text],
input[type=email],
input[type=search],
textarea {
    font-size: 1em;
    font-weight: 400;
    font-style: normal;
    line-height: 1.5em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0px;
  }

  form button,
input[type=submit] {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 18px;
    text-decoration: none;
  }

  .related_wrap .post_title,
.nav-links-single .nav-links .post-title,
.post_item_single .post_content > .post_meta_single .share_meta_label,
.post_item_single .post_content .post_tags .post_meta_label,
.nav-links,
.widget_calendar table,
.widget_categories ul {
    font-family: "BebasNeue", sans-serif;
  }

  .widget_calendar caption {
    font-family: "Poppins", sans-serif;
  }

  .post_meta {
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.4em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0px;
    margin-top: 0.4em;
  }

  .nav-links-single .screen-reader-text {
    font-family: "Poppins", sans-serif;
  }

  .sc_layouts_row:not(.sc_layouts_row_type_narrow) .sc_layouts_menu_nav {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    line-height: 1.5em;
  }

  .sc_layouts_row:not(.sc_layouts_row_type_narrow) .sc_layouts_menu_nav > li > a {
    font-weight: 500;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0.18px;
  }

  .sc_layouts_row:not(.sc_layouts_row_type_narrow) .sc_layouts_menu_nav > li > ul {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    line-height: 1.35em;
  }

  .sc_layouts_row:not(.sc_layouts_row_type_narrow) .sc_layouts_menu_nav > li ul > li > a {
    font-weight: 500;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0.18px;
  }

  .menu_mobile .menu_mobile_nav_area > ul {
    font-family: "Poppins", sans-serif;
  }

  .menu_mobile .menu_mobile_nav_area > ul > li ul {
    font-family: "Poppins", sans-serif;
  }

  form.mc4wp-form .mc4wp-form-fields input[type=email] {
    font-size: 1em;
    font-weight: 400;
    font-style: normal;
    line-height: 1.5em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0px;
  }

  form.mc4wp-form .mc4wp-form-fields input[type=submit] {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 18px;
    text-decoration: none;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__colors-default.css ; media=all */
@media all {
  .scheme_default {
    color: #797e87;
  }

  .scheme_default h1,
.scheme_default h2,
.scheme_default h3,
.scheme_default h5,
.scheme_default h6,
.scheme_default h3 a,
.scheme_default h6 a,
.scheme_default li a {
    color: #262f3e;
  }

  .scheme_default h3 a:hover,
.scheme_default h6 a:hover,
.scheme_default li a:hover {
    color: #ff0000;
  }

  .scheme_default a {
    color: #ff0000;
  }

  .scheme_default a:hover {
    color: #081324;
  }

  .scheme_default table th,
.scheme_default table th + th {
    border-color: #262f3e;
  }

  .scheme_default table td,
.scheme_default table td + td {
    color: #797e87;
    border-color: #e3e3e3;
  }

  .scheme_default table th {
    color: #8c9097;
    background-color: #081224;
  }

  .scheme_default table > tbody > tr:nth-child(2n+1) > td {
    background-color: #ebebeb;
  }

  .scheme_default table > tbody > tr:nth-child(2n) > td {
    background-color: #f4f4f4;
  }

  .scheme_default ul > li:before {
    color: #ff0000;
  }

  .scheme_default .widget_search form:after {
    color: #ffffff;
  }

  .scheme_default .widget_search form:hover:after {
    color: #ffffff;
  }

  .scheme_default input[type=text],
.scheme_default input[type=password],
.scheme_default input[type=email],
.scheme_default input[type=search],
.scheme_default textarea,
.scheme_default form.mc4wp-form input[type=email] {
    color: #797e87;
    border-color: #e4e4e4;
    background-color: #f4f4f4;
  }

  .scheme_default input[type=text]:focus,
.scheme_default input[type=password]:focus,
.scheme_default input[type=email]:focus,
.scheme_default input[type=search]:focus,
.scheme_default textarea:focus,
.scheme_default form.mc4wp-form input[type=email]:focus {
    color: #262f3e;
    border-color: #262f3e;
    background-color: #f4f4f4;
  }

  .scheme_default input[placeholder]::-webkit-input-placeholder {
    color: #797e87;
    opacity: 1;
  }

  .scheme_default textarea[placeholder]::-webkit-input-placeholder {
    color: #797e87;
    opacity: 1;
  }

  .scheme_default input[placeholder]::-moz-placeholder {
    color: #797e87;
    opacity: 1;
  }

  .scheme_default textarea[placeholder]::-moz-placeholder {
    color: #797e87;
    opacity: 1;
  }

  .scheme_default input[placeholder]:-ms-input-placeholder {
    color: #797e87;
    opacity: 1;
  }

  .scheme_default textarea[placeholder]:-ms-input-placeholder {
    color: #797e87;
    opacity: 1;
  }

  .scheme_default input[placeholder]::placeholder {
    color: #797e87;
    opacity: 1;
  }

  .scheme_default textarea[placeholder]::placeholder {
    color: #797e87;
    opacity: 1;
  }

  .scheme_default input[type=checkbox] + label:before {
    border-color: #e4e4e4 !important;
  }

  .scheme_default .search_wrap .search_submit:before {
    color: #797e87;
  }

  .scheme_default input[type=submit][disabled],
.scheme_default input[type=submit][disabled]:hover {
    background-color: rgba(38, 47, 62, 0.4) !important;
    color: #ffffff !important;
  }

  .scheme_default button:hover,
.scheme_default button:focus,
.scheme_default input[type=submit]:hover,
.scheme_default input[type=submit]:focus,
.scheme_default .comments_wrap .form-submit input[type=submit]:hover,
.scheme_default .comments_wrap .form-submit input[type=submit]:focus,
.scheme_default .socials_share:not(.socials_type_drop) .social_icon:hover,
.scheme_default .mc4wp-form input[type=submit]:hover,
.scheme_default .mc4wp-form input[type=submit]:focus {
    color: #ffffff;
    background-color: #262f3e;
  }

  .scheme_default .search_wrap .search_submit:hover:before {
    color: #262f3e;
  }

  .scheme_default .sc_layouts_item_icon {
    color: #797e87;
  }

  .scheme_default .sc_layouts_row_type_normal .sc_layouts_item {
    color: #797e87;
  }

  .scheme_default .sc_layouts_row_type_normal .sc_layouts_item a:not(.sc_button):not(.button) {
    color: #797e87;
  }

  .scheme_default .sc_layouts_row_type_normal .sc_layouts_item a:not(.sc_button):not(.button):hover,
.scheme_default .sc_layouts_row_type_normal .sc_layouts_item a:not(.sc_button):not(.button):hover .sc_layouts_item_icon {
    color: #262f3e;
  }

  .scheme_default .sc_layouts_row_type_normal .search_wrap .search_submit:before,
.scheme_default .sc_layouts_row_type_normal .search_wrap .search_submit {
    background-color: transparent;
    color: #262f3e;
  }

  .scheme_default .sc_layouts_row_type_normal .search_wrap .search_submit:hover:before,
.scheme_default .sc_layouts_row_type_normal .search_wrap .search_submit:hover {
    background-color: transparent;
    color: #797e87;
  }

  .scheme_default .sc_layouts_title .sc_layouts_title_breadcrumbs,
.scheme_default .sc_layouts_title .sc_layouts_title_breadcrumbs a {
    color: #262f3e;
  }

  .scheme_default .sc_layouts_title .sc_layouts_title_breadcrumbs a:hover {
    color: #081324;
  }

  .scheme_default .sc_layouts_menu_nav > li > a {
    color: #262f3e;
  }

  .scheme_default .sc_layouts_menu_nav > li > a:hover {
    color: #ff0000 !important;
  }

  .scheme_default .sc_layouts_menu_nav > li ul {
    background-color: #081224;
  }

  .scheme_default .sc_layouts_menu_nav > li > ul:after {
    background-color: #081224;
  }

  .scheme_default .sc_layouts_menu_nav > li li > a {
    color: #8c9097 !important;
  }

  .scheme_default .sc_layouts_menu_nav > li li > a:hover {
    color: #ff0000 !important;
  }

  .scheme_default .sc_layouts_menu_nav > li li > a:hover:after {
    color: #ff0000 !important;
  }

  .scheme_default .menu_mobile_inner {
    color: #797e87;
    background-color: #ffffff;
  }

  .scheme_default .menu_mobile_close:before,
.scheme_default .menu_mobile_close:after {
    border-color: #1d1d1d;
  }

  .scheme_default .menu_mobile_close:hover:before,
.scheme_default .menu_mobile_close:hover:after {
    border-color: #ff0000;
  }

  .scheme_default .menu_mobile_inner a,
.scheme_default .menu_mobile_inner .menu_mobile_nav_area li:before {
    color: #1d1d1d;
  }

  .scheme_default .menu_mobile_inner a:hover,
.scheme_default .menu_mobile_inner .menu_mobile_nav_area li:hover:before {
    color: #ff0000;
  }

  .scheme_default .menu_mobile_inner .search_mobile .search_submit {
    color: #797e87;
  }

  .scheme_default .menu_mobile_inner .search_mobile .search_submit:focus,
.scheme_default .menu_mobile_inner .search_mobile .search_submit:hover {
    color: #262f3e;
  }

  .scheme_default .sc_layouts_row_type_normal .sc_layouts_item_icon {
    color: #ffffff;
    background-color: #8c9097;
  }

  .scheme_default .sc_layouts_row_type_normal .sc_layouts_item_icon:hover {
    color: #ffffff;
    background-color: #262f3e;
  }

  .scheme_default .top_panel {
    background-color: #ffffff;
  }

  .scheme_default .post_item {
    color: #797e87;
  }

  .scheme_default .post_meta,
.scheme_default .post_meta_item,
.scheme_default .post_meta_item:after,
.scheme_default .post_meta_item:hover:after,
.scheme_default .post_meta_item a,
.scheme_default .post_info .post_info_item,
.scheme_default .post_info .post_info_item a {
    color: #797e87;
  }

  .scheme_default .post_info .post_info_item a.post_info_date {
    color: #ff0000;
  }

  .scheme_default .post_info .post_info_item a.post_info_author {
    color: #262f3e;
  }

  .scheme_default .post_info .post_info_item a.post_info_author:hover {
    color: #ff0000;
  }

  .scheme_default .post_meta_item.post_categories a {
    color: #ffffff;
    background-color: #ff0000;
  }

  .scheme_default .post_meta_item.post_categories a:hover {
    color: #ffffff;
    background-color: #081324;
  }

  .scheme_default .post_meta_item.post_date {
    color: #ff0000;
  }

  .scheme_default .post_meta_item a:hover,
.scheme_default .post_info .post_info_item a:hover {
    color: #262f3e;
  }

  .scheme_default .post_item .post_title a:hover {
    color: #ff0000;
  }

  .scheme_default .post_meta_item .social_items,
.scheme_default .post_meta_item .social_items:before {
    border-color: #e3e3e3;
    color: #797e87;
  }

  .scheme_default .post_item_single .post_content > .post_meta_single .share_meta_label,
.scheme_default .post_item_single .post_content .post_tags .post_meta_label {
    color: #262f3e;
  }

  .scheme_default .post_item_single .post_content .post_meta_single .post_tags a {
    color: #797e87;
    background-color: #f4f4f4;
  }

  .scheme_default .post_item_single .post_content .post_meta_single .post_tags a:hover {
    color: #ffffff;
    background-color: #ff0000;
  }

  .scheme_default .post_item_single .post_content .post_meta .post_share .socials_type_block .social_item .social_icon {
    color: #ffffff !important;
    background-color: #262f3e;
  }

  .scheme_default .post_item_single .post_content .post_meta .post_share .socials_type_block .social_item:hover .social_icon {
    color: #ffffff !important;
    background-color: #ff0000;
  }

  .scheme_default .nav-links-single .screen-reader-text {
    color: #ff0000;
  }

  .scheme_default .nav-links-single .screen-reader-text:hover {
    color: #081324 !important;
  }

  .scheme_default .nav-links-single .nav-links {
    border-color: #e3e3e3;
  }

  .scheme_default .nav-links-single .nav-links a:hover .post-title {
    color: #ff0000;
  }

  .scheme_default .related_wrap {
    border-color: #e3e3e3;
  }

  .scheme_default .related_wrap .post_title a:hover {
    color: #ff0000;
  }

  .scheme_default .comments_form_wrap {
    border-color: #e3e3e3;
  }

  .scheme_default .sidebar_inner .widget + .widget {
    border-color: #e3e3e3;
  }

  .scheme_default .sidebar .widget {
    background-color: #ffffff;
  }

  .scheme_default .widget ul > li:before {
    background-color: #ff0000;
  }

  .scheme_default .widget_calendar caption {
    color: #ffffff;
    background-color: #ff0000;
  }

  .scheme_default .widget_calendar th {
    color: #262f3e;
  }

  .scheme_default .widget_calendar tbody td {
    color: #797e87 !important;
  }

  .scheme_default .widget_calendar td#today {
    color: #ffffff !important;
  }

  .scheme_default .widget_calendar td#today {
    color: #ffffff;
  }

  .scheme_default .widget_calendar td#today:before {
    background-color: #ff0000;
  }

  .scheme_default .widget_calendar #prev a {
    color: #ff0000;
  }

  .scheme_default .widget_calendar #prev a:hover {
    color: #081324;
  }

  .scheme_default .widget_calendar td#prev a:before {
    background-color: #ffffff;
  }

  .scheme_default .widget_categories li {
    color: #262f3e;
  }

  .scheme_default .widget_tag_cloud a {
    color: #797e87;
    background-color: #f4f4f4;
  }

  .scheme_default .widget_tag_cloud a:hover {
    color: #ffffff !important;
    background-color: #ff0000;
  }

  .scheme_default .widget_search input.search-submit {
    background-color: #ff0000;
  }

  .scheme_default .widget .widget_title {
    background-color: #081224;
    color: #ffffff;
    border-color: #ff0000;
  }

  .scheme_default .recentcomments + .recentcomments,
.scheme_default aside .post_item + .post_item,
.scheme_default .widget_categories ul li + li {
    border-color: #e3e3e3;
  }

  .scheme_default .recentcomments .comment-author-link {
    color: #262f3e;
  }

  .scheme_default .single-post .post_item_single {
    background-color: #ffffff;
  }

  .scheme_default .sc_layouts_menu_mobile_button .sc_layouts_item_icon {
    background-color: #ff0000 !important;
    color: #ffffff !important;
  }

  .scheme_default .sc_layouts_menu_mobile_button .sc_layouts_item_icon:hover {
    background-color: #262f3e !important;
    color: #ffffff !important;
  }

  .scheme_default .sc_layouts_menu_mobile_button .sc_layouts_item_icon {
    color: #ffffff !important;
    background-color: #8c9097 !important;
  }

  .scheme_default .sc_layouts_menu_mobile_button .sc_layouts_item_icon:hover {
    color: #ffffff !important;
    background-color: #262f3e !important;
  }

  .scheme_default .nav-links-single .nav-links .nav-previous {
    background-color: #ffffff;
  }

  .scheme_default .comments_wrap .comments_field input,
.scheme_default .comments_wrap .comments_field textarea {
    background-color: #ffffff;
  }

  .scheme_default .widget_twitter .widget_content li {
    color: #797e87;
  }

  .scheme_default .widget_twitter .widget_content .sc_twitter_list li {
    color: #797e87;
  }

  .scheme_default .widget_twitter .widget_content .sc_twitter_list li:before {
    color: #ffffff !important;
    background-color: #ff0000 !important;
  }

  .scheme_default .widget_twitter .widget_content ul li + li {
    border-color: #e3e3e3;
  }

  .scheme_default .widget_twitter .widget_content ul a {
    border-color: #797e87;
    color: #797e87;
  }

  .scheme_default .widget_twitter .widget_content ul a:hover {
    border-color: #ff0000;
    color: #ff0000;
  }

  .scheme_default .socials_wrap .social_item .social_icon {
    background-color: #081224;
  }

  .scheme_default .socials_wrap .social_item .social_icon {
    color: #ffffff;
  }

  .scheme_default .socials_wrap .social_item:hover .social_icon {
    background-color: #ff0000;
  }

  .scheme_default .socials_wrap .social_item:hover .social_icon {
    color: #ffffff;
  }

  .scheme_default .trx_addons_scroll_to_top {
    color: #ffffff;
    border-color: #ff0000;
    background-color: #ff0000;
  }

  .scheme_default .trx_addons_scroll_to_top:hover {
    color: #ffffff;
    border-color: #ff150d;
    background-color: #262f3e;
  }

  .scheme_default .elementor-divider-separator {
    border-color: #e3e3e3;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__colors-dark.css ; media=all */
@media all {
  .scheme_dark {
    color: #848992;
  }

  .scheme_dark h1,
.scheme_dark h2,
.scheme_dark li a {
    color: #ffffff;
  }

  .scheme_dark li a:hover {
    color: #ff0000;
  }

  .scheme_dark a {
    color: #ff0000;
  }

  .scheme_dark a:hover {
    color: #ffffff;
  }

  .scheme_dark ul > li:before {
    color: #ff0000;
  }

  .scheme_dark input[type=text],
.scheme_dark input[type=email],
.scheme_dark form.mc4wp-form input[type=email] {
    color: #b7b7b7;
    border-color: #262f3e;
    background-color: #262f3e;
  }

  .scheme_dark input[type=text]:focus,
.scheme_dark input[type=email]:focus,
.scheme_dark form.mc4wp-form input[type=email]:focus {
    color: #ffffff;
    border-color: #353535;
    background-color: #262f3e;
  }

  .scheme_dark input[placeholder]::-webkit-input-placeholder {
    color: #6f6f6f;
    opacity: 1;
  }

  .scheme_dark input[placeholder]::-moz-placeholder {
    color: #6f6f6f;
    opacity: 1;
  }

  .scheme_dark input[placeholder]:-ms-input-placeholder {
    color: #6f6f6f;
    opacity: 1;
  }

  .scheme_dark input[placeholder]::placeholder {
    color: #6f6f6f;
    opacity: 1;
  }

  .scheme_dark input[type=checkbox] + label:before {
    border-color: #262f3e !important;
  }

  .scheme_dark button:not(.components-button),
.scheme_dark input[type=submit],
.scheme_dark .mc4wp-form input[type=submit] {
    color: #ffffff;
    background-color: #ff0000;
  }

  .scheme_dark .search_wrap .search_submit:before {
    color: #b7b7b7;
  }

  .scheme_dark input[type=submit][disabled],
.scheme_dark input[type=submit][disabled]:hover {
    background-color: rgba(255, 255, 255, 0.4) !important;
    color: #081224 !important;
  }

  .scheme_dark button:hover,
.scheme_dark button:focus,
.scheme_dark input[type=submit]:hover,
.scheme_dark input[type=submit]:focus,
.scheme_dark .mc4wp-form input[type=submit]:hover,
.scheme_dark .mc4wp-form input[type=submit]:focus {
    color: #262f3e;
    background-color: #ffffff;
  }

  .scheme_dark .search_wrap .search_submit:hover:before {
    color: #ffffff;
  }

  .scheme_dark.footer_wrap {
    color: #848992;
    background-color: #081224;
  }

  .scheme_dark .sc_layouts_title .sc_layouts_title_breadcrumbs,
.scheme_dark .sc_layouts_title .sc_layouts_title_breadcrumbs a {
    color: #ffffff;
  }

  .scheme_dark .sc_layouts_title .sc_layouts_title_breadcrumbs a:hover {
    color: #ffffff;
  }

  .scheme_dark .sc_layouts_menu_nav > li > a {
    color: #ffffff;
  }

  .scheme_dark .sc_layouts_menu_nav > li > a:hover {
    color: #ff0000 !important;
  }

  .scheme_dark .menu_mobile_inner {
    color: #a6a6a6;
    background-color: #081224;
  }

  .scheme_dark .menu_mobile_close:before,
.scheme_dark .menu_mobile_close:after {
    border-color: #ffffff;
  }

  .scheme_dark .menu_mobile_close:hover:before,
.scheme_dark .menu_mobile_close:hover:after {
    border-color: #ff0000;
  }

  .scheme_dark .menu_mobile_inner a,
.scheme_dark .menu_mobile_inner .menu_mobile_nav_area li:before {
    color: #ffffff;
  }

  .scheme_dark .menu_mobile_inner a:hover,
.scheme_dark .menu_mobile_inner .menu_mobile_nav_area li:hover:before {
    color: #ff0000;
  }

  .scheme_dark .menu_mobile_inner .search_mobile .search_submit {
    color: #6f6f6f;
  }

  .scheme_dark .menu_mobile_inner .search_mobile .search_submit:focus,
.scheme_dark .menu_mobile_inner .search_mobile .search_submit:hover {
    color: #ffffff;
  }

  .scheme_dark.footer_wrap {
    background-color: #081224;
    color: #a6a6a6;
  }

  .scheme_dark.footer_wrap h2 {
    color: #ffffff;
  }

  .scheme_dark.footer_wrap a {
    color: #ffffff;
  }

  .scheme_dark.footer_wrap a:hover {
    color: #ff0000;
  }

  .scheme_dark .elementor-divider-separator {
    border-color: #262f3e;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/trx_addons/css/__responsive.css ; media=all */
@media (max-width: 1439px) {
  .sc_layouts_row:not(.sc_layouts_row_type_narrow),
.sc_layouts_row:not(.sc_layouts_row_type_narrow) input[type=text] {
    font-size: 14px;
  }

  .sc_layouts_row .sc_layouts_logo img {
    max-height: 4.5em;
  }
}
@media (max-width: 1279px) {
  .sc_layouts_row:not(.sc_layouts_row_type_narrow),
.sc_layouts_row:not(.sc_layouts_row_type_narrow) input[type=text] {
    font-size: 13px;
    line-height: 21px;
  }
}
@media (max-width: 1023px) {
  .trx_addons_scroll_to_top {
    right: 1em;
  }

  .sc_item_title {
    letter-spacing: 1px;
  }

  .sc_layouts_column .sc_layouts_item {
    margin-right: 1.15em;
  }

  .sc_layouts_column_align_right .sc_layouts_item {
    margin-left: 1.15em;
    margin-right: 0;
  }

  .sc_layouts_title_title {
    font-size: 0.8em;
  }

  .sc_layouts_menu_nav > li > a {
    padding: 0.7em;
  }

  .sc_layouts_menu_nav > li.menu-item-has-children > a {
    padding-right: 1.3em;
  }

  .sc_layouts_menu_nav > li.menu-item-has-children > a:after {
    right: 0.5em;
  }
}
@media (max-width: 767px) {
  .sc_item_title {
    letter-spacing: 0;
  }

  .hide_on_mobile,
.sc_layouts_hide_on_mobile {
    display: none !important;
  }

  .socials_wrap .social_item .social_icon {
    width: 2em;
    height: 2em;
    line-height: 2em;
    text-align: center;
  }

  .socials_wrap .social_item {
    margin: 6px 6px 0 0;
  }

  .sc_layouts_menu_mobile_button {
    display: inline-block !important;
  }
}
@media (max-width: 479px) {
  .sc_layouts_row:not(.sc_layouts_row_type_narrow),
.sc_layouts_row:not(.sc_layouts_row_type_narrow) input[type=text] {
    font-size: 13px;
    line-height: 19px;
  }

  .sc_layouts_title_title {
    font-size: 0.7em;
  }
}
@media (min-width: 2160px) {
  .sc_layouts_hide_on_wide {
    display: none !important;
  }
}
@media (min-width: 1680px) and (max-width: 2159px) {
  .sc_layouts_hide_on_desktop {
    display: none !important;
  }
}
@media (min-width: 1280px) and (max-width: 1679px) {
  .sc_layouts_hide_on_notebook {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .sc_layouts_hide_on_tablet {
    display: none !important;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__responsive.css ; media=all */
@media (max-width: 1439px) {
  .content_wrap {
    width: 1100px;
  }

  body.body_style_wide:not(.expand_content) [class*=content_wrap] > .content {
    width: 704.06885759px;
  }

  [class*=content_wrap] > .sidebar {
    width: 374.4131455399px;
  }

  .widget_area .post_item .post_thumb,
aside .post_item .post_thumb {
    margin: 0 20px 3px 0;
  }

  .sc_layouts_row:not(.sc_layouts_row_type_narrow),
.sc_layouts_row:not(.sc_layouts_row_type_narrow) input[type=text] {
    font-size: 14px;
    line-height: inherit;
  }

  .sc_layouts_row_type_normal .search_wrap .search_field {
    width: 16em;
  }

  .related_wrap.related_style_classic .post_title + .post_content_inner {
    margin-top: 10px;
  }

  .single-post .post_item_single .post_header_single {
    padding: 20px 40px 20px;
  }

  .single-post .post_item_single .post_content_single {
    padding: 40px;
  }

  .single-post .header_content_wrap + .post_content_single {
    padding-top: 0;
  }

  .widget_recent_posts .post_info > .post_info_item.post_info_posted:first-child {
    display: block;
  }

  .widget_recent_posts .post_info > .post_info_item.post_info_posted_by:nth-child(2):before {
    display: none;
  }

  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 1130px;
  }

  .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 1279px) {
  .sc_layouts_title_caption {
    font-size: 4rem;
  }

  h1 {
    font-size: 3.7em;
    margin-top: 55px;
    margin-bottom: 22px;
    letter-spacing: 1.5px;
  }

  h2 {
    font-size: 3em;
    margin-top: 25px;
    margin-bottom: 20px;
    letter-spacing: 1.5px;
  }

  h3 {
    font-size: 2.4em;
    margin-top: 40px;
    margin-bottom: 20px;
    letter-spacing: 1px;
  }

  h5 {
    font-size: 1.6em;
    margin-top: 35px;
    margin-bottom: 18px;
  }

  h6 {
    font-size: 1.2em;
    margin-top: 30px;
    margin-bottom: 16px;
  }

  p,
ul {
    margin-bottom: 1.4em;
  }

  .widget_categories ul li {
    padding: 10px 0;
  }

  .widget_categories ul {
    font-size: 17px;
    letter-spacing: 1.5px;
  }

  .widget_area .post_item .post_info .post_info_item + .post_info_item:before,
aside .post_item .post_info .post_info_item + .post_info_item:before {
    margin: 0 0 0 7px;
  }

  .widget_area .post_item .post_thumb,
aside .post_item .post_thumb {
    margin: 0 18px 3px 0;
  }

  .content_wrap {
    width: 964px !important;
  }

  body:not(.expand_content):not(.body_style_fullwide):not(.body_style_fullscreen) [class*=content_wrap] > .content {
    width: 617.0203442879px;
  }

  [class*=content_wrap] > .sidebar {
    width: 328.1220657277px;
  }

  .columns_wrap {
    margin-right: -30px;
  }

  .columns_wrap > [class*=column-] {
    padding-right: 30px;
  }

  .columns_wrap.columns_padding_bottom > [class*=column-] {
    padding-bottom: 30px;
  }

  form button,
input[type=submit] {
    padding: 1em 3em;
  }

  .widget_calendar td {
    line-height: 2.5em;
  }

  .widget_calendar td#today:before {
    width: 2.5em;
    height: 2.5em;
    line-height: 2.5em;
  }

  .widget_calendar caption {
    padding: 12px;
  }

  .widget_tag_cloud a {
    padding: 5px 13px;
    margin: 0 5px 6px 0;
  }

  .widget_twitter .widget_content ul > li:before {
    width: 34px;
    height: 34px;
    line-height: 34px;
    font-size: 1.3em;
  }

  .widget_twitter .widget_content ul li {
    min-height: 34px;
  }

  .widget_twitter .widget_content ul li {
    padding-left: 50px;
  }

  table th,
table td {
    padding: 1rem;
  }

  .footer_wrap .sc_layouts_menu > ul > li {
    font-size: 15px;
  }

  .sc_layouts_row_type_normal .search_wrap .search_field {
    width: 10em;
  }

  .sc_layouts_menu_nav > li > a,
.sc_layouts_menu_nav > li.menu-item-has-children > a {
    padding: 0.85em 1em;
  }

  .trx_addons_scroll_to_top {
    right: 1px;
    width: 2.2em;
    height: 2.2em;
    line-height: 2.2em;
  }

  .sidebar .widget {
    padding: 20px;
  }

  .widget .widget_title {
    margin-bottom: 20px;
    padding: 16px 13px;
  }

  .sidebar_inner .widget .widget_title {
    margin-left: -20px;
    margin-right: -20px;
    margin-top: -20px;
  }

  .sc_layouts_row_type_normal {
    padding: 0.8em 0;
  }

  .single-post .post_item_single .post_content_single {
    padding: 30px;
  }

  .single-post .header_content_wrap + .post_content_single {
    padding-top: 0;
  }

  .single-post .post_item_single .post_header_single {
    padding: 20px 30px 20px;
  }

  .nav-links-single .nav-links .post-title {
    margin: 10px 0 0;
    font-size: 25px;
  }

  .related_wrap {
    margin-top: 60px;
    padding-top: 60px;
  }

  .section_title {
    margin: 0 0 0.5em;
    font-size: 3em;
  }

  .related_wrap .post_title {
    font-size: 26px;
  }

  .related_wrap.related_style_classic .post_featured {
    margin-bottom: 1.8em;
  }

  .comments_form_wrap {
    margin-top: 60px;
    padding-top: 60px;
  }

  .related_wrap + .comments_wrap .comments_form_wrap {
    margin-top: 30px;
  }

  .nav-links {
    margin-top: 1.6em;
  }

  .copyright-text {
    font-size: 15px;
  }

  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 994px;
  }

  .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 1023px) {
  .sc_layouts_title_caption {
    font-size: 3rem;
  }

  h1 {
    font-size: 3em;
    margin-top: 45px;
    margin-bottom: 15px;
    letter-spacing: 1.3px;
  }

  h2 {
    font-size: 2.6em;
    margin-top: 42px;
    margin-bottom: 15px;
    letter-spacing: 1.3px;
  }

  h3 {
    font-size: 2.1em;
    margin-top: 35px;
    margin-bottom: 14px;
    letter-spacing: 1px;
  }

  h5 {
    font-size: 1.45em;
    margin-top: 30px;
    margin-bottom: 13px;
  }

  h6 {
    font-size: 1.1em;
    margin-top: 25px;
    margin-bottom: 12px;
  }

  .content_wrap {
    width: 708px !important;
  }

  body:not(.expand_content):not(.body_style_fullwide):not(.body_style_fullscreen) [class*=content_wrap] > .content {
    width: 100% !important;
    float: none !important;
  }

  [class*=content_wrap] > .sidebar {
    width: 100% !important;
    float: none !important;
    margin-top: 75px;
  }

  .sc_layouts_row_type_normal .search_wrap .search_field {
    width: 9em;
    padding: 0 40px 0 20px !important;
    height: 40px;
    line-height: 40px;
  }

  .sc_layouts_row_type_normal .search_wrap .search_submit {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }

  .sc_layouts_menu_nav > li > a,
.sc_layouts_menu_nav > li.menu-item-has-children > a {
    padding: 0.8em;
  }

  .sc_layouts_row_type_normal {
    padding: 0.6em 0;
  }

  form.mc4wp-form .mc4wp-form-fields input[type=submit] {
    min-width: 7.5em;
  }

  form.mc4wp-form .mc4wp-form-fields input[type=email] {
    padding: 0 8em 0 2em;
  }

  .copyright-text {
    font-size: 14px;
    line-height: 1.4em;
  }

  .footer_wrap .sc_layouts_menu > ul > li > a {
    padding: 0.5em 0.8em;
  }

  .footer_wrap .sc_layouts_menu > ul > li {
    font-size: 14px;
  }

  .sc_layouts_row_type_normal .sc_layouts_item_icon {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 13px;
  }

  .sc_layouts_row_type_normal .search_wrap .search_submit {
    width: 35px;
  }

  .sc_layouts_row_type_normal .search_wrap .search_field {
    width: 7em;
    padding: 0 30px 0 12px !important;
  }

  .post_meta_item.post_categories a {
    padding: 3px 10px;
  }

  .post_meta .post_meta_item:after {
    margin: 0 0.3em;
  }

  .socials_wrap {
    margin: -8px 0 0 0;
  }

  .socials_wrap .social_item {
    margin: 8px 8px 0 0;
  }

  .socials_wrap .social_item .social_icon {
    width: 36px;
    height: 36px;
    line-height: 36px;
    font-size: 18px;
  }

  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 738px;
  }

  .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 767px) {
  .sc_layouts_title_caption {
    font-size: 2.6rem;
  }

  h1 {
    font-size: 2.6em;
    margin-top: 40px;
    margin-bottom: 15px;
    letter-spacing: 1.3px;
  }

  h2 {
    font-size: 2.3em;
    margin-top: 38px;
    margin-bottom: 15px;
    letter-spacing: 1.3px;
  }

  h3 {
    font-size: 2em;
    margin-top: 30px;
    margin-bottom: 14px;
    letter-spacing: 1px;
  }

  h5 {
    font-size: 1.3em;
    margin-top: 28px;
    margin-bottom: 13px;
  }

  h6 {
    font-size: 1em;
    margin-top: 23px;
    margin-bottom: 12px;
  }

  .menu_mobile .sc_layouts_logo {
    margin: 2.4rem 0 0;
  }

  .content_wrap {
    width: 440px !important;
  }

  body:not(.expand_content):not(.body_style_fullwide):not(.body_style_fullscreen) [class*=content_wrap] > .content {
    margin-bottom: 0;
  }

  [class*=content_wrap] > .sidebar {
    margin-top: 60px;
  }

  .columns_wrap {
    margin-right: -20px;
  }

  .columns_wrap > [class*=column-] {
    padding-right: 20px;
  }

  .columns_wrap.columns_padding_bottom > [class*=column-] {
    padding-bottom: 20px;
  }

  .columns_wrap:not(.columns_fluid) > [class*=column-] {
    width: 100%;
  }

  .post_item_single .post_content > .post_meta_single .post_tags,
.post_item_single .post_content > .post_meta_single .post_share {
    max-width: none;
    float: none;
    margin-top: 0;
  }

  .post_item_single .post_content > .post_meta_single .post_share {
    margin-top: 2em;
  }

  .post_item_single .post_content > .post_meta_single .post_share .social_item:first-child {
    margin-left: 0;
  }

  form button,
input[type=submit] {
    padding: 0.84em 2.5em;
  }

  input[type=text],
input[type=password],
input[type=email],
input[type=search],
textarea,
form.mc4wp-form .mc4wp-form-fields input[type=email] {
    padding: 0.8em 1.5em;
  }

  .menu_mobile .menu_mobile_inner {
    overflow-y: auto;
    top: 0;
    bottom: 0;
  }

  .sc_layouts_menu_nav > li > a,
.sc_layouts_menu_nav > li.menu-item-has-children > a {
    padding: 0.55em;
  }

  .sidebar .widget + .widget {
    margin-top: 20px;
  }

  .widget_area .post_item .post_title,
aside .post_item .post_title {
    font-size: 16px;
  }

  .widget_calendar td {
    line-height: 2.7em;
  }

  .single-post .post_item_single .post_header_single {
    padding: 15px 20px 15px;
  }

  .single-post .post_item_single .post_content_single {
    padding: 20px;
  }

  .single-post .header_content_wrap + .post_content_single {
    padding-top: 0;
  }

  .post_item_single .post_content > .post_meta_single .post_share {
    margin-top: 0.5em;
  }

  .post_item_single .post_content > .post_meta_single .share_meta_label {
    margin-right: 5px;
  }

  .nav-links-single .nav-links {
    margin-top: 40px;
  }

  .nav-links-single .nav-links .post-title {
    margin: 10px 0 0;
    font-size: 20px;
  }

  .related_wrap {
    margin-top: 40px;
    padding-top: 40px;
  }

  .section_title {
    font-size: 28px;
    letter-spacing: 1px;
  }

  .comments_form_wrap {
    margin-top: 40px;
    padding-top: 40px;
  }

  .comments_wrap .comments_field textarea {
    min-height: 10.5em;
  }

  .comments_wrap .comments_field input,
.comments_wrap .comments_field textarea {
    padding: 0.8em 1.5em;
  }

  body div .sc_layouts_column .copyright-text {
    text-align: center;
    display: block;
    width: 100% !important;
  }

  .sc_item_title {
    letter-spacing: 1px;
  }

  .widget_recent_posts .post_info > .post_info_item.post_info_posted:first-child {
    display: inline-block;
  }

  .widget_recent_posts .post_info > .post_info_item.post_info_posted_by:nth-child(2):before {
    display: inline;
  }

  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 460px;
  }

  .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 479px) {
  p,
ul {
    margin-bottom: 1.1em;
  }

  table th,
table td {
    padding: 0.5rem;
  }

  body {
    letter-spacing: 0;
    line-height: 1.6em;
  }

  .sc_layouts_title_caption {
    font-size: 2.2rem;
  }

  h1 {
    font-size: 2.2em;
    margin-top: 28px;
    margin-bottom: 12px;
    letter-spacing: 1px;
    line-height: 0.93em;
  }

  h2 {
    font-size: 2em;
    margin-top: 28px;
    margin-bottom: 11px;
    letter-spacing: 1px;
    line-height: 0.96em;
  }

  h3 {
    font-size: 1.8em;
    margin-top: 23px;
    margin-bottom: 10px;
    letter-spacing: 1px;
    line-height: 0.91em;
  }

  h5 {
    font-size: 1.3em;
    margin-top: 20px;
    margin-bottom: 8px;
    line-height: 1.06em;
  }

  h6 {
    font-size: 1em;
    margin-top: 20px;
    margin-bottom: 8px;
    line-height: 1.23em;
  }

  .menu_mobile .sc_layouts_logo {
    margin: 2rem 0 0;
  }

  .menu_mobile_close {
    width: 3em;
    height: 3em;
    line-height: 3em;
  }

  .content_wrap {
    width: 280px !important;
  }

  [class*=content_wrap] > .sidebar {
    margin-top: 50px;
  }

  .columns_wrap {
    margin-right: -20px;
  }

  .columns_wrap > [class*=column-] {
    padding-right: 20px;
  }

  .columns_wrap.columns_padding_bottom > [class*=column-] {
    padding-bottom: 20px;
  }

  .columns_wrap:not(.columns_fluid) > [class*=column-] {
    width: 100%;
  }

  .menu_mobile .menu_mobile_nav_area > ul > li > a {
    font-size: 18px;
    line-height: 1.5em;
  }

  .menu_mobile .menu_mobile_nav_area li > a {
    font-size: 14px;
    line-height: 1.5em;
    width: 280px;
  }

  .menu_mobile .menu_mobile_nav_area li li .open_child_menu {
    top: -1px;
  }

  .menu_mobile_close:before,
.menu_mobile_close:after {
    width: 35%;
  }

  .sidebar .widget {
    width: 100% !important;
  }

  .sidebar .widget + .widget {
    margin-top: 20px !important;
  }

  .nav-links-single .nav-links .nav-previous {
    padding-right: 8px;
  }

  .nav-links-single .nav-links .post-title {
    letter-spacing: 0;
  }

  .widget_area .post_item .post_title,
aside .post_item .post_title {
    font-size: 15px;
  }

  .widget_calendar td {
    line-height: 2.2em;
  }

  .widget_calendar th {
    font-size: 18px !important;
  }

  .widget_calendar caption {
    padding: 8px;
  }

  .widget_tag_cloud a {
    padding: 4px 12px;
    margin: 0 4px 5px 0;
  }

  .widget_twitter .widget_content ul li {
    padding-left: 45px;
  }

  .widget_twitter .widget_content ul li + li:before {
    margin-top: 14px;
  }

  .recentcomments > a {
    font-size: 16px;
    line-height: 20px;
  }

  form.mc4wp-form .mc4wp-form-fields input[type=email] {
    height: 40px;
    line-height: 40px;
  }

  form.mc4wp-form .mc4wp-form-fields input[type=submit] {
    height: 40px;
    line-height: 40px;
    font-size: 13px;
  }

  .widget_search .search-field {
    padding: 0.8em 1.5em !important;
    padding-right: 3em !important;
  }

  .widget_search input.search-submit {
    width: 4.5em;
  }

  .widget_search form:after {
    width: 4em;
  }

  .widget_calendar td#today:before {
    width: 2.3em;
    height: 2.3em;
    line-height: 2.3em;
  }

  form button,
input[type=submit] {
    font-size: 13px;
  }

  .sc_layouts_row_type_normal .sc_layouts_item_icon {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }

  .sc_layouts_menu_mobile_button .sc_layouts_item_icon {
    font-size: 25px !important;
  }

  .sc_item_title {
    letter-spacing: 1px;
  }

  .nav-links {
    margin-top: 1.3em;
  }

  .single-post .post_item_single .post_header_single {
    padding: 15px 20px 15px;
  }

  .related_wrap .post_title {
    font-size: 24px;
  }

  .related_wrap.related_style_classic .post_title + .post_content_inner {
    margin-top: 8px;
  }

  .comments_wrap .comments_author,
.comments_wrap .comments_email {
    width: 100%;
  }

  .comments_wrap .comments_field {
    margin-bottom: 15px;
  }

  .related_wrap + .comments_wrap .comments_form_wrap {
    margin-top: 20px;
  }

  .post_item_single .post_content > .post_meta_single .post_tags a {
    padding: 5px 12px;
  }

  .post_item_single .post_content > .post_meta_single .post_tags a {
    padding: 5px 12px;
  }

  .related_wrap.related_style_classic .post_featured {
    margin-bottom: 1.45em;
  }

  .widget_tag_cloud .tagcloud {
    margin-bottom: -8px;
  }

  .widget ul > li:before {
    top: 0.7em;
  }

  .widget_recent_posts .post_info > .post_info_item.post_info_posted:first-child {
    display: block;
  }

  .widget_recent_posts .post_info > .post_info_item.post_info_posted_by:nth-child(2):before {
    display: none;
  }

  .widget_twitter .widget_content ul a {
    display: inline;
    hyphens: manual;
  }

  .nav-links-single .nav-links .nav-previous {
    padding: 20px 15px 18px;
  }

  .nav-links-single .nav-links .post-title {
    margin: 8px 0 0;
  }

  .nav-links-single .nav-links .screen-reader-text:hover,
.nav-links-single .nav-links .screen-reader-text {
    font-size: 12px !important;
  }

  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 300px;
  }

  .elementor-column-gap-extended > .elementor-row > .elementor-column > .elementor-element-populated {
    padding-left: 10px;
    padding-right: 10px;
  }
}
/*! CSS Used from: Embedded */
.recentcomments a {
  display: inline !important;
  padding: 0 !important;
  margin: 0 !important;
}

/*! CSS Used from: Embedded */
.elementor-6 .elementor-element.elementor-element-bef9ba7 {
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.08);
}

.elementor-6 .elementor-element.elementor-element-d06c25b {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-6 .elementor-element.elementor-element-214e821 .logo_image {
  max-height: 80px;
}

.elementor-6 .elementor-element.elementor-element-f2e75ce {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-6 .elementor-element.elementor-element-b70eb50:not(.elementor-motion-effects-element-type-background) {
  background-image: url(../../assets/images/header1.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.elementor-6 .elementor-element.elementor-element-b70eb50 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-6 .elementor-element.elementor-element-ed1b682 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-6 .elementor-element.elementor-element-6850b4b .elementor-spacer-inner {
  height: 285px;
}

.elementor-6 .elementor-element.elementor-element-b76221f .elementor-spacer-inner {
  height: 180px;
}

.elementor-6 .elementor-element.elementor-element-612f306 .sc_layouts_title {
  min-height: 0px;
}

.elementor-6 .elementor-element.elementor-element-01551c2 .elementor-spacer-inner {
  height: 61px;
}

@media (max-width: 1024px) {
  .elementor-6 .elementor-element.elementor-element-b76221f .elementor-spacer-inner {
    height: 120px;
  }

  .elementor-6 .elementor-element.elementor-element-01551c2 .elementor-spacer-inner {
    height: 50px;
  }
}
@media (min-width: 768px) {
  .elementor-6 .elementor-element.elementor-element-d06c25b {
    width: 27.829%;
  }

  .elementor-6 .elementor-element.elementor-element-f2e75ce {
    width: 72.171%;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .elementor-6 .elementor-element.elementor-element-d06c25b {
    width: 32%;
  }

  .elementor-6 .elementor-element.elementor-element-f2e75ce {
    width: 68%;
  }
}
@media (max-width: 767px) {
  .elementor-6 .elementor-element.elementor-element-d06c25b {
    width: 70%;
  }

  .elementor-6 .elementor-element.elementor-element-f2e75ce {
    width: 30%;
  }

  .elementor-6 .elementor-element.elementor-element-b76221f .elementor-spacer-inner {
    height: 65px;
  }

  .elementor-6 .elementor-element.elementor-element-01551c2 .elementor-spacer-inner {
    height: 30px;
  }
}
/*! CSS Used from: Embedded */
.elementor-6 .elementor-element.elementor-element-bef9ba7 {
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.08);
}

.elementor-6 .elementor-element.elementor-element-d06c25b {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-6 .elementor-element.elementor-element-214e821 .logo_image {
  max-height: 80px;
}

.elementor-6 .elementor-element.elementor-element-f2e75ce {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-6 .elementor-element.elementor-element-b70eb50:not(.elementor-motion-effects-element-type-background) {
  background-image: url(../../assets/images/header1.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.elementor-6 .elementor-element.elementor-element-b70eb50 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-6 .elementor-element.elementor-element-ed1b682 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-6 .elementor-element.elementor-element-6850b4b .elementor-spacer-inner {
  height: 285px;
}

.elementor-6 .elementor-element.elementor-element-b76221f .elementor-spacer-inner {
  height: 180px;
}

.elementor-6 .elementor-element.elementor-element-612f306 .sc_layouts_title {
  min-height: 0px;
}

.elementor-6 .elementor-element.elementor-element-01551c2 .elementor-spacer-inner {
  height: 61px;
}

@media (max-width: 767px) {
  .elementor-6 .elementor-element.elementor-element-d06c25b {
    width: 70%;
  }

  .elementor-6 .elementor-element.elementor-element-f2e75ce {
    width: 30%;
  }

  .elementor-6 .elementor-element.elementor-element-b76221f .elementor-spacer-inner {
    height: 65px;
  }

  .elementor-6 .elementor-element.elementor-element-01551c2 .elementor-spacer-inner {
    height: 30px;
  }
}
@media (min-width: 768px) {
  .elementor-6 .elementor-element.elementor-element-d06c25b {
    width: 27.829%;
  }

  .elementor-6 .elementor-element.elementor-element-f2e75ce {
    width: 72.171%;
  }
}
@media (max-width: 1024px) and (min-width: 768px) {
  .elementor-6 .elementor-element.elementor-element-d06c25b {
    width: 32%;
  }

  .elementor-6 .elementor-element.elementor-element-f2e75ce {
    width: 68%;
  }
}
@media (max-width: 1024px) {
  .elementor-6 .elementor-element.elementor-element-b76221f .elementor-spacer-inner {
    height: 120px;
  }

  .elementor-6 .elementor-element.elementor-element-01551c2 .elementor-spacer-inner {
    height: 50px;
  }
}
/*! CSS Used from: Embedded */
.elementor-622 .elementor-element.elementor-element-09bdb0e {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-622 .elementor-element.elementor-element-f08c64c .elementor-spacer-inner {
  height: 84px;
}

.elementor-622 .elementor-element.elementor-element-eabacc7 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-622 .elementor-element.elementor-element-8e2b6db {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-622 .elementor-element.elementor-element-19f2f15 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-622 .elementor-element.elementor-element-37347fb {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-622 .elementor-element.elementor-element-54b219a {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-622 .elementor-element.elementor-element-baba68a .elementor-spacer-inner {
  height: 72px;
}

.elementor-622 .elementor-element.elementor-element-38e0052 .elementor-divider-separator {
  border-top-style: solid;
  border-top-width: 2px;
  width: 100%;
}

.elementor-622 .elementor-element.elementor-element-38e0052 .elementor-divider {
  padding-top: 0px;
  padding-bottom: 0px;
}

.elementor-622 .elementor-element.elementor-element-d85a5d0 .elementor-spacer-inner {
  height: 15px;
}

.elementor-622 .elementor-element.elementor-element-1c99334 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-622 .elementor-element.elementor-element-c6d692b {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-622 .elementor-element.elementor-element-87f9d0c {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-622 .elementor-element.elementor-element-fdaa68a .elementor-spacer-inner {
  height: 18px;
}

@media (min-width: 768px) {
  .elementor-622 .elementor-element.elementor-element-8e2b6db {
    width: 22.018%;
  }

  .elementor-622 .elementor-element.elementor-element-19f2f15 {
    width: 40.979%;
  }

  .elementor-622 .elementor-element.elementor-element-37347fb {
    width: 36.999%;
  }

  .elementor-622 .elementor-element.elementor-element-1c99334 {
    width: 60.092%;
  }

  .elementor-622 .elementor-element.elementor-element-c6d692b {
    width: 39.908%;
  }
}
@media (max-width: 1024px) {
  .elementor-622 .elementor-element.elementor-element-f08c64c .elementor-spacer-inner {
    height: 44px;
  }

  .elementor-622 .elementor-element.elementor-element-baba68a .elementor-spacer-inner {
    height: 37px;
  }
}
@media (max-width: 767px) {
  .elementor-622 .elementor-element.elementor-element-f08c64c .elementor-spacer-inner {
    height: 25px;
  }

  .elementor-622 .elementor-element.elementor-element-37347fb > .elementor-element-populated {
    margin: 3px 0px 0px 0px;
  }

  .elementor-622 .elementor-element.elementor-element-baba68a .elementor-spacer-inner {
    height: 17px;
  }

  .elementor-622 .elementor-element.elementor-element-d85a5d0 .elementor-spacer-inner {
    height: 9px;
  }

  .elementor-622 .elementor-element.elementor-element-fdaa68a .elementor-spacer-inner {
    height: 12px;
  }
}
/*! CSS Used from: Embedded */
.elementor-622 .elementor-element.elementor-element-09bdb0e {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-622 .elementor-element.elementor-element-f08c64c .elementor-spacer-inner {
  height: 84px;
}

.elementor-622 .elementor-element.elementor-element-eabacc7 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-622 .elementor-element.elementor-element-8e2b6db {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-622 .elementor-element.elementor-element-19f2f15 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-622 .elementor-element.elementor-element-37347fb {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-622 .elementor-element.elementor-element-54b219a {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-622 .elementor-element.elementor-element-baba68a .elementor-spacer-inner {
  height: 72px;
}

.elementor-622 .elementor-element.elementor-element-38e0052 .elementor-divider-separator {
  border-top-style: solid;
  border-top-width: 2px;
  width: 100%;
}

.elementor-622 .elementor-element.elementor-element-38e0052 .elementor-divider {
  padding-top: 0px;
  padding-bottom: 0px;
}

.elementor-622 .elementor-element.elementor-element-d85a5d0 .elementor-spacer-inner {
  height: 15px;
}

.elementor-622 .elementor-element.elementor-element-1c99334 {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-622 .elementor-element.elementor-element-c6d692b {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-622 .elementor-element.elementor-element-87f9d0c {
  -ms-flex-pack: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}

.elementor-622 .elementor-element.elementor-element-fdaa68a .elementor-spacer-inner {
  height: 18px;
}

@media (max-width: 1024px) {
  .elementor-622 .elementor-element.elementor-element-f08c64c .elementor-spacer-inner {
    height: 44px;
  }

  .elementor-622 .elementor-element.elementor-element-baba68a .elementor-spacer-inner {
    height: 37px;
  }
}
@media (max-width: 767px) {
  .elementor-622 .elementor-element.elementor-element-f08c64c .elementor-spacer-inner {
    height: 25px;
  }

  .elementor-622 .elementor-element.elementor-element-37347fb > .elementor-element-populated {
    margin: 3px 0px 0px 0px;
  }

  .elementor-622 .elementor-element.elementor-element-baba68a .elementor-spacer-inner {
    height: 17px;
  }

  .elementor-622 .elementor-element.elementor-element-d85a5d0 .elementor-spacer-inner {
    height: 9px;
  }

  .elementor-622 .elementor-element.elementor-element-fdaa68a .elementor-spacer-inner {
    height: 12px;
  }
}
@media (min-width: 768px) {
  .elementor-622 .elementor-element.elementor-element-8e2b6db {
    width: 22.018%;
  }

  .elementor-622 .elementor-element.elementor-element-19f2f15 {
    width: 40.979%;
  }

  .elementor-622 .elementor-element.elementor-element-37347fb {
    width: 36.999%;
  }

  .elementor-622 .elementor-element.elementor-element-1c99334 {
    width: 60.092%;
  }

  .elementor-622 .elementor-element.elementor-element-c6d692b {
    width: 39.908%;
  }
}
/*! CSS Used from: Embedded */
.fcunited_inline_1671874526 {
  background-color: #f4f4f4;
}

.trx_addons_inline_1743425229 img {
  max-height: 80px;
}

/*! CSS Used keyframes */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/**** News Items ****/
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/trx_addons/css/__styles.css ; media=all */
@media all {
  .post_meta_item {
    margin-left: 0.5em;
    white-space: nowrap;
  }

  .post_meta_item:before {
    vertical-align: top;
    line-height: inherit;
    margin: 0 0.3em 0 0;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/style.css ; media=all */
@media all {
  div,
span,
h4,
a,
article {
    font-family: inherit;
    font-size: 100%;
    line-height: inherit;
    font-weight: inherit;
    font-style: inherit;
    border: 0;
    outline: 0;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
  }

  article {
    display: block;
  }

  a {
    text-decoration: none;
    background: transparent;
  }

  a,
a:hover,
a:focus,
a:active {
    outline: 0;
  }

  a {
    -webkit-transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    -ms-transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  }

  h4 > a {
    display: block;
  }

  :active,
:focus {
    outline: 0;
  }

  img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
  }

  .post_featured {
    overflow: hidden;
    position: relative;
    margin-bottom: 1.8em;
  }

  .post_meta .post_meta_item {
    font-size: inherit;
    margin-left: 0;
    white-space: normal;
    -webkit-transition: color 0.3s ease;
    -ms-transition: color 0.3s ease;
    transition: color 0.3s ease;
  }

  .post_meta .post_meta_item:after {
    content: "";
    display: inline;
    font-family: inherit;
    font-size: inherit;
    font-weight: 400;
    font-style: normal;
    vertical-align: baseline;
    margin: 0 0.4em;
  }

  .post_meta .post_meta_item:last-child:after {
    display: none;
  }

  .post_meta_item.post_categories a {
    padding: 5px 16px;
    -webkit-border-radius: 15px;
    -ms-border-radius: 15px;
    border-radius: 15px;
    margin-right: 3px;
  }

  .post_meta_item.post_categories a:last-child {
    margin-right: 0;
  }

  .post_meta_item.post_categories {
    margin-left: -3px;
  }

  .post_featured .mask {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .post_meta_item.post_categories {
    margin-left: 0;
    margin-top: -5px !important;
    display: inline-block;
    margin-bottom: -5px !important;
  }

  .post_meta_item.post_categories a {
    display: inline-block;
    line-height: 20px;
    margin-bottom: 3px;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__plugins.css ; media=all */
@media all {
  .posts_container.columns_wrap .post_layout_classic {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 100%;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .post_layout_classic .post_layout_classic_wrap {
    padding: 30px;
  }

  .post_layout_classic {
    position: relative;
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    z-index: 1;
  }

  .post_layout_classic .post_featured[class*=hover_] {
    display: block;
  }

  .post_layout_classic .post_featured {
    margin-bottom: 8px;
    -webkit-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }

  .post_layout_classic .post_featured img {
    width: 100%;
  }

  .post_layout_classic .post_header {
    padding-top: 0.01px;
  }

  .post_layout_classic .post_title {
    font-size: 36px;
    line-height: 0.95em;
    margin: 0;
  }

  .post_layout_classic .post_meta {
    margin: 0;
  }

  .post_layout_classic .post_meta + .post_title {
    margin-top: 12px;
  }

  .post_layout_classic .post_header + .post_content {
    padding-top: 20px;
  }

  .post_featured[class*=hover_] {
    position: relative;
    -webkit-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
    display: inline-block;
    vertical-align: top;
  }

  .post_featured[class*=hover_],
.post_featured[class*=hover_] * {
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  .post_featured .mask {
    content: " ";
    position: absolute;
    z-index: 4;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .post_featured.hover_simple:hover .mask {
    opacity: 1;
  }

  .post_featured.hover_simple .icons {
    position: absolute;
    z-index: 6;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__custom.css ; media=all */
@media all {
  h4 {
    font-family: "BebasNeue", sans-serif;
    font-size: 2.143em;
    font-weight: 700;
    font-style: normal;
    line-height: 1.06em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 1.1px;
    margin-bottom: 0.6em;
  }

  .post_meta {
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.4em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0px;
    margin-top: 0.4em;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__colors-default.css ; media=all */
@media all {
  .post_item h4 a {
    color: #262f3e;
  }

  .scheme_default h4 a:hover {
    color: #ff0000;
  }

  .scheme_default a {
    color: #ff0000;
  }

  .scheme_default a:hover {
    color: #081324;
  }

  .scheme_default .post_item {
    color: #797e87;
  }

  .scheme_default .post_meta,
.scheme_default .post_meta_item,
.scheme_default .post_meta_item:after,
.scheme_default .post_meta_item:hover:after,
.scheme_default .post_meta_item a {
    color: #797e87;
  }

  .scheme_default .post_meta_item.post_categories a {
    color: #ffffff;
    background-color: #ff0000;
  }

  .scheme_default .post_meta_item.post_categories a:hover {
    color: #ffffff;
    background-color: #081324;
  }

  .scheme_default .post_meta_item.post_date,
.scheme_default .post_meta_item.post_date a {
    color: #ff0000;
  }

  .scheme_default .post_meta_item.post_date a:hover {
    color: #081324;
  }

  .scheme_default .post_date a:hover,
.scheme_default .post_meta_item a:hover {
    color: #262f3e;
  }

  .scheme_default .post_item .post_title a:hover {
    color: #ff0000;
  }

  .scheme_default .post_layout_classic {
    border-color: #e3e3e3;
  }

  .scheme_default .post_layout_classic {
    background-color: #ffffff;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__responsive.css ; media=all */
@media (max-width: 1439px) {
  .post_layout_classic .post_header + .post_content {
    padding-top: 10px;
  }

  .post_layout_classic .post_content {
    font-size: 1em;
  }

  .post_layout_classic .post_title {
    font-size: 32px;
  }
}
@media (max-width: 1279px) {
  h4 {
    font-size: 2em;
    margin-bottom: 5px;
    letter-spacing: 1px;
  }

  .post_layout_classic .post_title {
    font-size: 28px;
  }

  .post_layout_classic .post_featured {
    margin-bottom: 5px;
  }

  .post_layout_classic .post_layout_classic_wrap {
    padding: 25px;
  }
}
@media (max-width: 1023px) {
  h4 {
    font-size: 1.8em;
    margin-bottom: 13px;
    letter-spacing: 1px;
  }

  .post_meta_item.post_categories a {
    padding: 3px 10px;
  }

  .post_meta .post_meta_item:after {
    margin: 0 0.3em;
  }

  .post_layout_classic .post_featured {
    margin-bottom: 5px;
  }

  .blog_style_classic_3 .post_layout_classic_wrap {
    padding: 20px;
  }

  .post_layout_classic .post_meta_item.post_categories {
    margin-bottom: 0 !important;
  }

  .post_layout_classic .post_title {
    font-size: 24px;
  }
}
@media (max-width: 767px) {
  h4 {
    font-size: 1.6em;
    margin-top: 28px;
    margin-bottom: 13px;
    letter-spacing: 1px;
  }

  .post_layout_classic_wrap {
    padding: 25px;
  }
}
@media (max-width: 479px) {
  h4 {
    font-size: 1.6em;
    margin-top: 22px;
    margin-bottom: 9px;
    letter-spacing: 1px;
    line-height: 1.06em;
  }

  .post_layout_classic .post_header + .post_content {
    padding-top: 8px;
  }

  .post_layout_classic .post_featured {
    margin-bottom: 5px;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/woocommerce/assets/css/woocommerce.css?ver=3.6.2 ; media=all */
@media all {
  .screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/elementor/assets/css/frontend.min.css?ver=2.5.15 ; media=all */
@media all {
  .screen-reader-text {
    position: absolute;
    top: -10000em;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/style.css ; media=all */
@media all {
  div,
span,
h2,
a {
    font-family: inherit;
    font-size: 100%;
    line-height: inherit;
    font-weight: inherit;
    font-style: inherit;
    border: 0;
    outline: 0;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
  }

  nav {
    display: block;
  }

  a {
    text-decoration: none;
    background: transparent;
  }

  a,
a:hover,
a:focus,
a:active {
    outline: 0;
  }

  a {
    -webkit-transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    -ms-transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  }

  :active,
:focus {
    outline: 0;
  }

  .screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .screen-reader-text:hover,
.screen-reader-text:active,
.screen-reader-text:focus {
    display: block;
    top: 5px;
    left: 5px;
    width: auto;
    height: auto;
    font-size: 0.8em;
    line-height: normal;
    padding: 1em 1.5em;
    color: #21759b;
    background-color: #f1f1f1;
    border-radius: 3px;
    clip: auto !important;
    text-decoration: none;
    z-index: 100000;
    -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    -ms-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  }

  .nav-links {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 2em;
    text-align: left;
    border: none;
    list-style-type: none;
    clear: both;
  }

  .nav-links .page-numbers {
    display: inline-block;
    vertical-align: top;
    font-size: inherit;
    font-weight: inherit;
    margin: 3px 1px 0 0;
    padding: 0;
    border: none;
    width: 39px;
    height: 39px;
    line-height: 39px;
    text-align: center;
    -webkit-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease, background-position 0.3s ease;
    -ms-transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease, background-position 0.3s ease;
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease, background-position 0.3s ease;
  }

  .nav-links .page-numbers.prev {
    text-indent: 200px;
    overflow: hidden;
    position: relative;
    display: none;
  }

  .nav-links .page-numbers.prev:before {
    font-family: "fontello";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    text-indent: 0;
  }

  .nav-links .page-numbers.prev:before {
    content: "\e939";
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__custom.css ; media=all */
@media all {
  h2 {
    font-family: "BebasNeue", sans-serif;
    font-size: 3.429em;
    font-weight: 700;
    font-style: normal;
    line-height: 0.96em;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 1.75px;
    margin-bottom: 0.52em;
  }

  .nav-links {
    font-family: "BebasNeue", sans-serif;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__colors-default.css ; media=all */
@media all {
  .scheme_default a {
    color: #ff0000;
  }

  .scheme_default a:hover {
    color: #081324;
  }

  .scheme_default .nav-links .page-numbers {
    color: #262f3e;
  }

  .scheme_default .nav-links a.page-numbers:hover,
.scheme_default .nav-links .page-numbers.current {
    background-color: #081224;
    color: #ffffff;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__responsive.css ; media=all */
@media (max-width: 1279px) {
  h2 {
    font-size: 3em;
    margin-top: 25px;
    margin-bottom: 20px;
    letter-spacing: 1.5px;
  }

  .nav-links {
    margin-top: 1.6em;
  }
}
@media (max-width: 1023px) {
  h2 {
    font-size: 2.6em;
    margin-top: 42px;
    margin-bottom: 15px;
    letter-spacing: 1.3px;
  }

  .blog_style_classic_3 .pagination .nav-links {
    margin-top: 1.2em;
  }
}
@media (max-width: 767px) {
  h2 {
    font-size: 2.3em;
    margin-top: 38px;
    margin-bottom: 15px;
    letter-spacing: 1.3px;
  }

  .blog_style_classic_3 .pagination .nav-links {
    margin-top: 0.8em;
  }
}
@media (max-width: 479px) {
  h2 {
    font-size: 2em;
    margin-top: 28px;
    margin-bottom: 11px;
    letter-spacing: 1px;
    line-height: 0.96em;
  }

  .nav-links {
    margin-top: 1.3em;
  }

  .blog_style_classic_3 .pagination .nav-links {
    margin-top: 0.6em;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/sportspress/assets/css/sportspress-style.css?ver=2.3 ; media=all */
@media all {
  .sp-template * {
    box-sizing: border-box;
    font-weight: 400;
    text-transform: none;
    letter-spacing: normal;
    color: #333;
  }

  .sp-template a:hover {
    text-decoration: underline !important;
  }

  .sp-view-all-link a {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    text-decoration: none !important;
    color: rgba(51, 51, 51, 0.5) !important;
  }

  .sp-view-all-link a:hover {
    color: #333 !important;
    text-decoration: none !important;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/elementor/assets/css/frontend.min.css?ver=2.5.15 ; media=all */
@media all {
  .elementor *,
.elementor :after,
.elementor :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .elementor a {
    -webkit-box-shadow: none;
    box-shadow: none;
    text-decoration: none;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/style.css ; media=all */
@media all {
  a {
    font-family: inherit;
    font-size: 100%;
    line-height: inherit;
    font-weight: inherit;
    font-style: inherit;
    border: 0;
    outline: 0;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
  }

  a {
    text-decoration: none;
    background: transparent;
  }

  a,
a:hover,
a:focus,
a:active {
    outline: 0;
  }

  a {
    -webkit-transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    -ms-transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  }

  :active,
:focus {
    outline: 0;
  }

  .sp-view-all-link > a {
    -webkit-appearance: none;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    text-transform: none;
    white-space: nowrap;
    padding: 1.38em 3.5em;
    letter-spacing: 0;
    height: auto;
    border-width: 0 !important;
    -webkit-border-radius: 30px;
    -ms-border-radius: 30px;
    border-radius: 30px;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__plugins.css ; media=all */
@media all {
  .sp-template a:hover {
    text-decoration: none !important;
  }

  .sp-view-all-link > a {
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    text-transform: capitalize !important;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__custom.css ; media=all */
@media all {
  .sp-view-all-link > a {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 18px;
    text-decoration: none;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__colors-default.css ; media=all */
@media all {
  .scheme_default a {
    color: #ff0000;
  }

  .scheme_default a:hover {
    color: #081324;
  }

  .scheme_default .sp-view-all-link > a {
    color: #ffffff;
    background-color: #ff0000;
  }

  .scheme_default div .sp-view-all-link > a {
    color: #ffffff !important;
  }

  .scheme_default .sp-view-all-link > a:hover {
    color: #ffffff;
    background-color: #262f3e;
  }

  .scheme_default div .sp-view-all-link > a:hover {
    color: #ffffff;
  }

  .scheme_default .sp-template * {
    color: #797e87;
  }

  .scheme_default .sp-template a {
    color: #262f3e;
    font-weight: 400;
    font-size: 15px;
  }

  .scheme_default .sp-template a:hover {
    color: #ff0000;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__responsive.css ; media=all */
@media (max-width: 1279px) {
  .sp-view-all-link > a {
    padding: 1em 3em;
  }
}
@media (max-width: 767px) {
  .sp-view-all-link > a {
    padding: 0.84em 2.5em;
  }

  .sp-view-all-link > a {
    font-size: 13px !important;
  }
}
@media (max-width: 479px) {
  .sp-view-all-link > a {
    font-size: 13px;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/sportspress/assets/css/sportspress.css?ver=2.6.14 ; media=all */
@media all {
  .sp-template {
    margin-bottom: 1.5em;
  }

  .sp-scrollable-table-wrapper {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
  }

  .sp-data-table {
    width: 100%;
    table-layout: auto;
    border-spacing: 0;
  }

  .sp-data-table thead th {
    white-space: nowrap;
  }

  .sp-data-table thead .sorting {
    cursor: pointer;
  }

  .sp-data-table tbody a {
    text-decoration: none;
  }

  .sp-data-table .sorting:after {
    font-family: dashicons;
  }

  .sp-data-table .sorting:after {
    content: "\f156";
    color: transparent;
    position: absolute;
  }

  .sp-data-table .data-name {
    text-align: left;
  }

  .sp-data-table .data-number {
    width: 1px;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/plugins/sportspress/assets/css/sportspress-style.css?ver=2.3 ; media=all */
@media all {
  .sp-template,
.sp-data-table,
.sp-table-caption {
    font-family: "Roboto", "Helvetica", sans-serif;
  }

  .sp-template {
    clear: both;
    overflow: hidden;
  }

  .sp-template h4 {
    font-family: "Roboto", "Helvetica", sans-serif !important;
  }

  .sp-template {
    margin-bottom: 20px;
  }

  .sp-template *,
.sp-data-table *,
.sp-table-caption {
    box-sizing: border-box;
    text-transform: none;
    letter-spacing: normal;
    color: #333;
  }

  .sp-table-caption {
    margin: 0 !important;
    padding: 15px !important;
    background: #fff !important;
    border: 1px solid #e5e5e5 !important;
    border-bottom: none !important;
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    display: table !important;
    width: 100% !important;
  }

  .sp-template a:hover,
.sp-data-table a:hover {
    text-decoration: underline;
  }

  .sp-data-table {
    margin: 0;
    background: #fff;
    border-collapse: collapse;
    border: 1px solid #e5e5e5;
    width: 100%;
  }

  .sp-data-table tr {
    border: none;
  }

  .sp-data-table th,
.sp-data-table td {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    border: 1px solid #e5e5e5;
    padding: 5px 10px;
    text-align: left;
  }

  .sp-data-table th {
    font-size: 12px;
    font-weight: 500;
    background: #fff;
    color: #999;
    border: none;
  }
}
@media all {
  .sp-data-table .data-name {
    text-align: left !important;
  }

  .sp-data-table .data-player {
    text-align: left !important;
  }
}
@media all {
  .sp-template a:hover,
.sp-data-table a:hover {
    text-decoration: none !important;
  }

  .standings {
    margin-bottom: 30px;
  }

  .sp-table-caption {
    margin: 0 0 30px !important;
    padding: 0 !important;
    background: transparent !important;
    border: none !important;
    font-size: 48px !important;
    font-weight: 700 !important;
    line-height: 1em !important;
    display: block !important;
    width: 100% !important;
  }

  .sp-template h4 {
    letter-spacing: 1px !important;
  }

  .sp-data-table {
    border: none !important;
    background: transparent !important;
  }

  .sp-data-table th {
    font-size: 20px !important;
    font-weight: 700 !important;
    letter-spacing: 1.2px !important;
    padding: 10px 15px !important;
  }

  .sp-data-table td {
    font-weight: 400 !important;
    line-height: 1.4em !important;
    border: none !important;
    padding: 15px !important;
    text-align: left;
    background: transparent !important;
    letter-spacing: 0.6px !important;
  }

  .sp-data-table tr:last-child {
    border-bottom: 1px solid #ddd !important;
  }

  .sp-data-table .data-name {
    text-align: left !important;
  }

  .sp_event .sp-template {
    margin-bottom: 90px !important;
  }

  .sp_event .sp-template.sp-template-event-performance {
    margin-bottom: 30px !important;
  }

  .sp-data-table.sp-event-performance .data-number {
    font-size: 36px !important;
    font-weight: 700 !important;
  }

  .sp-data-table.sp-event-performance .data-name > a {
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  }

  .sp-data-table.sp-event-performance .data-name > p {
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  }

  .sp-data-table.sp-event-performance .data-player > a {
    font-size: 15px;
    line-height: 22px;
    text-align: left;
  }

  .sp-data-table.sp-event-performance .data-player > p {
    font-size: 15px;
    line-height: 22px;
    text-align: left;
    margin: 0;
  }

  .sp-data-table.sp-event-performance .data-games > p {
    font-size: 18px;
    line-height: 22px;
    text-align: left;
  }

  .sp-data-table.sp-event-performance .data-games > p span:nth-child(odd) {
    color: #262f3e !important;
  }

  .sp-data-table.data-table-results .data-header {
    padding: 0;
    background: #dee2e6;
  }
  .sp-data-table.data-table-results .data-header td {
    color: #081224 !important;
  }
  .sp-data-table.data-table-results tbody tr:last-child td {
    padding-bottom: 20px !important;
  }
  .sp-data-table.data-table-results td {
    padding: 15px !important;
    text-align: left;
    font-size: 16px;
  }
  .sp-data-table.data-table-results .data-games p {
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    margin: 0;
  }
  .sp-data-table.data-table-results .data-games p span:nth-child(odd) {
    color: #262f3e !important;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__custom.css ; media=all */
@media all {
  .sp-data-table.sp-event-performance .data-number,
.sp-data-table th,
.sp-table-caption,
.sp-template h4 {
    font-family: "BebasNeue", sans-serif !important;
  }

  .sp-template,
.sp-data-table,
.sp-table-caption {
    font-family: "Poppins", sans-serif;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__colors-default.css ; media=all */
@media all {
  .scheme_default table th,
.scheme_default table th + th {
    border-color: #262f3e;
  }

  .scheme_default table td,
.scheme_default table td + td {
    color: #797e87;
    border-color: #e3e3e3;
  }

  .scheme_default table th {
    color: #8c9097;
    background-color: #081224;
  }

  .scheme_default table > tbody > tr:nth-child(2n+1) > td {
    background-color: #ebebeb;
  }

  .scheme_default .sp-template *,
.scheme_default .sp-data-table *,
.scheme_default .sp-table-caption {
    color: #797e87;
  }

  .scheme_default .sp-table-caption {
    color: #262f3e;
  }

  .scheme_default .sp-data-table td {
    color: #262f3e;
  }

  .scheme_default .sp-data-table th {
    color: #262f3e;
    background: #f4f4f4;
  }

  .scheme_default .sp-template a,
.scheme_default .sp-data-table a {
    color: #262f3e;
  }

  .scheme_default .sp-template a:hover,
.scheme_default .sp-data-table a:hover {
    color: #ff0000;
  }
}
/*! CSS Used from: https://fcunited.azurewebsites.net/wp-content/themes/fcunited/css/__responsive.css ; media=all */
@media (max-width: 1279px) {
  table th,
table td {
    padding: 1rem;
  }

  .sp_event .sp-template {
    margin-bottom: 80px !important;
  }

  .sp-data-table.sp-event-performance .data-number {
    font-size: 32px !important;
  }

  .sp-data-table td {
    padding: 15px 20px !important;
  }
}
@media (max-width: 1023px) {
  .sp-table-caption {
    margin: 0 0 25px !important;
    font-size: 38px !important;
  }

  .sp_event .sp-template {
    margin-bottom: 75px !important;
  }

  .sp-data-table.sp-event-performance .data-name > a {
    font-size: 16px;
    line-height: 20px;
  }
}
@media (max-width: 767px) {
  .sp-data-table th {
    font-size: 18px !important;
    letter-spacing: 1px !important;
    padding: 8px 10px !important;
  }

  .sp-data-table td {
    padding: 8px 10px !important;
  }

  .sp-table-caption {
    margin: 0 0 20px !important;
    font-size: 36px !important;
  }

  .sp-template {
    margin-bottom: 20px !important;
  }

  .sp_event .sp-template {
    margin-bottom: 60px !important;
  }

  .sp-data-table.sp-event-performance .data-number {
    font-size: 26px !important;
  }

  .sp-data-table thead th {
    position: relative;
  }
}
@media (max-width: 479px) {
  table th,
table td {
    padding: 0.5rem;
  }

  .sp-data-table th {
    font-size: 16px !important;
    letter-spacing: 1px !important;
    padding: 6px 8px !important;
  }

  .sp-data-table td {
    padding: 6px 8px !important;
  }

  .sp-table-caption {
    margin: 0 0 16px !important;
    font-size: 32px !important;
  }

  .sp_event .sp-template {
    margin-bottom: 45px !important;
  }
}
.fixture-list .time {
  font-size: 25px;
  margin-bottom: 0;
}

.page-light {
  background-color: #f4f4f4;
}

@media (max-width: 768px) {
  .fixture h3 {
    font-size: 30px;
  }
}
.no-results {
  font-size: 16px;
}

.match-details p {
  line-height: 20px;
  margin: 0;
}

.match-details .sub-title {
  margin-bottom: 5px;
  font-size: 14px;
  text-transform: uppercase;
  color: #27303f;
  font-weight: bold;
}

/* adds some margin below the link sets  */
html,
body {
  height: 100%;
}

.navbar .dropdown-menu {
  border: none;
}

.navbar.navbar-submenu {
  margin: 20px 0;
}
.navbar.navbar-submenu .navbar-nav {
  margin: auto;
}
.navbar.navbar-submenu a {
  font-weight: 700;
  font-size: 15px;
  text-transform: uppercase;
  padding: 10px 15px;
}
.navbar.navbar-submenu a:hover {
  background: #262f3e;
  color: #fff;
}

@media screen and (max-width: 992px) {
  .navbar .container {
    padding: 0;
    position: relative;
  }
  .navbar .nav-item .nav-link {
    margin: 0;
    padding: 0;
  }
}
.navbar-menu {
  /* breakpoint and up - mega dropdown styles */
}
.navbar-menu .mega-menu {
  background-color: #26303e !important;
  padding: 0 10px 10px 10px;
  margin-bottom: 5px;
}
.navbar-menu .mega-menu .nav-item {
  padding: 0;
}
.navbar-menu .mega-menu .nav-item .nav-link {
  color: #fff;
  padding: 0;
}
.navbar-menu .mega-menu .nav-item .nav-link:hover {
  color: #fff;
}
.navbar-menu .mega-menu h3 {
  color: #fff;
  margin: 0;
}
.navbar-menu .mega-menu h5 {
  margin: 10px 0;
  color: #fff;
}
@media screen and (min-width: 992px) {
  .navbar-menu {
    /* remove the padding from the navbar so the dropdown hover state is not broken */
  }
  .navbar-menu.navbar {
    padding-top: 0px;
    padding-bottom: 0px;
    padding: 0.8em 0;
    height: 93px;
    /* remove the padding from the nav-item and add some margin to give some breathing room on hovers */
    /* makes the dropdown full width  */
  }
  .navbar-menu.navbar .dropdown-menu div[class*=col] {
    margin-bottom: 1rem;
  }
  .navbar-menu.navbar .nav-item {
    padding: 0.5rem 0.5rem;
    margin: 0 0.25rem;
  }
  .navbar-menu.navbar .dropdown {
    position: static;
  }
  .navbar-menu.navbar .mega-menu {
    width: 100%;
    left: 0;
    right: 0;
    /*  height of nav-item  */
    top: 90px;
    background-color: #091223 !important;
  }
  .navbar-menu.navbar .mega-menu a {
    color: #fff;
  }
  .navbar-menu.navbar .mega-menu .nav-item {
    padding: 0;
  }
  .navbar-menu.navbar .mega-menu .nav-item .nav-link {
    color: #fff;
    padding: 0;
  }
  .navbar-menu.navbar .mega-menu .nav-item .nav-link:hover {
    color: #fff;
  }
  .navbar-menu.navbar .mega-menu h3 {
    color: #fff;
    margin: 0;
  }
  .navbar-menu.navbar .mega-menu h5 {
    margin: 10px 0;
    color: #fff;
  }
}

.navbar-quick.navbar .dropdown-menu {
  background-color: #091223 !important;
}
.navbar-quick.navbar .dropdown-menu a {
  color: #fff;
}
.navbar-quick.navbar .dropdown-menu .dropdown-item:hover {
  color: #091223;
}
.navbar-quick.navbar .dropdown-menu .dropdown-divider {
  border-color: rgba(255, 255, 255, 0.5);
}
.navbar-quick.navbar .dropdown-toggle {
  color: rgba(255, 255, 255, 0.75);
  font-size: 16px;
}

.navbar-auth {
  position: relative;
}
.navbar-auth .dropdown-menu {
  left: auto;
  top: 110%;
  right: -11px;
  border: solid 1px;
  border-color: #262f3e;
  background-color: #081224;
  color: #848992;
}
.navbar-auth .dropdown-menu::after {
  content: " ";
  display: block;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 2.5em;
  width: 8px;
  height: 8px;
  margin: -5px 0 0 -4px;
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
  background-color: #fff;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border-color: #262f3e;
  background-color: #081224;
}
.navbar-auth .dropdown-menu .dropdown-item:hover, .navbar-auth .dropdown-menu .dropdown-item:focus {
  background: none;
}
@media screen and (min-width: 992px) {
  .navbar-auth .nav-item {
    padding: 0.5rem 0 !important;
  }
}
@media screen and (max-width: 992px) {
  .navbar-auth {
    position: absolute;
    right: 0;
    top: 8px;
  }
  .navbar-auth .dropdown-menu {
    top: 60px;
    position: absolute;
    right: -15px;
  }
}

.navbar-toggler {
  border: solid 1px #fff;
  width: 50px;
  height: 50px;
  color: white;
  border-radius: 50%;
  background-color: white !important;
  color: #262f3e !important;
}
@media screen and (max-width: 992px) {
  .navbar-toggler {
    width: 40px;
    height: 40px;
  }
}
.navbar-toggler i {
  color: #262f3e !important;
}

.navbar-social {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.useful-links a {
  margin: 0 10px;
}

.sub-page {
  background: #f4f4f4;
}

.navbar-brand h5 {
  margin: 0;
  padding: 0;
}

.navbar-quick {
  background: #071223;
  height: 50px;
}
.navbar-quick li a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
}
.navbar-quick li a:hover {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-custom > ul > li:first-child a {
  padding-left: 0;
}
.navbar-custom .dropdown-menu {
  border: solid 1px;
  border-color: #262f3e;
  background-color: #081224;
  color: #848992;
  padding: 0.5rem;
}
.navbar-custom .dropdown-menu::after {
  border-color: #262f3e;
  background-color: #081224;
}
.navbar-custom .dropdown-menu a {
  color: white;
}
@media screen and (max-width: 992px) {
  .navbar-custom .dropdown-menu {
    border: none;
    background-color: #eee;
    padding: 0 10px;
  }
  .navbar-custom .dropdown-menu .nav-item {
    width: 100%;
    padding-left: 15px;
  }
  .navbar-custom .dropdown-menu .nav-item a {
    color: #262f3e;
  }
}
.navbar-custom .dropdown-toggle:after {
  margin-left: 20px;
}
.navbar-custom.submenu {
  padding: 0;
}
@media screen and (min-width: 992px) {
  .navbar-custom.submenu .navbar-collapse > .navbar-nav > .nav-item > .nav-link {
    padding: 1rem;
  }
}
@media screen and (max-width: 992px) {
  .navbar-custom.submenu {
    padding: 0 15px;
  }
  .navbar-custom.submenu .navbar-collapse > .navbar-nav {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navbar-custom.submenu .navbar-collapse > .navbar-nav > .nav-item {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .navbar-custom.submenu .navbar-collapse > .navbar-nav > .dropdown > .dropdown-menu {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.logo-image {
  max-height: 55px;
  width: auto;
}
@media screen and (max-width: 992px) {
  .logo-image {
    max-height: 45px;
  }
}

.slider {
  position: relative;
  height: 65vh;
}
@media screen and (max-width: 992px) {
  .slider {
    height: 35vh;
    background-position-x: 25% !important;
  }
}
@media screen and (max-width: 767px) {
  .slider .sc_item_title_text {
    font-size: 30px;
    line-height: 30px;
  }
}
.slider .slider-description {
  font-size: 20px;
  color: #fff;
}
.slider--container {
  height: 100%;
}
.slider--title {
  position: absolute !important;
  bottom: 0;
}
.slider .sc_item_button {
  display: block !important;
  margin-bottom: 40px;
}

.article-page .body_wrap {
  background: #f4f4f4;
}
.article-page.scheme_alter .sc_title.sc_title_accent {
  background-color: #091223;
}
.article-page.scheme_alter .widget .widget_title {
  background-color: #091223;
}

.header--description {
  color: #fff;
}

.header_content_wrap .header--description {
  color: #f00;
  margin-top: 5px;
}

.changelog h1,
.changelog h2,
.changelog h3,
.changelog h4,
.changelog h5 {
  margin: 20px 0;
}

.clearfix {
  clear: both;
}

@media screen and (max-width: 992px) {
  .navbar-top {
    position: absolute;
    right: 0;
    top: 0px;
    z-index: 1;
  }
}
.venue img {
  width: auto !important;
  height: auto;
  max-height: 100%;
}
.venue h5 {
  margin: 0;
}
.venue .tags a {
  background: #071223;
  padding: 10px;
  display: inline-block;
  color: #fff;
  border-radius: 20px;
  margin: 0 5px 5px 0;
}

.background-image {
  background-size: cover;
  background-position: center;
}

.navbar-brand {
  max-width: 550px;
}
.navbar-brand h5 {
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .navbar-brand h5 {
    max-width: 380px;
  }
}

.latest-results .card .date {
  font-size: 12px;
  line-height: 11px;
}
.latest-results .card .team-name {
  font-size: 14px;
}
.latest-results .card .score {
  color: #262f3e !important;
  font-weight: 600;
  font-size: 35px;
}

.wrap-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content table td,
.content table th {
  padding: 0.75em 0.75em;
}

.clickable {
  cursor: pointer;
}

.nowrap {
  position: relative;
  word-break: keep-all;
  white-space: nowrap;
}

.stats-details {
  margin: 40px 0 20px 0;
}
.stats-details h3 {
  margin: 0;
  font-size: 15px;
  text-transform: uppercase;
}
.stats-details p {
  font-size: 20px;
}
.stats-details .result {
  padding: 0px 8px;
  color: #fff;
  font-size: 15px;
  display: inline-block;
  margin-top: 10px;
  color: #fff;
}
.stats-details .result--1 {
  background: #23BF08;
}
.stats-details .result--2 {
  background: #dc3545;
}
.stats-details .result--3 {
  background: #dee2e6;
}
.stats-details .vs {
  font-size: 15px;
  color: #262f3e;
  font-weight: 500;
}
.stats-details .team {
  margin: 0;
}
.stats-details .date {
  font-size: 13px;
  line-height: 14px;
}
.stats-details .player-match {
  font-size: 15px;
  display: inline-block;
  margin-top: 10px;
}

.player-match span {
  padding: 5px 10px;
  color: #fff;
  display: inline-block;
  width: 30px;
  text-align: center;
}
.player-match span.won-0 {
  background: #dee2e6;
  color: #081224;
}
.player-match span.won-1 {
  background: #bbcef0;
  color: #081224;
}
.player-match span.won-2 {
  background: #5282d9;
  color: #fff !important;
}
.player-match span.won-3 {
  background: #1f468c;
  color: #fff !important;
}
.player-match span.won-3 {
  background: #081224;
  color: #fff !important;
}

.text-success {
  color: #23BF08 !important;
}

.text-danger {
  color: #dc3545 !important;
}

.btn {
  -webkit-appearance: none;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  text-transform: none;
  white-space: nowrap;
  padding: 1.38em 3.5em;
  letter-spacing: 0;
  height: auto;
  border-width: 0 !important;
}
.btn-rounded {
  border-radius: 30px;
}
.btn-primary {
  color: #fff !important;
}

.feed h5 {
  margin: 0;
}
.feed--image {
  margin-bottom: 10px;
  height: 256px;
  background-size: cover;
  background-position: center;
}
.feed--image img {
  display: none !important;
}

.feed .owl-stage .owl-item.active + .active .feed--item {
  margin-right: 0 !important;
}
.feed .owl-stage .owl-item.active + :not(.active) {
  margin-left: 1.5rem !important;
}

.modal-open {
  padding: 0 !important;
}
.modal-open .modal {
  z-index: 999999;
}

.DayPicker {
  width: 100%;
  background: #fafafa;
  margin: 20px 0;
  z-index: 999 !important;
  position: relative;
}
.DayPicker-wrapper {
  width: 100%;
  z-index: 9999 !important;
}
.DayPicker-Day {
  border-radius: 5px;
}
.DayPicker-Day--selected {
  background-color: #262f3e !important;
}

.rc-time-picker .rc-time-picker-input {
  border: none;
}

.match-details h4 a {
  color: #262f3e;
}

body {
  background: #081224;
}

.top_panel .sc_layouts_row {
  background: #081224;
}

.scheme-dark {
  background: #081224;
}
.scheme-light {
  background: #f0f2f7;
}
.scheme-light .sc_title.sc_title_accent {
  background: #081224 !important;
}
.scheme-light .header {
  margin-bottom: 50px;
}
.scheme-light .widget .widget_title {
  background: #081224;
}

.btn--circle {
  display: flex;
  padding: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #ffffff;
  background-color: #262f3e;
}
@media screen and (max-width: 992px) {
  .btn--circle {
    width: 40px;
    height: 40px;
  }
}
.btn--circle i {
  margin: auto;
}

.equal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}
.equal > [class*=col-] {
  display: flex;
  flex-direction: column;
}

.memberships--element .membership .card {
  height: 100%;
}

.stripe .card-input {
  background: #fafafa;
  padding: 15px;
  margin: 15px 0;
  border-radius: 5px;
}

.btn-gray-200 {
  background: #e9ecef;
  color: #262f3e !important;
}
.btn-gray-200:hover {
  background: #262f3e;
  color: white !important;
}

.navbar-auth .dropdown-divider {
  background: #262f3e;
  border-color: #262f3e;
}
.navbar-auth .dropdown-menu {
  padding-top: 0;
}
.navbar-auth .nav-members .dropdown-item {
  cursor: pointer;
  padding: 5px 1.5rem;
}
.navbar-auth .nav-members .current {
  padding: 10px 1.5rem;
  cursor: default;
  background: #262f3e;
}
.navbar-auth .nav-members .current:hover {
  background: #262f3e;
  color: white;
}
.navbar-auth .nav-members .membership {
  font-size: 10px;
  margin-bottom: 10px;
  line-height: 1px;
  display: block;
}

.page-content {
  background: white;
  padding: 30px;
}
.page-content h1,
.page-content h2,
.page-content h3,
.page-content h4,
.page-content h5 {
  margin-top: 0;
  margin-bottom: 5px;
}

.accordion .view-all {
  display: block !important;
  text-align: right;
  cursor: pointer;
  width: 100%;
}
.accordion .hide-all {
  text-align: right;
  cursor: pointer;
  width: 100%;
}

.mceNonEditable h4 {
  font-size: 1.5em !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 10px;
  background-color: #F1F1F1;
  text-align: left;
  padding: 0.7em 1em;
  font-size: 1em;
  font-weight: 600;
  cursor: pointer;
  position: relative;
}

.fa-chevron-down {
  float: right;
  color: #000000;
  margin-top: -1em;
}

.fa-chevron-up {
  float: right;
  color: #000000;
  margin-top: -1em;
}

.mceNonEditable h4 a {
  font-size: 0.9em;
  color: #000000;
}

.mceNonEditable h4 .fa-chevron-down {
  display: none;
}
.mceNonEditable h4 .fa-chevron-up {
  display: block;
}

.collapsed .fa-chevron-down {
  display: block !important;
}
.collapsed .fa-chevron-up {
  display: none !important;
}

.membership-join .membership-option {
  border: solid 3px #fff;
}
.membership-join .membership-option.selected {
  border: solid 3px #262f3e;
}

.card.option {
  border: solid 3px #fff;
}
.card.option.selected {
  border: solid 3px #262f3e;
}

.membership-page .membership .content {
  background: white;
  padding: 30px;
}

.memberships-list h4,
.sessions-list h4 {
  text-transform: uppercase;
}

.page_content_wrap .sidebar {
  width: auto !important;
}

.bg-tertiary {
  background: #26303e;
}

.font-weight-semibold {
  font-weight: 600;
}

.event-price {
  line-height: 15px;
}
.event-price.disabled-price h4 {
  color: #848992;
  font-weight: 500;
}
@media (min-width: 992px) {
  .dropdown-menu .dropdown-toggle:after {
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
  }

  .dropdown-menu .dropdown-menu {
    margin-left: 0;
    margin-right: 0;
  }

  .dropdown-menu li {
    position: relative;
    width: 100%;
  }

  .nav-item .submenu {
    display: none;
    position: absolute;
    left: 100% !important;
    top: -7px !important;
    margin-left: 3px;
  }

  .nav-item .submenu-left {
    right: 100%;
    left: auto;
  }

  .dropdown-menu > li:hover > .submenu {
    display: block;
  }
}
.profile-page .card.card--nav {
  padding-bottom: 0;
}
.profile-page .card.card--nav .name {
  margin-top: 10px;
}
.profile-page .nav {
  margin-top: 20px;
  color: #bbb;
}
.profile-page .nav .active {
  color: #262f3e;
  border-bottom: solid 3px #262f3e;
}
.profile-page .nav .nav-link {
  padding: 15px;
  cursor: pointer;
}
.profile-page .nav .nav-link:hover {
  color: #262f3e;
}
.profile-page .status {
  width: 25px;
  height: 25px;
  display: inline-block;
  -webkit-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.gallery--item p {
  line-height: 20px;
}
.gallery .card-img-overlay {
  background: rgba(0, 0, 0, 0.5);
}

.header.homepage .slider {
  min-height: 50vh;
}

.global-search {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 50%, rgb(7, 18, 35) 100%), url(../../assets/images/header2.jpg);
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100vh - 100px);
  text-align: center;
}
.global-search section {
  width: 100%;
}
.global-search .title {
  color: white;
  font-size: 35px;
  font-weight: 500;
  line-height: 40px;
}
.global-search input[type=text] {
  color: #262f3e;
  border-color: white;
  background-color: rgba(255, 255, 255, 0.5);
}
.global-search input[type=text]:focus {
  color: #262f3e;
  border-color: white;
  background-color: rgba(255, 255, 255, 0.75);
}

/* Customize the label (the container) */
.checkmark-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkmark-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Hide the browser's default checkbox */
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkmark-container:hover input ~ .checkmark {
  background-color: #ccc;
}
.checkmark-container input:checked ~ .checkmark {
  background-color: #262f3e;
}

/* When the checkbox is checked, add a blue background */
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkmark-container input:checked ~ .checkmark:after {
  display: block;
}
.checkmark-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.terms-title {
  font-size: 18px;
}

.pre-content {
  margin-bottom: 20px;
}
.pre-content p {
  margin-bottom: 0;
}

.sidebar .alert {
  line-height: 18px;
}

.alert-rounded {
  border-radius: 30px;
}

.consents .consent {
  position: relative;
  background: #fafafa;
}
.consents .consent .required {
  color: #dc3545;
}

.sessions-list .min-period {
  display: flex;
}
.sessions-list .min-period i {
  margin: 3px;
}
.sessions-list .min-period span {
  margin-top: -1px;
  margin-left: 1px;
}

.btn-key {
  border-radius: 30px;
  padding: 20px;
}

.btn-link {
  color: #ff0000;
}

.btn-small {
  padding: 15px 30px;
}

.list-cards h6 {
  font-size: 1em;
}
.list-cards .card {
  border: solid 1px #ddd;
}
.list-cards .card p {
  margin: 0;
}
.list-cards .btn.btn-sm.btn--circle, .list-cards .btn-group-sm > .btn.btn--circle {
  width: 25px;
  height: 25px;
}

.help-container {
  float: right;
}

.game input.invalid {
  border: solid 1px red;
  background-color: rgba(255, 7, 7, 0.1) !important;
}
.game input.failed {
  border: solid 1px orange;
  background-color: rgba(255, 7, 7, 0.1) !important;
}

.modal-title {
  margin: 0;
}

.owl-nav {
  margin-top: 0px !important;
  height: 40px;
}
.owl-nav [class*=owl-] {
  background: none !important;
  font-size: 20px !important;
}
.owl-nav .owl-prev {
  float: left;
}
.owl-nav .owl-next {
  float: right;
}

.position.selected {
  outline: 0.2em #262f3e solid;
}

@media print {
  body {
    background: #fff;
  }

  table td {
    padding: 0;
    margin: 0;
    border: none !important;
  }

  .fixture-list .date {
    width: 90px;
  }
  .fixture-list .day {
    width: 130px;
  }
  .fixture-list .time {
    width: 80px;
  }
  .fixture-list .teama,
.fixture-list .teamb {
    width: 200px;
  }
  .fixture-list .venue {
    width: 200px;
  }
  .fixture-list .venue span {
    text-overflow: ellipsis;
    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
    display: block;
    width: 200px;
  }
  .fixture-list .score {
    width: 60px;
  }
  .fixture-list .tbd {
    font-size: 16px;
  }
}
.btn .fa-spin {
  display: none;
}
.btn.loading .fa-spin {
  display: block;
}
.btn[class*=bg-] {
  color: #fff;
}
.btn[class*=bg-]:hover {
  color: #fff;
}
.btn.bg-white {
  color: #ff0000;
}
.btn.bg-white:hover {
  color: #ff0000;
}
.btn .bg-primary:hover {
  background-color: rgba(38, 47, 62, 0.8);
}
.btn.btn-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 15px;
  display: inline-block;
}

.card {
  padding: 10px;
  border: none;
  border-radius: 5px;
  -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.07);
  -ms-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.07);
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.07);
  overflow: hidden;
}
.card--border {
  border: solid 1px #ddd;
}

.fixture-list .fixture {
  padding: 15px 0;
}
.fixture-list .fixture:hover {
  cursor: pointer;
}
.fixture-list .fixture .team {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767px) {
  .fixture-list .fixture .team a {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
.fixture-list .fixture .team .team-logo img {
  width: 100px;
}
.fixture-list .fixture .team .team-logo:hover {
  text-decoration: none;
}
.fixture-list .fixture .team .team-logo .team-name {
  color: #262f3e;
  font-size: 1.5em;
}
@media (max-width: 767px) {
  .fixture-list .fixture .team .team-logo .team-name {
    font-size: 1.1em;
    padding: 5px;
  }
}
.fixture-list .fixture h3 {
  margin: 0;
}

.league-table td.data-team {
  text-align: left !important;
}

.widget--league-table .sub-title {
  font-size: 12px;
  display: block;
  letter-spacing: 0.5px;
  font-weight: normal;
  line-height: 14px;
}

.pong-loader {
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 50%;
  height: 40px;
  width: 6px;
  background-color: transparent;
  animation: paddles 0.8s ease-out infinite;
  transform: translate3d(0, 0, 0);
  margin-top: -20px;
}
.pong-loader:before {
  content: "";
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 15px;
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  border-radius: 50%;
  animation: ballbounce 0.7s ease-out infinite;
}

@keyframes paddles {
  0% {
    box-shadow: -25px -10px 0px #fafafa, 25px 10px 0px #fafafa;
  }
  50% {
    box-shadow: -25px 8px 0px #fafafa, 25px -10px 0px #fafafa;
  }
  100% {
    box-shadow: -25px -10px 0px #fafafa, 25px 10px 0px #fafafa;
  }
}
@keyframes ballbounce {
  0% {
    transform: translateX(-20px) scale(1, 1.2);
  }
  25% {
    transform: scale(1.2, 1);
  }
  50% {
    transform: translateX(15px) scale(1, 1.2);
  }
  75% {
    transform: scale(1.2, 1);
  }
  100% {
    transform: translateX(-20px);
  }
}
.spinner {
  margin: auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: #081224;
  height: 100%;
  width: 5px;
  margin: 0 3px 0 0;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}
@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
.indicator--overlay {
  height: 100%;
}
.indicator--overlay > div {
  height: 100%;
}
.indicator--overlay .spinner > div {
  background-color: #081224;
}
.indicator--dark {
  background: #f4f4f4;
}
.indicator--dark > div {
  height: 100%;
}
.indicator--dark .spinner > div {
  background-color: #081224;
}

#nprogress .bar {
  z-index: 9999;
  background: white;
  height: 4px !important;
}
#nprogress .bar .peg {
  box-shadow: 0 0 10px #fff, 0 0 5px #fff;
}
#nprogress .spinner {
  z-index: 9999;
}
#nprogress .spinner .spinner-icon {
  border-top-color: white;
  border-left-color: white;
}

.pagemaker-section--1 .subtitle {
  color: #ff0000;
}
.pagemaker-section--1 h2 {
  color: #262f3e;
  text-transform: uppercase;
}
.pagemaker-content .subtitle {
  color: #ff0000;
}
.pagemaker-content h1 {
  color: #262f3e;
  text-transform: uppercase;
}
.pagemaker-content h2 {
  color: #262f3e;
  text-transform: uppercase;
}
.pagemaker-content .content {
  padding: 5.6em 4em;
}
.pagemaker-content--1 {
  text-align: left;
  margin: 20px 0;
}
.pagemaker-content--2 {
  text-align: center;
  margin: 20px 0;
}
.pagemaker-content--4 h1,
.pagemaker-content--4 h2,
.pagemaker-content--4 h3,
.pagemaker-content--4 h4,
.pagemaker-content--4 h5,
.pagemaker-content--4 h6 {
  margin-top: 0;
}
.pagemaker-content--6 {
  padding-top: 56.25%;
  position: relative;
}
.pagemaker-content--6 .react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
.pagemaker .bg-image {
  height: 100%;
}
.pagemaker .bg-cover {
  background-size: cover;
}

.booking-slots .slot a {
  padding: 20px;
  background-color: #ddd;
  border-radius: 30px;
}
.booking-slots .slot a.btn-danger {
  background-color: #dc3545;
}

.Toastify__toast {
  line-height: 18px;
  font-size: 15px;
  border-radius: 50px;
  padding: 0 20px;
}
.Toastify__toast--success {
  background: #fff;
  border: solid 2px #23BF08;
  color: #23BF08;
}
.Toastify__toast--warning {
  background: #fff;
  border: solid 2px #F49917;
  color: #F49917;
}
.Toastify__toast--error {
  background: #fff;
  border: solid 2px #dc3545;
  color: #dc3545;
}
.Toastify__toast-container {
  z-index: 9999999;
}
.Toastify__progress-bar--success {
  background: rgba(35, 191, 8, 0.5);
}
.Toastify__close-button {
  margin-top: 20px;
}
.Toastify__close-button--success {
  color: #23BF08;
}

/* Menu settings */
/* The main container */
.greedy-menu {
  position: relative;
  display: flex;
  justify-content: space-between;
  min-width: 240px;
  background-color: #fff;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 18px;
  line-height: 1;
  /* Style resets */
  /* The links */
  /* The hidden links list */
  /* The button which hides/shows links */
}
.greedy-menu * {
  box-sizing: border-box;
}
.greedy-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
  /* The visible links list */
}
.greedy-menu ul:first-of-type {
  position: relative;
  display: flex;
  /* Displaying the menu items on the same line */
}
.greedy-menu a {
  display: block;
  padding: 18px 25px;
  color: #78b1eb;
  text-decoration: none;
  white-space: nowrap;
}
.greedy-menu .visible-links > li:not(:last-child) > a {
  border-right: 1px solid #78b1eb;
}
.greedy-menu .hidden-links {
  position: absolute;
  top: 54px;
  right: 0;
  background-color: #fff;
  transform: none;
  transform-origin: 0 0;
  transition: transform 0.3s cubic-bezier(0.77, 0, 0.175, 1);
}
.greedy-menu .hidden-links.links-invisible {
  transform: scaleY(0);
}
.greedy-menu .hidden-links.links-invisible li {
  opacity: 0;
}
.greedy-menu .hidden-links.links-invisible li a {
  color: #fff;
}
.greedy-menu .hidden-links li {
  display: block;
  opacity: 1;
  transition: opacity 0.25s ease 0.15s, color 0.15s;
}
.greedy-menu .hidden-links li:not(:last-child) > a {
  border-bottom: 1px solid #78b1eb;
}
.greedy-menu .toggle-links {
  align-self: flex-end;
  position: relative;
  flex: 0 0 60px;
  order: 2;
  visibility: hidden;
  top: 0;
  right: 0;
  border: none;
  width: 60px;
  height: 54px;
  background-color: #46a1fe;
  font-size: 18px;
  line-height: 1;
  color: #78b1eb;
  cursor: pointer;
  /* Firefox specific style reset */
  /*  The hamburger icon */
  /* The circle icon */
}
.greedy-menu .toggle-links:hover {
  background-color: #3c9cfe;
}
.greedy-menu .toggle-links:hover::before {
  transform: scale(1.05);
}
.greedy-menu .toggle-links:focus {
  outline: none;
}
.greedy-menu .toggle-links.visible {
  visibility: visible;
}
.greedy-menu .toggle-links::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.greedy-menu .toggle-links::after {
  content: "";
  position: absolute;
  top: 17px;
  left: 12px;
  display: block;
  width: 36px;
  height: 4px;
  background-color: #fff;
  box-shadow: 0 8px 0 #fff, 0 16px 0 #fff;
  transition: transform 0.3s cubic-bezier(0.77, 0, 0.175, 1), box-shadow 0.2s ease 0.1s;
}
.greedy-menu .toggle-links.counter::before {
  box-sizing: border-box;
  content: attr(data-count);
  position: absolute;
  z-index: 2;
  top: 10px;
  left: -17px;
  display: block;
  color: #fff;
  font-size: 16px;
  width: 34px;
  padding: 6px 1px;
  background-color: #469ff9;
  border: 3px solid #fff;
  border-radius: 50%;
  line-height: 1;
  text-align: center;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.switch .sliderch {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.switch .sliderch:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch + span {
  position: absolute;
}

/* The slider */
input:checked + .sliderch {
  background-color: #262f3e;
}

input:focus + .sliderch {
  box-shadow: 0 0 1px #262f3e;
}

input:checked + .sliderch:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.sliderch.round {
  border-radius: 34px;
}

.sliderch.round:before {
  border-radius: 50%;
}

.disabled {
  background: #ccc !important;
}

.entry-teams .price .cost {
  font-weight: 500;
}
.entry-teams .price span {
  line-height: 10px;
}

.btn-hover-gray:hover {
  background-color: #eee !important;
  color: #262f3e !important;
}

.matchcard p {
  margin-bottom: 0;
}
.matchcard .games {
  display: block;
  width: 100%;
  overflow-x: auto;
}
@media (max-width: 992px) {
  .matchcard .games--container {
    padding: 20px 0;
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .matchcard .games--container {
    width: 550px;
  }
}
.matchcard .game {
  margin-right: 10px;
}
.matchcard .game input {
  width: 50px;
  text-align: center;
}
.matchcard .game input::-webkit-inner-spin-button, .matchcard .game input ::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.matchcard .game input:focus {
  position: relative;
  z-index: 2;
}
.matchcard .game input:last-child {
  margin-left: -1px;
}
.matchcard .game-vertical {
  margin-right: 5px;
}
.matchcard .game-vertical input {
  width: 50px;
  text-align: center;
  /* &:last-child {
      margin-left: -1px;
  } */
}
.matchcard .game-vertical input::-webkit-inner-spin-button, .matchcard .game-vertical input ::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.matchcard .game-vertical input:focus {
  position: relative;
  z-index: 2;
}
.matchcard .score p {
  font-weight: 600;
  font-size: 20px;
}
.matchcard .name .btn {
  text-align: left;
  padding: 0;
}

body.react-confirm-alert-body-element {
  overflow: hidden;
}

.react-confirm-alert--default {
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border: 1px solid #ced4da;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}

.react-confirm-alert-blur {
  filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.9);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}

.react-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.react-confirm-alert-body > h1 {
  margin-top: 0;
}

.react-confirm-alert-body > h3 {
  margin: 0;
  font-size: 16px;
}

.react-confirm-alert-button-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.react-confirm-alert-button-group > button {
  outline: none;
  background: #262f3e;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.modal--default {
  width: 450px;
}
.modal--large {
  width: 650px;
  max-width: 650px;
}

.loading-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9999;
  top: 0;
  display: none;
}
.loading-overlay.show {
  display: flex;
  width: 100%;
}
.loading-overlay.show .indicator {
  display: flex;
  width: 100%;
}
.loading-overlay.show .indicator .align-items-center {
  width: 100%;
}

@media screen and (max-width: 480px) {
  .sidebar-root {
    width: 100%;
  }
}
.sidebar-root .sidebar {
  width: 400px;
}
@media screen and (max-width: 480px) {
  .sidebar-root .sidebar {
    width: 100%;
  }
}
.sidebar-root .sidebar .sidebar--header {
  padding: 25px;
}
.sidebar-root .sidebar .sidebar--content {
  padding: 0 25px;
}
.sidebar-root .sidebar .sidebar--footer {
  padding: 0 25px;
}
.sidebar-root .sidebar h6 {
  position: relative;
}
.sidebar-root .sidebar h6 .help-container {
  float: right;
}
.sidebar-root .sidebar h6 .help-container .help {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
.sidebar-root .sidebar h6 .help-container .help i {
  color: #adb5bd;
}

.matchcard .card {
  margin-bottom: 10px;
}
.matchcard .slim-pageheader {
  margin: 20px 0;
  position: relative;
}
.matchcard .slim-pageheader .slim-pageactions {
  float: right;
  position: relative;
  top: -15px;
}
.matchcard .slim-pageheader + div {
  clear: both;
}
.matchcard h6 {
  margin-top: 0;
}
.matchcard .btn-icon {
  padding: 0;
}
.matchcard .btn-icon.btn-circle {
  border-radius: 50%;
  border: none;
}
.matchcard .btn-icon.btn-circle > div {
  height: 50px;
  width: 50px;
}
.matchcard .btn-icon.btn-circle.text-secondary:hover i {
  color: #fff;
}
.matchcard .btn-icon > div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 35px;
  height: 35px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.react-confirm-alert--default h1 {
  margin: 0 0 5px 0;
  font-size: 40px;
  letter-spacing: 0;
}

/* Wizard */
.modal-wizard {
  max-width: auto;
  min-width: 100%;
  width: 100%;
  height: 100vh;
  position: fixed;
  margin: 0;
}
.modal-wizard .modal-content {
  height: 100%;
  width: 100%;
}

.wizard {
  width: 100%;
}
.wizard .audible {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.wizard .summary label {
  font-size: 12px;
  margin: 0;
  text-transform: uppercase;
}
.wizard .summary p {
  margin-top: 0;
  line-height: 10px;
  margin-bottom: 10px;
}
.wizard .steps {
  padding-top: 30px;
  padding-bottom: 30px;
}
.wizard .steps > ul {
  list-style: none;
  padding-left: 0;
  display: flex;
  justify-content: space-between;
  background-image: linear-gradient(#7b7f89, #7b7f89);
  background-size: calc(100% - 50px) 4px;
  background-repeat: no-repeat;
  background-position: center;
}
.wizard .steps > ul li a {
  display: inline-block;
}
.wizard .steps > ul li span.number {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 2px solid #fff;
  border-radius: 50%;
  background-color: #7b7f89;
  text-align: center;
  line-height: 50px;
  color: #fff;
}
.wizard .steps > ul li.current span.number, .wizard .steps > ul li.done span.number {
  background-color: #ff0000;
}
.wizard .step {
  width: 100%;
}
.wizard .step h2 {
  text-transform: uppercase;
}
.wizard .content .title {
  display: none;
}
.wizard .content .bd-wizard-step-title {
  font-size: 20px;
  font-weight: bold;
  color: #7b7f89;
  margin-bottom: 45px;
}
.wizard .content .section-heading {
  font-size: 35px;
  font-weight: bold;
  color: #030303;
  margin-bottom: 17px;
}
.wizard .content p {
  font-size: 16px;
  color: #030303;
}
.wizard .content .purpose-radio {
  position: relative;
  display: inline-block;
}
.wizard .content .purpose-radio .purpose-radio-input {
  position: absolute;
  opacity: 0;
}
.wizard .content .purpose-radio .purpose-radio-input:checked + .purpose-radio-label {
  border-color: #ff0000;
}
.wizard .content .purpose-radio .purpose-radio-input:checked + .purpose-radio-label .label-icon {
  color: #ff0000;
}
.wizard .content .purpose-radio .purpose-radio-input:checked + .purpose-radio-label .label-icon .label-icon-default {
  display: none;
}
.wizard .content .purpose-radio .purpose-radio-input:checked + .purpose-radio-label .label-icon .label-icon-active {
  display: inline-block;
}
.wizard .content .purpose-radio .purpose-radio-input:checked + .purpose-radio-label .label-text {
  color: #ff0000;
}
.wizard .content .purpose-radio .purpose-radio-label {
  display: flex;
  width: 196px;
  height: 184px;
  max-width: 100%;
  border: 2px solid #7b7f89;
  border-radius: 4px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
@media (max-width: 575px) {
  .wizard .content .purpose-radio .purpose-radio-label {
    width: 100%;
  }
}
.wizard .content .purpose-radio .purpose-radio-label .label-icon {
  font-size: 21px;
  color: #262f3e;
  margin-bottom: 17px;
  transition: all 0.2s ease-in-out;
}
.wizard .content .purpose-radio .purpose-radio-label .label-icon .label-icon-active {
  display: none;
}
.wizard .content .purpose-radio .purpose-radio-label .label-text {
  font-size: 16px;
  color: #262f3e;
  transition: all 0.2s ease-in-out;
}
.wizard .content .purpose-radio .purpose-radio-label .label-info {
  font-size: 13px;
}
.wizard .content .purpose-radios-wrapper {
  margin-top: 60px;
}
.wizard .content .purpose-radios-wrapper .purpose-radio {
  margin-right: 18px;
  max-width: calc(33% - 18px);
}
@media (max-width: 575px) {
  .wizard .content .purpose-radios-wrapper .purpose-radio {
    margin-right: 0;
    margin-bottom: 18px;
    width: 100%;
    max-width: none;
  }
}
.wizard .content .purpose-radios-wrapper .purpose-radio:last-child {
  margin-right: 0;
}
.wizard .content .form-control {
  padding: 26px 25px;
  min-height: 50px;
  max-width: 550px;
  border-radius: 4px;
  border: solid 1px #ececec;
}
.wizard .content .form-control::placeholder {
  color: #919aa3;
}
.wizard .actions {
  margin-top: 50px;
}
@media (min-width: 768px) {
  .wizard.vertical {
    padding-left: 200px;
    position: relative;
    min-height: 450px;
  }
  .wizard.vertical .steps {
    padding-top: 0;
    padding-bottom: 0;
    min-height: 480px;
    position: absolute;
    left: 0;
    display: flex;
  }
  .wizard.vertical .steps > ul {
    width: 50px;
    flex-direction: column;
    background-size: 4px calc(100% - 50px);
    margin-bottom: 0;
  }
  .wizard.vertical .steps > ul li:not(:last-child) {
    margin-bottom: 24px;
  }
}

.basket .price {
  color: #262f3e;
  font-size: 30px;
  font-weight: 600;
}
.basket .item {
  padding: 15px;
}
.basket .item:nth-child(odd) {
  background: #fafafa;
}
.basket-count {
  left: 70%;
}
.basket-count > span {
  font-size: 10px;
  position: absolute;
  top: -1px;
  left: -1px;
  display: inline-block;
  height: 20px;
  width: 20px;
}

.list-group-default .list-group-item {
  padding: 15px 20px;
}
@media (min-width: 480px) {
  .list-group-default .list-group-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
.list-group-default .list-group-item img {
  width: 48px;
  border-radius: 100%;
}
.list-group-default .list-title {
  margin-top: 10px;
  margin-right: auto;
}
@media (min-width: 480px) {
  .list-group-default .list-title {
    margin-left: 15px;
    margin-top: 0;
  }
}
.list-group-default .list-title p {
  margin-bottom: 0;
  color: #343a40;
  font-weight: 500;
}
.list-group-default .list-title span {
  display: block;
  font-size: 13px;
}
.list-group-default .list-btn-wrapper {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
@media (min-width: 480px) {
  .list-group-default .list-btn-wrapper {
    margin-top: 0;
  }
}
.list-group-default .list-btn-wrapper .btn + .btn {
  margin-left: 5px;
}
.list-group-default .list-status-wrapper {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
@media (min-width: 480px) {
  .list-group-default .list-status-wrapper {
    margin-top: 0;
  }
}
.list-group-default .list-status-wrapper .btn + .btn {
  margin-left: 5px;
}

.list-group-sortable .list-group-item:hover {
  cursor: pointer;
}

@media (max-width: 992px) {
  .fixture-list .card-body {
    padding: 10px;
  }
}
.fixture-list .date {
  background: #f8f9fa;
  padding: 10px;
  margin-bottom: 10px;
  position: relative;
}
.fixture-list .date .dropdown {
  margin-top: -8px;
  position: absolute;
  right: 0;
}
.fixture-list .team-a {
  text-align: right;
}
.fixture-list .team-b {
  text-align: left;
}
.fixture-list .match {
  border-bottom: solid 1px #eee;
  padding: 10px 0;
}
.fixture-list .match .dropdown .btn-icon > div {
  height: auto;
}

body > .list-group {
  z-index: 9999;
}

.list-group .list-group-item .avatar {
  background: #f4f4f4;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #343a40;
  font-weight: 500;
  min-width: 50px;
}
.list-group .list-group-item .user-btn-wrapper {
  margin-left: 15px;
}
.list-group .list-group-item h5 {
  color: #555;
  margin: 0;
}

/* ------------------------------------------ */
/* ############### Typography ############### */
/* ------------------------------------------ */
.tx-black {
  font-weight: 900;
}

.tx-bold {
  font-weight: 700;
}

.tx-semibold {
  font-weight: 600 !important;
}

.tx-medium {
  font-weight: 500 !important;
}

.tx-normal {
  font-weight: 400;
}

.tx-light {
  font-weight: 300;
}

.tx-thin {
  font-weight: 200;
}

.tx-xthin {
  font-weight: 100;
}

.tx-roboto {
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
}

.tx-opensans {
  font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
}

.tx-mont {
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
}

.tx-lato {
  font-family: "Lato", "Helvetica Neue", Arial, sans-serif;
}

.tx-sserif {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.tx-white {
  color: #fff;
}

.tx-black {
  color: #000;
}

.tx-primary {
  color: #262f3e;
}

.tx-success {
  color: #23BF08;
}

.tx-warning {
  color: #F49917;
}

.tx-danger {
  color: #dc3545;
}

.tx-info {
  color: #5B93D3;
}

.tx-inverse {
  color: #343a40;
}

.tx-teal {
  color: #1CAF9A;
}

.tx-dark {
  color: #343a40;
}

.tx-indigo {
  color: #6610f2;
}

.tx-purple {
  color: #6f42c1;
}

.tx-orange {
  color: #f27510;
}

.tx-pink {
  color: #e83e8c;
}

.tx-blue {
  color: #1b84e7;
}

.tx-gray-100 {
  color: #f8f9fa;
}

.tx-gray-200 {
  color: #e9ecef;
}

.tx-gray-300 {
  color: #dee2e6;
}

.tx-gray-400 {
  color: #ced4da;
}

.tx-gray-500 {
  color: #adb5bd;
}

.tx-gray-600 {
  color: #6c757d;
}

.tx-gray-700 {
  color: #495057;
}

.tx-gray-800 {
  color: #343a40;
}

.tx-gray-900 {
  color: #212529;
}

.tx-white-2 {
  color: rgba(255, 255, 255, 0.2);
}

.tx-white-3 {
  color: rgba(255, 255, 255, 0.3);
}

.tx-white-4 {
  color: rgba(255, 255, 255, 0.4);
}

.tx-white-5 {
  color: rgba(255, 255, 255, 0.5);
}

.tx-white-6 {
  color: rgba(255, 255, 255, 0.6);
}

.tx-white-7 {
  color: rgba(255, 255, 255, 0.7);
}

.tx-white-8 {
  color: rgba(255, 255, 255, 0.8);
}

.tx-spacing-1 {
  letter-spacing: 0.5px;
}

.tx-spacing-2 {
  letter-spacing: 1px;
}

.tx-spacing-3 {
  letter-spacing: 1.5px;
}

.tx-spacing-4 {
  letter-spacing: 2px;
}

.tx-spacing-5 {
  letter-spacing: 2.5px;
}

.tx-spacing-6 {
  letter-spacing: 3px;
}

.tx-spacing-7 {
  letter-spacing: 3.5px;
}

.tx-spacing-8 {
  letter-spacing: 4px;
}

.tx-spacing--1 {
  letter-spacing: -0.5px;
}

.tx-spacing--2 {
  letter-spacing: -1px;
}

.tx-spacing--3 {
  letter-spacing: -1.5px;
}

.tx-spacing--4 {
  letter-spacing: -2px;
}

.tx-spacing--5 {
  letter-spacing: -2.5px;
}

.tx-spacing--6 {
  letter-spacing: -3px;
}

.tx-spacing--7 {
  letter-spacing: -3.5px;
}

.tx-spacing--8 {
  letter-spacing: -4px;
}

.tx-uppercase {
  text-transform: uppercase;
}

.tx-lowercase {
  text-transform: lowercase;
}

.tx-transform-none {
  text-transform: none;
}

.tx-center {
  text-align: center;
}

.tx-right {
  text-align: right;
}

.tx-left {
  text-align: left;
}

.tx-center-force {
  text-align: center !important;
}

.tx-right-force {
  text-align: right !important;
}

.tx-left-force {
  text-align: left !important;
}

.tx-italic {
  font-style: italic;
}

.tx-style-normal {
  font-style: normal;
}

.lh-base {
  line-height: 1.5;
}

.lh-normal {
  line-height: normal;
}

.lh-0 {
  line-height: 0;
}

.lh-1 {
  line-height: 1.1;
}

.lh-2 {
  line-height: 1.2;
}

.lh-3 {
  line-height: 1.3;
}

.lh-4 {
  line-height: 1.4;
}

.lh-5 {
  line-height: 1.5;
}

.lh-6 {
  line-height: 1.6;
}

.lh-7 {
  line-height: 1.7;
}

.lh-8 {
  line-height: 1.8;
}

.lh-9 {
  line-height: 1.9;
}

.lh-10 {
  line-height: 2;
}

.lh-11 {
  line-height: 2.1;
}

.lh-12 {
  line-height: 2.2;
}

.lh-13 {
  line-height: 2.3;
}

.lh-14 {
  line-height: 2.4;
}

.lh-15 {
  line-height: 2.5;
}

.valign-top {
  vertical-align: top;
}

.valign-middle {
  vertical-align: middle;
}

.valign-bottom {
  vertical-align: baseline;
}

.valign-top-force {
  vertical-align: top !important;
}

.valign-middle-force {
  vertical-align: middle !important;
}

.valign-bottom-force {
  vertical-align: baseline !important;
}

.tx-8 {
  font-size: 8px;
}

.tx-8-force {
  font-size: 8px !important;
}

.tx-9 {
  font-size: 9px;
}

.tx-9-force {
  font-size: 9px !important;
}

.tx-10 {
  font-size: 10px;
}

.tx-10-force {
  font-size: 10px !important;
}

.tx-11 {
  font-size: 11px;
}

.tx-11-force {
  font-size: 11px !important;
}

.tx-12 {
  font-size: 12px;
}

.tx-12-force {
  font-size: 12px !important;
}

.tx-13 {
  font-size: 13px;
}

.tx-13-force {
  font-size: 13px !important;
}

.tx-14 {
  font-size: 14px;
}

.tx-14-force {
  font-size: 14px !important;
}

.tx-15 {
  font-size: 15px;
}

.tx-15-force {
  font-size: 15px !important;
}

.tx-16 {
  font-size: 16px;
}

.tx-16-force {
  font-size: 16px !important;
}

.tx-18 {
  font-size: 18px;
}

.tx-18-force {
  font-size: 18px !important;
}

.tx-20 {
  font-size: 20px;
}

.tx-20-force {
  font-size: 20px !important;
}

.tx-22 {
  font-size: 22px;
}

.tx-22-force {
  font-size: 22px !important;
}

.tx-24 {
  font-size: 24px;
}

.tx-24-force {
  font-size: 24px !important;
}

.tx-26 {
  font-size: 26px;
}

.tx-26-force {
  font-size: 26px !important;
}

.tx-28 {
  font-size: 28px;
}

.tx-28-force {
  font-size: 28px !important;
}

.tx-30 {
  font-size: 30px;
}

.tx-30-force {
  font-size: 30px !important;
}

.tx-32 {
  font-size: 32px;
}

.tx-32-force {
  font-size: 32px !important;
}

.tx-34 {
  font-size: 34px;
}

.tx-34-force {
  font-size: 34px !important;
}

.tx-36 {
  font-size: 36px;
}

.tx-36-force {
  font-size: 36px !important;
}

.tx-38 {
  font-size: 38px;
}

.tx-38-force {
  font-size: 38px !important;
}

.tx-40 {
  font-size: 40px;
}

.tx-40-force {
  font-size: 40px !important;
}

.tx-42 {
  font-size: 42px;
}

.tx-42-force {
  font-size: 42px !important;
}

.tx-44 {
  font-size: 44px;
}

.tx-44-force {
  font-size: 44px !important;
}

.tx-46 {
  font-size: 46px;
}

.tx-46-force {
  font-size: 46px !important;
}

.tx-48 {
  font-size: 48px;
}

.tx-48-force {
  font-size: 48px !important;
}

.tx-50 {
  font-size: 50px;
}

.tx-50-force {
  font-size: 50px !important;
}

.tx-52 {
  font-size: 52px;
}

.tx-52-force {
  font-size: 52px !important;
}

.tx-54 {
  font-size: 54px;
}

.tx-54-force {
  font-size: 54px !important;
}

.tx-56 {
  font-size: 56px;
}

.tx-56-force {
  font-size: 56px !important;
}

.tx-58 {
  font-size: 58px;
}

.tx-58-force {
  font-size: 58px !important;
}

.tx-60 {
  font-size: 60px;
}

.tx-60-force {
  font-size: 60px !important;
}

.tx-62 {
  font-size: 62px;
}

.tx-62-force {
  font-size: 62px !important;
}

.tx-64 {
  font-size: 64px;
}

.tx-64-force {
  font-size: 64px !important;
}

.tx-66 {
  font-size: 66px;
}

.tx-66-force {
  font-size: 66px !important;
}

.tx-68 {
  font-size: 68px;
}

.tx-68-force {
  font-size: 68px !important;
}

.tx-70 {
  font-size: 70px;
}

.tx-70-force {
  font-size: 70px !important;
}

.tx-72 {
  font-size: 72px;
}

.tx-72-force {
  font-size: 72px !important;
}

.tx-74 {
  font-size: 74px;
}

.tx-74-force {
  font-size: 74px !important;
}

.tx-76 {
  font-size: 76px;
}

.tx-76-force {
  font-size: 76px !important;
}

.tx-78 {
  font-size: 78px;
}

.tx-78-force {
  font-size: 78px !important;
}

.tx-80 {
  font-size: 80px;
}

.tx-80-force {
  font-size: 80px !important;
}

.tx-82 {
  font-size: 82px;
}

.tx-82-force {
  font-size: 82px !important;
}

.tx-84 {
  font-size: 84px;
}

.tx-84-force {
  font-size: 84px !important;
}

.tx-86 {
  font-size: 86px;
}

.tx-86-force {
  font-size: 86px !important;
}

.tx-88 {
  font-size: 88px;
}

.tx-88-force {
  font-size: 88px !important;
}

.tx-90 {
  font-size: 90px;
}

.tx-90-force {
  font-size: 90px !important;
}

.tx-92 {
  font-size: 92px;
}

.tx-92-force {
  font-size: 92px !important;
}

.tx-94 {
  font-size: 94px;
}

.tx-94-force {
  font-size: 94px !important;
}

.tx-96 {
  font-size: 96px;
}

.tx-96-force {
  font-size: 96px !important;
}

.tx-98 {
  font-size: 98px;
}

.tx-98-force {
  font-size: 98px !important;
}

.tx-100 {
  font-size: 100px;
}

.tx-100-force {
  font-size: 100px !important;
}

.tx-102 {
  font-size: 102px;
}

.tx-102-force {
  font-size: 102px !important;
}

.tx-104 {
  font-size: 104px;
}

.tx-104-force {
  font-size: 104px !important;
}

.tx-106 {
  font-size: 106px;
}

.tx-106-force {
  font-size: 106px !important;
}

.tx-108 {
  font-size: 108px;
}

.tx-108-force {
  font-size: 108px !important;
}

.tx-110 {
  font-size: 110px;
}

.tx-110-force {
  font-size: 110px !important;
}

.tx-112 {
  font-size: 112px;
}

.tx-112-force {
  font-size: 112px !important;
}

.tx-114 {
  font-size: 114px;
}

.tx-114-force {
  font-size: 114px !important;
}

.tx-116 {
  font-size: 116px;
}

.tx-116-force {
  font-size: 116px !important;
}

.tx-118 {
  font-size: 118px;
}

.tx-118-force {
  font-size: 118px !important;
}

.tx-120 {
  font-size: 120px;
}

.tx-120-force {
  font-size: 120px !important;
}

.tx-122 {
  font-size: 122px;
}

.tx-122-force {
  font-size: 122px !important;
}

.tx-124 {
  font-size: 124px;
}

.tx-124-force {
  font-size: 124px !important;
}

.tx-126 {
  font-size: 126px;
}

.tx-126-force {
  font-size: 126px !important;
}

.tx-128 {
  font-size: 128px;
}

.tx-128-force {
  font-size: 128px !important;
}

.tx-130 {
  font-size: 130px;
}

.tx-130-force {
  font-size: 130px !important;
}

.tx-132 {
  font-size: 132px;
}

.tx-132-force {
  font-size: 132px !important;
}

.tx-134 {
  font-size: 134px;
}

.tx-134-force {
  font-size: 134px !important;
}

.tx-136 {
  font-size: 136px;
}

.tx-136-force {
  font-size: 136px !important;
}

.tx-138 {
  font-size: 138px;
}

.tx-138-force {
  font-size: 138px !important;
}

.tx-140 {
  font-size: 140px;
}

.tx-140-force {
  font-size: 140px !important;
}

@media (min-width: 480px) {
  .tx-xs-8 {
    font-size: 8px;
  }

  .tx-xs-8-force {
    font-size: 8px !important;
  }

  .tx-xs-9 {
    font-size: 9px;
  }

  .tx-xs-9-force {
    font-size: 9px !important;
  }

  .tx-xs-10 {
    font-size: 10px;
  }

  .tx-xs-10-force {
    font-size: 10px !important;
  }

  .tx-xs-11 {
    font-size: 11px;
  }

  .tx-xs-11-force {
    font-size: 11px !important;
  }

  .tx-xs-12 {
    font-size: 12px;
  }

  .tx-xs-12-force {
    font-size: 12px !important;
  }

  .tx-xs-13 {
    font-size: 13px;
  }

  .tx-xs-13-force {
    font-size: 13px !important;
  }

  .tx-xs-14 {
    font-size: 14px;
  }

  .tx-xs-14-force {
    font-size: 14px !important;
  }

  .tx-xs-15 {
    font-size: 15px;
  }

  .tx-xs-15-force {
    font-size: 15px !important;
  }

  .tx-xs-16 {
    font-size: 16px;
  }

  .tx-xs-16-force {
    font-size: 16px !important;
  }

  .tx-xs-18 {
    font-size: 18px;
  }

  .tx-xs-18-force {
    font-size: 18px !important;
  }

  .tx-xs-20 {
    font-size: 20px;
  }

  .tx-xs-20-force {
    font-size: 20px !important;
  }

  .tx-xs-22 {
    font-size: 22px;
  }

  .tx-xs-22-force {
    font-size: 22px !important;
  }

  .tx-xs-24 {
    font-size: 24px;
  }

  .tx-xs-24-force {
    font-size: 24px !important;
  }

  .tx-xs-26 {
    font-size: 26px;
  }

  .tx-xs-26-force {
    font-size: 26px !important;
  }

  .tx-xs-28 {
    font-size: 28px;
  }

  .tx-xs-28-force {
    font-size: 28px !important;
  }

  .tx-xs-30 {
    font-size: 30px;
  }

  .tx-xs-30-force {
    font-size: 30px !important;
  }

  .tx-xs-32 {
    font-size: 32px;
  }

  .tx-xs-32-force {
    font-size: 32px !important;
  }

  .tx-xs-34 {
    font-size: 34px;
  }

  .tx-xs-34-force {
    font-size: 34px !important;
  }

  .tx-xs-36 {
    font-size: 36px;
  }

  .tx-xs-36-force {
    font-size: 36px !important;
  }

  .tx-xs-38 {
    font-size: 38px;
  }

  .tx-xs-38-force {
    font-size: 38px !important;
  }

  .tx-xs-40 {
    font-size: 40px;
  }

  .tx-xs-40-force {
    font-size: 40px !important;
  }

  .tx-xs-42 {
    font-size: 42px;
  }

  .tx-xs-42-force {
    font-size: 42px !important;
  }

  .tx-xs-44 {
    font-size: 44px;
  }

  .tx-xs-44-force {
    font-size: 44px !important;
  }

  .tx-xs-46 {
    font-size: 46px;
  }

  .tx-xs-46-force {
    font-size: 46px !important;
  }

  .tx-xs-48 {
    font-size: 48px;
  }

  .tx-xs-48-force {
    font-size: 48px !important;
  }

  .tx-xs-50 {
    font-size: 50px;
  }

  .tx-xs-50-force {
    font-size: 50px !important;
  }

  .tx-xs-52 {
    font-size: 52px;
  }

  .tx-xs-52-force {
    font-size: 52px !important;
  }

  .tx-xs-54 {
    font-size: 54px;
  }

  .tx-xs-54-force {
    font-size: 54px !important;
  }

  .tx-xs-56 {
    font-size: 56px;
  }

  .tx-xs-56-force {
    font-size: 56px !important;
  }

  .tx-xs-58 {
    font-size: 58px;
  }

  .tx-xs-58-force {
    font-size: 58px !important;
  }

  .tx-xs-60 {
    font-size: 60px;
  }

  .tx-xs-60-force {
    font-size: 60px !important;
  }

  .tx-xs-62 {
    font-size: 62px;
  }

  .tx-xs-62-force {
    font-size: 62px !important;
  }

  .tx-xs-64 {
    font-size: 64px;
  }

  .tx-xs-64-force {
    font-size: 64px !important;
  }

  .tx-xs-66 {
    font-size: 66px;
  }

  .tx-xs-66-force {
    font-size: 66px !important;
  }

  .tx-xs-68 {
    font-size: 68px;
  }

  .tx-xs-68-force {
    font-size: 68px !important;
  }

  .tx-xs-70 {
    font-size: 70px;
  }

  .tx-xs-70-force {
    font-size: 70px !important;
  }

  .tx-xs-72 {
    font-size: 72px;
  }

  .tx-xs-72-force {
    font-size: 72px !important;
  }

  .tx-xs-74 {
    font-size: 74px;
  }

  .tx-xs-74-force {
    font-size: 74px !important;
  }

  .tx-xs-76 {
    font-size: 76px;
  }

  .tx-xs-76-force {
    font-size: 76px !important;
  }

  .tx-xs-78 {
    font-size: 78px;
  }

  .tx-xs-78-force {
    font-size: 78px !important;
  }

  .tx-xs-80 {
    font-size: 80px;
  }

  .tx-xs-80-force {
    font-size: 80px !important;
  }

  .tx-xs-82 {
    font-size: 82px;
  }

  .tx-xs-82-force {
    font-size: 82px !important;
  }

  .tx-xs-84 {
    font-size: 84px;
  }

  .tx-xs-84-force {
    font-size: 84px !important;
  }

  .tx-xs-86 {
    font-size: 86px;
  }

  .tx-xs-86-force {
    font-size: 86px !important;
  }

  .tx-xs-88 {
    font-size: 88px;
  }

  .tx-xs-88-force {
    font-size: 88px !important;
  }

  .tx-xs-90 {
    font-size: 90px;
  }

  .tx-xs-90-force {
    font-size: 90px !important;
  }

  .tx-xs-92 {
    font-size: 92px;
  }

  .tx-xs-92-force {
    font-size: 92px !important;
  }

  .tx-xs-94 {
    font-size: 94px;
  }

  .tx-xs-94-force {
    font-size: 94px !important;
  }

  .tx-xs-96 {
    font-size: 96px;
  }

  .tx-xs-96-force {
    font-size: 96px !important;
  }

  .tx-xs-98 {
    font-size: 98px;
  }

  .tx-xs-98-force {
    font-size: 98px !important;
  }

  .tx-xs-100 {
    font-size: 100px;
  }

  .tx-xs-100-force {
    font-size: 100px !important;
  }

  .tx-xs-102 {
    font-size: 102px;
  }

  .tx-xs-102-force {
    font-size: 102px !important;
  }

  .tx-xs-104 {
    font-size: 104px;
  }

  .tx-xs-104-force {
    font-size: 104px !important;
  }

  .tx-xs-106 {
    font-size: 106px;
  }

  .tx-xs-106-force {
    font-size: 106px !important;
  }

  .tx-xs-108 {
    font-size: 108px;
  }

  .tx-xs-108-force {
    font-size: 108px !important;
  }

  .tx-xs-110 {
    font-size: 110px;
  }

  .tx-xs-110-force {
    font-size: 110px !important;
  }

  .tx-xs-112 {
    font-size: 112px;
  }

  .tx-xs-112-force {
    font-size: 112px !important;
  }

  .tx-xs-114 {
    font-size: 114px;
  }

  .tx-xs-114-force {
    font-size: 114px !important;
  }

  .tx-xs-116 {
    font-size: 116px;
  }

  .tx-xs-116-force {
    font-size: 116px !important;
  }

  .tx-xs-118 {
    font-size: 118px;
  }

  .tx-xs-118-force {
    font-size: 118px !important;
  }

  .tx-xs-120 {
    font-size: 120px;
  }

  .tx-xs-120-force {
    font-size: 120px !important;
  }

  .tx-xs-122 {
    font-size: 122px;
  }

  .tx-xs-122-force {
    font-size: 122px !important;
  }

  .tx-xs-124 {
    font-size: 124px;
  }

  .tx-xs-124-force {
    font-size: 124px !important;
  }

  .tx-xs-126 {
    font-size: 126px;
  }

  .tx-xs-126-force {
    font-size: 126px !important;
  }

  .tx-xs-128 {
    font-size: 128px;
  }

  .tx-xs-128-force {
    font-size: 128px !important;
  }

  .tx-xs-130 {
    font-size: 130px;
  }

  .tx-xs-130-force {
    font-size: 130px !important;
  }

  .tx-xs-132 {
    font-size: 132px;
  }

  .tx-xs-132-force {
    font-size: 132px !important;
  }

  .tx-xs-134 {
    font-size: 134px;
  }

  .tx-xs-134-force {
    font-size: 134px !important;
  }

  .tx-xs-136 {
    font-size: 136px;
  }

  .tx-xs-136-force {
    font-size: 136px !important;
  }

  .tx-xs-138 {
    font-size: 138px;
  }

  .tx-xs-138-force {
    font-size: 138px !important;
  }

  .tx-xs-140 {
    font-size: 140px;
  }

  .tx-xs-140-force {
    font-size: 140px !important;
  }

  .tx-xs-bold {
    font-weight: 700;
  }

  .tx-xs-semibold {
    font-weight: 600;
  }

  .tx-xs-medium {
    font-weight: 500;
  }

  .tx-xs-normal {
    font-weight: 400;
  }

  .tx-xs-light {
    font-weight: 300;
  }

  .tx-xs-thin {
    font-weight: 200;
  }

  .tx-xs-xthin {
    font-weight: 100;
  }

  .lh-xs-0 {
    line-height: 0;
  }

  .lh-xs-1 {
    line-height: 1.1;
  }

  .lh-xs-2 {
    line-height: 1.2;
  }

  .lh-xs-3 {
    line-height: 1.3;
  }

  .lh-xs-4 {
    line-height: 1.4;
  }

  .lh-xs-5 {
    line-height: 1.5;
  }

  .lh-xs-6 {
    line-height: 1.6;
  }

  .lh-xs-7 {
    line-height: 1.7;
  }

  .lh-xs-8 {
    line-height: 1.8;
  }

  .lh-xs-9 {
    line-height: 1.9;
  }

  .lh-xs-10 {
    line-height: 2;
  }

  .lh-xs-11 {
    line-height: 2.1;
  }

  .lh-xs-12 {
    line-height: 2.2;
  }

  .lh-xs-13 {
    line-height: 2.3;
  }

  .lh-xs-14 {
    line-height: 2.4;
  }

  .lh-xs-15 {
    line-height: 2.5;
  }

  .tx-xs-center {
    text-align: center;
  }

  .tx-xs-right {
    text-align: right;
  }

  .tx-xs-left {
    text-align: left;
  }

  .tx-xs-center-force {
    text-align: center !important;
  }

  .tx-xs-right-force {
    text-align: right !important;
  }

  .tx-xs-left-force {
    text-align: left !important;
  }
}
@media (min-width: 576px) {
  .tx-sm-8 {
    font-size: 8px;
  }

  .tx-sm-8-force {
    font-size: 8px !important;
  }

  .tx-sm-9 {
    font-size: 9px;
  }

  .tx-sm-9-force {
    font-size: 9px !important;
  }

  .tx-sm-10 {
    font-size: 10px;
  }

  .tx-sm-10-force {
    font-size: 10px !important;
  }

  .tx-sm-11 {
    font-size: 11px;
  }

  .tx-sm-11-force {
    font-size: 11px !important;
  }

  .tx-sm-12 {
    font-size: 12px;
  }

  .tx-sm-12-force {
    font-size: 12px !important;
  }

  .tx-sm-13 {
    font-size: 13px;
  }

  .tx-sm-13-force {
    font-size: 13px !important;
  }

  .tx-sm-14 {
    font-size: 14px;
  }

  .tx-sm-14-force {
    font-size: 14px !important;
  }

  .tx-sm-15 {
    font-size: 15px;
  }

  .tx-sm-15-force {
    font-size: 15px !important;
  }

  .tx-sm-16 {
    font-size: 16px;
  }

  .tx-sm-16-force {
    font-size: 16px !important;
  }

  .tx-sm-18 {
    font-size: 18px;
  }

  .tx-sm-18-force {
    font-size: 18px !important;
  }

  .tx-sm-20 {
    font-size: 20px;
  }

  .tx-sm-20-force {
    font-size: 20px !important;
  }

  .tx-sm-22 {
    font-size: 22px;
  }

  .tx-sm-22-force {
    font-size: 22px !important;
  }

  .tx-sm-24 {
    font-size: 24px;
  }

  .tx-sm-24-force {
    font-size: 24px !important;
  }

  .tx-sm-26 {
    font-size: 26px;
  }

  .tx-sm-26-force {
    font-size: 26px !important;
  }

  .tx-sm-28 {
    font-size: 28px;
  }

  .tx-sm-28-force {
    font-size: 28px !important;
  }

  .tx-sm-30 {
    font-size: 30px;
  }

  .tx-sm-30-force {
    font-size: 30px !important;
  }

  .tx-sm-32 {
    font-size: 32px;
  }

  .tx-sm-32-force {
    font-size: 32px !important;
  }

  .tx-sm-34 {
    font-size: 34px;
  }

  .tx-sm-34-force {
    font-size: 34px !important;
  }

  .tx-sm-36 {
    font-size: 36px;
  }

  .tx-sm-36-force {
    font-size: 36px !important;
  }

  .tx-sm-38 {
    font-size: 38px;
  }

  .tx-sm-38-force {
    font-size: 38px !important;
  }

  .tx-sm-40 {
    font-size: 40px;
  }

  .tx-sm-40-force {
    font-size: 40px !important;
  }

  .tx-sm-42 {
    font-size: 42px;
  }

  .tx-sm-42-force {
    font-size: 42px !important;
  }

  .tx-sm-44 {
    font-size: 44px;
  }

  .tx-sm-44-force {
    font-size: 44px !important;
  }

  .tx-sm-46 {
    font-size: 46px;
  }

  .tx-sm-46-force {
    font-size: 46px !important;
  }

  .tx-sm-48 {
    font-size: 48px;
  }

  .tx-sm-48-force {
    font-size: 48px !important;
  }

  .tx-sm-50 {
    font-size: 50px;
  }

  .tx-sm-50-force {
    font-size: 50px !important;
  }

  .tx-sm-52 {
    font-size: 52px;
  }

  .tx-sm-52-force {
    font-size: 52px !important;
  }

  .tx-sm-54 {
    font-size: 54px;
  }

  .tx-sm-54-force {
    font-size: 54px !important;
  }

  .tx-sm-56 {
    font-size: 56px;
  }

  .tx-sm-56-force {
    font-size: 56px !important;
  }

  .tx-sm-58 {
    font-size: 58px;
  }

  .tx-sm-58-force {
    font-size: 58px !important;
  }

  .tx-sm-60 {
    font-size: 60px;
  }

  .tx-sm-60-force {
    font-size: 60px !important;
  }

  .tx-sm-62 {
    font-size: 62px;
  }

  .tx-sm-62-force {
    font-size: 62px !important;
  }

  .tx-sm-64 {
    font-size: 64px;
  }

  .tx-sm-64-force {
    font-size: 64px !important;
  }

  .tx-sm-66 {
    font-size: 66px;
  }

  .tx-sm-66-force {
    font-size: 66px !important;
  }

  .tx-sm-68 {
    font-size: 68px;
  }

  .tx-sm-68-force {
    font-size: 68px !important;
  }

  .tx-sm-70 {
    font-size: 70px;
  }

  .tx-sm-70-force {
    font-size: 70px !important;
  }

  .tx-sm-72 {
    font-size: 72px;
  }

  .tx-sm-72-force {
    font-size: 72px !important;
  }

  .tx-sm-74 {
    font-size: 74px;
  }

  .tx-sm-74-force {
    font-size: 74px !important;
  }

  .tx-sm-76 {
    font-size: 76px;
  }

  .tx-sm-76-force {
    font-size: 76px !important;
  }

  .tx-sm-78 {
    font-size: 78px;
  }

  .tx-sm-78-force {
    font-size: 78px !important;
  }

  .tx-sm-80 {
    font-size: 80px;
  }

  .tx-sm-80-force {
    font-size: 80px !important;
  }

  .tx-sm-82 {
    font-size: 82px;
  }

  .tx-sm-82-force {
    font-size: 82px !important;
  }

  .tx-sm-84 {
    font-size: 84px;
  }

  .tx-sm-84-force {
    font-size: 84px !important;
  }

  .tx-sm-86 {
    font-size: 86px;
  }

  .tx-sm-86-force {
    font-size: 86px !important;
  }

  .tx-sm-88 {
    font-size: 88px;
  }

  .tx-sm-88-force {
    font-size: 88px !important;
  }

  .tx-sm-90 {
    font-size: 90px;
  }

  .tx-sm-90-force {
    font-size: 90px !important;
  }

  .tx-sm-92 {
    font-size: 92px;
  }

  .tx-sm-92-force {
    font-size: 92px !important;
  }

  .tx-sm-94 {
    font-size: 94px;
  }

  .tx-sm-94-force {
    font-size: 94px !important;
  }

  .tx-sm-96 {
    font-size: 96px;
  }

  .tx-sm-96-force {
    font-size: 96px !important;
  }

  .tx-sm-98 {
    font-size: 98px;
  }

  .tx-sm-98-force {
    font-size: 98px !important;
  }

  .tx-sm-100 {
    font-size: 100px;
  }

  .tx-sm-100-force {
    font-size: 100px !important;
  }

  .tx-sm-102 {
    font-size: 102px;
  }

  .tx-sm-102-force {
    font-size: 102px !important;
  }

  .tx-sm-104 {
    font-size: 104px;
  }

  .tx-sm-104-force {
    font-size: 104px !important;
  }

  .tx-sm-106 {
    font-size: 106px;
  }

  .tx-sm-106-force {
    font-size: 106px !important;
  }

  .tx-sm-108 {
    font-size: 108px;
  }

  .tx-sm-108-force {
    font-size: 108px !important;
  }

  .tx-sm-110 {
    font-size: 110px;
  }

  .tx-sm-110-force {
    font-size: 110px !important;
  }

  .tx-sm-112 {
    font-size: 112px;
  }

  .tx-sm-112-force {
    font-size: 112px !important;
  }

  .tx-sm-114 {
    font-size: 114px;
  }

  .tx-sm-114-force {
    font-size: 114px !important;
  }

  .tx-sm-116 {
    font-size: 116px;
  }

  .tx-sm-116-force {
    font-size: 116px !important;
  }

  .tx-sm-118 {
    font-size: 118px;
  }

  .tx-sm-118-force {
    font-size: 118px !important;
  }

  .tx-sm-120 {
    font-size: 120px;
  }

  .tx-sm-120-force {
    font-size: 120px !important;
  }

  .tx-sm-122 {
    font-size: 122px;
  }

  .tx-sm-122-force {
    font-size: 122px !important;
  }

  .tx-sm-124 {
    font-size: 124px;
  }

  .tx-sm-124-force {
    font-size: 124px !important;
  }

  .tx-sm-126 {
    font-size: 126px;
  }

  .tx-sm-126-force {
    font-size: 126px !important;
  }

  .tx-sm-128 {
    font-size: 128px;
  }

  .tx-sm-128-force {
    font-size: 128px !important;
  }

  .tx-sm-130 {
    font-size: 130px;
  }

  .tx-sm-130-force {
    font-size: 130px !important;
  }

  .tx-sm-132 {
    font-size: 132px;
  }

  .tx-sm-132-force {
    font-size: 132px !important;
  }

  .tx-sm-134 {
    font-size: 134px;
  }

  .tx-sm-134-force {
    font-size: 134px !important;
  }

  .tx-sm-136 {
    font-size: 136px;
  }

  .tx-sm-136-force {
    font-size: 136px !important;
  }

  .tx-sm-138 {
    font-size: 138px;
  }

  .tx-sm-138-force {
    font-size: 138px !important;
  }

  .tx-sm-140 {
    font-size: 140px;
  }

  .tx-sm-140-force {
    font-size: 140px !important;
  }

  .tx-sm-bold {
    font-weight: 700;
  }

  .tx-sm-semibold {
    font-weight: 600;
  }

  .tx-sm-medium {
    font-weight: 500;
  }

  .tx-sm-normal {
    font-weight: 400;
  }

  .tx-sm-light {
    font-weight: 300;
  }

  .tx-sm-thin {
    font-weight: 200;
  }

  .tx-sm-xthin {
    font-weight: 100;
  }

  .lh-sm-0 {
    line-height: 0;
  }

  .lh-sm-1 {
    line-height: 1.1;
  }

  .lh-sm-2 {
    line-height: 1.2;
  }

  .lh-sm-3 {
    line-height: 1.3;
  }

  .lh-sm-4 {
    line-height: 1.4;
  }

  .lh-sm-5 {
    line-height: 1.5;
  }

  .lh-sm-6 {
    line-height: 1.6;
  }

  .lh-sm-7 {
    line-height: 1.7;
  }

  .lh-sm-8 {
    line-height: 1.8;
  }

  .lh-sm-9 {
    line-height: 1.9;
  }

  .lh-sm-10 {
    line-height: 2;
  }

  .lh-sm-11 {
    line-height: 2.1;
  }

  .lh-sm-12 {
    line-height: 2.2;
  }

  .lh-sm-13 {
    line-height: 2.3;
  }

  .lh-sm-14 {
    line-height: 2.4;
  }

  .lh-sm-15 {
    line-height: 2.5;
  }

  .tx-sm-center {
    text-align: center;
  }

  .tx-sm-right {
    text-align: right;
  }

  .tx-sm-left {
    text-align: left;
  }

  .tx-sm-center-force {
    text-align: center !important;
  }

  .tx-sm-right-force {
    text-align: right !important;
  }

  .tx-sm-left-force {
    text-align: left !important;
  }
}
@media (min-width: 768px) {
  .tx-md-8 {
    font-size: 8px;
  }

  .tx-md-8-force {
    font-size: 8px !important;
  }

  .tx-md-9 {
    font-size: 9px;
  }

  .tx-md-9-force {
    font-size: 9px !important;
  }

  .tx-md-10 {
    font-size: 10px;
  }

  .tx-md-10-force {
    font-size: 10px !important;
  }

  .tx-md-11 {
    font-size: 11px;
  }

  .tx-md-11-force {
    font-size: 11px !important;
  }

  .tx-md-12 {
    font-size: 12px;
  }

  .tx-md-12-force {
    font-size: 12px !important;
  }

  .tx-md-13 {
    font-size: 13px;
  }

  .tx-md-13-force {
    font-size: 13px !important;
  }

  .tx-md-14 {
    font-size: 14px;
  }

  .tx-md-14-force {
    font-size: 14px !important;
  }

  .tx-md-15 {
    font-size: 15px;
  }

  .tx-md-15-force {
    font-size: 15px !important;
  }

  .tx-md-16 {
    font-size: 16px;
  }

  .tx-md-16-force {
    font-size: 16px !important;
  }

  .tx-md-18 {
    font-size: 18px;
  }

  .tx-md-18-force {
    font-size: 18px !important;
  }

  .tx-md-20 {
    font-size: 20px;
  }

  .tx-md-20-force {
    font-size: 20px !important;
  }

  .tx-md-22 {
    font-size: 22px;
  }

  .tx-md-22-force {
    font-size: 22px !important;
  }

  .tx-md-24 {
    font-size: 24px;
  }

  .tx-md-24-force {
    font-size: 24px !important;
  }

  .tx-md-26 {
    font-size: 26px;
  }

  .tx-md-26-force {
    font-size: 26px !important;
  }

  .tx-md-28 {
    font-size: 28px;
  }

  .tx-md-28-force {
    font-size: 28px !important;
  }

  .tx-md-30 {
    font-size: 30px;
  }

  .tx-md-30-force {
    font-size: 30px !important;
  }

  .tx-md-32 {
    font-size: 32px;
  }

  .tx-md-32-force {
    font-size: 32px !important;
  }

  .tx-md-34 {
    font-size: 34px;
  }

  .tx-md-34-force {
    font-size: 34px !important;
  }

  .tx-md-36 {
    font-size: 36px;
  }

  .tx-md-36-force {
    font-size: 36px !important;
  }

  .tx-md-38 {
    font-size: 38px;
  }

  .tx-md-38-force {
    font-size: 38px !important;
  }

  .tx-md-40 {
    font-size: 40px;
  }

  .tx-md-40-force {
    font-size: 40px !important;
  }

  .tx-md-42 {
    font-size: 42px;
  }

  .tx-md-42-force {
    font-size: 42px !important;
  }

  .tx-md-44 {
    font-size: 44px;
  }

  .tx-md-44-force {
    font-size: 44px !important;
  }

  .tx-md-46 {
    font-size: 46px;
  }

  .tx-md-46-force {
    font-size: 46px !important;
  }

  .tx-md-48 {
    font-size: 48px;
  }

  .tx-md-48-force {
    font-size: 48px !important;
  }

  .tx-md-50 {
    font-size: 50px;
  }

  .tx-md-50-force {
    font-size: 50px !important;
  }

  .tx-md-52 {
    font-size: 52px;
  }

  .tx-md-52-force {
    font-size: 52px !important;
  }

  .tx-md-54 {
    font-size: 54px;
  }

  .tx-md-54-force {
    font-size: 54px !important;
  }

  .tx-md-56 {
    font-size: 56px;
  }

  .tx-md-56-force {
    font-size: 56px !important;
  }

  .tx-md-58 {
    font-size: 58px;
  }

  .tx-md-58-force {
    font-size: 58px !important;
  }

  .tx-md-60 {
    font-size: 60px;
  }

  .tx-md-60-force {
    font-size: 60px !important;
  }

  .tx-md-62 {
    font-size: 62px;
  }

  .tx-md-62-force {
    font-size: 62px !important;
  }

  .tx-md-64 {
    font-size: 64px;
  }

  .tx-md-64-force {
    font-size: 64px !important;
  }

  .tx-md-66 {
    font-size: 66px;
  }

  .tx-md-66-force {
    font-size: 66px !important;
  }

  .tx-md-68 {
    font-size: 68px;
  }

  .tx-md-68-force {
    font-size: 68px !important;
  }

  .tx-md-70 {
    font-size: 70px;
  }

  .tx-md-70-force {
    font-size: 70px !important;
  }

  .tx-md-72 {
    font-size: 72px;
  }

  .tx-md-72-force {
    font-size: 72px !important;
  }

  .tx-md-74 {
    font-size: 74px;
  }

  .tx-md-74-force {
    font-size: 74px !important;
  }

  .tx-md-76 {
    font-size: 76px;
  }

  .tx-md-76-force {
    font-size: 76px !important;
  }

  .tx-md-78 {
    font-size: 78px;
  }

  .tx-md-78-force {
    font-size: 78px !important;
  }

  .tx-md-80 {
    font-size: 80px;
  }

  .tx-md-80-force {
    font-size: 80px !important;
  }

  .tx-md-82 {
    font-size: 82px;
  }

  .tx-md-82-force {
    font-size: 82px !important;
  }

  .tx-md-84 {
    font-size: 84px;
  }

  .tx-md-84-force {
    font-size: 84px !important;
  }

  .tx-md-86 {
    font-size: 86px;
  }

  .tx-md-86-force {
    font-size: 86px !important;
  }

  .tx-md-88 {
    font-size: 88px;
  }

  .tx-md-88-force {
    font-size: 88px !important;
  }

  .tx-md-90 {
    font-size: 90px;
  }

  .tx-md-90-force {
    font-size: 90px !important;
  }

  .tx-md-92 {
    font-size: 92px;
  }

  .tx-md-92-force {
    font-size: 92px !important;
  }

  .tx-md-94 {
    font-size: 94px;
  }

  .tx-md-94-force {
    font-size: 94px !important;
  }

  .tx-md-96 {
    font-size: 96px;
  }

  .tx-md-96-force {
    font-size: 96px !important;
  }

  .tx-md-98 {
    font-size: 98px;
  }

  .tx-md-98-force {
    font-size: 98px !important;
  }

  .tx-md-100 {
    font-size: 100px;
  }

  .tx-md-100-force {
    font-size: 100px !important;
  }

  .tx-md-102 {
    font-size: 102px;
  }

  .tx-md-102-force {
    font-size: 102px !important;
  }

  .tx-md-104 {
    font-size: 104px;
  }

  .tx-md-104-force {
    font-size: 104px !important;
  }

  .tx-md-106 {
    font-size: 106px;
  }

  .tx-md-106-force {
    font-size: 106px !important;
  }

  .tx-md-108 {
    font-size: 108px;
  }

  .tx-md-108-force {
    font-size: 108px !important;
  }

  .tx-md-110 {
    font-size: 110px;
  }

  .tx-md-110-force {
    font-size: 110px !important;
  }

  .tx-md-112 {
    font-size: 112px;
  }

  .tx-md-112-force {
    font-size: 112px !important;
  }

  .tx-md-114 {
    font-size: 114px;
  }

  .tx-md-114-force {
    font-size: 114px !important;
  }

  .tx-md-116 {
    font-size: 116px;
  }

  .tx-md-116-force {
    font-size: 116px !important;
  }

  .tx-md-118 {
    font-size: 118px;
  }

  .tx-md-118-force {
    font-size: 118px !important;
  }

  .tx-md-120 {
    font-size: 120px;
  }

  .tx-md-120-force {
    font-size: 120px !important;
  }

  .tx-md-122 {
    font-size: 122px;
  }

  .tx-md-122-force {
    font-size: 122px !important;
  }

  .tx-md-124 {
    font-size: 124px;
  }

  .tx-md-124-force {
    font-size: 124px !important;
  }

  .tx-md-126 {
    font-size: 126px;
  }

  .tx-md-126-force {
    font-size: 126px !important;
  }

  .tx-md-128 {
    font-size: 128px;
  }

  .tx-md-128-force {
    font-size: 128px !important;
  }

  .tx-md-130 {
    font-size: 130px;
  }

  .tx-md-130-force {
    font-size: 130px !important;
  }

  .tx-md-132 {
    font-size: 132px;
  }

  .tx-md-132-force {
    font-size: 132px !important;
  }

  .tx-md-134 {
    font-size: 134px;
  }

  .tx-md-134-force {
    font-size: 134px !important;
  }

  .tx-md-136 {
    font-size: 136px;
  }

  .tx-md-136-force {
    font-size: 136px !important;
  }

  .tx-md-138 {
    font-size: 138px;
  }

  .tx-md-138-force {
    font-size: 138px !important;
  }

  .tx-md-140 {
    font-size: 140px;
  }

  .tx-md-140-force {
    font-size: 140px !important;
  }

  .tx-md-bold {
    font-weight: 700;
  }

  .tx-md-semibold {
    font-weight: 600;
  }

  .tx-md-medium {
    font-weight: 500;
  }

  .tx-md-normal {
    font-weight: 400;
  }

  .tx-md-light {
    font-weight: 300;
  }

  .tx-md-thin {
    font-weight: 200;
  }

  .tx-md-xthin {
    font-weight: 100;
  }

  .lh-md-0 {
    line-height: 0;
  }

  .lh-md-1 {
    line-height: 1.1;
  }

  .lh-md-2 {
    line-height: 1.2;
  }

  .lh-md-3 {
    line-height: 1.3;
  }

  .lh-md-4 {
    line-height: 1.4;
  }

  .lh-md-5 {
    line-height: 1.5;
  }

  .lh-md-6 {
    line-height: 1.6;
  }

  .lh-md-7 {
    line-height: 1.7;
  }

  .lh-md-8 {
    line-height: 1.8;
  }

  .lh-md-9 {
    line-height: 1.9;
  }

  .lh-md-10 {
    line-height: 2;
  }

  .lh-md-11 {
    line-height: 2.1;
  }

  .lh-md-12 {
    line-height: 2.2;
  }

  .lh-md-13 {
    line-height: 2.3;
  }

  .lh-md-14 {
    line-height: 2.4;
  }

  .lh-md-15 {
    line-height: 2.5;
  }

  .tx-md-center {
    text-align: center;
  }

  .tx-md-right {
    text-align: right;
  }

  .tx-md-left {
    text-align: left;
  }

  .tx-md-center-force {
    text-align: center !important;
  }

  .tx-md-right-force {
    text-align: right !important;
  }

  .tx-md-left-force {
    text-align: left !important;
  }
}
@media (min-width: 992px) {
  .tx-lg-8 {
    font-size: 8px;
  }

  .tx-lg-8-force {
    font-size: 8px !important;
  }

  .tx-lg-9 {
    font-size: 9px;
  }

  .tx-lg-9-force {
    font-size: 9px !important;
  }

  .tx-lg-10 {
    font-size: 10px;
  }

  .tx-lg-10-force {
    font-size: 10px !important;
  }

  .tx-lg-11 {
    font-size: 11px;
  }

  .tx-lg-11-force {
    font-size: 11px !important;
  }

  .tx-lg-12 {
    font-size: 12px;
  }

  .tx-lg-12-force {
    font-size: 12px !important;
  }

  .tx-lg-13 {
    font-size: 13px;
  }

  .tx-lg-13-force {
    font-size: 13px !important;
  }

  .tx-lg-14 {
    font-size: 14px;
  }

  .tx-lg-14-force {
    font-size: 14px !important;
  }

  .tx-lg-15 {
    font-size: 15px;
  }

  .tx-lg-15-force {
    font-size: 15px !important;
  }

  .tx-lg-16 {
    font-size: 16px;
  }

  .tx-lg-16-force {
    font-size: 16px !important;
  }

  .tx-lg-18 {
    font-size: 18px;
  }

  .tx-lg-18-force {
    font-size: 18px !important;
  }

  .tx-lg-20 {
    font-size: 20px;
  }

  .tx-lg-20-force {
    font-size: 20px !important;
  }

  .tx-lg-22 {
    font-size: 22px;
  }

  .tx-lg-22-force {
    font-size: 22px !important;
  }

  .tx-lg-24 {
    font-size: 24px;
  }

  .tx-lg-24-force {
    font-size: 24px !important;
  }

  .tx-lg-26 {
    font-size: 26px;
  }

  .tx-lg-26-force {
    font-size: 26px !important;
  }

  .tx-lg-28 {
    font-size: 28px;
  }

  .tx-lg-28-force {
    font-size: 28px !important;
  }

  .tx-lg-30 {
    font-size: 30px;
  }

  .tx-lg-30-force {
    font-size: 30px !important;
  }

  .tx-lg-32 {
    font-size: 32px;
  }

  .tx-lg-32-force {
    font-size: 32px !important;
  }

  .tx-lg-34 {
    font-size: 34px;
  }

  .tx-lg-34-force {
    font-size: 34px !important;
  }

  .tx-lg-36 {
    font-size: 36px;
  }

  .tx-lg-36-force {
    font-size: 36px !important;
  }

  .tx-lg-38 {
    font-size: 38px;
  }

  .tx-lg-38-force {
    font-size: 38px !important;
  }

  .tx-lg-40 {
    font-size: 40px;
  }

  .tx-lg-40-force {
    font-size: 40px !important;
  }

  .tx-lg-42 {
    font-size: 42px;
  }

  .tx-lg-42-force {
    font-size: 42px !important;
  }

  .tx-lg-44 {
    font-size: 44px;
  }

  .tx-lg-44-force {
    font-size: 44px !important;
  }

  .tx-lg-46 {
    font-size: 46px;
  }

  .tx-lg-46-force {
    font-size: 46px !important;
  }

  .tx-lg-48 {
    font-size: 48px;
  }

  .tx-lg-48-force {
    font-size: 48px !important;
  }

  .tx-lg-50 {
    font-size: 50px;
  }

  .tx-lg-50-force {
    font-size: 50px !important;
  }

  .tx-lg-52 {
    font-size: 52px;
  }

  .tx-lg-52-force {
    font-size: 52px !important;
  }

  .tx-lg-54 {
    font-size: 54px;
  }

  .tx-lg-54-force {
    font-size: 54px !important;
  }

  .tx-lg-56 {
    font-size: 56px;
  }

  .tx-lg-56-force {
    font-size: 56px !important;
  }

  .tx-lg-58 {
    font-size: 58px;
  }

  .tx-lg-58-force {
    font-size: 58px !important;
  }

  .tx-lg-60 {
    font-size: 60px;
  }

  .tx-lg-60-force {
    font-size: 60px !important;
  }

  .tx-lg-62 {
    font-size: 62px;
  }

  .tx-lg-62-force {
    font-size: 62px !important;
  }

  .tx-lg-64 {
    font-size: 64px;
  }

  .tx-lg-64-force {
    font-size: 64px !important;
  }

  .tx-lg-66 {
    font-size: 66px;
  }

  .tx-lg-66-force {
    font-size: 66px !important;
  }

  .tx-lg-68 {
    font-size: 68px;
  }

  .tx-lg-68-force {
    font-size: 68px !important;
  }

  .tx-lg-70 {
    font-size: 70px;
  }

  .tx-lg-70-force {
    font-size: 70px !important;
  }

  .tx-lg-72 {
    font-size: 72px;
  }

  .tx-lg-72-force {
    font-size: 72px !important;
  }

  .tx-lg-74 {
    font-size: 74px;
  }

  .tx-lg-74-force {
    font-size: 74px !important;
  }

  .tx-lg-76 {
    font-size: 76px;
  }

  .tx-lg-76-force {
    font-size: 76px !important;
  }

  .tx-lg-78 {
    font-size: 78px;
  }

  .tx-lg-78-force {
    font-size: 78px !important;
  }

  .tx-lg-80 {
    font-size: 80px;
  }

  .tx-lg-80-force {
    font-size: 80px !important;
  }

  .tx-lg-82 {
    font-size: 82px;
  }

  .tx-lg-82-force {
    font-size: 82px !important;
  }

  .tx-lg-84 {
    font-size: 84px;
  }

  .tx-lg-84-force {
    font-size: 84px !important;
  }

  .tx-lg-86 {
    font-size: 86px;
  }

  .tx-lg-86-force {
    font-size: 86px !important;
  }

  .tx-lg-88 {
    font-size: 88px;
  }

  .tx-lg-88-force {
    font-size: 88px !important;
  }

  .tx-lg-90 {
    font-size: 90px;
  }

  .tx-lg-90-force {
    font-size: 90px !important;
  }

  .tx-lg-92 {
    font-size: 92px;
  }

  .tx-lg-92-force {
    font-size: 92px !important;
  }

  .tx-lg-94 {
    font-size: 94px;
  }

  .tx-lg-94-force {
    font-size: 94px !important;
  }

  .tx-lg-96 {
    font-size: 96px;
  }

  .tx-lg-96-force {
    font-size: 96px !important;
  }

  .tx-lg-98 {
    font-size: 98px;
  }

  .tx-lg-98-force {
    font-size: 98px !important;
  }

  .tx-lg-100 {
    font-size: 100px;
  }

  .tx-lg-100-force {
    font-size: 100px !important;
  }

  .tx-lg-102 {
    font-size: 102px;
  }

  .tx-lg-102-force {
    font-size: 102px !important;
  }

  .tx-lg-104 {
    font-size: 104px;
  }

  .tx-lg-104-force {
    font-size: 104px !important;
  }

  .tx-lg-106 {
    font-size: 106px;
  }

  .tx-lg-106-force {
    font-size: 106px !important;
  }

  .tx-lg-108 {
    font-size: 108px;
  }

  .tx-lg-108-force {
    font-size: 108px !important;
  }

  .tx-lg-110 {
    font-size: 110px;
  }

  .tx-lg-110-force {
    font-size: 110px !important;
  }

  .tx-lg-112 {
    font-size: 112px;
  }

  .tx-lg-112-force {
    font-size: 112px !important;
  }

  .tx-lg-114 {
    font-size: 114px;
  }

  .tx-lg-114-force {
    font-size: 114px !important;
  }

  .tx-lg-116 {
    font-size: 116px;
  }

  .tx-lg-116-force {
    font-size: 116px !important;
  }

  .tx-lg-118 {
    font-size: 118px;
  }

  .tx-lg-118-force {
    font-size: 118px !important;
  }

  .tx-lg-120 {
    font-size: 120px;
  }

  .tx-lg-120-force {
    font-size: 120px !important;
  }

  .tx-lg-122 {
    font-size: 122px;
  }

  .tx-lg-122-force {
    font-size: 122px !important;
  }

  .tx-lg-124 {
    font-size: 124px;
  }

  .tx-lg-124-force {
    font-size: 124px !important;
  }

  .tx-lg-126 {
    font-size: 126px;
  }

  .tx-lg-126-force {
    font-size: 126px !important;
  }

  .tx-lg-128 {
    font-size: 128px;
  }

  .tx-lg-128-force {
    font-size: 128px !important;
  }

  .tx-lg-130 {
    font-size: 130px;
  }

  .tx-lg-130-force {
    font-size: 130px !important;
  }

  .tx-lg-132 {
    font-size: 132px;
  }

  .tx-lg-132-force {
    font-size: 132px !important;
  }

  .tx-lg-134 {
    font-size: 134px;
  }

  .tx-lg-134-force {
    font-size: 134px !important;
  }

  .tx-lg-136 {
    font-size: 136px;
  }

  .tx-lg-136-force {
    font-size: 136px !important;
  }

  .tx-lg-138 {
    font-size: 138px;
  }

  .tx-lg-138-force {
    font-size: 138px !important;
  }

  .tx-lg-140 {
    font-size: 140px;
  }

  .tx-lg-140-force {
    font-size: 140px !important;
  }

  .tx-lg-bold {
    font-weight: 700;
  }

  .tx-lg-semibold {
    font-weight: 600;
  }

  .tx-lg-medium {
    font-weight: 500;
  }

  .tx-lg-normal {
    font-weight: 400;
  }

  .tx-lg-light {
    font-weight: 300;
  }

  .tx-lg-thin {
    font-weight: 200;
  }

  .tx-lg-xthin {
    font-weight: 100;
  }

  .lh-lg-0 {
    line-height: 0;
  }

  .lh-lg-1 {
    line-height: 1.1;
  }

  .lh-lg-2 {
    line-height: 1.2;
  }

  .lh-lg-3 {
    line-height: 1.3;
  }

  .lh-lg-4 {
    line-height: 1.4;
  }

  .lh-lg-5 {
    line-height: 1.5;
  }

  .lh-lg-6 {
    line-height: 1.6;
  }

  .lh-lg-7 {
    line-height: 1.7;
  }

  .lh-lg-8 {
    line-height: 1.8;
  }

  .lh-lg-9 {
    line-height: 1.9;
  }

  .lh-lg-10 {
    line-height: 2;
  }

  .lh-lg-11 {
    line-height: 2.1;
  }

  .lh-lg-12 {
    line-height: 2.2;
  }

  .lh-lg-13 {
    line-height: 2.3;
  }

  .lh-lg-14 {
    line-height: 2.4;
  }

  .lh-lg-15 {
    line-height: 2.5;
  }

  .tx-lg-center {
    text-align: center;
  }

  .tx-lg-right {
    text-align: right;
  }

  .tx-lg-left {
    text-align: left;
  }

  .tx-lg-center-force {
    text-align: center !important;
  }

  .tx-lg-right-force {
    text-align: right !important;
  }

  .tx-lg-left-force {
    text-align: left !important;
  }
}
@media (min-width: 1200px) {
  .tx-xl-8 {
    font-size: 8px;
  }

  .tx-xl-8-force {
    font-size: 8px !important;
  }

  .tx-xl-9 {
    font-size: 9px;
  }

  .tx-xl-9-force {
    font-size: 9px !important;
  }

  .tx-xl-10 {
    font-size: 10px;
  }

  .tx-xl-10-force {
    font-size: 10px !important;
  }

  .tx-xl-11 {
    font-size: 11px;
  }

  .tx-xl-11-force {
    font-size: 11px !important;
  }

  .tx-xl-12 {
    font-size: 12px;
  }

  .tx-xl-12-force {
    font-size: 12px !important;
  }

  .tx-xl-13 {
    font-size: 13px;
  }

  .tx-xl-13-force {
    font-size: 13px !important;
  }

  .tx-xl-14 {
    font-size: 14px;
  }

  .tx-xl-14-force {
    font-size: 14px !important;
  }

  .tx-xl-15 {
    font-size: 15px;
  }

  .tx-xl-15-force {
    font-size: 15px !important;
  }

  .tx-xl-16 {
    font-size: 16px;
  }

  .tx-xl-16-force {
    font-size: 16px !important;
  }

  .tx-xl-18 {
    font-size: 18px;
  }

  .tx-xl-18-force {
    font-size: 18px !important;
  }

  .tx-xl-20 {
    font-size: 20px;
  }

  .tx-xl-20-force {
    font-size: 20px !important;
  }

  .tx-xl-22 {
    font-size: 22px;
  }

  .tx-xl-22-force {
    font-size: 22px !important;
  }

  .tx-xl-24 {
    font-size: 24px;
  }

  .tx-xl-24-force {
    font-size: 24px !important;
  }

  .tx-xl-26 {
    font-size: 26px;
  }

  .tx-xl-26-force {
    font-size: 26px !important;
  }

  .tx-xl-28 {
    font-size: 28px;
  }

  .tx-xl-28-force {
    font-size: 28px !important;
  }

  .tx-xl-30 {
    font-size: 30px;
  }

  .tx-xl-30-force {
    font-size: 30px !important;
  }

  .tx-xl-32 {
    font-size: 32px;
  }

  .tx-xl-32-force {
    font-size: 32px !important;
  }

  .tx-xl-34 {
    font-size: 34px;
  }

  .tx-xl-34-force {
    font-size: 34px !important;
  }

  .tx-xl-36 {
    font-size: 36px;
  }

  .tx-xl-36-force {
    font-size: 36px !important;
  }

  .tx-xl-38 {
    font-size: 38px;
  }

  .tx-xl-38-force {
    font-size: 38px !important;
  }

  .tx-xl-40 {
    font-size: 40px;
  }

  .tx-xl-40-force {
    font-size: 40px !important;
  }

  .tx-xl-42 {
    font-size: 42px;
  }

  .tx-xl-42-force {
    font-size: 42px !important;
  }

  .tx-xl-44 {
    font-size: 44px;
  }

  .tx-xl-44-force {
    font-size: 44px !important;
  }

  .tx-xl-46 {
    font-size: 46px;
  }

  .tx-xl-46-force {
    font-size: 46px !important;
  }

  .tx-xl-48 {
    font-size: 48px;
  }

  .tx-xl-48-force {
    font-size: 48px !important;
  }

  .tx-xl-50 {
    font-size: 50px;
  }

  .tx-xl-50-force {
    font-size: 50px !important;
  }

  .tx-xl-52 {
    font-size: 52px;
  }

  .tx-xl-52-force {
    font-size: 52px !important;
  }

  .tx-xl-54 {
    font-size: 54px;
  }

  .tx-xl-54-force {
    font-size: 54px !important;
  }

  .tx-xl-56 {
    font-size: 56px;
  }

  .tx-xl-56-force {
    font-size: 56px !important;
  }

  .tx-xl-58 {
    font-size: 58px;
  }

  .tx-xl-58-force {
    font-size: 58px !important;
  }

  .tx-xl-60 {
    font-size: 60px;
  }

  .tx-xl-60-force {
    font-size: 60px !important;
  }

  .tx-xl-62 {
    font-size: 62px;
  }

  .tx-xl-62-force {
    font-size: 62px !important;
  }

  .tx-xl-64 {
    font-size: 64px;
  }

  .tx-xl-64-force {
    font-size: 64px !important;
  }

  .tx-xl-66 {
    font-size: 66px;
  }

  .tx-xl-66-force {
    font-size: 66px !important;
  }

  .tx-xl-68 {
    font-size: 68px;
  }

  .tx-xl-68-force {
    font-size: 68px !important;
  }

  .tx-xl-70 {
    font-size: 70px;
  }

  .tx-xl-70-force {
    font-size: 70px !important;
  }

  .tx-xl-72 {
    font-size: 72px;
  }

  .tx-xl-72-force {
    font-size: 72px !important;
  }

  .tx-xl-74 {
    font-size: 74px;
  }

  .tx-xl-74-force {
    font-size: 74px !important;
  }

  .tx-xl-76 {
    font-size: 76px;
  }

  .tx-xl-76-force {
    font-size: 76px !important;
  }

  .tx-xl-78 {
    font-size: 78px;
  }

  .tx-xl-78-force {
    font-size: 78px !important;
  }

  .tx-xl-80 {
    font-size: 80px;
  }

  .tx-xl-80-force {
    font-size: 80px !important;
  }

  .tx-xl-82 {
    font-size: 82px;
  }

  .tx-xl-82-force {
    font-size: 82px !important;
  }

  .tx-xl-84 {
    font-size: 84px;
  }

  .tx-xl-84-force {
    font-size: 84px !important;
  }

  .tx-xl-86 {
    font-size: 86px;
  }

  .tx-xl-86-force {
    font-size: 86px !important;
  }

  .tx-xl-88 {
    font-size: 88px;
  }

  .tx-xl-88-force {
    font-size: 88px !important;
  }

  .tx-xl-90 {
    font-size: 90px;
  }

  .tx-xl-90-force {
    font-size: 90px !important;
  }

  .tx-xl-92 {
    font-size: 92px;
  }

  .tx-xl-92-force {
    font-size: 92px !important;
  }

  .tx-xl-94 {
    font-size: 94px;
  }

  .tx-xl-94-force {
    font-size: 94px !important;
  }

  .tx-xl-96 {
    font-size: 96px;
  }

  .tx-xl-96-force {
    font-size: 96px !important;
  }

  .tx-xl-98 {
    font-size: 98px;
  }

  .tx-xl-98-force {
    font-size: 98px !important;
  }

  .tx-xl-100 {
    font-size: 100px;
  }

  .tx-xl-100-force {
    font-size: 100px !important;
  }

  .tx-xl-102 {
    font-size: 102px;
  }

  .tx-xl-102-force {
    font-size: 102px !important;
  }

  .tx-xl-104 {
    font-size: 104px;
  }

  .tx-xl-104-force {
    font-size: 104px !important;
  }

  .tx-xl-106 {
    font-size: 106px;
  }

  .tx-xl-106-force {
    font-size: 106px !important;
  }

  .tx-xl-108 {
    font-size: 108px;
  }

  .tx-xl-108-force {
    font-size: 108px !important;
  }

  .tx-xl-110 {
    font-size: 110px;
  }

  .tx-xl-110-force {
    font-size: 110px !important;
  }

  .tx-xl-112 {
    font-size: 112px;
  }

  .tx-xl-112-force {
    font-size: 112px !important;
  }

  .tx-xl-114 {
    font-size: 114px;
  }

  .tx-xl-114-force {
    font-size: 114px !important;
  }

  .tx-xl-116 {
    font-size: 116px;
  }

  .tx-xl-116-force {
    font-size: 116px !important;
  }

  .tx-xl-118 {
    font-size: 118px;
  }

  .tx-xl-118-force {
    font-size: 118px !important;
  }

  .tx-xl-120 {
    font-size: 120px;
  }

  .tx-xl-120-force {
    font-size: 120px !important;
  }

  .tx-xl-122 {
    font-size: 122px;
  }

  .tx-xl-122-force {
    font-size: 122px !important;
  }

  .tx-xl-124 {
    font-size: 124px;
  }

  .tx-xl-124-force {
    font-size: 124px !important;
  }

  .tx-xl-126 {
    font-size: 126px;
  }

  .tx-xl-126-force {
    font-size: 126px !important;
  }

  .tx-xl-128 {
    font-size: 128px;
  }

  .tx-xl-128-force {
    font-size: 128px !important;
  }

  .tx-xl-130 {
    font-size: 130px;
  }

  .tx-xl-130-force {
    font-size: 130px !important;
  }

  .tx-xl-132 {
    font-size: 132px;
  }

  .tx-xl-132-force {
    font-size: 132px !important;
  }

  .tx-xl-134 {
    font-size: 134px;
  }

  .tx-xl-134-force {
    font-size: 134px !important;
  }

  .tx-xl-136 {
    font-size: 136px;
  }

  .tx-xl-136-force {
    font-size: 136px !important;
  }

  .tx-xl-138 {
    font-size: 138px;
  }

  .tx-xl-138-force {
    font-size: 138px !important;
  }

  .tx-xl-140 {
    font-size: 140px;
  }

  .tx-xl-140-force {
    font-size: 140px !important;
  }

  .tx-xl-bold {
    font-weight: 700;
  }

  .tx-xl-semibold {
    font-weight: 600;
  }

  .tx-xl-medium {
    font-weight: 500;
  }

  .tx-xl-normal {
    font-weight: 400;
  }

  .tx-xl-light {
    font-weight: 300;
  }

  .tx-xl-thin {
    font-weight: 200;
  }

  .tx-xl-xthin {
    font-weight: 100;
  }

  .lh-xl-0 {
    line-height: 0;
  }

  .lh-xl-1 {
    line-height: 1.1;
  }

  .lh-xl-2 {
    line-height: 1.2;
  }

  .lh-xl-3 {
    line-height: 1.3;
  }

  .lh-xl-4 {
    line-height: 1.4;
  }

  .lh-xl-5 {
    line-height: 1.5;
  }

  .lh-xl-6 {
    line-height: 1.6;
  }

  .lh-xl-7 {
    line-height: 1.7;
  }

  .lh-xl-8 {
    line-height: 1.8;
  }

  .lh-xl-9 {
    line-height: 1.9;
  }

  .lh-xl-10 {
    line-height: 2;
  }

  .lh-xl-11 {
    line-height: 2.1;
  }

  .lh-xl-12 {
    line-height: 2.2;
  }

  .lh-xl-13 {
    line-height: 2.3;
  }

  .lh-xl-14 {
    line-height: 2.4;
  }

  .lh-xl-15 {
    line-height: 2.5;
  }

  .tx-xl-center {
    text-align: center;
  }

  .tx-xl-right {
    text-align: right;
  }

  .tx-xl-left {
    text-align: left;
  }

  .tx-xl-center-force {
    text-align: center !important;
  }

  .tx-xl-right-force {
    text-align: right !important;
  }

  .tx-xl-left-force {
    text-align: left !important;
  }
}
/* -------------------------------------- */
/* ############### Height ############### */
/* -------------------------------------- */
.ht-5 {
  height: 5px;
}

.mn-ht-5 {
  min-height: 5px;
}

.ht-5p {
  height: 5%;
}

.mx-ht-5p {
  max-height: 5%;
}

.mn-ht-5p {
  min-height: 5%;
}

.ht-5-force {
  height: 5px !important;
}

.ht-5p-force {
  height: 5% !important;
}

.ht-10 {
  height: 10px;
}

.mn-ht-10 {
  min-height: 10px;
}

.ht-10p {
  height: 10%;
}

.mx-ht-10p {
  max-height: 10%;
}

.mn-ht-10p {
  min-height: 10%;
}

.ht-10-force {
  height: 10px !important;
}

.ht-10p-force {
  height: 10% !important;
}

.ht-15 {
  height: 15px;
}

.mn-ht-15 {
  min-height: 15px;
}

.ht-15p {
  height: 15%;
}

.mx-ht-15p {
  max-height: 15%;
}

.mn-ht-15p {
  min-height: 15%;
}

.ht-15-force {
  height: 15px !important;
}

.ht-15p-force {
  height: 15% !important;
}

.ht-20 {
  height: 20px;
}

.mn-ht-20 {
  min-height: 20px;
}

.ht-20p {
  height: 20%;
}

.mx-ht-20p {
  max-height: 20%;
}

.mn-ht-20p {
  min-height: 20%;
}

.ht-20-force {
  height: 20px !important;
}

.ht-20p-force {
  height: 20% !important;
}

.ht-25 {
  height: 25px;
}

.mn-ht-25 {
  min-height: 25px;
}

.ht-25p {
  height: 25%;
}

.mx-ht-25p {
  max-height: 25%;
}

.mn-ht-25p {
  min-height: 25%;
}

.ht-25-force {
  height: 25px !important;
}

.ht-25p-force {
  height: 25% !important;
}

.ht-30 {
  height: 30px;
}

.mn-ht-30 {
  min-height: 30px;
}

.ht-30p {
  height: 30%;
}

.mx-ht-30p {
  max-height: 30%;
}

.mn-ht-30p {
  min-height: 30%;
}

.ht-30-force {
  height: 30px !important;
}

.ht-30p-force {
  height: 30% !important;
}

.ht-35 {
  height: 35px;
}

.mn-ht-35 {
  min-height: 35px;
}

.ht-35p {
  height: 35%;
}

.mx-ht-35p {
  max-height: 35%;
}

.mn-ht-35p {
  min-height: 35%;
}

.ht-35-force {
  height: 35px !important;
}

.ht-35p-force {
  height: 35% !important;
}

.ht-40 {
  height: 40px;
}

.mn-ht-40 {
  min-height: 40px;
}

.ht-40p {
  height: 40%;
}

.mx-ht-40p {
  max-height: 40%;
}

.mn-ht-40p {
  min-height: 40%;
}

.ht-40-force {
  height: 40px !important;
}

.ht-40p-force {
  height: 40% !important;
}

.ht-45 {
  height: 45px;
}

.mn-ht-45 {
  min-height: 45px;
}

.ht-45p {
  height: 45%;
}

.mx-ht-45p {
  max-height: 45%;
}

.mn-ht-45p {
  min-height: 45%;
}

.ht-45-force {
  height: 45px !important;
}

.ht-45p-force {
  height: 45% !important;
}

.ht-50 {
  height: 50px;
}

.mn-ht-50 {
  min-height: 50px;
}

.ht-50p {
  height: 50%;
}

.mx-ht-50p {
  max-height: 50%;
}

.mn-ht-50p {
  min-height: 50%;
}

.ht-50-force {
  height: 50px !important;
}

.ht-50p-force {
  height: 50% !important;
}

.ht-55 {
  height: 55px;
}

.mn-ht-55 {
  min-height: 55px;
}

.ht-55p {
  height: 55%;
}

.mx-ht-55p {
  max-height: 55%;
}

.mn-ht-55p {
  min-height: 55%;
}

.ht-55-force {
  height: 55px !important;
}

.ht-55p-force {
  height: 55% !important;
}

.ht-60 {
  height: 60px;
}

.mn-ht-60 {
  min-height: 60px;
}

.ht-60p {
  height: 60%;
}

.mx-ht-60p {
  max-height: 60%;
}

.mn-ht-60p {
  min-height: 60%;
}

.ht-60-force {
  height: 60px !important;
}

.ht-60p-force {
  height: 60% !important;
}

.ht-65 {
  height: 65px;
}

.mn-ht-65 {
  min-height: 65px;
}

.ht-65p {
  height: 65%;
}

.mx-ht-65p {
  max-height: 65%;
}

.mn-ht-65p {
  min-height: 65%;
}

.ht-65-force {
  height: 65px !important;
}

.ht-65p-force {
  height: 65% !important;
}

.ht-70 {
  height: 70px;
}

.mn-ht-70 {
  min-height: 70px;
}

.ht-70p {
  height: 70%;
}

.mx-ht-70p {
  max-height: 70%;
}

.mn-ht-70p {
  min-height: 70%;
}

.ht-70-force {
  height: 70px !important;
}

.ht-70p-force {
  height: 70% !important;
}

.ht-75 {
  height: 75px;
}

.mn-ht-75 {
  min-height: 75px;
}

.ht-75p {
  height: 75%;
}

.mx-ht-75p {
  max-height: 75%;
}

.mn-ht-75p {
  min-height: 75%;
}

.ht-75-force {
  height: 75px !important;
}

.ht-75p-force {
  height: 75% !important;
}

.ht-80 {
  height: 80px;
}

.mn-ht-80 {
  min-height: 80px;
}

.ht-80p {
  height: 80%;
}

.mx-ht-80p {
  max-height: 80%;
}

.mn-ht-80p {
  min-height: 80%;
}

.ht-80-force {
  height: 80px !important;
}

.ht-80p-force {
  height: 80% !important;
}

.ht-85 {
  height: 85px;
}

.mn-ht-85 {
  min-height: 85px;
}

.ht-85p {
  height: 85%;
}

.mx-ht-85p {
  max-height: 85%;
}

.mn-ht-85p {
  min-height: 85%;
}

.ht-85-force {
  height: 85px !important;
}

.ht-85p-force {
  height: 85% !important;
}

.ht-90 {
  height: 90px;
}

.mn-ht-90 {
  min-height: 90px;
}

.ht-90p {
  height: 90%;
}

.mx-ht-90p {
  max-height: 90%;
}

.mn-ht-90p {
  min-height: 90%;
}

.ht-90-force {
  height: 90px !important;
}

.ht-90p-force {
  height: 90% !important;
}

.ht-95 {
  height: 95px;
}

.mn-ht-95 {
  min-height: 95px;
}

.ht-95p {
  height: 95%;
}

.mx-ht-95p {
  max-height: 95%;
}

.mn-ht-95p {
  min-height: 95%;
}

.ht-95-force {
  height: 95px !important;
}

.ht-95p-force {
  height: 95% !important;
}

.ht-100 {
  height: 100px;
}

.mn-ht-100 {
  min-height: 100px;
}

.ht-100p {
  height: 100%;
}

.mx-ht-100p {
  max-height: 100%;
}

.mn-ht-100p {
  min-height: 100%;
}

.ht-100-force {
  height: 100px !important;
}

.ht-100p-force {
  height: 100% !important;
}

.ht-150 {
  height: 150px;
}

.ht-200 {
  height: 200px;
}

.ht-250 {
  height: 250px;
}

.ht-300 {
  height: 300px;
}

.ht-350 {
  height: 350px;
}

.ht-400 {
  height: 400px;
}

.ht-450 {
  height: 450px;
}

.ht-500 {
  height: 500px;
}

.ht-550 {
  height: 550px;
}

.ht-600 {
  height: 600px;
}

.ht-650 {
  height: 650px;
}

.ht-700 {
  height: 700px;
}

.ht-750 {
  height: 750px;
}

.ht-800 {
  height: 800px;
}

.ht-100v {
  height: 100vh;
}

.ht-1 {
  height: 1px;
}

.ht-2 {
  height: 2px;
}

.ht-3 {
  height: 3px;
}

.ht-4 {
  height: 4px;
}

.ht-6 {
  height: 6px;
}

.ht-7 {
  height: 7px;
}

.ht-8 {
  height: 8px;
}

.ht-9 {
  height: 9px;
}

.ht-auto {
  height: auto;
}

.ht-16 {
  height: 16px;
}

.ht-32 {
  height: 32px;
}

.ht-36 {
  height: 36px;
}

.ht-48 {
  height: 48px;
}

.ht-64 {
  height: 64px;
}

.ht-72 {
  height: 72px;
}

.ht-115 {
  height: 115px;
}

.ht-120 {
  height: 120px;
}

.ht-160 {
  height: 160px;
}

@media (min-width: 480px) {
  .ht-xs-100 {
    height: 100px;
  }

  .mn-ht-xs-100 {
    min-height: 100px;
  }

  .ht-xs-100p {
    height: 100%;
  }

  .mx-ht-xs-100p {
    max-height: 100%;
  }

  .mn-ht-xs-100p {
    min-height: 100%;
  }

  .ht-xs-100-force {
    height: 100px !important;
  }

  .ht-xs-100p-force {
    height: 100% !important;
  }

  .ht-xs-200 {
    height: 200px;
  }

  .mn-ht-xs-200 {
    min-height: 200px;
  }

  .ht-xs-200p {
    height: 200%;
  }

  .mx-ht-xs-200p {
    max-height: 200%;
  }

  .mn-ht-xs-200p {
    min-height: 200%;
  }

  .ht-xs-200-force {
    height: 200px !important;
  }

  .ht-xs-200p-force {
    height: 200% !important;
  }

  .ht-xs-300 {
    height: 300px;
  }

  .mn-ht-xs-300 {
    min-height: 300px;
  }

  .ht-xs-300p {
    height: 300%;
  }

  .mx-ht-xs-300p {
    max-height: 300%;
  }

  .mn-ht-xs-300p {
    min-height: 300%;
  }

  .ht-xs-300-force {
    height: 300px !important;
  }

  .ht-xs-300p-force {
    height: 300% !important;
  }

  .ht-xs-400 {
    height: 400px;
  }

  .mn-ht-xs-400 {
    min-height: 400px;
  }

  .ht-xs-400p {
    height: 400%;
  }

  .mx-ht-xs-400p {
    max-height: 400%;
  }

  .mn-ht-xs-400p {
    min-height: 400%;
  }

  .ht-xs-400-force {
    height: 400px !important;
  }

  .ht-xs-400p-force {
    height: 400% !important;
  }

  .ht-xs-500 {
    height: 500px;
  }

  .mn-ht-xs-500 {
    min-height: 500px;
  }

  .ht-xs-500p {
    height: 500%;
  }

  .mx-ht-xs-500p {
    max-height: 500%;
  }

  .mn-ht-xs-500p {
    min-height: 500%;
  }

  .ht-xs-500-force {
    height: 500px !important;
  }

  .ht-xs-500p-force {
    height: 500% !important;
  }

  .ht-xs-600 {
    height: 600px;
  }

  .mn-ht-xs-600 {
    min-height: 600px;
  }

  .ht-xs-600p {
    height: 600%;
  }

  .mx-ht-xs-600p {
    max-height: 600%;
  }

  .mn-ht-xs-600p {
    min-height: 600%;
  }

  .ht-xs-600-force {
    height: 600px !important;
  }

  .ht-xs-600p-force {
    height: 600% !important;
  }

  .ht-xs-700 {
    height: 700px;
  }

  .mn-ht-xs-700 {
    min-height: 700px;
  }

  .ht-xs-700p {
    height: 700%;
  }

  .mx-ht-xs-700p {
    max-height: 700%;
  }

  .mn-ht-xs-700p {
    min-height: 700%;
  }

  .ht-xs-700-force {
    height: 700px !important;
  }

  .ht-xs-700p-force {
    height: 700% !important;
  }

  .ht-xs-800 {
    height: 800px;
  }

  .mn-ht-xs-800 {
    min-height: 800px;
  }

  .ht-xs-800p {
    height: 800%;
  }

  .mx-ht-xs-800p {
    max-height: 800%;
  }

  .mn-ht-xs-800p {
    min-height: 800%;
  }

  .ht-xs-800-force {
    height: 800px !important;
  }

  .ht-xs-800p-force {
    height: 800% !important;
  }

  .ht-xs-900 {
    height: 900px;
  }

  .mn-ht-xs-900 {
    min-height: 900px;
  }

  .ht-xs-900p {
    height: 900%;
  }

  .mx-ht-xs-900p {
    max-height: 900%;
  }

  .mn-ht-xs-900p {
    min-height: 900%;
  }

  .ht-xs-900-force {
    height: 900px !important;
  }

  .ht-xs-900p-force {
    height: 900% !important;
  }

  .ht-xs-1000 {
    height: 1000px;
  }

  .mn-ht-xs-1000 {
    min-height: 1000px;
  }

  .ht-xs-1000p {
    height: 1000%;
  }

  .mx-ht-xs-1000p {
    max-height: 1000%;
  }

  .mn-ht-xs-1000p {
    min-height: 1000%;
  }

  .ht-xs-1000-force {
    height: 1000px !important;
  }

  .ht-xs-1000p-force {
    height: 1000% !important;
  }

  .ht-xs-150 {
    height: 150px;
  }

  .mn-ht-xs-150 {
    min-height: 150px;
  }

  .ht-xs-150p {
    height: 150%;
  }

  .mx-ht-xs-150p {
    max-height: 150%;
  }

  .mn-ht-xs-150p {
    min-height: 150%;
  }

  .ht-xs-150-force {
    height: 150px !important;
  }

  .ht-xs-150p-force {
    height: 150% !important;
  }

  .ht-xs-200 {
    height: 200px;
  }

  .mn-ht-xs-200 {
    min-height: 200px;
  }

  .ht-xs-200p {
    height: 200%;
  }

  .mx-ht-xs-200p {
    max-height: 200%;
  }

  .mn-ht-xs-200p {
    min-height: 200%;
  }

  .ht-xs-200-force {
    height: 200px !important;
  }

  .ht-xs-200p-force {
    height: 200% !important;
  }

  .ht-xs-250 {
    height: 250px;
  }

  .mn-ht-xs-250 {
    min-height: 250px;
  }

  .ht-xs-250p {
    height: 250%;
  }

  .mx-ht-xs-250p {
    max-height: 250%;
  }

  .mn-ht-xs-250p {
    min-height: 250%;
  }

  .ht-xs-250-force {
    height: 250px !important;
  }

  .ht-xs-250p-force {
    height: 250% !important;
  }

  .ht-xs-300 {
    height: 300px;
  }

  .mn-ht-xs-300 {
    min-height: 300px;
  }

  .ht-xs-300p {
    height: 300%;
  }

  .mx-ht-xs-300p {
    max-height: 300%;
  }

  .mn-ht-xs-300p {
    min-height: 300%;
  }

  .ht-xs-300-force {
    height: 300px !important;
  }

  .ht-xs-300p-force {
    height: 300% !important;
  }

  .ht-xs-350 {
    height: 350px;
  }

  .mn-ht-xs-350 {
    min-height: 350px;
  }

  .ht-xs-350p {
    height: 350%;
  }

  .mx-ht-xs-350p {
    max-height: 350%;
  }

  .mn-ht-xs-350p {
    min-height: 350%;
  }

  .ht-xs-350-force {
    height: 350px !important;
  }

  .ht-xs-350p-force {
    height: 350% !important;
  }

  .ht-xs-400 {
    height: 400px;
  }

  .mn-ht-xs-400 {
    min-height: 400px;
  }

  .ht-xs-400p {
    height: 400%;
  }

  .mx-ht-xs-400p {
    max-height: 400%;
  }

  .mn-ht-xs-400p {
    min-height: 400%;
  }

  .ht-xs-400-force {
    height: 400px !important;
  }

  .ht-xs-400p-force {
    height: 400% !important;
  }

  .ht-xs-450 {
    height: 450px;
  }

  .mn-ht-xs-450 {
    min-height: 450px;
  }

  .ht-xs-450p {
    height: 450%;
  }

  .mx-ht-xs-450p {
    max-height: 450%;
  }

  .mn-ht-xs-450p {
    min-height: 450%;
  }

  .ht-xs-450-force {
    height: 450px !important;
  }

  .ht-xs-450p-force {
    height: 450% !important;
  }

  .ht-xs-500 {
    height: 500px;
  }

  .mn-ht-xs-500 {
    min-height: 500px;
  }

  .ht-xs-500p {
    height: 500%;
  }

  .mx-ht-xs-500p {
    max-height: 500%;
  }

  .mn-ht-xs-500p {
    min-height: 500%;
  }

  .ht-xs-500-force {
    height: 500px !important;
  }

  .ht-xs-500p-force {
    height: 500% !important;
  }

  .ht-xs-550 {
    height: 550px;
  }

  .mn-ht-xs-550 {
    min-height: 550px;
  }

  .ht-xs-550p {
    height: 550%;
  }

  .mx-ht-xs-550p {
    max-height: 550%;
  }

  .mn-ht-xs-550p {
    min-height: 550%;
  }

  .ht-xs-550-force {
    height: 550px !important;
  }

  .ht-xs-550p-force {
    height: 550% !important;
  }

  .ht-xs-600 {
    height: 600px;
  }

  .mn-ht-xs-600 {
    min-height: 600px;
  }

  .ht-xs-600p {
    height: 600%;
  }

  .mx-ht-xs-600p {
    max-height: 600%;
  }

  .mn-ht-xs-600p {
    min-height: 600%;
  }

  .ht-xs-600-force {
    height: 600px !important;
  }

  .ht-xs-600p-force {
    height: 600% !important;
  }

  .ht-xs-650 {
    height: 650px;
  }

  .mn-ht-xs-650 {
    min-height: 650px;
  }

  .ht-xs-650p {
    height: 650%;
  }

  .mx-ht-xs-650p {
    max-height: 650%;
  }

  .mn-ht-xs-650p {
    min-height: 650%;
  }

  .ht-xs-650-force {
    height: 650px !important;
  }

  .ht-xs-650p-force {
    height: 650% !important;
  }

  .ht-xs-700 {
    height: 700px;
  }

  .mn-ht-xs-700 {
    min-height: 700px;
  }

  .ht-xs-700p {
    height: 700%;
  }

  .mx-ht-xs-700p {
    max-height: 700%;
  }

  .mn-ht-xs-700p {
    min-height: 700%;
  }

  .ht-xs-700-force {
    height: 700px !important;
  }

  .ht-xs-700p-force {
    height: 700% !important;
  }

  .ht-xs-750 {
    height: 750px;
  }

  .mn-ht-xs-750 {
    min-height: 750px;
  }

  .ht-xs-750p {
    height: 750%;
  }

  .mx-ht-xs-750p {
    max-height: 750%;
  }

  .mn-ht-xs-750p {
    min-height: 750%;
  }

  .ht-xs-750-force {
    height: 750px !important;
  }

  .ht-xs-750p-force {
    height: 750% !important;
  }

  .ht-xs-800 {
    height: 800px;
  }

  .mn-ht-xs-800 {
    min-height: 800px;
  }

  .ht-xs-800p {
    height: 800%;
  }

  .mx-ht-xs-800p {
    max-height: 800%;
  }

  .mn-ht-xs-800p {
    min-height: 800%;
  }

  .ht-xs-800-force {
    height: 800px !important;
  }

  .ht-xs-800p-force {
    height: 800% !important;
  }

  .ht-xs-850 {
    height: 850px;
  }

  .mn-ht-xs-850 {
    min-height: 850px;
  }

  .ht-xs-850p {
    height: 850%;
  }

  .mx-ht-xs-850p {
    max-height: 850%;
  }

  .mn-ht-xs-850p {
    min-height: 850%;
  }

  .ht-xs-850-force {
    height: 850px !important;
  }

  .ht-xs-850p-force {
    height: 850% !important;
  }

  .ht-xs-100v {
    height: 100vh;
  }
}
@media (min-width: 576px) {
  .ht-sm-100 {
    height: 100px;
  }

  .mn-ht-sm-100 {
    min-height: 100px;
  }

  .ht-sm-100p {
    height: 100%;
  }

  .mx-ht-sm-100p {
    max-height: 100%;
  }

  .mn-ht-sm-100p {
    min-height: 100%;
  }

  .ht-sm-100-force {
    height: 100px !important;
  }

  .ht-sm-100p-force {
    height: 100% !important;
  }

  .ht-sm-200 {
    height: 200px;
  }

  .mn-ht-sm-200 {
    min-height: 200px;
  }

  .ht-sm-200p {
    height: 200%;
  }

  .mx-ht-sm-200p {
    max-height: 200%;
  }

  .mn-ht-sm-200p {
    min-height: 200%;
  }

  .ht-sm-200-force {
    height: 200px !important;
  }

  .ht-sm-200p-force {
    height: 200% !important;
  }

  .ht-sm-300 {
    height: 300px;
  }

  .mn-ht-sm-300 {
    min-height: 300px;
  }

  .ht-sm-300p {
    height: 300%;
  }

  .mx-ht-sm-300p {
    max-height: 300%;
  }

  .mn-ht-sm-300p {
    min-height: 300%;
  }

  .ht-sm-300-force {
    height: 300px !important;
  }

  .ht-sm-300p-force {
    height: 300% !important;
  }

  .ht-sm-400 {
    height: 400px;
  }

  .mn-ht-sm-400 {
    min-height: 400px;
  }

  .ht-sm-400p {
    height: 400%;
  }

  .mx-ht-sm-400p {
    max-height: 400%;
  }

  .mn-ht-sm-400p {
    min-height: 400%;
  }

  .ht-sm-400-force {
    height: 400px !important;
  }

  .ht-sm-400p-force {
    height: 400% !important;
  }

  .ht-sm-500 {
    height: 500px;
  }

  .mn-ht-sm-500 {
    min-height: 500px;
  }

  .ht-sm-500p {
    height: 500%;
  }

  .mx-ht-sm-500p {
    max-height: 500%;
  }

  .mn-ht-sm-500p {
    min-height: 500%;
  }

  .ht-sm-500-force {
    height: 500px !important;
  }

  .ht-sm-500p-force {
    height: 500% !important;
  }

  .ht-sm-600 {
    height: 600px;
  }

  .mn-ht-sm-600 {
    min-height: 600px;
  }

  .ht-sm-600p {
    height: 600%;
  }

  .mx-ht-sm-600p {
    max-height: 600%;
  }

  .mn-ht-sm-600p {
    min-height: 600%;
  }

  .ht-sm-600-force {
    height: 600px !important;
  }

  .ht-sm-600p-force {
    height: 600% !important;
  }

  .ht-sm-700 {
    height: 700px;
  }

  .mn-ht-sm-700 {
    min-height: 700px;
  }

  .ht-sm-700p {
    height: 700%;
  }

  .mx-ht-sm-700p {
    max-height: 700%;
  }

  .mn-ht-sm-700p {
    min-height: 700%;
  }

  .ht-sm-700-force {
    height: 700px !important;
  }

  .ht-sm-700p-force {
    height: 700% !important;
  }

  .ht-sm-800 {
    height: 800px;
  }

  .mn-ht-sm-800 {
    min-height: 800px;
  }

  .ht-sm-800p {
    height: 800%;
  }

  .mx-ht-sm-800p {
    max-height: 800%;
  }

  .mn-ht-sm-800p {
    min-height: 800%;
  }

  .ht-sm-800-force {
    height: 800px !important;
  }

  .ht-sm-800p-force {
    height: 800% !important;
  }

  .ht-sm-900 {
    height: 900px;
  }

  .mn-ht-sm-900 {
    min-height: 900px;
  }

  .ht-sm-900p {
    height: 900%;
  }

  .mx-ht-sm-900p {
    max-height: 900%;
  }

  .mn-ht-sm-900p {
    min-height: 900%;
  }

  .ht-sm-900-force {
    height: 900px !important;
  }

  .ht-sm-900p-force {
    height: 900% !important;
  }

  .ht-sm-1000 {
    height: 1000px;
  }

  .mn-ht-sm-1000 {
    min-height: 1000px;
  }

  .ht-sm-1000p {
    height: 1000%;
  }

  .mx-ht-sm-1000p {
    max-height: 1000%;
  }

  .mn-ht-sm-1000p {
    min-height: 1000%;
  }

  .ht-sm-1000-force {
    height: 1000px !important;
  }

  .ht-sm-1000p-force {
    height: 1000% !important;
  }

  .ht-sm-150 {
    height: 150px;
  }

  .mn-ht-sm-150 {
    min-height: 150px;
  }

  .ht-sm-150p {
    height: 150%;
  }

  .mx-ht-sm-150p {
    max-height: 150%;
  }

  .mn-ht-sm-150p {
    min-height: 150%;
  }

  .ht-sm-150-force {
    height: 150px !important;
  }

  .ht-sm-150p-force {
    height: 150% !important;
  }

  .ht-sm-200 {
    height: 200px;
  }

  .mn-ht-sm-200 {
    min-height: 200px;
  }

  .ht-sm-200p {
    height: 200%;
  }

  .mx-ht-sm-200p {
    max-height: 200%;
  }

  .mn-ht-sm-200p {
    min-height: 200%;
  }

  .ht-sm-200-force {
    height: 200px !important;
  }

  .ht-sm-200p-force {
    height: 200% !important;
  }

  .ht-sm-250 {
    height: 250px;
  }

  .mn-ht-sm-250 {
    min-height: 250px;
  }

  .ht-sm-250p {
    height: 250%;
  }

  .mx-ht-sm-250p {
    max-height: 250%;
  }

  .mn-ht-sm-250p {
    min-height: 250%;
  }

  .ht-sm-250-force {
    height: 250px !important;
  }

  .ht-sm-250p-force {
    height: 250% !important;
  }

  .ht-sm-300 {
    height: 300px;
  }

  .mn-ht-sm-300 {
    min-height: 300px;
  }

  .ht-sm-300p {
    height: 300%;
  }

  .mx-ht-sm-300p {
    max-height: 300%;
  }

  .mn-ht-sm-300p {
    min-height: 300%;
  }

  .ht-sm-300-force {
    height: 300px !important;
  }

  .ht-sm-300p-force {
    height: 300% !important;
  }

  .ht-sm-350 {
    height: 350px;
  }

  .mn-ht-sm-350 {
    min-height: 350px;
  }

  .ht-sm-350p {
    height: 350%;
  }

  .mx-ht-sm-350p {
    max-height: 350%;
  }

  .mn-ht-sm-350p {
    min-height: 350%;
  }

  .ht-sm-350-force {
    height: 350px !important;
  }

  .ht-sm-350p-force {
    height: 350% !important;
  }

  .ht-sm-400 {
    height: 400px;
  }

  .mn-ht-sm-400 {
    min-height: 400px;
  }

  .ht-sm-400p {
    height: 400%;
  }

  .mx-ht-sm-400p {
    max-height: 400%;
  }

  .mn-ht-sm-400p {
    min-height: 400%;
  }

  .ht-sm-400-force {
    height: 400px !important;
  }

  .ht-sm-400p-force {
    height: 400% !important;
  }

  .ht-sm-450 {
    height: 450px;
  }

  .mn-ht-sm-450 {
    min-height: 450px;
  }

  .ht-sm-450p {
    height: 450%;
  }

  .mx-ht-sm-450p {
    max-height: 450%;
  }

  .mn-ht-sm-450p {
    min-height: 450%;
  }

  .ht-sm-450-force {
    height: 450px !important;
  }

  .ht-sm-450p-force {
    height: 450% !important;
  }

  .ht-sm-500 {
    height: 500px;
  }

  .mn-ht-sm-500 {
    min-height: 500px;
  }

  .ht-sm-500p {
    height: 500%;
  }

  .mx-ht-sm-500p {
    max-height: 500%;
  }

  .mn-ht-sm-500p {
    min-height: 500%;
  }

  .ht-sm-500-force {
    height: 500px !important;
  }

  .ht-sm-500p-force {
    height: 500% !important;
  }

  .ht-sm-550 {
    height: 550px;
  }

  .mn-ht-sm-550 {
    min-height: 550px;
  }

  .ht-sm-550p {
    height: 550%;
  }

  .mx-ht-sm-550p {
    max-height: 550%;
  }

  .mn-ht-sm-550p {
    min-height: 550%;
  }

  .ht-sm-550-force {
    height: 550px !important;
  }

  .ht-sm-550p-force {
    height: 550% !important;
  }

  .ht-sm-600 {
    height: 600px;
  }

  .mn-ht-sm-600 {
    min-height: 600px;
  }

  .ht-sm-600p {
    height: 600%;
  }

  .mx-ht-sm-600p {
    max-height: 600%;
  }

  .mn-ht-sm-600p {
    min-height: 600%;
  }

  .ht-sm-600-force {
    height: 600px !important;
  }

  .ht-sm-600p-force {
    height: 600% !important;
  }

  .ht-sm-650 {
    height: 650px;
  }

  .mn-ht-sm-650 {
    min-height: 650px;
  }

  .ht-sm-650p {
    height: 650%;
  }

  .mx-ht-sm-650p {
    max-height: 650%;
  }

  .mn-ht-sm-650p {
    min-height: 650%;
  }

  .ht-sm-650-force {
    height: 650px !important;
  }

  .ht-sm-650p-force {
    height: 650% !important;
  }

  .ht-sm-700 {
    height: 700px;
  }

  .mn-ht-sm-700 {
    min-height: 700px;
  }

  .ht-sm-700p {
    height: 700%;
  }

  .mx-ht-sm-700p {
    max-height: 700%;
  }

  .mn-ht-sm-700p {
    min-height: 700%;
  }

  .ht-sm-700-force {
    height: 700px !important;
  }

  .ht-sm-700p-force {
    height: 700% !important;
  }

  .ht-sm-750 {
    height: 750px;
  }

  .mn-ht-sm-750 {
    min-height: 750px;
  }

  .ht-sm-750p {
    height: 750%;
  }

  .mx-ht-sm-750p {
    max-height: 750%;
  }

  .mn-ht-sm-750p {
    min-height: 750%;
  }

  .ht-sm-750-force {
    height: 750px !important;
  }

  .ht-sm-750p-force {
    height: 750% !important;
  }

  .ht-sm-800 {
    height: 800px;
  }

  .mn-ht-sm-800 {
    min-height: 800px;
  }

  .ht-sm-800p {
    height: 800%;
  }

  .mx-ht-sm-800p {
    max-height: 800%;
  }

  .mn-ht-sm-800p {
    min-height: 800%;
  }

  .ht-sm-800-force {
    height: 800px !important;
  }

  .ht-sm-800p-force {
    height: 800% !important;
  }

  .ht-sm-850 {
    height: 850px;
  }

  .mn-ht-sm-850 {
    min-height: 850px;
  }

  .ht-sm-850p {
    height: 850%;
  }

  .mx-ht-sm-850p {
    max-height: 850%;
  }

  .mn-ht-sm-850p {
    min-height: 850%;
  }

  .ht-sm-850-force {
    height: 850px !important;
  }

  .ht-sm-850p-force {
    height: 850% !important;
  }

  .ht-sm-100v {
    height: 100vh;
  }
}
@media (min-width: 768px) {
  .ht-md-100 {
    height: 100px;
  }

  .mn-ht-md-100 {
    min-height: 100px;
  }

  .ht-md-100p {
    height: 100%;
  }

  .mx-ht-md-100p {
    max-height: 100%;
  }

  .mn-ht-md-100p {
    min-height: 100%;
  }

  .ht-md-100-force {
    height: 100px !important;
  }

  .ht-md-100p-force {
    height: 100% !important;
  }

  .ht-md-200 {
    height: 200px;
  }

  .mn-ht-md-200 {
    min-height: 200px;
  }

  .ht-md-200p {
    height: 200%;
  }

  .mx-ht-md-200p {
    max-height: 200%;
  }

  .mn-ht-md-200p {
    min-height: 200%;
  }

  .ht-md-200-force {
    height: 200px !important;
  }

  .ht-md-200p-force {
    height: 200% !important;
  }

  .ht-md-300 {
    height: 300px;
  }

  .mn-ht-md-300 {
    min-height: 300px;
  }

  .ht-md-300p {
    height: 300%;
  }

  .mx-ht-md-300p {
    max-height: 300%;
  }

  .mn-ht-md-300p {
    min-height: 300%;
  }

  .ht-md-300-force {
    height: 300px !important;
  }

  .ht-md-300p-force {
    height: 300% !important;
  }

  .ht-md-400 {
    height: 400px;
  }

  .mn-ht-md-400 {
    min-height: 400px;
  }

  .ht-md-400p {
    height: 400%;
  }

  .mx-ht-md-400p {
    max-height: 400%;
  }

  .mn-ht-md-400p {
    min-height: 400%;
  }

  .ht-md-400-force {
    height: 400px !important;
  }

  .ht-md-400p-force {
    height: 400% !important;
  }

  .ht-md-500 {
    height: 500px;
  }

  .mn-ht-md-500 {
    min-height: 500px;
  }

  .ht-md-500p {
    height: 500%;
  }

  .mx-ht-md-500p {
    max-height: 500%;
  }

  .mn-ht-md-500p {
    min-height: 500%;
  }

  .ht-md-500-force {
    height: 500px !important;
  }

  .ht-md-500p-force {
    height: 500% !important;
  }

  .ht-md-600 {
    height: 600px;
  }

  .mn-ht-md-600 {
    min-height: 600px;
  }

  .ht-md-600p {
    height: 600%;
  }

  .mx-ht-md-600p {
    max-height: 600%;
  }

  .mn-ht-md-600p {
    min-height: 600%;
  }

  .ht-md-600-force {
    height: 600px !important;
  }

  .ht-md-600p-force {
    height: 600% !important;
  }

  .ht-md-700 {
    height: 700px;
  }

  .mn-ht-md-700 {
    min-height: 700px;
  }

  .ht-md-700p {
    height: 700%;
  }

  .mx-ht-md-700p {
    max-height: 700%;
  }

  .mn-ht-md-700p {
    min-height: 700%;
  }

  .ht-md-700-force {
    height: 700px !important;
  }

  .ht-md-700p-force {
    height: 700% !important;
  }

  .ht-md-800 {
    height: 800px;
  }

  .mn-ht-md-800 {
    min-height: 800px;
  }

  .ht-md-800p {
    height: 800%;
  }

  .mx-ht-md-800p {
    max-height: 800%;
  }

  .mn-ht-md-800p {
    min-height: 800%;
  }

  .ht-md-800-force {
    height: 800px !important;
  }

  .ht-md-800p-force {
    height: 800% !important;
  }

  .ht-md-900 {
    height: 900px;
  }

  .mn-ht-md-900 {
    min-height: 900px;
  }

  .ht-md-900p {
    height: 900%;
  }

  .mx-ht-md-900p {
    max-height: 900%;
  }

  .mn-ht-md-900p {
    min-height: 900%;
  }

  .ht-md-900-force {
    height: 900px !important;
  }

  .ht-md-900p-force {
    height: 900% !important;
  }

  .ht-md-1000 {
    height: 1000px;
  }

  .mn-ht-md-1000 {
    min-height: 1000px;
  }

  .ht-md-1000p {
    height: 1000%;
  }

  .mx-ht-md-1000p {
    max-height: 1000%;
  }

  .mn-ht-md-1000p {
    min-height: 1000%;
  }

  .ht-md-1000-force {
    height: 1000px !important;
  }

  .ht-md-1000p-force {
    height: 1000% !important;
  }

  .ht-md-150 {
    height: 150px;
  }

  .mn-ht-md-150 {
    min-height: 150px;
  }

  .ht-md-150p {
    height: 150%;
  }

  .mx-ht-md-150p {
    max-height: 150%;
  }

  .mn-ht-md-150p {
    min-height: 150%;
  }

  .ht-md-150-force {
    height: 150px !important;
  }

  .ht-md-150p-force {
    height: 150% !important;
  }

  .ht-md-200 {
    height: 200px;
  }

  .mn-ht-md-200 {
    min-height: 200px;
  }

  .ht-md-200p {
    height: 200%;
  }

  .mx-ht-md-200p {
    max-height: 200%;
  }

  .mn-ht-md-200p {
    min-height: 200%;
  }

  .ht-md-200-force {
    height: 200px !important;
  }

  .ht-md-200p-force {
    height: 200% !important;
  }

  .ht-md-250 {
    height: 250px;
  }

  .mn-ht-md-250 {
    min-height: 250px;
  }

  .ht-md-250p {
    height: 250%;
  }

  .mx-ht-md-250p {
    max-height: 250%;
  }

  .mn-ht-md-250p {
    min-height: 250%;
  }

  .ht-md-250-force {
    height: 250px !important;
  }

  .ht-md-250p-force {
    height: 250% !important;
  }

  .ht-md-300 {
    height: 300px;
  }

  .mn-ht-md-300 {
    min-height: 300px;
  }

  .ht-md-300p {
    height: 300%;
  }

  .mx-ht-md-300p {
    max-height: 300%;
  }

  .mn-ht-md-300p {
    min-height: 300%;
  }

  .ht-md-300-force {
    height: 300px !important;
  }

  .ht-md-300p-force {
    height: 300% !important;
  }

  .ht-md-350 {
    height: 350px;
  }

  .mn-ht-md-350 {
    min-height: 350px;
  }

  .ht-md-350p {
    height: 350%;
  }

  .mx-ht-md-350p {
    max-height: 350%;
  }

  .mn-ht-md-350p {
    min-height: 350%;
  }

  .ht-md-350-force {
    height: 350px !important;
  }

  .ht-md-350p-force {
    height: 350% !important;
  }

  .ht-md-400 {
    height: 400px;
  }

  .mn-ht-md-400 {
    min-height: 400px;
  }

  .ht-md-400p {
    height: 400%;
  }

  .mx-ht-md-400p {
    max-height: 400%;
  }

  .mn-ht-md-400p {
    min-height: 400%;
  }

  .ht-md-400-force {
    height: 400px !important;
  }

  .ht-md-400p-force {
    height: 400% !important;
  }

  .ht-md-450 {
    height: 450px;
  }

  .mn-ht-md-450 {
    min-height: 450px;
  }

  .ht-md-450p {
    height: 450%;
  }

  .mx-ht-md-450p {
    max-height: 450%;
  }

  .mn-ht-md-450p {
    min-height: 450%;
  }

  .ht-md-450-force {
    height: 450px !important;
  }

  .ht-md-450p-force {
    height: 450% !important;
  }

  .ht-md-500 {
    height: 500px;
  }

  .mn-ht-md-500 {
    min-height: 500px;
  }

  .ht-md-500p {
    height: 500%;
  }

  .mx-ht-md-500p {
    max-height: 500%;
  }

  .mn-ht-md-500p {
    min-height: 500%;
  }

  .ht-md-500-force {
    height: 500px !important;
  }

  .ht-md-500p-force {
    height: 500% !important;
  }

  .ht-md-550 {
    height: 550px;
  }

  .mn-ht-md-550 {
    min-height: 550px;
  }

  .ht-md-550p {
    height: 550%;
  }

  .mx-ht-md-550p {
    max-height: 550%;
  }

  .mn-ht-md-550p {
    min-height: 550%;
  }

  .ht-md-550-force {
    height: 550px !important;
  }

  .ht-md-550p-force {
    height: 550% !important;
  }

  .ht-md-600 {
    height: 600px;
  }

  .mn-ht-md-600 {
    min-height: 600px;
  }

  .ht-md-600p {
    height: 600%;
  }

  .mx-ht-md-600p {
    max-height: 600%;
  }

  .mn-ht-md-600p {
    min-height: 600%;
  }

  .ht-md-600-force {
    height: 600px !important;
  }

  .ht-md-600p-force {
    height: 600% !important;
  }

  .ht-md-650 {
    height: 650px;
  }

  .mn-ht-md-650 {
    min-height: 650px;
  }

  .ht-md-650p {
    height: 650%;
  }

  .mx-ht-md-650p {
    max-height: 650%;
  }

  .mn-ht-md-650p {
    min-height: 650%;
  }

  .ht-md-650-force {
    height: 650px !important;
  }

  .ht-md-650p-force {
    height: 650% !important;
  }

  .ht-md-700 {
    height: 700px;
  }

  .mn-ht-md-700 {
    min-height: 700px;
  }

  .ht-md-700p {
    height: 700%;
  }

  .mx-ht-md-700p {
    max-height: 700%;
  }

  .mn-ht-md-700p {
    min-height: 700%;
  }

  .ht-md-700-force {
    height: 700px !important;
  }

  .ht-md-700p-force {
    height: 700% !important;
  }

  .ht-md-750 {
    height: 750px;
  }

  .mn-ht-md-750 {
    min-height: 750px;
  }

  .ht-md-750p {
    height: 750%;
  }

  .mx-ht-md-750p {
    max-height: 750%;
  }

  .mn-ht-md-750p {
    min-height: 750%;
  }

  .ht-md-750-force {
    height: 750px !important;
  }

  .ht-md-750p-force {
    height: 750% !important;
  }

  .ht-md-800 {
    height: 800px;
  }

  .mn-ht-md-800 {
    min-height: 800px;
  }

  .ht-md-800p {
    height: 800%;
  }

  .mx-ht-md-800p {
    max-height: 800%;
  }

  .mn-ht-md-800p {
    min-height: 800%;
  }

  .ht-md-800-force {
    height: 800px !important;
  }

  .ht-md-800p-force {
    height: 800% !important;
  }

  .ht-md-850 {
    height: 850px;
  }

  .mn-ht-md-850 {
    min-height: 850px;
  }

  .ht-md-850p {
    height: 850%;
  }

  .mx-ht-md-850p {
    max-height: 850%;
  }

  .mn-ht-md-850p {
    min-height: 850%;
  }

  .ht-md-850-force {
    height: 850px !important;
  }

  .ht-md-850p-force {
    height: 850% !important;
  }

  .ht-md-100v {
    height: 100vh;
  }
}
@media (min-width: 992px) {
  .ht-lg-100 {
    height: 100px;
  }

  .mn-ht-lg-100 {
    min-height: 100px;
  }

  .ht-lg-100p {
    height: 100%;
  }

  .mx-ht-lg-100p {
    max-height: 100%;
  }

  .mn-ht-lg-100p {
    min-height: 100%;
  }

  .ht-lg-100-force {
    height: 100px !important;
  }

  .ht-lg-100p-force {
    height: 100% !important;
  }

  .ht-lg-200 {
    height: 200px;
  }

  .mn-ht-lg-200 {
    min-height: 200px;
  }

  .ht-lg-200p {
    height: 200%;
  }

  .mx-ht-lg-200p {
    max-height: 200%;
  }

  .mn-ht-lg-200p {
    min-height: 200%;
  }

  .ht-lg-200-force {
    height: 200px !important;
  }

  .ht-lg-200p-force {
    height: 200% !important;
  }

  .ht-lg-300 {
    height: 300px;
  }

  .mn-ht-lg-300 {
    min-height: 300px;
  }

  .ht-lg-300p {
    height: 300%;
  }

  .mx-ht-lg-300p {
    max-height: 300%;
  }

  .mn-ht-lg-300p {
    min-height: 300%;
  }

  .ht-lg-300-force {
    height: 300px !important;
  }

  .ht-lg-300p-force {
    height: 300% !important;
  }

  .ht-lg-400 {
    height: 400px;
  }

  .mn-ht-lg-400 {
    min-height: 400px;
  }

  .ht-lg-400p {
    height: 400%;
  }

  .mx-ht-lg-400p {
    max-height: 400%;
  }

  .mn-ht-lg-400p {
    min-height: 400%;
  }

  .ht-lg-400-force {
    height: 400px !important;
  }

  .ht-lg-400p-force {
    height: 400% !important;
  }

  .ht-lg-500 {
    height: 500px;
  }

  .mn-ht-lg-500 {
    min-height: 500px;
  }

  .ht-lg-500p {
    height: 500%;
  }

  .mx-ht-lg-500p {
    max-height: 500%;
  }

  .mn-ht-lg-500p {
    min-height: 500%;
  }

  .ht-lg-500-force {
    height: 500px !important;
  }

  .ht-lg-500p-force {
    height: 500% !important;
  }

  .ht-lg-600 {
    height: 600px;
  }

  .mn-ht-lg-600 {
    min-height: 600px;
  }

  .ht-lg-600p {
    height: 600%;
  }

  .mx-ht-lg-600p {
    max-height: 600%;
  }

  .mn-ht-lg-600p {
    min-height: 600%;
  }

  .ht-lg-600-force {
    height: 600px !important;
  }

  .ht-lg-600p-force {
    height: 600% !important;
  }

  .ht-lg-700 {
    height: 700px;
  }

  .mn-ht-lg-700 {
    min-height: 700px;
  }

  .ht-lg-700p {
    height: 700%;
  }

  .mx-ht-lg-700p {
    max-height: 700%;
  }

  .mn-ht-lg-700p {
    min-height: 700%;
  }

  .ht-lg-700-force {
    height: 700px !important;
  }

  .ht-lg-700p-force {
    height: 700% !important;
  }

  .ht-lg-800 {
    height: 800px;
  }

  .mn-ht-lg-800 {
    min-height: 800px;
  }

  .ht-lg-800p {
    height: 800%;
  }

  .mx-ht-lg-800p {
    max-height: 800%;
  }

  .mn-ht-lg-800p {
    min-height: 800%;
  }

  .ht-lg-800-force {
    height: 800px !important;
  }

  .ht-lg-800p-force {
    height: 800% !important;
  }

  .ht-lg-900 {
    height: 900px;
  }

  .mn-ht-lg-900 {
    min-height: 900px;
  }

  .ht-lg-900p {
    height: 900%;
  }

  .mx-ht-lg-900p {
    max-height: 900%;
  }

  .mn-ht-lg-900p {
    min-height: 900%;
  }

  .ht-lg-900-force {
    height: 900px !important;
  }

  .ht-lg-900p-force {
    height: 900% !important;
  }

  .ht-lg-1000 {
    height: 1000px;
  }

  .mn-ht-lg-1000 {
    min-height: 1000px;
  }

  .ht-lg-1000p {
    height: 1000%;
  }

  .mx-ht-lg-1000p {
    max-height: 1000%;
  }

  .mn-ht-lg-1000p {
    min-height: 1000%;
  }

  .ht-lg-1000-force {
    height: 1000px !important;
  }

  .ht-lg-1000p-force {
    height: 1000% !important;
  }

  .ht-lg-150 {
    height: 150px;
  }

  .mn-ht-lg-150 {
    min-height: 150px;
  }

  .ht-lg-150p {
    height: 150%;
  }

  .mx-ht-lg-150p {
    max-height: 150%;
  }

  .mn-ht-lg-150p {
    min-height: 150%;
  }

  .ht-lg-150-force {
    height: 150px !important;
  }

  .ht-lg-150p-force {
    height: 150% !important;
  }

  .ht-lg-200 {
    height: 200px;
  }

  .mn-ht-lg-200 {
    min-height: 200px;
  }

  .ht-lg-200p {
    height: 200%;
  }

  .mx-ht-lg-200p {
    max-height: 200%;
  }

  .mn-ht-lg-200p {
    min-height: 200%;
  }

  .ht-lg-200-force {
    height: 200px !important;
  }

  .ht-lg-200p-force {
    height: 200% !important;
  }

  .ht-lg-250 {
    height: 250px;
  }

  .mn-ht-lg-250 {
    min-height: 250px;
  }

  .ht-lg-250p {
    height: 250%;
  }

  .mx-ht-lg-250p {
    max-height: 250%;
  }

  .mn-ht-lg-250p {
    min-height: 250%;
  }

  .ht-lg-250-force {
    height: 250px !important;
  }

  .ht-lg-250p-force {
    height: 250% !important;
  }

  .ht-lg-300 {
    height: 300px;
  }

  .mn-ht-lg-300 {
    min-height: 300px;
  }

  .ht-lg-300p {
    height: 300%;
  }

  .mx-ht-lg-300p {
    max-height: 300%;
  }

  .mn-ht-lg-300p {
    min-height: 300%;
  }

  .ht-lg-300-force {
    height: 300px !important;
  }

  .ht-lg-300p-force {
    height: 300% !important;
  }

  .ht-lg-350 {
    height: 350px;
  }

  .mn-ht-lg-350 {
    min-height: 350px;
  }

  .ht-lg-350p {
    height: 350%;
  }

  .mx-ht-lg-350p {
    max-height: 350%;
  }

  .mn-ht-lg-350p {
    min-height: 350%;
  }

  .ht-lg-350-force {
    height: 350px !important;
  }

  .ht-lg-350p-force {
    height: 350% !important;
  }

  .ht-lg-400 {
    height: 400px;
  }

  .mn-ht-lg-400 {
    min-height: 400px;
  }

  .ht-lg-400p {
    height: 400%;
  }

  .mx-ht-lg-400p {
    max-height: 400%;
  }

  .mn-ht-lg-400p {
    min-height: 400%;
  }

  .ht-lg-400-force {
    height: 400px !important;
  }

  .ht-lg-400p-force {
    height: 400% !important;
  }

  .ht-lg-450 {
    height: 450px;
  }

  .mn-ht-lg-450 {
    min-height: 450px;
  }

  .ht-lg-450p {
    height: 450%;
  }

  .mx-ht-lg-450p {
    max-height: 450%;
  }

  .mn-ht-lg-450p {
    min-height: 450%;
  }

  .ht-lg-450-force {
    height: 450px !important;
  }

  .ht-lg-450p-force {
    height: 450% !important;
  }

  .ht-lg-500 {
    height: 500px;
  }

  .mn-ht-lg-500 {
    min-height: 500px;
  }

  .ht-lg-500p {
    height: 500%;
  }

  .mx-ht-lg-500p {
    max-height: 500%;
  }

  .mn-ht-lg-500p {
    min-height: 500%;
  }

  .ht-lg-500-force {
    height: 500px !important;
  }

  .ht-lg-500p-force {
    height: 500% !important;
  }

  .ht-lg-550 {
    height: 550px;
  }

  .mn-ht-lg-550 {
    min-height: 550px;
  }

  .ht-lg-550p {
    height: 550%;
  }

  .mx-ht-lg-550p {
    max-height: 550%;
  }

  .mn-ht-lg-550p {
    min-height: 550%;
  }

  .ht-lg-550-force {
    height: 550px !important;
  }

  .ht-lg-550p-force {
    height: 550% !important;
  }

  .ht-lg-600 {
    height: 600px;
  }

  .mn-ht-lg-600 {
    min-height: 600px;
  }

  .ht-lg-600p {
    height: 600%;
  }

  .mx-ht-lg-600p {
    max-height: 600%;
  }

  .mn-ht-lg-600p {
    min-height: 600%;
  }

  .ht-lg-600-force {
    height: 600px !important;
  }

  .ht-lg-600p-force {
    height: 600% !important;
  }

  .ht-lg-650 {
    height: 650px;
  }

  .mn-ht-lg-650 {
    min-height: 650px;
  }

  .ht-lg-650p {
    height: 650%;
  }

  .mx-ht-lg-650p {
    max-height: 650%;
  }

  .mn-ht-lg-650p {
    min-height: 650%;
  }

  .ht-lg-650-force {
    height: 650px !important;
  }

  .ht-lg-650p-force {
    height: 650% !important;
  }

  .ht-lg-700 {
    height: 700px;
  }

  .mn-ht-lg-700 {
    min-height: 700px;
  }

  .ht-lg-700p {
    height: 700%;
  }

  .mx-ht-lg-700p {
    max-height: 700%;
  }

  .mn-ht-lg-700p {
    min-height: 700%;
  }

  .ht-lg-700-force {
    height: 700px !important;
  }

  .ht-lg-700p-force {
    height: 700% !important;
  }

  .ht-lg-750 {
    height: 750px;
  }

  .mn-ht-lg-750 {
    min-height: 750px;
  }

  .ht-lg-750p {
    height: 750%;
  }

  .mx-ht-lg-750p {
    max-height: 750%;
  }

  .mn-ht-lg-750p {
    min-height: 750%;
  }

  .ht-lg-750-force {
    height: 750px !important;
  }

  .ht-lg-750p-force {
    height: 750% !important;
  }

  .ht-lg-800 {
    height: 800px;
  }

  .mn-ht-lg-800 {
    min-height: 800px;
  }

  .ht-lg-800p {
    height: 800%;
  }

  .mx-ht-lg-800p {
    max-height: 800%;
  }

  .mn-ht-lg-800p {
    min-height: 800%;
  }

  .ht-lg-800-force {
    height: 800px !important;
  }

  .ht-lg-800p-force {
    height: 800% !important;
  }

  .ht-lg-850 {
    height: 850px;
  }

  .mn-ht-lg-850 {
    min-height: 850px;
  }

  .ht-lg-850p {
    height: 850%;
  }

  .mx-ht-lg-850p {
    max-height: 850%;
  }

  .mn-ht-lg-850p {
    min-height: 850%;
  }

  .ht-lg-850-force {
    height: 850px !important;
  }

  .ht-lg-850p-force {
    height: 850% !important;
  }

  .ht-lg-100v {
    height: 100vh;
  }
}
@media (min-width: 1200px) {
  .ht-xl-100 {
    height: 100px;
  }

  .mn-ht-xl-100 {
    min-height: 100px;
  }

  .ht-xl-100p {
    height: 100%;
  }

  .mx-ht-xl-100p {
    max-height: 100%;
  }

  .mn-ht-xl-100p {
    min-height: 100%;
  }

  .ht-xl-100-force {
    height: 100px !important;
  }

  .ht-xl-100p-force {
    height: 100% !important;
  }

  .ht-xl-200 {
    height: 200px;
  }

  .mn-ht-xl-200 {
    min-height: 200px;
  }

  .ht-xl-200p {
    height: 200%;
  }

  .mx-ht-xl-200p {
    max-height: 200%;
  }

  .mn-ht-xl-200p {
    min-height: 200%;
  }

  .ht-xl-200-force {
    height: 200px !important;
  }

  .ht-xl-200p-force {
    height: 200% !important;
  }

  .ht-xl-300 {
    height: 300px;
  }

  .mn-ht-xl-300 {
    min-height: 300px;
  }

  .ht-xl-300p {
    height: 300%;
  }

  .mx-ht-xl-300p {
    max-height: 300%;
  }

  .mn-ht-xl-300p {
    min-height: 300%;
  }

  .ht-xl-300-force {
    height: 300px !important;
  }

  .ht-xl-300p-force {
    height: 300% !important;
  }

  .ht-xl-400 {
    height: 400px;
  }

  .mn-ht-xl-400 {
    min-height: 400px;
  }

  .ht-xl-400p {
    height: 400%;
  }

  .mx-ht-xl-400p {
    max-height: 400%;
  }

  .mn-ht-xl-400p {
    min-height: 400%;
  }

  .ht-xl-400-force {
    height: 400px !important;
  }

  .ht-xl-400p-force {
    height: 400% !important;
  }

  .ht-xl-500 {
    height: 500px;
  }

  .mn-ht-xl-500 {
    min-height: 500px;
  }

  .ht-xl-500p {
    height: 500%;
  }

  .mx-ht-xl-500p {
    max-height: 500%;
  }

  .mn-ht-xl-500p {
    min-height: 500%;
  }

  .ht-xl-500-force {
    height: 500px !important;
  }

  .ht-xl-500p-force {
    height: 500% !important;
  }

  .ht-xl-600 {
    height: 600px;
  }

  .mn-ht-xl-600 {
    min-height: 600px;
  }

  .ht-xl-600p {
    height: 600%;
  }

  .mx-ht-xl-600p {
    max-height: 600%;
  }

  .mn-ht-xl-600p {
    min-height: 600%;
  }

  .ht-xl-600-force {
    height: 600px !important;
  }

  .ht-xl-600p-force {
    height: 600% !important;
  }

  .ht-xl-700 {
    height: 700px;
  }

  .mn-ht-xl-700 {
    min-height: 700px;
  }

  .ht-xl-700p {
    height: 700%;
  }

  .mx-ht-xl-700p {
    max-height: 700%;
  }

  .mn-ht-xl-700p {
    min-height: 700%;
  }

  .ht-xl-700-force {
    height: 700px !important;
  }

  .ht-xl-700p-force {
    height: 700% !important;
  }

  .ht-xl-800 {
    height: 800px;
  }

  .mn-ht-xl-800 {
    min-height: 800px;
  }

  .ht-xl-800p {
    height: 800%;
  }

  .mx-ht-xl-800p {
    max-height: 800%;
  }

  .mn-ht-xl-800p {
    min-height: 800%;
  }

  .ht-xl-800-force {
    height: 800px !important;
  }

  .ht-xl-800p-force {
    height: 800% !important;
  }

  .ht-xl-900 {
    height: 900px;
  }

  .mn-ht-xl-900 {
    min-height: 900px;
  }

  .ht-xl-900p {
    height: 900%;
  }

  .mx-ht-xl-900p {
    max-height: 900%;
  }

  .mn-ht-xl-900p {
    min-height: 900%;
  }

  .ht-xl-900-force {
    height: 900px !important;
  }

  .ht-xl-900p-force {
    height: 900% !important;
  }

  .ht-xl-1000 {
    height: 1000px;
  }

  .mn-ht-xl-1000 {
    min-height: 1000px;
  }

  .ht-xl-1000p {
    height: 1000%;
  }

  .mx-ht-xl-1000p {
    max-height: 1000%;
  }

  .mn-ht-xl-1000p {
    min-height: 1000%;
  }

  .ht-xl-1000-force {
    height: 1000px !important;
  }

  .ht-xl-1000p-force {
    height: 1000% !important;
  }

  .ht-xl-150 {
    height: 150px;
  }

  .mn-ht-xl-150 {
    min-height: 150px;
  }

  .ht-xl-150p {
    height: 150%;
  }

  .mx-ht-xl-150p {
    max-height: 150%;
  }

  .mn-ht-xl-150p {
    min-height: 150%;
  }

  .ht-xl-150-force {
    height: 150px !important;
  }

  .ht-xl-150p-force {
    height: 150% !important;
  }

  .ht-xl-200 {
    height: 200px;
  }

  .mn-ht-xl-200 {
    min-height: 200px;
  }

  .ht-xl-200p {
    height: 200%;
  }

  .mx-ht-xl-200p {
    max-height: 200%;
  }

  .mn-ht-xl-200p {
    min-height: 200%;
  }

  .ht-xl-200-force {
    height: 200px !important;
  }

  .ht-xl-200p-force {
    height: 200% !important;
  }

  .ht-xl-250 {
    height: 250px;
  }

  .mn-ht-xl-250 {
    min-height: 250px;
  }

  .ht-xl-250p {
    height: 250%;
  }

  .mx-ht-xl-250p {
    max-height: 250%;
  }

  .mn-ht-xl-250p {
    min-height: 250%;
  }

  .ht-xl-250-force {
    height: 250px !important;
  }

  .ht-xl-250p-force {
    height: 250% !important;
  }

  .ht-xl-300 {
    height: 300px;
  }

  .mn-ht-xl-300 {
    min-height: 300px;
  }

  .ht-xl-300p {
    height: 300%;
  }

  .mx-ht-xl-300p {
    max-height: 300%;
  }

  .mn-ht-xl-300p {
    min-height: 300%;
  }

  .ht-xl-300-force {
    height: 300px !important;
  }

  .ht-xl-300p-force {
    height: 300% !important;
  }

  .ht-xl-350 {
    height: 350px;
  }

  .mn-ht-xl-350 {
    min-height: 350px;
  }

  .ht-xl-350p {
    height: 350%;
  }

  .mx-ht-xl-350p {
    max-height: 350%;
  }

  .mn-ht-xl-350p {
    min-height: 350%;
  }

  .ht-xl-350-force {
    height: 350px !important;
  }

  .ht-xl-350p-force {
    height: 350% !important;
  }

  .ht-xl-400 {
    height: 400px;
  }

  .mn-ht-xl-400 {
    min-height: 400px;
  }

  .ht-xl-400p {
    height: 400%;
  }

  .mx-ht-xl-400p {
    max-height: 400%;
  }

  .mn-ht-xl-400p {
    min-height: 400%;
  }

  .ht-xl-400-force {
    height: 400px !important;
  }

  .ht-xl-400p-force {
    height: 400% !important;
  }

  .ht-xl-450 {
    height: 450px;
  }

  .mn-ht-xl-450 {
    min-height: 450px;
  }

  .ht-xl-450p {
    height: 450%;
  }

  .mx-ht-xl-450p {
    max-height: 450%;
  }

  .mn-ht-xl-450p {
    min-height: 450%;
  }

  .ht-xl-450-force {
    height: 450px !important;
  }

  .ht-xl-450p-force {
    height: 450% !important;
  }

  .ht-xl-500 {
    height: 500px;
  }

  .mn-ht-xl-500 {
    min-height: 500px;
  }

  .ht-xl-500p {
    height: 500%;
  }

  .mx-ht-xl-500p {
    max-height: 500%;
  }

  .mn-ht-xl-500p {
    min-height: 500%;
  }

  .ht-xl-500-force {
    height: 500px !important;
  }

  .ht-xl-500p-force {
    height: 500% !important;
  }

  .ht-xl-550 {
    height: 550px;
  }

  .mn-ht-xl-550 {
    min-height: 550px;
  }

  .ht-xl-550p {
    height: 550%;
  }

  .mx-ht-xl-550p {
    max-height: 550%;
  }

  .mn-ht-xl-550p {
    min-height: 550%;
  }

  .ht-xl-550-force {
    height: 550px !important;
  }

  .ht-xl-550p-force {
    height: 550% !important;
  }

  .ht-xl-600 {
    height: 600px;
  }

  .mn-ht-xl-600 {
    min-height: 600px;
  }

  .ht-xl-600p {
    height: 600%;
  }

  .mx-ht-xl-600p {
    max-height: 600%;
  }

  .mn-ht-xl-600p {
    min-height: 600%;
  }

  .ht-xl-600-force {
    height: 600px !important;
  }

  .ht-xl-600p-force {
    height: 600% !important;
  }

  .ht-xl-650 {
    height: 650px;
  }

  .mn-ht-xl-650 {
    min-height: 650px;
  }

  .ht-xl-650p {
    height: 650%;
  }

  .mx-ht-xl-650p {
    max-height: 650%;
  }

  .mn-ht-xl-650p {
    min-height: 650%;
  }

  .ht-xl-650-force {
    height: 650px !important;
  }

  .ht-xl-650p-force {
    height: 650% !important;
  }

  .ht-xl-700 {
    height: 700px;
  }

  .mn-ht-xl-700 {
    min-height: 700px;
  }

  .ht-xl-700p {
    height: 700%;
  }

  .mx-ht-xl-700p {
    max-height: 700%;
  }

  .mn-ht-xl-700p {
    min-height: 700%;
  }

  .ht-xl-700-force {
    height: 700px !important;
  }

  .ht-xl-700p-force {
    height: 700% !important;
  }

  .ht-xl-750 {
    height: 750px;
  }

  .mn-ht-xl-750 {
    min-height: 750px;
  }

  .ht-xl-750p {
    height: 750%;
  }

  .mx-ht-xl-750p {
    max-height: 750%;
  }

  .mn-ht-xl-750p {
    min-height: 750%;
  }

  .ht-xl-750-force {
    height: 750px !important;
  }

  .ht-xl-750p-force {
    height: 750% !important;
  }

  .ht-xl-800 {
    height: 800px;
  }

  .mn-ht-xl-800 {
    min-height: 800px;
  }

  .ht-xl-800p {
    height: 800%;
  }

  .mx-ht-xl-800p {
    max-height: 800%;
  }

  .mn-ht-xl-800p {
    min-height: 800%;
  }

  .ht-xl-800-force {
    height: 800px !important;
  }

  .ht-xl-800p-force {
    height: 800% !important;
  }

  .ht-xl-850 {
    height: 850px;
  }

  .mn-ht-xl-850 {
    min-height: 850px;
  }

  .ht-xl-850p {
    height: 850%;
  }

  .mx-ht-xl-850p {
    max-height: 850%;
  }

  .mn-ht-xl-850p {
    min-height: 850%;
  }

  .ht-xl-850-force {
    height: 850px !important;
  }

  .ht-xl-850p-force {
    height: 850% !important;
  }

  .ht-xl-100v {
    height: 100vh;
  }
}
/* --------------------------------------- */
/* ############### Padding ############### */
/* --------------------------------------- */
.pd-0 {
  padding: 0px;
}

.pd-0-force {
  padding: 0px !important;
}

.pd-y-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.pd-y-0-force {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.pd-x-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.pd-x-0-force {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.pd-t-0 {
  padding-top: 0px;
}

.pd-r-0 {
  padding-right: 0px;
}

.pd-b-0 {
  padding-bottom: 0px;
}

.pd-l-0 {
  padding-left: 0px;
}

.pd-t-0-force {
  padding-top: 0px !important;
}

.pd-r-0-force {
  padding-right: 0px !important;
}

.pd-b-0-force {
  padding-bottom: 0px !important;
}

.pd-l-0-force {
  padding-left: 0px !important;
}

.pd-1 {
  padding: 1px;
}

.pd-1-force {
  padding: 1px !important;
}

.pd-y-1 {
  padding-top: 1px;
  padding-bottom: 1px;
}

.pd-y-1-force {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.pd-x-1 {
  padding-left: 1px;
  padding-right: 1px;
}

.pd-x-1-force {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.pd-t-1 {
  padding-top: 1px;
}

.pd-r-1 {
  padding-right: 1px;
}

.pd-b-1 {
  padding-bottom: 1px;
}

.pd-l-1 {
  padding-left: 1px;
}

.pd-t-1-force {
  padding-top: 1px !important;
}

.pd-r-1-force {
  padding-right: 1px !important;
}

.pd-b-1-force {
  padding-bottom: 1px !important;
}

.pd-l-1-force {
  padding-left: 1px !important;
}

.pd-2 {
  padding: 2px;
}

.pd-2-force {
  padding: 2px !important;
}

.pd-y-2 {
  padding-top: 2px;
  padding-bottom: 2px;
}

.pd-y-2-force {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.pd-x-2 {
  padding-left: 2px;
  padding-right: 2px;
}

.pd-x-2-force {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.pd-t-2 {
  padding-top: 2px;
}

.pd-r-2 {
  padding-right: 2px;
}

.pd-b-2 {
  padding-bottom: 2px;
}

.pd-l-2 {
  padding-left: 2px;
}

.pd-t-2-force {
  padding-top: 2px !important;
}

.pd-r-2-force {
  padding-right: 2px !important;
}

.pd-b-2-force {
  padding-bottom: 2px !important;
}

.pd-l-2-force {
  padding-left: 2px !important;
}

.pd-3 {
  padding: 3px;
}

.pd-3-force {
  padding: 3px !important;
}

.pd-y-3 {
  padding-top: 3px;
  padding-bottom: 3px;
}

.pd-y-3-force {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.pd-x-3 {
  padding-left: 3px;
  padding-right: 3px;
}

.pd-x-3-force {
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.pd-t-3 {
  padding-top: 3px;
}

.pd-r-3 {
  padding-right: 3px;
}

.pd-b-3 {
  padding-bottom: 3px;
}

.pd-l-3 {
  padding-left: 3px;
}

.pd-t-3-force {
  padding-top: 3px !important;
}

.pd-r-3-force {
  padding-right: 3px !important;
}

.pd-b-3-force {
  padding-bottom: 3px !important;
}

.pd-l-3-force {
  padding-left: 3px !important;
}

.pd-4 {
  padding: 4px;
}

.pd-4-force {
  padding: 4px !important;
}

.pd-y-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.pd-y-4-force {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.pd-x-4 {
  padding-left: 4px;
  padding-right: 4px;
}

.pd-x-4-force {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.pd-t-4 {
  padding-top: 4px;
}

.pd-r-4 {
  padding-right: 4px;
}

.pd-b-4 {
  padding-bottom: 4px;
}

.pd-l-4 {
  padding-left: 4px;
}

.pd-t-4-force {
  padding-top: 4px !important;
}

.pd-r-4-force {
  padding-right: 4px !important;
}

.pd-b-4-force {
  padding-bottom: 4px !important;
}

.pd-l-4-force {
  padding-left: 4px !important;
}

.pd-5 {
  padding: 5px;
}

.pd-5-force {
  padding: 5px !important;
}

.pd-y-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.pd-y-5-force {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.pd-x-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.pd-x-5-force {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.pd-t-5 {
  padding-top: 5px;
}

.pd-r-5 {
  padding-right: 5px;
}

.pd-b-5 {
  padding-bottom: 5px;
}

.pd-l-5 {
  padding-left: 5px;
}

.pd-t-5-force {
  padding-top: 5px !important;
}

.pd-r-5-force {
  padding-right: 5px !important;
}

.pd-b-5-force {
  padding-bottom: 5px !important;
}

.pd-l-5-force {
  padding-left: 5px !important;
}

.pd-6 {
  padding: 6px;
}

.pd-6-force {
  padding: 6px !important;
}

.pd-y-6 {
  padding-top: 6px;
  padding-bottom: 6px;
}

.pd-y-6-force {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.pd-x-6 {
  padding-left: 6px;
  padding-right: 6px;
}

.pd-x-6-force {
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.pd-t-6 {
  padding-top: 6px;
}

.pd-r-6 {
  padding-right: 6px;
}

.pd-b-6 {
  padding-bottom: 6px;
}

.pd-l-6 {
  padding-left: 6px;
}

.pd-t-6-force {
  padding-top: 6px !important;
}

.pd-r-6-force {
  padding-right: 6px !important;
}

.pd-b-6-force {
  padding-bottom: 6px !important;
}

.pd-l-6-force {
  padding-left: 6px !important;
}

.pd-7 {
  padding: 7px;
}

.pd-7-force {
  padding: 7px !important;
}

.pd-y-7 {
  padding-top: 7px;
  padding-bottom: 7px;
}

.pd-y-7-force {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.pd-x-7 {
  padding-left: 7px;
  padding-right: 7px;
}

.pd-x-7-force {
  padding-left: 7px !important;
  padding-right: 7px !important;
}

.pd-t-7 {
  padding-top: 7px;
}

.pd-r-7 {
  padding-right: 7px;
}

.pd-b-7 {
  padding-bottom: 7px;
}

.pd-l-7 {
  padding-left: 7px;
}

.pd-t-7-force {
  padding-top: 7px !important;
}

.pd-r-7-force {
  padding-right: 7px !important;
}

.pd-b-7-force {
  padding-bottom: 7px !important;
}

.pd-l-7-force {
  padding-left: 7px !important;
}

.pd-8 {
  padding: 8px;
}

.pd-8-force {
  padding: 8px !important;
}

.pd-y-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.pd-y-8-force {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.pd-x-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.pd-x-8-force {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.pd-t-8 {
  padding-top: 8px;
}

.pd-r-8 {
  padding-right: 8px;
}

.pd-b-8 {
  padding-bottom: 8px;
}

.pd-l-8 {
  padding-left: 8px;
}

.pd-t-8-force {
  padding-top: 8px !important;
}

.pd-r-8-force {
  padding-right: 8px !important;
}

.pd-b-8-force {
  padding-bottom: 8px !important;
}

.pd-l-8-force {
  padding-left: 8px !important;
}

.pd-9 {
  padding: 9px;
}

.pd-9-force {
  padding: 9px !important;
}

.pd-y-9 {
  padding-top: 9px;
  padding-bottom: 9px;
}

.pd-y-9-force {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

.pd-x-9 {
  padding-left: 9px;
  padding-right: 9px;
}

.pd-x-9-force {
  padding-left: 9px !important;
  padding-right: 9px !important;
}

.pd-t-9 {
  padding-top: 9px;
}

.pd-r-9 {
  padding-right: 9px;
}

.pd-b-9 {
  padding-bottom: 9px;
}

.pd-l-9 {
  padding-left: 9px;
}

.pd-t-9-force {
  padding-top: 9px !important;
}

.pd-r-9-force {
  padding-right: 9px !important;
}

.pd-b-9-force {
  padding-bottom: 9px !important;
}

.pd-l-9-force {
  padding-left: 9px !important;
}

.pd-10 {
  padding: 10px;
}

.pd-10-force {
  padding: 10px !important;
}

.pd-y-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pd-y-10-force {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.pd-x-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.pd-x-10-force {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.pd-t-10 {
  padding-top: 10px;
}

.pd-r-10 {
  padding-right: 10px;
}

.pd-b-10 {
  padding-bottom: 10px;
}

.pd-l-10 {
  padding-left: 10px;
}

.pd-t-10-force {
  padding-top: 10px !important;
}

.pd-r-10-force {
  padding-right: 10px !important;
}

.pd-b-10-force {
  padding-bottom: 10px !important;
}

.pd-l-10-force {
  padding-left: 10px !important;
}

.pd-15 {
  padding: 15px;
}

.pd-15-force {
  padding: 15px !important;
}

.pd-y-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.pd-y-15-force {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.pd-x-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.pd-x-15-force {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.pd-t-15 {
  padding-top: 15px;
}

.pd-r-15 {
  padding-right: 15px;
}

.pd-b-15 {
  padding-bottom: 15px;
}

.pd-l-15 {
  padding-left: 15px;
}

.pd-t-15-force {
  padding-top: 15px !important;
}

.pd-r-15-force {
  padding-right: 15px !important;
}

.pd-b-15-force {
  padding-bottom: 15px !important;
}

.pd-l-15-force {
  padding-left: 15px !important;
}

.pd-20 {
  padding: 20px;
}

.pd-20-force {
  padding: 20px !important;
}

.pd-y-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.pd-y-20-force {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.pd-x-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.pd-x-20-force {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.pd-t-20 {
  padding-top: 20px;
}

.pd-r-20 {
  padding-right: 20px;
}

.pd-b-20 {
  padding-bottom: 20px;
}

.pd-l-20 {
  padding-left: 20px;
}

.pd-t-20-force {
  padding-top: 20px !important;
}

.pd-r-20-force {
  padding-right: 20px !important;
}

.pd-b-20-force {
  padding-bottom: 20px !important;
}

.pd-l-20-force {
  padding-left: 20px !important;
}

.pd-25 {
  padding: 25px;
}

.pd-25-force {
  padding: 25px !important;
}

.pd-y-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.pd-y-25-force {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.pd-x-25 {
  padding-left: 25px;
  padding-right: 25px;
}

.pd-x-25-force {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.pd-t-25 {
  padding-top: 25px;
}

.pd-r-25 {
  padding-right: 25px;
}

.pd-b-25 {
  padding-bottom: 25px;
}

.pd-l-25 {
  padding-left: 25px;
}

.pd-t-25-force {
  padding-top: 25px !important;
}

.pd-r-25-force {
  padding-right: 25px !important;
}

.pd-b-25-force {
  padding-bottom: 25px !important;
}

.pd-l-25-force {
  padding-left: 25px !important;
}

.pd-30 {
  padding: 30px;
}

.pd-30-force {
  padding: 30px !important;
}

.pd-y-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.pd-y-30-force {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.pd-x-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.pd-x-30-force {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.pd-t-30 {
  padding-top: 30px;
}

.pd-r-30 {
  padding-right: 30px;
}

.pd-b-30 {
  padding-bottom: 30px;
}

.pd-l-30 {
  padding-left: 30px;
}

.pd-t-30-force {
  padding-top: 30px !important;
}

.pd-r-30-force {
  padding-right: 30px !important;
}

.pd-b-30-force {
  padding-bottom: 30px !important;
}

.pd-l-30-force {
  padding-left: 30px !important;
}

.pd-35 {
  padding: 35px;
}

.pd-35-force {
  padding: 35px !important;
}

.pd-y-35 {
  padding-top: 35px;
  padding-bottom: 35px;
}

.pd-y-35-force {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.pd-x-35 {
  padding-left: 35px;
  padding-right: 35px;
}

.pd-x-35-force {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.pd-t-35 {
  padding-top: 35px;
}

.pd-r-35 {
  padding-right: 35px;
}

.pd-b-35 {
  padding-bottom: 35px;
}

.pd-l-35 {
  padding-left: 35px;
}

.pd-t-35-force {
  padding-top: 35px !important;
}

.pd-r-35-force {
  padding-right: 35px !important;
}

.pd-b-35-force {
  padding-bottom: 35px !important;
}

.pd-l-35-force {
  padding-left: 35px !important;
}

.pd-40 {
  padding: 40px;
}

.pd-40-force {
  padding: 40px !important;
}

.pd-y-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.pd-y-40-force {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.pd-x-40 {
  padding-left: 40px;
  padding-right: 40px;
}

.pd-x-40-force {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.pd-t-40 {
  padding-top: 40px;
}

.pd-r-40 {
  padding-right: 40px;
}

.pd-b-40 {
  padding-bottom: 40px;
}

.pd-l-40 {
  padding-left: 40px;
}

.pd-t-40-force {
  padding-top: 40px !important;
}

.pd-r-40-force {
  padding-right: 40px !important;
}

.pd-b-40-force {
  padding-bottom: 40px !important;
}

.pd-l-40-force {
  padding-left: 40px !important;
}

.pd-45 {
  padding: 45px;
}

.pd-45-force {
  padding: 45px !important;
}

.pd-y-45 {
  padding-top: 45px;
  padding-bottom: 45px;
}

.pd-y-45-force {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.pd-x-45 {
  padding-left: 45px;
  padding-right: 45px;
}

.pd-x-45-force {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.pd-t-45 {
  padding-top: 45px;
}

.pd-r-45 {
  padding-right: 45px;
}

.pd-b-45 {
  padding-bottom: 45px;
}

.pd-l-45 {
  padding-left: 45px;
}

.pd-t-45-force {
  padding-top: 45px !important;
}

.pd-r-45-force {
  padding-right: 45px !important;
}

.pd-b-45-force {
  padding-bottom: 45px !important;
}

.pd-l-45-force {
  padding-left: 45px !important;
}

.pd-50 {
  padding: 50px;
}

.pd-50-force {
  padding: 50px !important;
}

.pd-y-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.pd-y-50-force {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.pd-x-50 {
  padding-left: 50px;
  padding-right: 50px;
}

.pd-x-50-force {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.pd-t-50 {
  padding-top: 50px;
}

.pd-r-50 {
  padding-right: 50px;
}

.pd-b-50 {
  padding-bottom: 50px;
}

.pd-l-50 {
  padding-left: 50px;
}

.pd-t-50-force {
  padding-top: 50px !important;
}

.pd-r-50-force {
  padding-right: 50px !important;
}

.pd-b-50-force {
  padding-bottom: 50px !important;
}

.pd-l-50-force {
  padding-left: 50px !important;
}

.pd-55 {
  padding: 55px;
}

.pd-55-force {
  padding: 55px !important;
}

.pd-y-55 {
  padding-top: 55px;
  padding-bottom: 55px;
}

.pd-y-55-force {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}

.pd-x-55 {
  padding-left: 55px;
  padding-right: 55px;
}

.pd-x-55-force {
  padding-left: 55px !important;
  padding-right: 55px !important;
}

.pd-t-55 {
  padding-top: 55px;
}

.pd-r-55 {
  padding-right: 55px;
}

.pd-b-55 {
  padding-bottom: 55px;
}

.pd-l-55 {
  padding-left: 55px;
}

.pd-t-55-force {
  padding-top: 55px !important;
}

.pd-r-55-force {
  padding-right: 55px !important;
}

.pd-b-55-force {
  padding-bottom: 55px !important;
}

.pd-l-55-force {
  padding-left: 55px !important;
}

.pd-60 {
  padding: 60px;
}

.pd-60-force {
  padding: 60px !important;
}

.pd-y-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.pd-y-60-force {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.pd-x-60 {
  padding-left: 60px;
  padding-right: 60px;
}

.pd-x-60-force {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.pd-t-60 {
  padding-top: 60px;
}

.pd-r-60 {
  padding-right: 60px;
}

.pd-b-60 {
  padding-bottom: 60px;
}

.pd-l-60 {
  padding-left: 60px;
}

.pd-t-60-force {
  padding-top: 60px !important;
}

.pd-r-60-force {
  padding-right: 60px !important;
}

.pd-b-60-force {
  padding-bottom: 60px !important;
}

.pd-l-60-force {
  padding-left: 60px !important;
}

.pd-65 {
  padding: 65px;
}

.pd-65-force {
  padding: 65px !important;
}

.pd-y-65 {
  padding-top: 65px;
  padding-bottom: 65px;
}

.pd-y-65-force {
  padding-top: 65px !important;
  padding-bottom: 65px !important;
}

.pd-x-65 {
  padding-left: 65px;
  padding-right: 65px;
}

.pd-x-65-force {
  padding-left: 65px !important;
  padding-right: 65px !important;
}

.pd-t-65 {
  padding-top: 65px;
}

.pd-r-65 {
  padding-right: 65px;
}

.pd-b-65 {
  padding-bottom: 65px;
}

.pd-l-65 {
  padding-left: 65px;
}

.pd-t-65-force {
  padding-top: 65px !important;
}

.pd-r-65-force {
  padding-right: 65px !important;
}

.pd-b-65-force {
  padding-bottom: 65px !important;
}

.pd-l-65-force {
  padding-left: 65px !important;
}

.pd-70 {
  padding: 70px;
}

.pd-70-force {
  padding: 70px !important;
}

.pd-y-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.pd-y-70-force {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.pd-x-70 {
  padding-left: 70px;
  padding-right: 70px;
}

.pd-x-70-force {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

.pd-t-70 {
  padding-top: 70px;
}

.pd-r-70 {
  padding-right: 70px;
}

.pd-b-70 {
  padding-bottom: 70px;
}

.pd-l-70 {
  padding-left: 70px;
}

.pd-t-70-force {
  padding-top: 70px !important;
}

.pd-r-70-force {
  padding-right: 70px !important;
}

.pd-b-70-force {
  padding-bottom: 70px !important;
}

.pd-l-70-force {
  padding-left: 70px !important;
}

.pd-75 {
  padding: 75px;
}

.pd-75-force {
  padding: 75px !important;
}

.pd-y-75 {
  padding-top: 75px;
  padding-bottom: 75px;
}

.pd-y-75-force {
  padding-top: 75px !important;
  padding-bottom: 75px !important;
}

.pd-x-75 {
  padding-left: 75px;
  padding-right: 75px;
}

.pd-x-75-force {
  padding-left: 75px !important;
  padding-right: 75px !important;
}

.pd-t-75 {
  padding-top: 75px;
}

.pd-r-75 {
  padding-right: 75px;
}

.pd-b-75 {
  padding-bottom: 75px;
}

.pd-l-75 {
  padding-left: 75px;
}

.pd-t-75-force {
  padding-top: 75px !important;
}

.pd-r-75-force {
  padding-right: 75px !important;
}

.pd-b-75-force {
  padding-bottom: 75px !important;
}

.pd-l-75-force {
  padding-left: 75px !important;
}

.pd-80 {
  padding: 80px;
}

.pd-80-force {
  padding: 80px !important;
}

.pd-y-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.pd-y-80-force {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.pd-x-80 {
  padding-left: 80px;
  padding-right: 80px;
}

.pd-x-80-force {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.pd-t-80 {
  padding-top: 80px;
}

.pd-r-80 {
  padding-right: 80px;
}

.pd-b-80 {
  padding-bottom: 80px;
}

.pd-l-80 {
  padding-left: 80px;
}

.pd-t-80-force {
  padding-top: 80px !important;
}

.pd-r-80-force {
  padding-right: 80px !important;
}

.pd-b-80-force {
  padding-bottom: 80px !important;
}

.pd-l-80-force {
  padding-left: 80px !important;
}

.pd-85 {
  padding: 85px;
}

.pd-85-force {
  padding: 85px !important;
}

.pd-y-85 {
  padding-top: 85px;
  padding-bottom: 85px;
}

.pd-y-85-force {
  padding-top: 85px !important;
  padding-bottom: 85px !important;
}

.pd-x-85 {
  padding-left: 85px;
  padding-right: 85px;
}

.pd-x-85-force {
  padding-left: 85px !important;
  padding-right: 85px !important;
}

.pd-t-85 {
  padding-top: 85px;
}

.pd-r-85 {
  padding-right: 85px;
}

.pd-b-85 {
  padding-bottom: 85px;
}

.pd-l-85 {
  padding-left: 85px;
}

.pd-t-85-force {
  padding-top: 85px !important;
}

.pd-r-85-force {
  padding-right: 85px !important;
}

.pd-b-85-force {
  padding-bottom: 85px !important;
}

.pd-l-85-force {
  padding-left: 85px !important;
}

.pd-90 {
  padding: 90px;
}

.pd-90-force {
  padding: 90px !important;
}

.pd-y-90 {
  padding-top: 90px;
  padding-bottom: 90px;
}

.pd-y-90-force {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

.pd-x-90 {
  padding-left: 90px;
  padding-right: 90px;
}

.pd-x-90-force {
  padding-left: 90px !important;
  padding-right: 90px !important;
}

.pd-t-90 {
  padding-top: 90px;
}

.pd-r-90 {
  padding-right: 90px;
}

.pd-b-90 {
  padding-bottom: 90px;
}

.pd-l-90 {
  padding-left: 90px;
}

.pd-t-90-force {
  padding-top: 90px !important;
}

.pd-r-90-force {
  padding-right: 90px !important;
}

.pd-b-90-force {
  padding-bottom: 90px !important;
}

.pd-l-90-force {
  padding-left: 90px !important;
}

.pd-95 {
  padding: 95px;
}

.pd-95-force {
  padding: 95px !important;
}

.pd-y-95 {
  padding-top: 95px;
  padding-bottom: 95px;
}

.pd-y-95-force {
  padding-top: 95px !important;
  padding-bottom: 95px !important;
}

.pd-x-95 {
  padding-left: 95px;
  padding-right: 95px;
}

.pd-x-95-force {
  padding-left: 95px !important;
  padding-right: 95px !important;
}

.pd-t-95 {
  padding-top: 95px;
}

.pd-r-95 {
  padding-right: 95px;
}

.pd-b-95 {
  padding-bottom: 95px;
}

.pd-l-95 {
  padding-left: 95px;
}

.pd-t-95-force {
  padding-top: 95px !important;
}

.pd-r-95-force {
  padding-right: 95px !important;
}

.pd-b-95-force {
  padding-bottom: 95px !important;
}

.pd-l-95-force {
  padding-left: 95px !important;
}

.pd-100 {
  padding: 100px;
}

.pd-100-force {
  padding: 100px !important;
}

.pd-y-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pd-y-100-force {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.pd-x-100 {
  padding-left: 100px;
  padding-right: 100px;
}

.pd-x-100-force {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

.pd-t-100 {
  padding-top: 100px;
}

.pd-r-100 {
  padding-right: 100px;
}

.pd-b-100 {
  padding-bottom: 100px;
}

.pd-l-100 {
  padding-left: 100px;
}

.pd-t-100-force {
  padding-top: 100px !important;
}

.pd-r-100-force {
  padding-right: 100px !important;
}

.pd-b-100-force {
  padding-bottom: 100px !important;
}

.pd-l-100-force {
  padding-left: 100px !important;
}

.pd-110 {
  padding: 110px;
}

.pd-110-force {
  padding: 110px !important;
}

.pd-y-110 {
  padding-top: 110px;
  padding-bottom: 110px;
}

.pd-y-110-force {
  padding-top: 110px !important;
  padding-bottom: 110px !important;
}

.pd-x-110 {
  padding-left: 110px;
  padding-right: 110px;
}

.pd-x-110-force {
  padding-left: 110px !important;
  padding-right: 110px !important;
}

.pd-t-110 {
  padding-top: 110px;
}

.pd-r-110 {
  padding-right: 110px;
}

.pd-b-110 {
  padding-bottom: 110px;
}

.pd-l-110 {
  padding-left: 110px;
}

.pd-t-110-force {
  padding-top: 110px !important;
}

.pd-r-110-force {
  padding-right: 110px !important;
}

.pd-b-110-force {
  padding-bottom: 110px !important;
}

.pd-l-110-force {
  padding-left: 110px !important;
}

.pd-120 {
  padding: 120px;
}

.pd-120-force {
  padding: 120px !important;
}

.pd-y-120 {
  padding-top: 120px;
  padding-bottom: 120px;
}

.pd-y-120-force {
  padding-top: 120px !important;
  padding-bottom: 120px !important;
}

.pd-x-120 {
  padding-left: 120px;
  padding-right: 120px;
}

.pd-x-120-force {
  padding-left: 120px !important;
  padding-right: 120px !important;
}

.pd-t-120 {
  padding-top: 120px;
}

.pd-r-120 {
  padding-right: 120px;
}

.pd-b-120 {
  padding-bottom: 120px;
}

.pd-l-120 {
  padding-left: 120px;
}

.pd-t-120-force {
  padding-top: 120px !important;
}

.pd-r-120-force {
  padding-right: 120px !important;
}

.pd-b-120-force {
  padding-bottom: 120px !important;
}

.pd-l-120-force {
  padding-left: 120px !important;
}

.pd-130 {
  padding: 130px;
}

.pd-130-force {
  padding: 130px !important;
}

.pd-y-130 {
  padding-top: 130px;
  padding-bottom: 130px;
}

.pd-y-130-force {
  padding-top: 130px !important;
  padding-bottom: 130px !important;
}

.pd-x-130 {
  padding-left: 130px;
  padding-right: 130px;
}

.pd-x-130-force {
  padding-left: 130px !important;
  padding-right: 130px !important;
}

.pd-t-130 {
  padding-top: 130px;
}

.pd-r-130 {
  padding-right: 130px;
}

.pd-b-130 {
  padding-bottom: 130px;
}

.pd-l-130 {
  padding-left: 130px;
}

.pd-t-130-force {
  padding-top: 130px !important;
}

.pd-r-130-force {
  padding-right: 130px !important;
}

.pd-b-130-force {
  padding-bottom: 130px !important;
}

.pd-l-130-force {
  padding-left: 130px !important;
}

.pd-140 {
  padding: 140px;
}

.pd-140-force {
  padding: 140px !important;
}

.pd-y-140 {
  padding-top: 140px;
  padding-bottom: 140px;
}

.pd-y-140-force {
  padding-top: 140px !important;
  padding-bottom: 140px !important;
}

.pd-x-140 {
  padding-left: 140px;
  padding-right: 140px;
}

.pd-x-140-force {
  padding-left: 140px !important;
  padding-right: 140px !important;
}

.pd-t-140 {
  padding-top: 140px;
}

.pd-r-140 {
  padding-right: 140px;
}

.pd-b-140 {
  padding-bottom: 140px;
}

.pd-l-140 {
  padding-left: 140px;
}

.pd-t-140-force {
  padding-top: 140px !important;
}

.pd-r-140-force {
  padding-right: 140px !important;
}

.pd-b-140-force {
  padding-bottom: 140px !important;
}

.pd-l-140-force {
  padding-left: 140px !important;
}

.pd-150 {
  padding: 150px;
}

.pd-150-force {
  padding: 150px !important;
}

.pd-y-150 {
  padding-top: 150px;
  padding-bottom: 150px;
}

.pd-y-150-force {
  padding-top: 150px !important;
  padding-bottom: 150px !important;
}

.pd-x-150 {
  padding-left: 150px;
  padding-right: 150px;
}

.pd-x-150-force {
  padding-left: 150px !important;
  padding-right: 150px !important;
}

.pd-t-150 {
  padding-top: 150px;
}

.pd-r-150 {
  padding-right: 150px;
}

.pd-b-150 {
  padding-bottom: 150px;
}

.pd-l-150 {
  padding-left: 150px;
}

.pd-t-150-force {
  padding-top: 150px !important;
}

.pd-r-150-force {
  padding-right: 150px !important;
}

.pd-b-150-force {
  padding-bottom: 150px !important;
}

.pd-l-150-force {
  padding-left: 150px !important;
}

.pd-160 {
  padding: 160px;
}

.pd-160-force {
  padding: 160px !important;
}

.pd-y-160 {
  padding-top: 160px;
  padding-bottom: 160px;
}

.pd-y-160-force {
  padding-top: 160px !important;
  padding-bottom: 160px !important;
}

.pd-x-160 {
  padding-left: 160px;
  padding-right: 160px;
}

.pd-x-160-force {
  padding-left: 160px !important;
  padding-right: 160px !important;
}

.pd-t-160 {
  padding-top: 160px;
}

.pd-r-160 {
  padding-right: 160px;
}

.pd-b-160 {
  padding-bottom: 160px;
}

.pd-l-160 {
  padding-left: 160px;
}

.pd-t-160-force {
  padding-top: 160px !important;
}

.pd-r-160-force {
  padding-right: 160px !important;
}

.pd-b-160-force {
  padding-bottom: 160px !important;
}

.pd-l-160-force {
  padding-left: 160px !important;
}

.pd-170 {
  padding: 170px;
}

.pd-170-force {
  padding: 170px !important;
}

.pd-y-170 {
  padding-top: 170px;
  padding-bottom: 170px;
}

.pd-y-170-force {
  padding-top: 170px !important;
  padding-bottom: 170px !important;
}

.pd-x-170 {
  padding-left: 170px;
  padding-right: 170px;
}

.pd-x-170-force {
  padding-left: 170px !important;
  padding-right: 170px !important;
}

.pd-t-170 {
  padding-top: 170px;
}

.pd-r-170 {
  padding-right: 170px;
}

.pd-b-170 {
  padding-bottom: 170px;
}

.pd-l-170 {
  padding-left: 170px;
}

.pd-t-170-force {
  padding-top: 170px !important;
}

.pd-r-170-force {
  padding-right: 170px !important;
}

.pd-b-170-force {
  padding-bottom: 170px !important;
}

.pd-l-170-force {
  padding-left: 170px !important;
}

.pd-180 {
  padding: 180px;
}

.pd-180-force {
  padding: 180px !important;
}

.pd-y-180 {
  padding-top: 180px;
  padding-bottom: 180px;
}

.pd-y-180-force {
  padding-top: 180px !important;
  padding-bottom: 180px !important;
}

.pd-x-180 {
  padding-left: 180px;
  padding-right: 180px;
}

.pd-x-180-force {
  padding-left: 180px !important;
  padding-right: 180px !important;
}

.pd-t-180 {
  padding-top: 180px;
}

.pd-r-180 {
  padding-right: 180px;
}

.pd-b-180 {
  padding-bottom: 180px;
}

.pd-l-180 {
  padding-left: 180px;
}

.pd-t-180-force {
  padding-top: 180px !important;
}

.pd-r-180-force {
  padding-right: 180px !important;
}

.pd-b-180-force {
  padding-bottom: 180px !important;
}

.pd-l-180-force {
  padding-left: 180px !important;
}

.pd-190 {
  padding: 190px;
}

.pd-190-force {
  padding: 190px !important;
}

.pd-y-190 {
  padding-top: 190px;
  padding-bottom: 190px;
}

.pd-y-190-force {
  padding-top: 190px !important;
  padding-bottom: 190px !important;
}

.pd-x-190 {
  padding-left: 190px;
  padding-right: 190px;
}

.pd-x-190-force {
  padding-left: 190px !important;
  padding-right: 190px !important;
}

.pd-t-190 {
  padding-top: 190px;
}

.pd-r-190 {
  padding-right: 190px;
}

.pd-b-190 {
  padding-bottom: 190px;
}

.pd-l-190 {
  padding-left: 190px;
}

.pd-t-190-force {
  padding-top: 190px !important;
}

.pd-r-190-force {
  padding-right: 190px !important;
}

.pd-b-190-force {
  padding-bottom: 190px !important;
}

.pd-l-190-force {
  padding-left: 190px !important;
}

.pd-200 {
  padding: 200px;
}

.pd-200-force {
  padding: 200px !important;
}

.pd-y-200 {
  padding-top: 200px;
  padding-bottom: 200px;
}

.pd-y-200-force {
  padding-top: 200px !important;
  padding-bottom: 200px !important;
}

.pd-x-200 {
  padding-left: 200px;
  padding-right: 200px;
}

.pd-x-200-force {
  padding-left: 200px !important;
  padding-right: 200px !important;
}

.pd-t-200 {
  padding-top: 200px;
}

.pd-r-200 {
  padding-right: 200px;
}

.pd-b-200 {
  padding-bottom: 200px;
}

.pd-l-200 {
  padding-left: 200px;
}

.pd-t-200-force {
  padding-top: 200px !important;
}

.pd-r-200-force {
  padding-right: 200px !important;
}

.pd-b-200-force {
  padding-bottom: 200px !important;
}

.pd-l-200-force {
  padding-left: 200px !important;
}

@media (min-width: 480px) {
  .pd-xs-0 {
    padding: 0px;
  }

  .pd-xs-0-force {
    padding: 0px !important;
  }

  .pd-xs-y-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .pd-xs-y-0-force {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .pd-xs-x-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .pd-xs-x-0-force {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .pd-xs-t-0 {
    padding-top: 0px;
  }

  .pd-xs-r-0 {
    padding-right: 0px;
  }

  .pd-xs-b-0 {
    padding-bottom: 0px;
  }

  .pd-xs-l-0 {
    padding-left: 0px;
  }

  .pd-xs-t-0-force {
    padding-top: 0px !important;
  }

  .pd-xs-r-0-force {
    padding-right: 0px !important;
  }

  .pd-xs-b-0-force {
    padding-bottom: 0px !important;
  }

  .pd-xs-l-0-force {
    padding-left: 0px !important;
  }

  .pd-xs-1 {
    padding: 1px;
  }

  .pd-xs-1-force {
    padding: 1px !important;
  }

  .pd-xs-y-1 {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .pd-xs-y-1-force {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .pd-xs-x-1 {
    padding-left: 1px;
    padding-right: 1px;
  }

  .pd-xs-x-1-force {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .pd-xs-t-1 {
    padding-top: 1px;
  }

  .pd-xs-r-1 {
    padding-right: 1px;
  }

  .pd-xs-b-1 {
    padding-bottom: 1px;
  }

  .pd-xs-l-1 {
    padding-left: 1px;
  }

  .pd-xs-t-1-force {
    padding-top: 1px !important;
  }

  .pd-xs-r-1-force {
    padding-right: 1px !important;
  }

  .pd-xs-b-1-force {
    padding-bottom: 1px !important;
  }

  .pd-xs-l-1-force {
    padding-left: 1px !important;
  }

  .pd-xs-2 {
    padding: 2px;
  }

  .pd-xs-2-force {
    padding: 2px !important;
  }

  .pd-xs-y-2 {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .pd-xs-y-2-force {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  .pd-xs-x-2 {
    padding-left: 2px;
    padding-right: 2px;
  }

  .pd-xs-x-2-force {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .pd-xs-t-2 {
    padding-top: 2px;
  }

  .pd-xs-r-2 {
    padding-right: 2px;
  }

  .pd-xs-b-2 {
    padding-bottom: 2px;
  }

  .pd-xs-l-2 {
    padding-left: 2px;
  }

  .pd-xs-t-2-force {
    padding-top: 2px !important;
  }

  .pd-xs-r-2-force {
    padding-right: 2px !important;
  }

  .pd-xs-b-2-force {
    padding-bottom: 2px !important;
  }

  .pd-xs-l-2-force {
    padding-left: 2px !important;
  }

  .pd-xs-3 {
    padding: 3px;
  }

  .pd-xs-3-force {
    padding: 3px !important;
  }

  .pd-xs-y-3 {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .pd-xs-y-3-force {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  .pd-xs-x-3 {
    padding-left: 3px;
    padding-right: 3px;
  }

  .pd-xs-x-3-force {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }

  .pd-xs-t-3 {
    padding-top: 3px;
  }

  .pd-xs-r-3 {
    padding-right: 3px;
  }

  .pd-xs-b-3 {
    padding-bottom: 3px;
  }

  .pd-xs-l-3 {
    padding-left: 3px;
  }

  .pd-xs-t-3-force {
    padding-top: 3px !important;
  }

  .pd-xs-r-3-force {
    padding-right: 3px !important;
  }

  .pd-xs-b-3-force {
    padding-bottom: 3px !important;
  }

  .pd-xs-l-3-force {
    padding-left: 3px !important;
  }

  .pd-xs-4 {
    padding: 4px;
  }

  .pd-xs-4-force {
    padding: 4px !important;
  }

  .pd-xs-y-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .pd-xs-y-4-force {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .pd-xs-x-4 {
    padding-left: 4px;
    padding-right: 4px;
  }

  .pd-xs-x-4-force {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .pd-xs-t-4 {
    padding-top: 4px;
  }

  .pd-xs-r-4 {
    padding-right: 4px;
  }

  .pd-xs-b-4 {
    padding-bottom: 4px;
  }

  .pd-xs-l-4 {
    padding-left: 4px;
  }

  .pd-xs-t-4-force {
    padding-top: 4px !important;
  }

  .pd-xs-r-4-force {
    padding-right: 4px !important;
  }

  .pd-xs-b-4-force {
    padding-bottom: 4px !important;
  }

  .pd-xs-l-4-force {
    padding-left: 4px !important;
  }

  .pd-xs-5 {
    padding: 5px;
  }

  .pd-xs-5-force {
    padding: 5px !important;
  }

  .pd-xs-y-5 {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .pd-xs-y-5-force {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .pd-xs-x-5 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .pd-xs-x-5-force {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .pd-xs-t-5 {
    padding-top: 5px;
  }

  .pd-xs-r-5 {
    padding-right: 5px;
  }

  .pd-xs-b-5 {
    padding-bottom: 5px;
  }

  .pd-xs-l-5 {
    padding-left: 5px;
  }

  .pd-xs-t-5-force {
    padding-top: 5px !important;
  }

  .pd-xs-r-5-force {
    padding-right: 5px !important;
  }

  .pd-xs-b-5-force {
    padding-bottom: 5px !important;
  }

  .pd-xs-l-5-force {
    padding-left: 5px !important;
  }

  .pd-xs-6 {
    padding: 6px;
  }

  .pd-xs-6-force {
    padding: 6px !important;
  }

  .pd-xs-y-6 {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .pd-xs-y-6-force {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }

  .pd-xs-x-6 {
    padding-left: 6px;
    padding-right: 6px;
  }

  .pd-xs-x-6-force {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  .pd-xs-t-6 {
    padding-top: 6px;
  }

  .pd-xs-r-6 {
    padding-right: 6px;
  }

  .pd-xs-b-6 {
    padding-bottom: 6px;
  }

  .pd-xs-l-6 {
    padding-left: 6px;
  }

  .pd-xs-t-6-force {
    padding-top: 6px !important;
  }

  .pd-xs-r-6-force {
    padding-right: 6px !important;
  }

  .pd-xs-b-6-force {
    padding-bottom: 6px !important;
  }

  .pd-xs-l-6-force {
    padding-left: 6px !important;
  }

  .pd-xs-7 {
    padding: 7px;
  }

  .pd-xs-7-force {
    padding: 7px !important;
  }

  .pd-xs-y-7 {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .pd-xs-y-7-force {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
  }

  .pd-xs-x-7 {
    padding-left: 7px;
    padding-right: 7px;
  }

  .pd-xs-x-7-force {
    padding-left: 7px !important;
    padding-right: 7px !important;
  }

  .pd-xs-t-7 {
    padding-top: 7px;
  }

  .pd-xs-r-7 {
    padding-right: 7px;
  }

  .pd-xs-b-7 {
    padding-bottom: 7px;
  }

  .pd-xs-l-7 {
    padding-left: 7px;
  }

  .pd-xs-t-7-force {
    padding-top: 7px !important;
  }

  .pd-xs-r-7-force {
    padding-right: 7px !important;
  }

  .pd-xs-b-7-force {
    padding-bottom: 7px !important;
  }

  .pd-xs-l-7-force {
    padding-left: 7px !important;
  }

  .pd-xs-8 {
    padding: 8px;
  }

  .pd-xs-8-force {
    padding: 8px !important;
  }

  .pd-xs-y-8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .pd-xs-y-8-force {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .pd-xs-x-8 {
    padding-left: 8px;
    padding-right: 8px;
  }

  .pd-xs-x-8-force {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .pd-xs-t-8 {
    padding-top: 8px;
  }

  .pd-xs-r-8 {
    padding-right: 8px;
  }

  .pd-xs-b-8 {
    padding-bottom: 8px;
  }

  .pd-xs-l-8 {
    padding-left: 8px;
  }

  .pd-xs-t-8-force {
    padding-top: 8px !important;
  }

  .pd-xs-r-8-force {
    padding-right: 8px !important;
  }

  .pd-xs-b-8-force {
    padding-bottom: 8px !important;
  }

  .pd-xs-l-8-force {
    padding-left: 8px !important;
  }

  .pd-xs-9 {
    padding: 9px;
  }

  .pd-xs-9-force {
    padding: 9px !important;
  }

  .pd-xs-y-9 {
    padding-top: 9px;
    padding-bottom: 9px;
  }

  .pd-xs-y-9-force {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
  }

  .pd-xs-x-9 {
    padding-left: 9px;
    padding-right: 9px;
  }

  .pd-xs-x-9-force {
    padding-left: 9px !important;
    padding-right: 9px !important;
  }

  .pd-xs-t-9 {
    padding-top: 9px;
  }

  .pd-xs-r-9 {
    padding-right: 9px;
  }

  .pd-xs-b-9 {
    padding-bottom: 9px;
  }

  .pd-xs-l-9 {
    padding-left: 9px;
  }

  .pd-xs-t-9-force {
    padding-top: 9px !important;
  }

  .pd-xs-r-9-force {
    padding-right: 9px !important;
  }

  .pd-xs-b-9-force {
    padding-bottom: 9px !important;
  }

  .pd-xs-l-9-force {
    padding-left: 9px !important;
  }

  .pd-xs-10 {
    padding: 10px;
  }

  .pd-xs-10-force {
    padding: 10px !important;
  }

  .pd-xs-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .pd-xs-y-10-force {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .pd-xs-x-10 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .pd-xs-x-10-force {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .pd-xs-t-10 {
    padding-top: 10px;
  }

  .pd-xs-r-10 {
    padding-right: 10px;
  }

  .pd-xs-b-10 {
    padding-bottom: 10px;
  }

  .pd-xs-l-10 {
    padding-left: 10px;
  }

  .pd-xs-t-10-force {
    padding-top: 10px !important;
  }

  .pd-xs-r-10-force {
    padding-right: 10px !important;
  }

  .pd-xs-b-10-force {
    padding-bottom: 10px !important;
  }

  .pd-xs-l-10-force {
    padding-left: 10px !important;
  }

  .pd-xs-15 {
    padding: 15px;
  }

  .pd-xs-15-force {
    padding: 15px !important;
  }

  .pd-xs-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .pd-xs-y-15-force {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .pd-xs-x-15 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .pd-xs-x-15-force {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .pd-xs-t-15 {
    padding-top: 15px;
  }

  .pd-xs-r-15 {
    padding-right: 15px;
  }

  .pd-xs-b-15 {
    padding-bottom: 15px;
  }

  .pd-xs-l-15 {
    padding-left: 15px;
  }

  .pd-xs-t-15-force {
    padding-top: 15px !important;
  }

  .pd-xs-r-15-force {
    padding-right: 15px !important;
  }

  .pd-xs-b-15-force {
    padding-bottom: 15px !important;
  }

  .pd-xs-l-15-force {
    padding-left: 15px !important;
  }

  .pd-xs-20 {
    padding: 20px;
  }

  .pd-xs-20-force {
    padding: 20px !important;
  }

  .pd-xs-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .pd-xs-y-20-force {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .pd-xs-x-20 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .pd-xs-x-20-force {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .pd-xs-t-20 {
    padding-top: 20px;
  }

  .pd-xs-r-20 {
    padding-right: 20px;
  }

  .pd-xs-b-20 {
    padding-bottom: 20px;
  }

  .pd-xs-l-20 {
    padding-left: 20px;
  }

  .pd-xs-t-20-force {
    padding-top: 20px !important;
  }

  .pd-xs-r-20-force {
    padding-right: 20px !important;
  }

  .pd-xs-b-20-force {
    padding-bottom: 20px !important;
  }

  .pd-xs-l-20-force {
    padding-left: 20px !important;
  }

  .pd-xs-25 {
    padding: 25px;
  }

  .pd-xs-25-force {
    padding: 25px !important;
  }

  .pd-xs-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .pd-xs-y-25-force {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .pd-xs-x-25 {
    padding-left: 25px;
    padding-right: 25px;
  }

  .pd-xs-x-25-force {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .pd-xs-t-25 {
    padding-top: 25px;
  }

  .pd-xs-r-25 {
    padding-right: 25px;
  }

  .pd-xs-b-25 {
    padding-bottom: 25px;
  }

  .pd-xs-l-25 {
    padding-left: 25px;
  }

  .pd-xs-t-25-force {
    padding-top: 25px !important;
  }

  .pd-xs-r-25-force {
    padding-right: 25px !important;
  }

  .pd-xs-b-25-force {
    padding-bottom: 25px !important;
  }

  .pd-xs-l-25-force {
    padding-left: 25px !important;
  }

  .pd-xs-30 {
    padding: 30px;
  }

  .pd-xs-30-force {
    padding: 30px !important;
  }

  .pd-xs-y-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .pd-xs-y-30-force {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .pd-xs-x-30 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .pd-xs-x-30-force {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .pd-xs-t-30 {
    padding-top: 30px;
  }

  .pd-xs-r-30 {
    padding-right: 30px;
  }

  .pd-xs-b-30 {
    padding-bottom: 30px;
  }

  .pd-xs-l-30 {
    padding-left: 30px;
  }

  .pd-xs-t-30-force {
    padding-top: 30px !important;
  }

  .pd-xs-r-30-force {
    padding-right: 30px !important;
  }

  .pd-xs-b-30-force {
    padding-bottom: 30px !important;
  }

  .pd-xs-l-30-force {
    padding-left: 30px !important;
  }

  .pd-xs-35 {
    padding: 35px;
  }

  .pd-xs-35-force {
    padding: 35px !important;
  }

  .pd-xs-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .pd-xs-y-35-force {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }

  .pd-xs-x-35 {
    padding-left: 35px;
    padding-right: 35px;
  }

  .pd-xs-x-35-force {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }

  .pd-xs-t-35 {
    padding-top: 35px;
  }

  .pd-xs-r-35 {
    padding-right: 35px;
  }

  .pd-xs-b-35 {
    padding-bottom: 35px;
  }

  .pd-xs-l-35 {
    padding-left: 35px;
  }

  .pd-xs-t-35-force {
    padding-top: 35px !important;
  }

  .pd-xs-r-35-force {
    padding-right: 35px !important;
  }

  .pd-xs-b-35-force {
    padding-bottom: 35px !important;
  }

  .pd-xs-l-35-force {
    padding-left: 35px !important;
  }

  .pd-xs-40 {
    padding: 40px;
  }

  .pd-xs-40-force {
    padding: 40px !important;
  }

  .pd-xs-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .pd-xs-y-40-force {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .pd-xs-x-40 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .pd-xs-x-40-force {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .pd-xs-t-40 {
    padding-top: 40px;
  }

  .pd-xs-r-40 {
    padding-right: 40px;
  }

  .pd-xs-b-40 {
    padding-bottom: 40px;
  }

  .pd-xs-l-40 {
    padding-left: 40px;
  }

  .pd-xs-t-40-force {
    padding-top: 40px !important;
  }

  .pd-xs-r-40-force {
    padding-right: 40px !important;
  }

  .pd-xs-b-40-force {
    padding-bottom: 40px !important;
  }

  .pd-xs-l-40-force {
    padding-left: 40px !important;
  }

  .pd-xs-45 {
    padding: 45px;
  }

  .pd-xs-45-force {
    padding: 45px !important;
  }

  .pd-xs-y-45 {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .pd-xs-y-45-force {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }

  .pd-xs-x-45 {
    padding-left: 45px;
    padding-right: 45px;
  }

  .pd-xs-x-45-force {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }

  .pd-xs-t-45 {
    padding-top: 45px;
  }

  .pd-xs-r-45 {
    padding-right: 45px;
  }

  .pd-xs-b-45 {
    padding-bottom: 45px;
  }

  .pd-xs-l-45 {
    padding-left: 45px;
  }

  .pd-xs-t-45-force {
    padding-top: 45px !important;
  }

  .pd-xs-r-45-force {
    padding-right: 45px !important;
  }

  .pd-xs-b-45-force {
    padding-bottom: 45px !important;
  }

  .pd-xs-l-45-force {
    padding-left: 45px !important;
  }

  .pd-xs-50 {
    padding: 50px;
  }

  .pd-xs-50-force {
    padding: 50px !important;
  }

  .pd-xs-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .pd-xs-y-50-force {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .pd-xs-x-50 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .pd-xs-x-50-force {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .pd-xs-t-50 {
    padding-top: 50px;
  }

  .pd-xs-r-50 {
    padding-right: 50px;
  }

  .pd-xs-b-50 {
    padding-bottom: 50px;
  }

  .pd-xs-l-50 {
    padding-left: 50px;
  }

  .pd-xs-t-50-force {
    padding-top: 50px !important;
  }

  .pd-xs-r-50-force {
    padding-right: 50px !important;
  }

  .pd-xs-b-50-force {
    padding-bottom: 50px !important;
  }

  .pd-xs-l-50-force {
    padding-left: 50px !important;
  }

  .pd-xs-55 {
    padding: 55px;
  }

  .pd-xs-55-force {
    padding: 55px !important;
  }

  .pd-xs-y-55 {
    padding-top: 55px;
    padding-bottom: 55px;
  }

  .pd-xs-y-55-force {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }

  .pd-xs-x-55 {
    padding-left: 55px;
    padding-right: 55px;
  }

  .pd-xs-x-55-force {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }

  .pd-xs-t-55 {
    padding-top: 55px;
  }

  .pd-xs-r-55 {
    padding-right: 55px;
  }

  .pd-xs-b-55 {
    padding-bottom: 55px;
  }

  .pd-xs-l-55 {
    padding-left: 55px;
  }

  .pd-xs-t-55-force {
    padding-top: 55px !important;
  }

  .pd-xs-r-55-force {
    padding-right: 55px !important;
  }

  .pd-xs-b-55-force {
    padding-bottom: 55px !important;
  }

  .pd-xs-l-55-force {
    padding-left: 55px !important;
  }

  .pd-xs-60 {
    padding: 60px;
  }

  .pd-xs-60-force {
    padding: 60px !important;
  }

  .pd-xs-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .pd-xs-y-60-force {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  .pd-xs-x-60 {
    padding-left: 60px;
    padding-right: 60px;
  }

  .pd-xs-x-60-force {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .pd-xs-t-60 {
    padding-top: 60px;
  }

  .pd-xs-r-60 {
    padding-right: 60px;
  }

  .pd-xs-b-60 {
    padding-bottom: 60px;
  }

  .pd-xs-l-60 {
    padding-left: 60px;
  }

  .pd-xs-t-60-force {
    padding-top: 60px !important;
  }

  .pd-xs-r-60-force {
    padding-right: 60px !important;
  }

  .pd-xs-b-60-force {
    padding-bottom: 60px !important;
  }

  .pd-xs-l-60-force {
    padding-left: 60px !important;
  }

  .pd-xs-65 {
    padding: 65px;
  }

  .pd-xs-65-force {
    padding: 65px !important;
  }

  .pd-xs-y-65 {
    padding-top: 65px;
    padding-bottom: 65px;
  }

  .pd-xs-y-65-force {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }

  .pd-xs-x-65 {
    padding-left: 65px;
    padding-right: 65px;
  }

  .pd-xs-x-65-force {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }

  .pd-xs-t-65 {
    padding-top: 65px;
  }

  .pd-xs-r-65 {
    padding-right: 65px;
  }

  .pd-xs-b-65 {
    padding-bottom: 65px;
  }

  .pd-xs-l-65 {
    padding-left: 65px;
  }

  .pd-xs-t-65-force {
    padding-top: 65px !important;
  }

  .pd-xs-r-65-force {
    padding-right: 65px !important;
  }

  .pd-xs-b-65-force {
    padding-bottom: 65px !important;
  }

  .pd-xs-l-65-force {
    padding-left: 65px !important;
  }

  .pd-xs-70 {
    padding: 70px;
  }

  .pd-xs-70-force {
    padding: 70px !important;
  }

  .pd-xs-y-70 {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .pd-xs-y-70-force {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }

  .pd-xs-x-70 {
    padding-left: 70px;
    padding-right: 70px;
  }

  .pd-xs-x-70-force {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }

  .pd-xs-t-70 {
    padding-top: 70px;
  }

  .pd-xs-r-70 {
    padding-right: 70px;
  }

  .pd-xs-b-70 {
    padding-bottom: 70px;
  }

  .pd-xs-l-70 {
    padding-left: 70px;
  }

  .pd-xs-t-70-force {
    padding-top: 70px !important;
  }

  .pd-xs-r-70-force {
    padding-right: 70px !important;
  }

  .pd-xs-b-70-force {
    padding-bottom: 70px !important;
  }

  .pd-xs-l-70-force {
    padding-left: 70px !important;
  }

  .pd-xs-75 {
    padding: 75px;
  }

  .pd-xs-75-force {
    padding: 75px !important;
  }

  .pd-xs-y-75 {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .pd-xs-y-75-force {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }

  .pd-xs-x-75 {
    padding-left: 75px;
    padding-right: 75px;
  }

  .pd-xs-x-75-force {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }

  .pd-xs-t-75 {
    padding-top: 75px;
  }

  .pd-xs-r-75 {
    padding-right: 75px;
  }

  .pd-xs-b-75 {
    padding-bottom: 75px;
  }

  .pd-xs-l-75 {
    padding-left: 75px;
  }

  .pd-xs-t-75-force {
    padding-top: 75px !important;
  }

  .pd-xs-r-75-force {
    padding-right: 75px !important;
  }

  .pd-xs-b-75-force {
    padding-bottom: 75px !important;
  }

  .pd-xs-l-75-force {
    padding-left: 75px !important;
  }

  .pd-xs-80 {
    padding: 80px;
  }

  .pd-xs-80-force {
    padding: 80px !important;
  }

  .pd-xs-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .pd-xs-y-80-force {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }

  .pd-xs-x-80 {
    padding-left: 80px;
    padding-right: 80px;
  }

  .pd-xs-x-80-force {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }

  .pd-xs-t-80 {
    padding-top: 80px;
  }

  .pd-xs-r-80 {
    padding-right: 80px;
  }

  .pd-xs-b-80 {
    padding-bottom: 80px;
  }

  .pd-xs-l-80 {
    padding-left: 80px;
  }

  .pd-xs-t-80-force {
    padding-top: 80px !important;
  }

  .pd-xs-r-80-force {
    padding-right: 80px !important;
  }

  .pd-xs-b-80-force {
    padding-bottom: 80px !important;
  }

  .pd-xs-l-80-force {
    padding-left: 80px !important;
  }

  .pd-xs-85 {
    padding: 85px;
  }

  .pd-xs-85-force {
    padding: 85px !important;
  }

  .pd-xs-y-85 {
    padding-top: 85px;
    padding-bottom: 85px;
  }

  .pd-xs-y-85-force {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }

  .pd-xs-x-85 {
    padding-left: 85px;
    padding-right: 85px;
  }

  .pd-xs-x-85-force {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }

  .pd-xs-t-85 {
    padding-top: 85px;
  }

  .pd-xs-r-85 {
    padding-right: 85px;
  }

  .pd-xs-b-85 {
    padding-bottom: 85px;
  }

  .pd-xs-l-85 {
    padding-left: 85px;
  }

  .pd-xs-t-85-force {
    padding-top: 85px !important;
  }

  .pd-xs-r-85-force {
    padding-right: 85px !important;
  }

  .pd-xs-b-85-force {
    padding-bottom: 85px !important;
  }

  .pd-xs-l-85-force {
    padding-left: 85px !important;
  }

  .pd-xs-90 {
    padding: 90px;
  }

  .pd-xs-90-force {
    padding: 90px !important;
  }

  .pd-xs-y-90 {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .pd-xs-y-90-force {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }

  .pd-xs-x-90 {
    padding-left: 90px;
    padding-right: 90px;
  }

  .pd-xs-x-90-force {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }

  .pd-xs-t-90 {
    padding-top: 90px;
  }

  .pd-xs-r-90 {
    padding-right: 90px;
  }

  .pd-xs-b-90 {
    padding-bottom: 90px;
  }

  .pd-xs-l-90 {
    padding-left: 90px;
  }

  .pd-xs-t-90-force {
    padding-top: 90px !important;
  }

  .pd-xs-r-90-force {
    padding-right: 90px !important;
  }

  .pd-xs-b-90-force {
    padding-bottom: 90px !important;
  }

  .pd-xs-l-90-force {
    padding-left: 90px !important;
  }

  .pd-xs-95 {
    padding: 95px;
  }

  .pd-xs-95-force {
    padding: 95px !important;
  }

  .pd-xs-y-95 {
    padding-top: 95px;
    padding-bottom: 95px;
  }

  .pd-xs-y-95-force {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }

  .pd-xs-x-95 {
    padding-left: 95px;
    padding-right: 95px;
  }

  .pd-xs-x-95-force {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }

  .pd-xs-t-95 {
    padding-top: 95px;
  }

  .pd-xs-r-95 {
    padding-right: 95px;
  }

  .pd-xs-b-95 {
    padding-bottom: 95px;
  }

  .pd-xs-l-95 {
    padding-left: 95px;
  }

  .pd-xs-t-95-force {
    padding-top: 95px !important;
  }

  .pd-xs-r-95-force {
    padding-right: 95px !important;
  }

  .pd-xs-b-95-force {
    padding-bottom: 95px !important;
  }

  .pd-xs-l-95-force {
    padding-left: 95px !important;
  }

  .pd-xs-100 {
    padding: 100px;
  }

  .pd-xs-100-force {
    padding: 100px !important;
  }

  .pd-xs-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .pd-xs-y-100-force {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }

  .pd-xs-x-100 {
    padding-left: 100px;
    padding-right: 100px;
  }

  .pd-xs-x-100-force {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }

  .pd-xs-t-100 {
    padding-top: 100px;
  }

  .pd-xs-r-100 {
    padding-right: 100px;
  }

  .pd-xs-b-100 {
    padding-bottom: 100px;
  }

  .pd-xs-l-100 {
    padding-left: 100px;
  }

  .pd-xs-t-100-force {
    padding-top: 100px !important;
  }

  .pd-xs-r-100-force {
    padding-right: 100px !important;
  }

  .pd-xs-b-100-force {
    padding-bottom: 100px !important;
  }

  .pd-xs-l-100-force {
    padding-left: 100px !important;
  }

  .pd-xs-110 {
    padding: 110px;
  }

  .pd-xs-110-force {
    padding: 110px !important;
  }

  .pd-xs-y-110 {
    padding-top: 110px;
    padding-bottom: 110px;
  }

  .pd-xs-y-110-force {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }

  .pd-xs-x-110 {
    padding-left: 110px;
    padding-right: 110px;
  }

  .pd-xs-x-110-force {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }

  .pd-xs-t-110 {
    padding-top: 110px;
  }

  .pd-xs-r-110 {
    padding-right: 110px;
  }

  .pd-xs-b-110 {
    padding-bottom: 110px;
  }

  .pd-xs-l-110 {
    padding-left: 110px;
  }

  .pd-xs-t-110-force {
    padding-top: 110px !important;
  }

  .pd-xs-r-110-force {
    padding-right: 110px !important;
  }

  .pd-xs-b-110-force {
    padding-bottom: 110px !important;
  }

  .pd-xs-l-110-force {
    padding-left: 110px !important;
  }

  .pd-xs-120 {
    padding: 120px;
  }

  .pd-xs-120-force {
    padding: 120px !important;
  }

  .pd-xs-y-120 {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .pd-xs-y-120-force {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }

  .pd-xs-x-120 {
    padding-left: 120px;
    padding-right: 120px;
  }

  .pd-xs-x-120-force {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }

  .pd-xs-t-120 {
    padding-top: 120px;
  }

  .pd-xs-r-120 {
    padding-right: 120px;
  }

  .pd-xs-b-120 {
    padding-bottom: 120px;
  }

  .pd-xs-l-120 {
    padding-left: 120px;
  }

  .pd-xs-t-120-force {
    padding-top: 120px !important;
  }

  .pd-xs-r-120-force {
    padding-right: 120px !important;
  }

  .pd-xs-b-120-force {
    padding-bottom: 120px !important;
  }

  .pd-xs-l-120-force {
    padding-left: 120px !important;
  }

  .pd-xs-130 {
    padding: 130px;
  }

  .pd-xs-130-force {
    padding: 130px !important;
  }

  .pd-xs-y-130 {
    padding-top: 130px;
    padding-bottom: 130px;
  }

  .pd-xs-y-130-force {
    padding-top: 130px !important;
    padding-bottom: 130px !important;
  }

  .pd-xs-x-130 {
    padding-left: 130px;
    padding-right: 130px;
  }

  .pd-xs-x-130-force {
    padding-left: 130px !important;
    padding-right: 130px !important;
  }

  .pd-xs-t-130 {
    padding-top: 130px;
  }

  .pd-xs-r-130 {
    padding-right: 130px;
  }

  .pd-xs-b-130 {
    padding-bottom: 130px;
  }

  .pd-xs-l-130 {
    padding-left: 130px;
  }

  .pd-xs-t-130-force {
    padding-top: 130px !important;
  }

  .pd-xs-r-130-force {
    padding-right: 130px !important;
  }

  .pd-xs-b-130-force {
    padding-bottom: 130px !important;
  }

  .pd-xs-l-130-force {
    padding-left: 130px !important;
  }

  .pd-xs-140 {
    padding: 140px;
  }

  .pd-xs-140-force {
    padding: 140px !important;
  }

  .pd-xs-y-140 {
    padding-top: 140px;
    padding-bottom: 140px;
  }

  .pd-xs-y-140-force {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }

  .pd-xs-x-140 {
    padding-left: 140px;
    padding-right: 140px;
  }

  .pd-xs-x-140-force {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }

  .pd-xs-t-140 {
    padding-top: 140px;
  }

  .pd-xs-r-140 {
    padding-right: 140px;
  }

  .pd-xs-b-140 {
    padding-bottom: 140px;
  }

  .pd-xs-l-140 {
    padding-left: 140px;
  }

  .pd-xs-t-140-force {
    padding-top: 140px !important;
  }

  .pd-xs-r-140-force {
    padding-right: 140px !important;
  }

  .pd-xs-b-140-force {
    padding-bottom: 140px !important;
  }

  .pd-xs-l-140-force {
    padding-left: 140px !important;
  }

  .pd-xs-150 {
    padding: 150px;
  }

  .pd-xs-150-force {
    padding: 150px !important;
  }

  .pd-xs-y-150 {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .pd-xs-y-150-force {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }

  .pd-xs-x-150 {
    padding-left: 150px;
    padding-right: 150px;
  }

  .pd-xs-x-150-force {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }

  .pd-xs-t-150 {
    padding-top: 150px;
  }

  .pd-xs-r-150 {
    padding-right: 150px;
  }

  .pd-xs-b-150 {
    padding-bottom: 150px;
  }

  .pd-xs-l-150 {
    padding-left: 150px;
  }

  .pd-xs-t-150-force {
    padding-top: 150px !important;
  }

  .pd-xs-r-150-force {
    padding-right: 150px !important;
  }

  .pd-xs-b-150-force {
    padding-bottom: 150px !important;
  }

  .pd-xs-l-150-force {
    padding-left: 150px !important;
  }

  .pd-xs-160 {
    padding: 160px;
  }

  .pd-xs-160-force {
    padding: 160px !important;
  }

  .pd-xs-y-160 {
    padding-top: 160px;
    padding-bottom: 160px;
  }

  .pd-xs-y-160-force {
    padding-top: 160px !important;
    padding-bottom: 160px !important;
  }

  .pd-xs-x-160 {
    padding-left: 160px;
    padding-right: 160px;
  }

  .pd-xs-x-160-force {
    padding-left: 160px !important;
    padding-right: 160px !important;
  }

  .pd-xs-t-160 {
    padding-top: 160px;
  }

  .pd-xs-r-160 {
    padding-right: 160px;
  }

  .pd-xs-b-160 {
    padding-bottom: 160px;
  }

  .pd-xs-l-160 {
    padding-left: 160px;
  }

  .pd-xs-t-160-force {
    padding-top: 160px !important;
  }

  .pd-xs-r-160-force {
    padding-right: 160px !important;
  }

  .pd-xs-b-160-force {
    padding-bottom: 160px !important;
  }

  .pd-xs-l-160-force {
    padding-left: 160px !important;
  }

  .pd-xs-170 {
    padding: 170px;
  }

  .pd-xs-170-force {
    padding: 170px !important;
  }

  .pd-xs-y-170 {
    padding-top: 170px;
    padding-bottom: 170px;
  }

  .pd-xs-y-170-force {
    padding-top: 170px !important;
    padding-bottom: 170px !important;
  }

  .pd-xs-x-170 {
    padding-left: 170px;
    padding-right: 170px;
  }

  .pd-xs-x-170-force {
    padding-left: 170px !important;
    padding-right: 170px !important;
  }

  .pd-xs-t-170 {
    padding-top: 170px;
  }

  .pd-xs-r-170 {
    padding-right: 170px;
  }

  .pd-xs-b-170 {
    padding-bottom: 170px;
  }

  .pd-xs-l-170 {
    padding-left: 170px;
  }

  .pd-xs-t-170-force {
    padding-top: 170px !important;
  }

  .pd-xs-r-170-force {
    padding-right: 170px !important;
  }

  .pd-xs-b-170-force {
    padding-bottom: 170px !important;
  }

  .pd-xs-l-170-force {
    padding-left: 170px !important;
  }

  .pd-xs-180 {
    padding: 180px;
  }

  .pd-xs-180-force {
    padding: 180px !important;
  }

  .pd-xs-y-180 {
    padding-top: 180px;
    padding-bottom: 180px;
  }

  .pd-xs-y-180-force {
    padding-top: 180px !important;
    padding-bottom: 180px !important;
  }

  .pd-xs-x-180 {
    padding-left: 180px;
    padding-right: 180px;
  }

  .pd-xs-x-180-force {
    padding-left: 180px !important;
    padding-right: 180px !important;
  }

  .pd-xs-t-180 {
    padding-top: 180px;
  }

  .pd-xs-r-180 {
    padding-right: 180px;
  }

  .pd-xs-b-180 {
    padding-bottom: 180px;
  }

  .pd-xs-l-180 {
    padding-left: 180px;
  }

  .pd-xs-t-180-force {
    padding-top: 180px !important;
  }

  .pd-xs-r-180-force {
    padding-right: 180px !important;
  }

  .pd-xs-b-180-force {
    padding-bottom: 180px !important;
  }

  .pd-xs-l-180-force {
    padding-left: 180px !important;
  }

  .pd-xs-190 {
    padding: 190px;
  }

  .pd-xs-190-force {
    padding: 190px !important;
  }

  .pd-xs-y-190 {
    padding-top: 190px;
    padding-bottom: 190px;
  }

  .pd-xs-y-190-force {
    padding-top: 190px !important;
    padding-bottom: 190px !important;
  }

  .pd-xs-x-190 {
    padding-left: 190px;
    padding-right: 190px;
  }

  .pd-xs-x-190-force {
    padding-left: 190px !important;
    padding-right: 190px !important;
  }

  .pd-xs-t-190 {
    padding-top: 190px;
  }

  .pd-xs-r-190 {
    padding-right: 190px;
  }

  .pd-xs-b-190 {
    padding-bottom: 190px;
  }

  .pd-xs-l-190 {
    padding-left: 190px;
  }

  .pd-xs-t-190-force {
    padding-top: 190px !important;
  }

  .pd-xs-r-190-force {
    padding-right: 190px !important;
  }

  .pd-xs-b-190-force {
    padding-bottom: 190px !important;
  }

  .pd-xs-l-190-force {
    padding-left: 190px !important;
  }

  .pd-xs-200 {
    padding: 200px;
  }

  .pd-xs-200-force {
    padding: 200px !important;
  }

  .pd-xs-y-200 {
    padding-top: 200px;
    padding-bottom: 200px;
  }

  .pd-xs-y-200-force {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }

  .pd-xs-x-200 {
    padding-left: 200px;
    padding-right: 200px;
  }

  .pd-xs-x-200-force {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }

  .pd-xs-t-200 {
    padding-top: 200px;
  }

  .pd-xs-r-200 {
    padding-right: 200px;
  }

  .pd-xs-b-200 {
    padding-bottom: 200px;
  }

  .pd-xs-l-200 {
    padding-left: 200px;
  }

  .pd-xs-t-200-force {
    padding-top: 200px !important;
  }

  .pd-xs-r-200-force {
    padding-right: 200px !important;
  }

  .pd-xs-b-200-force {
    padding-bottom: 200px !important;
  }

  .pd-xs-l-200-force {
    padding-left: 200px !important;
  }
}
@media (min-width: 576px) {
  .pd-sm-0 {
    padding: 0px;
  }

  .pd-sm-0-force {
    padding: 0px !important;
  }

  .pd-sm-y-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .pd-sm-y-0-force {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .pd-sm-x-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .pd-sm-x-0-force {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .pd-sm-t-0 {
    padding-top: 0px;
  }

  .pd-sm-r-0 {
    padding-right: 0px;
  }

  .pd-sm-b-0 {
    padding-bottom: 0px;
  }

  .pd-sm-l-0 {
    padding-left: 0px;
  }

  .pd-sm-t-0-force {
    padding-top: 0px !important;
  }

  .pd-sm-r-0-force {
    padding-right: 0px !important;
  }

  .pd-sm-b-0-force {
    padding-bottom: 0px !important;
  }

  .pd-sm-l-0-force {
    padding-left: 0px !important;
  }

  .pd-sm-1 {
    padding: 1px;
  }

  .pd-sm-1-force {
    padding: 1px !important;
  }

  .pd-sm-y-1 {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .pd-sm-y-1-force {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .pd-sm-x-1 {
    padding-left: 1px;
    padding-right: 1px;
  }

  .pd-sm-x-1-force {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .pd-sm-t-1 {
    padding-top: 1px;
  }

  .pd-sm-r-1 {
    padding-right: 1px;
  }

  .pd-sm-b-1 {
    padding-bottom: 1px;
  }

  .pd-sm-l-1 {
    padding-left: 1px;
  }

  .pd-sm-t-1-force {
    padding-top: 1px !important;
  }

  .pd-sm-r-1-force {
    padding-right: 1px !important;
  }

  .pd-sm-b-1-force {
    padding-bottom: 1px !important;
  }

  .pd-sm-l-1-force {
    padding-left: 1px !important;
  }

  .pd-sm-2 {
    padding: 2px;
  }

  .pd-sm-2-force {
    padding: 2px !important;
  }

  .pd-sm-y-2 {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .pd-sm-y-2-force {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  .pd-sm-x-2 {
    padding-left: 2px;
    padding-right: 2px;
  }

  .pd-sm-x-2-force {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .pd-sm-t-2 {
    padding-top: 2px;
  }

  .pd-sm-r-2 {
    padding-right: 2px;
  }

  .pd-sm-b-2 {
    padding-bottom: 2px;
  }

  .pd-sm-l-2 {
    padding-left: 2px;
  }

  .pd-sm-t-2-force {
    padding-top: 2px !important;
  }

  .pd-sm-r-2-force {
    padding-right: 2px !important;
  }

  .pd-sm-b-2-force {
    padding-bottom: 2px !important;
  }

  .pd-sm-l-2-force {
    padding-left: 2px !important;
  }

  .pd-sm-3 {
    padding: 3px;
  }

  .pd-sm-3-force {
    padding: 3px !important;
  }

  .pd-sm-y-3 {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .pd-sm-y-3-force {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  .pd-sm-x-3 {
    padding-left: 3px;
    padding-right: 3px;
  }

  .pd-sm-x-3-force {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }

  .pd-sm-t-3 {
    padding-top: 3px;
  }

  .pd-sm-r-3 {
    padding-right: 3px;
  }

  .pd-sm-b-3 {
    padding-bottom: 3px;
  }

  .pd-sm-l-3 {
    padding-left: 3px;
  }

  .pd-sm-t-3-force {
    padding-top: 3px !important;
  }

  .pd-sm-r-3-force {
    padding-right: 3px !important;
  }

  .pd-sm-b-3-force {
    padding-bottom: 3px !important;
  }

  .pd-sm-l-3-force {
    padding-left: 3px !important;
  }

  .pd-sm-4 {
    padding: 4px;
  }

  .pd-sm-4-force {
    padding: 4px !important;
  }

  .pd-sm-y-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .pd-sm-y-4-force {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .pd-sm-x-4 {
    padding-left: 4px;
    padding-right: 4px;
  }

  .pd-sm-x-4-force {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .pd-sm-t-4 {
    padding-top: 4px;
  }

  .pd-sm-r-4 {
    padding-right: 4px;
  }

  .pd-sm-b-4 {
    padding-bottom: 4px;
  }

  .pd-sm-l-4 {
    padding-left: 4px;
  }

  .pd-sm-t-4-force {
    padding-top: 4px !important;
  }

  .pd-sm-r-4-force {
    padding-right: 4px !important;
  }

  .pd-sm-b-4-force {
    padding-bottom: 4px !important;
  }

  .pd-sm-l-4-force {
    padding-left: 4px !important;
  }

  .pd-sm-5 {
    padding: 5px;
  }

  .pd-sm-5-force {
    padding: 5px !important;
  }

  .pd-sm-y-5 {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .pd-sm-y-5-force {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .pd-sm-x-5 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .pd-sm-x-5-force {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .pd-sm-t-5 {
    padding-top: 5px;
  }

  .pd-sm-r-5 {
    padding-right: 5px;
  }

  .pd-sm-b-5 {
    padding-bottom: 5px;
  }

  .pd-sm-l-5 {
    padding-left: 5px;
  }

  .pd-sm-t-5-force {
    padding-top: 5px !important;
  }

  .pd-sm-r-5-force {
    padding-right: 5px !important;
  }

  .pd-sm-b-5-force {
    padding-bottom: 5px !important;
  }

  .pd-sm-l-5-force {
    padding-left: 5px !important;
  }

  .pd-sm-6 {
    padding: 6px;
  }

  .pd-sm-6-force {
    padding: 6px !important;
  }

  .pd-sm-y-6 {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .pd-sm-y-6-force {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }

  .pd-sm-x-6 {
    padding-left: 6px;
    padding-right: 6px;
  }

  .pd-sm-x-6-force {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  .pd-sm-t-6 {
    padding-top: 6px;
  }

  .pd-sm-r-6 {
    padding-right: 6px;
  }

  .pd-sm-b-6 {
    padding-bottom: 6px;
  }

  .pd-sm-l-6 {
    padding-left: 6px;
  }

  .pd-sm-t-6-force {
    padding-top: 6px !important;
  }

  .pd-sm-r-6-force {
    padding-right: 6px !important;
  }

  .pd-sm-b-6-force {
    padding-bottom: 6px !important;
  }

  .pd-sm-l-6-force {
    padding-left: 6px !important;
  }

  .pd-sm-7 {
    padding: 7px;
  }

  .pd-sm-7-force {
    padding: 7px !important;
  }

  .pd-sm-y-7 {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .pd-sm-y-7-force {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
  }

  .pd-sm-x-7 {
    padding-left: 7px;
    padding-right: 7px;
  }

  .pd-sm-x-7-force {
    padding-left: 7px !important;
    padding-right: 7px !important;
  }

  .pd-sm-t-7 {
    padding-top: 7px;
  }

  .pd-sm-r-7 {
    padding-right: 7px;
  }

  .pd-sm-b-7 {
    padding-bottom: 7px;
  }

  .pd-sm-l-7 {
    padding-left: 7px;
  }

  .pd-sm-t-7-force {
    padding-top: 7px !important;
  }

  .pd-sm-r-7-force {
    padding-right: 7px !important;
  }

  .pd-sm-b-7-force {
    padding-bottom: 7px !important;
  }

  .pd-sm-l-7-force {
    padding-left: 7px !important;
  }

  .pd-sm-8 {
    padding: 8px;
  }

  .pd-sm-8-force {
    padding: 8px !important;
  }

  .pd-sm-y-8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .pd-sm-y-8-force {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .pd-sm-x-8 {
    padding-left: 8px;
    padding-right: 8px;
  }

  .pd-sm-x-8-force {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .pd-sm-t-8 {
    padding-top: 8px;
  }

  .pd-sm-r-8 {
    padding-right: 8px;
  }

  .pd-sm-b-8 {
    padding-bottom: 8px;
  }

  .pd-sm-l-8 {
    padding-left: 8px;
  }

  .pd-sm-t-8-force {
    padding-top: 8px !important;
  }

  .pd-sm-r-8-force {
    padding-right: 8px !important;
  }

  .pd-sm-b-8-force {
    padding-bottom: 8px !important;
  }

  .pd-sm-l-8-force {
    padding-left: 8px !important;
  }

  .pd-sm-9 {
    padding: 9px;
  }

  .pd-sm-9-force {
    padding: 9px !important;
  }

  .pd-sm-y-9 {
    padding-top: 9px;
    padding-bottom: 9px;
  }

  .pd-sm-y-9-force {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
  }

  .pd-sm-x-9 {
    padding-left: 9px;
    padding-right: 9px;
  }

  .pd-sm-x-9-force {
    padding-left: 9px !important;
    padding-right: 9px !important;
  }

  .pd-sm-t-9 {
    padding-top: 9px;
  }

  .pd-sm-r-9 {
    padding-right: 9px;
  }

  .pd-sm-b-9 {
    padding-bottom: 9px;
  }

  .pd-sm-l-9 {
    padding-left: 9px;
  }

  .pd-sm-t-9-force {
    padding-top: 9px !important;
  }

  .pd-sm-r-9-force {
    padding-right: 9px !important;
  }

  .pd-sm-b-9-force {
    padding-bottom: 9px !important;
  }

  .pd-sm-l-9-force {
    padding-left: 9px !important;
  }

  .pd-sm-10 {
    padding: 10px;
  }

  .pd-sm-10-force {
    padding: 10px !important;
  }

  .pd-sm-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .pd-sm-y-10-force {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .pd-sm-x-10 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .pd-sm-x-10-force {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .pd-sm-t-10 {
    padding-top: 10px;
  }

  .pd-sm-r-10 {
    padding-right: 10px;
  }

  .pd-sm-b-10 {
    padding-bottom: 10px;
  }

  .pd-sm-l-10 {
    padding-left: 10px;
  }

  .pd-sm-t-10-force {
    padding-top: 10px !important;
  }

  .pd-sm-r-10-force {
    padding-right: 10px !important;
  }

  .pd-sm-b-10-force {
    padding-bottom: 10px !important;
  }

  .pd-sm-l-10-force {
    padding-left: 10px !important;
  }

  .pd-sm-15 {
    padding: 15px;
  }

  .pd-sm-15-force {
    padding: 15px !important;
  }

  .pd-sm-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .pd-sm-y-15-force {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .pd-sm-x-15 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .pd-sm-x-15-force {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .pd-sm-t-15 {
    padding-top: 15px;
  }

  .pd-sm-r-15 {
    padding-right: 15px;
  }

  .pd-sm-b-15 {
    padding-bottom: 15px;
  }

  .pd-sm-l-15 {
    padding-left: 15px;
  }

  .pd-sm-t-15-force {
    padding-top: 15px !important;
  }

  .pd-sm-r-15-force {
    padding-right: 15px !important;
  }

  .pd-sm-b-15-force {
    padding-bottom: 15px !important;
  }

  .pd-sm-l-15-force {
    padding-left: 15px !important;
  }

  .pd-sm-20 {
    padding: 20px;
  }

  .pd-sm-20-force {
    padding: 20px !important;
  }

  .pd-sm-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .pd-sm-y-20-force {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .pd-sm-x-20 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .pd-sm-x-20-force {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .pd-sm-t-20 {
    padding-top: 20px;
  }

  .pd-sm-r-20 {
    padding-right: 20px;
  }

  .pd-sm-b-20 {
    padding-bottom: 20px;
  }

  .pd-sm-l-20 {
    padding-left: 20px;
  }

  .pd-sm-t-20-force {
    padding-top: 20px !important;
  }

  .pd-sm-r-20-force {
    padding-right: 20px !important;
  }

  .pd-sm-b-20-force {
    padding-bottom: 20px !important;
  }

  .pd-sm-l-20-force {
    padding-left: 20px !important;
  }

  .pd-sm-25 {
    padding: 25px;
  }

  .pd-sm-25-force {
    padding: 25px !important;
  }

  .pd-sm-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .pd-sm-y-25-force {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .pd-sm-x-25 {
    padding-left: 25px;
    padding-right: 25px;
  }

  .pd-sm-x-25-force {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .pd-sm-t-25 {
    padding-top: 25px;
  }

  .pd-sm-r-25 {
    padding-right: 25px;
  }

  .pd-sm-b-25 {
    padding-bottom: 25px;
  }

  .pd-sm-l-25 {
    padding-left: 25px;
  }

  .pd-sm-t-25-force {
    padding-top: 25px !important;
  }

  .pd-sm-r-25-force {
    padding-right: 25px !important;
  }

  .pd-sm-b-25-force {
    padding-bottom: 25px !important;
  }

  .pd-sm-l-25-force {
    padding-left: 25px !important;
  }

  .pd-sm-30 {
    padding: 30px;
  }

  .pd-sm-30-force {
    padding: 30px !important;
  }

  .pd-sm-y-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .pd-sm-y-30-force {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .pd-sm-x-30 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .pd-sm-x-30-force {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .pd-sm-t-30 {
    padding-top: 30px;
  }

  .pd-sm-r-30 {
    padding-right: 30px;
  }

  .pd-sm-b-30 {
    padding-bottom: 30px;
  }

  .pd-sm-l-30 {
    padding-left: 30px;
  }

  .pd-sm-t-30-force {
    padding-top: 30px !important;
  }

  .pd-sm-r-30-force {
    padding-right: 30px !important;
  }

  .pd-sm-b-30-force {
    padding-bottom: 30px !important;
  }

  .pd-sm-l-30-force {
    padding-left: 30px !important;
  }

  .pd-sm-35 {
    padding: 35px;
  }

  .pd-sm-35-force {
    padding: 35px !important;
  }

  .pd-sm-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .pd-sm-y-35-force {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }

  .pd-sm-x-35 {
    padding-left: 35px;
    padding-right: 35px;
  }

  .pd-sm-x-35-force {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }

  .pd-sm-t-35 {
    padding-top: 35px;
  }

  .pd-sm-r-35 {
    padding-right: 35px;
  }

  .pd-sm-b-35 {
    padding-bottom: 35px;
  }

  .pd-sm-l-35 {
    padding-left: 35px;
  }

  .pd-sm-t-35-force {
    padding-top: 35px !important;
  }

  .pd-sm-r-35-force {
    padding-right: 35px !important;
  }

  .pd-sm-b-35-force {
    padding-bottom: 35px !important;
  }

  .pd-sm-l-35-force {
    padding-left: 35px !important;
  }

  .pd-sm-40 {
    padding: 40px;
  }

  .pd-sm-40-force {
    padding: 40px !important;
  }

  .pd-sm-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .pd-sm-y-40-force {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .pd-sm-x-40 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .pd-sm-x-40-force {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .pd-sm-t-40 {
    padding-top: 40px;
  }

  .pd-sm-r-40 {
    padding-right: 40px;
  }

  .pd-sm-b-40 {
    padding-bottom: 40px;
  }

  .pd-sm-l-40 {
    padding-left: 40px;
  }

  .pd-sm-t-40-force {
    padding-top: 40px !important;
  }

  .pd-sm-r-40-force {
    padding-right: 40px !important;
  }

  .pd-sm-b-40-force {
    padding-bottom: 40px !important;
  }

  .pd-sm-l-40-force {
    padding-left: 40px !important;
  }

  .pd-sm-45 {
    padding: 45px;
  }

  .pd-sm-45-force {
    padding: 45px !important;
  }

  .pd-sm-y-45 {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .pd-sm-y-45-force {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }

  .pd-sm-x-45 {
    padding-left: 45px;
    padding-right: 45px;
  }

  .pd-sm-x-45-force {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }

  .pd-sm-t-45 {
    padding-top: 45px;
  }

  .pd-sm-r-45 {
    padding-right: 45px;
  }

  .pd-sm-b-45 {
    padding-bottom: 45px;
  }

  .pd-sm-l-45 {
    padding-left: 45px;
  }

  .pd-sm-t-45-force {
    padding-top: 45px !important;
  }

  .pd-sm-r-45-force {
    padding-right: 45px !important;
  }

  .pd-sm-b-45-force {
    padding-bottom: 45px !important;
  }

  .pd-sm-l-45-force {
    padding-left: 45px !important;
  }

  .pd-sm-50 {
    padding: 50px;
  }

  .pd-sm-50-force {
    padding: 50px !important;
  }

  .pd-sm-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .pd-sm-y-50-force {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .pd-sm-x-50 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .pd-sm-x-50-force {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .pd-sm-t-50 {
    padding-top: 50px;
  }

  .pd-sm-r-50 {
    padding-right: 50px;
  }

  .pd-sm-b-50 {
    padding-bottom: 50px;
  }

  .pd-sm-l-50 {
    padding-left: 50px;
  }

  .pd-sm-t-50-force {
    padding-top: 50px !important;
  }

  .pd-sm-r-50-force {
    padding-right: 50px !important;
  }

  .pd-sm-b-50-force {
    padding-bottom: 50px !important;
  }

  .pd-sm-l-50-force {
    padding-left: 50px !important;
  }

  .pd-sm-55 {
    padding: 55px;
  }

  .pd-sm-55-force {
    padding: 55px !important;
  }

  .pd-sm-y-55 {
    padding-top: 55px;
    padding-bottom: 55px;
  }

  .pd-sm-y-55-force {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }

  .pd-sm-x-55 {
    padding-left: 55px;
    padding-right: 55px;
  }

  .pd-sm-x-55-force {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }

  .pd-sm-t-55 {
    padding-top: 55px;
  }

  .pd-sm-r-55 {
    padding-right: 55px;
  }

  .pd-sm-b-55 {
    padding-bottom: 55px;
  }

  .pd-sm-l-55 {
    padding-left: 55px;
  }

  .pd-sm-t-55-force {
    padding-top: 55px !important;
  }

  .pd-sm-r-55-force {
    padding-right: 55px !important;
  }

  .pd-sm-b-55-force {
    padding-bottom: 55px !important;
  }

  .pd-sm-l-55-force {
    padding-left: 55px !important;
  }

  .pd-sm-60 {
    padding: 60px;
  }

  .pd-sm-60-force {
    padding: 60px !important;
  }

  .pd-sm-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .pd-sm-y-60-force {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  .pd-sm-x-60 {
    padding-left: 60px;
    padding-right: 60px;
  }

  .pd-sm-x-60-force {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .pd-sm-t-60 {
    padding-top: 60px;
  }

  .pd-sm-r-60 {
    padding-right: 60px;
  }

  .pd-sm-b-60 {
    padding-bottom: 60px;
  }

  .pd-sm-l-60 {
    padding-left: 60px;
  }

  .pd-sm-t-60-force {
    padding-top: 60px !important;
  }

  .pd-sm-r-60-force {
    padding-right: 60px !important;
  }

  .pd-sm-b-60-force {
    padding-bottom: 60px !important;
  }

  .pd-sm-l-60-force {
    padding-left: 60px !important;
  }

  .pd-sm-65 {
    padding: 65px;
  }

  .pd-sm-65-force {
    padding: 65px !important;
  }

  .pd-sm-y-65 {
    padding-top: 65px;
    padding-bottom: 65px;
  }

  .pd-sm-y-65-force {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }

  .pd-sm-x-65 {
    padding-left: 65px;
    padding-right: 65px;
  }

  .pd-sm-x-65-force {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }

  .pd-sm-t-65 {
    padding-top: 65px;
  }

  .pd-sm-r-65 {
    padding-right: 65px;
  }

  .pd-sm-b-65 {
    padding-bottom: 65px;
  }

  .pd-sm-l-65 {
    padding-left: 65px;
  }

  .pd-sm-t-65-force {
    padding-top: 65px !important;
  }

  .pd-sm-r-65-force {
    padding-right: 65px !important;
  }

  .pd-sm-b-65-force {
    padding-bottom: 65px !important;
  }

  .pd-sm-l-65-force {
    padding-left: 65px !important;
  }

  .pd-sm-70 {
    padding: 70px;
  }

  .pd-sm-70-force {
    padding: 70px !important;
  }

  .pd-sm-y-70 {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .pd-sm-y-70-force {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }

  .pd-sm-x-70 {
    padding-left: 70px;
    padding-right: 70px;
  }

  .pd-sm-x-70-force {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }

  .pd-sm-t-70 {
    padding-top: 70px;
  }

  .pd-sm-r-70 {
    padding-right: 70px;
  }

  .pd-sm-b-70 {
    padding-bottom: 70px;
  }

  .pd-sm-l-70 {
    padding-left: 70px;
  }

  .pd-sm-t-70-force {
    padding-top: 70px !important;
  }

  .pd-sm-r-70-force {
    padding-right: 70px !important;
  }

  .pd-sm-b-70-force {
    padding-bottom: 70px !important;
  }

  .pd-sm-l-70-force {
    padding-left: 70px !important;
  }

  .pd-sm-75 {
    padding: 75px;
  }

  .pd-sm-75-force {
    padding: 75px !important;
  }

  .pd-sm-y-75 {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .pd-sm-y-75-force {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }

  .pd-sm-x-75 {
    padding-left: 75px;
    padding-right: 75px;
  }

  .pd-sm-x-75-force {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }

  .pd-sm-t-75 {
    padding-top: 75px;
  }

  .pd-sm-r-75 {
    padding-right: 75px;
  }

  .pd-sm-b-75 {
    padding-bottom: 75px;
  }

  .pd-sm-l-75 {
    padding-left: 75px;
  }

  .pd-sm-t-75-force {
    padding-top: 75px !important;
  }

  .pd-sm-r-75-force {
    padding-right: 75px !important;
  }

  .pd-sm-b-75-force {
    padding-bottom: 75px !important;
  }

  .pd-sm-l-75-force {
    padding-left: 75px !important;
  }

  .pd-sm-80 {
    padding: 80px;
  }

  .pd-sm-80-force {
    padding: 80px !important;
  }

  .pd-sm-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .pd-sm-y-80-force {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }

  .pd-sm-x-80 {
    padding-left: 80px;
    padding-right: 80px;
  }

  .pd-sm-x-80-force {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }

  .pd-sm-t-80 {
    padding-top: 80px;
  }

  .pd-sm-r-80 {
    padding-right: 80px;
  }

  .pd-sm-b-80 {
    padding-bottom: 80px;
  }

  .pd-sm-l-80 {
    padding-left: 80px;
  }

  .pd-sm-t-80-force {
    padding-top: 80px !important;
  }

  .pd-sm-r-80-force {
    padding-right: 80px !important;
  }

  .pd-sm-b-80-force {
    padding-bottom: 80px !important;
  }

  .pd-sm-l-80-force {
    padding-left: 80px !important;
  }

  .pd-sm-85 {
    padding: 85px;
  }

  .pd-sm-85-force {
    padding: 85px !important;
  }

  .pd-sm-y-85 {
    padding-top: 85px;
    padding-bottom: 85px;
  }

  .pd-sm-y-85-force {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }

  .pd-sm-x-85 {
    padding-left: 85px;
    padding-right: 85px;
  }

  .pd-sm-x-85-force {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }

  .pd-sm-t-85 {
    padding-top: 85px;
  }

  .pd-sm-r-85 {
    padding-right: 85px;
  }

  .pd-sm-b-85 {
    padding-bottom: 85px;
  }

  .pd-sm-l-85 {
    padding-left: 85px;
  }

  .pd-sm-t-85-force {
    padding-top: 85px !important;
  }

  .pd-sm-r-85-force {
    padding-right: 85px !important;
  }

  .pd-sm-b-85-force {
    padding-bottom: 85px !important;
  }

  .pd-sm-l-85-force {
    padding-left: 85px !important;
  }

  .pd-sm-90 {
    padding: 90px;
  }

  .pd-sm-90-force {
    padding: 90px !important;
  }

  .pd-sm-y-90 {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .pd-sm-y-90-force {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }

  .pd-sm-x-90 {
    padding-left: 90px;
    padding-right: 90px;
  }

  .pd-sm-x-90-force {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }

  .pd-sm-t-90 {
    padding-top: 90px;
  }

  .pd-sm-r-90 {
    padding-right: 90px;
  }

  .pd-sm-b-90 {
    padding-bottom: 90px;
  }

  .pd-sm-l-90 {
    padding-left: 90px;
  }

  .pd-sm-t-90-force {
    padding-top: 90px !important;
  }

  .pd-sm-r-90-force {
    padding-right: 90px !important;
  }

  .pd-sm-b-90-force {
    padding-bottom: 90px !important;
  }

  .pd-sm-l-90-force {
    padding-left: 90px !important;
  }

  .pd-sm-95 {
    padding: 95px;
  }

  .pd-sm-95-force {
    padding: 95px !important;
  }

  .pd-sm-y-95 {
    padding-top: 95px;
    padding-bottom: 95px;
  }

  .pd-sm-y-95-force {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }

  .pd-sm-x-95 {
    padding-left: 95px;
    padding-right: 95px;
  }

  .pd-sm-x-95-force {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }

  .pd-sm-t-95 {
    padding-top: 95px;
  }

  .pd-sm-r-95 {
    padding-right: 95px;
  }

  .pd-sm-b-95 {
    padding-bottom: 95px;
  }

  .pd-sm-l-95 {
    padding-left: 95px;
  }

  .pd-sm-t-95-force {
    padding-top: 95px !important;
  }

  .pd-sm-r-95-force {
    padding-right: 95px !important;
  }

  .pd-sm-b-95-force {
    padding-bottom: 95px !important;
  }

  .pd-sm-l-95-force {
    padding-left: 95px !important;
  }

  .pd-sm-100 {
    padding: 100px;
  }

  .pd-sm-100-force {
    padding: 100px !important;
  }

  .pd-sm-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .pd-sm-y-100-force {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }

  .pd-sm-x-100 {
    padding-left: 100px;
    padding-right: 100px;
  }

  .pd-sm-x-100-force {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }

  .pd-sm-t-100 {
    padding-top: 100px;
  }

  .pd-sm-r-100 {
    padding-right: 100px;
  }

  .pd-sm-b-100 {
    padding-bottom: 100px;
  }

  .pd-sm-l-100 {
    padding-left: 100px;
  }

  .pd-sm-t-100-force {
    padding-top: 100px !important;
  }

  .pd-sm-r-100-force {
    padding-right: 100px !important;
  }

  .pd-sm-b-100-force {
    padding-bottom: 100px !important;
  }

  .pd-sm-l-100-force {
    padding-left: 100px !important;
  }

  .pd-sm-110 {
    padding: 110px;
  }

  .pd-sm-110-force {
    padding: 110px !important;
  }

  .pd-sm-y-110 {
    padding-top: 110px;
    padding-bottom: 110px;
  }

  .pd-sm-y-110-force {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }

  .pd-sm-x-110 {
    padding-left: 110px;
    padding-right: 110px;
  }

  .pd-sm-x-110-force {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }

  .pd-sm-t-110 {
    padding-top: 110px;
  }

  .pd-sm-r-110 {
    padding-right: 110px;
  }

  .pd-sm-b-110 {
    padding-bottom: 110px;
  }

  .pd-sm-l-110 {
    padding-left: 110px;
  }

  .pd-sm-t-110-force {
    padding-top: 110px !important;
  }

  .pd-sm-r-110-force {
    padding-right: 110px !important;
  }

  .pd-sm-b-110-force {
    padding-bottom: 110px !important;
  }

  .pd-sm-l-110-force {
    padding-left: 110px !important;
  }

  .pd-sm-120 {
    padding: 120px;
  }

  .pd-sm-120-force {
    padding: 120px !important;
  }

  .pd-sm-y-120 {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .pd-sm-y-120-force {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }

  .pd-sm-x-120 {
    padding-left: 120px;
    padding-right: 120px;
  }

  .pd-sm-x-120-force {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }

  .pd-sm-t-120 {
    padding-top: 120px;
  }

  .pd-sm-r-120 {
    padding-right: 120px;
  }

  .pd-sm-b-120 {
    padding-bottom: 120px;
  }

  .pd-sm-l-120 {
    padding-left: 120px;
  }

  .pd-sm-t-120-force {
    padding-top: 120px !important;
  }

  .pd-sm-r-120-force {
    padding-right: 120px !important;
  }

  .pd-sm-b-120-force {
    padding-bottom: 120px !important;
  }

  .pd-sm-l-120-force {
    padding-left: 120px !important;
  }

  .pd-sm-130 {
    padding: 130px;
  }

  .pd-sm-130-force {
    padding: 130px !important;
  }

  .pd-sm-y-130 {
    padding-top: 130px;
    padding-bottom: 130px;
  }

  .pd-sm-y-130-force {
    padding-top: 130px !important;
    padding-bottom: 130px !important;
  }

  .pd-sm-x-130 {
    padding-left: 130px;
    padding-right: 130px;
  }

  .pd-sm-x-130-force {
    padding-left: 130px !important;
    padding-right: 130px !important;
  }

  .pd-sm-t-130 {
    padding-top: 130px;
  }

  .pd-sm-r-130 {
    padding-right: 130px;
  }

  .pd-sm-b-130 {
    padding-bottom: 130px;
  }

  .pd-sm-l-130 {
    padding-left: 130px;
  }

  .pd-sm-t-130-force {
    padding-top: 130px !important;
  }

  .pd-sm-r-130-force {
    padding-right: 130px !important;
  }

  .pd-sm-b-130-force {
    padding-bottom: 130px !important;
  }

  .pd-sm-l-130-force {
    padding-left: 130px !important;
  }

  .pd-sm-140 {
    padding: 140px;
  }

  .pd-sm-140-force {
    padding: 140px !important;
  }

  .pd-sm-y-140 {
    padding-top: 140px;
    padding-bottom: 140px;
  }

  .pd-sm-y-140-force {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }

  .pd-sm-x-140 {
    padding-left: 140px;
    padding-right: 140px;
  }

  .pd-sm-x-140-force {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }

  .pd-sm-t-140 {
    padding-top: 140px;
  }

  .pd-sm-r-140 {
    padding-right: 140px;
  }

  .pd-sm-b-140 {
    padding-bottom: 140px;
  }

  .pd-sm-l-140 {
    padding-left: 140px;
  }

  .pd-sm-t-140-force {
    padding-top: 140px !important;
  }

  .pd-sm-r-140-force {
    padding-right: 140px !important;
  }

  .pd-sm-b-140-force {
    padding-bottom: 140px !important;
  }

  .pd-sm-l-140-force {
    padding-left: 140px !important;
  }

  .pd-sm-150 {
    padding: 150px;
  }

  .pd-sm-150-force {
    padding: 150px !important;
  }

  .pd-sm-y-150 {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .pd-sm-y-150-force {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }

  .pd-sm-x-150 {
    padding-left: 150px;
    padding-right: 150px;
  }

  .pd-sm-x-150-force {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }

  .pd-sm-t-150 {
    padding-top: 150px;
  }

  .pd-sm-r-150 {
    padding-right: 150px;
  }

  .pd-sm-b-150 {
    padding-bottom: 150px;
  }

  .pd-sm-l-150 {
    padding-left: 150px;
  }

  .pd-sm-t-150-force {
    padding-top: 150px !important;
  }

  .pd-sm-r-150-force {
    padding-right: 150px !important;
  }

  .pd-sm-b-150-force {
    padding-bottom: 150px !important;
  }

  .pd-sm-l-150-force {
    padding-left: 150px !important;
  }

  .pd-sm-160 {
    padding: 160px;
  }

  .pd-sm-160-force {
    padding: 160px !important;
  }

  .pd-sm-y-160 {
    padding-top: 160px;
    padding-bottom: 160px;
  }

  .pd-sm-y-160-force {
    padding-top: 160px !important;
    padding-bottom: 160px !important;
  }

  .pd-sm-x-160 {
    padding-left: 160px;
    padding-right: 160px;
  }

  .pd-sm-x-160-force {
    padding-left: 160px !important;
    padding-right: 160px !important;
  }

  .pd-sm-t-160 {
    padding-top: 160px;
  }

  .pd-sm-r-160 {
    padding-right: 160px;
  }

  .pd-sm-b-160 {
    padding-bottom: 160px;
  }

  .pd-sm-l-160 {
    padding-left: 160px;
  }

  .pd-sm-t-160-force {
    padding-top: 160px !important;
  }

  .pd-sm-r-160-force {
    padding-right: 160px !important;
  }

  .pd-sm-b-160-force {
    padding-bottom: 160px !important;
  }

  .pd-sm-l-160-force {
    padding-left: 160px !important;
  }

  .pd-sm-170 {
    padding: 170px;
  }

  .pd-sm-170-force {
    padding: 170px !important;
  }

  .pd-sm-y-170 {
    padding-top: 170px;
    padding-bottom: 170px;
  }

  .pd-sm-y-170-force {
    padding-top: 170px !important;
    padding-bottom: 170px !important;
  }

  .pd-sm-x-170 {
    padding-left: 170px;
    padding-right: 170px;
  }

  .pd-sm-x-170-force {
    padding-left: 170px !important;
    padding-right: 170px !important;
  }

  .pd-sm-t-170 {
    padding-top: 170px;
  }

  .pd-sm-r-170 {
    padding-right: 170px;
  }

  .pd-sm-b-170 {
    padding-bottom: 170px;
  }

  .pd-sm-l-170 {
    padding-left: 170px;
  }

  .pd-sm-t-170-force {
    padding-top: 170px !important;
  }

  .pd-sm-r-170-force {
    padding-right: 170px !important;
  }

  .pd-sm-b-170-force {
    padding-bottom: 170px !important;
  }

  .pd-sm-l-170-force {
    padding-left: 170px !important;
  }

  .pd-sm-180 {
    padding: 180px;
  }

  .pd-sm-180-force {
    padding: 180px !important;
  }

  .pd-sm-y-180 {
    padding-top: 180px;
    padding-bottom: 180px;
  }

  .pd-sm-y-180-force {
    padding-top: 180px !important;
    padding-bottom: 180px !important;
  }

  .pd-sm-x-180 {
    padding-left: 180px;
    padding-right: 180px;
  }

  .pd-sm-x-180-force {
    padding-left: 180px !important;
    padding-right: 180px !important;
  }

  .pd-sm-t-180 {
    padding-top: 180px;
  }

  .pd-sm-r-180 {
    padding-right: 180px;
  }

  .pd-sm-b-180 {
    padding-bottom: 180px;
  }

  .pd-sm-l-180 {
    padding-left: 180px;
  }

  .pd-sm-t-180-force {
    padding-top: 180px !important;
  }

  .pd-sm-r-180-force {
    padding-right: 180px !important;
  }

  .pd-sm-b-180-force {
    padding-bottom: 180px !important;
  }

  .pd-sm-l-180-force {
    padding-left: 180px !important;
  }

  .pd-sm-190 {
    padding: 190px;
  }

  .pd-sm-190-force {
    padding: 190px !important;
  }

  .pd-sm-y-190 {
    padding-top: 190px;
    padding-bottom: 190px;
  }

  .pd-sm-y-190-force {
    padding-top: 190px !important;
    padding-bottom: 190px !important;
  }

  .pd-sm-x-190 {
    padding-left: 190px;
    padding-right: 190px;
  }

  .pd-sm-x-190-force {
    padding-left: 190px !important;
    padding-right: 190px !important;
  }

  .pd-sm-t-190 {
    padding-top: 190px;
  }

  .pd-sm-r-190 {
    padding-right: 190px;
  }

  .pd-sm-b-190 {
    padding-bottom: 190px;
  }

  .pd-sm-l-190 {
    padding-left: 190px;
  }

  .pd-sm-t-190-force {
    padding-top: 190px !important;
  }

  .pd-sm-r-190-force {
    padding-right: 190px !important;
  }

  .pd-sm-b-190-force {
    padding-bottom: 190px !important;
  }

  .pd-sm-l-190-force {
    padding-left: 190px !important;
  }

  .pd-sm-200 {
    padding: 200px;
  }

  .pd-sm-200-force {
    padding: 200px !important;
  }

  .pd-sm-y-200 {
    padding-top: 200px;
    padding-bottom: 200px;
  }

  .pd-sm-y-200-force {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }

  .pd-sm-x-200 {
    padding-left: 200px;
    padding-right: 200px;
  }

  .pd-sm-x-200-force {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }

  .pd-sm-t-200 {
    padding-top: 200px;
  }

  .pd-sm-r-200 {
    padding-right: 200px;
  }

  .pd-sm-b-200 {
    padding-bottom: 200px;
  }

  .pd-sm-l-200 {
    padding-left: 200px;
  }

  .pd-sm-t-200-force {
    padding-top: 200px !important;
  }

  .pd-sm-r-200-force {
    padding-right: 200px !important;
  }

  .pd-sm-b-200-force {
    padding-bottom: 200px !important;
  }

  .pd-sm-l-200-force {
    padding-left: 200px !important;
  }
}
@media (min-width: 768px) {
  .pd-md-0 {
    padding: 0px;
  }

  .pd-md-0-force {
    padding: 0px !important;
  }

  .pd-md-y-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .pd-md-y-0-force {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .pd-md-x-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .pd-md-x-0-force {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .pd-md-t-0 {
    padding-top: 0px;
  }

  .pd-md-r-0 {
    padding-right: 0px;
  }

  .pd-md-b-0 {
    padding-bottom: 0px;
  }

  .pd-md-l-0 {
    padding-left: 0px;
  }

  .pd-md-t-0-force {
    padding-top: 0px !important;
  }

  .pd-md-r-0-force {
    padding-right: 0px !important;
  }

  .pd-md-b-0-force {
    padding-bottom: 0px !important;
  }

  .pd-md-l-0-force {
    padding-left: 0px !important;
  }

  .pd-md-1 {
    padding: 1px;
  }

  .pd-md-1-force {
    padding: 1px !important;
  }

  .pd-md-y-1 {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .pd-md-y-1-force {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .pd-md-x-1 {
    padding-left: 1px;
    padding-right: 1px;
  }

  .pd-md-x-1-force {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .pd-md-t-1 {
    padding-top: 1px;
  }

  .pd-md-r-1 {
    padding-right: 1px;
  }

  .pd-md-b-1 {
    padding-bottom: 1px;
  }

  .pd-md-l-1 {
    padding-left: 1px;
  }

  .pd-md-t-1-force {
    padding-top: 1px !important;
  }

  .pd-md-r-1-force {
    padding-right: 1px !important;
  }

  .pd-md-b-1-force {
    padding-bottom: 1px !important;
  }

  .pd-md-l-1-force {
    padding-left: 1px !important;
  }

  .pd-md-2 {
    padding: 2px;
  }

  .pd-md-2-force {
    padding: 2px !important;
  }

  .pd-md-y-2 {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .pd-md-y-2-force {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  .pd-md-x-2 {
    padding-left: 2px;
    padding-right: 2px;
  }

  .pd-md-x-2-force {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .pd-md-t-2 {
    padding-top: 2px;
  }

  .pd-md-r-2 {
    padding-right: 2px;
  }

  .pd-md-b-2 {
    padding-bottom: 2px;
  }

  .pd-md-l-2 {
    padding-left: 2px;
  }

  .pd-md-t-2-force {
    padding-top: 2px !important;
  }

  .pd-md-r-2-force {
    padding-right: 2px !important;
  }

  .pd-md-b-2-force {
    padding-bottom: 2px !important;
  }

  .pd-md-l-2-force {
    padding-left: 2px !important;
  }

  .pd-md-3 {
    padding: 3px;
  }

  .pd-md-3-force {
    padding: 3px !important;
  }

  .pd-md-y-3 {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .pd-md-y-3-force {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  .pd-md-x-3 {
    padding-left: 3px;
    padding-right: 3px;
  }

  .pd-md-x-3-force {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }

  .pd-md-t-3 {
    padding-top: 3px;
  }

  .pd-md-r-3 {
    padding-right: 3px;
  }

  .pd-md-b-3 {
    padding-bottom: 3px;
  }

  .pd-md-l-3 {
    padding-left: 3px;
  }

  .pd-md-t-3-force {
    padding-top: 3px !important;
  }

  .pd-md-r-3-force {
    padding-right: 3px !important;
  }

  .pd-md-b-3-force {
    padding-bottom: 3px !important;
  }

  .pd-md-l-3-force {
    padding-left: 3px !important;
  }

  .pd-md-4 {
    padding: 4px;
  }

  .pd-md-4-force {
    padding: 4px !important;
  }

  .pd-md-y-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .pd-md-y-4-force {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .pd-md-x-4 {
    padding-left: 4px;
    padding-right: 4px;
  }

  .pd-md-x-4-force {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .pd-md-t-4 {
    padding-top: 4px;
  }

  .pd-md-r-4 {
    padding-right: 4px;
  }

  .pd-md-b-4 {
    padding-bottom: 4px;
  }

  .pd-md-l-4 {
    padding-left: 4px;
  }

  .pd-md-t-4-force {
    padding-top: 4px !important;
  }

  .pd-md-r-4-force {
    padding-right: 4px !important;
  }

  .pd-md-b-4-force {
    padding-bottom: 4px !important;
  }

  .pd-md-l-4-force {
    padding-left: 4px !important;
  }

  .pd-md-5 {
    padding: 5px;
  }

  .pd-md-5-force {
    padding: 5px !important;
  }

  .pd-md-y-5 {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .pd-md-y-5-force {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .pd-md-x-5 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .pd-md-x-5-force {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .pd-md-t-5 {
    padding-top: 5px;
  }

  .pd-md-r-5 {
    padding-right: 5px;
  }

  .pd-md-b-5 {
    padding-bottom: 5px;
  }

  .pd-md-l-5 {
    padding-left: 5px;
  }

  .pd-md-t-5-force {
    padding-top: 5px !important;
  }

  .pd-md-r-5-force {
    padding-right: 5px !important;
  }

  .pd-md-b-5-force {
    padding-bottom: 5px !important;
  }

  .pd-md-l-5-force {
    padding-left: 5px !important;
  }

  .pd-md-6 {
    padding: 6px;
  }

  .pd-md-6-force {
    padding: 6px !important;
  }

  .pd-md-y-6 {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .pd-md-y-6-force {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }

  .pd-md-x-6 {
    padding-left: 6px;
    padding-right: 6px;
  }

  .pd-md-x-6-force {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  .pd-md-t-6 {
    padding-top: 6px;
  }

  .pd-md-r-6 {
    padding-right: 6px;
  }

  .pd-md-b-6 {
    padding-bottom: 6px;
  }

  .pd-md-l-6 {
    padding-left: 6px;
  }

  .pd-md-t-6-force {
    padding-top: 6px !important;
  }

  .pd-md-r-6-force {
    padding-right: 6px !important;
  }

  .pd-md-b-6-force {
    padding-bottom: 6px !important;
  }

  .pd-md-l-6-force {
    padding-left: 6px !important;
  }

  .pd-md-7 {
    padding: 7px;
  }

  .pd-md-7-force {
    padding: 7px !important;
  }

  .pd-md-y-7 {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .pd-md-y-7-force {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
  }

  .pd-md-x-7 {
    padding-left: 7px;
    padding-right: 7px;
  }

  .pd-md-x-7-force {
    padding-left: 7px !important;
    padding-right: 7px !important;
  }

  .pd-md-t-7 {
    padding-top: 7px;
  }

  .pd-md-r-7 {
    padding-right: 7px;
  }

  .pd-md-b-7 {
    padding-bottom: 7px;
  }

  .pd-md-l-7 {
    padding-left: 7px;
  }

  .pd-md-t-7-force {
    padding-top: 7px !important;
  }

  .pd-md-r-7-force {
    padding-right: 7px !important;
  }

  .pd-md-b-7-force {
    padding-bottom: 7px !important;
  }

  .pd-md-l-7-force {
    padding-left: 7px !important;
  }

  .pd-md-8 {
    padding: 8px;
  }

  .pd-md-8-force {
    padding: 8px !important;
  }

  .pd-md-y-8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .pd-md-y-8-force {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .pd-md-x-8 {
    padding-left: 8px;
    padding-right: 8px;
  }

  .pd-md-x-8-force {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .pd-md-t-8 {
    padding-top: 8px;
  }

  .pd-md-r-8 {
    padding-right: 8px;
  }

  .pd-md-b-8 {
    padding-bottom: 8px;
  }

  .pd-md-l-8 {
    padding-left: 8px;
  }

  .pd-md-t-8-force {
    padding-top: 8px !important;
  }

  .pd-md-r-8-force {
    padding-right: 8px !important;
  }

  .pd-md-b-8-force {
    padding-bottom: 8px !important;
  }

  .pd-md-l-8-force {
    padding-left: 8px !important;
  }

  .pd-md-9 {
    padding: 9px;
  }

  .pd-md-9-force {
    padding: 9px !important;
  }

  .pd-md-y-9 {
    padding-top: 9px;
    padding-bottom: 9px;
  }

  .pd-md-y-9-force {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
  }

  .pd-md-x-9 {
    padding-left: 9px;
    padding-right: 9px;
  }

  .pd-md-x-9-force {
    padding-left: 9px !important;
    padding-right: 9px !important;
  }

  .pd-md-t-9 {
    padding-top: 9px;
  }

  .pd-md-r-9 {
    padding-right: 9px;
  }

  .pd-md-b-9 {
    padding-bottom: 9px;
  }

  .pd-md-l-9 {
    padding-left: 9px;
  }

  .pd-md-t-9-force {
    padding-top: 9px !important;
  }

  .pd-md-r-9-force {
    padding-right: 9px !important;
  }

  .pd-md-b-9-force {
    padding-bottom: 9px !important;
  }

  .pd-md-l-9-force {
    padding-left: 9px !important;
  }

  .pd-md-10 {
    padding: 10px;
  }

  .pd-md-10-force {
    padding: 10px !important;
  }

  .pd-md-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .pd-md-y-10-force {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .pd-md-x-10 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .pd-md-x-10-force {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .pd-md-t-10 {
    padding-top: 10px;
  }

  .pd-md-r-10 {
    padding-right: 10px;
  }

  .pd-md-b-10 {
    padding-bottom: 10px;
  }

  .pd-md-l-10 {
    padding-left: 10px;
  }

  .pd-md-t-10-force {
    padding-top: 10px !important;
  }

  .pd-md-r-10-force {
    padding-right: 10px !important;
  }

  .pd-md-b-10-force {
    padding-bottom: 10px !important;
  }

  .pd-md-l-10-force {
    padding-left: 10px !important;
  }

  .pd-md-15 {
    padding: 15px;
  }

  .pd-md-15-force {
    padding: 15px !important;
  }

  .pd-md-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .pd-md-y-15-force {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .pd-md-x-15 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .pd-md-x-15-force {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .pd-md-t-15 {
    padding-top: 15px;
  }

  .pd-md-r-15 {
    padding-right: 15px;
  }

  .pd-md-b-15 {
    padding-bottom: 15px;
  }

  .pd-md-l-15 {
    padding-left: 15px;
  }

  .pd-md-t-15-force {
    padding-top: 15px !important;
  }

  .pd-md-r-15-force {
    padding-right: 15px !important;
  }

  .pd-md-b-15-force {
    padding-bottom: 15px !important;
  }

  .pd-md-l-15-force {
    padding-left: 15px !important;
  }

  .pd-md-20 {
    padding: 20px;
  }

  .pd-md-20-force {
    padding: 20px !important;
  }

  .pd-md-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .pd-md-y-20-force {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .pd-md-x-20 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .pd-md-x-20-force {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .pd-md-t-20 {
    padding-top: 20px;
  }

  .pd-md-r-20 {
    padding-right: 20px;
  }

  .pd-md-b-20 {
    padding-bottom: 20px;
  }

  .pd-md-l-20 {
    padding-left: 20px;
  }

  .pd-md-t-20-force {
    padding-top: 20px !important;
  }

  .pd-md-r-20-force {
    padding-right: 20px !important;
  }

  .pd-md-b-20-force {
    padding-bottom: 20px !important;
  }

  .pd-md-l-20-force {
    padding-left: 20px !important;
  }

  .pd-md-25 {
    padding: 25px;
  }

  .pd-md-25-force {
    padding: 25px !important;
  }

  .pd-md-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .pd-md-y-25-force {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .pd-md-x-25 {
    padding-left: 25px;
    padding-right: 25px;
  }

  .pd-md-x-25-force {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .pd-md-t-25 {
    padding-top: 25px;
  }

  .pd-md-r-25 {
    padding-right: 25px;
  }

  .pd-md-b-25 {
    padding-bottom: 25px;
  }

  .pd-md-l-25 {
    padding-left: 25px;
  }

  .pd-md-t-25-force {
    padding-top: 25px !important;
  }

  .pd-md-r-25-force {
    padding-right: 25px !important;
  }

  .pd-md-b-25-force {
    padding-bottom: 25px !important;
  }

  .pd-md-l-25-force {
    padding-left: 25px !important;
  }

  .pd-md-30 {
    padding: 30px;
  }

  .pd-md-30-force {
    padding: 30px !important;
  }

  .pd-md-y-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .pd-md-y-30-force {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .pd-md-x-30 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .pd-md-x-30-force {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .pd-md-t-30 {
    padding-top: 30px;
  }

  .pd-md-r-30 {
    padding-right: 30px;
  }

  .pd-md-b-30 {
    padding-bottom: 30px;
  }

  .pd-md-l-30 {
    padding-left: 30px;
  }

  .pd-md-t-30-force {
    padding-top: 30px !important;
  }

  .pd-md-r-30-force {
    padding-right: 30px !important;
  }

  .pd-md-b-30-force {
    padding-bottom: 30px !important;
  }

  .pd-md-l-30-force {
    padding-left: 30px !important;
  }

  .pd-md-35 {
    padding: 35px;
  }

  .pd-md-35-force {
    padding: 35px !important;
  }

  .pd-md-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .pd-md-y-35-force {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }

  .pd-md-x-35 {
    padding-left: 35px;
    padding-right: 35px;
  }

  .pd-md-x-35-force {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }

  .pd-md-t-35 {
    padding-top: 35px;
  }

  .pd-md-r-35 {
    padding-right: 35px;
  }

  .pd-md-b-35 {
    padding-bottom: 35px;
  }

  .pd-md-l-35 {
    padding-left: 35px;
  }

  .pd-md-t-35-force {
    padding-top: 35px !important;
  }

  .pd-md-r-35-force {
    padding-right: 35px !important;
  }

  .pd-md-b-35-force {
    padding-bottom: 35px !important;
  }

  .pd-md-l-35-force {
    padding-left: 35px !important;
  }

  .pd-md-40 {
    padding: 40px;
  }

  .pd-md-40-force {
    padding: 40px !important;
  }

  .pd-md-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .pd-md-y-40-force {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .pd-md-x-40 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .pd-md-x-40-force {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .pd-md-t-40 {
    padding-top: 40px;
  }

  .pd-md-r-40 {
    padding-right: 40px;
  }

  .pd-md-b-40 {
    padding-bottom: 40px;
  }

  .pd-md-l-40 {
    padding-left: 40px;
  }

  .pd-md-t-40-force {
    padding-top: 40px !important;
  }

  .pd-md-r-40-force {
    padding-right: 40px !important;
  }

  .pd-md-b-40-force {
    padding-bottom: 40px !important;
  }

  .pd-md-l-40-force {
    padding-left: 40px !important;
  }

  .pd-md-45 {
    padding: 45px;
  }

  .pd-md-45-force {
    padding: 45px !important;
  }

  .pd-md-y-45 {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .pd-md-y-45-force {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }

  .pd-md-x-45 {
    padding-left: 45px;
    padding-right: 45px;
  }

  .pd-md-x-45-force {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }

  .pd-md-t-45 {
    padding-top: 45px;
  }

  .pd-md-r-45 {
    padding-right: 45px;
  }

  .pd-md-b-45 {
    padding-bottom: 45px;
  }

  .pd-md-l-45 {
    padding-left: 45px;
  }

  .pd-md-t-45-force {
    padding-top: 45px !important;
  }

  .pd-md-r-45-force {
    padding-right: 45px !important;
  }

  .pd-md-b-45-force {
    padding-bottom: 45px !important;
  }

  .pd-md-l-45-force {
    padding-left: 45px !important;
  }

  .pd-md-50 {
    padding: 50px;
  }

  .pd-md-50-force {
    padding: 50px !important;
  }

  .pd-md-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .pd-md-y-50-force {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .pd-md-x-50 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .pd-md-x-50-force {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .pd-md-t-50 {
    padding-top: 50px;
  }

  .pd-md-r-50 {
    padding-right: 50px;
  }

  .pd-md-b-50 {
    padding-bottom: 50px;
  }

  .pd-md-l-50 {
    padding-left: 50px;
  }

  .pd-md-t-50-force {
    padding-top: 50px !important;
  }

  .pd-md-r-50-force {
    padding-right: 50px !important;
  }

  .pd-md-b-50-force {
    padding-bottom: 50px !important;
  }

  .pd-md-l-50-force {
    padding-left: 50px !important;
  }

  .pd-md-55 {
    padding: 55px;
  }

  .pd-md-55-force {
    padding: 55px !important;
  }

  .pd-md-y-55 {
    padding-top: 55px;
    padding-bottom: 55px;
  }

  .pd-md-y-55-force {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }

  .pd-md-x-55 {
    padding-left: 55px;
    padding-right: 55px;
  }

  .pd-md-x-55-force {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }

  .pd-md-t-55 {
    padding-top: 55px;
  }

  .pd-md-r-55 {
    padding-right: 55px;
  }

  .pd-md-b-55 {
    padding-bottom: 55px;
  }

  .pd-md-l-55 {
    padding-left: 55px;
  }

  .pd-md-t-55-force {
    padding-top: 55px !important;
  }

  .pd-md-r-55-force {
    padding-right: 55px !important;
  }

  .pd-md-b-55-force {
    padding-bottom: 55px !important;
  }

  .pd-md-l-55-force {
    padding-left: 55px !important;
  }

  .pd-md-60 {
    padding: 60px;
  }

  .pd-md-60-force {
    padding: 60px !important;
  }

  .pd-md-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .pd-md-y-60-force {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  .pd-md-x-60 {
    padding-left: 60px;
    padding-right: 60px;
  }

  .pd-md-x-60-force {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .pd-md-t-60 {
    padding-top: 60px;
  }

  .pd-md-r-60 {
    padding-right: 60px;
  }

  .pd-md-b-60 {
    padding-bottom: 60px;
  }

  .pd-md-l-60 {
    padding-left: 60px;
  }

  .pd-md-t-60-force {
    padding-top: 60px !important;
  }

  .pd-md-r-60-force {
    padding-right: 60px !important;
  }

  .pd-md-b-60-force {
    padding-bottom: 60px !important;
  }

  .pd-md-l-60-force {
    padding-left: 60px !important;
  }

  .pd-md-65 {
    padding: 65px;
  }

  .pd-md-65-force {
    padding: 65px !important;
  }

  .pd-md-y-65 {
    padding-top: 65px;
    padding-bottom: 65px;
  }

  .pd-md-y-65-force {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }

  .pd-md-x-65 {
    padding-left: 65px;
    padding-right: 65px;
  }

  .pd-md-x-65-force {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }

  .pd-md-t-65 {
    padding-top: 65px;
  }

  .pd-md-r-65 {
    padding-right: 65px;
  }

  .pd-md-b-65 {
    padding-bottom: 65px;
  }

  .pd-md-l-65 {
    padding-left: 65px;
  }

  .pd-md-t-65-force {
    padding-top: 65px !important;
  }

  .pd-md-r-65-force {
    padding-right: 65px !important;
  }

  .pd-md-b-65-force {
    padding-bottom: 65px !important;
  }

  .pd-md-l-65-force {
    padding-left: 65px !important;
  }

  .pd-md-70 {
    padding: 70px;
  }

  .pd-md-70-force {
    padding: 70px !important;
  }

  .pd-md-y-70 {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .pd-md-y-70-force {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }

  .pd-md-x-70 {
    padding-left: 70px;
    padding-right: 70px;
  }

  .pd-md-x-70-force {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }

  .pd-md-t-70 {
    padding-top: 70px;
  }

  .pd-md-r-70 {
    padding-right: 70px;
  }

  .pd-md-b-70 {
    padding-bottom: 70px;
  }

  .pd-md-l-70 {
    padding-left: 70px;
  }

  .pd-md-t-70-force {
    padding-top: 70px !important;
  }

  .pd-md-r-70-force {
    padding-right: 70px !important;
  }

  .pd-md-b-70-force {
    padding-bottom: 70px !important;
  }

  .pd-md-l-70-force {
    padding-left: 70px !important;
  }

  .pd-md-75 {
    padding: 75px;
  }

  .pd-md-75-force {
    padding: 75px !important;
  }

  .pd-md-y-75 {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .pd-md-y-75-force {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }

  .pd-md-x-75 {
    padding-left: 75px;
    padding-right: 75px;
  }

  .pd-md-x-75-force {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }

  .pd-md-t-75 {
    padding-top: 75px;
  }

  .pd-md-r-75 {
    padding-right: 75px;
  }

  .pd-md-b-75 {
    padding-bottom: 75px;
  }

  .pd-md-l-75 {
    padding-left: 75px;
  }

  .pd-md-t-75-force {
    padding-top: 75px !important;
  }

  .pd-md-r-75-force {
    padding-right: 75px !important;
  }

  .pd-md-b-75-force {
    padding-bottom: 75px !important;
  }

  .pd-md-l-75-force {
    padding-left: 75px !important;
  }

  .pd-md-80 {
    padding: 80px;
  }

  .pd-md-80-force {
    padding: 80px !important;
  }

  .pd-md-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .pd-md-y-80-force {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }

  .pd-md-x-80 {
    padding-left: 80px;
    padding-right: 80px;
  }

  .pd-md-x-80-force {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }

  .pd-md-t-80 {
    padding-top: 80px;
  }

  .pd-md-r-80 {
    padding-right: 80px;
  }

  .pd-md-b-80 {
    padding-bottom: 80px;
  }

  .pd-md-l-80 {
    padding-left: 80px;
  }

  .pd-md-t-80-force {
    padding-top: 80px !important;
  }

  .pd-md-r-80-force {
    padding-right: 80px !important;
  }

  .pd-md-b-80-force {
    padding-bottom: 80px !important;
  }

  .pd-md-l-80-force {
    padding-left: 80px !important;
  }

  .pd-md-85 {
    padding: 85px;
  }

  .pd-md-85-force {
    padding: 85px !important;
  }

  .pd-md-y-85 {
    padding-top: 85px;
    padding-bottom: 85px;
  }

  .pd-md-y-85-force {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }

  .pd-md-x-85 {
    padding-left: 85px;
    padding-right: 85px;
  }

  .pd-md-x-85-force {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }

  .pd-md-t-85 {
    padding-top: 85px;
  }

  .pd-md-r-85 {
    padding-right: 85px;
  }

  .pd-md-b-85 {
    padding-bottom: 85px;
  }

  .pd-md-l-85 {
    padding-left: 85px;
  }

  .pd-md-t-85-force {
    padding-top: 85px !important;
  }

  .pd-md-r-85-force {
    padding-right: 85px !important;
  }

  .pd-md-b-85-force {
    padding-bottom: 85px !important;
  }

  .pd-md-l-85-force {
    padding-left: 85px !important;
  }

  .pd-md-90 {
    padding: 90px;
  }

  .pd-md-90-force {
    padding: 90px !important;
  }

  .pd-md-y-90 {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .pd-md-y-90-force {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }

  .pd-md-x-90 {
    padding-left: 90px;
    padding-right: 90px;
  }

  .pd-md-x-90-force {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }

  .pd-md-t-90 {
    padding-top: 90px;
  }

  .pd-md-r-90 {
    padding-right: 90px;
  }

  .pd-md-b-90 {
    padding-bottom: 90px;
  }

  .pd-md-l-90 {
    padding-left: 90px;
  }

  .pd-md-t-90-force {
    padding-top: 90px !important;
  }

  .pd-md-r-90-force {
    padding-right: 90px !important;
  }

  .pd-md-b-90-force {
    padding-bottom: 90px !important;
  }

  .pd-md-l-90-force {
    padding-left: 90px !important;
  }

  .pd-md-95 {
    padding: 95px;
  }

  .pd-md-95-force {
    padding: 95px !important;
  }

  .pd-md-y-95 {
    padding-top: 95px;
    padding-bottom: 95px;
  }

  .pd-md-y-95-force {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }

  .pd-md-x-95 {
    padding-left: 95px;
    padding-right: 95px;
  }

  .pd-md-x-95-force {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }

  .pd-md-t-95 {
    padding-top: 95px;
  }

  .pd-md-r-95 {
    padding-right: 95px;
  }

  .pd-md-b-95 {
    padding-bottom: 95px;
  }

  .pd-md-l-95 {
    padding-left: 95px;
  }

  .pd-md-t-95-force {
    padding-top: 95px !important;
  }

  .pd-md-r-95-force {
    padding-right: 95px !important;
  }

  .pd-md-b-95-force {
    padding-bottom: 95px !important;
  }

  .pd-md-l-95-force {
    padding-left: 95px !important;
  }

  .pd-md-100 {
    padding: 100px;
  }

  .pd-md-100-force {
    padding: 100px !important;
  }

  .pd-md-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .pd-md-y-100-force {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }

  .pd-md-x-100 {
    padding-left: 100px;
    padding-right: 100px;
  }

  .pd-md-x-100-force {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }

  .pd-md-t-100 {
    padding-top: 100px;
  }

  .pd-md-r-100 {
    padding-right: 100px;
  }

  .pd-md-b-100 {
    padding-bottom: 100px;
  }

  .pd-md-l-100 {
    padding-left: 100px;
  }

  .pd-md-t-100-force {
    padding-top: 100px !important;
  }

  .pd-md-r-100-force {
    padding-right: 100px !important;
  }

  .pd-md-b-100-force {
    padding-bottom: 100px !important;
  }

  .pd-md-l-100-force {
    padding-left: 100px !important;
  }

  .pd-md-110 {
    padding: 110px;
  }

  .pd-md-110-force {
    padding: 110px !important;
  }

  .pd-md-y-110 {
    padding-top: 110px;
    padding-bottom: 110px;
  }

  .pd-md-y-110-force {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }

  .pd-md-x-110 {
    padding-left: 110px;
    padding-right: 110px;
  }

  .pd-md-x-110-force {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }

  .pd-md-t-110 {
    padding-top: 110px;
  }

  .pd-md-r-110 {
    padding-right: 110px;
  }

  .pd-md-b-110 {
    padding-bottom: 110px;
  }

  .pd-md-l-110 {
    padding-left: 110px;
  }

  .pd-md-t-110-force {
    padding-top: 110px !important;
  }

  .pd-md-r-110-force {
    padding-right: 110px !important;
  }

  .pd-md-b-110-force {
    padding-bottom: 110px !important;
  }

  .pd-md-l-110-force {
    padding-left: 110px !important;
  }

  .pd-md-120 {
    padding: 120px;
  }

  .pd-md-120-force {
    padding: 120px !important;
  }

  .pd-md-y-120 {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .pd-md-y-120-force {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }

  .pd-md-x-120 {
    padding-left: 120px;
    padding-right: 120px;
  }

  .pd-md-x-120-force {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }

  .pd-md-t-120 {
    padding-top: 120px;
  }

  .pd-md-r-120 {
    padding-right: 120px;
  }

  .pd-md-b-120 {
    padding-bottom: 120px;
  }

  .pd-md-l-120 {
    padding-left: 120px;
  }

  .pd-md-t-120-force {
    padding-top: 120px !important;
  }

  .pd-md-r-120-force {
    padding-right: 120px !important;
  }

  .pd-md-b-120-force {
    padding-bottom: 120px !important;
  }

  .pd-md-l-120-force {
    padding-left: 120px !important;
  }

  .pd-md-130 {
    padding: 130px;
  }

  .pd-md-130-force {
    padding: 130px !important;
  }

  .pd-md-y-130 {
    padding-top: 130px;
    padding-bottom: 130px;
  }

  .pd-md-y-130-force {
    padding-top: 130px !important;
    padding-bottom: 130px !important;
  }

  .pd-md-x-130 {
    padding-left: 130px;
    padding-right: 130px;
  }

  .pd-md-x-130-force {
    padding-left: 130px !important;
    padding-right: 130px !important;
  }

  .pd-md-t-130 {
    padding-top: 130px;
  }

  .pd-md-r-130 {
    padding-right: 130px;
  }

  .pd-md-b-130 {
    padding-bottom: 130px;
  }

  .pd-md-l-130 {
    padding-left: 130px;
  }

  .pd-md-t-130-force {
    padding-top: 130px !important;
  }

  .pd-md-r-130-force {
    padding-right: 130px !important;
  }

  .pd-md-b-130-force {
    padding-bottom: 130px !important;
  }

  .pd-md-l-130-force {
    padding-left: 130px !important;
  }

  .pd-md-140 {
    padding: 140px;
  }

  .pd-md-140-force {
    padding: 140px !important;
  }

  .pd-md-y-140 {
    padding-top: 140px;
    padding-bottom: 140px;
  }

  .pd-md-y-140-force {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }

  .pd-md-x-140 {
    padding-left: 140px;
    padding-right: 140px;
  }

  .pd-md-x-140-force {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }

  .pd-md-t-140 {
    padding-top: 140px;
  }

  .pd-md-r-140 {
    padding-right: 140px;
  }

  .pd-md-b-140 {
    padding-bottom: 140px;
  }

  .pd-md-l-140 {
    padding-left: 140px;
  }

  .pd-md-t-140-force {
    padding-top: 140px !important;
  }

  .pd-md-r-140-force {
    padding-right: 140px !important;
  }

  .pd-md-b-140-force {
    padding-bottom: 140px !important;
  }

  .pd-md-l-140-force {
    padding-left: 140px !important;
  }

  .pd-md-150 {
    padding: 150px;
  }

  .pd-md-150-force {
    padding: 150px !important;
  }

  .pd-md-y-150 {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .pd-md-y-150-force {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }

  .pd-md-x-150 {
    padding-left: 150px;
    padding-right: 150px;
  }

  .pd-md-x-150-force {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }

  .pd-md-t-150 {
    padding-top: 150px;
  }

  .pd-md-r-150 {
    padding-right: 150px;
  }

  .pd-md-b-150 {
    padding-bottom: 150px;
  }

  .pd-md-l-150 {
    padding-left: 150px;
  }

  .pd-md-t-150-force {
    padding-top: 150px !important;
  }

  .pd-md-r-150-force {
    padding-right: 150px !important;
  }

  .pd-md-b-150-force {
    padding-bottom: 150px !important;
  }

  .pd-md-l-150-force {
    padding-left: 150px !important;
  }

  .pd-md-160 {
    padding: 160px;
  }

  .pd-md-160-force {
    padding: 160px !important;
  }

  .pd-md-y-160 {
    padding-top: 160px;
    padding-bottom: 160px;
  }

  .pd-md-y-160-force {
    padding-top: 160px !important;
    padding-bottom: 160px !important;
  }

  .pd-md-x-160 {
    padding-left: 160px;
    padding-right: 160px;
  }

  .pd-md-x-160-force {
    padding-left: 160px !important;
    padding-right: 160px !important;
  }

  .pd-md-t-160 {
    padding-top: 160px;
  }

  .pd-md-r-160 {
    padding-right: 160px;
  }

  .pd-md-b-160 {
    padding-bottom: 160px;
  }

  .pd-md-l-160 {
    padding-left: 160px;
  }

  .pd-md-t-160-force {
    padding-top: 160px !important;
  }

  .pd-md-r-160-force {
    padding-right: 160px !important;
  }

  .pd-md-b-160-force {
    padding-bottom: 160px !important;
  }

  .pd-md-l-160-force {
    padding-left: 160px !important;
  }

  .pd-md-170 {
    padding: 170px;
  }

  .pd-md-170-force {
    padding: 170px !important;
  }

  .pd-md-y-170 {
    padding-top: 170px;
    padding-bottom: 170px;
  }

  .pd-md-y-170-force {
    padding-top: 170px !important;
    padding-bottom: 170px !important;
  }

  .pd-md-x-170 {
    padding-left: 170px;
    padding-right: 170px;
  }

  .pd-md-x-170-force {
    padding-left: 170px !important;
    padding-right: 170px !important;
  }

  .pd-md-t-170 {
    padding-top: 170px;
  }

  .pd-md-r-170 {
    padding-right: 170px;
  }

  .pd-md-b-170 {
    padding-bottom: 170px;
  }

  .pd-md-l-170 {
    padding-left: 170px;
  }

  .pd-md-t-170-force {
    padding-top: 170px !important;
  }

  .pd-md-r-170-force {
    padding-right: 170px !important;
  }

  .pd-md-b-170-force {
    padding-bottom: 170px !important;
  }

  .pd-md-l-170-force {
    padding-left: 170px !important;
  }

  .pd-md-180 {
    padding: 180px;
  }

  .pd-md-180-force {
    padding: 180px !important;
  }

  .pd-md-y-180 {
    padding-top: 180px;
    padding-bottom: 180px;
  }

  .pd-md-y-180-force {
    padding-top: 180px !important;
    padding-bottom: 180px !important;
  }

  .pd-md-x-180 {
    padding-left: 180px;
    padding-right: 180px;
  }

  .pd-md-x-180-force {
    padding-left: 180px !important;
    padding-right: 180px !important;
  }

  .pd-md-t-180 {
    padding-top: 180px;
  }

  .pd-md-r-180 {
    padding-right: 180px;
  }

  .pd-md-b-180 {
    padding-bottom: 180px;
  }

  .pd-md-l-180 {
    padding-left: 180px;
  }

  .pd-md-t-180-force {
    padding-top: 180px !important;
  }

  .pd-md-r-180-force {
    padding-right: 180px !important;
  }

  .pd-md-b-180-force {
    padding-bottom: 180px !important;
  }

  .pd-md-l-180-force {
    padding-left: 180px !important;
  }

  .pd-md-190 {
    padding: 190px;
  }

  .pd-md-190-force {
    padding: 190px !important;
  }

  .pd-md-y-190 {
    padding-top: 190px;
    padding-bottom: 190px;
  }

  .pd-md-y-190-force {
    padding-top: 190px !important;
    padding-bottom: 190px !important;
  }

  .pd-md-x-190 {
    padding-left: 190px;
    padding-right: 190px;
  }

  .pd-md-x-190-force {
    padding-left: 190px !important;
    padding-right: 190px !important;
  }

  .pd-md-t-190 {
    padding-top: 190px;
  }

  .pd-md-r-190 {
    padding-right: 190px;
  }

  .pd-md-b-190 {
    padding-bottom: 190px;
  }

  .pd-md-l-190 {
    padding-left: 190px;
  }

  .pd-md-t-190-force {
    padding-top: 190px !important;
  }

  .pd-md-r-190-force {
    padding-right: 190px !important;
  }

  .pd-md-b-190-force {
    padding-bottom: 190px !important;
  }

  .pd-md-l-190-force {
    padding-left: 190px !important;
  }

  .pd-md-200 {
    padding: 200px;
  }

  .pd-md-200-force {
    padding: 200px !important;
  }

  .pd-md-y-200 {
    padding-top: 200px;
    padding-bottom: 200px;
  }

  .pd-md-y-200-force {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }

  .pd-md-x-200 {
    padding-left: 200px;
    padding-right: 200px;
  }

  .pd-md-x-200-force {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }

  .pd-md-t-200 {
    padding-top: 200px;
  }

  .pd-md-r-200 {
    padding-right: 200px;
  }

  .pd-md-b-200 {
    padding-bottom: 200px;
  }

  .pd-md-l-200 {
    padding-left: 200px;
  }

  .pd-md-t-200-force {
    padding-top: 200px !important;
  }

  .pd-md-r-200-force {
    padding-right: 200px !important;
  }

  .pd-md-b-200-force {
    padding-bottom: 200px !important;
  }

  .pd-md-l-200-force {
    padding-left: 200px !important;
  }
}
@media (min-width: 992px) {
  .pd-lg-0 {
    padding: 0px;
  }

  .pd-lg-0-force {
    padding: 0px !important;
  }

  .pd-lg-y-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .pd-lg-y-0-force {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .pd-lg-x-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .pd-lg-x-0-force {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .pd-lg-t-0 {
    padding-top: 0px;
  }

  .pd-lg-r-0 {
    padding-right: 0px;
  }

  .pd-lg-b-0 {
    padding-bottom: 0px;
  }

  .pd-lg-l-0 {
    padding-left: 0px;
  }

  .pd-lg-t-0-force {
    padding-top: 0px !important;
  }

  .pd-lg-r-0-force {
    padding-right: 0px !important;
  }

  .pd-lg-b-0-force {
    padding-bottom: 0px !important;
  }

  .pd-lg-l-0-force {
    padding-left: 0px !important;
  }

  .pd-lg-1 {
    padding: 1px;
  }

  .pd-lg-1-force {
    padding: 1px !important;
  }

  .pd-lg-y-1 {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .pd-lg-y-1-force {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .pd-lg-x-1 {
    padding-left: 1px;
    padding-right: 1px;
  }

  .pd-lg-x-1-force {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .pd-lg-t-1 {
    padding-top: 1px;
  }

  .pd-lg-r-1 {
    padding-right: 1px;
  }

  .pd-lg-b-1 {
    padding-bottom: 1px;
  }

  .pd-lg-l-1 {
    padding-left: 1px;
  }

  .pd-lg-t-1-force {
    padding-top: 1px !important;
  }

  .pd-lg-r-1-force {
    padding-right: 1px !important;
  }

  .pd-lg-b-1-force {
    padding-bottom: 1px !important;
  }

  .pd-lg-l-1-force {
    padding-left: 1px !important;
  }

  .pd-lg-2 {
    padding: 2px;
  }

  .pd-lg-2-force {
    padding: 2px !important;
  }

  .pd-lg-y-2 {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .pd-lg-y-2-force {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  .pd-lg-x-2 {
    padding-left: 2px;
    padding-right: 2px;
  }

  .pd-lg-x-2-force {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .pd-lg-t-2 {
    padding-top: 2px;
  }

  .pd-lg-r-2 {
    padding-right: 2px;
  }

  .pd-lg-b-2 {
    padding-bottom: 2px;
  }

  .pd-lg-l-2 {
    padding-left: 2px;
  }

  .pd-lg-t-2-force {
    padding-top: 2px !important;
  }

  .pd-lg-r-2-force {
    padding-right: 2px !important;
  }

  .pd-lg-b-2-force {
    padding-bottom: 2px !important;
  }

  .pd-lg-l-2-force {
    padding-left: 2px !important;
  }

  .pd-lg-3 {
    padding: 3px;
  }

  .pd-lg-3-force {
    padding: 3px !important;
  }

  .pd-lg-y-3 {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .pd-lg-y-3-force {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  .pd-lg-x-3 {
    padding-left: 3px;
    padding-right: 3px;
  }

  .pd-lg-x-3-force {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }

  .pd-lg-t-3 {
    padding-top: 3px;
  }

  .pd-lg-r-3 {
    padding-right: 3px;
  }

  .pd-lg-b-3 {
    padding-bottom: 3px;
  }

  .pd-lg-l-3 {
    padding-left: 3px;
  }

  .pd-lg-t-3-force {
    padding-top: 3px !important;
  }

  .pd-lg-r-3-force {
    padding-right: 3px !important;
  }

  .pd-lg-b-3-force {
    padding-bottom: 3px !important;
  }

  .pd-lg-l-3-force {
    padding-left: 3px !important;
  }

  .pd-lg-4 {
    padding: 4px;
  }

  .pd-lg-4-force {
    padding: 4px !important;
  }

  .pd-lg-y-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .pd-lg-y-4-force {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .pd-lg-x-4 {
    padding-left: 4px;
    padding-right: 4px;
  }

  .pd-lg-x-4-force {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .pd-lg-t-4 {
    padding-top: 4px;
  }

  .pd-lg-r-4 {
    padding-right: 4px;
  }

  .pd-lg-b-4 {
    padding-bottom: 4px;
  }

  .pd-lg-l-4 {
    padding-left: 4px;
  }

  .pd-lg-t-4-force {
    padding-top: 4px !important;
  }

  .pd-lg-r-4-force {
    padding-right: 4px !important;
  }

  .pd-lg-b-4-force {
    padding-bottom: 4px !important;
  }

  .pd-lg-l-4-force {
    padding-left: 4px !important;
  }

  .pd-lg-5 {
    padding: 5px;
  }

  .pd-lg-5-force {
    padding: 5px !important;
  }

  .pd-lg-y-5 {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .pd-lg-y-5-force {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .pd-lg-x-5 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .pd-lg-x-5-force {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .pd-lg-t-5 {
    padding-top: 5px;
  }

  .pd-lg-r-5 {
    padding-right: 5px;
  }

  .pd-lg-b-5 {
    padding-bottom: 5px;
  }

  .pd-lg-l-5 {
    padding-left: 5px;
  }

  .pd-lg-t-5-force {
    padding-top: 5px !important;
  }

  .pd-lg-r-5-force {
    padding-right: 5px !important;
  }

  .pd-lg-b-5-force {
    padding-bottom: 5px !important;
  }

  .pd-lg-l-5-force {
    padding-left: 5px !important;
  }

  .pd-lg-6 {
    padding: 6px;
  }

  .pd-lg-6-force {
    padding: 6px !important;
  }

  .pd-lg-y-6 {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .pd-lg-y-6-force {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }

  .pd-lg-x-6 {
    padding-left: 6px;
    padding-right: 6px;
  }

  .pd-lg-x-6-force {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  .pd-lg-t-6 {
    padding-top: 6px;
  }

  .pd-lg-r-6 {
    padding-right: 6px;
  }

  .pd-lg-b-6 {
    padding-bottom: 6px;
  }

  .pd-lg-l-6 {
    padding-left: 6px;
  }

  .pd-lg-t-6-force {
    padding-top: 6px !important;
  }

  .pd-lg-r-6-force {
    padding-right: 6px !important;
  }

  .pd-lg-b-6-force {
    padding-bottom: 6px !important;
  }

  .pd-lg-l-6-force {
    padding-left: 6px !important;
  }

  .pd-lg-7 {
    padding: 7px;
  }

  .pd-lg-7-force {
    padding: 7px !important;
  }

  .pd-lg-y-7 {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .pd-lg-y-7-force {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
  }

  .pd-lg-x-7 {
    padding-left: 7px;
    padding-right: 7px;
  }

  .pd-lg-x-7-force {
    padding-left: 7px !important;
    padding-right: 7px !important;
  }

  .pd-lg-t-7 {
    padding-top: 7px;
  }

  .pd-lg-r-7 {
    padding-right: 7px;
  }

  .pd-lg-b-7 {
    padding-bottom: 7px;
  }

  .pd-lg-l-7 {
    padding-left: 7px;
  }

  .pd-lg-t-7-force {
    padding-top: 7px !important;
  }

  .pd-lg-r-7-force {
    padding-right: 7px !important;
  }

  .pd-lg-b-7-force {
    padding-bottom: 7px !important;
  }

  .pd-lg-l-7-force {
    padding-left: 7px !important;
  }

  .pd-lg-8 {
    padding: 8px;
  }

  .pd-lg-8-force {
    padding: 8px !important;
  }

  .pd-lg-y-8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .pd-lg-y-8-force {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .pd-lg-x-8 {
    padding-left: 8px;
    padding-right: 8px;
  }

  .pd-lg-x-8-force {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .pd-lg-t-8 {
    padding-top: 8px;
  }

  .pd-lg-r-8 {
    padding-right: 8px;
  }

  .pd-lg-b-8 {
    padding-bottom: 8px;
  }

  .pd-lg-l-8 {
    padding-left: 8px;
  }

  .pd-lg-t-8-force {
    padding-top: 8px !important;
  }

  .pd-lg-r-8-force {
    padding-right: 8px !important;
  }

  .pd-lg-b-8-force {
    padding-bottom: 8px !important;
  }

  .pd-lg-l-8-force {
    padding-left: 8px !important;
  }

  .pd-lg-9 {
    padding: 9px;
  }

  .pd-lg-9-force {
    padding: 9px !important;
  }

  .pd-lg-y-9 {
    padding-top: 9px;
    padding-bottom: 9px;
  }

  .pd-lg-y-9-force {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
  }

  .pd-lg-x-9 {
    padding-left: 9px;
    padding-right: 9px;
  }

  .pd-lg-x-9-force {
    padding-left: 9px !important;
    padding-right: 9px !important;
  }

  .pd-lg-t-9 {
    padding-top: 9px;
  }

  .pd-lg-r-9 {
    padding-right: 9px;
  }

  .pd-lg-b-9 {
    padding-bottom: 9px;
  }

  .pd-lg-l-9 {
    padding-left: 9px;
  }

  .pd-lg-t-9-force {
    padding-top: 9px !important;
  }

  .pd-lg-r-9-force {
    padding-right: 9px !important;
  }

  .pd-lg-b-9-force {
    padding-bottom: 9px !important;
  }

  .pd-lg-l-9-force {
    padding-left: 9px !important;
  }

  .pd-lg-10 {
    padding: 10px;
  }

  .pd-lg-10-force {
    padding: 10px !important;
  }

  .pd-lg-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .pd-lg-y-10-force {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .pd-lg-x-10 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .pd-lg-x-10-force {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .pd-lg-t-10 {
    padding-top: 10px;
  }

  .pd-lg-r-10 {
    padding-right: 10px;
  }

  .pd-lg-b-10 {
    padding-bottom: 10px;
  }

  .pd-lg-l-10 {
    padding-left: 10px;
  }

  .pd-lg-t-10-force {
    padding-top: 10px !important;
  }

  .pd-lg-r-10-force {
    padding-right: 10px !important;
  }

  .pd-lg-b-10-force {
    padding-bottom: 10px !important;
  }

  .pd-lg-l-10-force {
    padding-left: 10px !important;
  }

  .pd-lg-15 {
    padding: 15px;
  }

  .pd-lg-15-force {
    padding: 15px !important;
  }

  .pd-lg-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .pd-lg-y-15-force {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .pd-lg-x-15 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .pd-lg-x-15-force {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .pd-lg-t-15 {
    padding-top: 15px;
  }

  .pd-lg-r-15 {
    padding-right: 15px;
  }

  .pd-lg-b-15 {
    padding-bottom: 15px;
  }

  .pd-lg-l-15 {
    padding-left: 15px;
  }

  .pd-lg-t-15-force {
    padding-top: 15px !important;
  }

  .pd-lg-r-15-force {
    padding-right: 15px !important;
  }

  .pd-lg-b-15-force {
    padding-bottom: 15px !important;
  }

  .pd-lg-l-15-force {
    padding-left: 15px !important;
  }

  .pd-lg-20 {
    padding: 20px;
  }

  .pd-lg-20-force {
    padding: 20px !important;
  }

  .pd-lg-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .pd-lg-y-20-force {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .pd-lg-x-20 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .pd-lg-x-20-force {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .pd-lg-t-20 {
    padding-top: 20px;
  }

  .pd-lg-r-20 {
    padding-right: 20px;
  }

  .pd-lg-b-20 {
    padding-bottom: 20px;
  }

  .pd-lg-l-20 {
    padding-left: 20px;
  }

  .pd-lg-t-20-force {
    padding-top: 20px !important;
  }

  .pd-lg-r-20-force {
    padding-right: 20px !important;
  }

  .pd-lg-b-20-force {
    padding-bottom: 20px !important;
  }

  .pd-lg-l-20-force {
    padding-left: 20px !important;
  }

  .pd-lg-25 {
    padding: 25px;
  }

  .pd-lg-25-force {
    padding: 25px !important;
  }

  .pd-lg-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .pd-lg-y-25-force {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .pd-lg-x-25 {
    padding-left: 25px;
    padding-right: 25px;
  }

  .pd-lg-x-25-force {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .pd-lg-t-25 {
    padding-top: 25px;
  }

  .pd-lg-r-25 {
    padding-right: 25px;
  }

  .pd-lg-b-25 {
    padding-bottom: 25px;
  }

  .pd-lg-l-25 {
    padding-left: 25px;
  }

  .pd-lg-t-25-force {
    padding-top: 25px !important;
  }

  .pd-lg-r-25-force {
    padding-right: 25px !important;
  }

  .pd-lg-b-25-force {
    padding-bottom: 25px !important;
  }

  .pd-lg-l-25-force {
    padding-left: 25px !important;
  }

  .pd-lg-30 {
    padding: 30px;
  }

  .pd-lg-30-force {
    padding: 30px !important;
  }

  .pd-lg-y-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .pd-lg-y-30-force {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .pd-lg-x-30 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .pd-lg-x-30-force {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .pd-lg-t-30 {
    padding-top: 30px;
  }

  .pd-lg-r-30 {
    padding-right: 30px;
  }

  .pd-lg-b-30 {
    padding-bottom: 30px;
  }

  .pd-lg-l-30 {
    padding-left: 30px;
  }

  .pd-lg-t-30-force {
    padding-top: 30px !important;
  }

  .pd-lg-r-30-force {
    padding-right: 30px !important;
  }

  .pd-lg-b-30-force {
    padding-bottom: 30px !important;
  }

  .pd-lg-l-30-force {
    padding-left: 30px !important;
  }

  .pd-lg-35 {
    padding: 35px;
  }

  .pd-lg-35-force {
    padding: 35px !important;
  }

  .pd-lg-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .pd-lg-y-35-force {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }

  .pd-lg-x-35 {
    padding-left: 35px;
    padding-right: 35px;
  }

  .pd-lg-x-35-force {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }

  .pd-lg-t-35 {
    padding-top: 35px;
  }

  .pd-lg-r-35 {
    padding-right: 35px;
  }

  .pd-lg-b-35 {
    padding-bottom: 35px;
  }

  .pd-lg-l-35 {
    padding-left: 35px;
  }

  .pd-lg-t-35-force {
    padding-top: 35px !important;
  }

  .pd-lg-r-35-force {
    padding-right: 35px !important;
  }

  .pd-lg-b-35-force {
    padding-bottom: 35px !important;
  }

  .pd-lg-l-35-force {
    padding-left: 35px !important;
  }

  .pd-lg-40 {
    padding: 40px;
  }

  .pd-lg-40-force {
    padding: 40px !important;
  }

  .pd-lg-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .pd-lg-y-40-force {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .pd-lg-x-40 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .pd-lg-x-40-force {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .pd-lg-t-40 {
    padding-top: 40px;
  }

  .pd-lg-r-40 {
    padding-right: 40px;
  }

  .pd-lg-b-40 {
    padding-bottom: 40px;
  }

  .pd-lg-l-40 {
    padding-left: 40px;
  }

  .pd-lg-t-40-force {
    padding-top: 40px !important;
  }

  .pd-lg-r-40-force {
    padding-right: 40px !important;
  }

  .pd-lg-b-40-force {
    padding-bottom: 40px !important;
  }

  .pd-lg-l-40-force {
    padding-left: 40px !important;
  }

  .pd-lg-45 {
    padding: 45px;
  }

  .pd-lg-45-force {
    padding: 45px !important;
  }

  .pd-lg-y-45 {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .pd-lg-y-45-force {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }

  .pd-lg-x-45 {
    padding-left: 45px;
    padding-right: 45px;
  }

  .pd-lg-x-45-force {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }

  .pd-lg-t-45 {
    padding-top: 45px;
  }

  .pd-lg-r-45 {
    padding-right: 45px;
  }

  .pd-lg-b-45 {
    padding-bottom: 45px;
  }

  .pd-lg-l-45 {
    padding-left: 45px;
  }

  .pd-lg-t-45-force {
    padding-top: 45px !important;
  }

  .pd-lg-r-45-force {
    padding-right: 45px !important;
  }

  .pd-lg-b-45-force {
    padding-bottom: 45px !important;
  }

  .pd-lg-l-45-force {
    padding-left: 45px !important;
  }

  .pd-lg-50 {
    padding: 50px;
  }

  .pd-lg-50-force {
    padding: 50px !important;
  }

  .pd-lg-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .pd-lg-y-50-force {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .pd-lg-x-50 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .pd-lg-x-50-force {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .pd-lg-t-50 {
    padding-top: 50px;
  }

  .pd-lg-r-50 {
    padding-right: 50px;
  }

  .pd-lg-b-50 {
    padding-bottom: 50px;
  }

  .pd-lg-l-50 {
    padding-left: 50px;
  }

  .pd-lg-t-50-force {
    padding-top: 50px !important;
  }

  .pd-lg-r-50-force {
    padding-right: 50px !important;
  }

  .pd-lg-b-50-force {
    padding-bottom: 50px !important;
  }

  .pd-lg-l-50-force {
    padding-left: 50px !important;
  }

  .pd-lg-55 {
    padding: 55px;
  }

  .pd-lg-55-force {
    padding: 55px !important;
  }

  .pd-lg-y-55 {
    padding-top: 55px;
    padding-bottom: 55px;
  }

  .pd-lg-y-55-force {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }

  .pd-lg-x-55 {
    padding-left: 55px;
    padding-right: 55px;
  }

  .pd-lg-x-55-force {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }

  .pd-lg-t-55 {
    padding-top: 55px;
  }

  .pd-lg-r-55 {
    padding-right: 55px;
  }

  .pd-lg-b-55 {
    padding-bottom: 55px;
  }

  .pd-lg-l-55 {
    padding-left: 55px;
  }

  .pd-lg-t-55-force {
    padding-top: 55px !important;
  }

  .pd-lg-r-55-force {
    padding-right: 55px !important;
  }

  .pd-lg-b-55-force {
    padding-bottom: 55px !important;
  }

  .pd-lg-l-55-force {
    padding-left: 55px !important;
  }

  .pd-lg-60 {
    padding: 60px;
  }

  .pd-lg-60-force {
    padding: 60px !important;
  }

  .pd-lg-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .pd-lg-y-60-force {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  .pd-lg-x-60 {
    padding-left: 60px;
    padding-right: 60px;
  }

  .pd-lg-x-60-force {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .pd-lg-t-60 {
    padding-top: 60px;
  }

  .pd-lg-r-60 {
    padding-right: 60px;
  }

  .pd-lg-b-60 {
    padding-bottom: 60px;
  }

  .pd-lg-l-60 {
    padding-left: 60px;
  }

  .pd-lg-t-60-force {
    padding-top: 60px !important;
  }

  .pd-lg-r-60-force {
    padding-right: 60px !important;
  }

  .pd-lg-b-60-force {
    padding-bottom: 60px !important;
  }

  .pd-lg-l-60-force {
    padding-left: 60px !important;
  }

  .pd-lg-65 {
    padding: 65px;
  }

  .pd-lg-65-force {
    padding: 65px !important;
  }

  .pd-lg-y-65 {
    padding-top: 65px;
    padding-bottom: 65px;
  }

  .pd-lg-y-65-force {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }

  .pd-lg-x-65 {
    padding-left: 65px;
    padding-right: 65px;
  }

  .pd-lg-x-65-force {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }

  .pd-lg-t-65 {
    padding-top: 65px;
  }

  .pd-lg-r-65 {
    padding-right: 65px;
  }

  .pd-lg-b-65 {
    padding-bottom: 65px;
  }

  .pd-lg-l-65 {
    padding-left: 65px;
  }

  .pd-lg-t-65-force {
    padding-top: 65px !important;
  }

  .pd-lg-r-65-force {
    padding-right: 65px !important;
  }

  .pd-lg-b-65-force {
    padding-bottom: 65px !important;
  }

  .pd-lg-l-65-force {
    padding-left: 65px !important;
  }

  .pd-lg-70 {
    padding: 70px;
  }

  .pd-lg-70-force {
    padding: 70px !important;
  }

  .pd-lg-y-70 {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .pd-lg-y-70-force {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }

  .pd-lg-x-70 {
    padding-left: 70px;
    padding-right: 70px;
  }

  .pd-lg-x-70-force {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }

  .pd-lg-t-70 {
    padding-top: 70px;
  }

  .pd-lg-r-70 {
    padding-right: 70px;
  }

  .pd-lg-b-70 {
    padding-bottom: 70px;
  }

  .pd-lg-l-70 {
    padding-left: 70px;
  }

  .pd-lg-t-70-force {
    padding-top: 70px !important;
  }

  .pd-lg-r-70-force {
    padding-right: 70px !important;
  }

  .pd-lg-b-70-force {
    padding-bottom: 70px !important;
  }

  .pd-lg-l-70-force {
    padding-left: 70px !important;
  }

  .pd-lg-75 {
    padding: 75px;
  }

  .pd-lg-75-force {
    padding: 75px !important;
  }

  .pd-lg-y-75 {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .pd-lg-y-75-force {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }

  .pd-lg-x-75 {
    padding-left: 75px;
    padding-right: 75px;
  }

  .pd-lg-x-75-force {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }

  .pd-lg-t-75 {
    padding-top: 75px;
  }

  .pd-lg-r-75 {
    padding-right: 75px;
  }

  .pd-lg-b-75 {
    padding-bottom: 75px;
  }

  .pd-lg-l-75 {
    padding-left: 75px;
  }

  .pd-lg-t-75-force {
    padding-top: 75px !important;
  }

  .pd-lg-r-75-force {
    padding-right: 75px !important;
  }

  .pd-lg-b-75-force {
    padding-bottom: 75px !important;
  }

  .pd-lg-l-75-force {
    padding-left: 75px !important;
  }

  .pd-lg-80 {
    padding: 80px;
  }

  .pd-lg-80-force {
    padding: 80px !important;
  }

  .pd-lg-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .pd-lg-y-80-force {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }

  .pd-lg-x-80 {
    padding-left: 80px;
    padding-right: 80px;
  }

  .pd-lg-x-80-force {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }

  .pd-lg-t-80 {
    padding-top: 80px;
  }

  .pd-lg-r-80 {
    padding-right: 80px;
  }

  .pd-lg-b-80 {
    padding-bottom: 80px;
  }

  .pd-lg-l-80 {
    padding-left: 80px;
  }

  .pd-lg-t-80-force {
    padding-top: 80px !important;
  }

  .pd-lg-r-80-force {
    padding-right: 80px !important;
  }

  .pd-lg-b-80-force {
    padding-bottom: 80px !important;
  }

  .pd-lg-l-80-force {
    padding-left: 80px !important;
  }

  .pd-lg-85 {
    padding: 85px;
  }

  .pd-lg-85-force {
    padding: 85px !important;
  }

  .pd-lg-y-85 {
    padding-top: 85px;
    padding-bottom: 85px;
  }

  .pd-lg-y-85-force {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }

  .pd-lg-x-85 {
    padding-left: 85px;
    padding-right: 85px;
  }

  .pd-lg-x-85-force {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }

  .pd-lg-t-85 {
    padding-top: 85px;
  }

  .pd-lg-r-85 {
    padding-right: 85px;
  }

  .pd-lg-b-85 {
    padding-bottom: 85px;
  }

  .pd-lg-l-85 {
    padding-left: 85px;
  }

  .pd-lg-t-85-force {
    padding-top: 85px !important;
  }

  .pd-lg-r-85-force {
    padding-right: 85px !important;
  }

  .pd-lg-b-85-force {
    padding-bottom: 85px !important;
  }

  .pd-lg-l-85-force {
    padding-left: 85px !important;
  }

  .pd-lg-90 {
    padding: 90px;
  }

  .pd-lg-90-force {
    padding: 90px !important;
  }

  .pd-lg-y-90 {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .pd-lg-y-90-force {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }

  .pd-lg-x-90 {
    padding-left: 90px;
    padding-right: 90px;
  }

  .pd-lg-x-90-force {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }

  .pd-lg-t-90 {
    padding-top: 90px;
  }

  .pd-lg-r-90 {
    padding-right: 90px;
  }

  .pd-lg-b-90 {
    padding-bottom: 90px;
  }

  .pd-lg-l-90 {
    padding-left: 90px;
  }

  .pd-lg-t-90-force {
    padding-top: 90px !important;
  }

  .pd-lg-r-90-force {
    padding-right: 90px !important;
  }

  .pd-lg-b-90-force {
    padding-bottom: 90px !important;
  }

  .pd-lg-l-90-force {
    padding-left: 90px !important;
  }

  .pd-lg-95 {
    padding: 95px;
  }

  .pd-lg-95-force {
    padding: 95px !important;
  }

  .pd-lg-y-95 {
    padding-top: 95px;
    padding-bottom: 95px;
  }

  .pd-lg-y-95-force {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }

  .pd-lg-x-95 {
    padding-left: 95px;
    padding-right: 95px;
  }

  .pd-lg-x-95-force {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }

  .pd-lg-t-95 {
    padding-top: 95px;
  }

  .pd-lg-r-95 {
    padding-right: 95px;
  }

  .pd-lg-b-95 {
    padding-bottom: 95px;
  }

  .pd-lg-l-95 {
    padding-left: 95px;
  }

  .pd-lg-t-95-force {
    padding-top: 95px !important;
  }

  .pd-lg-r-95-force {
    padding-right: 95px !important;
  }

  .pd-lg-b-95-force {
    padding-bottom: 95px !important;
  }

  .pd-lg-l-95-force {
    padding-left: 95px !important;
  }

  .pd-lg-100 {
    padding: 100px;
  }

  .pd-lg-100-force {
    padding: 100px !important;
  }

  .pd-lg-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .pd-lg-y-100-force {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }

  .pd-lg-x-100 {
    padding-left: 100px;
    padding-right: 100px;
  }

  .pd-lg-x-100-force {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }

  .pd-lg-t-100 {
    padding-top: 100px;
  }

  .pd-lg-r-100 {
    padding-right: 100px;
  }

  .pd-lg-b-100 {
    padding-bottom: 100px;
  }

  .pd-lg-l-100 {
    padding-left: 100px;
  }

  .pd-lg-t-100-force {
    padding-top: 100px !important;
  }

  .pd-lg-r-100-force {
    padding-right: 100px !important;
  }

  .pd-lg-b-100-force {
    padding-bottom: 100px !important;
  }

  .pd-lg-l-100-force {
    padding-left: 100px !important;
  }

  .pd-lg-110 {
    padding: 110px;
  }

  .pd-lg-110-force {
    padding: 110px !important;
  }

  .pd-lg-y-110 {
    padding-top: 110px;
    padding-bottom: 110px;
  }

  .pd-lg-y-110-force {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }

  .pd-lg-x-110 {
    padding-left: 110px;
    padding-right: 110px;
  }

  .pd-lg-x-110-force {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }

  .pd-lg-t-110 {
    padding-top: 110px;
  }

  .pd-lg-r-110 {
    padding-right: 110px;
  }

  .pd-lg-b-110 {
    padding-bottom: 110px;
  }

  .pd-lg-l-110 {
    padding-left: 110px;
  }

  .pd-lg-t-110-force {
    padding-top: 110px !important;
  }

  .pd-lg-r-110-force {
    padding-right: 110px !important;
  }

  .pd-lg-b-110-force {
    padding-bottom: 110px !important;
  }

  .pd-lg-l-110-force {
    padding-left: 110px !important;
  }

  .pd-lg-120 {
    padding: 120px;
  }

  .pd-lg-120-force {
    padding: 120px !important;
  }

  .pd-lg-y-120 {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .pd-lg-y-120-force {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }

  .pd-lg-x-120 {
    padding-left: 120px;
    padding-right: 120px;
  }

  .pd-lg-x-120-force {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }

  .pd-lg-t-120 {
    padding-top: 120px;
  }

  .pd-lg-r-120 {
    padding-right: 120px;
  }

  .pd-lg-b-120 {
    padding-bottom: 120px;
  }

  .pd-lg-l-120 {
    padding-left: 120px;
  }

  .pd-lg-t-120-force {
    padding-top: 120px !important;
  }

  .pd-lg-r-120-force {
    padding-right: 120px !important;
  }

  .pd-lg-b-120-force {
    padding-bottom: 120px !important;
  }

  .pd-lg-l-120-force {
    padding-left: 120px !important;
  }

  .pd-lg-130 {
    padding: 130px;
  }

  .pd-lg-130-force {
    padding: 130px !important;
  }

  .pd-lg-y-130 {
    padding-top: 130px;
    padding-bottom: 130px;
  }

  .pd-lg-y-130-force {
    padding-top: 130px !important;
    padding-bottom: 130px !important;
  }

  .pd-lg-x-130 {
    padding-left: 130px;
    padding-right: 130px;
  }

  .pd-lg-x-130-force {
    padding-left: 130px !important;
    padding-right: 130px !important;
  }

  .pd-lg-t-130 {
    padding-top: 130px;
  }

  .pd-lg-r-130 {
    padding-right: 130px;
  }

  .pd-lg-b-130 {
    padding-bottom: 130px;
  }

  .pd-lg-l-130 {
    padding-left: 130px;
  }

  .pd-lg-t-130-force {
    padding-top: 130px !important;
  }

  .pd-lg-r-130-force {
    padding-right: 130px !important;
  }

  .pd-lg-b-130-force {
    padding-bottom: 130px !important;
  }

  .pd-lg-l-130-force {
    padding-left: 130px !important;
  }

  .pd-lg-140 {
    padding: 140px;
  }

  .pd-lg-140-force {
    padding: 140px !important;
  }

  .pd-lg-y-140 {
    padding-top: 140px;
    padding-bottom: 140px;
  }

  .pd-lg-y-140-force {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }

  .pd-lg-x-140 {
    padding-left: 140px;
    padding-right: 140px;
  }

  .pd-lg-x-140-force {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }

  .pd-lg-t-140 {
    padding-top: 140px;
  }

  .pd-lg-r-140 {
    padding-right: 140px;
  }

  .pd-lg-b-140 {
    padding-bottom: 140px;
  }

  .pd-lg-l-140 {
    padding-left: 140px;
  }

  .pd-lg-t-140-force {
    padding-top: 140px !important;
  }

  .pd-lg-r-140-force {
    padding-right: 140px !important;
  }

  .pd-lg-b-140-force {
    padding-bottom: 140px !important;
  }

  .pd-lg-l-140-force {
    padding-left: 140px !important;
  }

  .pd-lg-150 {
    padding: 150px;
  }

  .pd-lg-150-force {
    padding: 150px !important;
  }

  .pd-lg-y-150 {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .pd-lg-y-150-force {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }

  .pd-lg-x-150 {
    padding-left: 150px;
    padding-right: 150px;
  }

  .pd-lg-x-150-force {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }

  .pd-lg-t-150 {
    padding-top: 150px;
  }

  .pd-lg-r-150 {
    padding-right: 150px;
  }

  .pd-lg-b-150 {
    padding-bottom: 150px;
  }

  .pd-lg-l-150 {
    padding-left: 150px;
  }

  .pd-lg-t-150-force {
    padding-top: 150px !important;
  }

  .pd-lg-r-150-force {
    padding-right: 150px !important;
  }

  .pd-lg-b-150-force {
    padding-bottom: 150px !important;
  }

  .pd-lg-l-150-force {
    padding-left: 150px !important;
  }

  .pd-lg-160 {
    padding: 160px;
  }

  .pd-lg-160-force {
    padding: 160px !important;
  }

  .pd-lg-y-160 {
    padding-top: 160px;
    padding-bottom: 160px;
  }

  .pd-lg-y-160-force {
    padding-top: 160px !important;
    padding-bottom: 160px !important;
  }

  .pd-lg-x-160 {
    padding-left: 160px;
    padding-right: 160px;
  }

  .pd-lg-x-160-force {
    padding-left: 160px !important;
    padding-right: 160px !important;
  }

  .pd-lg-t-160 {
    padding-top: 160px;
  }

  .pd-lg-r-160 {
    padding-right: 160px;
  }

  .pd-lg-b-160 {
    padding-bottom: 160px;
  }

  .pd-lg-l-160 {
    padding-left: 160px;
  }

  .pd-lg-t-160-force {
    padding-top: 160px !important;
  }

  .pd-lg-r-160-force {
    padding-right: 160px !important;
  }

  .pd-lg-b-160-force {
    padding-bottom: 160px !important;
  }

  .pd-lg-l-160-force {
    padding-left: 160px !important;
  }

  .pd-lg-170 {
    padding: 170px;
  }

  .pd-lg-170-force {
    padding: 170px !important;
  }

  .pd-lg-y-170 {
    padding-top: 170px;
    padding-bottom: 170px;
  }

  .pd-lg-y-170-force {
    padding-top: 170px !important;
    padding-bottom: 170px !important;
  }

  .pd-lg-x-170 {
    padding-left: 170px;
    padding-right: 170px;
  }

  .pd-lg-x-170-force {
    padding-left: 170px !important;
    padding-right: 170px !important;
  }

  .pd-lg-t-170 {
    padding-top: 170px;
  }

  .pd-lg-r-170 {
    padding-right: 170px;
  }

  .pd-lg-b-170 {
    padding-bottom: 170px;
  }

  .pd-lg-l-170 {
    padding-left: 170px;
  }

  .pd-lg-t-170-force {
    padding-top: 170px !important;
  }

  .pd-lg-r-170-force {
    padding-right: 170px !important;
  }

  .pd-lg-b-170-force {
    padding-bottom: 170px !important;
  }

  .pd-lg-l-170-force {
    padding-left: 170px !important;
  }

  .pd-lg-180 {
    padding: 180px;
  }

  .pd-lg-180-force {
    padding: 180px !important;
  }

  .pd-lg-y-180 {
    padding-top: 180px;
    padding-bottom: 180px;
  }

  .pd-lg-y-180-force {
    padding-top: 180px !important;
    padding-bottom: 180px !important;
  }

  .pd-lg-x-180 {
    padding-left: 180px;
    padding-right: 180px;
  }

  .pd-lg-x-180-force {
    padding-left: 180px !important;
    padding-right: 180px !important;
  }

  .pd-lg-t-180 {
    padding-top: 180px;
  }

  .pd-lg-r-180 {
    padding-right: 180px;
  }

  .pd-lg-b-180 {
    padding-bottom: 180px;
  }

  .pd-lg-l-180 {
    padding-left: 180px;
  }

  .pd-lg-t-180-force {
    padding-top: 180px !important;
  }

  .pd-lg-r-180-force {
    padding-right: 180px !important;
  }

  .pd-lg-b-180-force {
    padding-bottom: 180px !important;
  }

  .pd-lg-l-180-force {
    padding-left: 180px !important;
  }

  .pd-lg-190 {
    padding: 190px;
  }

  .pd-lg-190-force {
    padding: 190px !important;
  }

  .pd-lg-y-190 {
    padding-top: 190px;
    padding-bottom: 190px;
  }

  .pd-lg-y-190-force {
    padding-top: 190px !important;
    padding-bottom: 190px !important;
  }

  .pd-lg-x-190 {
    padding-left: 190px;
    padding-right: 190px;
  }

  .pd-lg-x-190-force {
    padding-left: 190px !important;
    padding-right: 190px !important;
  }

  .pd-lg-t-190 {
    padding-top: 190px;
  }

  .pd-lg-r-190 {
    padding-right: 190px;
  }

  .pd-lg-b-190 {
    padding-bottom: 190px;
  }

  .pd-lg-l-190 {
    padding-left: 190px;
  }

  .pd-lg-t-190-force {
    padding-top: 190px !important;
  }

  .pd-lg-r-190-force {
    padding-right: 190px !important;
  }

  .pd-lg-b-190-force {
    padding-bottom: 190px !important;
  }

  .pd-lg-l-190-force {
    padding-left: 190px !important;
  }

  .pd-lg-200 {
    padding: 200px;
  }

  .pd-lg-200-force {
    padding: 200px !important;
  }

  .pd-lg-y-200 {
    padding-top: 200px;
    padding-bottom: 200px;
  }

  .pd-lg-y-200-force {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }

  .pd-lg-x-200 {
    padding-left: 200px;
    padding-right: 200px;
  }

  .pd-lg-x-200-force {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }

  .pd-lg-t-200 {
    padding-top: 200px;
  }

  .pd-lg-r-200 {
    padding-right: 200px;
  }

  .pd-lg-b-200 {
    padding-bottom: 200px;
  }

  .pd-lg-l-200 {
    padding-left: 200px;
  }

  .pd-lg-t-200-force {
    padding-top: 200px !important;
  }

  .pd-lg-r-200-force {
    padding-right: 200px !important;
  }

  .pd-lg-b-200-force {
    padding-bottom: 200px !important;
  }

  .pd-lg-l-200-force {
    padding-left: 200px !important;
  }
}
@media (min-width: 1200px) {
  .pd-xl-0 {
    padding: 0px;
  }

  .pd-xl-0-force {
    padding: 0px !important;
  }

  .pd-xl-y-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .pd-xl-y-0-force {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .pd-xl-x-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .pd-xl-x-0-force {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .pd-xl-t-0 {
    padding-top: 0px;
  }

  .pd-xl-r-0 {
    padding-right: 0px;
  }

  .pd-xl-b-0 {
    padding-bottom: 0px;
  }

  .pd-xl-l-0 {
    padding-left: 0px;
  }

  .pd-xl-t-0-force {
    padding-top: 0px !important;
  }

  .pd-xl-r-0-force {
    padding-right: 0px !important;
  }

  .pd-xl-b-0-force {
    padding-bottom: 0px !important;
  }

  .pd-xl-l-0-force {
    padding-left: 0px !important;
  }

  .pd-xl-1 {
    padding: 1px;
  }

  .pd-xl-1-force {
    padding: 1px !important;
  }

  .pd-xl-y-1 {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .pd-xl-y-1-force {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .pd-xl-x-1 {
    padding-left: 1px;
    padding-right: 1px;
  }

  .pd-xl-x-1-force {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .pd-xl-t-1 {
    padding-top: 1px;
  }

  .pd-xl-r-1 {
    padding-right: 1px;
  }

  .pd-xl-b-1 {
    padding-bottom: 1px;
  }

  .pd-xl-l-1 {
    padding-left: 1px;
  }

  .pd-xl-t-1-force {
    padding-top: 1px !important;
  }

  .pd-xl-r-1-force {
    padding-right: 1px !important;
  }

  .pd-xl-b-1-force {
    padding-bottom: 1px !important;
  }

  .pd-xl-l-1-force {
    padding-left: 1px !important;
  }

  .pd-xl-2 {
    padding: 2px;
  }

  .pd-xl-2-force {
    padding: 2px !important;
  }

  .pd-xl-y-2 {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .pd-xl-y-2-force {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  .pd-xl-x-2 {
    padding-left: 2px;
    padding-right: 2px;
  }

  .pd-xl-x-2-force {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .pd-xl-t-2 {
    padding-top: 2px;
  }

  .pd-xl-r-2 {
    padding-right: 2px;
  }

  .pd-xl-b-2 {
    padding-bottom: 2px;
  }

  .pd-xl-l-2 {
    padding-left: 2px;
  }

  .pd-xl-t-2-force {
    padding-top: 2px !important;
  }

  .pd-xl-r-2-force {
    padding-right: 2px !important;
  }

  .pd-xl-b-2-force {
    padding-bottom: 2px !important;
  }

  .pd-xl-l-2-force {
    padding-left: 2px !important;
  }

  .pd-xl-3 {
    padding: 3px;
  }

  .pd-xl-3-force {
    padding: 3px !important;
  }

  .pd-xl-y-3 {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .pd-xl-y-3-force {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }

  .pd-xl-x-3 {
    padding-left: 3px;
    padding-right: 3px;
  }

  .pd-xl-x-3-force {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }

  .pd-xl-t-3 {
    padding-top: 3px;
  }

  .pd-xl-r-3 {
    padding-right: 3px;
  }

  .pd-xl-b-3 {
    padding-bottom: 3px;
  }

  .pd-xl-l-3 {
    padding-left: 3px;
  }

  .pd-xl-t-3-force {
    padding-top: 3px !important;
  }

  .pd-xl-r-3-force {
    padding-right: 3px !important;
  }

  .pd-xl-b-3-force {
    padding-bottom: 3px !important;
  }

  .pd-xl-l-3-force {
    padding-left: 3px !important;
  }

  .pd-xl-4 {
    padding: 4px;
  }

  .pd-xl-4-force {
    padding: 4px !important;
  }

  .pd-xl-y-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .pd-xl-y-4-force {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .pd-xl-x-4 {
    padding-left: 4px;
    padding-right: 4px;
  }

  .pd-xl-x-4-force {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .pd-xl-t-4 {
    padding-top: 4px;
  }

  .pd-xl-r-4 {
    padding-right: 4px;
  }

  .pd-xl-b-4 {
    padding-bottom: 4px;
  }

  .pd-xl-l-4 {
    padding-left: 4px;
  }

  .pd-xl-t-4-force {
    padding-top: 4px !important;
  }

  .pd-xl-r-4-force {
    padding-right: 4px !important;
  }

  .pd-xl-b-4-force {
    padding-bottom: 4px !important;
  }

  .pd-xl-l-4-force {
    padding-left: 4px !important;
  }

  .pd-xl-5 {
    padding: 5px;
  }

  .pd-xl-5-force {
    padding: 5px !important;
  }

  .pd-xl-y-5 {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .pd-xl-y-5-force {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .pd-xl-x-5 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .pd-xl-x-5-force {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .pd-xl-t-5 {
    padding-top: 5px;
  }

  .pd-xl-r-5 {
    padding-right: 5px;
  }

  .pd-xl-b-5 {
    padding-bottom: 5px;
  }

  .pd-xl-l-5 {
    padding-left: 5px;
  }

  .pd-xl-t-5-force {
    padding-top: 5px !important;
  }

  .pd-xl-r-5-force {
    padding-right: 5px !important;
  }

  .pd-xl-b-5-force {
    padding-bottom: 5px !important;
  }

  .pd-xl-l-5-force {
    padding-left: 5px !important;
  }

  .pd-xl-6 {
    padding: 6px;
  }

  .pd-xl-6-force {
    padding: 6px !important;
  }

  .pd-xl-y-6 {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .pd-xl-y-6-force {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }

  .pd-xl-x-6 {
    padding-left: 6px;
    padding-right: 6px;
  }

  .pd-xl-x-6-force {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  .pd-xl-t-6 {
    padding-top: 6px;
  }

  .pd-xl-r-6 {
    padding-right: 6px;
  }

  .pd-xl-b-6 {
    padding-bottom: 6px;
  }

  .pd-xl-l-6 {
    padding-left: 6px;
  }

  .pd-xl-t-6-force {
    padding-top: 6px !important;
  }

  .pd-xl-r-6-force {
    padding-right: 6px !important;
  }

  .pd-xl-b-6-force {
    padding-bottom: 6px !important;
  }

  .pd-xl-l-6-force {
    padding-left: 6px !important;
  }

  .pd-xl-7 {
    padding: 7px;
  }

  .pd-xl-7-force {
    padding: 7px !important;
  }

  .pd-xl-y-7 {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .pd-xl-y-7-force {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
  }

  .pd-xl-x-7 {
    padding-left: 7px;
    padding-right: 7px;
  }

  .pd-xl-x-7-force {
    padding-left: 7px !important;
    padding-right: 7px !important;
  }

  .pd-xl-t-7 {
    padding-top: 7px;
  }

  .pd-xl-r-7 {
    padding-right: 7px;
  }

  .pd-xl-b-7 {
    padding-bottom: 7px;
  }

  .pd-xl-l-7 {
    padding-left: 7px;
  }

  .pd-xl-t-7-force {
    padding-top: 7px !important;
  }

  .pd-xl-r-7-force {
    padding-right: 7px !important;
  }

  .pd-xl-b-7-force {
    padding-bottom: 7px !important;
  }

  .pd-xl-l-7-force {
    padding-left: 7px !important;
  }

  .pd-xl-8 {
    padding: 8px;
  }

  .pd-xl-8-force {
    padding: 8px !important;
  }

  .pd-xl-y-8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .pd-xl-y-8-force {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .pd-xl-x-8 {
    padding-left: 8px;
    padding-right: 8px;
  }

  .pd-xl-x-8-force {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .pd-xl-t-8 {
    padding-top: 8px;
  }

  .pd-xl-r-8 {
    padding-right: 8px;
  }

  .pd-xl-b-8 {
    padding-bottom: 8px;
  }

  .pd-xl-l-8 {
    padding-left: 8px;
  }

  .pd-xl-t-8-force {
    padding-top: 8px !important;
  }

  .pd-xl-r-8-force {
    padding-right: 8px !important;
  }

  .pd-xl-b-8-force {
    padding-bottom: 8px !important;
  }

  .pd-xl-l-8-force {
    padding-left: 8px !important;
  }

  .pd-xl-9 {
    padding: 9px;
  }

  .pd-xl-9-force {
    padding: 9px !important;
  }

  .pd-xl-y-9 {
    padding-top: 9px;
    padding-bottom: 9px;
  }

  .pd-xl-y-9-force {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
  }

  .pd-xl-x-9 {
    padding-left: 9px;
    padding-right: 9px;
  }

  .pd-xl-x-9-force {
    padding-left: 9px !important;
    padding-right: 9px !important;
  }

  .pd-xl-t-9 {
    padding-top: 9px;
  }

  .pd-xl-r-9 {
    padding-right: 9px;
  }

  .pd-xl-b-9 {
    padding-bottom: 9px;
  }

  .pd-xl-l-9 {
    padding-left: 9px;
  }

  .pd-xl-t-9-force {
    padding-top: 9px !important;
  }

  .pd-xl-r-9-force {
    padding-right: 9px !important;
  }

  .pd-xl-b-9-force {
    padding-bottom: 9px !important;
  }

  .pd-xl-l-9-force {
    padding-left: 9px !important;
  }

  .pd-xl-10 {
    padding: 10px;
  }

  .pd-xl-10-force {
    padding: 10px !important;
  }

  .pd-xl-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .pd-xl-y-10-force {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .pd-xl-x-10 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .pd-xl-x-10-force {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .pd-xl-t-10 {
    padding-top: 10px;
  }

  .pd-xl-r-10 {
    padding-right: 10px;
  }

  .pd-xl-b-10 {
    padding-bottom: 10px;
  }

  .pd-xl-l-10 {
    padding-left: 10px;
  }

  .pd-xl-t-10-force {
    padding-top: 10px !important;
  }

  .pd-xl-r-10-force {
    padding-right: 10px !important;
  }

  .pd-xl-b-10-force {
    padding-bottom: 10px !important;
  }

  .pd-xl-l-10-force {
    padding-left: 10px !important;
  }

  .pd-xl-15 {
    padding: 15px;
  }

  .pd-xl-15-force {
    padding: 15px !important;
  }

  .pd-xl-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .pd-xl-y-15-force {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .pd-xl-x-15 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .pd-xl-x-15-force {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .pd-xl-t-15 {
    padding-top: 15px;
  }

  .pd-xl-r-15 {
    padding-right: 15px;
  }

  .pd-xl-b-15 {
    padding-bottom: 15px;
  }

  .pd-xl-l-15 {
    padding-left: 15px;
  }

  .pd-xl-t-15-force {
    padding-top: 15px !important;
  }

  .pd-xl-r-15-force {
    padding-right: 15px !important;
  }

  .pd-xl-b-15-force {
    padding-bottom: 15px !important;
  }

  .pd-xl-l-15-force {
    padding-left: 15px !important;
  }

  .pd-xl-20 {
    padding: 20px;
  }

  .pd-xl-20-force {
    padding: 20px !important;
  }

  .pd-xl-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .pd-xl-y-20-force {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .pd-xl-x-20 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .pd-xl-x-20-force {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .pd-xl-t-20 {
    padding-top: 20px;
  }

  .pd-xl-r-20 {
    padding-right: 20px;
  }

  .pd-xl-b-20 {
    padding-bottom: 20px;
  }

  .pd-xl-l-20 {
    padding-left: 20px;
  }

  .pd-xl-t-20-force {
    padding-top: 20px !important;
  }

  .pd-xl-r-20-force {
    padding-right: 20px !important;
  }

  .pd-xl-b-20-force {
    padding-bottom: 20px !important;
  }

  .pd-xl-l-20-force {
    padding-left: 20px !important;
  }

  .pd-xl-25 {
    padding: 25px;
  }

  .pd-xl-25-force {
    padding: 25px !important;
  }

  .pd-xl-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .pd-xl-y-25-force {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .pd-xl-x-25 {
    padding-left: 25px;
    padding-right: 25px;
  }

  .pd-xl-x-25-force {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .pd-xl-t-25 {
    padding-top: 25px;
  }

  .pd-xl-r-25 {
    padding-right: 25px;
  }

  .pd-xl-b-25 {
    padding-bottom: 25px;
  }

  .pd-xl-l-25 {
    padding-left: 25px;
  }

  .pd-xl-t-25-force {
    padding-top: 25px !important;
  }

  .pd-xl-r-25-force {
    padding-right: 25px !important;
  }

  .pd-xl-b-25-force {
    padding-bottom: 25px !important;
  }

  .pd-xl-l-25-force {
    padding-left: 25px !important;
  }

  .pd-xl-30 {
    padding: 30px;
  }

  .pd-xl-30-force {
    padding: 30px !important;
  }

  .pd-xl-y-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .pd-xl-y-30-force {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .pd-xl-x-30 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .pd-xl-x-30-force {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .pd-xl-t-30 {
    padding-top: 30px;
  }

  .pd-xl-r-30 {
    padding-right: 30px;
  }

  .pd-xl-b-30 {
    padding-bottom: 30px;
  }

  .pd-xl-l-30 {
    padding-left: 30px;
  }

  .pd-xl-t-30-force {
    padding-top: 30px !important;
  }

  .pd-xl-r-30-force {
    padding-right: 30px !important;
  }

  .pd-xl-b-30-force {
    padding-bottom: 30px !important;
  }

  .pd-xl-l-30-force {
    padding-left: 30px !important;
  }

  .pd-xl-35 {
    padding: 35px;
  }

  .pd-xl-35-force {
    padding: 35px !important;
  }

  .pd-xl-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  .pd-xl-y-35-force {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }

  .pd-xl-x-35 {
    padding-left: 35px;
    padding-right: 35px;
  }

  .pd-xl-x-35-force {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }

  .pd-xl-t-35 {
    padding-top: 35px;
  }

  .pd-xl-r-35 {
    padding-right: 35px;
  }

  .pd-xl-b-35 {
    padding-bottom: 35px;
  }

  .pd-xl-l-35 {
    padding-left: 35px;
  }

  .pd-xl-t-35-force {
    padding-top: 35px !important;
  }

  .pd-xl-r-35-force {
    padding-right: 35px !important;
  }

  .pd-xl-b-35-force {
    padding-bottom: 35px !important;
  }

  .pd-xl-l-35-force {
    padding-left: 35px !important;
  }

  .pd-xl-40 {
    padding: 40px;
  }

  .pd-xl-40-force {
    padding: 40px !important;
  }

  .pd-xl-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .pd-xl-y-40-force {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .pd-xl-x-40 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .pd-xl-x-40-force {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .pd-xl-t-40 {
    padding-top: 40px;
  }

  .pd-xl-r-40 {
    padding-right: 40px;
  }

  .pd-xl-b-40 {
    padding-bottom: 40px;
  }

  .pd-xl-l-40 {
    padding-left: 40px;
  }

  .pd-xl-t-40-force {
    padding-top: 40px !important;
  }

  .pd-xl-r-40-force {
    padding-right: 40px !important;
  }

  .pd-xl-b-40-force {
    padding-bottom: 40px !important;
  }

  .pd-xl-l-40-force {
    padding-left: 40px !important;
  }

  .pd-xl-45 {
    padding: 45px;
  }

  .pd-xl-45-force {
    padding: 45px !important;
  }

  .pd-xl-y-45 {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .pd-xl-y-45-force {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }

  .pd-xl-x-45 {
    padding-left: 45px;
    padding-right: 45px;
  }

  .pd-xl-x-45-force {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }

  .pd-xl-t-45 {
    padding-top: 45px;
  }

  .pd-xl-r-45 {
    padding-right: 45px;
  }

  .pd-xl-b-45 {
    padding-bottom: 45px;
  }

  .pd-xl-l-45 {
    padding-left: 45px;
  }

  .pd-xl-t-45-force {
    padding-top: 45px !important;
  }

  .pd-xl-r-45-force {
    padding-right: 45px !important;
  }

  .pd-xl-b-45-force {
    padding-bottom: 45px !important;
  }

  .pd-xl-l-45-force {
    padding-left: 45px !important;
  }

  .pd-xl-50 {
    padding: 50px;
  }

  .pd-xl-50-force {
    padding: 50px !important;
  }

  .pd-xl-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .pd-xl-y-50-force {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .pd-xl-x-50 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .pd-xl-x-50-force {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .pd-xl-t-50 {
    padding-top: 50px;
  }

  .pd-xl-r-50 {
    padding-right: 50px;
  }

  .pd-xl-b-50 {
    padding-bottom: 50px;
  }

  .pd-xl-l-50 {
    padding-left: 50px;
  }

  .pd-xl-t-50-force {
    padding-top: 50px !important;
  }

  .pd-xl-r-50-force {
    padding-right: 50px !important;
  }

  .pd-xl-b-50-force {
    padding-bottom: 50px !important;
  }

  .pd-xl-l-50-force {
    padding-left: 50px !important;
  }

  .pd-xl-55 {
    padding: 55px;
  }

  .pd-xl-55-force {
    padding: 55px !important;
  }

  .pd-xl-y-55 {
    padding-top: 55px;
    padding-bottom: 55px;
  }

  .pd-xl-y-55-force {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }

  .pd-xl-x-55 {
    padding-left: 55px;
    padding-right: 55px;
  }

  .pd-xl-x-55-force {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }

  .pd-xl-t-55 {
    padding-top: 55px;
  }

  .pd-xl-r-55 {
    padding-right: 55px;
  }

  .pd-xl-b-55 {
    padding-bottom: 55px;
  }

  .pd-xl-l-55 {
    padding-left: 55px;
  }

  .pd-xl-t-55-force {
    padding-top: 55px !important;
  }

  .pd-xl-r-55-force {
    padding-right: 55px !important;
  }

  .pd-xl-b-55-force {
    padding-bottom: 55px !important;
  }

  .pd-xl-l-55-force {
    padding-left: 55px !important;
  }

  .pd-xl-60 {
    padding: 60px;
  }

  .pd-xl-60-force {
    padding: 60px !important;
  }

  .pd-xl-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .pd-xl-y-60-force {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  .pd-xl-x-60 {
    padding-left: 60px;
    padding-right: 60px;
  }

  .pd-xl-x-60-force {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .pd-xl-t-60 {
    padding-top: 60px;
  }

  .pd-xl-r-60 {
    padding-right: 60px;
  }

  .pd-xl-b-60 {
    padding-bottom: 60px;
  }

  .pd-xl-l-60 {
    padding-left: 60px;
  }

  .pd-xl-t-60-force {
    padding-top: 60px !important;
  }

  .pd-xl-r-60-force {
    padding-right: 60px !important;
  }

  .pd-xl-b-60-force {
    padding-bottom: 60px !important;
  }

  .pd-xl-l-60-force {
    padding-left: 60px !important;
  }

  .pd-xl-65 {
    padding: 65px;
  }

  .pd-xl-65-force {
    padding: 65px !important;
  }

  .pd-xl-y-65 {
    padding-top: 65px;
    padding-bottom: 65px;
  }

  .pd-xl-y-65-force {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }

  .pd-xl-x-65 {
    padding-left: 65px;
    padding-right: 65px;
  }

  .pd-xl-x-65-force {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }

  .pd-xl-t-65 {
    padding-top: 65px;
  }

  .pd-xl-r-65 {
    padding-right: 65px;
  }

  .pd-xl-b-65 {
    padding-bottom: 65px;
  }

  .pd-xl-l-65 {
    padding-left: 65px;
  }

  .pd-xl-t-65-force {
    padding-top: 65px !important;
  }

  .pd-xl-r-65-force {
    padding-right: 65px !important;
  }

  .pd-xl-b-65-force {
    padding-bottom: 65px !important;
  }

  .pd-xl-l-65-force {
    padding-left: 65px !important;
  }

  .pd-xl-70 {
    padding: 70px;
  }

  .pd-xl-70-force {
    padding: 70px !important;
  }

  .pd-xl-y-70 {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .pd-xl-y-70-force {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }

  .pd-xl-x-70 {
    padding-left: 70px;
    padding-right: 70px;
  }

  .pd-xl-x-70-force {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }

  .pd-xl-t-70 {
    padding-top: 70px;
  }

  .pd-xl-r-70 {
    padding-right: 70px;
  }

  .pd-xl-b-70 {
    padding-bottom: 70px;
  }

  .pd-xl-l-70 {
    padding-left: 70px;
  }

  .pd-xl-t-70-force {
    padding-top: 70px !important;
  }

  .pd-xl-r-70-force {
    padding-right: 70px !important;
  }

  .pd-xl-b-70-force {
    padding-bottom: 70px !important;
  }

  .pd-xl-l-70-force {
    padding-left: 70px !important;
  }

  .pd-xl-75 {
    padding: 75px;
  }

  .pd-xl-75-force {
    padding: 75px !important;
  }

  .pd-xl-y-75 {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .pd-xl-y-75-force {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }

  .pd-xl-x-75 {
    padding-left: 75px;
    padding-right: 75px;
  }

  .pd-xl-x-75-force {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }

  .pd-xl-t-75 {
    padding-top: 75px;
  }

  .pd-xl-r-75 {
    padding-right: 75px;
  }

  .pd-xl-b-75 {
    padding-bottom: 75px;
  }

  .pd-xl-l-75 {
    padding-left: 75px;
  }

  .pd-xl-t-75-force {
    padding-top: 75px !important;
  }

  .pd-xl-r-75-force {
    padding-right: 75px !important;
  }

  .pd-xl-b-75-force {
    padding-bottom: 75px !important;
  }

  .pd-xl-l-75-force {
    padding-left: 75px !important;
  }

  .pd-xl-80 {
    padding: 80px;
  }

  .pd-xl-80-force {
    padding: 80px !important;
  }

  .pd-xl-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .pd-xl-y-80-force {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }

  .pd-xl-x-80 {
    padding-left: 80px;
    padding-right: 80px;
  }

  .pd-xl-x-80-force {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }

  .pd-xl-t-80 {
    padding-top: 80px;
  }

  .pd-xl-r-80 {
    padding-right: 80px;
  }

  .pd-xl-b-80 {
    padding-bottom: 80px;
  }

  .pd-xl-l-80 {
    padding-left: 80px;
  }

  .pd-xl-t-80-force {
    padding-top: 80px !important;
  }

  .pd-xl-r-80-force {
    padding-right: 80px !important;
  }

  .pd-xl-b-80-force {
    padding-bottom: 80px !important;
  }

  .pd-xl-l-80-force {
    padding-left: 80px !important;
  }

  .pd-xl-85 {
    padding: 85px;
  }

  .pd-xl-85-force {
    padding: 85px !important;
  }

  .pd-xl-y-85 {
    padding-top: 85px;
    padding-bottom: 85px;
  }

  .pd-xl-y-85-force {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }

  .pd-xl-x-85 {
    padding-left: 85px;
    padding-right: 85px;
  }

  .pd-xl-x-85-force {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }

  .pd-xl-t-85 {
    padding-top: 85px;
  }

  .pd-xl-r-85 {
    padding-right: 85px;
  }

  .pd-xl-b-85 {
    padding-bottom: 85px;
  }

  .pd-xl-l-85 {
    padding-left: 85px;
  }

  .pd-xl-t-85-force {
    padding-top: 85px !important;
  }

  .pd-xl-r-85-force {
    padding-right: 85px !important;
  }

  .pd-xl-b-85-force {
    padding-bottom: 85px !important;
  }

  .pd-xl-l-85-force {
    padding-left: 85px !important;
  }

  .pd-xl-90 {
    padding: 90px;
  }

  .pd-xl-90-force {
    padding: 90px !important;
  }

  .pd-xl-y-90 {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .pd-xl-y-90-force {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }

  .pd-xl-x-90 {
    padding-left: 90px;
    padding-right: 90px;
  }

  .pd-xl-x-90-force {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }

  .pd-xl-t-90 {
    padding-top: 90px;
  }

  .pd-xl-r-90 {
    padding-right: 90px;
  }

  .pd-xl-b-90 {
    padding-bottom: 90px;
  }

  .pd-xl-l-90 {
    padding-left: 90px;
  }

  .pd-xl-t-90-force {
    padding-top: 90px !important;
  }

  .pd-xl-r-90-force {
    padding-right: 90px !important;
  }

  .pd-xl-b-90-force {
    padding-bottom: 90px !important;
  }

  .pd-xl-l-90-force {
    padding-left: 90px !important;
  }

  .pd-xl-95 {
    padding: 95px;
  }

  .pd-xl-95-force {
    padding: 95px !important;
  }

  .pd-xl-y-95 {
    padding-top: 95px;
    padding-bottom: 95px;
  }

  .pd-xl-y-95-force {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }

  .pd-xl-x-95 {
    padding-left: 95px;
    padding-right: 95px;
  }

  .pd-xl-x-95-force {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }

  .pd-xl-t-95 {
    padding-top: 95px;
  }

  .pd-xl-r-95 {
    padding-right: 95px;
  }

  .pd-xl-b-95 {
    padding-bottom: 95px;
  }

  .pd-xl-l-95 {
    padding-left: 95px;
  }

  .pd-xl-t-95-force {
    padding-top: 95px !important;
  }

  .pd-xl-r-95-force {
    padding-right: 95px !important;
  }

  .pd-xl-b-95-force {
    padding-bottom: 95px !important;
  }

  .pd-xl-l-95-force {
    padding-left: 95px !important;
  }

  .pd-xl-100 {
    padding: 100px;
  }

  .pd-xl-100-force {
    padding: 100px !important;
  }

  .pd-xl-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .pd-xl-y-100-force {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }

  .pd-xl-x-100 {
    padding-left: 100px;
    padding-right: 100px;
  }

  .pd-xl-x-100-force {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }

  .pd-xl-t-100 {
    padding-top: 100px;
  }

  .pd-xl-r-100 {
    padding-right: 100px;
  }

  .pd-xl-b-100 {
    padding-bottom: 100px;
  }

  .pd-xl-l-100 {
    padding-left: 100px;
  }

  .pd-xl-t-100-force {
    padding-top: 100px !important;
  }

  .pd-xl-r-100-force {
    padding-right: 100px !important;
  }

  .pd-xl-b-100-force {
    padding-bottom: 100px !important;
  }

  .pd-xl-l-100-force {
    padding-left: 100px !important;
  }

  .pd-xl-110 {
    padding: 110px;
  }

  .pd-xl-110-force {
    padding: 110px !important;
  }

  .pd-xl-y-110 {
    padding-top: 110px;
    padding-bottom: 110px;
  }

  .pd-xl-y-110-force {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }

  .pd-xl-x-110 {
    padding-left: 110px;
    padding-right: 110px;
  }

  .pd-xl-x-110-force {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }

  .pd-xl-t-110 {
    padding-top: 110px;
  }

  .pd-xl-r-110 {
    padding-right: 110px;
  }

  .pd-xl-b-110 {
    padding-bottom: 110px;
  }

  .pd-xl-l-110 {
    padding-left: 110px;
  }

  .pd-xl-t-110-force {
    padding-top: 110px !important;
  }

  .pd-xl-r-110-force {
    padding-right: 110px !important;
  }

  .pd-xl-b-110-force {
    padding-bottom: 110px !important;
  }

  .pd-xl-l-110-force {
    padding-left: 110px !important;
  }

  .pd-xl-120 {
    padding: 120px;
  }

  .pd-xl-120-force {
    padding: 120px !important;
  }

  .pd-xl-y-120 {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .pd-xl-y-120-force {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }

  .pd-xl-x-120 {
    padding-left: 120px;
    padding-right: 120px;
  }

  .pd-xl-x-120-force {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }

  .pd-xl-t-120 {
    padding-top: 120px;
  }

  .pd-xl-r-120 {
    padding-right: 120px;
  }

  .pd-xl-b-120 {
    padding-bottom: 120px;
  }

  .pd-xl-l-120 {
    padding-left: 120px;
  }

  .pd-xl-t-120-force {
    padding-top: 120px !important;
  }

  .pd-xl-r-120-force {
    padding-right: 120px !important;
  }

  .pd-xl-b-120-force {
    padding-bottom: 120px !important;
  }

  .pd-xl-l-120-force {
    padding-left: 120px !important;
  }

  .pd-xl-130 {
    padding: 130px;
  }

  .pd-xl-130-force {
    padding: 130px !important;
  }

  .pd-xl-y-130 {
    padding-top: 130px;
    padding-bottom: 130px;
  }

  .pd-xl-y-130-force {
    padding-top: 130px !important;
    padding-bottom: 130px !important;
  }

  .pd-xl-x-130 {
    padding-left: 130px;
    padding-right: 130px;
  }

  .pd-xl-x-130-force {
    padding-left: 130px !important;
    padding-right: 130px !important;
  }

  .pd-xl-t-130 {
    padding-top: 130px;
  }

  .pd-xl-r-130 {
    padding-right: 130px;
  }

  .pd-xl-b-130 {
    padding-bottom: 130px;
  }

  .pd-xl-l-130 {
    padding-left: 130px;
  }

  .pd-xl-t-130-force {
    padding-top: 130px !important;
  }

  .pd-xl-r-130-force {
    padding-right: 130px !important;
  }

  .pd-xl-b-130-force {
    padding-bottom: 130px !important;
  }

  .pd-xl-l-130-force {
    padding-left: 130px !important;
  }

  .pd-xl-140 {
    padding: 140px;
  }

  .pd-xl-140-force {
    padding: 140px !important;
  }

  .pd-xl-y-140 {
    padding-top: 140px;
    padding-bottom: 140px;
  }

  .pd-xl-y-140-force {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }

  .pd-xl-x-140 {
    padding-left: 140px;
    padding-right: 140px;
  }

  .pd-xl-x-140-force {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }

  .pd-xl-t-140 {
    padding-top: 140px;
  }

  .pd-xl-r-140 {
    padding-right: 140px;
  }

  .pd-xl-b-140 {
    padding-bottom: 140px;
  }

  .pd-xl-l-140 {
    padding-left: 140px;
  }

  .pd-xl-t-140-force {
    padding-top: 140px !important;
  }

  .pd-xl-r-140-force {
    padding-right: 140px !important;
  }

  .pd-xl-b-140-force {
    padding-bottom: 140px !important;
  }

  .pd-xl-l-140-force {
    padding-left: 140px !important;
  }

  .pd-xl-150 {
    padding: 150px;
  }

  .pd-xl-150-force {
    padding: 150px !important;
  }

  .pd-xl-y-150 {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .pd-xl-y-150-force {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }

  .pd-xl-x-150 {
    padding-left: 150px;
    padding-right: 150px;
  }

  .pd-xl-x-150-force {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }

  .pd-xl-t-150 {
    padding-top: 150px;
  }

  .pd-xl-r-150 {
    padding-right: 150px;
  }

  .pd-xl-b-150 {
    padding-bottom: 150px;
  }

  .pd-xl-l-150 {
    padding-left: 150px;
  }

  .pd-xl-t-150-force {
    padding-top: 150px !important;
  }

  .pd-xl-r-150-force {
    padding-right: 150px !important;
  }

  .pd-xl-b-150-force {
    padding-bottom: 150px !important;
  }

  .pd-xl-l-150-force {
    padding-left: 150px !important;
  }

  .pd-xl-160 {
    padding: 160px;
  }

  .pd-xl-160-force {
    padding: 160px !important;
  }

  .pd-xl-y-160 {
    padding-top: 160px;
    padding-bottom: 160px;
  }

  .pd-xl-y-160-force {
    padding-top: 160px !important;
    padding-bottom: 160px !important;
  }

  .pd-xl-x-160 {
    padding-left: 160px;
    padding-right: 160px;
  }

  .pd-xl-x-160-force {
    padding-left: 160px !important;
    padding-right: 160px !important;
  }

  .pd-xl-t-160 {
    padding-top: 160px;
  }

  .pd-xl-r-160 {
    padding-right: 160px;
  }

  .pd-xl-b-160 {
    padding-bottom: 160px;
  }

  .pd-xl-l-160 {
    padding-left: 160px;
  }

  .pd-xl-t-160-force {
    padding-top: 160px !important;
  }

  .pd-xl-r-160-force {
    padding-right: 160px !important;
  }

  .pd-xl-b-160-force {
    padding-bottom: 160px !important;
  }

  .pd-xl-l-160-force {
    padding-left: 160px !important;
  }

  .pd-xl-170 {
    padding: 170px;
  }

  .pd-xl-170-force {
    padding: 170px !important;
  }

  .pd-xl-y-170 {
    padding-top: 170px;
    padding-bottom: 170px;
  }

  .pd-xl-y-170-force {
    padding-top: 170px !important;
    padding-bottom: 170px !important;
  }

  .pd-xl-x-170 {
    padding-left: 170px;
    padding-right: 170px;
  }

  .pd-xl-x-170-force {
    padding-left: 170px !important;
    padding-right: 170px !important;
  }

  .pd-xl-t-170 {
    padding-top: 170px;
  }

  .pd-xl-r-170 {
    padding-right: 170px;
  }

  .pd-xl-b-170 {
    padding-bottom: 170px;
  }

  .pd-xl-l-170 {
    padding-left: 170px;
  }

  .pd-xl-t-170-force {
    padding-top: 170px !important;
  }

  .pd-xl-r-170-force {
    padding-right: 170px !important;
  }

  .pd-xl-b-170-force {
    padding-bottom: 170px !important;
  }

  .pd-xl-l-170-force {
    padding-left: 170px !important;
  }

  .pd-xl-180 {
    padding: 180px;
  }

  .pd-xl-180-force {
    padding: 180px !important;
  }

  .pd-xl-y-180 {
    padding-top: 180px;
    padding-bottom: 180px;
  }

  .pd-xl-y-180-force {
    padding-top: 180px !important;
    padding-bottom: 180px !important;
  }

  .pd-xl-x-180 {
    padding-left: 180px;
    padding-right: 180px;
  }

  .pd-xl-x-180-force {
    padding-left: 180px !important;
    padding-right: 180px !important;
  }

  .pd-xl-t-180 {
    padding-top: 180px;
  }

  .pd-xl-r-180 {
    padding-right: 180px;
  }

  .pd-xl-b-180 {
    padding-bottom: 180px;
  }

  .pd-xl-l-180 {
    padding-left: 180px;
  }

  .pd-xl-t-180-force {
    padding-top: 180px !important;
  }

  .pd-xl-r-180-force {
    padding-right: 180px !important;
  }

  .pd-xl-b-180-force {
    padding-bottom: 180px !important;
  }

  .pd-xl-l-180-force {
    padding-left: 180px !important;
  }

  .pd-xl-190 {
    padding: 190px;
  }

  .pd-xl-190-force {
    padding: 190px !important;
  }

  .pd-xl-y-190 {
    padding-top: 190px;
    padding-bottom: 190px;
  }

  .pd-xl-y-190-force {
    padding-top: 190px !important;
    padding-bottom: 190px !important;
  }

  .pd-xl-x-190 {
    padding-left: 190px;
    padding-right: 190px;
  }

  .pd-xl-x-190-force {
    padding-left: 190px !important;
    padding-right: 190px !important;
  }

  .pd-xl-t-190 {
    padding-top: 190px;
  }

  .pd-xl-r-190 {
    padding-right: 190px;
  }

  .pd-xl-b-190 {
    padding-bottom: 190px;
  }

  .pd-xl-l-190 {
    padding-left: 190px;
  }

  .pd-xl-t-190-force {
    padding-top: 190px !important;
  }

  .pd-xl-r-190-force {
    padding-right: 190px !important;
  }

  .pd-xl-b-190-force {
    padding-bottom: 190px !important;
  }

  .pd-xl-l-190-force {
    padding-left: 190px !important;
  }

  .pd-xl-200 {
    padding: 200px;
  }

  .pd-xl-200-force {
    padding: 200px !important;
  }

  .pd-xl-y-200 {
    padding-top: 200px;
    padding-bottom: 200px;
  }

  .pd-xl-y-200-force {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }

  .pd-xl-x-200 {
    padding-left: 200px;
    padding-right: 200px;
  }

  .pd-xl-x-200-force {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }

  .pd-xl-t-200 {
    padding-top: 200px;
  }

  .pd-xl-r-200 {
    padding-right: 200px;
  }

  .pd-xl-b-200 {
    padding-bottom: 200px;
  }

  .pd-xl-l-200 {
    padding-left: 200px;
  }

  .pd-xl-t-200-force {
    padding-top: 200px !important;
  }

  .pd-xl-r-200-force {
    padding-right: 200px !important;
  }

  .pd-xl-b-200-force {
    padding-bottom: 200px !important;
  }

  .pd-xl-l-200-force {
    padding-left: 200px !important;
  }
}
.pd-12 {
  padding: 12px;
}

.pd-12-force {
  padding: 12px !important;
}

.pd-y-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.pd-y-12-force {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.pd-x-12 {
  padding-left: 12px;
  padding-right: 12px;
}

.pd-x-12-force {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.pd-t-12 {
  padding-top: 12px;
}

.pd-r-12 {
  padding-right: 12px;
}

.pd-b-12 {
  padding-bottom: 12px;
}

.pd-l-12 {
  padding-left: 12px;
}

.pd-t-12-force {
  padding-top: 12px !important;
}

.pd-r-12-force {
  padding-right: 12px !important;
}

.pd-b-12-force {
  padding-bottom: 12px !important;
}

.pd-l-12-force {
  padding-left: 12px !important;
}

.pd-13 {
  padding: 13px;
}

.pd-13-force {
  padding: 13px !important;
}

.pd-y-13 {
  padding-top: 13px;
  padding-bottom: 13px;
}

.pd-y-13-force {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}

.pd-x-13 {
  padding-left: 13px;
  padding-right: 13px;
}

.pd-x-13-force {
  padding-left: 13px !important;
  padding-right: 13px !important;
}

.pd-t-13 {
  padding-top: 13px;
}

.pd-r-13 {
  padding-right: 13px;
}

.pd-b-13 {
  padding-bottom: 13px;
}

.pd-l-13 {
  padding-left: 13px;
}

.pd-t-13-force {
  padding-top: 13px !important;
}

.pd-r-13-force {
  padding-right: 13px !important;
}

.pd-b-13-force {
  padding-bottom: 13px !important;
}

.pd-l-13-force {
  padding-left: 13px !important;
}

/* -------------------------------------- */
/* ############### Margin ############### */
/* -------------------------------------- */
.mg-0 {
  margin: 0px;
}

.mg-0-force {
  margin: 0px !important;
}

.mg-y-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.mg-y-0-force {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.mg-x-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.mg-x-0-force {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.mg-t-0 {
  margin-top: 0px;
}

.mg-r-0 {
  margin-right: 0px;
}

.mg-b-0 {
  margin-bottom: 0px;
}

.mg-l-0 {
  margin-left: 0px;
}

.mg-t-0-force {
  margin-top: 0px !important;
}

.mg-r-0-force {
  margin-right: 0px !important;
}

.mg-b-0-force {
  margin-bottom: 0px !important;
}

.mg-l-0-force {
  margin-left: 0px !important;
}

.mg-1 {
  margin: 1px;
}

.mg-1-force {
  margin: 1px !important;
}

.mg-y-1 {
  margin-top: 1px;
  margin-bottom: 1px;
}

.mg-y-1-force {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.mg-x-1 {
  margin-left: 1px;
  margin-right: 1px;
}

.mg-x-1-force {
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.mg-t-1 {
  margin-top: 1px;
}

.mg-r-1 {
  margin-right: 1px;
}

.mg-b-1 {
  margin-bottom: 1px;
}

.mg-l-1 {
  margin-left: 1px;
}

.mg-t-1-force {
  margin-top: 1px !important;
}

.mg-r-1-force {
  margin-right: 1px !important;
}

.mg-b-1-force {
  margin-bottom: 1px !important;
}

.mg-l-1-force {
  margin-left: 1px !important;
}

.mg-2 {
  margin: 2px;
}

.mg-2-force {
  margin: 2px !important;
}

.mg-y-2 {
  margin-top: 2px;
  margin-bottom: 2px;
}

.mg-y-2-force {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.mg-x-2 {
  margin-left: 2px;
  margin-right: 2px;
}

.mg-x-2-force {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.mg-t-2 {
  margin-top: 2px;
}

.mg-r-2 {
  margin-right: 2px;
}

.mg-b-2 {
  margin-bottom: 2px;
}

.mg-l-2 {
  margin-left: 2px;
}

.mg-t-2-force {
  margin-top: 2px !important;
}

.mg-r-2-force {
  margin-right: 2px !important;
}

.mg-b-2-force {
  margin-bottom: 2px !important;
}

.mg-l-2-force {
  margin-left: 2px !important;
}

.mg-3 {
  margin: 3px;
}

.mg-3-force {
  margin: 3px !important;
}

.mg-y-3 {
  margin-top: 3px;
  margin-bottom: 3px;
}

.mg-y-3-force {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

.mg-x-3 {
  margin-left: 3px;
  margin-right: 3px;
}

.mg-x-3-force {
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.mg-t-3 {
  margin-top: 3px;
}

.mg-r-3 {
  margin-right: 3px;
}

.mg-b-3 {
  margin-bottom: 3px;
}

.mg-l-3 {
  margin-left: 3px;
}

.mg-t-3-force {
  margin-top: 3px !important;
}

.mg-r-3-force {
  margin-right: 3px !important;
}

.mg-b-3-force {
  margin-bottom: 3px !important;
}

.mg-l-3-force {
  margin-left: 3px !important;
}

.mg-4 {
  margin: 4px;
}

.mg-4-force {
  margin: 4px !important;
}

.mg-y-4 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.mg-y-4-force {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.mg-x-4 {
  margin-left: 4px;
  margin-right: 4px;
}

.mg-x-4-force {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.mg-t-4 {
  margin-top: 4px;
}

.mg-r-4 {
  margin-right: 4px;
}

.mg-b-4 {
  margin-bottom: 4px;
}

.mg-l-4 {
  margin-left: 4px;
}

.mg-t-4-force {
  margin-top: 4px !important;
}

.mg-r-4-force {
  margin-right: 4px !important;
}

.mg-b-4-force {
  margin-bottom: 4px !important;
}

.mg-l-4-force {
  margin-left: 4px !important;
}

.mg-5 {
  margin: 5px;
}

.mg-5-force {
  margin: 5px !important;
}

.mg-y-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.mg-y-5-force {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.mg-x-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.mg-x-5-force {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.mg-t-5 {
  margin-top: 5px;
}

.mg-r-5 {
  margin-right: 5px;
}

.mg-b-5 {
  margin-bottom: 5px;
}

.mg-l-5 {
  margin-left: 5px;
}

.mg-t-5-force {
  margin-top: 5px !important;
}

.mg-r-5-force {
  margin-right: 5px !important;
}

.mg-b-5-force {
  margin-bottom: 5px !important;
}

.mg-l-5-force {
  margin-left: 5px !important;
}

.mg-6 {
  margin: 6px;
}

.mg-6-force {
  margin: 6px !important;
}

.mg-y-6 {
  margin-top: 6px;
  margin-bottom: 6px;
}

.mg-y-6-force {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.mg-x-6 {
  margin-left: 6px;
  margin-right: 6px;
}

.mg-x-6-force {
  margin-left: 6px !important;
  margin-right: 6px !important;
}

.mg-t-6 {
  margin-top: 6px;
}

.mg-r-6 {
  margin-right: 6px;
}

.mg-b-6 {
  margin-bottom: 6px;
}

.mg-l-6 {
  margin-left: 6px;
}

.mg-t-6-force {
  margin-top: 6px !important;
}

.mg-r-6-force {
  margin-right: 6px !important;
}

.mg-b-6-force {
  margin-bottom: 6px !important;
}

.mg-l-6-force {
  margin-left: 6px !important;
}

.mg-7 {
  margin: 7px;
}

.mg-7-force {
  margin: 7px !important;
}

.mg-y-7 {
  margin-top: 7px;
  margin-bottom: 7px;
}

.mg-y-7-force {
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}

.mg-x-7 {
  margin-left: 7px;
  margin-right: 7px;
}

.mg-x-7-force {
  margin-left: 7px !important;
  margin-right: 7px !important;
}

.mg-t-7 {
  margin-top: 7px;
}

.mg-r-7 {
  margin-right: 7px;
}

.mg-b-7 {
  margin-bottom: 7px;
}

.mg-l-7 {
  margin-left: 7px;
}

.mg-t-7-force {
  margin-top: 7px !important;
}

.mg-r-7-force {
  margin-right: 7px !important;
}

.mg-b-7-force {
  margin-bottom: 7px !important;
}

.mg-l-7-force {
  margin-left: 7px !important;
}

.mg-8 {
  margin: 8px;
}

.mg-8-force {
  margin: 8px !important;
}

.mg-y-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.mg-y-8-force {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.mg-x-8 {
  margin-left: 8px;
  margin-right: 8px;
}

.mg-x-8-force {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.mg-t-8 {
  margin-top: 8px;
}

.mg-r-8 {
  margin-right: 8px;
}

.mg-b-8 {
  margin-bottom: 8px;
}

.mg-l-8 {
  margin-left: 8px;
}

.mg-t-8-force {
  margin-top: 8px !important;
}

.mg-r-8-force {
  margin-right: 8px !important;
}

.mg-b-8-force {
  margin-bottom: 8px !important;
}

.mg-l-8-force {
  margin-left: 8px !important;
}

.mg-9 {
  margin: 9px;
}

.mg-9-force {
  margin: 9px !important;
}

.mg-y-9 {
  margin-top: 9px;
  margin-bottom: 9px;
}

.mg-y-9-force {
  margin-top: 9px !important;
  margin-bottom: 9px !important;
}

.mg-x-9 {
  margin-left: 9px;
  margin-right: 9px;
}

.mg-x-9-force {
  margin-left: 9px !important;
  margin-right: 9px !important;
}

.mg-t-9 {
  margin-top: 9px;
}

.mg-r-9 {
  margin-right: 9px;
}

.mg-b-9 {
  margin-bottom: 9px;
}

.mg-l-9 {
  margin-left: 9px;
}

.mg-t-9-force {
  margin-top: 9px !important;
}

.mg-r-9-force {
  margin-right: 9px !important;
}

.mg-b-9-force {
  margin-bottom: 9px !important;
}

.mg-l-9-force {
  margin-left: 9px !important;
}

.mg-10 {
  margin: 10px;
}

.mg-10-force {
  margin: 10px !important;
}

.mg-y-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mg-y-10-force {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.mg-x-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mg-x-10-force {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.mg-t-10 {
  margin-top: 10px;
}

.mg-r-10 {
  margin-right: 10px;
}

.mg-b-10 {
  margin-bottom: 10px;
}

.mg-l-10 {
  margin-left: 10px;
}

.mg-t-10-force {
  margin-top: 10px !important;
}

.mg-r-10-force {
  margin-right: 10px !important;
}

.mg-b-10-force {
  margin-bottom: 10px !important;
}

.mg-l-10-force {
  margin-left: 10px !important;
}

.mg-15 {
  margin: 15px;
}

.mg-15-force {
  margin: 15px !important;
}

.mg-y-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mg-y-15-force {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.mg-x-15 {
  margin-left: 15px;
  margin-right: 15px;
}

.mg-x-15-force {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.mg-t-15 {
  margin-top: 15px;
}

.mg-r-15 {
  margin-right: 15px;
}

.mg-b-15 {
  margin-bottom: 15px;
}

.mg-l-15 {
  margin-left: 15px;
}

.mg-t-15-force {
  margin-top: 15px !important;
}

.mg-r-15-force {
  margin-right: 15px !important;
}

.mg-b-15-force {
  margin-bottom: 15px !important;
}

.mg-l-15-force {
  margin-left: 15px !important;
}

.mg-20 {
  margin: 20px;
}

.mg-20-force {
  margin: 20px !important;
}

.mg-y-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mg-y-20-force {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.mg-x-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.mg-x-20-force {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.mg-t-20 {
  margin-top: 20px;
}

.mg-r-20 {
  margin-right: 20px;
}

.mg-b-20 {
  margin-bottom: 20px;
}

.mg-l-20 {
  margin-left: 20px;
}

.mg-t-20-force {
  margin-top: 20px !important;
}

.mg-r-20-force {
  margin-right: 20px !important;
}

.mg-b-20-force {
  margin-bottom: 20px !important;
}

.mg-l-20-force {
  margin-left: 20px !important;
}

.mg-25 {
  margin: 25px;
}

.mg-25-force {
  margin: 25px !important;
}

.mg-y-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.mg-y-25-force {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.mg-x-25 {
  margin-left: 25px;
  margin-right: 25px;
}

.mg-x-25-force {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

.mg-t-25 {
  margin-top: 25px;
}

.mg-r-25 {
  margin-right: 25px;
}

.mg-b-25 {
  margin-bottom: 25px;
}

.mg-l-25 {
  margin-left: 25px;
}

.mg-t-25-force {
  margin-top: 25px !important;
}

.mg-r-25-force {
  margin-right: 25px !important;
}

.mg-b-25-force {
  margin-bottom: 25px !important;
}

.mg-l-25-force {
  margin-left: 25px !important;
}

.mg-30 {
  margin: 30px;
}

.mg-30-force {
  margin: 30px !important;
}

.mg-y-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mg-y-30-force {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.mg-x-30 {
  margin-left: 30px;
  margin-right: 30px;
}

.mg-x-30-force {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.mg-t-30 {
  margin-top: 30px;
}

.mg-r-30 {
  margin-right: 30px;
}

.mg-b-30 {
  margin-bottom: 30px;
}

.mg-l-30 {
  margin-left: 30px;
}

.mg-t-30-force {
  margin-top: 30px !important;
}

.mg-r-30-force {
  margin-right: 30px !important;
}

.mg-b-30-force {
  margin-bottom: 30px !important;
}

.mg-l-30-force {
  margin-left: 30px !important;
}

.mg-35 {
  margin: 35px;
}

.mg-35-force {
  margin: 35px !important;
}

.mg-y-35 {
  margin-top: 35px;
  margin-bottom: 35px;
}

.mg-y-35-force {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.mg-x-35 {
  margin-left: 35px;
  margin-right: 35px;
}

.mg-x-35-force {
  margin-left: 35px !important;
  margin-right: 35px !important;
}

.mg-t-35 {
  margin-top: 35px;
}

.mg-r-35 {
  margin-right: 35px;
}

.mg-b-35 {
  margin-bottom: 35px;
}

.mg-l-35 {
  margin-left: 35px;
}

.mg-t-35-force {
  margin-top: 35px !important;
}

.mg-r-35-force {
  margin-right: 35px !important;
}

.mg-b-35-force {
  margin-bottom: 35px !important;
}

.mg-l-35-force {
  margin-left: 35px !important;
}

.mg-40 {
  margin: 40px;
}

.mg-40-force {
  margin: 40px !important;
}

.mg-y-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.mg-y-40-force {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.mg-x-40 {
  margin-left: 40px;
  margin-right: 40px;
}

.mg-x-40-force {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.mg-t-40 {
  margin-top: 40px;
}

.mg-r-40 {
  margin-right: 40px;
}

.mg-b-40 {
  margin-bottom: 40px;
}

.mg-l-40 {
  margin-left: 40px;
}

.mg-t-40-force {
  margin-top: 40px !important;
}

.mg-r-40-force {
  margin-right: 40px !important;
}

.mg-b-40-force {
  margin-bottom: 40px !important;
}

.mg-l-40-force {
  margin-left: 40px !important;
}

.mg-45 {
  margin: 45px;
}

.mg-45-force {
  margin: 45px !important;
}

.mg-y-45 {
  margin-top: 45px;
  margin-bottom: 45px;
}

.mg-y-45-force {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

.mg-x-45 {
  margin-left: 45px;
  margin-right: 45px;
}

.mg-x-45-force {
  margin-left: 45px !important;
  margin-right: 45px !important;
}

.mg-t-45 {
  margin-top: 45px;
}

.mg-r-45 {
  margin-right: 45px;
}

.mg-b-45 {
  margin-bottom: 45px;
}

.mg-l-45 {
  margin-left: 45px;
}

.mg-t-45-force {
  margin-top: 45px !important;
}

.mg-r-45-force {
  margin-right: 45px !important;
}

.mg-b-45-force {
  margin-bottom: 45px !important;
}

.mg-l-45-force {
  margin-left: 45px !important;
}

.mg-50 {
  margin: 50px;
}

.mg-50-force {
  margin: 50px !important;
}

.mg-y-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.mg-y-50-force {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.mg-x-50 {
  margin-left: 50px;
  margin-right: 50px;
}

.mg-x-50-force {
  margin-left: 50px !important;
  margin-right: 50px !important;
}

.mg-t-50 {
  margin-top: 50px;
}

.mg-r-50 {
  margin-right: 50px;
}

.mg-b-50 {
  margin-bottom: 50px;
}

.mg-l-50 {
  margin-left: 50px;
}

.mg-t-50-force {
  margin-top: 50px !important;
}

.mg-r-50-force {
  margin-right: 50px !important;
}

.mg-b-50-force {
  margin-bottom: 50px !important;
}

.mg-l-50-force {
  margin-left: 50px !important;
}

.mg-55 {
  margin: 55px;
}

.mg-55-force {
  margin: 55px !important;
}

.mg-y-55 {
  margin-top: 55px;
  margin-bottom: 55px;
}

.mg-y-55-force {
  margin-top: 55px !important;
  margin-bottom: 55px !important;
}

.mg-x-55 {
  margin-left: 55px;
  margin-right: 55px;
}

.mg-x-55-force {
  margin-left: 55px !important;
  margin-right: 55px !important;
}

.mg-t-55 {
  margin-top: 55px;
}

.mg-r-55 {
  margin-right: 55px;
}

.mg-b-55 {
  margin-bottom: 55px;
}

.mg-l-55 {
  margin-left: 55px;
}

.mg-t-55-force {
  margin-top: 55px !important;
}

.mg-r-55-force {
  margin-right: 55px !important;
}

.mg-b-55-force {
  margin-bottom: 55px !important;
}

.mg-l-55-force {
  margin-left: 55px !important;
}

.mg-60 {
  margin: 60px;
}

.mg-60-force {
  margin: 60px !important;
}

.mg-y-60 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.mg-y-60-force {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.mg-x-60 {
  margin-left: 60px;
  margin-right: 60px;
}

.mg-x-60-force {
  margin-left: 60px !important;
  margin-right: 60px !important;
}

.mg-t-60 {
  margin-top: 60px;
}

.mg-r-60 {
  margin-right: 60px;
}

.mg-b-60 {
  margin-bottom: 60px;
}

.mg-l-60 {
  margin-left: 60px;
}

.mg-t-60-force {
  margin-top: 60px !important;
}

.mg-r-60-force {
  margin-right: 60px !important;
}

.mg-b-60-force {
  margin-bottom: 60px !important;
}

.mg-l-60-force {
  margin-left: 60px !important;
}

.mg-65 {
  margin: 65px;
}

.mg-65-force {
  margin: 65px !important;
}

.mg-y-65 {
  margin-top: 65px;
  margin-bottom: 65px;
}

.mg-y-65-force {
  margin-top: 65px !important;
  margin-bottom: 65px !important;
}

.mg-x-65 {
  margin-left: 65px;
  margin-right: 65px;
}

.mg-x-65-force {
  margin-left: 65px !important;
  margin-right: 65px !important;
}

.mg-t-65 {
  margin-top: 65px;
}

.mg-r-65 {
  margin-right: 65px;
}

.mg-b-65 {
  margin-bottom: 65px;
}

.mg-l-65 {
  margin-left: 65px;
}

.mg-t-65-force {
  margin-top: 65px !important;
}

.mg-r-65-force {
  margin-right: 65px !important;
}

.mg-b-65-force {
  margin-bottom: 65px !important;
}

.mg-l-65-force {
  margin-left: 65px !important;
}

.mg-70 {
  margin: 70px;
}

.mg-70-force {
  margin: 70px !important;
}

.mg-y-70 {
  margin-top: 70px;
  margin-bottom: 70px;
}

.mg-y-70-force {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

.mg-x-70 {
  margin-left: 70px;
  margin-right: 70px;
}

.mg-x-70-force {
  margin-left: 70px !important;
  margin-right: 70px !important;
}

.mg-t-70 {
  margin-top: 70px;
}

.mg-r-70 {
  margin-right: 70px;
}

.mg-b-70 {
  margin-bottom: 70px;
}

.mg-l-70 {
  margin-left: 70px;
}

.mg-t-70-force {
  margin-top: 70px !important;
}

.mg-r-70-force {
  margin-right: 70px !important;
}

.mg-b-70-force {
  margin-bottom: 70px !important;
}

.mg-l-70-force {
  margin-left: 70px !important;
}

.mg-75 {
  margin: 75px;
}

.mg-75-force {
  margin: 75px !important;
}

.mg-y-75 {
  margin-top: 75px;
  margin-bottom: 75px;
}

.mg-y-75-force {
  margin-top: 75px !important;
  margin-bottom: 75px !important;
}

.mg-x-75 {
  margin-left: 75px;
  margin-right: 75px;
}

.mg-x-75-force {
  margin-left: 75px !important;
  margin-right: 75px !important;
}

.mg-t-75 {
  margin-top: 75px;
}

.mg-r-75 {
  margin-right: 75px;
}

.mg-b-75 {
  margin-bottom: 75px;
}

.mg-l-75 {
  margin-left: 75px;
}

.mg-t-75-force {
  margin-top: 75px !important;
}

.mg-r-75-force {
  margin-right: 75px !important;
}

.mg-b-75-force {
  margin-bottom: 75px !important;
}

.mg-l-75-force {
  margin-left: 75px !important;
}

.mg-80 {
  margin: 80px;
}

.mg-80-force {
  margin: 80px !important;
}

.mg-y-80 {
  margin-top: 80px;
  margin-bottom: 80px;
}

.mg-y-80-force {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.mg-x-80 {
  margin-left: 80px;
  margin-right: 80px;
}

.mg-x-80-force {
  margin-left: 80px !important;
  margin-right: 80px !important;
}

.mg-t-80 {
  margin-top: 80px;
}

.mg-r-80 {
  margin-right: 80px;
}

.mg-b-80 {
  margin-bottom: 80px;
}

.mg-l-80 {
  margin-left: 80px;
}

.mg-t-80-force {
  margin-top: 80px !important;
}

.mg-r-80-force {
  margin-right: 80px !important;
}

.mg-b-80-force {
  margin-bottom: 80px !important;
}

.mg-l-80-force {
  margin-left: 80px !important;
}

.mg-85 {
  margin: 85px;
}

.mg-85-force {
  margin: 85px !important;
}

.mg-y-85 {
  margin-top: 85px;
  margin-bottom: 85px;
}

.mg-y-85-force {
  margin-top: 85px !important;
  margin-bottom: 85px !important;
}

.mg-x-85 {
  margin-left: 85px;
  margin-right: 85px;
}

.mg-x-85-force {
  margin-left: 85px !important;
  margin-right: 85px !important;
}

.mg-t-85 {
  margin-top: 85px;
}

.mg-r-85 {
  margin-right: 85px;
}

.mg-b-85 {
  margin-bottom: 85px;
}

.mg-l-85 {
  margin-left: 85px;
}

.mg-t-85-force {
  margin-top: 85px !important;
}

.mg-r-85-force {
  margin-right: 85px !important;
}

.mg-b-85-force {
  margin-bottom: 85px !important;
}

.mg-l-85-force {
  margin-left: 85px !important;
}

.mg-90 {
  margin: 90px;
}

.mg-90-force {
  margin: 90px !important;
}

.mg-y-90 {
  margin-top: 90px;
  margin-bottom: 90px;
}

.mg-y-90-force {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}

.mg-x-90 {
  margin-left: 90px;
  margin-right: 90px;
}

.mg-x-90-force {
  margin-left: 90px !important;
  margin-right: 90px !important;
}

.mg-t-90 {
  margin-top: 90px;
}

.mg-r-90 {
  margin-right: 90px;
}

.mg-b-90 {
  margin-bottom: 90px;
}

.mg-l-90 {
  margin-left: 90px;
}

.mg-t-90-force {
  margin-top: 90px !important;
}

.mg-r-90-force {
  margin-right: 90px !important;
}

.mg-b-90-force {
  margin-bottom: 90px !important;
}

.mg-l-90-force {
  margin-left: 90px !important;
}

.mg-95 {
  margin: 95px;
}

.mg-95-force {
  margin: 95px !important;
}

.mg-y-95 {
  margin-top: 95px;
  margin-bottom: 95px;
}

.mg-y-95-force {
  margin-top: 95px !important;
  margin-bottom: 95px !important;
}

.mg-x-95 {
  margin-left: 95px;
  margin-right: 95px;
}

.mg-x-95-force {
  margin-left: 95px !important;
  margin-right: 95px !important;
}

.mg-t-95 {
  margin-top: 95px;
}

.mg-r-95 {
  margin-right: 95px;
}

.mg-b-95 {
  margin-bottom: 95px;
}

.mg-l-95 {
  margin-left: 95px;
}

.mg-t-95-force {
  margin-top: 95px !important;
}

.mg-r-95-force {
  margin-right: 95px !important;
}

.mg-b-95-force {
  margin-bottom: 95px !important;
}

.mg-l-95-force {
  margin-left: 95px !important;
}

.mg-100 {
  margin: 100px;
}

.mg-100-force {
  margin: 100px !important;
}

.mg-y-100 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.mg-y-100-force {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.mg-x-100 {
  margin-left: 100px;
  margin-right: 100px;
}

.mg-x-100-force {
  margin-left: 100px !important;
  margin-right: 100px !important;
}

.mg-t-100 {
  margin-top: 100px;
}

.mg-r-100 {
  margin-right: 100px;
}

.mg-b-100 {
  margin-bottom: 100px;
}

.mg-l-100 {
  margin-left: 100px;
}

.mg-t-100-force {
  margin-top: 100px !important;
}

.mg-r-100-force {
  margin-right: 100px !important;
}

.mg-b-100-force {
  margin-bottom: 100px !important;
}

.mg-l-100-force {
  margin-left: 100px !important;
}

.mg-105 {
  margin: 105px;
}

.mg-105-force {
  margin: 105px !important;
}

.mg-y-105 {
  margin-top: 105px;
  margin-bottom: 105px;
}

.mg-y-105-force {
  margin-top: 105px !important;
  margin-bottom: 105px !important;
}

.mg-x-105 {
  margin-left: 105px;
  margin-right: 105px;
}

.mg-x-105-force {
  margin-left: 105px !important;
  margin-right: 105px !important;
}

.mg-t-105 {
  margin-top: 105px;
}

.mg-r-105 {
  margin-right: 105px;
}

.mg-b-105 {
  margin-bottom: 105px;
}

.mg-l-105 {
  margin-left: 105px;
}

.mg-t-105-force {
  margin-top: 105px !important;
}

.mg-r-105-force {
  margin-right: 105px !important;
}

.mg-b-105-force {
  margin-bottom: 105px !important;
}

.mg-l-105-force {
  margin-left: 105px !important;
}

.mg-110 {
  margin: 110px;
}

.mg-110-force {
  margin: 110px !important;
}

.mg-y-110 {
  margin-top: 110px;
  margin-bottom: 110px;
}

.mg-y-110-force {
  margin-top: 110px !important;
  margin-bottom: 110px !important;
}

.mg-x-110 {
  margin-left: 110px;
  margin-right: 110px;
}

.mg-x-110-force {
  margin-left: 110px !important;
  margin-right: 110px !important;
}

.mg-t-110 {
  margin-top: 110px;
}

.mg-r-110 {
  margin-right: 110px;
}

.mg-b-110 {
  margin-bottom: 110px;
}

.mg-l-110 {
  margin-left: 110px;
}

.mg-t-110-force {
  margin-top: 110px !important;
}

.mg-r-110-force {
  margin-right: 110px !important;
}

.mg-b-110-force {
  margin-bottom: 110px !important;
}

.mg-l-110-force {
  margin-left: 110px !important;
}

.mg-115 {
  margin: 115px;
}

.mg-115-force {
  margin: 115px !important;
}

.mg-y-115 {
  margin-top: 115px;
  margin-bottom: 115px;
}

.mg-y-115-force {
  margin-top: 115px !important;
  margin-bottom: 115px !important;
}

.mg-x-115 {
  margin-left: 115px;
  margin-right: 115px;
}

.mg-x-115-force {
  margin-left: 115px !important;
  margin-right: 115px !important;
}

.mg-t-115 {
  margin-top: 115px;
}

.mg-r-115 {
  margin-right: 115px;
}

.mg-b-115 {
  margin-bottom: 115px;
}

.mg-l-115 {
  margin-left: 115px;
}

.mg-t-115-force {
  margin-top: 115px !important;
}

.mg-r-115-force {
  margin-right: 115px !important;
}

.mg-b-115-force {
  margin-bottom: 115px !important;
}

.mg-l-115-force {
  margin-left: 115px !important;
}

.mg-120 {
  margin: 120px;
}

.mg-120-force {
  margin: 120px !important;
}

.mg-y-120 {
  margin-top: 120px;
  margin-bottom: 120px;
}

.mg-y-120-force {
  margin-top: 120px !important;
  margin-bottom: 120px !important;
}

.mg-x-120 {
  margin-left: 120px;
  margin-right: 120px;
}

.mg-x-120-force {
  margin-left: 120px !important;
  margin-right: 120px !important;
}

.mg-t-120 {
  margin-top: 120px;
}

.mg-r-120 {
  margin-right: 120px;
}

.mg-b-120 {
  margin-bottom: 120px;
}

.mg-l-120 {
  margin-left: 120px;
}

.mg-t-120-force {
  margin-top: 120px !important;
}

.mg-r-120-force {
  margin-right: 120px !important;
}

.mg-b-120-force {
  margin-bottom: 120px !important;
}

.mg-l-120-force {
  margin-left: 120px !important;
}

.mg-t-auto {
  margin-top: auto;
}

.mg-r-auto {
  margin-right: auto;
}

.mg-b-auto {
  margin-bottom: auto;
}

.mg-l-auto {
  margin-left: auto;
}

.mg-x-auto {
  margin: auto;
}

.mg-l--1 {
  margin-left: -1px;
}

.mg-r--1 {
  margin-right: -1px;
}

.mg-t--1 {
  margin-top: -1px;
}

.mg-l--5 {
  margin-left: -5px;
}

.mg-r--5 {
  margin-right: -5px;
}

@media (min-width: 480px) {
  .mg-xs-0 {
    margin: 0px;
  }

  .mg-xs-0-force {
    margin: 0px !important;
  }

  .mg-xs-y-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .mg-xs-y-0-force {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .mg-xs-x-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .mg-xs-x-0-force {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .mg-xs-t-0 {
    margin-top: 0px;
  }

  .mg-xs-r-0 {
    margin-right: 0px;
  }

  .mg-xs-b-0 {
    margin-bottom: 0px;
  }

  .mg-xs-l-0 {
    margin-left: 0px;
  }

  .mg-xs-t-0-force {
    margin-top: 0px !important;
  }

  .mg-xs-r-0-force {
    margin-right: 0px !important;
  }

  .mg-xs-b-0-force {
    margin-bottom: 0px !important;
  }

  .mg-xs-l-0-force {
    margin-left: 0px !important;
  }

  .mg-xs-1 {
    margin: 1px;
  }

  .mg-xs-1-force {
    margin: 1px !important;
  }

  .mg-xs-y-1 {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .mg-xs-y-1-force {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .mg-xs-x-1 {
    margin-left: 1px;
    margin-right: 1px;
  }

  .mg-xs-x-1-force {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .mg-xs-t-1 {
    margin-top: 1px;
  }

  .mg-xs-r-1 {
    margin-right: 1px;
  }

  .mg-xs-b-1 {
    margin-bottom: 1px;
  }

  .mg-xs-l-1 {
    margin-left: 1px;
  }

  .mg-xs-t-1-force {
    margin-top: 1px !important;
  }

  .mg-xs-r-1-force {
    margin-right: 1px !important;
  }

  .mg-xs-b-1-force {
    margin-bottom: 1px !important;
  }

  .mg-xs-l-1-force {
    margin-left: 1px !important;
  }

  .mg-xs-2 {
    margin: 2px;
  }

  .mg-xs-2-force {
    margin: 2px !important;
  }

  .mg-xs-y-2 {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .mg-xs-y-2-force {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .mg-xs-x-2 {
    margin-left: 2px;
    margin-right: 2px;
  }

  .mg-xs-x-2-force {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .mg-xs-t-2 {
    margin-top: 2px;
  }

  .mg-xs-r-2 {
    margin-right: 2px;
  }

  .mg-xs-b-2 {
    margin-bottom: 2px;
  }

  .mg-xs-l-2 {
    margin-left: 2px;
  }

  .mg-xs-t-2-force {
    margin-top: 2px !important;
  }

  .mg-xs-r-2-force {
    margin-right: 2px !important;
  }

  .mg-xs-b-2-force {
    margin-bottom: 2px !important;
  }

  .mg-xs-l-2-force {
    margin-left: 2px !important;
  }

  .mg-xs-3 {
    margin: 3px;
  }

  .mg-xs-3-force {
    margin: 3px !important;
  }

  .mg-xs-y-3 {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .mg-xs-y-3-force {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .mg-xs-x-3 {
    margin-left: 3px;
    margin-right: 3px;
  }

  .mg-xs-x-3-force {
    margin-left: 3px !important;
    margin-right: 3px !important;
  }

  .mg-xs-t-3 {
    margin-top: 3px;
  }

  .mg-xs-r-3 {
    margin-right: 3px;
  }

  .mg-xs-b-3 {
    margin-bottom: 3px;
  }

  .mg-xs-l-3 {
    margin-left: 3px;
  }

  .mg-xs-t-3-force {
    margin-top: 3px !important;
  }

  .mg-xs-r-3-force {
    margin-right: 3px !important;
  }

  .mg-xs-b-3-force {
    margin-bottom: 3px !important;
  }

  .mg-xs-l-3-force {
    margin-left: 3px !important;
  }

  .mg-xs-4 {
    margin: 4px;
  }

  .mg-xs-4-force {
    margin: 4px !important;
  }

  .mg-xs-y-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .mg-xs-y-4-force {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .mg-xs-x-4 {
    margin-left: 4px;
    margin-right: 4px;
  }

  .mg-xs-x-4-force {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }

  .mg-xs-t-4 {
    margin-top: 4px;
  }

  .mg-xs-r-4 {
    margin-right: 4px;
  }

  .mg-xs-b-4 {
    margin-bottom: 4px;
  }

  .mg-xs-l-4 {
    margin-left: 4px;
  }

  .mg-xs-t-4-force {
    margin-top: 4px !important;
  }

  .mg-xs-r-4-force {
    margin-right: 4px !important;
  }

  .mg-xs-b-4-force {
    margin-bottom: 4px !important;
  }

  .mg-xs-l-4-force {
    margin-left: 4px !important;
  }

  .mg-xs-5 {
    margin: 5px;
  }

  .mg-xs-5-force {
    margin: 5px !important;
  }

  .mg-xs-y-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .mg-xs-y-5-force {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .mg-xs-x-5 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mg-xs-x-5-force {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .mg-xs-t-5 {
    margin-top: 5px;
  }

  .mg-xs-r-5 {
    margin-right: 5px;
  }

  .mg-xs-b-5 {
    margin-bottom: 5px;
  }

  .mg-xs-l-5 {
    margin-left: 5px;
  }

  .mg-xs-t-5-force {
    margin-top: 5px !important;
  }

  .mg-xs-r-5-force {
    margin-right: 5px !important;
  }

  .mg-xs-b-5-force {
    margin-bottom: 5px !important;
  }

  .mg-xs-l-5-force {
    margin-left: 5px !important;
  }

  .mg-xs-6 {
    margin: 6px;
  }

  .mg-xs-6-force {
    margin: 6px !important;
  }

  .mg-xs-y-6 {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .mg-xs-y-6-force {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }

  .mg-xs-x-6 {
    margin-left: 6px;
    margin-right: 6px;
  }

  .mg-xs-x-6-force {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }

  .mg-xs-t-6 {
    margin-top: 6px;
  }

  .mg-xs-r-6 {
    margin-right: 6px;
  }

  .mg-xs-b-6 {
    margin-bottom: 6px;
  }

  .mg-xs-l-6 {
    margin-left: 6px;
  }

  .mg-xs-t-6-force {
    margin-top: 6px !important;
  }

  .mg-xs-r-6-force {
    margin-right: 6px !important;
  }

  .mg-xs-b-6-force {
    margin-bottom: 6px !important;
  }

  .mg-xs-l-6-force {
    margin-left: 6px !important;
  }

  .mg-xs-7 {
    margin: 7px;
  }

  .mg-xs-7-force {
    margin: 7px !important;
  }

  .mg-xs-y-7 {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .mg-xs-y-7-force {
    margin-top: 7px !important;
    margin-bottom: 7px !important;
  }

  .mg-xs-x-7 {
    margin-left: 7px;
    margin-right: 7px;
  }

  .mg-xs-x-7-force {
    margin-left: 7px !important;
    margin-right: 7px !important;
  }

  .mg-xs-t-7 {
    margin-top: 7px;
  }

  .mg-xs-r-7 {
    margin-right: 7px;
  }

  .mg-xs-b-7 {
    margin-bottom: 7px;
  }

  .mg-xs-l-7 {
    margin-left: 7px;
  }

  .mg-xs-t-7-force {
    margin-top: 7px !important;
  }

  .mg-xs-r-7-force {
    margin-right: 7px !important;
  }

  .mg-xs-b-7-force {
    margin-bottom: 7px !important;
  }

  .mg-xs-l-7-force {
    margin-left: 7px !important;
  }

  .mg-xs-8 {
    margin: 8px;
  }

  .mg-xs-8-force {
    margin: 8px !important;
  }

  .mg-xs-y-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .mg-xs-y-8-force {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .mg-xs-x-8 {
    margin-left: 8px;
    margin-right: 8px;
  }

  .mg-xs-x-8-force {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .mg-xs-t-8 {
    margin-top: 8px;
  }

  .mg-xs-r-8 {
    margin-right: 8px;
  }

  .mg-xs-b-8 {
    margin-bottom: 8px;
  }

  .mg-xs-l-8 {
    margin-left: 8px;
  }

  .mg-xs-t-8-force {
    margin-top: 8px !important;
  }

  .mg-xs-r-8-force {
    margin-right: 8px !important;
  }

  .mg-xs-b-8-force {
    margin-bottom: 8px !important;
  }

  .mg-xs-l-8-force {
    margin-left: 8px !important;
  }

  .mg-xs-9 {
    margin: 9px;
  }

  .mg-xs-9-force {
    margin: 9px !important;
  }

  .mg-xs-y-9 {
    margin-top: 9px;
    margin-bottom: 9px;
  }

  .mg-xs-y-9-force {
    margin-top: 9px !important;
    margin-bottom: 9px !important;
  }

  .mg-xs-x-9 {
    margin-left: 9px;
    margin-right: 9px;
  }

  .mg-xs-x-9-force {
    margin-left: 9px !important;
    margin-right: 9px !important;
  }

  .mg-xs-t-9 {
    margin-top: 9px;
  }

  .mg-xs-r-9 {
    margin-right: 9px;
  }

  .mg-xs-b-9 {
    margin-bottom: 9px;
  }

  .mg-xs-l-9 {
    margin-left: 9px;
  }

  .mg-xs-t-9-force {
    margin-top: 9px !important;
  }

  .mg-xs-r-9-force {
    margin-right: 9px !important;
  }

  .mg-xs-b-9-force {
    margin-bottom: 9px !important;
  }

  .mg-xs-l-9-force {
    margin-left: 9px !important;
  }

  .mg-xs-10 {
    margin: 10px;
  }

  .mg-xs-10-force {
    margin: 10px !important;
  }

  .mg-xs-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mg-xs-y-10-force {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .mg-xs-x-10 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mg-xs-x-10-force {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .mg-xs-t-10 {
    margin-top: 10px;
  }

  .mg-xs-r-10 {
    margin-right: 10px;
  }

  .mg-xs-b-10 {
    margin-bottom: 10px;
  }

  .mg-xs-l-10 {
    margin-left: 10px;
  }

  .mg-xs-t-10-force {
    margin-top: 10px !important;
  }

  .mg-xs-r-10-force {
    margin-right: 10px !important;
  }

  .mg-xs-b-10-force {
    margin-bottom: 10px !important;
  }

  .mg-xs-l-10-force {
    margin-left: 10px !important;
  }

  .mg-xs-15 {
    margin: 15px;
  }

  .mg-xs-15-force {
    margin: 15px !important;
  }

  .mg-xs-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .mg-xs-y-15-force {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .mg-xs-x-15 {
    margin-left: 15px;
    margin-right: 15px;
  }

  .mg-xs-x-15-force {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .mg-xs-t-15 {
    margin-top: 15px;
  }

  .mg-xs-r-15 {
    margin-right: 15px;
  }

  .mg-xs-b-15 {
    margin-bottom: 15px;
  }

  .mg-xs-l-15 {
    margin-left: 15px;
  }

  .mg-xs-t-15-force {
    margin-top: 15px !important;
  }

  .mg-xs-r-15-force {
    margin-right: 15px !important;
  }

  .mg-xs-b-15-force {
    margin-bottom: 15px !important;
  }

  .mg-xs-l-15-force {
    margin-left: 15px !important;
  }

  .mg-xs-20 {
    margin: 20px;
  }

  .mg-xs-20-force {
    margin: 20px !important;
  }

  .mg-xs-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mg-xs-y-20-force {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .mg-xs-x-20 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mg-xs-x-20-force {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .mg-xs-t-20 {
    margin-top: 20px;
  }

  .mg-xs-r-20 {
    margin-right: 20px;
  }

  .mg-xs-b-20 {
    margin-bottom: 20px;
  }

  .mg-xs-l-20 {
    margin-left: 20px;
  }

  .mg-xs-t-20-force {
    margin-top: 20px !important;
  }

  .mg-xs-r-20-force {
    margin-right: 20px !important;
  }

  .mg-xs-b-20-force {
    margin-bottom: 20px !important;
  }

  .mg-xs-l-20-force {
    margin-left: 20px !important;
  }

  .mg-xs-25 {
    margin: 25px;
  }

  .mg-xs-25-force {
    margin: 25px !important;
  }

  .mg-xs-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .mg-xs-y-25-force {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .mg-xs-x-25 {
    margin-left: 25px;
    margin-right: 25px;
  }

  .mg-xs-x-25-force {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }

  .mg-xs-t-25 {
    margin-top: 25px;
  }

  .mg-xs-r-25 {
    margin-right: 25px;
  }

  .mg-xs-b-25 {
    margin-bottom: 25px;
  }

  .mg-xs-l-25 {
    margin-left: 25px;
  }

  .mg-xs-t-25-force {
    margin-top: 25px !important;
  }

  .mg-xs-r-25-force {
    margin-right: 25px !important;
  }

  .mg-xs-b-25-force {
    margin-bottom: 25px !important;
  }

  .mg-xs-l-25-force {
    margin-left: 25px !important;
  }

  .mg-xs-30 {
    margin: 30px;
  }

  .mg-xs-30-force {
    margin: 30px !important;
  }

  .mg-xs-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mg-xs-y-30-force {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .mg-xs-x-30 {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mg-xs-x-30-force {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .mg-xs-t-30 {
    margin-top: 30px;
  }

  .mg-xs-r-30 {
    margin-right: 30px;
  }

  .mg-xs-b-30 {
    margin-bottom: 30px;
  }

  .mg-xs-l-30 {
    margin-left: 30px;
  }

  .mg-xs-t-30-force {
    margin-top: 30px !important;
  }

  .mg-xs-r-30-force {
    margin-right: 30px !important;
  }

  .mg-xs-b-30-force {
    margin-bottom: 30px !important;
  }

  .mg-xs-l-30-force {
    margin-left: 30px !important;
  }

  .mg-xs-35 {
    margin: 35px;
  }

  .mg-xs-35-force {
    margin: 35px !important;
  }

  .mg-xs-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .mg-xs-y-35-force {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }

  .mg-xs-x-35 {
    margin-left: 35px;
    margin-right: 35px;
  }

  .mg-xs-x-35-force {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }

  .mg-xs-t-35 {
    margin-top: 35px;
  }

  .mg-xs-r-35 {
    margin-right: 35px;
  }

  .mg-xs-b-35 {
    margin-bottom: 35px;
  }

  .mg-xs-l-35 {
    margin-left: 35px;
  }

  .mg-xs-t-35-force {
    margin-top: 35px !important;
  }

  .mg-xs-r-35-force {
    margin-right: 35px !important;
  }

  .mg-xs-b-35-force {
    margin-bottom: 35px !important;
  }

  .mg-xs-l-35-force {
    margin-left: 35px !important;
  }

  .mg-xs-40 {
    margin: 40px;
  }

  .mg-xs-40-force {
    margin: 40px !important;
  }

  .mg-xs-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .mg-xs-y-40-force {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .mg-xs-x-40 {
    margin-left: 40px;
    margin-right: 40px;
  }

  .mg-xs-x-40-force {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .mg-xs-t-40 {
    margin-top: 40px;
  }

  .mg-xs-r-40 {
    margin-right: 40px;
  }

  .mg-xs-b-40 {
    margin-bottom: 40px;
  }

  .mg-xs-l-40 {
    margin-left: 40px;
  }

  .mg-xs-t-40-force {
    margin-top: 40px !important;
  }

  .mg-xs-r-40-force {
    margin-right: 40px !important;
  }

  .mg-xs-b-40-force {
    margin-bottom: 40px !important;
  }

  .mg-xs-l-40-force {
    margin-left: 40px !important;
  }

  .mg-xs-45 {
    margin: 45px;
  }

  .mg-xs-45-force {
    margin: 45px !important;
  }

  .mg-xs-y-45 {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .mg-xs-y-45-force {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }

  .mg-xs-x-45 {
    margin-left: 45px;
    margin-right: 45px;
  }

  .mg-xs-x-45-force {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }

  .mg-xs-t-45 {
    margin-top: 45px;
  }

  .mg-xs-r-45 {
    margin-right: 45px;
  }

  .mg-xs-b-45 {
    margin-bottom: 45px;
  }

  .mg-xs-l-45 {
    margin-left: 45px;
  }

  .mg-xs-t-45-force {
    margin-top: 45px !important;
  }

  .mg-xs-r-45-force {
    margin-right: 45px !important;
  }

  .mg-xs-b-45-force {
    margin-bottom: 45px !important;
  }

  .mg-xs-l-45-force {
    margin-left: 45px !important;
  }

  .mg-xs-50 {
    margin: 50px;
  }

  .mg-xs-50-force {
    margin: 50px !important;
  }

  .mg-xs-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .mg-xs-y-50-force {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .mg-xs-x-50 {
    margin-left: 50px;
    margin-right: 50px;
  }

  .mg-xs-x-50-force {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .mg-xs-t-50 {
    margin-top: 50px;
  }

  .mg-xs-r-50 {
    margin-right: 50px;
  }

  .mg-xs-b-50 {
    margin-bottom: 50px;
  }

  .mg-xs-l-50 {
    margin-left: 50px;
  }

  .mg-xs-t-50-force {
    margin-top: 50px !important;
  }

  .mg-xs-r-50-force {
    margin-right: 50px !important;
  }

  .mg-xs-b-50-force {
    margin-bottom: 50px !important;
  }

  .mg-xs-l-50-force {
    margin-left: 50px !important;
  }

  .mg-xs-55 {
    margin: 55px;
  }

  .mg-xs-55-force {
    margin: 55px !important;
  }

  .mg-xs-y-55 {
    margin-top: 55px;
    margin-bottom: 55px;
  }

  .mg-xs-y-55-force {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }

  .mg-xs-x-55 {
    margin-left: 55px;
    margin-right: 55px;
  }

  .mg-xs-x-55-force {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }

  .mg-xs-t-55 {
    margin-top: 55px;
  }

  .mg-xs-r-55 {
    margin-right: 55px;
  }

  .mg-xs-b-55 {
    margin-bottom: 55px;
  }

  .mg-xs-l-55 {
    margin-left: 55px;
  }

  .mg-xs-t-55-force {
    margin-top: 55px !important;
  }

  .mg-xs-r-55-force {
    margin-right: 55px !important;
  }

  .mg-xs-b-55-force {
    margin-bottom: 55px !important;
  }

  .mg-xs-l-55-force {
    margin-left: 55px !important;
  }

  .mg-xs-60 {
    margin: 60px;
  }

  .mg-xs-60-force {
    margin: 60px !important;
  }

  .mg-xs-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .mg-xs-y-60-force {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  .mg-xs-x-60 {
    margin-left: 60px;
    margin-right: 60px;
  }

  .mg-xs-x-60-force {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }

  .mg-xs-t-60 {
    margin-top: 60px;
  }

  .mg-xs-r-60 {
    margin-right: 60px;
  }

  .mg-xs-b-60 {
    margin-bottom: 60px;
  }

  .mg-xs-l-60 {
    margin-left: 60px;
  }

  .mg-xs-t-60-force {
    margin-top: 60px !important;
  }

  .mg-xs-r-60-force {
    margin-right: 60px !important;
  }

  .mg-xs-b-60-force {
    margin-bottom: 60px !important;
  }

  .mg-xs-l-60-force {
    margin-left: 60px !important;
  }

  .mg-xs-65 {
    margin: 65px;
  }

  .mg-xs-65-force {
    margin: 65px !important;
  }

  .mg-xs-y-65 {
    margin-top: 65px;
    margin-bottom: 65px;
  }

  .mg-xs-y-65-force {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }

  .mg-xs-x-65 {
    margin-left: 65px;
    margin-right: 65px;
  }

  .mg-xs-x-65-force {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }

  .mg-xs-t-65 {
    margin-top: 65px;
  }

  .mg-xs-r-65 {
    margin-right: 65px;
  }

  .mg-xs-b-65 {
    margin-bottom: 65px;
  }

  .mg-xs-l-65 {
    margin-left: 65px;
  }

  .mg-xs-t-65-force {
    margin-top: 65px !important;
  }

  .mg-xs-r-65-force {
    margin-right: 65px !important;
  }

  .mg-xs-b-65-force {
    margin-bottom: 65px !important;
  }

  .mg-xs-l-65-force {
    margin-left: 65px !important;
  }

  .mg-xs-70 {
    margin: 70px;
  }

  .mg-xs-70-force {
    margin: 70px !important;
  }

  .mg-xs-y-70 {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .mg-xs-y-70-force {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }

  .mg-xs-x-70 {
    margin-left: 70px;
    margin-right: 70px;
  }

  .mg-xs-x-70-force {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }

  .mg-xs-t-70 {
    margin-top: 70px;
  }

  .mg-xs-r-70 {
    margin-right: 70px;
  }

  .mg-xs-b-70 {
    margin-bottom: 70px;
  }

  .mg-xs-l-70 {
    margin-left: 70px;
  }

  .mg-xs-t-70-force {
    margin-top: 70px !important;
  }

  .mg-xs-r-70-force {
    margin-right: 70px !important;
  }

  .mg-xs-b-70-force {
    margin-bottom: 70px !important;
  }

  .mg-xs-l-70-force {
    margin-left: 70px !important;
  }

  .mg-xs-75 {
    margin: 75px;
  }

  .mg-xs-75-force {
    margin: 75px !important;
  }

  .mg-xs-y-75 {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .mg-xs-y-75-force {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }

  .mg-xs-x-75 {
    margin-left: 75px;
    margin-right: 75px;
  }

  .mg-xs-x-75-force {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }

  .mg-xs-t-75 {
    margin-top: 75px;
  }

  .mg-xs-r-75 {
    margin-right: 75px;
  }

  .mg-xs-b-75 {
    margin-bottom: 75px;
  }

  .mg-xs-l-75 {
    margin-left: 75px;
  }

  .mg-xs-t-75-force {
    margin-top: 75px !important;
  }

  .mg-xs-r-75-force {
    margin-right: 75px !important;
  }

  .mg-xs-b-75-force {
    margin-bottom: 75px !important;
  }

  .mg-xs-l-75-force {
    margin-left: 75px !important;
  }

  .mg-xs-80 {
    margin: 80px;
  }

  .mg-xs-80-force {
    margin: 80px !important;
  }

  .mg-xs-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .mg-xs-y-80-force {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }

  .mg-xs-x-80 {
    margin-left: 80px;
    margin-right: 80px;
  }

  .mg-xs-x-80-force {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }

  .mg-xs-t-80 {
    margin-top: 80px;
  }

  .mg-xs-r-80 {
    margin-right: 80px;
  }

  .mg-xs-b-80 {
    margin-bottom: 80px;
  }

  .mg-xs-l-80 {
    margin-left: 80px;
  }

  .mg-xs-t-80-force {
    margin-top: 80px !important;
  }

  .mg-xs-r-80-force {
    margin-right: 80px !important;
  }

  .mg-xs-b-80-force {
    margin-bottom: 80px !important;
  }

  .mg-xs-l-80-force {
    margin-left: 80px !important;
  }

  .mg-xs-85 {
    margin: 85px;
  }

  .mg-xs-85-force {
    margin: 85px !important;
  }

  .mg-xs-y-85 {
    margin-top: 85px;
    margin-bottom: 85px;
  }

  .mg-xs-y-85-force {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }

  .mg-xs-x-85 {
    margin-left: 85px;
    margin-right: 85px;
  }

  .mg-xs-x-85-force {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }

  .mg-xs-t-85 {
    margin-top: 85px;
  }

  .mg-xs-r-85 {
    margin-right: 85px;
  }

  .mg-xs-b-85 {
    margin-bottom: 85px;
  }

  .mg-xs-l-85 {
    margin-left: 85px;
  }

  .mg-xs-t-85-force {
    margin-top: 85px !important;
  }

  .mg-xs-r-85-force {
    margin-right: 85px !important;
  }

  .mg-xs-b-85-force {
    margin-bottom: 85px !important;
  }

  .mg-xs-l-85-force {
    margin-left: 85px !important;
  }

  .mg-xs-90 {
    margin: 90px;
  }

  .mg-xs-90-force {
    margin: 90px !important;
  }

  .mg-xs-y-90 {
    margin-top: 90px;
    margin-bottom: 90px;
  }

  .mg-xs-y-90-force {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }

  .mg-xs-x-90 {
    margin-left: 90px;
    margin-right: 90px;
  }

  .mg-xs-x-90-force {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }

  .mg-xs-t-90 {
    margin-top: 90px;
  }

  .mg-xs-r-90 {
    margin-right: 90px;
  }

  .mg-xs-b-90 {
    margin-bottom: 90px;
  }

  .mg-xs-l-90 {
    margin-left: 90px;
  }

  .mg-xs-t-90-force {
    margin-top: 90px !important;
  }

  .mg-xs-r-90-force {
    margin-right: 90px !important;
  }

  .mg-xs-b-90-force {
    margin-bottom: 90px !important;
  }

  .mg-xs-l-90-force {
    margin-left: 90px !important;
  }

  .mg-xs-95 {
    margin: 95px;
  }

  .mg-xs-95-force {
    margin: 95px !important;
  }

  .mg-xs-y-95 {
    margin-top: 95px;
    margin-bottom: 95px;
  }

  .mg-xs-y-95-force {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }

  .mg-xs-x-95 {
    margin-left: 95px;
    margin-right: 95px;
  }

  .mg-xs-x-95-force {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }

  .mg-xs-t-95 {
    margin-top: 95px;
  }

  .mg-xs-r-95 {
    margin-right: 95px;
  }

  .mg-xs-b-95 {
    margin-bottom: 95px;
  }

  .mg-xs-l-95 {
    margin-left: 95px;
  }

  .mg-xs-t-95-force {
    margin-top: 95px !important;
  }

  .mg-xs-r-95-force {
    margin-right: 95px !important;
  }

  .mg-xs-b-95-force {
    margin-bottom: 95px !important;
  }

  .mg-xs-l-95-force {
    margin-left: 95px !important;
  }

  .mg-xs-100 {
    margin: 100px;
  }

  .mg-xs-100-force {
    margin: 100px !important;
  }

  .mg-xs-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .mg-xs-y-100-force {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }

  .mg-xs-x-100 {
    margin-left: 100px;
    margin-right: 100px;
  }

  .mg-xs-x-100-force {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }

  .mg-xs-t-100 {
    margin-top: 100px;
  }

  .mg-xs-r-100 {
    margin-right: 100px;
  }

  .mg-xs-b-100 {
    margin-bottom: 100px;
  }

  .mg-xs-l-100 {
    margin-left: 100px;
  }

  .mg-xs-t-100-force {
    margin-top: 100px !important;
  }

  .mg-xs-r-100-force {
    margin-right: 100px !important;
  }

  .mg-xs-b-100-force {
    margin-bottom: 100px !important;
  }

  .mg-xs-l-100-force {
    margin-left: 100px !important;
  }

  .mg-xs-t-auto {
    margin-top: auto;
  }

  .mg-xs-r-auto {
    margin-right: auto;
  }

  .mg-xs-b-auto {
    margin-bottom: auto;
  }

  .mg-xs-l-auto {
    margin-left: auto;
  }

  .mg-xs-auto {
    margin: auto;
  }

  .mg-xs-x-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .mg-xs-l--1 {
    margin-left: -1px;
  }

  .mg-xs-r--1 {
    margin-right: -1px;
  }

  .mg-xs-t--1 {
    margin-top: -1px;
  }
}
@media (min-width: 576px) {
  .mg-sm-0 {
    margin: 0px;
  }

  .mg-sm-0-force {
    margin: 0px !important;
  }

  .mg-sm-y-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .mg-sm-y-0-force {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .mg-sm-x-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .mg-sm-x-0-force {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .mg-sm-t-0 {
    margin-top: 0px;
  }

  .mg-sm-r-0 {
    margin-right: 0px;
  }

  .mg-sm-b-0 {
    margin-bottom: 0px;
  }

  .mg-sm-l-0 {
    margin-left: 0px;
  }

  .mg-sm-t-0-force {
    margin-top: 0px !important;
  }

  .mg-sm-r-0-force {
    margin-right: 0px !important;
  }

  .mg-sm-b-0-force {
    margin-bottom: 0px !important;
  }

  .mg-sm-l-0-force {
    margin-left: 0px !important;
  }

  .mg-sm-1 {
    margin: 1px;
  }

  .mg-sm-1-force {
    margin: 1px !important;
  }

  .mg-sm-y-1 {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .mg-sm-y-1-force {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .mg-sm-x-1 {
    margin-left: 1px;
    margin-right: 1px;
  }

  .mg-sm-x-1-force {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .mg-sm-t-1 {
    margin-top: 1px;
  }

  .mg-sm-r-1 {
    margin-right: 1px;
  }

  .mg-sm-b-1 {
    margin-bottom: 1px;
  }

  .mg-sm-l-1 {
    margin-left: 1px;
  }

  .mg-sm-t-1-force {
    margin-top: 1px !important;
  }

  .mg-sm-r-1-force {
    margin-right: 1px !important;
  }

  .mg-sm-b-1-force {
    margin-bottom: 1px !important;
  }

  .mg-sm-l-1-force {
    margin-left: 1px !important;
  }

  .mg-sm-2 {
    margin: 2px;
  }

  .mg-sm-2-force {
    margin: 2px !important;
  }

  .mg-sm-y-2 {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .mg-sm-y-2-force {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .mg-sm-x-2 {
    margin-left: 2px;
    margin-right: 2px;
  }

  .mg-sm-x-2-force {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .mg-sm-t-2 {
    margin-top: 2px;
  }

  .mg-sm-r-2 {
    margin-right: 2px;
  }

  .mg-sm-b-2 {
    margin-bottom: 2px;
  }

  .mg-sm-l-2 {
    margin-left: 2px;
  }

  .mg-sm-t-2-force {
    margin-top: 2px !important;
  }

  .mg-sm-r-2-force {
    margin-right: 2px !important;
  }

  .mg-sm-b-2-force {
    margin-bottom: 2px !important;
  }

  .mg-sm-l-2-force {
    margin-left: 2px !important;
  }

  .mg-sm-3 {
    margin: 3px;
  }

  .mg-sm-3-force {
    margin: 3px !important;
  }

  .mg-sm-y-3 {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .mg-sm-y-3-force {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .mg-sm-x-3 {
    margin-left: 3px;
    margin-right: 3px;
  }

  .mg-sm-x-3-force {
    margin-left: 3px !important;
    margin-right: 3px !important;
  }

  .mg-sm-t-3 {
    margin-top: 3px;
  }

  .mg-sm-r-3 {
    margin-right: 3px;
  }

  .mg-sm-b-3 {
    margin-bottom: 3px;
  }

  .mg-sm-l-3 {
    margin-left: 3px;
  }

  .mg-sm-t-3-force {
    margin-top: 3px !important;
  }

  .mg-sm-r-3-force {
    margin-right: 3px !important;
  }

  .mg-sm-b-3-force {
    margin-bottom: 3px !important;
  }

  .mg-sm-l-3-force {
    margin-left: 3px !important;
  }

  .mg-sm-4 {
    margin: 4px;
  }

  .mg-sm-4-force {
    margin: 4px !important;
  }

  .mg-sm-y-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .mg-sm-y-4-force {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .mg-sm-x-4 {
    margin-left: 4px;
    margin-right: 4px;
  }

  .mg-sm-x-4-force {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }

  .mg-sm-t-4 {
    margin-top: 4px;
  }

  .mg-sm-r-4 {
    margin-right: 4px;
  }

  .mg-sm-b-4 {
    margin-bottom: 4px;
  }

  .mg-sm-l-4 {
    margin-left: 4px;
  }

  .mg-sm-t-4-force {
    margin-top: 4px !important;
  }

  .mg-sm-r-4-force {
    margin-right: 4px !important;
  }

  .mg-sm-b-4-force {
    margin-bottom: 4px !important;
  }

  .mg-sm-l-4-force {
    margin-left: 4px !important;
  }

  .mg-sm-5 {
    margin: 5px;
  }

  .mg-sm-5-force {
    margin: 5px !important;
  }

  .mg-sm-y-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .mg-sm-y-5-force {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .mg-sm-x-5 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mg-sm-x-5-force {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .mg-sm-t-5 {
    margin-top: 5px;
  }

  .mg-sm-r-5 {
    margin-right: 5px;
  }

  .mg-sm-b-5 {
    margin-bottom: 5px;
  }

  .mg-sm-l-5 {
    margin-left: 5px;
  }

  .mg-sm-t-5-force {
    margin-top: 5px !important;
  }

  .mg-sm-r-5-force {
    margin-right: 5px !important;
  }

  .mg-sm-b-5-force {
    margin-bottom: 5px !important;
  }

  .mg-sm-l-5-force {
    margin-left: 5px !important;
  }

  .mg-sm-6 {
    margin: 6px;
  }

  .mg-sm-6-force {
    margin: 6px !important;
  }

  .mg-sm-y-6 {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .mg-sm-y-6-force {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }

  .mg-sm-x-6 {
    margin-left: 6px;
    margin-right: 6px;
  }

  .mg-sm-x-6-force {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }

  .mg-sm-t-6 {
    margin-top: 6px;
  }

  .mg-sm-r-6 {
    margin-right: 6px;
  }

  .mg-sm-b-6 {
    margin-bottom: 6px;
  }

  .mg-sm-l-6 {
    margin-left: 6px;
  }

  .mg-sm-t-6-force {
    margin-top: 6px !important;
  }

  .mg-sm-r-6-force {
    margin-right: 6px !important;
  }

  .mg-sm-b-6-force {
    margin-bottom: 6px !important;
  }

  .mg-sm-l-6-force {
    margin-left: 6px !important;
  }

  .mg-sm-7 {
    margin: 7px;
  }

  .mg-sm-7-force {
    margin: 7px !important;
  }

  .mg-sm-y-7 {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .mg-sm-y-7-force {
    margin-top: 7px !important;
    margin-bottom: 7px !important;
  }

  .mg-sm-x-7 {
    margin-left: 7px;
    margin-right: 7px;
  }

  .mg-sm-x-7-force {
    margin-left: 7px !important;
    margin-right: 7px !important;
  }

  .mg-sm-t-7 {
    margin-top: 7px;
  }

  .mg-sm-r-7 {
    margin-right: 7px;
  }

  .mg-sm-b-7 {
    margin-bottom: 7px;
  }

  .mg-sm-l-7 {
    margin-left: 7px;
  }

  .mg-sm-t-7-force {
    margin-top: 7px !important;
  }

  .mg-sm-r-7-force {
    margin-right: 7px !important;
  }

  .mg-sm-b-7-force {
    margin-bottom: 7px !important;
  }

  .mg-sm-l-7-force {
    margin-left: 7px !important;
  }

  .mg-sm-8 {
    margin: 8px;
  }

  .mg-sm-8-force {
    margin: 8px !important;
  }

  .mg-sm-y-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .mg-sm-y-8-force {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .mg-sm-x-8 {
    margin-left: 8px;
    margin-right: 8px;
  }

  .mg-sm-x-8-force {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .mg-sm-t-8 {
    margin-top: 8px;
  }

  .mg-sm-r-8 {
    margin-right: 8px;
  }

  .mg-sm-b-8 {
    margin-bottom: 8px;
  }

  .mg-sm-l-8 {
    margin-left: 8px;
  }

  .mg-sm-t-8-force {
    margin-top: 8px !important;
  }

  .mg-sm-r-8-force {
    margin-right: 8px !important;
  }

  .mg-sm-b-8-force {
    margin-bottom: 8px !important;
  }

  .mg-sm-l-8-force {
    margin-left: 8px !important;
  }

  .mg-sm-9 {
    margin: 9px;
  }

  .mg-sm-9-force {
    margin: 9px !important;
  }

  .mg-sm-y-9 {
    margin-top: 9px;
    margin-bottom: 9px;
  }

  .mg-sm-y-9-force {
    margin-top: 9px !important;
    margin-bottom: 9px !important;
  }

  .mg-sm-x-9 {
    margin-left: 9px;
    margin-right: 9px;
  }

  .mg-sm-x-9-force {
    margin-left: 9px !important;
    margin-right: 9px !important;
  }

  .mg-sm-t-9 {
    margin-top: 9px;
  }

  .mg-sm-r-9 {
    margin-right: 9px;
  }

  .mg-sm-b-9 {
    margin-bottom: 9px;
  }

  .mg-sm-l-9 {
    margin-left: 9px;
  }

  .mg-sm-t-9-force {
    margin-top: 9px !important;
  }

  .mg-sm-r-9-force {
    margin-right: 9px !important;
  }

  .mg-sm-b-9-force {
    margin-bottom: 9px !important;
  }

  .mg-sm-l-9-force {
    margin-left: 9px !important;
  }

  .mg-sm-10 {
    margin: 10px;
  }

  .mg-sm-10-force {
    margin: 10px !important;
  }

  .mg-sm-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mg-sm-y-10-force {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .mg-sm-x-10 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mg-sm-x-10-force {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .mg-sm-t-10 {
    margin-top: 10px;
  }

  .mg-sm-r-10 {
    margin-right: 10px;
  }

  .mg-sm-b-10 {
    margin-bottom: 10px;
  }

  .mg-sm-l-10 {
    margin-left: 10px;
  }

  .mg-sm-t-10-force {
    margin-top: 10px !important;
  }

  .mg-sm-r-10-force {
    margin-right: 10px !important;
  }

  .mg-sm-b-10-force {
    margin-bottom: 10px !important;
  }

  .mg-sm-l-10-force {
    margin-left: 10px !important;
  }

  .mg-sm-15 {
    margin: 15px;
  }

  .mg-sm-15-force {
    margin: 15px !important;
  }

  .mg-sm-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .mg-sm-y-15-force {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .mg-sm-x-15 {
    margin-left: 15px;
    margin-right: 15px;
  }

  .mg-sm-x-15-force {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .mg-sm-t-15 {
    margin-top: 15px;
  }

  .mg-sm-r-15 {
    margin-right: 15px;
  }

  .mg-sm-b-15 {
    margin-bottom: 15px;
  }

  .mg-sm-l-15 {
    margin-left: 15px;
  }

  .mg-sm-t-15-force {
    margin-top: 15px !important;
  }

  .mg-sm-r-15-force {
    margin-right: 15px !important;
  }

  .mg-sm-b-15-force {
    margin-bottom: 15px !important;
  }

  .mg-sm-l-15-force {
    margin-left: 15px !important;
  }

  .mg-sm-20 {
    margin: 20px;
  }

  .mg-sm-20-force {
    margin: 20px !important;
  }

  .mg-sm-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mg-sm-y-20-force {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .mg-sm-x-20 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mg-sm-x-20-force {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .mg-sm-t-20 {
    margin-top: 20px;
  }

  .mg-sm-r-20 {
    margin-right: 20px;
  }

  .mg-sm-b-20 {
    margin-bottom: 20px;
  }

  .mg-sm-l-20 {
    margin-left: 20px;
  }

  .mg-sm-t-20-force {
    margin-top: 20px !important;
  }

  .mg-sm-r-20-force {
    margin-right: 20px !important;
  }

  .mg-sm-b-20-force {
    margin-bottom: 20px !important;
  }

  .mg-sm-l-20-force {
    margin-left: 20px !important;
  }

  .mg-sm-25 {
    margin: 25px;
  }

  .mg-sm-25-force {
    margin: 25px !important;
  }

  .mg-sm-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .mg-sm-y-25-force {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .mg-sm-x-25 {
    margin-left: 25px;
    margin-right: 25px;
  }

  .mg-sm-x-25-force {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }

  .mg-sm-t-25 {
    margin-top: 25px;
  }

  .mg-sm-r-25 {
    margin-right: 25px;
  }

  .mg-sm-b-25 {
    margin-bottom: 25px;
  }

  .mg-sm-l-25 {
    margin-left: 25px;
  }

  .mg-sm-t-25-force {
    margin-top: 25px !important;
  }

  .mg-sm-r-25-force {
    margin-right: 25px !important;
  }

  .mg-sm-b-25-force {
    margin-bottom: 25px !important;
  }

  .mg-sm-l-25-force {
    margin-left: 25px !important;
  }

  .mg-sm-30 {
    margin: 30px;
  }

  .mg-sm-30-force {
    margin: 30px !important;
  }

  .mg-sm-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mg-sm-y-30-force {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .mg-sm-x-30 {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mg-sm-x-30-force {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .mg-sm-t-30 {
    margin-top: 30px;
  }

  .mg-sm-r-30 {
    margin-right: 30px;
  }

  .mg-sm-b-30 {
    margin-bottom: 30px;
  }

  .mg-sm-l-30 {
    margin-left: 30px;
  }

  .mg-sm-t-30-force {
    margin-top: 30px !important;
  }

  .mg-sm-r-30-force {
    margin-right: 30px !important;
  }

  .mg-sm-b-30-force {
    margin-bottom: 30px !important;
  }

  .mg-sm-l-30-force {
    margin-left: 30px !important;
  }

  .mg-sm-35 {
    margin: 35px;
  }

  .mg-sm-35-force {
    margin: 35px !important;
  }

  .mg-sm-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .mg-sm-y-35-force {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }

  .mg-sm-x-35 {
    margin-left: 35px;
    margin-right: 35px;
  }

  .mg-sm-x-35-force {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }

  .mg-sm-t-35 {
    margin-top: 35px;
  }

  .mg-sm-r-35 {
    margin-right: 35px;
  }

  .mg-sm-b-35 {
    margin-bottom: 35px;
  }

  .mg-sm-l-35 {
    margin-left: 35px;
  }

  .mg-sm-t-35-force {
    margin-top: 35px !important;
  }

  .mg-sm-r-35-force {
    margin-right: 35px !important;
  }

  .mg-sm-b-35-force {
    margin-bottom: 35px !important;
  }

  .mg-sm-l-35-force {
    margin-left: 35px !important;
  }

  .mg-sm-40 {
    margin: 40px;
  }

  .mg-sm-40-force {
    margin: 40px !important;
  }

  .mg-sm-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .mg-sm-y-40-force {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .mg-sm-x-40 {
    margin-left: 40px;
    margin-right: 40px;
  }

  .mg-sm-x-40-force {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .mg-sm-t-40 {
    margin-top: 40px;
  }

  .mg-sm-r-40 {
    margin-right: 40px;
  }

  .mg-sm-b-40 {
    margin-bottom: 40px;
  }

  .mg-sm-l-40 {
    margin-left: 40px;
  }

  .mg-sm-t-40-force {
    margin-top: 40px !important;
  }

  .mg-sm-r-40-force {
    margin-right: 40px !important;
  }

  .mg-sm-b-40-force {
    margin-bottom: 40px !important;
  }

  .mg-sm-l-40-force {
    margin-left: 40px !important;
  }

  .mg-sm-45 {
    margin: 45px;
  }

  .mg-sm-45-force {
    margin: 45px !important;
  }

  .mg-sm-y-45 {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .mg-sm-y-45-force {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }

  .mg-sm-x-45 {
    margin-left: 45px;
    margin-right: 45px;
  }

  .mg-sm-x-45-force {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }

  .mg-sm-t-45 {
    margin-top: 45px;
  }

  .mg-sm-r-45 {
    margin-right: 45px;
  }

  .mg-sm-b-45 {
    margin-bottom: 45px;
  }

  .mg-sm-l-45 {
    margin-left: 45px;
  }

  .mg-sm-t-45-force {
    margin-top: 45px !important;
  }

  .mg-sm-r-45-force {
    margin-right: 45px !important;
  }

  .mg-sm-b-45-force {
    margin-bottom: 45px !important;
  }

  .mg-sm-l-45-force {
    margin-left: 45px !important;
  }

  .mg-sm-50 {
    margin: 50px;
  }

  .mg-sm-50-force {
    margin: 50px !important;
  }

  .mg-sm-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .mg-sm-y-50-force {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .mg-sm-x-50 {
    margin-left: 50px;
    margin-right: 50px;
  }

  .mg-sm-x-50-force {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .mg-sm-t-50 {
    margin-top: 50px;
  }

  .mg-sm-r-50 {
    margin-right: 50px;
  }

  .mg-sm-b-50 {
    margin-bottom: 50px;
  }

  .mg-sm-l-50 {
    margin-left: 50px;
  }

  .mg-sm-t-50-force {
    margin-top: 50px !important;
  }

  .mg-sm-r-50-force {
    margin-right: 50px !important;
  }

  .mg-sm-b-50-force {
    margin-bottom: 50px !important;
  }

  .mg-sm-l-50-force {
    margin-left: 50px !important;
  }

  .mg-sm-55 {
    margin: 55px;
  }

  .mg-sm-55-force {
    margin: 55px !important;
  }

  .mg-sm-y-55 {
    margin-top: 55px;
    margin-bottom: 55px;
  }

  .mg-sm-y-55-force {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }

  .mg-sm-x-55 {
    margin-left: 55px;
    margin-right: 55px;
  }

  .mg-sm-x-55-force {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }

  .mg-sm-t-55 {
    margin-top: 55px;
  }

  .mg-sm-r-55 {
    margin-right: 55px;
  }

  .mg-sm-b-55 {
    margin-bottom: 55px;
  }

  .mg-sm-l-55 {
    margin-left: 55px;
  }

  .mg-sm-t-55-force {
    margin-top: 55px !important;
  }

  .mg-sm-r-55-force {
    margin-right: 55px !important;
  }

  .mg-sm-b-55-force {
    margin-bottom: 55px !important;
  }

  .mg-sm-l-55-force {
    margin-left: 55px !important;
  }

  .mg-sm-60 {
    margin: 60px;
  }

  .mg-sm-60-force {
    margin: 60px !important;
  }

  .mg-sm-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .mg-sm-y-60-force {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  .mg-sm-x-60 {
    margin-left: 60px;
    margin-right: 60px;
  }

  .mg-sm-x-60-force {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }

  .mg-sm-t-60 {
    margin-top: 60px;
  }

  .mg-sm-r-60 {
    margin-right: 60px;
  }

  .mg-sm-b-60 {
    margin-bottom: 60px;
  }

  .mg-sm-l-60 {
    margin-left: 60px;
  }

  .mg-sm-t-60-force {
    margin-top: 60px !important;
  }

  .mg-sm-r-60-force {
    margin-right: 60px !important;
  }

  .mg-sm-b-60-force {
    margin-bottom: 60px !important;
  }

  .mg-sm-l-60-force {
    margin-left: 60px !important;
  }

  .mg-sm-65 {
    margin: 65px;
  }

  .mg-sm-65-force {
    margin: 65px !important;
  }

  .mg-sm-y-65 {
    margin-top: 65px;
    margin-bottom: 65px;
  }

  .mg-sm-y-65-force {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }

  .mg-sm-x-65 {
    margin-left: 65px;
    margin-right: 65px;
  }

  .mg-sm-x-65-force {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }

  .mg-sm-t-65 {
    margin-top: 65px;
  }

  .mg-sm-r-65 {
    margin-right: 65px;
  }

  .mg-sm-b-65 {
    margin-bottom: 65px;
  }

  .mg-sm-l-65 {
    margin-left: 65px;
  }

  .mg-sm-t-65-force {
    margin-top: 65px !important;
  }

  .mg-sm-r-65-force {
    margin-right: 65px !important;
  }

  .mg-sm-b-65-force {
    margin-bottom: 65px !important;
  }

  .mg-sm-l-65-force {
    margin-left: 65px !important;
  }

  .mg-sm-70 {
    margin: 70px;
  }

  .mg-sm-70-force {
    margin: 70px !important;
  }

  .mg-sm-y-70 {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .mg-sm-y-70-force {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }

  .mg-sm-x-70 {
    margin-left: 70px;
    margin-right: 70px;
  }

  .mg-sm-x-70-force {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }

  .mg-sm-t-70 {
    margin-top: 70px;
  }

  .mg-sm-r-70 {
    margin-right: 70px;
  }

  .mg-sm-b-70 {
    margin-bottom: 70px;
  }

  .mg-sm-l-70 {
    margin-left: 70px;
  }

  .mg-sm-t-70-force {
    margin-top: 70px !important;
  }

  .mg-sm-r-70-force {
    margin-right: 70px !important;
  }

  .mg-sm-b-70-force {
    margin-bottom: 70px !important;
  }

  .mg-sm-l-70-force {
    margin-left: 70px !important;
  }

  .mg-sm-75 {
    margin: 75px;
  }

  .mg-sm-75-force {
    margin: 75px !important;
  }

  .mg-sm-y-75 {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .mg-sm-y-75-force {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }

  .mg-sm-x-75 {
    margin-left: 75px;
    margin-right: 75px;
  }

  .mg-sm-x-75-force {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }

  .mg-sm-t-75 {
    margin-top: 75px;
  }

  .mg-sm-r-75 {
    margin-right: 75px;
  }

  .mg-sm-b-75 {
    margin-bottom: 75px;
  }

  .mg-sm-l-75 {
    margin-left: 75px;
  }

  .mg-sm-t-75-force {
    margin-top: 75px !important;
  }

  .mg-sm-r-75-force {
    margin-right: 75px !important;
  }

  .mg-sm-b-75-force {
    margin-bottom: 75px !important;
  }

  .mg-sm-l-75-force {
    margin-left: 75px !important;
  }

  .mg-sm-80 {
    margin: 80px;
  }

  .mg-sm-80-force {
    margin: 80px !important;
  }

  .mg-sm-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .mg-sm-y-80-force {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }

  .mg-sm-x-80 {
    margin-left: 80px;
    margin-right: 80px;
  }

  .mg-sm-x-80-force {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }

  .mg-sm-t-80 {
    margin-top: 80px;
  }

  .mg-sm-r-80 {
    margin-right: 80px;
  }

  .mg-sm-b-80 {
    margin-bottom: 80px;
  }

  .mg-sm-l-80 {
    margin-left: 80px;
  }

  .mg-sm-t-80-force {
    margin-top: 80px !important;
  }

  .mg-sm-r-80-force {
    margin-right: 80px !important;
  }

  .mg-sm-b-80-force {
    margin-bottom: 80px !important;
  }

  .mg-sm-l-80-force {
    margin-left: 80px !important;
  }

  .mg-sm-85 {
    margin: 85px;
  }

  .mg-sm-85-force {
    margin: 85px !important;
  }

  .mg-sm-y-85 {
    margin-top: 85px;
    margin-bottom: 85px;
  }

  .mg-sm-y-85-force {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }

  .mg-sm-x-85 {
    margin-left: 85px;
    margin-right: 85px;
  }

  .mg-sm-x-85-force {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }

  .mg-sm-t-85 {
    margin-top: 85px;
  }

  .mg-sm-r-85 {
    margin-right: 85px;
  }

  .mg-sm-b-85 {
    margin-bottom: 85px;
  }

  .mg-sm-l-85 {
    margin-left: 85px;
  }

  .mg-sm-t-85-force {
    margin-top: 85px !important;
  }

  .mg-sm-r-85-force {
    margin-right: 85px !important;
  }

  .mg-sm-b-85-force {
    margin-bottom: 85px !important;
  }

  .mg-sm-l-85-force {
    margin-left: 85px !important;
  }

  .mg-sm-90 {
    margin: 90px;
  }

  .mg-sm-90-force {
    margin: 90px !important;
  }

  .mg-sm-y-90 {
    margin-top: 90px;
    margin-bottom: 90px;
  }

  .mg-sm-y-90-force {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }

  .mg-sm-x-90 {
    margin-left: 90px;
    margin-right: 90px;
  }

  .mg-sm-x-90-force {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }

  .mg-sm-t-90 {
    margin-top: 90px;
  }

  .mg-sm-r-90 {
    margin-right: 90px;
  }

  .mg-sm-b-90 {
    margin-bottom: 90px;
  }

  .mg-sm-l-90 {
    margin-left: 90px;
  }

  .mg-sm-t-90-force {
    margin-top: 90px !important;
  }

  .mg-sm-r-90-force {
    margin-right: 90px !important;
  }

  .mg-sm-b-90-force {
    margin-bottom: 90px !important;
  }

  .mg-sm-l-90-force {
    margin-left: 90px !important;
  }

  .mg-sm-95 {
    margin: 95px;
  }

  .mg-sm-95-force {
    margin: 95px !important;
  }

  .mg-sm-y-95 {
    margin-top: 95px;
    margin-bottom: 95px;
  }

  .mg-sm-y-95-force {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }

  .mg-sm-x-95 {
    margin-left: 95px;
    margin-right: 95px;
  }

  .mg-sm-x-95-force {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }

  .mg-sm-t-95 {
    margin-top: 95px;
  }

  .mg-sm-r-95 {
    margin-right: 95px;
  }

  .mg-sm-b-95 {
    margin-bottom: 95px;
  }

  .mg-sm-l-95 {
    margin-left: 95px;
  }

  .mg-sm-t-95-force {
    margin-top: 95px !important;
  }

  .mg-sm-r-95-force {
    margin-right: 95px !important;
  }

  .mg-sm-b-95-force {
    margin-bottom: 95px !important;
  }

  .mg-sm-l-95-force {
    margin-left: 95px !important;
  }

  .mg-sm-100 {
    margin: 100px;
  }

  .mg-sm-100-force {
    margin: 100px !important;
  }

  .mg-sm-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .mg-sm-y-100-force {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }

  .mg-sm-x-100 {
    margin-left: 100px;
    margin-right: 100px;
  }

  .mg-sm-x-100-force {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }

  .mg-sm-t-100 {
    margin-top: 100px;
  }

  .mg-sm-r-100 {
    margin-right: 100px;
  }

  .mg-sm-b-100 {
    margin-bottom: 100px;
  }

  .mg-sm-l-100 {
    margin-left: 100px;
  }

  .mg-sm-t-100-force {
    margin-top: 100px !important;
  }

  .mg-sm-r-100-force {
    margin-right: 100px !important;
  }

  .mg-sm-b-100-force {
    margin-bottom: 100px !important;
  }

  .mg-sm-l-100-force {
    margin-left: 100px !important;
  }

  .mg-sm-t-auto {
    margin-top: auto;
  }

  .mg-sm-r-auto {
    margin-right: auto;
  }

  .mg-sm-b-auto {
    margin-bottom: auto;
  }

  .mg-sm-l-auto {
    margin-left: auto;
  }

  .mg-sm-auto {
    margin: auto;
  }

  .mg-sm-x-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .mg-sm-l--1 {
    margin-left: -1px;
  }

  .mg-sm-r--1 {
    margin-right: -1px;
  }

  .mg-sm-t--1 {
    margin-top: -1px;
  }
}
@media (min-width: 768px) {
  .mg-md-0 {
    margin: 0px;
  }

  .mg-md-0-force {
    margin: 0px !important;
  }

  .mg-md-y-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .mg-md-y-0-force {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .mg-md-x-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .mg-md-x-0-force {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .mg-md-t-0 {
    margin-top: 0px;
  }

  .mg-md-r-0 {
    margin-right: 0px;
  }

  .mg-md-b-0 {
    margin-bottom: 0px;
  }

  .mg-md-l-0 {
    margin-left: 0px;
  }

  .mg-md-t-0-force {
    margin-top: 0px !important;
  }

  .mg-md-r-0-force {
    margin-right: 0px !important;
  }

  .mg-md-b-0-force {
    margin-bottom: 0px !important;
  }

  .mg-md-l-0-force {
    margin-left: 0px !important;
  }

  .mg-md-1 {
    margin: 1px;
  }

  .mg-md-1-force {
    margin: 1px !important;
  }

  .mg-md-y-1 {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .mg-md-y-1-force {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .mg-md-x-1 {
    margin-left: 1px;
    margin-right: 1px;
  }

  .mg-md-x-1-force {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .mg-md-t-1 {
    margin-top: 1px;
  }

  .mg-md-r-1 {
    margin-right: 1px;
  }

  .mg-md-b-1 {
    margin-bottom: 1px;
  }

  .mg-md-l-1 {
    margin-left: 1px;
  }

  .mg-md-t-1-force {
    margin-top: 1px !important;
  }

  .mg-md-r-1-force {
    margin-right: 1px !important;
  }

  .mg-md-b-1-force {
    margin-bottom: 1px !important;
  }

  .mg-md-l-1-force {
    margin-left: 1px !important;
  }

  .mg-md-2 {
    margin: 2px;
  }

  .mg-md-2-force {
    margin: 2px !important;
  }

  .mg-md-y-2 {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .mg-md-y-2-force {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .mg-md-x-2 {
    margin-left: 2px;
    margin-right: 2px;
  }

  .mg-md-x-2-force {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .mg-md-t-2 {
    margin-top: 2px;
  }

  .mg-md-r-2 {
    margin-right: 2px;
  }

  .mg-md-b-2 {
    margin-bottom: 2px;
  }

  .mg-md-l-2 {
    margin-left: 2px;
  }

  .mg-md-t-2-force {
    margin-top: 2px !important;
  }

  .mg-md-r-2-force {
    margin-right: 2px !important;
  }

  .mg-md-b-2-force {
    margin-bottom: 2px !important;
  }

  .mg-md-l-2-force {
    margin-left: 2px !important;
  }

  .mg-md-3 {
    margin: 3px;
  }

  .mg-md-3-force {
    margin: 3px !important;
  }

  .mg-md-y-3 {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .mg-md-y-3-force {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .mg-md-x-3 {
    margin-left: 3px;
    margin-right: 3px;
  }

  .mg-md-x-3-force {
    margin-left: 3px !important;
    margin-right: 3px !important;
  }

  .mg-md-t-3 {
    margin-top: 3px;
  }

  .mg-md-r-3 {
    margin-right: 3px;
  }

  .mg-md-b-3 {
    margin-bottom: 3px;
  }

  .mg-md-l-3 {
    margin-left: 3px;
  }

  .mg-md-t-3-force {
    margin-top: 3px !important;
  }

  .mg-md-r-3-force {
    margin-right: 3px !important;
  }

  .mg-md-b-3-force {
    margin-bottom: 3px !important;
  }

  .mg-md-l-3-force {
    margin-left: 3px !important;
  }

  .mg-md-4 {
    margin: 4px;
  }

  .mg-md-4-force {
    margin: 4px !important;
  }

  .mg-md-y-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .mg-md-y-4-force {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .mg-md-x-4 {
    margin-left: 4px;
    margin-right: 4px;
  }

  .mg-md-x-4-force {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }

  .mg-md-t-4 {
    margin-top: 4px;
  }

  .mg-md-r-4 {
    margin-right: 4px;
  }

  .mg-md-b-4 {
    margin-bottom: 4px;
  }

  .mg-md-l-4 {
    margin-left: 4px;
  }

  .mg-md-t-4-force {
    margin-top: 4px !important;
  }

  .mg-md-r-4-force {
    margin-right: 4px !important;
  }

  .mg-md-b-4-force {
    margin-bottom: 4px !important;
  }

  .mg-md-l-4-force {
    margin-left: 4px !important;
  }

  .mg-md-5 {
    margin: 5px;
  }

  .mg-md-5-force {
    margin: 5px !important;
  }

  .mg-md-y-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .mg-md-y-5-force {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .mg-md-x-5 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mg-md-x-5-force {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .mg-md-t-5 {
    margin-top: 5px;
  }

  .mg-md-r-5 {
    margin-right: 5px;
  }

  .mg-md-b-5 {
    margin-bottom: 5px;
  }

  .mg-md-l-5 {
    margin-left: 5px;
  }

  .mg-md-t-5-force {
    margin-top: 5px !important;
  }

  .mg-md-r-5-force {
    margin-right: 5px !important;
  }

  .mg-md-b-5-force {
    margin-bottom: 5px !important;
  }

  .mg-md-l-5-force {
    margin-left: 5px !important;
  }

  .mg-md-6 {
    margin: 6px;
  }

  .mg-md-6-force {
    margin: 6px !important;
  }

  .mg-md-y-6 {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .mg-md-y-6-force {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }

  .mg-md-x-6 {
    margin-left: 6px;
    margin-right: 6px;
  }

  .mg-md-x-6-force {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }

  .mg-md-t-6 {
    margin-top: 6px;
  }

  .mg-md-r-6 {
    margin-right: 6px;
  }

  .mg-md-b-6 {
    margin-bottom: 6px;
  }

  .mg-md-l-6 {
    margin-left: 6px;
  }

  .mg-md-t-6-force {
    margin-top: 6px !important;
  }

  .mg-md-r-6-force {
    margin-right: 6px !important;
  }

  .mg-md-b-6-force {
    margin-bottom: 6px !important;
  }

  .mg-md-l-6-force {
    margin-left: 6px !important;
  }

  .mg-md-7 {
    margin: 7px;
  }

  .mg-md-7-force {
    margin: 7px !important;
  }

  .mg-md-y-7 {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .mg-md-y-7-force {
    margin-top: 7px !important;
    margin-bottom: 7px !important;
  }

  .mg-md-x-7 {
    margin-left: 7px;
    margin-right: 7px;
  }

  .mg-md-x-7-force {
    margin-left: 7px !important;
    margin-right: 7px !important;
  }

  .mg-md-t-7 {
    margin-top: 7px;
  }

  .mg-md-r-7 {
    margin-right: 7px;
  }

  .mg-md-b-7 {
    margin-bottom: 7px;
  }

  .mg-md-l-7 {
    margin-left: 7px;
  }

  .mg-md-t-7-force {
    margin-top: 7px !important;
  }

  .mg-md-r-7-force {
    margin-right: 7px !important;
  }

  .mg-md-b-7-force {
    margin-bottom: 7px !important;
  }

  .mg-md-l-7-force {
    margin-left: 7px !important;
  }

  .mg-md-8 {
    margin: 8px;
  }

  .mg-md-8-force {
    margin: 8px !important;
  }

  .mg-md-y-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .mg-md-y-8-force {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .mg-md-x-8 {
    margin-left: 8px;
    margin-right: 8px;
  }

  .mg-md-x-8-force {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .mg-md-t-8 {
    margin-top: 8px;
  }

  .mg-md-r-8 {
    margin-right: 8px;
  }

  .mg-md-b-8 {
    margin-bottom: 8px;
  }

  .mg-md-l-8 {
    margin-left: 8px;
  }

  .mg-md-t-8-force {
    margin-top: 8px !important;
  }

  .mg-md-r-8-force {
    margin-right: 8px !important;
  }

  .mg-md-b-8-force {
    margin-bottom: 8px !important;
  }

  .mg-md-l-8-force {
    margin-left: 8px !important;
  }

  .mg-md-9 {
    margin: 9px;
  }

  .mg-md-9-force {
    margin: 9px !important;
  }

  .mg-md-y-9 {
    margin-top: 9px;
    margin-bottom: 9px;
  }

  .mg-md-y-9-force {
    margin-top: 9px !important;
    margin-bottom: 9px !important;
  }

  .mg-md-x-9 {
    margin-left: 9px;
    margin-right: 9px;
  }

  .mg-md-x-9-force {
    margin-left: 9px !important;
    margin-right: 9px !important;
  }

  .mg-md-t-9 {
    margin-top: 9px;
  }

  .mg-md-r-9 {
    margin-right: 9px;
  }

  .mg-md-b-9 {
    margin-bottom: 9px;
  }

  .mg-md-l-9 {
    margin-left: 9px;
  }

  .mg-md-t-9-force {
    margin-top: 9px !important;
  }

  .mg-md-r-9-force {
    margin-right: 9px !important;
  }

  .mg-md-b-9-force {
    margin-bottom: 9px !important;
  }

  .mg-md-l-9-force {
    margin-left: 9px !important;
  }

  .mg-md-10 {
    margin: 10px;
  }

  .mg-md-10-force {
    margin: 10px !important;
  }

  .mg-md-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mg-md-y-10-force {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .mg-md-x-10 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mg-md-x-10-force {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .mg-md-t-10 {
    margin-top: 10px;
  }

  .mg-md-r-10 {
    margin-right: 10px;
  }

  .mg-md-b-10 {
    margin-bottom: 10px;
  }

  .mg-md-l-10 {
    margin-left: 10px;
  }

  .mg-md-t-10-force {
    margin-top: 10px !important;
  }

  .mg-md-r-10-force {
    margin-right: 10px !important;
  }

  .mg-md-b-10-force {
    margin-bottom: 10px !important;
  }

  .mg-md-l-10-force {
    margin-left: 10px !important;
  }

  .mg-md-15 {
    margin: 15px;
  }

  .mg-md-15-force {
    margin: 15px !important;
  }

  .mg-md-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .mg-md-y-15-force {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .mg-md-x-15 {
    margin-left: 15px;
    margin-right: 15px;
  }

  .mg-md-x-15-force {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .mg-md-t-15 {
    margin-top: 15px;
  }

  .mg-md-r-15 {
    margin-right: 15px;
  }

  .mg-md-b-15 {
    margin-bottom: 15px;
  }

  .mg-md-l-15 {
    margin-left: 15px;
  }

  .mg-md-t-15-force {
    margin-top: 15px !important;
  }

  .mg-md-r-15-force {
    margin-right: 15px !important;
  }

  .mg-md-b-15-force {
    margin-bottom: 15px !important;
  }

  .mg-md-l-15-force {
    margin-left: 15px !important;
  }

  .mg-md-20 {
    margin: 20px;
  }

  .mg-md-20-force {
    margin: 20px !important;
  }

  .mg-md-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mg-md-y-20-force {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .mg-md-x-20 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mg-md-x-20-force {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .mg-md-t-20 {
    margin-top: 20px;
  }

  .mg-md-r-20 {
    margin-right: 20px;
  }

  .mg-md-b-20 {
    margin-bottom: 20px;
  }

  .mg-md-l-20 {
    margin-left: 20px;
  }

  .mg-md-t-20-force {
    margin-top: 20px !important;
  }

  .mg-md-r-20-force {
    margin-right: 20px !important;
  }

  .mg-md-b-20-force {
    margin-bottom: 20px !important;
  }

  .mg-md-l-20-force {
    margin-left: 20px !important;
  }

  .mg-md-25 {
    margin: 25px;
  }

  .mg-md-25-force {
    margin: 25px !important;
  }

  .mg-md-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .mg-md-y-25-force {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .mg-md-x-25 {
    margin-left: 25px;
    margin-right: 25px;
  }

  .mg-md-x-25-force {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }

  .mg-md-t-25 {
    margin-top: 25px;
  }

  .mg-md-r-25 {
    margin-right: 25px;
  }

  .mg-md-b-25 {
    margin-bottom: 25px;
  }

  .mg-md-l-25 {
    margin-left: 25px;
  }

  .mg-md-t-25-force {
    margin-top: 25px !important;
  }

  .mg-md-r-25-force {
    margin-right: 25px !important;
  }

  .mg-md-b-25-force {
    margin-bottom: 25px !important;
  }

  .mg-md-l-25-force {
    margin-left: 25px !important;
  }

  .mg-md-30 {
    margin: 30px;
  }

  .mg-md-30-force {
    margin: 30px !important;
  }

  .mg-md-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mg-md-y-30-force {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .mg-md-x-30 {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mg-md-x-30-force {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .mg-md-t-30 {
    margin-top: 30px;
  }

  .mg-md-r-30 {
    margin-right: 30px;
  }

  .mg-md-b-30 {
    margin-bottom: 30px;
  }

  .mg-md-l-30 {
    margin-left: 30px;
  }

  .mg-md-t-30-force {
    margin-top: 30px !important;
  }

  .mg-md-r-30-force {
    margin-right: 30px !important;
  }

  .mg-md-b-30-force {
    margin-bottom: 30px !important;
  }

  .mg-md-l-30-force {
    margin-left: 30px !important;
  }

  .mg-md-35 {
    margin: 35px;
  }

  .mg-md-35-force {
    margin: 35px !important;
  }

  .mg-md-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .mg-md-y-35-force {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }

  .mg-md-x-35 {
    margin-left: 35px;
    margin-right: 35px;
  }

  .mg-md-x-35-force {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }

  .mg-md-t-35 {
    margin-top: 35px;
  }

  .mg-md-r-35 {
    margin-right: 35px;
  }

  .mg-md-b-35 {
    margin-bottom: 35px;
  }

  .mg-md-l-35 {
    margin-left: 35px;
  }

  .mg-md-t-35-force {
    margin-top: 35px !important;
  }

  .mg-md-r-35-force {
    margin-right: 35px !important;
  }

  .mg-md-b-35-force {
    margin-bottom: 35px !important;
  }

  .mg-md-l-35-force {
    margin-left: 35px !important;
  }

  .mg-md-40 {
    margin: 40px;
  }

  .mg-md-40-force {
    margin: 40px !important;
  }

  .mg-md-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .mg-md-y-40-force {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .mg-md-x-40 {
    margin-left: 40px;
    margin-right: 40px;
  }

  .mg-md-x-40-force {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .mg-md-t-40 {
    margin-top: 40px;
  }

  .mg-md-r-40 {
    margin-right: 40px;
  }

  .mg-md-b-40 {
    margin-bottom: 40px;
  }

  .mg-md-l-40 {
    margin-left: 40px;
  }

  .mg-md-t-40-force {
    margin-top: 40px !important;
  }

  .mg-md-r-40-force {
    margin-right: 40px !important;
  }

  .mg-md-b-40-force {
    margin-bottom: 40px !important;
  }

  .mg-md-l-40-force {
    margin-left: 40px !important;
  }

  .mg-md-45 {
    margin: 45px;
  }

  .mg-md-45-force {
    margin: 45px !important;
  }

  .mg-md-y-45 {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .mg-md-y-45-force {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }

  .mg-md-x-45 {
    margin-left: 45px;
    margin-right: 45px;
  }

  .mg-md-x-45-force {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }

  .mg-md-t-45 {
    margin-top: 45px;
  }

  .mg-md-r-45 {
    margin-right: 45px;
  }

  .mg-md-b-45 {
    margin-bottom: 45px;
  }

  .mg-md-l-45 {
    margin-left: 45px;
  }

  .mg-md-t-45-force {
    margin-top: 45px !important;
  }

  .mg-md-r-45-force {
    margin-right: 45px !important;
  }

  .mg-md-b-45-force {
    margin-bottom: 45px !important;
  }

  .mg-md-l-45-force {
    margin-left: 45px !important;
  }

  .mg-md-50 {
    margin: 50px;
  }

  .mg-md-50-force {
    margin: 50px !important;
  }

  .mg-md-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .mg-md-y-50-force {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .mg-md-x-50 {
    margin-left: 50px;
    margin-right: 50px;
  }

  .mg-md-x-50-force {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .mg-md-t-50 {
    margin-top: 50px;
  }

  .mg-md-r-50 {
    margin-right: 50px;
  }

  .mg-md-b-50 {
    margin-bottom: 50px;
  }

  .mg-md-l-50 {
    margin-left: 50px;
  }

  .mg-md-t-50-force {
    margin-top: 50px !important;
  }

  .mg-md-r-50-force {
    margin-right: 50px !important;
  }

  .mg-md-b-50-force {
    margin-bottom: 50px !important;
  }

  .mg-md-l-50-force {
    margin-left: 50px !important;
  }

  .mg-md-55 {
    margin: 55px;
  }

  .mg-md-55-force {
    margin: 55px !important;
  }

  .mg-md-y-55 {
    margin-top: 55px;
    margin-bottom: 55px;
  }

  .mg-md-y-55-force {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }

  .mg-md-x-55 {
    margin-left: 55px;
    margin-right: 55px;
  }

  .mg-md-x-55-force {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }

  .mg-md-t-55 {
    margin-top: 55px;
  }

  .mg-md-r-55 {
    margin-right: 55px;
  }

  .mg-md-b-55 {
    margin-bottom: 55px;
  }

  .mg-md-l-55 {
    margin-left: 55px;
  }

  .mg-md-t-55-force {
    margin-top: 55px !important;
  }

  .mg-md-r-55-force {
    margin-right: 55px !important;
  }

  .mg-md-b-55-force {
    margin-bottom: 55px !important;
  }

  .mg-md-l-55-force {
    margin-left: 55px !important;
  }

  .mg-md-60 {
    margin: 60px;
  }

  .mg-md-60-force {
    margin: 60px !important;
  }

  .mg-md-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .mg-md-y-60-force {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  .mg-md-x-60 {
    margin-left: 60px;
    margin-right: 60px;
  }

  .mg-md-x-60-force {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }

  .mg-md-t-60 {
    margin-top: 60px;
  }

  .mg-md-r-60 {
    margin-right: 60px;
  }

  .mg-md-b-60 {
    margin-bottom: 60px;
  }

  .mg-md-l-60 {
    margin-left: 60px;
  }

  .mg-md-t-60-force {
    margin-top: 60px !important;
  }

  .mg-md-r-60-force {
    margin-right: 60px !important;
  }

  .mg-md-b-60-force {
    margin-bottom: 60px !important;
  }

  .mg-md-l-60-force {
    margin-left: 60px !important;
  }

  .mg-md-65 {
    margin: 65px;
  }

  .mg-md-65-force {
    margin: 65px !important;
  }

  .mg-md-y-65 {
    margin-top: 65px;
    margin-bottom: 65px;
  }

  .mg-md-y-65-force {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }

  .mg-md-x-65 {
    margin-left: 65px;
    margin-right: 65px;
  }

  .mg-md-x-65-force {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }

  .mg-md-t-65 {
    margin-top: 65px;
  }

  .mg-md-r-65 {
    margin-right: 65px;
  }

  .mg-md-b-65 {
    margin-bottom: 65px;
  }

  .mg-md-l-65 {
    margin-left: 65px;
  }

  .mg-md-t-65-force {
    margin-top: 65px !important;
  }

  .mg-md-r-65-force {
    margin-right: 65px !important;
  }

  .mg-md-b-65-force {
    margin-bottom: 65px !important;
  }

  .mg-md-l-65-force {
    margin-left: 65px !important;
  }

  .mg-md-70 {
    margin: 70px;
  }

  .mg-md-70-force {
    margin: 70px !important;
  }

  .mg-md-y-70 {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .mg-md-y-70-force {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }

  .mg-md-x-70 {
    margin-left: 70px;
    margin-right: 70px;
  }

  .mg-md-x-70-force {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }

  .mg-md-t-70 {
    margin-top: 70px;
  }

  .mg-md-r-70 {
    margin-right: 70px;
  }

  .mg-md-b-70 {
    margin-bottom: 70px;
  }

  .mg-md-l-70 {
    margin-left: 70px;
  }

  .mg-md-t-70-force {
    margin-top: 70px !important;
  }

  .mg-md-r-70-force {
    margin-right: 70px !important;
  }

  .mg-md-b-70-force {
    margin-bottom: 70px !important;
  }

  .mg-md-l-70-force {
    margin-left: 70px !important;
  }

  .mg-md-75 {
    margin: 75px;
  }

  .mg-md-75-force {
    margin: 75px !important;
  }

  .mg-md-y-75 {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .mg-md-y-75-force {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }

  .mg-md-x-75 {
    margin-left: 75px;
    margin-right: 75px;
  }

  .mg-md-x-75-force {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }

  .mg-md-t-75 {
    margin-top: 75px;
  }

  .mg-md-r-75 {
    margin-right: 75px;
  }

  .mg-md-b-75 {
    margin-bottom: 75px;
  }

  .mg-md-l-75 {
    margin-left: 75px;
  }

  .mg-md-t-75-force {
    margin-top: 75px !important;
  }

  .mg-md-r-75-force {
    margin-right: 75px !important;
  }

  .mg-md-b-75-force {
    margin-bottom: 75px !important;
  }

  .mg-md-l-75-force {
    margin-left: 75px !important;
  }

  .mg-md-80 {
    margin: 80px;
  }

  .mg-md-80-force {
    margin: 80px !important;
  }

  .mg-md-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .mg-md-y-80-force {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }

  .mg-md-x-80 {
    margin-left: 80px;
    margin-right: 80px;
  }

  .mg-md-x-80-force {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }

  .mg-md-t-80 {
    margin-top: 80px;
  }

  .mg-md-r-80 {
    margin-right: 80px;
  }

  .mg-md-b-80 {
    margin-bottom: 80px;
  }

  .mg-md-l-80 {
    margin-left: 80px;
  }

  .mg-md-t-80-force {
    margin-top: 80px !important;
  }

  .mg-md-r-80-force {
    margin-right: 80px !important;
  }

  .mg-md-b-80-force {
    margin-bottom: 80px !important;
  }

  .mg-md-l-80-force {
    margin-left: 80px !important;
  }

  .mg-md-85 {
    margin: 85px;
  }

  .mg-md-85-force {
    margin: 85px !important;
  }

  .mg-md-y-85 {
    margin-top: 85px;
    margin-bottom: 85px;
  }

  .mg-md-y-85-force {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }

  .mg-md-x-85 {
    margin-left: 85px;
    margin-right: 85px;
  }

  .mg-md-x-85-force {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }

  .mg-md-t-85 {
    margin-top: 85px;
  }

  .mg-md-r-85 {
    margin-right: 85px;
  }

  .mg-md-b-85 {
    margin-bottom: 85px;
  }

  .mg-md-l-85 {
    margin-left: 85px;
  }

  .mg-md-t-85-force {
    margin-top: 85px !important;
  }

  .mg-md-r-85-force {
    margin-right: 85px !important;
  }

  .mg-md-b-85-force {
    margin-bottom: 85px !important;
  }

  .mg-md-l-85-force {
    margin-left: 85px !important;
  }

  .mg-md-90 {
    margin: 90px;
  }

  .mg-md-90-force {
    margin: 90px !important;
  }

  .mg-md-y-90 {
    margin-top: 90px;
    margin-bottom: 90px;
  }

  .mg-md-y-90-force {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }

  .mg-md-x-90 {
    margin-left: 90px;
    margin-right: 90px;
  }

  .mg-md-x-90-force {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }

  .mg-md-t-90 {
    margin-top: 90px;
  }

  .mg-md-r-90 {
    margin-right: 90px;
  }

  .mg-md-b-90 {
    margin-bottom: 90px;
  }

  .mg-md-l-90 {
    margin-left: 90px;
  }

  .mg-md-t-90-force {
    margin-top: 90px !important;
  }

  .mg-md-r-90-force {
    margin-right: 90px !important;
  }

  .mg-md-b-90-force {
    margin-bottom: 90px !important;
  }

  .mg-md-l-90-force {
    margin-left: 90px !important;
  }

  .mg-md-95 {
    margin: 95px;
  }

  .mg-md-95-force {
    margin: 95px !important;
  }

  .mg-md-y-95 {
    margin-top: 95px;
    margin-bottom: 95px;
  }

  .mg-md-y-95-force {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }

  .mg-md-x-95 {
    margin-left: 95px;
    margin-right: 95px;
  }

  .mg-md-x-95-force {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }

  .mg-md-t-95 {
    margin-top: 95px;
  }

  .mg-md-r-95 {
    margin-right: 95px;
  }

  .mg-md-b-95 {
    margin-bottom: 95px;
  }

  .mg-md-l-95 {
    margin-left: 95px;
  }

  .mg-md-t-95-force {
    margin-top: 95px !important;
  }

  .mg-md-r-95-force {
    margin-right: 95px !important;
  }

  .mg-md-b-95-force {
    margin-bottom: 95px !important;
  }

  .mg-md-l-95-force {
    margin-left: 95px !important;
  }

  .mg-md-100 {
    margin: 100px;
  }

  .mg-md-100-force {
    margin: 100px !important;
  }

  .mg-md-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .mg-md-y-100-force {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }

  .mg-md-x-100 {
    margin-left: 100px;
    margin-right: 100px;
  }

  .mg-md-x-100-force {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }

  .mg-md-t-100 {
    margin-top: 100px;
  }

  .mg-md-r-100 {
    margin-right: 100px;
  }

  .mg-md-b-100 {
    margin-bottom: 100px;
  }

  .mg-md-l-100 {
    margin-left: 100px;
  }

  .mg-md-t-100-force {
    margin-top: 100px !important;
  }

  .mg-md-r-100-force {
    margin-right: 100px !important;
  }

  .mg-md-b-100-force {
    margin-bottom: 100px !important;
  }

  .mg-md-l-100-force {
    margin-left: 100px !important;
  }

  .mg-md-t-auto {
    margin-top: auto;
  }

  .mg-md-r-auto {
    margin-right: auto;
  }

  .mg-md-b-auto {
    margin-bottom: auto;
  }

  .mg-md-l-auto {
    margin-left: auto;
  }

  .mg-md-auto {
    margin: auto;
  }

  .mg-md-x-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .mg-md-l--1 {
    margin-left: -1px;
  }

  .mg-md-r--1 {
    margin-right: -1px;
  }

  .mg-md-t--1 {
    margin-top: -1px;
  }
}
@media (min-width: 992px) {
  .mg-lg-0 {
    margin: 0px;
  }

  .mg-lg-0-force {
    margin: 0px !important;
  }

  .mg-lg-y-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .mg-lg-y-0-force {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .mg-lg-x-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .mg-lg-x-0-force {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .mg-lg-t-0 {
    margin-top: 0px;
  }

  .mg-lg-r-0 {
    margin-right: 0px;
  }

  .mg-lg-b-0 {
    margin-bottom: 0px;
  }

  .mg-lg-l-0 {
    margin-left: 0px;
  }

  .mg-lg-t-0-force {
    margin-top: 0px !important;
  }

  .mg-lg-r-0-force {
    margin-right: 0px !important;
  }

  .mg-lg-b-0-force {
    margin-bottom: 0px !important;
  }

  .mg-lg-l-0-force {
    margin-left: 0px !important;
  }

  .mg-lg-1 {
    margin: 1px;
  }

  .mg-lg-1-force {
    margin: 1px !important;
  }

  .mg-lg-y-1 {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .mg-lg-y-1-force {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .mg-lg-x-1 {
    margin-left: 1px;
    margin-right: 1px;
  }

  .mg-lg-x-1-force {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .mg-lg-t-1 {
    margin-top: 1px;
  }

  .mg-lg-r-1 {
    margin-right: 1px;
  }

  .mg-lg-b-1 {
    margin-bottom: 1px;
  }

  .mg-lg-l-1 {
    margin-left: 1px;
  }

  .mg-lg-t-1-force {
    margin-top: 1px !important;
  }

  .mg-lg-r-1-force {
    margin-right: 1px !important;
  }

  .mg-lg-b-1-force {
    margin-bottom: 1px !important;
  }

  .mg-lg-l-1-force {
    margin-left: 1px !important;
  }

  .mg-lg-2 {
    margin: 2px;
  }

  .mg-lg-2-force {
    margin: 2px !important;
  }

  .mg-lg-y-2 {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .mg-lg-y-2-force {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .mg-lg-x-2 {
    margin-left: 2px;
    margin-right: 2px;
  }

  .mg-lg-x-2-force {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .mg-lg-t-2 {
    margin-top: 2px;
  }

  .mg-lg-r-2 {
    margin-right: 2px;
  }

  .mg-lg-b-2 {
    margin-bottom: 2px;
  }

  .mg-lg-l-2 {
    margin-left: 2px;
  }

  .mg-lg-t-2-force {
    margin-top: 2px !important;
  }

  .mg-lg-r-2-force {
    margin-right: 2px !important;
  }

  .mg-lg-b-2-force {
    margin-bottom: 2px !important;
  }

  .mg-lg-l-2-force {
    margin-left: 2px !important;
  }

  .mg-lg-3 {
    margin: 3px;
  }

  .mg-lg-3-force {
    margin: 3px !important;
  }

  .mg-lg-y-3 {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .mg-lg-y-3-force {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .mg-lg-x-3 {
    margin-left: 3px;
    margin-right: 3px;
  }

  .mg-lg-x-3-force {
    margin-left: 3px !important;
    margin-right: 3px !important;
  }

  .mg-lg-t-3 {
    margin-top: 3px;
  }

  .mg-lg-r-3 {
    margin-right: 3px;
  }

  .mg-lg-b-3 {
    margin-bottom: 3px;
  }

  .mg-lg-l-3 {
    margin-left: 3px;
  }

  .mg-lg-t-3-force {
    margin-top: 3px !important;
  }

  .mg-lg-r-3-force {
    margin-right: 3px !important;
  }

  .mg-lg-b-3-force {
    margin-bottom: 3px !important;
  }

  .mg-lg-l-3-force {
    margin-left: 3px !important;
  }

  .mg-lg-4 {
    margin: 4px;
  }

  .mg-lg-4-force {
    margin: 4px !important;
  }

  .mg-lg-y-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .mg-lg-y-4-force {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .mg-lg-x-4 {
    margin-left: 4px;
    margin-right: 4px;
  }

  .mg-lg-x-4-force {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }

  .mg-lg-t-4 {
    margin-top: 4px;
  }

  .mg-lg-r-4 {
    margin-right: 4px;
  }

  .mg-lg-b-4 {
    margin-bottom: 4px;
  }

  .mg-lg-l-4 {
    margin-left: 4px;
  }

  .mg-lg-t-4-force {
    margin-top: 4px !important;
  }

  .mg-lg-r-4-force {
    margin-right: 4px !important;
  }

  .mg-lg-b-4-force {
    margin-bottom: 4px !important;
  }

  .mg-lg-l-4-force {
    margin-left: 4px !important;
  }

  .mg-lg-5 {
    margin: 5px;
  }

  .mg-lg-5-force {
    margin: 5px !important;
  }

  .mg-lg-y-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .mg-lg-y-5-force {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .mg-lg-x-5 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mg-lg-x-5-force {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .mg-lg-t-5 {
    margin-top: 5px;
  }

  .mg-lg-r-5 {
    margin-right: 5px;
  }

  .mg-lg-b-5 {
    margin-bottom: 5px;
  }

  .mg-lg-l-5 {
    margin-left: 5px;
  }

  .mg-lg-t-5-force {
    margin-top: 5px !important;
  }

  .mg-lg-r-5-force {
    margin-right: 5px !important;
  }

  .mg-lg-b-5-force {
    margin-bottom: 5px !important;
  }

  .mg-lg-l-5-force {
    margin-left: 5px !important;
  }

  .mg-lg-6 {
    margin: 6px;
  }

  .mg-lg-6-force {
    margin: 6px !important;
  }

  .mg-lg-y-6 {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .mg-lg-y-6-force {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }

  .mg-lg-x-6 {
    margin-left: 6px;
    margin-right: 6px;
  }

  .mg-lg-x-6-force {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }

  .mg-lg-t-6 {
    margin-top: 6px;
  }

  .mg-lg-r-6 {
    margin-right: 6px;
  }

  .mg-lg-b-6 {
    margin-bottom: 6px;
  }

  .mg-lg-l-6 {
    margin-left: 6px;
  }

  .mg-lg-t-6-force {
    margin-top: 6px !important;
  }

  .mg-lg-r-6-force {
    margin-right: 6px !important;
  }

  .mg-lg-b-6-force {
    margin-bottom: 6px !important;
  }

  .mg-lg-l-6-force {
    margin-left: 6px !important;
  }

  .mg-lg-7 {
    margin: 7px;
  }

  .mg-lg-7-force {
    margin: 7px !important;
  }

  .mg-lg-y-7 {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .mg-lg-y-7-force {
    margin-top: 7px !important;
    margin-bottom: 7px !important;
  }

  .mg-lg-x-7 {
    margin-left: 7px;
    margin-right: 7px;
  }

  .mg-lg-x-7-force {
    margin-left: 7px !important;
    margin-right: 7px !important;
  }

  .mg-lg-t-7 {
    margin-top: 7px;
  }

  .mg-lg-r-7 {
    margin-right: 7px;
  }

  .mg-lg-b-7 {
    margin-bottom: 7px;
  }

  .mg-lg-l-7 {
    margin-left: 7px;
  }

  .mg-lg-t-7-force {
    margin-top: 7px !important;
  }

  .mg-lg-r-7-force {
    margin-right: 7px !important;
  }

  .mg-lg-b-7-force {
    margin-bottom: 7px !important;
  }

  .mg-lg-l-7-force {
    margin-left: 7px !important;
  }

  .mg-lg-8 {
    margin: 8px;
  }

  .mg-lg-8-force {
    margin: 8px !important;
  }

  .mg-lg-y-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .mg-lg-y-8-force {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .mg-lg-x-8 {
    margin-left: 8px;
    margin-right: 8px;
  }

  .mg-lg-x-8-force {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .mg-lg-t-8 {
    margin-top: 8px;
  }

  .mg-lg-r-8 {
    margin-right: 8px;
  }

  .mg-lg-b-8 {
    margin-bottom: 8px;
  }

  .mg-lg-l-8 {
    margin-left: 8px;
  }

  .mg-lg-t-8-force {
    margin-top: 8px !important;
  }

  .mg-lg-r-8-force {
    margin-right: 8px !important;
  }

  .mg-lg-b-8-force {
    margin-bottom: 8px !important;
  }

  .mg-lg-l-8-force {
    margin-left: 8px !important;
  }

  .mg-lg-9 {
    margin: 9px;
  }

  .mg-lg-9-force {
    margin: 9px !important;
  }

  .mg-lg-y-9 {
    margin-top: 9px;
    margin-bottom: 9px;
  }

  .mg-lg-y-9-force {
    margin-top: 9px !important;
    margin-bottom: 9px !important;
  }

  .mg-lg-x-9 {
    margin-left: 9px;
    margin-right: 9px;
  }

  .mg-lg-x-9-force {
    margin-left: 9px !important;
    margin-right: 9px !important;
  }

  .mg-lg-t-9 {
    margin-top: 9px;
  }

  .mg-lg-r-9 {
    margin-right: 9px;
  }

  .mg-lg-b-9 {
    margin-bottom: 9px;
  }

  .mg-lg-l-9 {
    margin-left: 9px;
  }

  .mg-lg-t-9-force {
    margin-top: 9px !important;
  }

  .mg-lg-r-9-force {
    margin-right: 9px !important;
  }

  .mg-lg-b-9-force {
    margin-bottom: 9px !important;
  }

  .mg-lg-l-9-force {
    margin-left: 9px !important;
  }

  .mg-lg-10 {
    margin: 10px;
  }

  .mg-lg-10-force {
    margin: 10px !important;
  }

  .mg-lg-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mg-lg-y-10-force {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .mg-lg-x-10 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mg-lg-x-10-force {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .mg-lg-t-10 {
    margin-top: 10px;
  }

  .mg-lg-r-10 {
    margin-right: 10px;
  }

  .mg-lg-b-10 {
    margin-bottom: 10px;
  }

  .mg-lg-l-10 {
    margin-left: 10px;
  }

  .mg-lg-t-10-force {
    margin-top: 10px !important;
  }

  .mg-lg-r-10-force {
    margin-right: 10px !important;
  }

  .mg-lg-b-10-force {
    margin-bottom: 10px !important;
  }

  .mg-lg-l-10-force {
    margin-left: 10px !important;
  }

  .mg-lg-15 {
    margin: 15px;
  }

  .mg-lg-15-force {
    margin: 15px !important;
  }

  .mg-lg-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .mg-lg-y-15-force {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .mg-lg-x-15 {
    margin-left: 15px;
    margin-right: 15px;
  }

  .mg-lg-x-15-force {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .mg-lg-t-15 {
    margin-top: 15px;
  }

  .mg-lg-r-15 {
    margin-right: 15px;
  }

  .mg-lg-b-15 {
    margin-bottom: 15px;
  }

  .mg-lg-l-15 {
    margin-left: 15px;
  }

  .mg-lg-t-15-force {
    margin-top: 15px !important;
  }

  .mg-lg-r-15-force {
    margin-right: 15px !important;
  }

  .mg-lg-b-15-force {
    margin-bottom: 15px !important;
  }

  .mg-lg-l-15-force {
    margin-left: 15px !important;
  }

  .mg-lg-20 {
    margin: 20px;
  }

  .mg-lg-20-force {
    margin: 20px !important;
  }

  .mg-lg-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mg-lg-y-20-force {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .mg-lg-x-20 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mg-lg-x-20-force {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .mg-lg-t-20 {
    margin-top: 20px;
  }

  .mg-lg-r-20 {
    margin-right: 20px;
  }

  .mg-lg-b-20 {
    margin-bottom: 20px;
  }

  .mg-lg-l-20 {
    margin-left: 20px;
  }

  .mg-lg-t-20-force {
    margin-top: 20px !important;
  }

  .mg-lg-r-20-force {
    margin-right: 20px !important;
  }

  .mg-lg-b-20-force {
    margin-bottom: 20px !important;
  }

  .mg-lg-l-20-force {
    margin-left: 20px !important;
  }

  .mg-lg-25 {
    margin: 25px;
  }

  .mg-lg-25-force {
    margin: 25px !important;
  }

  .mg-lg-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .mg-lg-y-25-force {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .mg-lg-x-25 {
    margin-left: 25px;
    margin-right: 25px;
  }

  .mg-lg-x-25-force {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }

  .mg-lg-t-25 {
    margin-top: 25px;
  }

  .mg-lg-r-25 {
    margin-right: 25px;
  }

  .mg-lg-b-25 {
    margin-bottom: 25px;
  }

  .mg-lg-l-25 {
    margin-left: 25px;
  }

  .mg-lg-t-25-force {
    margin-top: 25px !important;
  }

  .mg-lg-r-25-force {
    margin-right: 25px !important;
  }

  .mg-lg-b-25-force {
    margin-bottom: 25px !important;
  }

  .mg-lg-l-25-force {
    margin-left: 25px !important;
  }

  .mg-lg-30 {
    margin: 30px;
  }

  .mg-lg-30-force {
    margin: 30px !important;
  }

  .mg-lg-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mg-lg-y-30-force {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .mg-lg-x-30 {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mg-lg-x-30-force {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .mg-lg-t-30 {
    margin-top: 30px;
  }

  .mg-lg-r-30 {
    margin-right: 30px;
  }

  .mg-lg-b-30 {
    margin-bottom: 30px;
  }

  .mg-lg-l-30 {
    margin-left: 30px;
  }

  .mg-lg-t-30-force {
    margin-top: 30px !important;
  }

  .mg-lg-r-30-force {
    margin-right: 30px !important;
  }

  .mg-lg-b-30-force {
    margin-bottom: 30px !important;
  }

  .mg-lg-l-30-force {
    margin-left: 30px !important;
  }

  .mg-lg-35 {
    margin: 35px;
  }

  .mg-lg-35-force {
    margin: 35px !important;
  }

  .mg-lg-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .mg-lg-y-35-force {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }

  .mg-lg-x-35 {
    margin-left: 35px;
    margin-right: 35px;
  }

  .mg-lg-x-35-force {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }

  .mg-lg-t-35 {
    margin-top: 35px;
  }

  .mg-lg-r-35 {
    margin-right: 35px;
  }

  .mg-lg-b-35 {
    margin-bottom: 35px;
  }

  .mg-lg-l-35 {
    margin-left: 35px;
  }

  .mg-lg-t-35-force {
    margin-top: 35px !important;
  }

  .mg-lg-r-35-force {
    margin-right: 35px !important;
  }

  .mg-lg-b-35-force {
    margin-bottom: 35px !important;
  }

  .mg-lg-l-35-force {
    margin-left: 35px !important;
  }

  .mg-lg-40 {
    margin: 40px;
  }

  .mg-lg-40-force {
    margin: 40px !important;
  }

  .mg-lg-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .mg-lg-y-40-force {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .mg-lg-x-40 {
    margin-left: 40px;
    margin-right: 40px;
  }

  .mg-lg-x-40-force {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .mg-lg-t-40 {
    margin-top: 40px;
  }

  .mg-lg-r-40 {
    margin-right: 40px;
  }

  .mg-lg-b-40 {
    margin-bottom: 40px;
  }

  .mg-lg-l-40 {
    margin-left: 40px;
  }

  .mg-lg-t-40-force {
    margin-top: 40px !important;
  }

  .mg-lg-r-40-force {
    margin-right: 40px !important;
  }

  .mg-lg-b-40-force {
    margin-bottom: 40px !important;
  }

  .mg-lg-l-40-force {
    margin-left: 40px !important;
  }

  .mg-lg-45 {
    margin: 45px;
  }

  .mg-lg-45-force {
    margin: 45px !important;
  }

  .mg-lg-y-45 {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .mg-lg-y-45-force {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }

  .mg-lg-x-45 {
    margin-left: 45px;
    margin-right: 45px;
  }

  .mg-lg-x-45-force {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }

  .mg-lg-t-45 {
    margin-top: 45px;
  }

  .mg-lg-r-45 {
    margin-right: 45px;
  }

  .mg-lg-b-45 {
    margin-bottom: 45px;
  }

  .mg-lg-l-45 {
    margin-left: 45px;
  }

  .mg-lg-t-45-force {
    margin-top: 45px !important;
  }

  .mg-lg-r-45-force {
    margin-right: 45px !important;
  }

  .mg-lg-b-45-force {
    margin-bottom: 45px !important;
  }

  .mg-lg-l-45-force {
    margin-left: 45px !important;
  }

  .mg-lg-50 {
    margin: 50px;
  }

  .mg-lg-50-force {
    margin: 50px !important;
  }

  .mg-lg-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .mg-lg-y-50-force {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .mg-lg-x-50 {
    margin-left: 50px;
    margin-right: 50px;
  }

  .mg-lg-x-50-force {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .mg-lg-t-50 {
    margin-top: 50px;
  }

  .mg-lg-r-50 {
    margin-right: 50px;
  }

  .mg-lg-b-50 {
    margin-bottom: 50px;
  }

  .mg-lg-l-50 {
    margin-left: 50px;
  }

  .mg-lg-t-50-force {
    margin-top: 50px !important;
  }

  .mg-lg-r-50-force {
    margin-right: 50px !important;
  }

  .mg-lg-b-50-force {
    margin-bottom: 50px !important;
  }

  .mg-lg-l-50-force {
    margin-left: 50px !important;
  }

  .mg-lg-55 {
    margin: 55px;
  }

  .mg-lg-55-force {
    margin: 55px !important;
  }

  .mg-lg-y-55 {
    margin-top: 55px;
    margin-bottom: 55px;
  }

  .mg-lg-y-55-force {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }

  .mg-lg-x-55 {
    margin-left: 55px;
    margin-right: 55px;
  }

  .mg-lg-x-55-force {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }

  .mg-lg-t-55 {
    margin-top: 55px;
  }

  .mg-lg-r-55 {
    margin-right: 55px;
  }

  .mg-lg-b-55 {
    margin-bottom: 55px;
  }

  .mg-lg-l-55 {
    margin-left: 55px;
  }

  .mg-lg-t-55-force {
    margin-top: 55px !important;
  }

  .mg-lg-r-55-force {
    margin-right: 55px !important;
  }

  .mg-lg-b-55-force {
    margin-bottom: 55px !important;
  }

  .mg-lg-l-55-force {
    margin-left: 55px !important;
  }

  .mg-lg-60 {
    margin: 60px;
  }

  .mg-lg-60-force {
    margin: 60px !important;
  }

  .mg-lg-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .mg-lg-y-60-force {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  .mg-lg-x-60 {
    margin-left: 60px;
    margin-right: 60px;
  }

  .mg-lg-x-60-force {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }

  .mg-lg-t-60 {
    margin-top: 60px;
  }

  .mg-lg-r-60 {
    margin-right: 60px;
  }

  .mg-lg-b-60 {
    margin-bottom: 60px;
  }

  .mg-lg-l-60 {
    margin-left: 60px;
  }

  .mg-lg-t-60-force {
    margin-top: 60px !important;
  }

  .mg-lg-r-60-force {
    margin-right: 60px !important;
  }

  .mg-lg-b-60-force {
    margin-bottom: 60px !important;
  }

  .mg-lg-l-60-force {
    margin-left: 60px !important;
  }

  .mg-lg-65 {
    margin: 65px;
  }

  .mg-lg-65-force {
    margin: 65px !important;
  }

  .mg-lg-y-65 {
    margin-top: 65px;
    margin-bottom: 65px;
  }

  .mg-lg-y-65-force {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }

  .mg-lg-x-65 {
    margin-left: 65px;
    margin-right: 65px;
  }

  .mg-lg-x-65-force {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }

  .mg-lg-t-65 {
    margin-top: 65px;
  }

  .mg-lg-r-65 {
    margin-right: 65px;
  }

  .mg-lg-b-65 {
    margin-bottom: 65px;
  }

  .mg-lg-l-65 {
    margin-left: 65px;
  }

  .mg-lg-t-65-force {
    margin-top: 65px !important;
  }

  .mg-lg-r-65-force {
    margin-right: 65px !important;
  }

  .mg-lg-b-65-force {
    margin-bottom: 65px !important;
  }

  .mg-lg-l-65-force {
    margin-left: 65px !important;
  }

  .mg-lg-70 {
    margin: 70px;
  }

  .mg-lg-70-force {
    margin: 70px !important;
  }

  .mg-lg-y-70 {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .mg-lg-y-70-force {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }

  .mg-lg-x-70 {
    margin-left: 70px;
    margin-right: 70px;
  }

  .mg-lg-x-70-force {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }

  .mg-lg-t-70 {
    margin-top: 70px;
  }

  .mg-lg-r-70 {
    margin-right: 70px;
  }

  .mg-lg-b-70 {
    margin-bottom: 70px;
  }

  .mg-lg-l-70 {
    margin-left: 70px;
  }

  .mg-lg-t-70-force {
    margin-top: 70px !important;
  }

  .mg-lg-r-70-force {
    margin-right: 70px !important;
  }

  .mg-lg-b-70-force {
    margin-bottom: 70px !important;
  }

  .mg-lg-l-70-force {
    margin-left: 70px !important;
  }

  .mg-lg-75 {
    margin: 75px;
  }

  .mg-lg-75-force {
    margin: 75px !important;
  }

  .mg-lg-y-75 {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .mg-lg-y-75-force {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }

  .mg-lg-x-75 {
    margin-left: 75px;
    margin-right: 75px;
  }

  .mg-lg-x-75-force {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }

  .mg-lg-t-75 {
    margin-top: 75px;
  }

  .mg-lg-r-75 {
    margin-right: 75px;
  }

  .mg-lg-b-75 {
    margin-bottom: 75px;
  }

  .mg-lg-l-75 {
    margin-left: 75px;
  }

  .mg-lg-t-75-force {
    margin-top: 75px !important;
  }

  .mg-lg-r-75-force {
    margin-right: 75px !important;
  }

  .mg-lg-b-75-force {
    margin-bottom: 75px !important;
  }

  .mg-lg-l-75-force {
    margin-left: 75px !important;
  }

  .mg-lg-80 {
    margin: 80px;
  }

  .mg-lg-80-force {
    margin: 80px !important;
  }

  .mg-lg-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .mg-lg-y-80-force {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }

  .mg-lg-x-80 {
    margin-left: 80px;
    margin-right: 80px;
  }

  .mg-lg-x-80-force {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }

  .mg-lg-t-80 {
    margin-top: 80px;
  }

  .mg-lg-r-80 {
    margin-right: 80px;
  }

  .mg-lg-b-80 {
    margin-bottom: 80px;
  }

  .mg-lg-l-80 {
    margin-left: 80px;
  }

  .mg-lg-t-80-force {
    margin-top: 80px !important;
  }

  .mg-lg-r-80-force {
    margin-right: 80px !important;
  }

  .mg-lg-b-80-force {
    margin-bottom: 80px !important;
  }

  .mg-lg-l-80-force {
    margin-left: 80px !important;
  }

  .mg-lg-85 {
    margin: 85px;
  }

  .mg-lg-85-force {
    margin: 85px !important;
  }

  .mg-lg-y-85 {
    margin-top: 85px;
    margin-bottom: 85px;
  }

  .mg-lg-y-85-force {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }

  .mg-lg-x-85 {
    margin-left: 85px;
    margin-right: 85px;
  }

  .mg-lg-x-85-force {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }

  .mg-lg-t-85 {
    margin-top: 85px;
  }

  .mg-lg-r-85 {
    margin-right: 85px;
  }

  .mg-lg-b-85 {
    margin-bottom: 85px;
  }

  .mg-lg-l-85 {
    margin-left: 85px;
  }

  .mg-lg-t-85-force {
    margin-top: 85px !important;
  }

  .mg-lg-r-85-force {
    margin-right: 85px !important;
  }

  .mg-lg-b-85-force {
    margin-bottom: 85px !important;
  }

  .mg-lg-l-85-force {
    margin-left: 85px !important;
  }

  .mg-lg-90 {
    margin: 90px;
  }

  .mg-lg-90-force {
    margin: 90px !important;
  }

  .mg-lg-y-90 {
    margin-top: 90px;
    margin-bottom: 90px;
  }

  .mg-lg-y-90-force {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }

  .mg-lg-x-90 {
    margin-left: 90px;
    margin-right: 90px;
  }

  .mg-lg-x-90-force {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }

  .mg-lg-t-90 {
    margin-top: 90px;
  }

  .mg-lg-r-90 {
    margin-right: 90px;
  }

  .mg-lg-b-90 {
    margin-bottom: 90px;
  }

  .mg-lg-l-90 {
    margin-left: 90px;
  }

  .mg-lg-t-90-force {
    margin-top: 90px !important;
  }

  .mg-lg-r-90-force {
    margin-right: 90px !important;
  }

  .mg-lg-b-90-force {
    margin-bottom: 90px !important;
  }

  .mg-lg-l-90-force {
    margin-left: 90px !important;
  }

  .mg-lg-95 {
    margin: 95px;
  }

  .mg-lg-95-force {
    margin: 95px !important;
  }

  .mg-lg-y-95 {
    margin-top: 95px;
    margin-bottom: 95px;
  }

  .mg-lg-y-95-force {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }

  .mg-lg-x-95 {
    margin-left: 95px;
    margin-right: 95px;
  }

  .mg-lg-x-95-force {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }

  .mg-lg-t-95 {
    margin-top: 95px;
  }

  .mg-lg-r-95 {
    margin-right: 95px;
  }

  .mg-lg-b-95 {
    margin-bottom: 95px;
  }

  .mg-lg-l-95 {
    margin-left: 95px;
  }

  .mg-lg-t-95-force {
    margin-top: 95px !important;
  }

  .mg-lg-r-95-force {
    margin-right: 95px !important;
  }

  .mg-lg-b-95-force {
    margin-bottom: 95px !important;
  }

  .mg-lg-l-95-force {
    margin-left: 95px !important;
  }

  .mg-lg-100 {
    margin: 100px;
  }

  .mg-lg-100-force {
    margin: 100px !important;
  }

  .mg-lg-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .mg-lg-y-100-force {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }

  .mg-lg-x-100 {
    margin-left: 100px;
    margin-right: 100px;
  }

  .mg-lg-x-100-force {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }

  .mg-lg-t-100 {
    margin-top: 100px;
  }

  .mg-lg-r-100 {
    margin-right: 100px;
  }

  .mg-lg-b-100 {
    margin-bottom: 100px;
  }

  .mg-lg-l-100 {
    margin-left: 100px;
  }

  .mg-lg-t-100-force {
    margin-top: 100px !important;
  }

  .mg-lg-r-100-force {
    margin-right: 100px !important;
  }

  .mg-lg-b-100-force {
    margin-bottom: 100px !important;
  }

  .mg-lg-l-100-force {
    margin-left: 100px !important;
  }

  .mg-lg-t-auto {
    margin-top: auto;
  }

  .mg-lg-r-auto {
    margin-right: auto;
  }

  .mg-lg-b-auto {
    margin-bottom: auto;
  }

  .mg-lg-l-auto {
    margin-left: auto;
  }

  .mg-lg-auto {
    margin: auto;
  }

  .mg-lg-x-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .mg-lg-l--1 {
    margin-left: -1px;
  }

  .mg-lg-r--1 {
    margin-right: -1px;
  }

  .mg-lg-t--1 {
    margin-top: -1px;
  }
}
@media (min-width: 1200px) {
  .mg-xl-0 {
    margin: 0px;
  }

  .mg-xl-0-force {
    margin: 0px !important;
  }

  .mg-xl-y-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .mg-xl-y-0-force {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .mg-xl-x-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .mg-xl-x-0-force {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .mg-xl-t-0 {
    margin-top: 0px;
  }

  .mg-xl-r-0 {
    margin-right: 0px;
  }

  .mg-xl-b-0 {
    margin-bottom: 0px;
  }

  .mg-xl-l-0 {
    margin-left: 0px;
  }

  .mg-xl-t-0-force {
    margin-top: 0px !important;
  }

  .mg-xl-r-0-force {
    margin-right: 0px !important;
  }

  .mg-xl-b-0-force {
    margin-bottom: 0px !important;
  }

  .mg-xl-l-0-force {
    margin-left: 0px !important;
  }

  .mg-xl-1 {
    margin: 1px;
  }

  .mg-xl-1-force {
    margin: 1px !important;
  }

  .mg-xl-y-1 {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .mg-xl-y-1-force {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .mg-xl-x-1 {
    margin-left: 1px;
    margin-right: 1px;
  }

  .mg-xl-x-1-force {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .mg-xl-t-1 {
    margin-top: 1px;
  }

  .mg-xl-r-1 {
    margin-right: 1px;
  }

  .mg-xl-b-1 {
    margin-bottom: 1px;
  }

  .mg-xl-l-1 {
    margin-left: 1px;
  }

  .mg-xl-t-1-force {
    margin-top: 1px !important;
  }

  .mg-xl-r-1-force {
    margin-right: 1px !important;
  }

  .mg-xl-b-1-force {
    margin-bottom: 1px !important;
  }

  .mg-xl-l-1-force {
    margin-left: 1px !important;
  }

  .mg-xl-2 {
    margin: 2px;
  }

  .mg-xl-2-force {
    margin: 2px !important;
  }

  .mg-xl-y-2 {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .mg-xl-y-2-force {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .mg-xl-x-2 {
    margin-left: 2px;
    margin-right: 2px;
  }

  .mg-xl-x-2-force {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .mg-xl-t-2 {
    margin-top: 2px;
  }

  .mg-xl-r-2 {
    margin-right: 2px;
  }

  .mg-xl-b-2 {
    margin-bottom: 2px;
  }

  .mg-xl-l-2 {
    margin-left: 2px;
  }

  .mg-xl-t-2-force {
    margin-top: 2px !important;
  }

  .mg-xl-r-2-force {
    margin-right: 2px !important;
  }

  .mg-xl-b-2-force {
    margin-bottom: 2px !important;
  }

  .mg-xl-l-2-force {
    margin-left: 2px !important;
  }

  .mg-xl-3 {
    margin: 3px;
  }

  .mg-xl-3-force {
    margin: 3px !important;
  }

  .mg-xl-y-3 {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .mg-xl-y-3-force {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .mg-xl-x-3 {
    margin-left: 3px;
    margin-right: 3px;
  }

  .mg-xl-x-3-force {
    margin-left: 3px !important;
    margin-right: 3px !important;
  }

  .mg-xl-t-3 {
    margin-top: 3px;
  }

  .mg-xl-r-3 {
    margin-right: 3px;
  }

  .mg-xl-b-3 {
    margin-bottom: 3px;
  }

  .mg-xl-l-3 {
    margin-left: 3px;
  }

  .mg-xl-t-3-force {
    margin-top: 3px !important;
  }

  .mg-xl-r-3-force {
    margin-right: 3px !important;
  }

  .mg-xl-b-3-force {
    margin-bottom: 3px !important;
  }

  .mg-xl-l-3-force {
    margin-left: 3px !important;
  }

  .mg-xl-4 {
    margin: 4px;
  }

  .mg-xl-4-force {
    margin: 4px !important;
  }

  .mg-xl-y-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .mg-xl-y-4-force {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .mg-xl-x-4 {
    margin-left: 4px;
    margin-right: 4px;
  }

  .mg-xl-x-4-force {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }

  .mg-xl-t-4 {
    margin-top: 4px;
  }

  .mg-xl-r-4 {
    margin-right: 4px;
  }

  .mg-xl-b-4 {
    margin-bottom: 4px;
  }

  .mg-xl-l-4 {
    margin-left: 4px;
  }

  .mg-xl-t-4-force {
    margin-top: 4px !important;
  }

  .mg-xl-r-4-force {
    margin-right: 4px !important;
  }

  .mg-xl-b-4-force {
    margin-bottom: 4px !important;
  }

  .mg-xl-l-4-force {
    margin-left: 4px !important;
  }

  .mg-xl-5 {
    margin: 5px;
  }

  .mg-xl-5-force {
    margin: 5px !important;
  }

  .mg-xl-y-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .mg-xl-y-5-force {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .mg-xl-x-5 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mg-xl-x-5-force {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .mg-xl-t-5 {
    margin-top: 5px;
  }

  .mg-xl-r-5 {
    margin-right: 5px;
  }

  .mg-xl-b-5 {
    margin-bottom: 5px;
  }

  .mg-xl-l-5 {
    margin-left: 5px;
  }

  .mg-xl-t-5-force {
    margin-top: 5px !important;
  }

  .mg-xl-r-5-force {
    margin-right: 5px !important;
  }

  .mg-xl-b-5-force {
    margin-bottom: 5px !important;
  }

  .mg-xl-l-5-force {
    margin-left: 5px !important;
  }

  .mg-xl-6 {
    margin: 6px;
  }

  .mg-xl-6-force {
    margin: 6px !important;
  }

  .mg-xl-y-6 {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .mg-xl-y-6-force {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }

  .mg-xl-x-6 {
    margin-left: 6px;
    margin-right: 6px;
  }

  .mg-xl-x-6-force {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }

  .mg-xl-t-6 {
    margin-top: 6px;
  }

  .mg-xl-r-6 {
    margin-right: 6px;
  }

  .mg-xl-b-6 {
    margin-bottom: 6px;
  }

  .mg-xl-l-6 {
    margin-left: 6px;
  }

  .mg-xl-t-6-force {
    margin-top: 6px !important;
  }

  .mg-xl-r-6-force {
    margin-right: 6px !important;
  }

  .mg-xl-b-6-force {
    margin-bottom: 6px !important;
  }

  .mg-xl-l-6-force {
    margin-left: 6px !important;
  }

  .mg-xl-7 {
    margin: 7px;
  }

  .mg-xl-7-force {
    margin: 7px !important;
  }

  .mg-xl-y-7 {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .mg-xl-y-7-force {
    margin-top: 7px !important;
    margin-bottom: 7px !important;
  }

  .mg-xl-x-7 {
    margin-left: 7px;
    margin-right: 7px;
  }

  .mg-xl-x-7-force {
    margin-left: 7px !important;
    margin-right: 7px !important;
  }

  .mg-xl-t-7 {
    margin-top: 7px;
  }

  .mg-xl-r-7 {
    margin-right: 7px;
  }

  .mg-xl-b-7 {
    margin-bottom: 7px;
  }

  .mg-xl-l-7 {
    margin-left: 7px;
  }

  .mg-xl-t-7-force {
    margin-top: 7px !important;
  }

  .mg-xl-r-7-force {
    margin-right: 7px !important;
  }

  .mg-xl-b-7-force {
    margin-bottom: 7px !important;
  }

  .mg-xl-l-7-force {
    margin-left: 7px !important;
  }

  .mg-xl-8 {
    margin: 8px;
  }

  .mg-xl-8-force {
    margin: 8px !important;
  }

  .mg-xl-y-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .mg-xl-y-8-force {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .mg-xl-x-8 {
    margin-left: 8px;
    margin-right: 8px;
  }

  .mg-xl-x-8-force {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .mg-xl-t-8 {
    margin-top: 8px;
  }

  .mg-xl-r-8 {
    margin-right: 8px;
  }

  .mg-xl-b-8 {
    margin-bottom: 8px;
  }

  .mg-xl-l-8 {
    margin-left: 8px;
  }

  .mg-xl-t-8-force {
    margin-top: 8px !important;
  }

  .mg-xl-r-8-force {
    margin-right: 8px !important;
  }

  .mg-xl-b-8-force {
    margin-bottom: 8px !important;
  }

  .mg-xl-l-8-force {
    margin-left: 8px !important;
  }

  .mg-xl-9 {
    margin: 9px;
  }

  .mg-xl-9-force {
    margin: 9px !important;
  }

  .mg-xl-y-9 {
    margin-top: 9px;
    margin-bottom: 9px;
  }

  .mg-xl-y-9-force {
    margin-top: 9px !important;
    margin-bottom: 9px !important;
  }

  .mg-xl-x-9 {
    margin-left: 9px;
    margin-right: 9px;
  }

  .mg-xl-x-9-force {
    margin-left: 9px !important;
    margin-right: 9px !important;
  }

  .mg-xl-t-9 {
    margin-top: 9px;
  }

  .mg-xl-r-9 {
    margin-right: 9px;
  }

  .mg-xl-b-9 {
    margin-bottom: 9px;
  }

  .mg-xl-l-9 {
    margin-left: 9px;
  }

  .mg-xl-t-9-force {
    margin-top: 9px !important;
  }

  .mg-xl-r-9-force {
    margin-right: 9px !important;
  }

  .mg-xl-b-9-force {
    margin-bottom: 9px !important;
  }

  .mg-xl-l-9-force {
    margin-left: 9px !important;
  }

  .mg-xl-10 {
    margin: 10px;
  }

  .mg-xl-10-force {
    margin: 10px !important;
  }

  .mg-xl-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mg-xl-y-10-force {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .mg-xl-x-10 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mg-xl-x-10-force {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .mg-xl-t-10 {
    margin-top: 10px;
  }

  .mg-xl-r-10 {
    margin-right: 10px;
  }

  .mg-xl-b-10 {
    margin-bottom: 10px;
  }

  .mg-xl-l-10 {
    margin-left: 10px;
  }

  .mg-xl-t-10-force {
    margin-top: 10px !important;
  }

  .mg-xl-r-10-force {
    margin-right: 10px !important;
  }

  .mg-xl-b-10-force {
    margin-bottom: 10px !important;
  }

  .mg-xl-l-10-force {
    margin-left: 10px !important;
  }

  .mg-xl-15 {
    margin: 15px;
  }

  .mg-xl-15-force {
    margin: 15px !important;
  }

  .mg-xl-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .mg-xl-y-15-force {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .mg-xl-x-15 {
    margin-left: 15px;
    margin-right: 15px;
  }

  .mg-xl-x-15-force {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .mg-xl-t-15 {
    margin-top: 15px;
  }

  .mg-xl-r-15 {
    margin-right: 15px;
  }

  .mg-xl-b-15 {
    margin-bottom: 15px;
  }

  .mg-xl-l-15 {
    margin-left: 15px;
  }

  .mg-xl-t-15-force {
    margin-top: 15px !important;
  }

  .mg-xl-r-15-force {
    margin-right: 15px !important;
  }

  .mg-xl-b-15-force {
    margin-bottom: 15px !important;
  }

  .mg-xl-l-15-force {
    margin-left: 15px !important;
  }

  .mg-xl-20 {
    margin: 20px;
  }

  .mg-xl-20-force {
    margin: 20px !important;
  }

  .mg-xl-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mg-xl-y-20-force {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .mg-xl-x-20 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mg-xl-x-20-force {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .mg-xl-t-20 {
    margin-top: 20px;
  }

  .mg-xl-r-20 {
    margin-right: 20px;
  }

  .mg-xl-b-20 {
    margin-bottom: 20px;
  }

  .mg-xl-l-20 {
    margin-left: 20px;
  }

  .mg-xl-t-20-force {
    margin-top: 20px !important;
  }

  .mg-xl-r-20-force {
    margin-right: 20px !important;
  }

  .mg-xl-b-20-force {
    margin-bottom: 20px !important;
  }

  .mg-xl-l-20-force {
    margin-left: 20px !important;
  }

  .mg-xl-25 {
    margin: 25px;
  }

  .mg-xl-25-force {
    margin: 25px !important;
  }

  .mg-xl-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .mg-xl-y-25-force {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .mg-xl-x-25 {
    margin-left: 25px;
    margin-right: 25px;
  }

  .mg-xl-x-25-force {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }

  .mg-xl-t-25 {
    margin-top: 25px;
  }

  .mg-xl-r-25 {
    margin-right: 25px;
  }

  .mg-xl-b-25 {
    margin-bottom: 25px;
  }

  .mg-xl-l-25 {
    margin-left: 25px;
  }

  .mg-xl-t-25-force {
    margin-top: 25px !important;
  }

  .mg-xl-r-25-force {
    margin-right: 25px !important;
  }

  .mg-xl-b-25-force {
    margin-bottom: 25px !important;
  }

  .mg-xl-l-25-force {
    margin-left: 25px !important;
  }

  .mg-xl-30 {
    margin: 30px;
  }

  .mg-xl-30-force {
    margin: 30px !important;
  }

  .mg-xl-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mg-xl-y-30-force {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .mg-xl-x-30 {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mg-xl-x-30-force {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .mg-xl-t-30 {
    margin-top: 30px;
  }

  .mg-xl-r-30 {
    margin-right: 30px;
  }

  .mg-xl-b-30 {
    margin-bottom: 30px;
  }

  .mg-xl-l-30 {
    margin-left: 30px;
  }

  .mg-xl-t-30-force {
    margin-top: 30px !important;
  }

  .mg-xl-r-30-force {
    margin-right: 30px !important;
  }

  .mg-xl-b-30-force {
    margin-bottom: 30px !important;
  }

  .mg-xl-l-30-force {
    margin-left: 30px !important;
  }

  .mg-xl-35 {
    margin: 35px;
  }

  .mg-xl-35-force {
    margin: 35px !important;
  }

  .mg-xl-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .mg-xl-y-35-force {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }

  .mg-xl-x-35 {
    margin-left: 35px;
    margin-right: 35px;
  }

  .mg-xl-x-35-force {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }

  .mg-xl-t-35 {
    margin-top: 35px;
  }

  .mg-xl-r-35 {
    margin-right: 35px;
  }

  .mg-xl-b-35 {
    margin-bottom: 35px;
  }

  .mg-xl-l-35 {
    margin-left: 35px;
  }

  .mg-xl-t-35-force {
    margin-top: 35px !important;
  }

  .mg-xl-r-35-force {
    margin-right: 35px !important;
  }

  .mg-xl-b-35-force {
    margin-bottom: 35px !important;
  }

  .mg-xl-l-35-force {
    margin-left: 35px !important;
  }

  .mg-xl-40 {
    margin: 40px;
  }

  .mg-xl-40-force {
    margin: 40px !important;
  }

  .mg-xl-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .mg-xl-y-40-force {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .mg-xl-x-40 {
    margin-left: 40px;
    margin-right: 40px;
  }

  .mg-xl-x-40-force {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .mg-xl-t-40 {
    margin-top: 40px;
  }

  .mg-xl-r-40 {
    margin-right: 40px;
  }

  .mg-xl-b-40 {
    margin-bottom: 40px;
  }

  .mg-xl-l-40 {
    margin-left: 40px;
  }

  .mg-xl-t-40-force {
    margin-top: 40px !important;
  }

  .mg-xl-r-40-force {
    margin-right: 40px !important;
  }

  .mg-xl-b-40-force {
    margin-bottom: 40px !important;
  }

  .mg-xl-l-40-force {
    margin-left: 40px !important;
  }

  .mg-xl-45 {
    margin: 45px;
  }

  .mg-xl-45-force {
    margin: 45px !important;
  }

  .mg-xl-y-45 {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .mg-xl-y-45-force {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }

  .mg-xl-x-45 {
    margin-left: 45px;
    margin-right: 45px;
  }

  .mg-xl-x-45-force {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }

  .mg-xl-t-45 {
    margin-top: 45px;
  }

  .mg-xl-r-45 {
    margin-right: 45px;
  }

  .mg-xl-b-45 {
    margin-bottom: 45px;
  }

  .mg-xl-l-45 {
    margin-left: 45px;
  }

  .mg-xl-t-45-force {
    margin-top: 45px !important;
  }

  .mg-xl-r-45-force {
    margin-right: 45px !important;
  }

  .mg-xl-b-45-force {
    margin-bottom: 45px !important;
  }

  .mg-xl-l-45-force {
    margin-left: 45px !important;
  }

  .mg-xl-50 {
    margin: 50px;
  }

  .mg-xl-50-force {
    margin: 50px !important;
  }

  .mg-xl-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .mg-xl-y-50-force {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .mg-xl-x-50 {
    margin-left: 50px;
    margin-right: 50px;
  }

  .mg-xl-x-50-force {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .mg-xl-t-50 {
    margin-top: 50px;
  }

  .mg-xl-r-50 {
    margin-right: 50px;
  }

  .mg-xl-b-50 {
    margin-bottom: 50px;
  }

  .mg-xl-l-50 {
    margin-left: 50px;
  }

  .mg-xl-t-50-force {
    margin-top: 50px !important;
  }

  .mg-xl-r-50-force {
    margin-right: 50px !important;
  }

  .mg-xl-b-50-force {
    margin-bottom: 50px !important;
  }

  .mg-xl-l-50-force {
    margin-left: 50px !important;
  }

  .mg-xl-55 {
    margin: 55px;
  }

  .mg-xl-55-force {
    margin: 55px !important;
  }

  .mg-xl-y-55 {
    margin-top: 55px;
    margin-bottom: 55px;
  }

  .mg-xl-y-55-force {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }

  .mg-xl-x-55 {
    margin-left: 55px;
    margin-right: 55px;
  }

  .mg-xl-x-55-force {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }

  .mg-xl-t-55 {
    margin-top: 55px;
  }

  .mg-xl-r-55 {
    margin-right: 55px;
  }

  .mg-xl-b-55 {
    margin-bottom: 55px;
  }

  .mg-xl-l-55 {
    margin-left: 55px;
  }

  .mg-xl-t-55-force {
    margin-top: 55px !important;
  }

  .mg-xl-r-55-force {
    margin-right: 55px !important;
  }

  .mg-xl-b-55-force {
    margin-bottom: 55px !important;
  }

  .mg-xl-l-55-force {
    margin-left: 55px !important;
  }

  .mg-xl-60 {
    margin: 60px;
  }

  .mg-xl-60-force {
    margin: 60px !important;
  }

  .mg-xl-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .mg-xl-y-60-force {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  .mg-xl-x-60 {
    margin-left: 60px;
    margin-right: 60px;
  }

  .mg-xl-x-60-force {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }

  .mg-xl-t-60 {
    margin-top: 60px;
  }

  .mg-xl-r-60 {
    margin-right: 60px;
  }

  .mg-xl-b-60 {
    margin-bottom: 60px;
  }

  .mg-xl-l-60 {
    margin-left: 60px;
  }

  .mg-xl-t-60-force {
    margin-top: 60px !important;
  }

  .mg-xl-r-60-force {
    margin-right: 60px !important;
  }

  .mg-xl-b-60-force {
    margin-bottom: 60px !important;
  }

  .mg-xl-l-60-force {
    margin-left: 60px !important;
  }

  .mg-xl-65 {
    margin: 65px;
  }

  .mg-xl-65-force {
    margin: 65px !important;
  }

  .mg-xl-y-65 {
    margin-top: 65px;
    margin-bottom: 65px;
  }

  .mg-xl-y-65-force {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }

  .mg-xl-x-65 {
    margin-left: 65px;
    margin-right: 65px;
  }

  .mg-xl-x-65-force {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }

  .mg-xl-t-65 {
    margin-top: 65px;
  }

  .mg-xl-r-65 {
    margin-right: 65px;
  }

  .mg-xl-b-65 {
    margin-bottom: 65px;
  }

  .mg-xl-l-65 {
    margin-left: 65px;
  }

  .mg-xl-t-65-force {
    margin-top: 65px !important;
  }

  .mg-xl-r-65-force {
    margin-right: 65px !important;
  }

  .mg-xl-b-65-force {
    margin-bottom: 65px !important;
  }

  .mg-xl-l-65-force {
    margin-left: 65px !important;
  }

  .mg-xl-70 {
    margin: 70px;
  }

  .mg-xl-70-force {
    margin: 70px !important;
  }

  .mg-xl-y-70 {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .mg-xl-y-70-force {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }

  .mg-xl-x-70 {
    margin-left: 70px;
    margin-right: 70px;
  }

  .mg-xl-x-70-force {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }

  .mg-xl-t-70 {
    margin-top: 70px;
  }

  .mg-xl-r-70 {
    margin-right: 70px;
  }

  .mg-xl-b-70 {
    margin-bottom: 70px;
  }

  .mg-xl-l-70 {
    margin-left: 70px;
  }

  .mg-xl-t-70-force {
    margin-top: 70px !important;
  }

  .mg-xl-r-70-force {
    margin-right: 70px !important;
  }

  .mg-xl-b-70-force {
    margin-bottom: 70px !important;
  }

  .mg-xl-l-70-force {
    margin-left: 70px !important;
  }

  .mg-xl-75 {
    margin: 75px;
  }

  .mg-xl-75-force {
    margin: 75px !important;
  }

  .mg-xl-y-75 {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .mg-xl-y-75-force {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }

  .mg-xl-x-75 {
    margin-left: 75px;
    margin-right: 75px;
  }

  .mg-xl-x-75-force {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }

  .mg-xl-t-75 {
    margin-top: 75px;
  }

  .mg-xl-r-75 {
    margin-right: 75px;
  }

  .mg-xl-b-75 {
    margin-bottom: 75px;
  }

  .mg-xl-l-75 {
    margin-left: 75px;
  }

  .mg-xl-t-75-force {
    margin-top: 75px !important;
  }

  .mg-xl-r-75-force {
    margin-right: 75px !important;
  }

  .mg-xl-b-75-force {
    margin-bottom: 75px !important;
  }

  .mg-xl-l-75-force {
    margin-left: 75px !important;
  }

  .mg-xl-80 {
    margin: 80px;
  }

  .mg-xl-80-force {
    margin: 80px !important;
  }

  .mg-xl-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .mg-xl-y-80-force {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }

  .mg-xl-x-80 {
    margin-left: 80px;
    margin-right: 80px;
  }

  .mg-xl-x-80-force {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }

  .mg-xl-t-80 {
    margin-top: 80px;
  }

  .mg-xl-r-80 {
    margin-right: 80px;
  }

  .mg-xl-b-80 {
    margin-bottom: 80px;
  }

  .mg-xl-l-80 {
    margin-left: 80px;
  }

  .mg-xl-t-80-force {
    margin-top: 80px !important;
  }

  .mg-xl-r-80-force {
    margin-right: 80px !important;
  }

  .mg-xl-b-80-force {
    margin-bottom: 80px !important;
  }

  .mg-xl-l-80-force {
    margin-left: 80px !important;
  }

  .mg-xl-85 {
    margin: 85px;
  }

  .mg-xl-85-force {
    margin: 85px !important;
  }

  .mg-xl-y-85 {
    margin-top: 85px;
    margin-bottom: 85px;
  }

  .mg-xl-y-85-force {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }

  .mg-xl-x-85 {
    margin-left: 85px;
    margin-right: 85px;
  }

  .mg-xl-x-85-force {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }

  .mg-xl-t-85 {
    margin-top: 85px;
  }

  .mg-xl-r-85 {
    margin-right: 85px;
  }

  .mg-xl-b-85 {
    margin-bottom: 85px;
  }

  .mg-xl-l-85 {
    margin-left: 85px;
  }

  .mg-xl-t-85-force {
    margin-top: 85px !important;
  }

  .mg-xl-r-85-force {
    margin-right: 85px !important;
  }

  .mg-xl-b-85-force {
    margin-bottom: 85px !important;
  }

  .mg-xl-l-85-force {
    margin-left: 85px !important;
  }

  .mg-xl-90 {
    margin: 90px;
  }

  .mg-xl-90-force {
    margin: 90px !important;
  }

  .mg-xl-y-90 {
    margin-top: 90px;
    margin-bottom: 90px;
  }

  .mg-xl-y-90-force {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }

  .mg-xl-x-90 {
    margin-left: 90px;
    margin-right: 90px;
  }

  .mg-xl-x-90-force {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }

  .mg-xl-t-90 {
    margin-top: 90px;
  }

  .mg-xl-r-90 {
    margin-right: 90px;
  }

  .mg-xl-b-90 {
    margin-bottom: 90px;
  }

  .mg-xl-l-90 {
    margin-left: 90px;
  }

  .mg-xl-t-90-force {
    margin-top: 90px !important;
  }

  .mg-xl-r-90-force {
    margin-right: 90px !important;
  }

  .mg-xl-b-90-force {
    margin-bottom: 90px !important;
  }

  .mg-xl-l-90-force {
    margin-left: 90px !important;
  }

  .mg-xl-95 {
    margin: 95px;
  }

  .mg-xl-95-force {
    margin: 95px !important;
  }

  .mg-xl-y-95 {
    margin-top: 95px;
    margin-bottom: 95px;
  }

  .mg-xl-y-95-force {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }

  .mg-xl-x-95 {
    margin-left: 95px;
    margin-right: 95px;
  }

  .mg-xl-x-95-force {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }

  .mg-xl-t-95 {
    margin-top: 95px;
  }

  .mg-xl-r-95 {
    margin-right: 95px;
  }

  .mg-xl-b-95 {
    margin-bottom: 95px;
  }

  .mg-xl-l-95 {
    margin-left: 95px;
  }

  .mg-xl-t-95-force {
    margin-top: 95px !important;
  }

  .mg-xl-r-95-force {
    margin-right: 95px !important;
  }

  .mg-xl-b-95-force {
    margin-bottom: 95px !important;
  }

  .mg-xl-l-95-force {
    margin-left: 95px !important;
  }

  .mg-xl-100 {
    margin: 100px;
  }

  .mg-xl-100-force {
    margin: 100px !important;
  }

  .mg-xl-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .mg-xl-y-100-force {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }

  .mg-xl-x-100 {
    margin-left: 100px;
    margin-right: 100px;
  }

  .mg-xl-x-100-force {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }

  .mg-xl-t-100 {
    margin-top: 100px;
  }

  .mg-xl-r-100 {
    margin-right: 100px;
  }

  .mg-xl-b-100 {
    margin-bottom: 100px;
  }

  .mg-xl-l-100 {
    margin-left: 100px;
  }

  .mg-xl-t-100-force {
    margin-top: 100px !important;
  }

  .mg-xl-r-100-force {
    margin-right: 100px !important;
  }

  .mg-xl-b-100-force {
    margin-bottom: 100px !important;
  }

  .mg-xl-l-100-force {
    margin-left: 100px !important;
  }

  .mg-xl-t-auto {
    margin-top: auto;
  }

  .mg-xl-r-auto {
    margin-right: auto;
  }

  .mg-xl-b-auto {
    margin-bottom: auto;
  }

  .mg-xl-l-auto {
    margin-left: auto;
  }

  .mg-xl-auto {
    margin: auto;
  }

  .mg-xl-x-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .mg-xl-l--1 {
    margin-left: -1px;
  }

  .mg-xl-r--1 {
    margin-right: -1px;
  }

  .mg-xl-t--1 {
    margin-top: -1px;
  }
}