.loading-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    z-index: 9999;
    top: 0;
    display: none;

    &.show {
        display: flex;
        width: 100%;

        .indicator {
            display: flex;
            width: 100%;

            .align-items-center {
                width: 100%;
            }
        }
    }
}