#nprogress {
  .bar {
    z-index: 9999;
    background: white;
    height: 4px !important;
    .peg {
      box-shadow: 0 0 10px #fff, 0 0 5px #fff;
    }
  }
  .spinner {
    z-index: 9999;
    .spinner-icon {
      border-top-color: white;
      border-left-color: white;
    }
  }
}