.pagemaker {
    &-section {
        &--1 {
            .subtitle {
                color: $secondary;
            }
            h2 {
                color: $primary;
                text-transform: uppercase;
            }
        }
    }
    &-content {
        .subtitle {
            color: $secondary;
        }
        h1 {
            color: $primary;
            text-transform: uppercase;
        }
        h2 {
            color: $primary;
            text-transform: uppercase;
        }
        .content {
            padding: 5.6em 4em;
        }
        &--1 {
            text-align: left;
            margin: 20px 0;
        }
        &--2 {
            text-align: center;
            margin: 20px 0;
        }
        &--3 {
            &.theme {
                &--clear {
                }
                &--light {
                }
                &--dark {
                }
            }
        }
        &--4 {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                margin-top: 0;
            }
        }
        &--6 {
            padding-top: 56.25%;
            position: relative;
            .react-player {
                position: absolute;
                top: 0;
                left: 0;
                width: 100% !important;
                height: 100% !important;
            }
        }
    }

    .bg-image {
        height: 100%;
    }
    .bg-cover {
        background-size: cover;
    }
}
