.Toastify {
    &__toast {
        line-height: 18px;
        font-size: 15px;
        border-radius: 50px;
        padding:0 20px;
        &--success {
            background: $white;
            border: solid 2px $success;
            color: $success;
        }
        &--warning {
            background: $white;
            border: solid 2px $warning;
            color: $warning;
        }
        &--error {
            background: $white;
            border: solid 2px $danger;
            color: $danger;
        }
        &-container {
            z-index: 9999999;
        }
    }
    &__progress-bar--success {
        background: rgba($success, 0.5);
    }
    &__close-button {
        margin-top: 20px;
        &--success {
            color: $success;
        }
    }
}