/* adds some margin below the link sets  */

html,
body {
	height: 100%;
}

.navbar .dropdown-menu {
	border: none;
}

.navbar {
	&.navbar-submenu {
		margin: 20px 0;
		.navbar-nav {
			margin: auto;
		}
		a {
			font-weight: 700;
			font-size: 15px;
			text-transform: uppercase;
			padding: 10px 15px;
			&:hover {
				background: $primary;
				color: $white;
			}
		}
	}
}

@media screen and (max-width: 992px) {
	.navbar {
		.container {
			padding: 0;
			position: relative;
		}

		.nav-item {
			.nav-link {
				margin: 0;
				padding: 0;
			}
		}
	}
}

.navbar-menu {
	.mega-menu {
		background-color: #26303e !important;
		padding: 0 10px 10px 10px;
		margin-bottom: 5px;
	}

	.mega-menu {
		.nav-item {
			padding: 0;
			.nav-link {
				color: #fff;
				padding: 0;

				&:hover {
					color: #fff;
				}
			}
		}

		h3 {
			color: $white;
			margin: 0;
		}

		h5 {
			margin: 10px 0;
			color: $white;
		}
	}

	/* breakpoint and up - mega dropdown styles */
	@media screen and (min-width: 992px) {
		/* remove the padding from the navbar so the dropdown hover state is not broken */
		&.navbar {
			padding-top: 0px;
			padding-bottom: 0px;
			padding: 0.8em 0;
			height: 93px;

			.dropdown-menu div[class*="col"] {
				margin-bottom: 1rem;
			}

			/* remove the padding from the nav-item and add some margin to give some breathing room on hovers */
			.nav-item {
				padding: 0.5rem 0.5rem;
				margin: 0 0.25rem;
			}

			/* makes the dropdown full width  */
			.dropdown {
				position: static;
			}

			.mega-menu {
				width: 100%;
				left: 0;
				right: 0;
				/*  height of nav-item  */
				top: 90px;
				background-color: #091223 !important;

				a {
					color: #fff;
				}

				.nav-item {
					padding: 0;

					.nav-link {
						color: #fff;
						padding: 0;

						&:hover {
							color: #fff;
						}
					}
				}

				h3 {
					color: $white;
					margin: 0;
				}

				h5 {
					margin: 10px 0;
					color: $white;
				}
			}

			.dropdown-menu {
				// border: 1px solid rgba(0, 0, 0, 0.15);
				// background-color: #fff;
			}
		}
	}
}

.navbar-quick {
	&.navbar {
		.dropdown-menu {
			background-color: #091223 !important;

			a {
				color: #fff;
			}

			.dropdown-item {
				&:hover {
					color: #091223;
				}
			}

			.dropdown-divider {
				border-color: rgba(255, 255, 255, 0.5);
			}
		}

		.dropdown-toggle {
			color: rgba(255, 255, 255, 0.75);
			font-size: 16px;
		}
	}
}

.navbar-auth {
	position: relative;
	.dropdown-menu {
		left: auto;
		top: 110%;
		right: -11px;
		border: solid 1px;
		border-color: #262f3e;
		background-color: #081224;
		color: #848992;
		&::after {
			content: " ";
			display: block;
			position: absolute;
			z-index: 1;
			top: 0;
			right: 2.5em;
			width: 8px;
			height: 8px;
			margin: -5px 0 0 -4px;
			border-left: 1px solid #ddd;
			border-top: 1px solid #ddd;
			background-color: #fff;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
			border-color: #262f3e;
			background-color: #081224;
		}
		.dropdown-item {
			&:hover,
			&:focus {
				background: none;
			}
		}
	}
	@media screen and (min-width: 992px) {
		.nav-item {
			padding: 0.5rem 0 !important;
		}
	}
	@media screen and (max-width: 992px) {
		position: absolute;
		right: 0;
		top: 8px;
		.dropdown-menu {
			top: 60px;
			position: absolute;
			right: -15px;
		}
	}
}

.navbar-toggler {
	border: solid 1px #fff;
	width: 50px;
	height: 50px;
	@media screen and (max-width: 992px) {
		width: 40px;
		height: 40px;
	}
	color: white;
	border-radius: 50%;
	background-color: white !important;
	color: $primary !important;
	i {
		color: $primary !important;
	}
}

.navbar-social {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
}

.useful-links {
	a {
		margin: 0 10px;
	}
}

.sub-page {
	background: #f4f4f4;
}

.navbar-brand {
	h5 {
		margin: 0;
		padding: 0;
	}
}

.navbar-quick {
	background: #071223;
	height: 50px;

	li {
		a {
			color: #fff;
			text-decoration: none;
			font-size: 14px;

			&:hover {
				color: rgba(255, 255, 255, 0.75);
			}
		}
	}
}

.navbar-custom {
	> ul {
		> li:first-child {
			a {
				padding-left: 0;
			}
		}
	}

	.dropdown-menu {
		border: solid 1px;
		border-color: #262f3e;
		background-color: #081224;
		color: #848992;
		padding: 0.5rem;
		&::after {
			border-color: #262f3e;
			background-color: #081224;
		}
		a {
			color: white;
		}

		@media screen and (max-width: 992px) {
			border: none;
			background-color: #eee;
			padding: 0 10px;

			.nav-item {
				width: 100%;
				padding-left: 15px;
				a {
					color: $primary;
				}
			}
		}
	}

	.dropdown-toggle {
		&:after {
			margin-left: 20px;
		}
	}

	&.submenu {
		padding: 0;
		@media screen and (min-width: 992px) {
			.navbar-collapse {
				> .navbar-nav {
					> .nav-item {
						> .nav-link {
							padding: 1rem;
						}
					}
				}
			}
		}

		@media screen and (max-width: 992px) {
			padding: 0 15px;
			.navbar-collapse {
				> .navbar-nav {
					padding-top: 10px;
					padding-bottom: 10px;
					> .nav-item {
						padding-top: 5px;
						padding-bottom: 5px;
					}
					> .dropdown {
						> .dropdown-menu {
							padding-top: 10px;
							padding-bottom: 10px;
						}
					}
				}
			}
		}
	}
}

.logo-image {
	max-height: 55px;
	width: auto;
	@media screen and (max-width: 992px) {
		max-height: 45px;
	}
}

.slider {
	position: relative;
	height: 65vh;
	@media screen and (max-width: 992px) {
		height: 35vh;
		background-position-x: 25% !important;
	}
	@media screen and (max-width: 767px) {
		.sc_item_title_text {
			font-size: 30px;
			line-height:30px;
		}
	}
	.slider-description {
		font-size: 20px;
		color: #fff;
	}

	&--container {
		height: 100%;
	}
	&--title {
		position: absolute !important;
		bottom: 0;
	}

	.sc_item_button {
		display: block !important;
		margin-bottom: 40px;
	}
}

// .scheme-light {
// 	.slider {
// 		height: auto !important;
// 		padding-top: 40%;
// 	}
// }

.article-page {
	.body_wrap {
		background: #f4f4f4;
	}

	&.scheme_alter {
		.sc_title {
			&.sc_title_accent {
				background-color: #091223;
			}
		}

		.widget {
			.widget_title {
				background-color: #091223;
			}
		}
	}
}

.header {
	&--description {
		color: #fff;
	}
}

.header_content_wrap {
	.header {
		&--description {
			color: #f00;
			margin-top: 5px;
		}
	}
}

.changelog {
	h1,
	h2,
	h3,
	h4,
	h5 {
		margin: 20px 0;
	}
}

.clearfix {
	clear: both;
}

.navbar-top {
	@media screen and (max-width: 992px) {
		position: absolute;
		right: 0;
		top: 0px;
		z-index: 1;
	}

	.dropdown-menu {
	}
}

.venue {
	img {
		width: auto !important;
		height: auto;
		max-height: 100%;
	}

	h5 {
		margin: 0;
	}

	.tags {
		a {
			background: #071223;
			padding: 10px;
			display: inline-block;
			color: #fff;
			border-radius: 20px;
			margin: 0 5px 5px 0;
		}
	}
}

.background-image {
	background-size: cover;
	background-position: center;
}

.navbar-brand {
	max-width: 550px;
	h5 {
		word-break: break-all;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	@media screen and (min-width: 992px) and (max-width: 1200px) {
		h5 {
			max-width: 380px;
		}
	}
}

.latest-results {
	.card {
		.date {
			font-size: 12px;
			line-height: 11px;
		}
		.team-name {
			font-size: 14px;
		}
		.score {
			color: $primary !important;
			font-weight: 600;
			font-size: 35px;
		}
	}
}

.wrap-text {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.content {
	table td,
	table th {
		padding: 0.75em 0.75em;
	}
}

.clickable {
	cursor: pointer;
}

.nowrap {
	position: relative;
	word-break: keep-all;
	white-space: nowrap;
}

.stats-details {
	margin: 40px 0 20px 0;
	h3 {
		margin: 0;
		font-size: 15px;
		text-transform: uppercase;
	}
	p {
		font-size: 20px;
	}
	.result {
		padding: 0px 8px;
		color: #fff;
		font-size: 15px;
		display: inline-block;
		margin-top: 10px;
		color: $white;
		&--1 {
			background: $success;
		}
		&--2 {
			background: $danger;
		}
		&--3 {
			background: $gray-300;
		}
	}
	.vs {
		font-size: 15px;
		color: $primary;
		font-weight: 500;
	}
	.team {
		margin: 0;
	}
	.date {
		font-size: 13px;
		line-height: 14px;
	}
	.player-match {
		font-size: 15px;
		display: inline-block;
		margin-top: 10px;
	}
}

.player-match {
	span {
		padding: 5px 10px;
		color: $white;
		display: inline-block;
		width: 30px;
		text-align: center;
		&.won-0 {
			background: $gray-300;
			color: $primary-dark;
		}
		&.won-1 {
			background: lighten($primary-dark, 75%);
			color: $primary-dark;
		}
		&.won-2 {
			background: lighten($primary-dark, 50%);
			color: $white !important;
		}
		&.won-3 {
			background: lighten($primary-dark, 25%);
			color: $white !important;
		}
		&.won-3 {
			background: $primary-dark;
			color: $white !important;
		}
	}
}

.text-success {
	color: $success !important;
}

.text-danger {
	color: $danger !important;
}

.btn {
	-webkit-appearance: none;
	cursor: pointer;
	display: inline-block;
	vertical-align: top;
	font-size: 14px;
	line-height: 18px;
	font-weight: 500;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
	text-transform: none;
	white-space: nowrap;
	padding: 1.38em 3.5em;
	letter-spacing: 0;
	height: auto;
	border-width: 0 !important;
	&-rounded {
		border-radius: 30px;
	}
	&-primary {
		color: $white !important;
	}
}

.feed {
	h5 {
		margin: 0;
	}
	&--item {
	}
	&--image {
		img {
			display: none !important;
		}
		margin-bottom: 10px;
		height: 256px;
		background-size: cover;
		background-position: center;
	}
}

.feed {
	.owl-stage {
		.owl-item {
			&.active {
				+ .active {
					.feed--item {
						margin-right: 0 !important;
					}
				}
				+ :not(.active) {
					margin-left: 1.5rem !important;
				}
			}
		}
	}
}

.modal-open {
	padding: 0 !important;
	.modal {
		z-index: 999999;
	}
}

.DayPicker {
	width: 100%;
	background: #fafafa;
	margin: 20px 0;
	z-index: 999 !important;
	position: relative;
	&-wrapper {
		width: 100%;
		z-index: 9999 !important;
	}
	&-Month {
	}
	&-Day {
		border-radius: 5px;
		&--selected {
			background-color: $primary !important;
		}
	}
}

.rc-time-picker {
	.rc-time-picker-input {
		border: none;
	}
}

.match-details {
	h4 {
		a {
			color: $primary;
		}
	}
}

body {
	background: #081224;
}

.top_panel {
	.sc_layouts_row {
		background: #081224;
	}
}

.scheme {
	&-dark {
		background: #081224;
	}
	&-light {
		background: #f0f2f7;
		.sc_title {
			&.sc_title_accent {
				background: #081224 !important;
			}
		}
		.header {
			margin-bottom: 50px;
		}
		.widget {
			.widget_title {
				background: #081224;
			}
		}
	}
}

.btn {
	&--circle {
		display: flex;
		padding: 0;
		width: 50px;
		height: 50px;
		@media screen and (max-width: 992px) {
			width: 40px;
			height: 40px;
		}
		border-radius: 50%;
		color: #ffffff;
		background-color: #262f3e;
		i {
			margin: auto;
		}
	}
}

.equal {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	> [class*="col-"] {
		display: flex;
		flex-direction: column;
	}
}

.memberships {
	&--element {
		.membership {
			.card {
				height: 100%;
			}
		}
	}
}

.stripe {
	.card-input {
		background: #fafafa;
		padding: 15px;
		margin: 15px 0;
		border-radius: 5px;
	}
}

.btn-gray-200 {
	background: $gray-200;
	color: $primary !important;
	&:hover {
		background: $primary;
		color: white !important;
	}
}

.navbar-auth {
	.dropdown-divider {
		background: #262f3e;
		border-color: #262f3e;
	}
	.dropdown-menu {
		padding-top: 0;
	}
	.nav-members {
		.dropdown-item {
			cursor: pointer;
			padding: 5px 1.5rem;
		}
		.current {
			padding: 10px 1.5rem;
			cursor: default;
			background: #262f3e;
			&:hover {
				background: #262f3e;
				color: white;
			}
		}
		.membership {
			font-size: 10px;
			margin-bottom: 10px;
			line-height: 1px;
			display: block;
		}
	}
}

.page-content {
	background: white;
	padding: 30px;
	h1,
	h2,
	h3,
	h4,
	h5 {
		margin-top: 0;
		margin-bottom: 5px;
	}
}

.accordion {
	.view-all{
		display: block !important;
		text-align: right;
		cursor: pointer;
		width: 100%;
	}

	.hide-all{
		text-align: right;
		cursor: pointer;
		width: 100%;
	}
}

.mceNonEditable h4 {
	font-size: 1.5em !important;
	-webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 10px;
    background-color: #F1F1F1;
    text-align: left;
    padding: 0.7em 1em;
    font-size: 1em;
    font-weight: 600;
    cursor: pointer;
    position: relative;
}

.fa-chevron-down{
	float: right;
	color: #000000;
	margin-top: -1em;
}

.fa-chevron-up{
	float: right;
	color: #000000;
	margin-top: -1em;
}

.mceNonEditable h4 a {
	font-size: 0.9em;
	color: #000000;
}

.mceNonEditable h4 {
	.fa-chevron-down{
		display: none;
	}

	.fa-chevron-up{
		display: block;
	}
}

.collapsed {
	.fa-chevron-down{
		display: block !important;
	}

	.fa-chevron-up{
		display: none !important;
	}
}

.membership-join {
	.membership-option {
		border: solid 3px #fff;
		&.selected {
			border: solid 3px $primary;
		}
	}
}

.card {
	&.option {
		border: solid 3px #fff;
		&.selected {
			border: solid 3px $primary;
		}
	}
}

.membership-page {
	.membership {
		.content {
			background: white;
			padding: 30px;
		}
	}
}
.memberships-list,
.sessions-list {
	h4 {
		text-transform: uppercase;
	}
}

.page_content_wrap {
	.sidebar {
		width: auto !important;
	}
}

.bg-tertiary {
	background: #26303e;
}

.font-weight-semibold {
	font-weight: 600;
}

.event-price {
	line-height: 15px;
	&.disabled-price {
		h4 {
			color: #848992;
			font-weight: 500;
		}
	}

	&.member-price {
	}

	&.public-price {
	}
}

@media (min-width: 992px) {
	.dropdown-menu .dropdown-toggle:after {
		border-top: 0.3em solid transparent;
		border-right: 0;
		border-bottom: 0.3em solid transparent;
		border-left: 0.3em solid;
	}
	.dropdown-menu .dropdown-menu {
		margin-left: 0;
		margin-right: 0;
	}
	.dropdown-menu li {
		position: relative;
		width: 100%;
	}
	.nav-item .submenu {
		display: none;
		position: absolute;
		left: 100% !important;
		top: -7px !important;
		margin-left: 3px;
	}
	.nav-item .submenu-left {
		right: 100%;
		left: auto;
	}
	.dropdown-menu > li:hover {
	}

	.dropdown-menu > li:hover > .submenu {
		display: block;
	}
}

.profile-page {
	.card {
		&.card--nav {
			padding-bottom: 0;
			.name {
				margin-top: 10px;
			}
		}
	}
	.nav {
		margin-top: 20px;
		color: #bbb;
		.active {
			color: $primary;
			border-bottom: solid 3px $primary;
		}
		.nav-link {
			padding: 15px;
			cursor: pointer;
			&:hover {
				color: $primary;
			}
		}
	}
	.status {
		width: 25px;
		height: 25px;
		display: inline-block;
		-webkit-border-radius: 50%;
		-ms-border-radius: 50%;
		border-radius: 50%;
	}
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

.gallery {
	&--item {
		p {
			line-height: 20px;
		}
	}
	.card-img-overlay {
		background: rgba(0, 0, 0, 0.5);
	}
}

.header {
	&.homepage {
		.slider {
			min-height: 50vh;
		}
	}
}

.global-search {
	background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 50%, rgba(7, 18, 35, 1) 100%),
		url(../../assets/images/header2.jpg);
	background-position: bottom center;
	background-repeat: no-repeat;
	background-size: cover;
	height: calc(100vh - 100px);
	text-align: center;

	section {
		width: 100%;
	}

	.title {
		color: white;
		font-size: 35px;
		font-weight: 500;
		line-height: 40px;
	}

	input {
		&[type="text"] {
			color: $primary;
			border-color: white;
			background-color: rgba(255, 255, 255, 0.5);
			&:focus {
				color: $primary;
				border-color: white;
				background-color: rgba(255, 255, 255, 0.75);
			}
		}
	}
}

/* Customize the label (the container) */

.checkmark-container {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 18px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}
}

/* Hide the browser's default checkbox */

/* Create a custom checkbox */

.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: #eee;
}

/* On mouse-over, add a grey background color */

.checkmark-container {
	&:hover input ~ .checkmark {
		background-color: #ccc;
	}

	input:checked ~ .checkmark {
		background-color: $primary;
	}
}

/* When the checkbox is checked, add a blue background */

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */

.checkmark-container {
	input:checked ~ .checkmark:after {
		display: block;
	}

	.checkmark:after {
		left: 9px;
		top: 5px;
		width: 5px;
		height: 10px;
		border: solid white;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
}

.terms-title {
	font-size: 18px;
}

.pre-content {
	margin-bottom: 20px;
	p {
		margin-bottom: 0;
	}
}

.sidebar {
	.alert {
		line-height: 18px;
	}
}

.alert {
	&-rounded {
		border-radius: 30px;
	}
}

.consents {
	.consent {
		position: relative;
		background: #fafafa;
		.required {
			color: $danger;
		}
	}
}

.sessions-list {
	.min-period {
		display: flex;
		i {
			margin: 3px;
		}
		span {
			margin-top: -1px;
			margin-left: 1px;
		}
	}
}

.btn-key {
	border-radius: 30px;
	padding: 20px;
}

.btn-link {
	color: $secondary;
}

.btn-small {
	padding: 15px 30px;
}

.list-cards {
	h6 {
		font-size: 1em;
	}
	.card {
		border: solid 1px #ddd;
		p {
			margin: 0;
		}
	}
	.btn {
		&.btn-sm {
			&.btn--circle {
				width: 25px;
				height: 25px;
			}
		}
	}
}

.help-container {
	float: right;
}

.game {
    input {
        &.invalid {
            border: solid 1px red;
            background-color: rgba(255, 7, 7, 0.1) !important;
        }
		&.failed {
            border: solid 1px orange;
            background-color: rgba(255, 7, 7, 0.1) !important;
        }
    }
}

.modal-title {
	margin:0;
}

.owl-nav {
	margin-top: 0px !important;
    height: 40px;
	[class*='owl-'] {
		background: none !important;
		font-size: 20px !important;
	}
	.owl-prev {
		float:left;
	}
	.owl-next {
		float:right;
	}
}

.position {
	&.selected {
		outline: .2em $primary solid;
	}
}