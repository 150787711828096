.fixture-list {
	.fixture {
	padding: 15px 0;
	&:hover {
		cursor: pointer;
	}
		.team {
			display: flex;
			align-items: center;
			justify-content: center;
			a {
				@media(max-width: 767px) {
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
				}
			}
			.team-logo {
				img {
					width: 100px;
				}
				&:hover {
					text-decoration: none;
				}
				.team-name {
					color: #262f3e;
					font-size: 1.5em;
					@media (max-width:767px) {
						font-size: 1.1em;
						padding: 5px;
					}
				}
			}
		}
		h3 {
			margin: 0;
		}
	}
}
