.sidebar-root {
    @media screen and (max-width: 480px) {
        width: 100%;
    }
    .sidebar {
        width: 400px;
        @media screen and (max-width: 480px) {
            width: 100%;
        }
        .sidebar--header {
            padding: 25px;
        }
        .sidebar--content {
            padding: 0 25px;
        }
        .sidebar--footer {
            padding: 0 25px;
        }
        h6 {
                position: relative;
            
                .help-container {
                    float: right;
            
                    .help {
                        position: absolute;
                        top: 0;
                        right: 0;
                        cursor: pointer;
            
                        i {
                            color: $gray-500;
                        }
                    }
                }
        }
    }
}