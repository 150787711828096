.matchcard {
    p {
        margin-bottom: 0;
    }
    .games {
        display: block;
        width: 100%;
        overflow-x: auto;
        &--container {
            @media (max-width: 992px) {
                padding: 20px 0;
                display: flex;
                justify-content: center;
            }
            @media (max-width: 767px) {
                width:550px;
            }
        }
    }
    .game {
        margin-right: 10px;
        input {
            width: 50px;
            text-align: center;
            &::-webkit-inner-spin-button,::-webkit-outer-spin-button { 
                -webkit-appearance: none; 
                margin: 0; 
            }
            &:focus {
                position: relative;
                z-index: 2;
            }
            &:last-child {
                margin-left: -1px;
            }
        }
    }
    .game-vertical {
        margin-right: 5px;
        input {
            width: 50px;
            text-align: center;
            &::-webkit-inner-spin-button,::-webkit-outer-spin-button { 
                -webkit-appearance: none; 
                margin: 0; 
            }
            &:focus {
                position: relative;
                z-index: 2;
            }
            /* &:last-child {
                margin-left: -1px;
            } */
        }
    }
    .score {
        p {
            font-weight: 600;
            font-size: 20px;
        }
    }
    .name {
        .btn {
            text-align: left;
            padding: 0;
        }
    }
}