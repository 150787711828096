.entry-teams {
    .price {
        .cost {
            font-weight: 500;
        }
        span {
            line-height: 10px;
        }
    }
}

.btn-hover-gray {
    &:hover {
        background-color: #eee !important;
        color: $primary !important;
    }
}